import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import { strings } from "../localization/Localization";
import { Close } from "@mui/icons-material";

interface IProps {
    open: boolean,
    handleClose?: () => void,
    handleConfirm?: () => void,
    title: string | boolean,
    content:any,
    actionButtonName?:string,
    color?:any;
    variant?:any;
    onClose?:boolean;
    isPassive? : boolean;
}

export default function ConfirmationModal ({ open, handleClose, handleConfirm, title, content, actionButtonName, color, variant, onClose, isPassive }: IProps) {

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                    {onClose ? (
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Close />
                        </IconButton>
                    ) : null}
                </DialogTitle>
                <DialogContent>
                   {content}
                </DialogContent>
                <DialogActions>
                    {!onClose &&<Button onClick={handleClose}>{strings.cancel}</Button>}
                    {actionButtonName && (<Button disabled = {isPassive === false ? true : false} onClick={handleConfirm} color={color ? undefined : "error"} variant={variant ? variant : undefined }>
                        {actionButtonName}
                    </Button>)}
                </DialogActions>
            </Dialog>
        </div>
    );
}
