import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { DatePickerField } from "../formFields/DatePickerFields";
import InputField from "../formFields/InputField";
import SelectField from "../formFields/SelectField";
import { NomenclatureEntryDTO } from "../../../models/nomenclatures/NomenclatureEntryDTO";
import { useEffect, useState } from "react";
import { getAllEntriesForNomenclatureAnbAnswers, getAllEntriesForNomenclatureDiscover, getAllEntriesForNomenclatureMeasureTakenReasons, getAllEntriesForNomenclatureMeasureTakenTypes, getAllEntriesForNomenclatureMvrOrganizationalUnit, getAllResidenceReasons } from "../../../common/NomenclatureMethods";
import { useFormikContext } from "formik";
import { NomenclatureConstants } from "../../../constants/NomenclatureConstants";
import { PaginatedSelect } from "../../../components/PaginatedSelect";
import { NomenclatureService } from "../../../services/NomenclatureService";
import CustomSelect from "../formFields/CustomSelect";
import InputFieldCyrillic from "../formFields/InputFieldCyrillic";
const list = [
  {
    value: true,
    label: 'Да'
  },
  {
    value: false,
    label: 'Не'
  },
];
export function NoteForeignerDataForm(props: any) {
  const {
    formField: {
      measureTakenType,
      measureTakenReason,
      placeMeasuresTaken,
      placeMeasuresTakenMunicipality,
      placeMeasuresTakenPlace,
      dateMeasuresTaken,
      OVRMeasuresTaken,
      attemptIllegal,
      attemptIllegalPlace,
      attemptIllegalDate,
      residenceBan,
      residenceBanStartDate,
      residenceBanEndDate,
      noteForeigner,
      dateEvidented,
      checksPerformed,
      illegalPlace,
      illegalPlaceMunicipality,
      illegalPlacePlace,
      discovererMvrKey,
      OVR,
      shelterForeigners,
    }
  } = props;

  const [measureTakenReasons, setMeasureTakenReasons] = useState<NomenclatureEntryDTO[]>([]);
  const [measureTakenTypes, setMeasureTakenTypes] = useState<NomenclatureEntryDTO[]>([]);
  const formikProps = useFormikContext<any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [anbAnswers, setAnbAnswers] = useState<NomenclatureEntryDTO[]>([]);
  const [discoverers, setDiscoverers] = useState<NomenclatureEntryDTO[]>([]);
  const [mvrOrganizationalUnits, setMvrOrganizationalUnits] = useState<NomenclatureEntryDTO[]>([]);
  useEffect(() => {
    getDisabled();
    getAllEntriesForNomenclatureMvrOrganizationalUnit().then((res) => {setMvrOrganizationalUnits(res)})
    getAllEntriesForNomenclatureMeasureTakenReasons().then((res)=>{setMeasureTakenReasons(res)});
    getAllEntriesForNomenclatureMeasureTakenTypes().then((res)=>{setMeasureTakenTypes(res)});
    getAllEntriesForNomenclatureAnbAnswers().then((res)=>{setAnbAnswers(res)});
    getAllEntriesForNomenclatureDiscover().then((res)=>{setDiscoverers(res)});
    if(formikProps.values.evidenceReasonMvrKey){
    getAllResidenceReasons(formikProps.values.evidencyReasonMvrKey).then((res)=>{setMeasureTakenReasons(res)});
    }
   
    async function loadData(): Promise<void> {
     if(formikProps.values.evidencyReasonMvrKey!==NomenclatureConstants.measureTakenKey){
      setDisabled(true);
     }
    }
    loadData().then((_) => {
    });


  }, [])
  function getDisabled(){
    if(formikProps.values.arrivalWay && formikProps.values.arrivalWay !== NomenclatureConstants.illegalEntranceTypeKey){
     return true
    }
    else{
    return false
    }
  }
  function handleChange(event: SelectChangeEvent){
    formikProps.setFieldValue("shelterForeigners", event.target.value)
  };
  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {/* Grid 1 */}
        <Grid item xs={12} sm={4} md={6}>
          <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
            3. Податоци за преземените мерки
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {measureTakenType.id + ". " + measureTakenType.label}
          </Typography>
           <SelectField className="pb-3"
            name={measureTakenType.name}
            label={measureTakenType.placeholder}
            data={measureTakenTypes}
            disabled={disabled}
            read={props.hasAuthority ? false : true}
          /> 
          <Typography variant="subtitle1" gutterBottom>
            {measureTakenReason.id + ". " + measureTakenReason.label}
          </Typography>
          <SelectField className="pb-3"
            name={measureTakenReason.name}
            label={measureTakenReason.placeholder}
            data={measureTakenReasons}
            disabled={disabled}
            read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" gutterBottom>
            {placeMeasuresTaken.id + ". " + placeMeasuresTaken.label}
          </Typography>
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
            <Typography variant="subtitle1" gutterBottom>
              {placeMeasuresTakenMunicipality.id + ". " + placeMeasuresTakenMunicipality.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.placeMeasuresTakenMunicipality!=="" ? formikProps.values.placeMeasuresTakenMunicipality : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("placeMeasuresTakenMunicipality", value);
                          formikProps.setFieldValue("placeMeasuresTakenPlace", "");
                        }
                        }
                        label={placeMeasuresTakenMunicipality.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={placeMeasuresTakenMunicipality.name}
                        className="pb-3"
                        disabled={disabled}
                        readOnly={props.hasAuthority ? false : true}
                      />
            <Typography variant="subtitle1" gutterBottom>
              {placeMeasuresTakenPlace.id + ". " + placeMeasuresTakenPlace.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.placeMeasuresTakenPlace!=="" ? formikProps.values.placeMeasuresTakenPlace : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("placeMeasuresTakenPlace", value)
                          }
                        }
                        label={placeMeasuresTakenPlace.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.placeMeasuresTakenMunicipality?.key}`, filter ? filter : ""
                          );
                        }}
                        name={placeMeasuresTakenPlace.name}
                        className="pb-3"
                        disabled={disabled}
                        readOnly={props.hasAuthority ? false : true}
                      />
          </CenteredCardLayout>
          <Typography variant="subtitle1" gutterBottom>
            {dateMeasuresTaken.id + ". " + dateMeasuresTaken.label}
          </Typography>
          <DatePickerField
            name={dateMeasuresTaken.name}
            label={dateMeasuresTaken.placeholder}
            className="pb-3"
            disabled={disabled}
            read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" gutterBottom>
            {OVRMeasuresTaken.id + ". " + OVRMeasuresTaken.label}
          </Typography>
          <SelectField className="pb-3"
            name={OVRMeasuresTaken.name}
            label={OVRMeasuresTaken.placeholder}
            data={mvrOrganizationalUnits}
            disabled={getDisabled()}
            read={props.hasAuthority ? false : true}
          />
                       <Typography variant="subtitle1" gutterBottom>
            {illegalPlace.id + ". " + illegalPlace.label}
        </Typography>  
        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
        <Typography variant="subtitle1" gutterBottom>
            {illegalPlaceMunicipality.id + ". " + illegalPlaceMunicipality.label}
        </Typography> 
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.illegalPlaceMunicipality!=="" ? formikProps.values.illegalPlaceMunicipality : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("illegalPlaceMunicipality", value);
                          formikProps.setFieldValue("illegalPlacePlace", "")
                        }
                        }
                        label={illegalPlaceMunicipality.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={illegalPlaceMunicipality.name}
                        className="pb-3"
                        disabled={getDisabled()}
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {illegalPlacePlace.id + ". " + illegalPlacePlace.label}
        </Typography> 
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.illegalPlacePlace!=="" ? formikProps.values.illegalPlacePlace : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("illegalPlacePlace", value)
                          }
                        }
                        label={illegalPlacePlace.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.illegalPlaceMunicipality.key}`, filter ? filter: ""
                          );
                        }}
                        name={illegalPlacePlace.name}
                        className="pb-3"
                        disabled={getDisabled()}
                        readOnly={props.hasAuthority ? false : true}
                      />
        </CenteredCardLayout>
        <Typography variant="subtitle1" gutterBottom>
            {discovererMvrKey.id + ". " + discovererMvrKey.label}
        </Typography>  
        <SelectField className="pb-3"
            name={discovererMvrKey.name}
            label={discovererMvrKey.placeholder}
            data={discoverers}
            disabled={getDisabled()}
            read={props.hasAuthority ? false : true}
          />
        <Typography variant="subtitle1" gutterBottom>
            {OVR.id + ". " + OVR.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.OVR!=="" ? formikProps.values.OVR : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("OVR", value)
                          }
                        }
                        label={OVR.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.organisationalUnit, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={OVR.name}
                        className="pb-3"
                        disabled={getDisabled()}
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {shelterForeigners.id + ". " + shelterForeigners.label}
        </Typography>  
        <CustomSelect className="pb-3"
            name={shelterForeigners.name}
            label={shelterForeigners.placeholder}
            data={list}
            disabled={getDisabled()}
            read={props.hasAuthority ? false : true}
            onChange={handleChange}
          />
          <Typography variant="subtitle1" gutterBottom>
            {attemptIllegal.id + ". " + attemptIllegal.label}
          </Typography>

          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
            <Typography variant="subtitle1" gutterBottom>
              {attemptIllegalPlace.id + ". " + attemptIllegalPlace.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.attemptIllegalPlace!=="" ? formikProps.values.attemptIllegalPlace : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("attemptIllegalPlace", value)
                          }
                        }
                        label={attemptIllegalPlace.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.place, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={attemptIllegalPlace.name}
                        className="pb-3"
                        disabled={disabled}
                        readOnly={props.hasAuthority ? false : true}
                      />
            <Typography variant="subtitle1"  gutterBottom>
              {attemptIllegalDate.id + ". " + attemptIllegalDate.label}
            </Typography>
            <DatePickerField
              name={attemptIllegalDate.name}
              label={attemptIllegalDate.placeholder}
              className="pb-3"
              disabled={disabled}
              read={props.hasAuthority ? false : true}
              //maxDate={true}
            />
          </CenteredCardLayout>

        </Grid>
        {/* Grid 2 */}
        <Grid item xs={12} sm={4} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            {residenceBan.id + ". " + residenceBan.label}
          </Typography>
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
            <Typography variant="subtitle1" gutterBottom>
              {residenceBanStartDate.id + ". " + residenceBanStartDate.label}
            </Typography>
            <DatePickerField
              name={residenceBanStartDate.name}
              label={residenceBanStartDate.placeholder}
              className="pb-3"
              disabled={disabled}
              read={props.hasAuthority ? false : true}
            />
            <Typography variant="subtitle1" gutterBottom>
              {residenceBanEndDate.id + ". " + residenceBanEndDate.label}
            </Typography>
            <DatePickerField
              name={residenceBanEndDate.name}
              label={residenceBanEndDate.placeholder}
              className="pb-3"
              disabled={disabled}
              minDate={formikProps?.values?.residenceBanStartDate}
              read={props.hasAuthority ? false : true}
            />
          </CenteredCardLayout>
          <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
            4. Забелешка за странецот
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {noteForeigner.id + ". " + noteForeigner.label}
          </Typography>
          <InputField name={noteForeigner.name} label={noteForeigner.placeholder} fullWidth className="pb-3" multiline={true} rows={4} readOnly={props.hasAuthority ? false : true}/>
          <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
            5. Податоци за внесувачот на барањето
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {dateEvidented.id + ". " + dateEvidented.label}
          </Typography>
          <DatePickerField
            name={dateEvidented.name}
            label={dateEvidented.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" gutterBottom>
            {checksPerformed.id + ". " + checksPerformed.label}
          </Typography>
          <SelectField className="pb-3"
            name={checksPerformed.name}
            label={checksPerformed.placeholder}
            data={anbAnswers}
            read={props.hasAuthority ? false : true}
          />
          
        </Grid>
      </Grid>
    </>
  )
}
