import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconButton, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/mk";
import { useField } from "formik";
import { Clear } from "@mui/icons-material";
import { strings } from "../../../localization/Localization";
import { DateValidationError } from "@mui/x-date-pickers/internals";

dayjs.extend(customParseFormat);
dayjs.locale("mk");

interface Props {
  label: string;
  name: string;
  className: string;
  defaultToToday?: boolean;
  disabled?: boolean;
  flag?:boolean;
  read?: boolean;
  maxDate?: boolean;
  minDate? : Dayjs
}

export function DatePickerField(props: Props) {
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null | string | Date>(
    null
  );
  const { flag, read } = props;
  const [errorDate, setErrorDate] = useState<boolean>(false);
  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    } else if (flag) {
      setSelectedDate(dayjs());
      setValue(dayjs().toISOString());
    }
  }, [value, flag, error]);

  function _onChange(date: Dayjs | null) {
    if (date) {
    setSelectedDate(date);
    const isValidYear = date?.year() >= 1900 && date?.year() <= 2500;
    if(date?.isValid() && isValidYear){
    setErrorDate(false)
      try {
      const ISODateString = date.format('YYYY-MM-DD');
      setValue(ISODateString);
      } catch (error) {

      }
    }else{
      setErrorDate(true)
    }
    } else {
      setValue(date);
    }
  }

  const today = dayjs().startOf('day')

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="mk">
      <DatePicker
        {...field}
        {...props}
        onChange={_onChange}
        value={selectedDate}
        label={props.label}
        maxDate={props.maxDate ? today : undefined}
        minDate={props.minDate ? props.minDate : undefined}
        inputFormat="DD.MM.YYYY"
        renderInput={(params: any) => (
          <TextField
            {...params}
            fullWidth
            error={isError || errorDate}
            disabled={props.disabled}
            helperText={isError ? error : errorDate ? strings.invalidDate : ""}
            inputProps={{
              ...params.inputProps,
              placeholder: props.label,
            }}
          />
        )}
        InputProps={{
          endAdornment:
            value && !props.disabled ? (
              !read && <IconButton
                sx={{ height: "10px", width: "10px" }}
                onClick={() => {
                  setSelectedDate(null);
                  setValue(null);
                  setErrorDate(false)
                }}
              >
                <Clear />
              </IconButton>
            ) : undefined,
        }}
        InputAdornmentProps={{
          position: "start",
        }}
        readOnly={read ? true : false}
        
      ></DatePicker>
    </LocalizationProvider>
  );
}