import {RegistrationTypeDTO} from "../models/registration/RegistrationTypeDTO";
import axiosInstance from "../network/AxiosInstance";

export class TransliterationService{
    static async getTransliterationFile() {
        await axiosInstance.get(
            `/rest/transliteration/all/`, {
                responseType: 'blob'
            }
        ).then((response) => {
            var blob = response.data
            const fileURL = window.URL.createObjectURL(blob);
            let fileName = response.headers["content-disposition"]?.split("filename=")[1].split(".")[0].replace(/"/g,'');
            var w = window.open(fileURL, '_blank')
            setTimeout(function(){ if(w !== null) {w.document.title = fileName;} }, 100);

        })
    }
}