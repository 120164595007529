import { RolesWithOUResponse } from "./RolesWithOUResponse";

export class CustomUserDetailsResponse {
    username: string;
    fullName: string;
    email: string;
    userId: string;
    roles?: RolesWithOUResponse[];

    constructor(json:CustomUserDetailsResponse){
        this.username = json.username;
        this.fullName = json.fullName;
        this.email = json.email;
        this.userId = json.userId;
        this.roles = json.roles?.map(item => new RolesWithOUResponse(item));
    }
}