import { VisaTypeDTO } from "./VisaTypeDTO";
import {CountryDTO} from "./CountryDTO";

export class VisaDTO {
    id: number;
    visaType?: VisaTypeDTO;
    country?: CountryDTO;
    visaNumber: string;
    constructor(json: VisaDTO) {
        this.id = json.id;
        this.visaType = json.visaType ? new VisaTypeDTO(json.visaType) : undefined;
        this.country =  json.country ?  new CountryDTO(json.country) : undefined;
        this.visaNumber = json.visaNumber;
}

}