export class Routes {
    public static HOME = "/";
    public static NOT_FOUND = "/not-found";
    public static LOGIN = "/login";
    public static LOGOUT = "/logout";
    public static APPLICATION = "/application";
    public static ASYLUM_SEEKERS='/asylum-seekers'
    public static PROFILE = "/profile";
    public static FOREIGNERS_SUBMITTED_FORMS = "/foreigners-submitted-forms";
    public static FOREIGNERS_SUBMITTED_FORM_DECISIONS = "/foreigners-submitted-form-decisions";
    public static FOREIGNERS_SUBMITTED_FORMS_PENDING = "/foreigners-submitted-forms/pending";
    public static FOREIGNERS_SUBMITTED_SHORT_STAY_FORMS = "/foreigners-submitted-short-stay-forms";
    public static FOREIGNERS_SUBMITTED_SHORT_STAY_FORMS_PENDING = "/foreigners-submitted-short-stay-forms/pending";
    public static INTERNAL_LOGIN = "/internal-login";
    public static RESET_PASSWORD = "/reset-password";
    public static NOMENCLATURES = "/nomenclatures";
    // public static EDIT_NOMENCLATURES = "/edit-nomenclatures";
    public static NOMENCLATURE_ENTRY_NEW = "/nomenclature/:n_id/new";
    public static NOMENCLATURE_DETAILS = "/nomenclature-details/:id";
    public static USERS = "/users-view";
    public static USERDETAILS = "/users-view/user-details/:id";
    public static EDIT_APPLICATION = "/application/:id";
    public static ASYLUM_SEEKERS_EDIT = "/asylum-seekers/:id";
    public static ADD_SHORT_TERM_STAY_APPLICATION = "/application/short-term/";
    public static EDIT_SHORT_TERM_STAY_APPLICATION = "/application/short-term/:id";
    public static REPORTS_NUMBER_OF_PERMITS = "/reports-number-of-permits";
    public static CALLBACK = "/callback";
    public static SILENT_RENEW = "/silent_renew";
    public static ASYLUM_REPORTS = "/asylum-reports";
    public static DIPLOMATS_FORM = "/diplomats-form";
    public static DIPLOMATS_FORM_EDIT = "/diplomats-form/:id";
    public static DIPLOMATS_SUBMITTED_FORMS = "/diplomats-submitted-forms";
    public static DIPLOMATS_SUBMITTED_FORMS_PENDING = "/diplomats-submitted-forms/peding";

}
