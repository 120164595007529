import {combineReducers} from 'redux';
import {connectRouter} from "connected-react-router";
// import {notificationsReducer} from "./notifications/duck";
// import {headerReducer} from "./header/duck";
import {reducer as oidcReducer} from "redux-oidc";
// import {rolesReducers} from './domain/roles'
// import {searchReasonsReducers} from './domain/searchReasons'

const rootReducer = (history:any) => {

    return combineReducers(
        {
            oidc: oidcReducer,
            router: connectRouter(history),
            // notifications: notificationsReducer,
            // header: headerReducer,
            // roles: rolesReducers,
            // searchReasons: searchReasonsReducers
        })
};

export default rootReducer;
