import { StreetCreationDTO } from "./StreetCreationDTO";

export class AddressCreationDTO {
    streetDTO:StreetCreationDTO;
    streetNumber:string;
    snMvrKey:string | null;
    entranceNumber: string | null;
    apartmentNumber: string | null;
    constructor(json:AddressCreationDTO){
        this.streetDTO =  new StreetCreationDTO(json.streetDTO);
        this.streetNumber  = json.streetNumber;
        this.snMvrKey = json.snMvrKey;
        this.entranceNumber = json.entranceNumber;
        this.apartmentNumber = json.apartmentNumber;
    }
}
