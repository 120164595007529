import { RegistrationTypes } from "../../constants/RegistrationTypes";
import { StorageService } from "../../storage/StorageService";
import {NomenclatureConstants} from "../../constants/NomenclatureConstants";
export function DiplomatsUserData(values:any, correctionOfPersonData? : boolean | null){
const diplomatsCreationDTO = values;

const data =  {
processedBy:StorageService.getJWTPayload()?.sub,
registrationTypeMvrKey: RegistrationTypes.diplomat,
embg : diplomatsCreationDTO.embg ? diplomatsCreationDTO.embg : null,
foreignCitizenData:{
  personDataCreationDTO:{
    citizenshipCreationDTO:{
        mvrKey:diplomatsCreationDTO?.citizenship?.key,
        statKey:null,
        isoName:null,
        iso2:null,
        iso3:null,
        nameSq:null,
        nameEn:null,
        countryDTO:{
            mvrKey:"",
            isoName:null,
            iso2:null,
            iso3:null,
            nameSq:null,
            nameEn:null
            }
    },
    otherCitizenshipCreationDTO:{
        mvrKey:"",
        statKey:null,
        isoName:null,
        iso2:null,
        iso3:null,
        nameSq:null,
        nameEn:null,
        countryDTO:{
            mvrKey:"",
            isoName:null,
            iso2:null,
            iso3:null,
            nameSq:null,
            nameEn:null
            }
    },
    existingMacEmbg:diplomatsCreationDTO.existingMacEmbg,
    embg:diplomatsCreationDTO?.embg,
    firstName:diplomatsCreationDTO?.firstName,
    lastName:diplomatsCreationDTO?.lastName,
    genderMvrKey:diplomatsCreationDTO?.genderMvrKey,
    birthDate:diplomatsCreationDTO?.birthDate,
    birthPlaceDTO:{
        mvrKey:diplomatsCreationDTO?.abroadBirthPlace ? diplomatsCreationDTO?.placeOfBirthAbroad :  diplomatsCreationDTO?.inRSMBirthPlacePlace?.key,
        countryDTO:{
            mvrKey:diplomatsCreationDTO?.abroadBirthPlace ? diplomatsCreationDTO?.abroadBirthPlace?.key : NomenclatureConstants.macedoniaMvrKey,
            isoName:null,
            iso2:null,
            iso3:null,
            nameSq:null,
            nameEn:null
            },
            municipalityName:null,
            municipalityMvrKey:diplomatsCreationDTO?.inRSMBirthMunicipality?.key,
            name:null,
            nameSq:null,
            nameEn:null
    },
    parentName:diplomatsCreationDTO?.parentName,
    addressDTO:{
        streetDTO:{
            placeDTO:{
        mvrKey:diplomatsCreationDTO?.livingPlaceRSM?.key,
        countryDTO:{
            mvrKey: NomenclatureConstants.macedoniaMvrKey,
            isoName:null,
            iso2:null,
            iso3:null,
            nameSq:null,
            nameEn:null
            },
            municipalityName:"",
            municipalityMvrKey:diplomatsCreationDTO?.livingMunicipalityRSM?.key,
            name:null,
            nameSq:null,
            nameEn:null
        },
        mvrKey:diplomatsCreationDTO?.livingStreetRSM?.key,
        name:null,
        nameSq:null,
        nameEn:null
        },
        streetNumber:diplomatsCreationDTO?.livingStreetNumberRSM,
        snMvrKey:null,
        entranceNumber: diplomatsCreationDTO?.livingApartmentEntranceRSM,
        apartmentNumber: diplomatsCreationDTO?.livingApartmentRSM
    },
    numberOfAdultFamilyMembers:null,
    numberOfForeignChildren:null,
    numberOfDomesticChildren:null,
    ageGroupMvrKey:null,
    addressDateFrom: diplomatsCreationDTO?.addressDateFrom,
    firstNameCyrillic: diplomatsCreationDTO?.firstNameCyrillic,
    lastNameCyrillic: diplomatsCreationDTO?.lastNameCyrillic,
    parentSurname: diplomatsCreationDTO?.parentSurname,
    motherName: diplomatsCreationDTO?.motherName,
    motherSurname:diplomatsCreationDTO?.motherSurname,
  },
  archiveNumber: diplomatsCreationDTO?.archiveNumber,
  archiveDate: diplomatsCreationDTO?.archiveDate,
  dossierNumber: diplomatsCreationDTO?.dossierNumber,
  passportCreationDTO:{
    passportTypeMvrKey:diplomatsCreationDTO?.passportTypeMvrKey,
    countryDTO:{
            mvrKey:diplomatsCreationDTO?.countryPassport?.key,
            isoName:null,
            iso2:null,
            iso3:null,
            nameSq:null,
            nameEn:null
            },
    passportNumber:diplomatsCreationDTO?.identityCardNumber
  },
  approvedStayDate: diplomatsCreationDTO?.approvalDate,
  restrictedStayDataCreationDTOS:[{
  startDateOfStay:diplomatsCreationDTO?.startDateStay,
  finalDateOfStay:diplomatsCreationDTO?.endDateStay,
  extendedDateOfStay:diplomatsCreationDTO?.dateOfExtension,
  stoppedDateOfStay:null,
  proposer:null,
  restrictedStayPlaceMvrKey:"",
}],
resolutionCreationDTO:{
  dateHanded: diplomatsCreationDTO?.dateOfDeliveryOfDecision,
  opinionPositive: diplomatsCreationDTO?.decision,
  resolutionFile: diplomatsCreationDTO?.fileDecision !=="" ? diplomatsCreationDTO?.fileDecision : null,
  entranceRestrictionDateFrom: "",
  entranceRestrictionDateTo: "",
  evidencyDate: diplomatsCreationDTO?.dateEvidented,
  isChecked: null,
  },
  },
parentRegistrationId:null,
oldRegistrationId: null,
correctionOfPersonData: correctionOfPersonData,
active: true,
submitted: false,
workingVersionId: "",
id:""
}
return data;
}
