import { ComplaintDecisionDTO } from "./ComplaintDecisionDTO";

export class ComplaintDTO {
    id:number;
    dateCreated:Date | string;
    dateModified: Date | string;
    decisionId:number;
    complaintNumber:string;
    complaintDate?: Date | null | undefined;
    complaintDecision?: ComplaintDecisionDTO;
    constructor(json:ComplaintDTO){
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified  = json.dateModified;
        this.decisionId = json.decisionId;
        this.complaintNumber = json.complaintNumber;
        this.complaintDate = json.complaintDate ? new Date(json.complaintDate) : undefined;
        this.complaintDecision = json.complaintDecision ? new ComplaintDecisionDTO(json.complaintDecision) : undefined;
    }
}