export class RegistrationStatusDTO {
id:number;
key:string;
name:string;
nameSq:string;
nameEn:string;
displayName:string;
constructor(json:RegistrationStatusDTO){
    this.id = json.id;
    this.key = json.key;
    this.name = json.name;
    this.nameSq = json.nameSq;
    this.nameEn = json.nameEn;
    this.displayName = json.displayName;
}
}