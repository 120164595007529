import {Guard} from "./Guard";
import {StorageService} from "../../storage/StorageService";
import {Navigate} from "react-router-dom";
import {Routes} from "../Routes";
import {AuthService} from "../../services/AuthService";
import {EnvironmentUtils} from "../../environment/EnvironmentUtils";

export class InternalAuthGuard extends Guard {
    constructor() {
        super((
            <Navigate replace={true} to={Routes.INTERNAL_LOGIN}/>
        ));
    }

    async check(): Promise<boolean> {
        if (!EnvironmentUtils.internalAuth()) {
            return true;
        }

        if (!StorageService.hasInternalToken()) {
            return false;
        }

        try {
            await AuthService.authorize(StorageService.getInternalToken()!);
            return true;
        } catch (e) {
            StorageService.clearInternalAuthToken()
            return false;
        }
    }
}
