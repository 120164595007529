import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";

interface FullPageLoadingCircleProps {
    loading: boolean,
}

export function FullPageLoadingCircle(props: FullPageLoadingCircleProps) {
    return (
        props.loading ?
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={props.loading}
            >
                <CircularProgress size={60} style={{color: "#3f51b5"}}/>
            </Backdrop> : <></>
    )
}
