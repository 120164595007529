import React, {Component} from 'react';
import userManager from "../userManager";
import {properties} from "../../../config/properties";

class LogoutPage extends Component {

    componentDidMount() {
        //userManager.signinRedirect(); // poradi nekoja pricina ne ja krati sso sesijata. Ako se inicira i redirektira na nezastiten link raboti
        //mozebi so popup i callback ke raboti
        //vaka racno funkcionira
        userManager.removeUser();
        userManager.clearStaleState();
        userManager.metadataService.getEndSessionEndpoint().then((signouturl)=>{
            // Keycloak 14.0.0
            // window.location = signouturl+"?post_logout_redirect_uri="+encodeURIComponent(userManager.settings.post_logout_redirect_uri);

            // Keycloak 21.0.1
            window.location = signouturl+"?client_id="+properties.oidcClientId+"&post_logout_redirect_uri="+encodeURIComponent(userManager.settings.post_logout_redirect_uri);
        });
    }

    render() {
        return (
            <div>
                Logging out
            </div>
        );
    }
}

export default LogoutPage;
