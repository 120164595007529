import { AnbAnswerDTO } from "./AnbAnswerDTO";
import { ProcedureTypeDTO } from "./ProcedureTypeDTO";

export class AsylumDataDTO {
    isInformedAboutLegalHelp?: boolean;
    requestedSameGenderProcessor?: boolean;
    requestReason?: string;
    dateFilled?: string;
    anbAnswer?:AnbAnswerDTO;
    procedureType?: ProcedureTypeDTO

    constructor(json:AsylumDataDTO){
        this.isInformedAboutLegalHelp = json.isInformedAboutLegalHelp;
        this.requestedSameGenderProcessor = json.requestedSameGenderProcessor;
        this.requestReason = json.requestReason;
        this.dateFilled = json.dateFilled;
        this.anbAnswer = json.anbAnswer ? new AnbAnswerDTO(json.anbAnswer) : undefined;;
        this.procedureType = json.procedureType ? new ProcedureTypeDTO(json.procedureType) : undefined;;

    }
}
