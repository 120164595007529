export class OrganizationalUnitSimplifiedResponse {
    id: string;
    name: string;
    withSubOU: boolean;

    constructor(json:OrganizationalUnitSimplifiedResponse){
        this.id = json.id;
        this.name = json.name;
        this.withSubOU = json.withSubOU;
    }
}