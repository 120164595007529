export class RegistrationOrEmbgHistoryActionDTO{

    embg:string;
    dateModified : Date | undefined;
    action : string;
    reason:string;

    constructor(json:RegistrationOrEmbgHistoryActionDTO){
        this.embg = json.embg;
        this.action = json.action;
        this.reason = json .reason
        this.dateModified = json.dateModified ? new Date(json.dateModified) : undefined;
    }

}