import { CircularProgress } from "@mui/material";
import {ReactElement, useEffect, useState} from "react";
import {Guard} from "./Guard";

class GuardedRouteProps {
    constructor(
        public component: ReactElement,
        public guards: Guard[],
    ) {
    }
}

export function GuardedRoute(props: GuardedRouteProps) {
    const [fallback, setFallback] = useState<ReactElement | null>(null);
    const [checked, setChecked] = useState<boolean>(false);

    async function checkAndBuildFallback(): Promise<void> {
        for (const guard of props.guards) {
            if (!(await guard.check())) {
                setChecked(true);
                setFallback(guard.fallback);
                return;
            }
        }

        setFallback(null);
        setChecked(true);
    }

    useEffect(() => {
        checkAndBuildFallback().then(_ => {})
    }, [checkAndBuildFallback]);

    if (!checked) {
        return (
            <CircularProgress />
        );
    }

    return (
        fallback ? fallback : props.component
    );
}
