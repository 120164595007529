import { makeStyles } from "@mui/styles";

export default makeStyles((theme:any) => ({
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    width:"200px"
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '20%',
    left: '70%',
    width:"100%"
    
  },
  dropdown: {
    maxHeight: 250,
    overflowY: 'auto',
  },
}));
