import {properties} from "../../config/properties";


export const userManagerConfig = {
    client_id: properties.oidcClientId,
    redirect_uri: properties.oidcRedirectUrl,
    response_type: 'token id_token',
    scope: properties.oidcScope,
    authority: properties.oidcAuthority,
    silent_redirect_uri: properties.oidcSilentRenew,
    post_logout_redirect_uri: properties.oidcLogoutRedirect,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
};
