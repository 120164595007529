import { CountryCreationDTO } from "./CountryCreationDTO";

export class PassportCreationDTO {
    passportTypeMvrKey:string;
    countryDTO?:CountryCreationDTO;
    passportNumber:string;
    passportDate?: Date | string;
    validUntil?: Date | string;
    constructor(json:PassportCreationDTO){
        this.passportTypeMvrKey = json.passportTypeMvrKey;
        this.countryDTO = json.countryDTO ? new CountryCreationDTO(json.countryDTO) : undefined;
        this.passportNumber =  json.passportNumber;
        this.passportDate = json.passportDate;
        this.validUntil = json.validUntil;
    }
}
