import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@mui/material";
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { DatePickerField } from "../../applications/formFields/DatePickerFields";
import InputField from "../../applications/formFields/InputField";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { NomenclatureService } from "../../../services/NomenclatureService";
import { NomenclatureEntryDTO } from "../../../models/nomenclatures/NomenclatureEntryDTO";
import SelectField from "../../applications/formFields/SelectField";
import {FieldArray, useFormikContext} from "formik"
import { useParams } from "react-router-dom";
import { PaginatedSelect } from "../../../components/PaginatedSelect";
import { strings } from "../../../localization/Localization";
import { NomenclatureConstants } from "../../../constants/NomenclatureConstants";
import InputFieldCyrillic from "../../applications/formFields/InputFieldCyrillic";
export function DataFormAsylumTwo(props:any) {
  interface FormElement {
    id: string;
    data: JSX.Element;
  }

  const [professionalTrainings, setProfessionalTrainings] = useState<NomenclatureEntryDTO[]>([]);
  const [keyProfessionalTraining, setKeyProfessionalTraining] = useState<string>("professional_trainings");
  const [countries,setCountries] = useState<NomenclatureEntryDTO[]>([])
  const [keyCountry, setKeyCountry] = useState<string>("country");
  const [places, setPlaces] = useState<NomenclatureEntryDTO[]>([]);
  const [keyPlace, setKeyPlace] = useState<string>("place");
  const formikProps = useFormikContext<any>()
  let {id} = useParams();
  const isAddMode = !id;
  
  const {
    formField: {
      nameOfTheInstitution,
      education,
      placeWhereCompleted,
      yearOfCompletion,
      employement,
      from,
      to,
      postAndCompany,
      state,
      place,
      employementList
    }
  } = props;

  async function getAllEntriesForNomenclatureProfessionalTraining(): Promise<void> {
    const professional_trainings = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyProfessionalTraining);
    setProfessionalTrainings(professional_trainings)
  }

  async function getAllEntriesForNomenclatureCountry(): Promise<void> {
    const countries = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyCountry);
    setCountries(countries)
  }

  async function getAllEntriesForNomenclaturePlace(): Promise<void> {
    const places = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyPlace);
    setPlaces(places)
  }


  useEffect(()=>{
    getAllEntriesForNomenclatureProfessionalTraining();
    getAllEntriesForNomenclatureCountry();
    getAllEntriesForNomenclaturePlace();
  },[])

  useEffect(()=>{

  },[employementList])

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {/* Grid 1 */}
        <Grid item xs={12} sm={4} md={6}>
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 ">
            <Typography variant="subtitle1" fontWeight="500" gutterBottom>
              {education.id + ". " + education.label}
            </Typography>
            <SelectField className="pb-3"
              name={education.name}
              label={education.placeholder}
              data={professionalTrainings}
              read={props.hasAuthority ? false : true}
            />
            <Typography variant="subtitle1" fontWeight="500" gutterBottom>
              {nameOfTheInstitution.id + ". " + nameOfTheInstitution.label}
            </Typography>
            <InputField name={nameOfTheInstitution.name} label={nameOfTheInstitution.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true}/>
            <Typography variant="subtitle1" fontWeight="500" gutterBottom>
              {placeWhereCompleted.id + ". " + placeWhereCompleted.label}
            </Typography>
            <InputField name={placeWhereCompleted.name} label={placeWhereCompleted.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true}/>
            <Typography variant="subtitle1" fontWeight="500" gutterBottom>
              {yearOfCompletion.id + ". " + yearOfCompletion.label}
            </Typography>
            <DatePickerField
              name={yearOfCompletion.name}
              label={yearOfCompletion.placeholder}
              className="pb-3"
              read={props.hasAuthority ? false : true}
              //maxDate={true}
            />
          </CenteredCardLayout>
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {employement.id + ". " + employement.label}
                        </Typography>
                        <FieldArray
                        name="employementList"
                        render={({ remove, push, form: { values, setFieldValue } }) => (
                          <div>
                            {values.employementList && values.employementList.length > 0 ? (
                              values.employementList.map((form:any, index:any) => (
         <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 " key={index}>
            <Accordion className="sm:mt-14 mb-4" key={index} defaultExpanded={!isAddMode? true : false}>
               <AccordionSummary
                aria-controls="panel1a-content"
                className="bg-white sm:mt-8"
                expandIcon={<ExpandMoreIcon />}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                  {employement.label}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="bg-white">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "49%" }}>
                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                      {"Од"}
                    </Typography>
                    <DatePickerField
                      name={`employementList[${index}].from`}
                      label={"Од"}
                      className="pb-3"
                      read={props.hasAuthority ? false : true}
                    />
                  </div>
                  <div style={{ width: "49%" }}>
                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                      {"До"}
                    </Typography>
                    <DatePickerField
                      name={`employementList[${index}].to`}
                      label={"До"}
                      className="pb-3"
                      minDate={formikProps.values.employementList[index].from}
                      read={props.hasAuthority ? false : true}
                    />
                  </div>
                </div>
                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                  {"Име на компанијата/фирмата"}
                </Typography>
                <InputField name={`employementList[${index}].institution`} label={"Име на компанијата/фирмата"} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                  {"Држава"}
                </Typography>
                <PaginatedSelect<NomenclatureEntryDTO>
                    value={formikProps.values.employementList[index].state !== ""? formikProps.values.employementList[index].state : undefined}
                    onChange={(value) => {
                      formikProps.setFieldValue(`employementList[${index}].state`, value);
                    }}
                    label={strings.country}
                    valueMapper={(item) => item?.name || ""}
                    keyMapper={(item) => item.id.toString()}
                    itemMapper={(item) => <>{item.name}</>}
                    labelMapper={(item) => item.name}
                    dataFetcher={(page, size, filter) => {
                      return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                        page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                      );
                    }}
                    name={`employementList[${index}].state`}
                    className="pb-3"
                    readOnly={props.hasAuthority ? false : true}
                  />

                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                  {'Место'}
                </Typography>
                <InputField  name={`employementList[${index}].place`} label={"Место"} fullWidth readOnly={props.hasAuthority ? false : true} />
              </AccordionDetails>
            </Accordion>
            {props.hasAuthority && <Button className="mb-4" variant="contained" color="error" onClick={() => remove(index)}>Избриши</Button>}
          </CenteredCardLayout>
                              ))
                            ) : (
                              <div>Нема податоци за вработување.</div>
                            )}
                            {props.hasAuthority && <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                push({ from: "", to:"", institution:"", place:"", state:"" });
                              }}
                            >
                              +
                            </Button>}
                            </div>
                        )}
                      />
                    <br />
                    { props.employmentRegionForm && props.employmentRegionForm.map((form: any) => (
                        <div key={form.id}>
                            {form.data}
                        </div>
                    ))}
                    <br />
        </Grid>
      </Grid>
    </>
  )
}
