import {NomenclatureDTO} from "../models/nomenclatures/NomenclatureDTO";
import {Page} from "../components/Page";
import axiosInstance from "../network/AxiosInstance";
import {NomenclatureEntryDTO} from "../models/nomenclatures/NomenclatureEntryDTO";
import {NomenclatureEntryCreationDTO} from "../models/nomenclatures/NomenclatureEntryCreationDTO";
import { AuthService } from "./AuthService";

export class NomenclatureService {
    static async findAllNomenclatureEntriesForNomenclatureForAdmin(page: number, size: number, key: string, name: string, nameSq: string,
                                                           nameEn: string, active: boolean | null | undefined, nomenclatureEntryKey: string): Promise<Page<NomenclatureEntryDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString()
        })

        if(active !== null && active !== undefined) {
            params.append("active", active.toString());
        }

        if(nomenclatureEntryKey) {
            params.append("nomenclatureEntryKey", nomenclatureEntryKey);
        }

        if(name) {
            params.append("name", name);
        }

        if(nameSq) {
            params.append("nameSq", nameSq);
        }

        if(nameEn) {
            params.append("nameEn", nameEn);
        }

        const response = await axiosInstance.get<Page<NomenclatureEntryDTO>>(
            `${AuthService.PREFIX}/nomenclatures/${key}/entries-paged`, {params: params}
        )

        const data = response.data;

        return new Page<NomenclatureEntryDTO>(
            data.content.map(nomenclatureEntry => new NomenclatureEntryDTO(nomenclatureEntry)),
            data.totalElements
        )
    }

    static async findAllNomenclatureEntriesForNomenclature(page: number, size: number, key: string, name: string, nameSq: string,
                                                           nameEn: string, active: boolean | null | undefined, nomenclatureEntryKey: string, filterOutMacedonianKey? : boolean): Promise<Page<NomenclatureEntryDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString()
        })

        if(active !== null && active !== undefined) {
            params.append("active", active.toString());
        }

        if(nomenclatureEntryKey) {
            params.append("nomenclatureEntryKey", nomenclatureEntryKey);
        }

        if(name) {
            params.append("searchValue", name);
        }

        if(nameSq) {
            params.append("nameSq", nameSq);
        }

        if(nameEn) {
            params.append("nameEn", nameEn);
        }
        if(filterOutMacedonianKey) {
            params.append("filterOutMacedonianKey", filterOutMacedonianKey.toString());
        }

        const response = await axiosInstance.get<Page<NomenclatureEntryDTO>>(
            `${AuthService.PREFIX}/nomenclatures/${key}/entries/all-paged`, {params: params}
        )

        const data = response.data;

        return new Page<NomenclatureEntryDTO>(
            data.content.map(nomenclatureEntry => new NomenclatureEntryDTO(nomenclatureEntry)),
            data.totalElements
        )
    }

    static async getAllNomenclatures(key: string, name: string, nameSq: string, nameEn: string) : Promise<Array<NomenclatureDTO>> {
        const params = new URLSearchParams({})

        if(key) {
            params.append("key", key);
        }

        if(name) {
            params.append("name", name);
        }

        if(nameSq) {
            params.append("nameSq", nameSq);
        }

        if(nameEn) {
            params.append("nameEn", nameEn);
        }

        const response = await axiosInstance.get<Array<NomenclatureDTO>>(
            `/rest/nomenclatures/`, {params: params}
        )

        const data = response.data;

        return data.map(nomenclature => new NomenclatureDTO(nomenclature))
    }

    static async getNomenclatureById(id: number): Promise<NomenclatureDTO> {
        const response = await axiosInstance.get<NomenclatureDTO>(`/rest/nomenclatures/${id}`,);
        const data = response.data;

        return new NomenclatureDTO(data);
    }

    static async createNomenclature(nomenclature: NomenclatureEntryCreationDTO, id: number): Promise<NomenclatureEntryDTO> {
        const response = await axiosInstance.post<NomenclatureEntryDTO>(
            `/rest/nomenclatures/${id}/entries`, nomenclature
        )

        const data = response.data;
        return new NomenclatureEntryDTO(data);
    }

    static async editNomenclatureEntry(nomenclature: NomenclatureEntryCreationDTO, id: number) : Promise<NomenclatureEntryDTO>{
        const response = await axiosInstance.put<NomenclatureEntryDTO>(
            `/rest/nomenclatures/entries/${id}`, nomenclature,
        )

        const data = response.data;
        return new NomenclatureEntryDTO(data);
    }

    static async deleteNomenclatureEntry(id: number): Promise<void>{
        await axiosInstance.delete<NomenclatureEntryDTO>(`/rest/nomenclatures/entries/${id}`, {});
    }

    static async getNomenclatureEntryById(id: number): Promise<NomenclatureEntryDTO> {
        const response = await axiosInstance.get<NomenclatureEntryDTO>(`/rest/nomenclatures/entries/${id}`,);
        const data = response.data;

        return new NomenclatureEntryDTO(data);
    }

    static async getNomenclatureEntryByKeyAndNomenclatureKey(nomenclatureEntryKey : string, nomenclatureKey : string): Promise<NomenclatureEntryDTO> {
        const response = await axiosInstance.get<NomenclatureEntryDTO>(`/rest/nomenclatures/entries/${nomenclatureKey}/${nomenclatureEntryKey}`,);
        const data = response.data;

        return new NomenclatureEntryDTO(data);
    }


    static async getAllNomenclatureEntriesForNomenclatureList(key: string | undefined, active?:boolean, filter?: string | undefined, municipalityId?: string | undefined, infrastructureObjectType?:number | undefined, searchValue?:string | undefined): Promise<Array<NomenclatureEntryDTO>> {
    const response = await axiosInstance.get<Array<NomenclatureEntryDTO>>(`${AuthService.PREFIX}/nomenclatures/${key}/entries`, {params:{active: true, filter, municipalityId, infrastructureObjectType, searchValue}});
    const data = response.data;
    return data.map((entry) => new NomenclatureEntryDTO(entry));
   }

   static async findAllNomenclatureEntriesForNomenclatureList(key: string | undefined, active?:boolean | undefined,filter?: string | undefined): Promise<Array<NomenclatureEntryDTO>> {
    const response = await axiosInstance.get<Array<NomenclatureEntryDTO>>(`${AuthService.PREFIX}/nomenclatures/${key}/entries`, {params:{active, filter, }});
    const data = response.data;
    return data.map((entry) => new NomenclatureEntryDTO(entry));
   }


   static async findAllNomenclatureEntriesForNomenclaturePageable(page:number, size:number,key:string | undefined, active?:boolean | undefined, filter?:string | undefined, searchValue?:string | undefined, filterOutMacedonianKey? : boolean):Promise<Page<NomenclatureEntryDTO>>{
    const response = await axiosInstance.get<Page<NomenclatureEntryDTO>>(`${AuthService.PREFIX}/nomenclatures/${key}/entries/all-paged`, {params: {page, size,active, filter,searchValue,filterOutMacedonianKey}});
    const data = response.data;
        return new Page<NomenclatureEntryDTO>(
            data.content.map(nomenclatureEntry => new NomenclatureEntryDTO(nomenclatureEntry)),
            data.totalElements
        )
   }


}
