import {
    Box,
    Button, Checkbox, CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {strings} from "../localization/Localization";
import {NullableDatePicker} from "./NullableDatePicker";
import React, {useEffect, useState} from "react";
import {PassivationCreationDTO} from "../models/foreign_citizens/PassivationCreationDTO";
import {Dayjs} from "dayjs";
import {NomenclatureEntryDTO} from "../models/nomenclatures/NomenclatureEntryDTO";
import {NomenclatureService} from "../services/NomenclatureService";
import {NomenclatureConstants} from "../constants/NomenclatureConstants";
import {PassivationService} from "../services/PassivationService";
import {ErrorHandler} from "../utils/ErrorHandler";
import {showErrorDialog, showSuccessDialog} from "../common/Dialogues";
import {useNavigate, useParams} from "react-router-dom";
import {ForeignCitizenService} from "../services/registrations/ForeignCitizenService";
import {Routes} from "../router/Routes";
import {PageTable} from "./table/PageTable";
import {LightTooltip} from "./LightTooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {RegistrationReducedDTO} from "../models/registration/RegistrationReducedDTO";
import CancelIcon from "@mui/icons-material/Cancel";
import {RegistrationDTO} from "../models/foreign_citizens/RegistrationDTO";
import {RegistrationStatuses} from "../constants/RegistrationStatuses";
import {OnHoldCreationDTO} from "../models/OnHoldCreationDTO";
import {PassivationRegistrationCreationDTO} from "../models/PassivationRegistrationCreationDTO";
import useStyles from "../pages/ShortTermStayForm/styles/styles";
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

interface IProps {
    open: boolean,
    handleClose: (actionPerformed: boolean) => void,
    registrationData?: RegistrationDTO,
    isAsylum?: boolean,
    isActivate?: boolean,
    isPasivise?: boolean,
    isOnHold?: boolean,
    isForeign?: boolean,
    isPassivateRequest?: boolean,
}

export function EMBGModal({open, handleClose, registrationData, isAsylum, isActivate, isPasivise, isOnHold, isForeign, isPassivateRequest} : IProps) {
    const [passivateReasons, setPassivateReasons] = useState<NomenclatureEntryDTO[]>([]);
    const [passivateReasonKey] = useState<string>(NomenclatureConstants.passivateReason);
    const [passivisationReasons, setPassivisationReasons] = useState<string>();

    const [onHoldReasonKey] = useState<string>(NomenclatureConstants.onHoldReason);
    const [onHoldReasons, setOnHoldReasons] = useState<NomenclatureEntryDTO[]>();
    const [onHoldReasonsString, setOnHoldReasonsString] = useState<string>();

    const [pasiviseRequestKey] = useState<string>(NomenclatureConstants.passivateRequestReasons);
    const [passivateRequestReasons, setPassivateRequestReasons] = useState<NomenclatureEntryDTO[]>();
    const [passivateRequestReasonsString, setPassivateRequestReasonsString] = useState<string>("");

    const [datePassivate, setDatePassivate] = useState<Dayjs | null>(null);
    const [decisionNumber, setDecisionNumber] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [checked, setChecked] = useState<Array<RegistrationReducedDTO | RegistrationDTO>>([]);
    let {id} = useParams();
    const navigate = useNavigate();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function activateEMBG(registrationIds : Array<number>){
        setIsLoading(true);
        await ForeignCitizenService.activateEMBG(registrationIds).then(() => {
                handleClose(true);
                setChecked([]);
                let embgs: any;
                if(!isAsylum){
                    embgs = registrationData?.embg
                }else{
                    embgs = checked.map((embg: any) => {
                        return embg?.embg;
                    })
                }
                showSuccessDialog(strings.successfullyActivatedEMBG, strings.successfullyActivatedEMBG + " " + embgs, strings.ok).then(_ => {
                    setIsLoading(false);
                    // window.location.reload();
                });
            })
                .catch((e) => {
                    const errorMessage = ErrorHandler.parseErrorMessage(e);
                    showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
                });
    }

    async function passivateRequest(id: number, passivateRequestDTO: PassivationRegistrationCreationDTO){
        setIsLoading(true)
        if(passivateRequestReasonsString)
            await PassivationService.passivateRegistration(id, passivateRequestDTO).then(()=>{
                showSuccessDialog(strings.successfullyPassivateRequest, strings.successfullyPassivateRequest, strings.ok).then(_ => {
                    setIsLoading(false);
                    setPassivateRequestReasonsString("")
                    handleClose(true)
                });
            })
                .catch((e) => {
                    const errorMessage = ErrorHandler.parseErrorMessage(e);
                    showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
                });
    }

    function buildPassivationRequest(): PassivationRegistrationCreationDTO {
        return new PassivationRegistrationCreationDTO({
            passivationRegistrationReasonMvrKey: passivateRequestReasonsString,
        });
    }

    async function onHoldEMBG(onHoldDTO : OnHoldCreationDTO, registrationIds: Array<number>) {
        setIsLoading(true)
        if(onHoldReasonsString)
            await PassivationService.onHoldEMBG(onHoldDTO, registrationIds).then(() => {
                handleClose(true);
                setChecked([]);
                let embgs: any;
                if(!isAsylum){
                    embgs = registrationData?.embg
                }else{
                    embgs = checked.map((embg: any) => {
                        return embg?.embg;
                    })
                }

                showSuccessDialog(strings.success, strings.successOnHold + " " + embgs, strings.ok).then(_ => {
                    setOnHoldReasonsString("");
                    setDecisionNumber("");
                    setDatePassivate(null);
                    setIsLoading(false);
                    // window.location.reload();
                })
            }).catch((error) => {
                const errorMessage = ErrorHandler.parseErrorMessage(error);
                showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
            })
    }

    function buildPassivation(): PassivationCreationDTO {
        return new PassivationCreationDTO({
            decisionNumber: decisionNumber,
            passivationDate: datePassivate,
            passivationReasonMvrKey: passivisationReasons
        });
    }

    async function onPassivateEMBG(passivationCreationDTO : PassivationCreationDTO, registrationIds : Array<number>){
        setIsLoading(false);
        await PassivationService.passivateEMBG(passivationCreationDTO, registrationIds).then(_ => {
            setChecked([]);
            let embgs: any;
            if(!isAsylum){
                embgs = registrationData?.embg
            }else{
                embgs = checked.map((embg: any) => {
                    return embg?.embg;
                })
            }
            showSuccessDialog(strings.success, strings.successPassivate + " " + embgs, strings.ok).then(_ => {
                setOnHoldReasonsString("");
                setDecisionNumber("");
                setDatePassivate(null);
                setIsLoading(false);
                handleClose(true);
                // window.location.reload();
            })
        }).catch((error) => {
            const message = ErrorHandler.parseErrorMessage(error);
            showErrorDialog(strings.error, message, strings.ok).then(_ => {
            });
        })
    }

    // async function markAsDeceased(registrationIds : Array<number>){
    //     setIsLoading(true);
    //     await PassivationService.markAsDeceased(registrationIds).then(() => {
    //         handleClose();
    //         setChecked([]);
    //         let embgs: any;
    //         if(!isAsylum){
    //             embgs = registrationData?.embg
    //         }else{
    //             embgs = checked.map((embg: any) => {
    //                 return embg?.embg;
    //             })
    //         }
    //         showSuccessDialog(strings.successfullyMarkedAsDeceased, strings.successfullyMarkedAsDeceased + " " + embgs, strings.ok).then(_ => {
    //             setIsLoading(false);
    //             // window.location.reload();
    //         });
    //     })
    //         .catch((e) => {
    //             const errorMessage = ErrorHandler.parseErrorMessage(e);
    //             showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
    //         });
    // }

    function buildOnHold(): OnHoldCreationDTO {
        return new OnHoldCreationDTO({
            decisionNumber: decisionNumber,
            onHoldDate: datePassivate,
            onHoldReasonMvrKey: onHoldReasonsString
        });
    }

    function handleChangeDatePicker(date: Dayjs | null) {
        setDatePassivate(date)
    }

    function handleDecisionNumber(event: React.ChangeEvent<HTMLInputElement>){
        setDecisionNumber(event.target.value)
    }

    function handlePassivisationReasons(event: SelectChangeEvent){
        setPassivisationReasons(event.target.value)
    }

    function handleOnHoldReasons(event: SelectChangeEvent){
        setOnHoldReasonsString(event.target.value)
    }

    function handlePassivateRequestReasons(event: SelectChangeEvent){
        setPassivateRequestReasonsString(event.target.value)
    }

    useEffect(()=>{
        async function getAllPassivateReasonNomenclatures(): Promise<void> {
            const passivateReasons = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(passivateReasonKey);
            setPassivateReasons(passivateReasons)
        }

        async function getAllOnHoldReasonNomenclatures(): Promise<void> {
            const onHoldReasons = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(onHoldReasonKey);
            setOnHoldReasons(onHoldReasons)
        }

        async function getAllPassivateRequestReasonsNomenclatures(): Promise<void> {
            const passivateRequestReasons = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(pasiviseRequestKey);
            setPassivateRequestReasons(passivateRequestReasons)
        }

        getAllPassivateReasonNomenclatures().then((_) => { });
        getAllOnHoldReasonNomenclatures().then((_) => { });
        getAllPassivateRequestReasonsNomenclatures().then((_) => { });
    },[registrationData])

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage)
    }
    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const registrationRows = [
        {
            id: "checkbox",
            width: "3%"
        },
        {
            id: "embg",
            label: strings.embg,
            width: "5%"
        },
        {
            id: "firstName",
            label: strings.firstName,
            search: false,
            type: "text",
            variant: "standard",
            width: "10%"
        },
        {
            id: "lastName",
            label: strings.lastName,
            search: false,
            type: "text",
            variant: "standard",
            width: "10%"
        },
        {
            id: "registrationStatus",
            label: strings.statusEmbgShort,
            search: false,
            type: "text",
            variant: "standard",
            width: "10%"
        },
        {
            id: "holder",
            label: strings.claimHolder,
            width: "3%"
        },
    ]

    let registrationValues: any = [
        {
            key: registrationData?.id,
            id: registrationData?.id,
            values: [
                {
                    
                    type: 'text',
                    name: 'checkbox',
                    value: <Checkbox
                    checked={(isActivate && ((registrationData ? isChecked(registrationData) : false) || registrationData?.registrationStatus?.key === RegistrationStatuses.ACTIVE))
                      || (isPasivise && ((registrationData ? isChecked(registrationData) : false) || registrationData?.registrationStatus?.key === RegistrationStatuses.PASSIVE))
                      || (isOnHold && ((registrationData ? isChecked(registrationData) : false) || registrationData?.registrationStatus?.key === RegistrationStatuses.ON_HOLD)) 
                      || false
                    }
                    onClick={() => {
                      if (!registrationData) {
                        return;
                      }
                  
                      if (isChecked(registrationData)) {
                        setChecked(checked.filter(item => item.id !== registrationData.id));
                      } else {
                        setChecked([
                          ...checked,
                          registrationData,
                        ]);
                      }
                    }}
                    disabled={
                      (isActivate && (registrationData?.registrationStatus?.key === RegistrationStatuses.ACTIVE || registrationData?.registrationStatus?.key === RegistrationStatuses.PASSIVE || !registrationData?.embg))
                      || (isPasivise && (registrationData?.registrationStatus?.key === RegistrationStatuses.PASSIVE || registrationData?.registrationStatus?.key === RegistrationStatuses.ON_HOLD || registrationData?.registrationStatus === undefined))
                      || (isOnHold && (registrationData?.registrationStatus?.key === RegistrationStatuses.ON_HOLD || registrationData?.registrationStatus?.key === RegistrationStatuses.PASSIVE || registrationData?.registrationStatus === undefined))
                      || false
                    }
                  />
                },
                {
                    type: 'text',
                    name: 'embg',
                    value: <LightTooltip className={'tableTooltip'} title={registrationData?.embg}><span>{registrationData?.embg}</span></LightTooltip>,
                },
                {
                    type: 'text',
                    name: 'firstName',
                    value: <LightTooltip className={'tableTooltip'} title={registrationData?.foreignCitizen?.personData?.firstName}><span>{registrationData?.foreignCitizen?.personData?.firstName}</span></LightTooltip>,
                },
                {
                    type: 'text',
                    name: 'lastName',
                    value: <LightTooltip className={'tableTooltip'} title={registrationData?.foreignCitizen?.personData?.lastName}><span>{registrationData?.foreignCitizen?.personData?.lastName}</span></LightTooltip>,
                },
                {
                    type: 'text',
                    name: 'registrationStatus',
                    value: <LightTooltip className={'tableTooltip'} title={registrationData?.registrationStatus?.displayName}><span>{registrationData?.registrationStatus?.displayName}</span></LightTooltip>,
                },
                {
                    type: 'text',
                    name: 'holder',
                    value: <CheckCircleIcon />
                }
            ]
        }

    ]

    let registrationChildValues = registrationData?.childRegistrations?.map((registration) => {
        const values = []
            values.push({
                type: 'text',
                name: 'checkbox',
                value: <Checkbox
                checked={
                  (isActivate && (isChecked(registration) || registration.registrationStatus?.key === RegistrationStatuses.ACTIVE))
                  || (isPasivise && (isChecked(registration) || registration?.registrationStatus?.key === RegistrationStatuses.PASSIVE))
                  || (isOnHold && (isChecked(registration) || registration.registrationStatus?.key === RegistrationStatuses.ON_HOLD))
                  || false
                }
                onClick={() => {
                  if (isChecked(registration)) {
                    setChecked(checked.filter(item => item.id !== registration.id));
                  } else {
                    setChecked([...checked, registration]);
                  }
                }}
                disabled={
                  (isActivate && (registration?.registrationStatus?.key === RegistrationStatuses.ACTIVE || registration?.registrationStatus?.key === RegistrationStatuses.PASSIVE || !registration?.embg))
                  || (isPasivise && (registration?.registrationStatus?.key === RegistrationStatuses.PASSIVE || registration?.registrationStatus?.key === RegistrationStatuses.ON_HOLD || registration?.registrationStatus === undefined))
                  || (isOnHold && (registration?.registrationStatus?.key === RegistrationStatuses.ON_HOLD || registration?.registrationStatus?.key === RegistrationStatuses.PASSIVE || registration?.registrationStatus === undefined))
                  || false
                }
              />
            })

        values.push({
                type: 'text',
                name: 'embg',
                value: <LightTooltip className={'tableTooltip'} title={registration?.embg}><span>{registration?.embg}</span></LightTooltip>,
            },
            {
                type: 'text',
                name: 'firstName',
                value: <LightTooltip className={'tableTooltip'} title={registration?.foreignCitizen?.personData?.firstName}><span>{registration?.foreignCitizen?.personData?.firstName}</span></LightTooltip>,
            },
            {
                type: 'text',
                name: 'lastName',
                value: <LightTooltip className={'tableTooltip'} title={registration?.foreignCitizen?.personData?.lastName}><span>{registration?.foreignCitizen?.personData?.lastName}</span></LightTooltip>,
            },
            {
                type: 'text',
                name: 'registrationStatus',
                value: <LightTooltip className={'tableTooltip'} title={registration?.registrationStatus?.displayName}><span>{registration?.registrationStatus?.displayName}</span></LightTooltip>,
            },
        )
        if (registration instanceof RegistrationReducedDTO) {
            values.push({
                type: 'text',
                name: 'holder',
                value: <CancelIcon />
            });
        }

        return {
            key: registration?.id,
            id: registration?.id,
            values: values,
        };
    })

    function isChecked(registration: RegistrationReducedDTO | RegistrationDTO): boolean {
        return checked.find(item => item.id === registration.id) !== undefined;
    }

    registrationValues.push(...registrationChildValues ?? []);
    return (
        <>
            <Modal
                open={open}
    onClose={() => handleClose(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box sx={style}>
        <Typography variant={'h6'} className = 'mb-3'>
            {((isPasivise && isForeign) && strings.passivationForm) ||
            (isPasivise && isAsylum && strings.passivationFormAsylum) ||
            (isActivate && strings.activationForm) ||
            (isOnHold && strings.onHoldForm) ||
            ((isPassivateRequest && (isForeign || isAsylum)) && strings.passivateRequest)}
        </Typography>
        <Typography fontSize={"16px"} className = 'mb-3'>
            {(isPasivise && isForeign && strings.thisEmbg + registrationData?.embg + strings.willbePassivated)
            || (isActivate && isForeign && strings.thisEmbg + registrationData?.embg + strings.willBeActivated)
            || (isOnHold && isForeign && strings.thisEmbg + registrationData?.embg + strings.willBeOnHold)}</Typography>
        {(isPasivise || isOnHold) &&
            <>
                <TextField
                    value={decisionNumber}
                    onChange={handleDecisionNumber}
                    name="decisionNumber"
                    label={strings.decisionNumber}
                    className={"w-100"}
                    style={{marginBottom: 15}}
                    error={!!(isPasivise && (datePassivate !== null && decisionNumber === ""))}
                />
                <NullableDatePicker
                    value={datePassivate}
                    onChange={handleChangeDatePicker}  error={!!(isPasivise && (decisionNumber && !datePassivate))}
                    label={(isPasivise && strings.passivationDate) || (isOnHold && strings.onHoldDate)}/>
                <FormControl
                    className = "mt-3"
                    fullWidth
                    sx={{ textAlign: "left" }}
                >
                    <InputLabel>
                        {(isPasivise && (isForeign ? strings.passivationReasonForeign : strings.passivationReason)) || (isOnHold && strings.onHoldReason)}
                    </InputLabel>

                    {isPasivise && <Select
                        value={passivisationReasons || ''}
                        onChange={handlePassivisationReasons}
                        error={!passivisationReasons}
                        input={
                            <OutlinedInput
                                label={isForeign ? strings.passivationReasonForeign : strings.passivationReason}
                            />
                        }
                    >
                        {passivateReasons.map((p) => (
                            <MenuItem
                                value={p.key}
                                key={p.key}
                            >
                                {p.name}
                            </MenuItem>
                        ))}
                    </Select>}

                    {isOnHold &&
                        <Select
                            value={onHoldReasonsString || ''}
                            onChange={handleOnHoldReasons}
                            error={!onHoldReasonsString}
                            input={
                                <OutlinedInput
                                    label={strings.onHoldReason}
                                />
                            }
                        >
                            {onHoldReasons !== undefined && onHoldReasons.map((o) => (
                                <MenuItem
                                    value={o.key}
                                    key={o.key}
                                >
                                    {o.name}
                                </MenuItem>
                            ))}
                        </Select>
                    }

                </FormControl>
            </>

        }

        {isPassivateRequest &&
            <FormControl
                className = "mt-3"
                fullWidth
                sx={{ textAlign: "left" }}
            >
                <InputLabel>
                    {(isPassivateRequest && strings.passivationReason)}
                </InputLabel>
            <Select
                value={passivateRequestReasonsString || ''}
                onChange={handlePassivateRequestReasons}
                error={!passivateRequestReasonsString}
                fullWidth
                input={
                    <OutlinedInput
                        label={strings.onHoldReason}
                    />
                }
            >
                {passivateRequestReasons !== undefined && passivateRequestReasons.map((o) => (
                    <MenuItem
                        value={o.key}
                        key={o.key}
                    >
                        {o.name}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>

        }


        {isAsylum && !isPassivateRequest && <div>
            <PageTable
                headerRows={registrationRows}
                rows={registrationValues}
                rowsPerPage={rowsPerPage}
                totalElements={totalElements}
                handleChangePage={handleChangePage}
                page={page}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                withoutPagination={true}
                clickable={false} />
        </div>}
        <div className={classes.wrapper} style={{marginTop: "40px"}}>
            <Button onClick={() => handleClose(false)} style={{float:"left", background: "green"}} variant='contained' className='mb-4'>{strings.cancel}</Button>
            {isActivate && <Button onClick={() => activateEMBG(checked.length > 0 ? checked.map(item => item.id) : Array.of(+id!))} style={{float:"right"}} variant='contained' className='mb-4'>{strings.activate}</Button>}
            {(isOnHold && !isAsylum) && <Button onClick={() => onHoldEMBG(buildOnHold(), Array.of(+id!))} style={{float:"right"}} variant='contained' className='mb-4'>{strings.putOnHold}</Button>}
            {(isPasivise && isAsylum) && <Button disabled={!passivisationReasons || (datePassivate !== null && decisionNumber === "") || (datePassivate === null && decisionNumber !== "")} onClick={() => onPassivateEMBG(buildPassivation(), checked.map(item => item.id))} style={{float:"right"}} variant='contained' className='mb-4'>{strings.pasiviseEMBG}</Button>}
            {(isPasivise && !isAsylum) && <Button disabled={!passivisationReasons || (datePassivate !== null && decisionNumber === "") || (datePassivate === null && decisionNumber !== "")}  onClick={() => onPassivateEMBG(buildPassivation(), Array.of(+id!))} style={{float:"right"}} variant='contained' className='mb-4'>{strings.cancelEMBG}</Button>}
            {(isOnHold && isAsylum) && <Button disabled={!onHoldReasonsString} onClick={() => onHoldEMBG(buildOnHold(), checked.map(item => item.id))} style={{float:"right"}} variant='contained' className='mb-4'>{strings.putOnHold}</Button>}
            {isPassivateRequest && <Button onClick={() => passivateRequest(+id!, buildPassivationRequest())} style={{float:"right"}} variant='contained' className='mb-4'>{strings.passivize}</Button>}
            {isLoading && (
                <CircularProgress
                    size={24}
                    className={classes.buttonProgressEMBGModal}
                />
            )}
        </div>
        </Box>
        </Modal>
        </>
)
}
