import { Grid, Typography } from "@mui/material";
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { DatePickerField } from "../../applications/formFields/DatePickerFields";
import InputField from "../../applications/formFields/InputField";
import SelectField from "../../applications/formFields/SelectField";
import { strings } from "../../../localization/Localization";
import { useEffect, useState } from "react";
import { NomenclatureEntryDTO } from "../../../models/nomenclatures/NomenclatureEntryDTO";
import { useFormikContext } from "formik";
import {
    getAllEntriesForNomenclatureGender,
    getAllEntriesForNomenclaturePassportType,
} from "../../../common/NomenclatureMethods";
import { PaginatedSelect } from "../../../components/PaginatedSelect";
import { NomenclatureService } from "../../../services/NomenclatureService";
import { NomenclatureConstants } from "../../../constants/NomenclatureConstants";
import InputFieldCyrillic from "../../applications/formFields/InputFieldCyrillic";

export default function DiplomatsBasicData(props:any) {
  const {
    formField: {
      archiveNumber,
      archiveDate,
      dossierNumber,
      embg,
      existingMacEmbg,
      lastName,
      parentName,
      parentSurname,
      motherName,
      motherSurname,
      firstName,
      genderMvrKey,
      citizenship,
      birthDate,
      birthPlace,
      abroadBirthPlace,
      placeOfBirthAbroad,
      inRSMBirthPlace,
      inRSMBirthMunicipality,
      inRSMBirthPlacePlace,
      livingPlaceOrStayRSM,
      livingPlaceRSM,
      livingMunicipalityRSM,
      addressDateFrom,
      livingStreetRSM,
      livingStreetNumberRSM,
      livingApartmentEntranceRSM,
      livingApartmentRSM,
      firstNameCyrillic,
      lastNameCyrillic,
      identityCardNumber,
      passportTypeMvrKey,
      countryPassport
    },
  } = props;
  
    const [genders, setGenders] = useState<NomenclatureEntryDTO[]>([]);
    const [passportTypes, setPassportTypes] = useState<NomenclatureEntryDTO[]>([]);
    const [disabledAbroad, setDisabledAbroad] = useState<boolean>();
    const [disabledRSM, setDisabledRSM] = useState<boolean>();
    const [selectedPassportType, setSelectedPassportType] = useState<NomenclatureEntryDTO | null | undefined>(null);

    const formikProps = useFormikContext<any>()
  useEffect(() => {
    getAllEntriesForNomenclatureGender().then((res)=>{
      setGenders(res)
    })
    getAllEntriesForNomenclaturePassportType().then((res)=>{

      setPassportTypes(res.filter((type)=>type.key===NomenclatureConstants.idKey))
    });
  }, [])


  useEffect(()=>{
    if(formikProps.values.abroadBirthPlace || formikProps.values.placeOfBirthAbroad){
      setDisabledRSM(true);
    }else{
      setDisabledRSM(false);
    }
    if(formikProps.values.inRSMBirthMunicipality || formikProps.values.inRSMBirthPlacePlace){
      setDisabledAbroad(true);
    }else{
      setDisabledAbroad(false)
    }
  },[formikProps.values.abroadBirthPlace, formikProps.values.placeOfBirthAbroad, formikProps.values.inRSMBirthMunicipality,formikProps.values.inRSMBirthPlacePlace, disabledAbroad, disabledRSM])

  useEffect(()=>{
    props.func(!(formikProps.values.firstName || formikProps.values.lastName || formikProps.values.archiveNumber));
  },[formikProps.values.firstName,formikProps.values.lastName,formikProps.values.archiveNumber])
  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
         <Grid item xs={12} sm={4} md={6}>
        <Grid container  spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> 
        <Grid item xs={2} sm={4} md={4}>
        <Typography variant="subtitle1" gutterBottom>
            {archiveNumber.label}
          </Typography>
          <InputField name={archiveNumber.name} label={archiveNumber.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true}/>
          </Grid>
          <Grid item xs={2} sm={4} md={4} >
          <Typography variant="subtitle1" gutterBottom>
            {archiveDate.label}
          </Typography>
          <DatePickerField
            name={archiveDate.name}
            label={archiveDate.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
          />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
          <Typography variant="subtitle1" gutterBottom>
            {dossierNumber.label}
          </Typography>
          <InputField name={dossierNumber.name} label={dossierNumber.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true}/>
          </Grid>
        
          </Grid> 
          <Typography variant="subtitle1" gutterBottom>
            {embg.id + ". " + strings.embg}
          </Typography>
          <InputField name={embg.name} label={strings.embg + "*"} fullWidth className="pb-3" disabled={true}/>
             <Typography variant="subtitle1" fontWeight="500" gutterBottom >
                 {existingMacEmbg.label}
             </Typography>
             <InputField name={existingMacEmbg.name} label={existingMacEmbg.placeholder} fullWidth className="pb-3" embgNumber={true}/>
          <Grid container  spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> 
        <Grid item xs={2} sm={4} md={6}>
          <Typography variant="subtitle1" gutterBottom >
            {lastName.id + ". " + strings.lastName}
          </Typography>
          <InputField name={lastName.name} label={strings.lastName + "*"} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} icao="true"/>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
          <Typography variant="subtitle1" gutterBottom >
            {lastNameCyrillic.label}
          </Typography>
          <InputFieldCyrillic name={lastNameCyrillic.name} label={lastNameCyrillic.placeholder + "*"} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          </Grid>
          </Grid>
          <Grid container  spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> 
        <Grid item xs={2} sm={4} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            {parentName.id + ". " + strings.parentName}
          </Typography>
          <InputField name={parentName.name} label={strings.parentName} fullWidth className="pb-3"  readOnly={props.hasAuthority ? false : true} icao='true' />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            {parentSurname.id + ". " + parentSurname.label}
          </Typography>
          <InputField name={parentSurname.name} label={parentSurname.placeholder} fullWidth className="pb-3"  readOnly={props.hasAuthority ? false : true} icao="true" />
          </Grid>
          </Grid>
          <Grid container  spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> 
        <Grid item xs={2} sm={4} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            {motherName.id + ". " + motherName.label}
          </Typography>
          <InputField name={motherName.name} label={motherName.placeholder} fullWidth className="pb-3"  readOnly={props.hasAuthority ? false : true} icao="true" />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            {motherSurname.id + ". " + motherSurname.label}
          </Typography>
          <InputField name={motherSurname.name} label={motherSurname.placeholder} fullWidth className="pb-3"  readOnly={props.hasAuthority ? false : true} icao="true" />
          </Grid>
          </Grid>
          <Grid container  spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}> 
          <Grid item xs={2} sm={4} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            {firstName.id + ". " + strings.firstName}
          </Typography>
          <InputField name={firstName.name} label={strings.firstName + "*"} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} icao="true"/>
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
          <Typography variant="subtitle1" gutterBottom >
            {firstNameCyrillic.label}
          </Typography>
          <InputFieldCyrillic name={firstNameCyrillic.name} label={firstNameCyrillic.placeholder + "*"} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          </Grid>
          </Grid>
          <Typography variant="subtitle1" gutterBottom>
            {genderMvrKey.id + ". " + strings.gender}
          </Typography>
          <SelectField className="pb-3"
            name={genderMvrKey.name}
            label={strings.gender + "*"}
            data={genders}
            read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" gutterBottom>
            {citizenship.id + ". " + strings.citizenship + "*"}
          </Typography>
          <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.citizenship!=="" ? formikProps.values.citizenship : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("citizenship", value)
                          }
                        }
                        label={strings.citizenship + "*"}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.citizenship, filter ? filter : "", "", "", undefined, "", true
                          );
                        }}
                        name={citizenship.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
                       <Typography variant="subtitle1" gutterBottom>
            {birthDate.id + ". " + strings.birthDate}
          </Typography>
          <DatePickerField
            name={birthDate.name}
            label={strings.birthDate + "*"}
            className="pb-3"
            read={props.hasAuthority ? false : true}
            maxDate={true}
          />
         <Typography variant="subtitle1" gutterBottom>
            {birthPlace.id + ". " + strings.birthPlace}
          </Typography>
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
            <Typography variant="subtitle1" gutterBottom>
              {abroadBirthPlace.id + ". " + abroadBirthPlace.label+"*"}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.abroadBirthPlace!=="" ? formikProps.values.abroadBirthPlace : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("abroadBirthPlace", value)

                          }
                        }
                        label={strings.countryName + "*"}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={abroadBirthPlace.name}
                        className="pb-3"
                        disabled={disabledAbroad}
                        readOnly={props.hasAuthority ? false : true}
                      />
          <Typography variant="subtitle1" gutterBottom>
              {placeOfBirthAbroad.id + ". " + placeOfBirthAbroad.label + "*"}
            </Typography>
            <InputField name={placeOfBirthAbroad.name} label={placeOfBirthAbroad.label + "*"} fullWidth className="pb-3"  disabled={disabledAbroad}  readOnly={props.hasAuthority ? false : true}/>
            <Typography variant="subtitle1" gutterBottom>
              {inRSMBirthPlace.id + ". " + inRSMBirthPlace.label}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              {inRSMBirthMunicipality.id + ". " + inRSMBirthMunicipality.label + "*"}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.inRSMBirthMunicipality!=="" ? formikProps.values.inRSMBirthMunicipality : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("inRSMBirthMunicipality", value);
                          formikProps.setFieldValue("inRSMBirthPlacePlace", "");
                        }
                        }
                        label={strings.municipality + "*"}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={inRSMBirthMunicipality.name}
                        className="pb-3"
                        disabled={disabledRSM}
                        readOnly={props.hasAuthority ? false : true}
                      />
            <Typography variant="subtitle1" gutterBottom>
              {inRSMBirthPlacePlace.id + ". " + inRSMBirthPlacePlace.label + "*"}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.inRSMBirthPlacePlace!=="" ? formikProps.values.inRSMBirthPlacePlace : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("inRSMBirthPlacePlace", value)
                          }
                        }
                        label={strings.place + "*"}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.inRSMBirthMunicipality.key}`, filter ? filter : ""
                          );
                        }}
                        name={inRSMBirthPlacePlace.name}
                        className="pb-3"
                        disabled={disabledRSM}
                        readOnly={props.hasAuthority ? false : true}
                      />
          </CenteredCardLayout>
          </Grid>
          <Grid item xs={12} sm={4} md={6}>
          <Typography variant="subtitle1" gutterBottom>
            {livingPlaceOrStayRSM.id + ". " + livingPlaceOrStayRSM.label}
          </Typography>
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
          <Typography variant="subtitle1" gutterBottom>
              {addressDateFrom.id + ". " + addressDateFrom.label}
            </Typography>
            <DatePickerField
            name={addressDateFrom.name}
            label={addressDateFrom.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
          />
            <Typography variant="subtitle1" gutterBottom>
              {livingMunicipalityRSM.id + ". " + livingMunicipalityRSM.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.livingMunicipalityRSM!=="" ? formikProps.values.livingMunicipalityRSM : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("livingMunicipalityRSM", value);
                          formikProps.setFieldValue("livingPlaceRSM", "");
                          formikProps.setFieldValue("livingStreetRSM", "");
                        }
                        }
                        label={strings.municipality + "*"}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={livingMunicipalityRSM.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
            <Typography variant="subtitle1" gutterBottom>
              {livingPlaceRSM.id + ". " + livingPlaceRSM.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.livingPlaceRSM!=="" ? formikProps.values.livingPlaceRSM : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("livingPlaceRSM", value);
                          formikProps.setFieldValue("livingStreetRSM", "");
                        }
                        }
                        label={strings.place + "*"}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.livingMunicipalityRSM.key}`, filter ? filter : ""
                          );
                        }}
                        name={livingPlaceRSM.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
            <Typography variant="subtitle1" gutterBottom>
              {livingStreetRSM.id + ". " + livingStreetRSM.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.livingStreetRSM!=="" ? formikProps.values.livingStreetRSM : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("livingStreetRSM", value)
                          }
                        }
                        label={strings.street + "*"}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.street, true, `place=${formikProps.values.livingPlaceRSM?.key}`, filter ? filter : ""
                          );
                        }}
                        name={livingStreetRSM.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
            <Typography variant="subtitle1" gutterBottom>
              {livingStreetNumberRSM.id + ". " + livingStreetNumberRSM.label}
            </Typography>
            <InputField name={livingStreetNumberRSM.name} label={livingStreetNumberRSM.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true}></InputField> 
            <Typography variant="subtitle1" gutterBottom>
              {livingApartmentEntranceRSM.id + ". " + livingApartmentEntranceRSM.label}
            </Typography>
            <InputField name={livingApartmentEntranceRSM.name} label={livingApartmentEntranceRSM.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true}></InputField> 

            <Typography variant="subtitle1" gutterBottom>
              {livingApartmentRSM.id + ". " + livingApartmentRSM.label}
            </Typography>
            <InputField name={livingApartmentRSM.name} label={livingApartmentRSM.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true}></InputField> 
          </CenteredCardLayout>
             <Typography variant="h5" gutterBottom display="flex" justifyContent="center" className="pb-3">
        Б. Патна исправа
      </Typography>
      {(
        <Typography variant="subtitle1" gutterBottom>
          {passportTypeMvrKey.id + '. ' + passportTypeMvrKey.label}
        </Typography>
      )}
      <SelectField
        className="pb-3"
        name={passportTypeMvrKey.label}
        label={passportTypeMvrKey.label}
        data={passportTypes}
        read={props.hasAuthority ? false : true}
      />
        <Typography variant="subtitle1" gutterBottom>
            {countryPassport.id + ". " + countryPassport.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.countryPassport!=="" ? formikProps.values.countryPassport : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("countryPassport", value)
                          }
                        }
                        label={countryPassport.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={countryPassport.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {identityCardNumber.id + ". " + identityCardNumber.label}
        </Typography>  
        <InputField name={identityCardNumber.name} label={identityCardNumber.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField>
          </Grid>
        </Grid>
    </>
  )
}

