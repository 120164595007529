// @ts-nocheck
import {applyMiddleware, compose, createStore} from "redux";
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router';
// import { connectRouter} from 'connected-react-router'
import {loadUser} from "redux-oidc";
// import createOidcMiddleware from "redux-oidc";
import rootReducer from "./root-reducer";
import thunk from 'redux-thunk'
import userManager from "./util/auth/userManager";
import { composeWithDevTools } from "redux-devtools-extension";
export const history = createBrowserHistory({basename:process.env.REACT_APP_ROUTER_BASE || ''})
//const oidcMiddleware = createOidcMiddleware(userManager);

const loggerMiddleware = (store:any) => (next:any) => (action:any) => {
  next(action);
};

const initialState = {};
const enhancers = [];
const middleware = [
  thunk,
  //loggerMiddleware,
  //oidcMiddleware,
  routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
)

const store = createStore(
    rootReducer(history),
    initialState,
    composedEnhancers
)

loadUser(store, userManager);
export default store;
