import { ChangeEvent, useState } from "react";
import { SideBarPage } from "../../components/SideBarPage";
import { strings } from "../../localization/Localization";
import { PermitDTO } from "../../models/reports/PermitDTO";
import { ReportService } from "../../services/registrations/ReportService";
import { Box, Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import { PageTable } from "../../components/table/PageTable";
import { LightTooltip } from "../../components/LightTooltip";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { showErrorDialog } from "../../common/Dialogues";


export function Reports() {


    const [numberOfPermits, setNumberOfPermits] = useState<PermitDTO[]>([]);
    const [embg, setEmbg] = useState<string>("");


    async function readCardDataForEMBG(embg: string): Promise<void> {
        setNumberOfPermits([]);
        await ReportService.readCardDataForEMBG(embg).then((response) => {
            setNumberOfPermits(response)
        })
            .catch((e) => {
                const errorMessage = ErrorHandler.parseErrorMessage(e);
                if (errorMessage === "No message available") {
                    showErrorDialog(strings.fail, "Не постои регистрација со тој матичен број!", strings.ok).then(_ => { })
                } else {
                    showErrorDialog(strings.fail, "Не постојат податоци за дозвола за конкретниот матичен број!", strings.ok).then(_ => { })
                }
            });
    }

    async function downloadCardDataForEMBG(embg: string): Promise<void> {
        await ReportService.downloadCardDataForEMBG(embg).catch((e) => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
        });
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const digitsOnly = value.replace(/\D/g, ''); 
        const truncatedValue = digitsOnly.slice(0, 13); // Ogranicuvanje na tocno 13 brojki 

        setEmbg(truncatedValue);
        if (truncatedValue === "" || truncatedValue.length !== 13) {
            setNumberOfPermits([])
        }
    }
    const reportsHeadersRows = [
        {
            id: "orderId",
            label: strings.ordinalNumber,
            search: false,
            type: "text",
            variant: "standard",
            width: "50%"
        },
        {
            id: "permitNumber",
            label: strings.permitNumber,
            search: false,
            type: "text",
            variant: "standard",
            width: "50%"
        },
        {
            id: "permitDate",
            label: strings.permitDate,
            search: false,
            type: "text",
            variant: "standard",
            width: "50%"
        },
        {
            id: "typeOfStay",
            label: strings.typeOfStay,
            search: false,
            type: "text",
            variant: "standard",
            width: "50%"
        },
        {
            id: "expiryDate",
            label: strings.expiryDate,
            search: false,
            type: "text",
            variant: "standard",
            width: "50%"
        },
        {
            id: "firstName",
            label: strings.firstName,
            search: false,
            type: "text",
            variant: "standard",
            width: "50%"
        },
        {
            id: "lastName",
            label: strings.lastName,
            search: false,
            type: "text",
            variant: "standard",
            width: "50%"
        }
    ]

    const reportsRows = numberOfPermits?.map((permit, index : number) => {
        const permitDate = permit.permitDate ? new Date(permit.permitDate) : null;
        const formattedDate = permitDate ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(permitDate).replace(/\//g, ".") : "";
        const expiryDate = permit.expiryDate ? new Date(permit.expiryDate) : null;
        const formattedExpiryDate = expiryDate ? new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(expiryDate).replace(/\//g, ".") : "";
        return {
            values: [
                {
                    type: 'text',
                    name: "orderId",
                    value: index + 1
                },
                {
                    type: 'text',
                    name: "permitNumber",
                    value: <LightTooltip className={"tableTooltip"} title={permit.permitNumber ? permit.permitNumber : ""}><span>{permit.permitNumber ? permit.permitNumber : ""}</span></LightTooltip>
                },
                {
                    type: 'text',
                    name: "permitDate",
                    value: <LightTooltip className={"tableTooltip"} title={formattedDate}><span>{formattedDate}</span></LightTooltip>
                },
                {
                    type: 'text',
                    name: "typeOfStay",
                    value: <LightTooltip className={"tableTooltip"} title={permit.typeOfStay ? permit.typeOfStay : ""}><span>{permit.typeOfStay ? permit.typeOfStay : ""}</span></LightTooltip>
                },
                {
                    type: 'text',
                    name: "permitDate",
                    value: <LightTooltip className={"tableTooltip"} title={formattedExpiryDate}><span>{formattedExpiryDate}</span></LightTooltip>
                },
                {
                    type: 'text',
                    name: "firstName",
                    value: <LightTooltip className={"tableTooltip"} title={permit.firstName ? permit.firstName : ""}><span>{permit.firstName ? permit.firstName : ""}</span></LightTooltip>
                },
                {
                    type: 'text',
                    name: "lastName",
                    value: <LightTooltip className={"tableTooltip"} title={permit.lastName ? permit.lastName : ""}><span>{permit.lastName ? permit.lastName : ""}</span></LightTooltip>
                }
            ]
        }
    })

    return (
        <SideBarPage
            component={
                <Box component="div" display="flex" justifyContent="center">
                    <Box component="div" maxWidth="md" width="100%" className="mt-3">
                        <TextField
                            onChange={handleChange}
                            name="embg"
                            label={strings.embg}
                            fullWidth
                            className="pb-3"
                            value={embg} 
                            inputProps={{
                                pattern: '^[0-9]{13}$', 
                                title: 'Ве молиме внесете валиден ЕМБГ!', 
                            }}
                        />
                        <Box component="div" className="mt-3" display="flex" justifyContent="end">
                            <Button style={{ marginRight: '10px' }} variant="contained" onClick={() => readCardDataForEMBG(embg)} disabled={(embg && embg.length === 13) ? false : true}>{strings.viewOfPermitData}</Button>
                            <Button style={(embg && embg.length === 13) ? { background: "green" } : {}} variant="contained" onClick={() => downloadCardDataForEMBG(embg)} disabled={(embg && embg.length === 13) ? false : true}>{strings.generatePermitData}</Button>
                        </Box>
                        {numberOfPermits.length > 0 && embg !== "" && <Box component="div" className="mt-5">
                            <PageTable
                                headerRows={reportsHeadersRows}
                                rows={reportsRows}
                                withoutPagination={true}
                                clickable={false} />
                        </Box>}
                    </Box>
                </Box>}
            pageTitle={strings.reportsNumberOfPermits}
        />

    )
}