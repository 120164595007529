import {NomenclatureEntryDTO} from "./NomenclatureEntryDTO";

export class NomenclatureEntryDTOWithKey {
    id: number | string;
    dateCreated: string;
    dateModified: string;
    name: string;
    nameSq: string;
    nameEn: string;
    active: boolean | null | undefined;
    nomenclatureEntryKey: string;


    constructor(json: NomenclatureEntryDTO) {
        this.id = json.key;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.name = json.name;
        this.nameSq = json.nameSq;
        this.nameEn = json.nameEn;
        this.active = json.active;
        this.nomenclatureEntryKey = json.nomenclatureEntryKey;
    }
}
