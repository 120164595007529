import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { DatePickerField } from "../../applications/formFields/DatePickerFields";
import InputField from "../../applications/formFields/InputField";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { NomenclatureService } from "../../../services/NomenclatureService";
import { NomenclatureEntryDTO } from "../../../models/nomenclatures/NomenclatureEntryDTO";
import SelectField from "../../applications/formFields/SelectField";
import CustomSelect from "../../applications/formFields/CustomSelect";
import { useFormikContext } from "formik";
import { FieldArray } from "formik"
import { useNavigate, useParams } from "react-router-dom";
import InputFieldCyrillic from "../../applications/formFields/InputFieldCyrillic";
import { PaginatedSelect } from "../../../components/PaginatedSelect";
import { NomenclatureConstants } from "../../../constants/NomenclatureConstants";
import { strings } from "../../../localization/Localization";
import { ForeignCitizenService } from "../../../services/registrations/ForeignCitizenService";
import { Routes } from "../../../router/Routes";
import { RegistrationReducedDTO } from "../../../models/registration/RegistrationReducedDTO";


export default function DataFormAsylumFour(props: any) {
    interface FormElementFamilyMembers {
        id: string;
        data: JSX.Element;
    }
    interface FormElementFamilyMembersInTheCountryOfOrigin {
        id: string;
        data: JSX.Element;
    }
    const [newForm, setNewForm] = useState<FormElementFamilyMembers[]>([]);
    const [newFormFMOrigin, setNewFormFMOrigin] = useState<FormElementFamilyMembersInTheCountryOfOrigin[]>([]);
    const [countries, setCountries] = useState<NomenclatureEntryDTO[]>([])
    const [keyCountry, setKeyCountry] = useState<string>("country");
    const [genders, setGenders] = useState<NomenclatureEntryDTO[]>([]);
    const [keyGender, setKeyGender] = useState<string>("genders");
    const [borderPoints, setBorderPoint] = useState<NomenclatureEntryDTO[]>([]);
    const [keyborderPoints, setKeyBorderPoint] = useState<string>("border_point");
    const [decisionTypes, setdecisionTypes] = useState<NomenclatureEntryDTO[]>([]);
    const [keyDecisionTypes, setKeyDecisionTypes] = useState<string>("decision_types");
    const [entranceType, setEntranceType] = useState<NomenclatureEntryDTO[]>([]);
    const [keyEntranceType, setKeyEntranceType] = useState<string>("entrance_types");
    const formikProps = useFormikContext<any>()
    const [disabled, setDisabled] = useState<boolean>(true);
    const [flag, setFlag] = useState<boolean>(false)
    const [anbAnswers, setAnbAnswers] = useState<NomenclatureEntryDTO[]>([]);
    const [keyAnbAnswers, setKeyAnbAnswers] = useState<string>("anb_answers");
    const [citizenships, setCitizenships] = useState<NomenclatureEntryDTO[]>([]);
    const [keyCitizenship, setKeyCitizenship] = useState<string>("citizenship")
    const [procedureTypeData, setProcedureTypeData] = useState<NomenclatureEntryDTO[]>([]);
    const [keyProcedureTypeData, setKeyProcedureTypeData] = useState<string>("procedure_type")
    const [decisionResults, setDecisionResults] = useState<NomenclatureEntryDTO[]>([]);
    const [keyDecisionResults, setKeyDecisionResults] = useState<string>("decision_results");
    const navigate = useNavigate();

    const datePickerProps = {
        flag: flag
    }

    let { id } = useParams();
    const isAddMode = !id;

    const typeYesNo = [
        {
            value: true,
            label: 'Да'
        },
        {
            value: false,
            label: 'Не'
        },
    ];

    const {
        formField: {
            familyMembersAcompany,
            memberSurname,
            memberName,
            memberDateOfBirth,
            memberPlaceOfBirth,
            relationshipsOrSex,
            familyMembersInTheCountryOfOrigin,
            memberNameOG,
            memberSurnameOG,
            memberDateOfBirthOG,
            memberPlaceOfBirthOG,
            relationshipsOrSexOG,
            appliedForTheRightToAsylumInRSM,
            appliedForTheRightToAsylumInOtherCountry,
            dateOfSubbmission,
            decision,
            mannerOfArival,
            dateOfArrival,
            manner,
            borderPoint,
            transportationMeans,
            residenceStatusPriorToSubmissionOfTheApplication,
            legal,
            temporary,
            permanent,
            temporaryProtection,
            otherResidenceStatus,
            procedureType,
            countryWhereYouApplied,
            decisionOtherCountry,
            briefSummaryAboutTheReasons,
            freeLegalAid,
            sameGenderOfficial,
            idOfIdentification,
            expireDate,
            resultDecision,
            completedFormData,
            dateOfSubmissionForm,
            startTime,
            endTime,
            checksByUBK,
            haveChildren,
            familyMembersAcompanyList,
            familyMembersOriginCountryOrAbroadList,
            memberAbroadCountry,
            memberAbroadPlace,
        }
    } = props;

    async function getAllEntriesForNomenclatureCountry(): Promise<void> {
        const countries = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyCountry);
        setCountries(countries)
    }

    async function getAllEntriesForNomenclatureGender(): Promise<void> {
        const genders = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyGender);
        setGenders(genders)
    }

    async function getAllEntriesForNomenclatureBorderPoints(): Promise<void> {
        const borderPoint = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyborderPoints);
        setBorderPoint(borderPoint)
    }

    async function getAllEntriesForNomenclatureDecisionTypes(): Promise<void> {
        const decisionTypes = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyDecisionTypes);
        setdecisionTypes(decisionTypes)
    }

    async function getAllEntriesForNomenclatureDecisionResults(): Promise<void> {
        const decisionResults = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyDecisionResults);
        setDecisionResults(decisionResults)
    }

    async function getAllEntriesForNomenclatureProcedureType(): Promise<void> {
        const procedureType = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyProcedureTypeData);
        setProcedureTypeData(procedureType)
    }

    async function getAllEntriesForNomenclatureEntranceType(): Promise<void> {
        const EntranceType = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyEntranceType);
        setEntranceType(EntranceType)
    }

    async function getAllEntriesForNomenclatureAnbAnswers(): Promise<void> {
        const anbAnswer = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyAnbAnswers);
        setAnbAnswers(anbAnswer)
    }

    async function getAllEntriesForNomenclatureCitizenship(): Promise<void> {
        const citizenships = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyCitizenship);
        setCitizenships(citizenships)
    }

    useEffect(() => {
        getAllEntriesForNomenclatureCountry();
        getAllEntriesForNomenclatureGender();
        getAllEntriesForNomenclatureBorderPoints();
        getAllEntriesForNomenclatureDecisionTypes();
        getAllEntriesForNomenclatureEntranceType();
        getAllEntriesForNomenclatureAnbAnswers();
        getAllEntriesForNomenclatureCitizenship();
        getAllEntriesForNomenclatureProcedureType();
        getAllEntriesForNomenclatureDecisionResults();
    }, [])

    useEffect(() => {
        if (!formikProps.values.haveChildren) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }

        if (!isAddMode && props.childrenState === true) {
            formikProps.setFieldValue("haveChildren", true)
        }

        if (isAddMode) {
            setFlag(true)
        }
    }, [formikProps.values.haveChildren, props.childrenState, props.children, flag])

    useEffect(() => {
        props.func(formikProps.values);
      }, [formikProps.values.familyMembersAcompanyList]);

      function handleChangeHaveChildren(event: SelectChangeEvent){
        formikProps.setFieldValue("haveChildren", event.target.value)

    };
    function handleChangeFreeLegalAid(event: SelectChangeEvent) {
        formikProps.setFieldValue("freeLegalAid", event.target.value)

    };
    function handleChangeSameGenderOfficial(event: SelectChangeEvent) {
        formikProps.setFieldValue("sameGenderOfficial", event.target.value)

    };

    async function moveToForeigner(id: number) {
        const data = await ForeignCitizenService.getRegistrationById(id);
        let route = Routes.APPLICATION
        navigate(route, {state: { data }})
    }

    return (
        <>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                {/* Grid 1 */}
                <Grid item xs={12} sm={4} md={6}>
                    <div>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom >
                            {haveChildren.label}
                        </Typography>
                        <CustomSelect className="pb-3"
                            name={haveChildren.name}
                            label={haveChildren.label}
                            data={typeYesNo}
                            read={props.hasAuthority ? false : true}
                            onChange={handleChangeHaveChildren}
                        />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {familyMembersAcompany.id + ". " + familyMembersAcompany.label}
                        </Typography>
                        <FieldArray
                            name="familyMembersAcompanyList"
                            render={({ remove, push, form: { values, setFieldValue } }) => (
                                <div>
                                    {values.familyMembersAcompanyList && values.familyMembersAcompanyList.length > 0 && (
                                        values.familyMembersAcompanyList.map((form: any, index: any) => (
                                            <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 " key={index}>
                                                <Accordion className="sm:mt-14 mb-4" key={index} defaultExpanded={!isAddMode ? true : false}>
                                                    <AccordionSummary
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="bg-white sm:mt-8"
                                                        expandIcon={<ExpandMoreIcon />}
                                                        style={{ display: "flex", justifyContent: "flex-end" }}
                                                    >
                                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                            {familyMembersAcompany.label}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="bg-white">
                                                    {formikProps.values.familyMembersAcompanyList[index].id !== "" && props.copyToForeignCitizen && <div style={{display:'flex', justifyContent:'end'}}>
                                                    <Button variant='contained' className='mb-4' onClick={() => moveToForeigner(form.id)}>{strings.moveAsylumToForeigner}</Button>
                                                    </div>}
                                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom >
                                                            {"Македонски матичен број (доколку има таков)"}
                                                        </Typography>
                                                        <InputField embgNumber={true} name={`familyMembersAcompanyList[${index}].memberExistingMacEmbg`} label={"Македонски матичен број (доколку има таков)"} fullWidth className="pb-3" />
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div style={{ width: "49%" }}>
                                                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                                    {'Име*'}
                                                                </Typography>
                                                                <InputField name={`familyMembersAcompanyList[${index}].memberName`} label={'Име'} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth icao="true"></InputField>
                                                            </div>
                                                            <div style={{ width: "49%" }}>
                                                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                                    {'Име (кирилица)*'}
                                                                </Typography>
                                                                <InputFieldCyrillic name={`familyMembersAcompanyList[${index}].memberNameCyrillic`} label={'Име (кирилица)*'} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputFieldCyrillic>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div style={{ width: "49%" }}>
                                                                <Typography variant="subtitle1" gutterBottom>
                                                                    {"Презиме*"}
                                                                </Typography>
                                                                <InputField name={`familyMembersAcompanyList[${index}].memberSurname`} label={'Презиме*'} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth icao="true"></InputField>
                                                            </div>
                                                            <div style={{ width: "49%" }}>
                                                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                                    {'Презиме (кирилица)*'}
                                                                </Typography>
                                                                <InputFieldCyrillic name={`familyMembersAcompanyList[${index}].memberSurnameCyrillic`} label={'Презиме (кирилица)*'} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputFieldCyrillic>
                                                            </div>
                                                        </div>
                                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                            {'Датум на раѓање*'}
                                                        </Typography>
                                                        <DatePickerField
                                                            name={`familyMembersAcompanyList[${index}].memberDateOfBirth`}
                                                            label={'Датум на раѓање*'}
                                                            className="pb-3"
                                                            read={props.hasAuthority ? false : true}
                                                            maxDate={true}
                                                        />



                                                        {/*OD TUKAAAAAAAAAAAAAA*/}
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            {"* Место на раѓање во странство"}
                                                        </Typography>
                                                        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">

                                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                            {"Држава*"}
                                                        </Typography>
                                                        <PaginatedSelect<NomenclatureEntryDTO>
                                                            value={formikProps.values.familyMembersAcompanyList[index].memberStateOfBirth !== "" ? formikProps.values.familyMembersAcompanyList[index].memberStateOfBirth : undefined}
                                                            onChange={(value) => {
                                                                formikProps.setFieldValue(`familyMembersAcompanyList[${index}].memberStateOfBirth`, value);
                                                            }}
                                                            label={strings.country + "*"}
                                                            valueMapper={(item) => item?.name || ""}
                                                            keyMapper={(item) => item.id.toString()}
                                                            itemMapper={(item) => <>{item.name}</>}
                                                            labelMapper={(item) => item.name}
                                                            dataFetcher={(page, size, filter) => {
                                                                return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                                                                    page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                                                                );
                                                            }}
                                                            name={`familyMembersAcompanyList[${index}].memberStateOfBirth`}
                                                            disabled={!!(formikProps.values.familyMembersAcompanyList[index].memberInRSMMunicipality || formikProps.values.familyMembersAcompanyList[index].memberInRSMPlace)}
                                                            className="pb-3"
                                                            readOnly={props.hasAuthority ? false : true}
                                                        />
                                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                            {"Место на раѓање*"}
                                                        </Typography>
                                                        <InputField
                                                            name={`familyMembersAcompanyList[${index}].memberPlaceOfBirth`}
                                                            label={'Место на раѓање*'}
                                                            disabled={!!(formikProps.values.familyMembersAcompanyList[index].memberInRSMMunicipality || formikProps.values.familyMembersAcompanyList[index].memberInRSMPlace)}
                                                            readOnly={props.hasAuthority ? false : true}
                                                            fullWidth
                                                        />
                                                        </CenteredCardLayout>
                                                        {/*<Typography variant="subtitle1" gutterBottom>*/}
                                                        {/*    {memberInRSMPlace.id + ". " + memberInRSMPlace.label}*/}
                                                        {/*</Typography>*/}
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            {"* Место на раѓање во РСМ"}
                                                        </Typography>
                                                        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            {"Општина во РСМ*"}
                                                        </Typography>
                                                        <PaginatedSelect<NomenclatureEntryDTO>
                                                            value={formikProps.values.familyMembersAcompanyList[index].memberInRSMMunicipality !== "" ? formikProps.values.familyMembersAcompanyList[index].memberInRSMMunicipality : undefined}
                                                            onChange={(value) => {
                                                                formikProps.setFieldValue(`familyMembersAcompanyList[${index}].memberInRSMMunicipality`, value);
                                                                formikProps.setFieldValue(`familyMembersAcompanyList[${index}].memberInRSMPlace`, "");
                                                            }
                                                            }
                                                            label={strings.municipality + "*"}
                                                            valueMapper={(item) => item?.key && item?.key?.toString()}
                                                            keyMapper={(item) => item.id.toString()}
                                                            itemMapper={(item) => <>{item.name}</>}
                                                            labelMapper={(item) => item.name}
                                                            dataFetcher={(page, size, filter) => {
                                                                return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                                                                    page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                                                                );
                                                            }}
                                                            name={`familyMembersAcompanyList[${index}].memberInRSMMunicipality`}
                                                            className="pb-3"
                                                            disabled={!!(formikProps.values.familyMembersAcompanyList[index].memberStateOfBirth || formikProps.values.familyMembersAcompanyList[index].memberPlaceOfBirth)}
                                                            readOnly={props.hasAuthority ? false : true}
                                                        />
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            {"Место во РСМ*"}
                                                        </Typography>
                                                        <PaginatedSelect<NomenclatureEntryDTO>
                                                            value={formikProps.values.familyMembersAcompanyList[index].memberInRSMPlace !== "" ? formikProps.values.familyMembersAcompanyList[index].memberInRSMPlace : undefined}
                                                            onChange={(value) => {
                                                                // formikProps.setFieldValue(`familyMembersAcompanyList[${index}].memberInRSMMunicipality`, "");
                                                                formikProps.setFieldValue(`familyMembersAcompanyList[${index}].memberInRSMPlace`, value);

                                                            }
                                                            }
                                                            label={strings.place + "*"}
                                                            valueMapper={(item) => item?.key && item?.key?.toString()}
                                                            keyMapper={(item) => item.id.toString()}
                                                            itemMapper={(item) => <>{item.name}</>}
                                                            labelMapper={(item) => item.name}
                                                            dataFetcher={(page, size, filter) => {
                                                                return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                                                                    page, size, NomenclatureConstants.place, true, `municipality=${formikProps.getFieldProps(`familyMembersAcompanyList[${index}].memberInRSMMunicipality`).value.key}`, filter ? filter : ""
                                                                );
                                                            }}
                                                            name={`familyMembersAcompanyList[${index}].memberInRSMPlace`}
                                                            className="pb-3"
                                                            disabled={!!(formikProps.values.familyMembersAcompanyList[index].memberStateOfBirth || formikProps.values.familyMembersAcompanyList[index].memberPlaceOfBirth)}
                                                            readOnly={props.hasAuthority ? false : true}
                                                        />
                                                        </CenteredCardLayout>

                                                        {/*DO TUKAAAAAAAAAAAAAAAAA*/}


                                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                            {"Државјанство*"}
                                                        </Typography>
                                                        <PaginatedSelect<NomenclatureEntryDTO>
                                                            value={formikProps.values.familyMembersAcompanyList[index].memberCitizenShip !== "" ? formikProps.values.familyMembersAcompanyList[index].memberCitizenShip : undefined}
                                                            onChange={(value) => {
                                                                formikProps.setFieldValue(`familyMembersAcompanyList[${index}].memberCitizenShip`, value);
                                                            }}
                                                            label={strings.citizenship+"*"}
                                                            valueMapper={(item) => item?.name || ""}
                                                            keyMapper={(item) => item.id.toString()}
                                                            itemMapper={(item) => <>{item.name}</>}
                                                            labelMapper={(item) => item.name}
                                                            dataFetcher={(page, size, filter) => {
                                                                return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                                                                    page, size, NomenclatureConstants.citizenship, filter ? filter : "", "", "", undefined, "",true
                                                                );
                                                            }}
                                                            name={`familyMembersAcompanyList[${index}].memberCitizenShip`}
                                                            className="pb-3"
                                                            readOnly={props.hasAuthority ? false : true}
                                                        />
                                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                            {'Пол*'}
                                                        </Typography>
                                                        <SelectField
                                                            name={`familyMembersAcompanyList[${index}].relationshipsOrSex`}
                                                            label={'Пол*'}
                                                            data={genders}
                                                            read={props.hasAuthority ? false : true}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                                {props.hasAuthority && <Button className="mb-4" variant="contained" color="error" onClick={() => remove(index)}
                                                    disabled={formikProps.values.familyMembersAcompanyList[index].id !== ""}
                                                >Избриши</Button>}
                                            </CenteredCardLayout>
                                        ))
                                    )}
                                    {props.hasAuthority && <Button
                                        type="button"
                                        variant="contained"
                                        onClick={() => {
                                            push({
                                                id: "",
                                                memberExistingMacEmbg:"",
                                                memberName: "",
                                                memberSurname: "",
                                                memberDateOfBirth: "",
                                                memberStateOfBirth: "",
                                                memberPlaceOfBirth: "",
                                                memberInRSMMunicipality: "",
                                                memberInRSMPlace: "",
                                                memberCitizenShip: "",
                                                relationshipsOrSex: "",
                                                memberNameCyrillic: "",
                                                memberSurnameCyrillic: ""
                                            });
                                        }}
                                        disabled={disabled}
                                    >
                                        +
                                    </Button>}
                                </div>
                            )}
                        />
                    </div>
                    <br />
                    {props.familyMembersAcompany && props.familyMembersAcompany.map((form: any) => (
                        <div key={form.id}>
                            {form.data}
                        </div>
                    ))}
                    <br />
                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                        {familyMembersInTheCountryOfOrigin.id + ". " + familyMembersInTheCountryOfOrigin.label}
                    </Typography>
                    <FieldArray
                        name="familyMembersOriginCountryOrAbroadList"
                        render={({ remove, push, form: { values, setFieldValue } }) => (
                            <div>
                                {values.familyMembersOriginCountryOrAbroadList && values.familyMembersOriginCountryOrAbroadList.length > 0 && (
                                    values.familyMembersOriginCountryOrAbroadList.map((form: any, index: any) => (
                                        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 " key={index}>
                                            <Accordion className="sm:mt-14 mb-4" key={index} defaultExpanded={!isAddMode ? true : false}>
                                                <AccordionSummary
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="bg-white sm:mt-8"
                                                    expandIcon={<ExpandMoreIcon />}
                                                    style={{ display: "flex", justifyContent: "flex-end" }}
                                                >
                                                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                        {familyMembersInTheCountryOfOrigin.label}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className="bg-white">
                                                    {/* `familyMembersAcompanyList[${index}].relationshipsOrSex` */}
                                                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                        {'Име'}
                                                    </Typography>
                                                    <InputField name={`familyMembersOriginCountryOrAbroadList[${index}].memberNameOG`} label={'Име'} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {"Презиме"}
                                                    </Typography>
                                                    <InputField name={`familyMembersOriginCountryOrAbroadList[${index}].memberSurnameOG`} label={'Презиме'} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                                                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                        {'Датум на раѓање'}
                                                    </Typography>
                                                    <DatePickerField
                                                        name={`familyMembersOriginCountryOrAbroadList[${index}].memberDateOfBirthOG`}
                                                        label={'Датум на раѓање'}
                                                        className="pb-3"
                                                        read={props.hasAuthority ? false : true}
                                                        maxDate={true}
                                                    />
                                                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                        {"Држава"}
                                                    </Typography>
                                                    <PaginatedSelect<NomenclatureEntryDTO>
                                                        value={formikProps.values.familyMembersOriginCountryOrAbroadList[index].memberStateOfBirthOG !== "" ? formikProps.values.familyMembersOriginCountryOrAbroadList[index].memberStateOfBirthOG : undefined}
                                                        onChange={(value) => {
                                                            formikProps.setFieldValue(`familyMembersOriginCountryOrAbroadList[${index}].memberStateOfBirthOG`, value);
                                                        }}
                                                        label={strings.country}
                                                        valueMapper={(item) => item?.name || ""}
                                                        keyMapper={(item) => item.id.toString()}
                                                        itemMapper={(item) => <>{item.name}</>}
                                                        labelMapper={(item) => item.name}
                                                        dataFetcher={(page, size, filter) => {
                                                            return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                                                                page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                                                            );
                                                        }}
                                                        name={`familyMembersOriginCountryOrAbroadList[${index}].memberStateOfBirthOG`}
                                                        className="pb-3"
                                                        readOnly={props.hasAuthority ? false : true}
                                                    />
                                                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                        {"Место на раѓање"}
                                                    </Typography>
                                                    <InputField
                                                        name={`familyMembersOriginCountryOrAbroadList[${index}].memberPlaceOfBirthOG`}
                                                        label={'Место на раѓање'}
                                                        readOnly={props.hasAuthority ? false : true}
                                                        className="pb-3"
                                                        fullWidth
                                                    />
                                                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                        {"Државјанство"}
                                                    </Typography>
                                                    <PaginatedSelect<NomenclatureEntryDTO>
                                                        value={formikProps.values.familyMembersOriginCountryOrAbroadList[index].memberCitizenShipOG !== "" ? formikProps.values.familyMembersOriginCountryOrAbroadList[index].memberCitizenShipOG : undefined}
                                                        onChange={(value) => {
                                                            formikProps.setFieldValue(`familyMembersOriginCountryOrAbroadList[${index}].memberCitizenShipOG`, value);
                                                        }}
                                                        label={strings.citizenship}
                                                        valueMapper={(item) => item?.name || ""}
                                                        keyMapper={(item) => item.id.toString()}
                                                        itemMapper={(item) => <>{item.name}</>}
                                                        labelMapper={(item) => item.name}
                                                        dataFetcher={(page, size, filter) => {
                                                            return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                                                                page, size, NomenclatureConstants.citizenship, filter ? filter : "", "", "", undefined, ""
                                                            );
                                                        }}
                                                        name={`familyMembersOriginCountryOrAbroadList[${index}].memberCitizenShipOG`}
                                                        className="pb-3"
                                                        readOnly={props.hasAuthority ? false : true}
                                                    />
                                                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                        {'Пол'}
                                                    </Typography>
                                                    <SelectField
                                                        name={`familyMembersOriginCountryOrAbroadList[${index}].relationshipsOrSexOG`}
                                                        label={'Пол'}
                                                        data={genders}
                                                        read={props.hasAuthority ? false : true}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                            {props.hasAuthority && <Button className="mb-4" variant="contained" color="error" onClick={() => remove(index)}>Избриши</Button>}
                                        </CenteredCardLayout>
                                    ))
                                )}
                                {props.hasAuthority && <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                        push({
                                            memberNameOG: "",
                                            memberSurnameOG: "",
                                            memberDateOfBirthOG: "",
                                            memberPlaceOfBirthOG: "",
                                            relationshipsOrSexOG: "",
                                            memberStateOfBirthOG: "",
                                            memberCitizenShipOG: "",
                                        });
                                    }}
                                >
                                    +
                                </Button>}
                            </div>
                        )}
                    />
                    <br />
                    {props.familyMembersOG && props.familyMembersOG.map((form: any) => (
                        <div key={form.id}>
                            {form.data}
                        </div>
                    ))}
                    <br />
                    <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 ">
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {appliedForTheRightToAsylumInRSM.id + ". " + appliedForTheRightToAsylumInRSM.label}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {dateOfSubbmission.id + ". " + dateOfSubbmission.label}
                        </Typography>
                        <DatePickerField
                            name={dateOfSubbmission.name}
                            label={dateOfSubbmission.placeholder}
                            className="pb-3"
                            read={props.hasAuthority ? false : true}
                            //maxDate={true}
                        />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {decision.id + ". " + decision.label}
                        </Typography>
                        <SelectField name={decision.name} label={decision.placeholder} data={decisionTypes} className="pb-3" read={props.hasAuthority ? false : true} />
                    </CenteredCardLayout>

                    <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 ">
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {appliedForTheRightToAsylumInOtherCountry.id + ". " + appliedForTheRightToAsylumInOtherCountry.label}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {countryWhereYouApplied.id + ". " + countryWhereYouApplied.label}
                        </Typography>
                        <PaginatedSelect<NomenclatureEntryDTO>
                            value={formikProps.values.countryWhereYouApplied !== "" ? formikProps.values.countryWhereYouApplied : undefined}
                            onChange={(value) => {
                                formikProps.setFieldValue("countryWhereYouApplied", value)
                            }
                            }
                            label={strings.country}
                            valueMapper={(item) => item?.key && item?.key?.toString()}
                            keyMapper={(item) => item.id.toString()}
                            itemMapper={(item) => <>{item.name}</>}
                            labelMapper={(item) => item.name}
                            dataFetcher={(page, size, filter) => {
                                return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                                    page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                                );
                            }}
                            name={countryWhereYouApplied.name}
                            className="pb-3"
                            readOnly={props.hasAuthority ? false : true}
                        />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {decisionOtherCountry.id + ". " + decisionOtherCountry.label}
                        </Typography>
                        <SelectField name={decisionOtherCountry.name} label={decisionOtherCountry.placeholder} data={decisionResults} className="pb-3" read={props.hasAuthority ? false : true} />
                    </CenteredCardLayout>
                    <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 ">
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {mannerOfArival.id + ". " + mannerOfArival.label}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {dateOfArrival.id + ". " + dateOfArrival.label}
                        </Typography>
                        <DatePickerField name={dateOfArrival.name} label={dateOfArrival.placeholder} className="pb-3" read={props.hasAuthority ? false : true} maxDate={true}/>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {manner.id + ". " + manner.label}
                        </Typography>
                        <SelectField
                            name={manner.name}
                            label={manner.label}
                            data={entranceType}
                            read={props.hasAuthority ? false : true}
                        />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {borderPoint.id + ". " + borderPoint.label}
                        </Typography>
                        <SelectField
                            name={borderPoint.name}
                            label={borderPoint.label}
                            data={borderPoints}
                            read={props.hasAuthority ? false : true}
                        />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {transportationMeans.id + ". " + transportationMeans.label}
                        </Typography>
                        <InputField name={transportationMeans.name} label={transportationMeans.placeholder} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                    </CenteredCardLayout>
                </Grid>

                <Grid item xs={12} sm={4} md={6}>
                    <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 ">
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {residenceStatusPriorToSubmissionOfTheApplication.id + ". " + residenceStatusPriorToSubmissionOfTheApplication.label}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {legal.id + ". " + legal.label}
                        </Typography>
                        <InputField name={legal.name} label={legal.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {temporary.id + ". " + temporary.label}
                        </Typography>
                        <InputField name={temporary.name} label={temporary.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {permanent.id + ". " + permanent.label}
                        </Typography>
                        <InputField name={permanent.name} label={permanent.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {temporaryProtection.id + ". " + temporaryProtection.label}
                        </Typography>
                        <InputField name={temporaryProtection.name} label={temporaryProtection.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
                    </CenteredCardLayout>
                    {props.readAsylumSensitiveData && <>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {briefSummaryAboutTheReasons.id + ". " + briefSummaryAboutTheReasons.label}
                        </Typography>
                        <InputField name={briefSummaryAboutTheReasons.name} label={briefSummaryAboutTheReasons.label} multiline={true} rows={4} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {freeLegalAid.id + ". " + freeLegalAid.label}
                        </Typography>
                        <CustomSelect
                            name={freeLegalAid.name}
                            label={freeLegalAid.label}
                            data={typeYesNo}
                            onChange={handleChangeFreeLegalAid}
                            read={props.hasAuthority ? false : true}
                        />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {sameGenderOfficial.id + ". " + sameGenderOfficial.label}
                        </Typography>
                        <CustomSelect
                            name={sameGenderOfficial.name}
                            label={sameGenderOfficial.label}
                            data={typeYesNo}
                            onChange={handleChangeSameGenderOfficial}
                            read={props.hasAuthority ? false : true}
                        />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {procedureType.id + ". " + procedureType.label}
                        </Typography>
                        <SelectField name={procedureType.name} label={procedureType.label} data={procedureTypeData} className="pb-3" read={props.hasAuthority ? false : true} />
                    </>}
                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                        {idOfIdentification.id + ". " + idOfIdentification.label}
                    </Typography>
                    <InputField name={idOfIdentification.name} label={idOfIdentification.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
                    <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                        {expireDate.id + ". " + expireDate.label}
                    </Typography>
                    <DatePickerField
                        className="pb-3"
                        name={expireDate.name}
                        label={expireDate.label}
                        read={props.hasAuthority ? false : true}
                    />
                    {props.readAsylumSensitiveData && <>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {completedFormData.id + ". " + completedFormData.label}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {checksByUBK.id + ". " + checksByUBK.label}
                        </Typography>
                        <SelectField name={checksByUBK.name} label={checksByUBK.label} data={anbAnswers} className="pb-3" read={props.hasAuthority ? false : true} />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {dateOfSubmissionForm.id + ". " + dateOfSubmissionForm.label}
                        </Typography>
                        <DatePickerField
                            className="pb-3"
                            name={dateOfSubmissionForm.name}
                            label={dateOfSubmissionForm.label}
                            {...datePickerProps}
                            disabled
                        />
                    </>}
                </Grid>
            </Grid>
        </>
    )
}