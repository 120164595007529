import React, {useEffect} from "react";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import {Button} from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

type props = {
    style?: React.CSSProperties
    direction?: boolean //true = up, false = down
}

export function AutoScroller(props: props) {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        window.scrollTo({top: 100, left: 0, behavior: 'smooth'});
    }, []);

    return(
         props.direction ?
                <Button style={props.style}
                        onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>
                    <ArrowCircleUpIcon fontSize={"large"} color={"primary"}/>
                </Button>
            :
             <Button style={props.style} onClick={() => {window.scrollTo({top: 5000, left: 0, behavior: 'smooth'})}}>
                 <ArrowCircleDownIcon fontSize={"large"} color={"primary"}/>
             </Button>
    )
}