export class NomenclatureEntryCreationDTO {
    id?: number | undefined;
    key: string;
    name: string;
    nameSq: string;
    nameEn: string;
    active: boolean | null;

    constructor(id: number | undefined, key: string, name: string, nameSq: string, nameEn: string, active: boolean | null) {
        this.id = id;
        this.key = key;
        this.name = name;
        this.nameSq = nameSq;
        this.nameEn = nameEn;
        this.active = active;
    }

    static empty(): NomenclatureEntryCreationDTO {
        return new NomenclatureEntryCreationDTO(
            undefined,
            "",
            "",
            "",
            "",
            true
        );
    }
}
