import * as Yup from 'yup';
import FormModel from './ShortTermFormModel';
const {
  formField: {
    placeOfRequest,
    temporaryStaySettlementReasonMvrKey,
    temporaryStayReportedByMvrKey,
    lastName,
    firstName,
    genderMvrKey,
    birthDate,
    entranceDate,
    borderPointMvrKey,
    shortTermStayPossibilityMvrKey,
  }
} = FormModel;


export default [
  Yup.object().shape({
    [placeOfRequest.name]: Yup.object().required(`${placeOfRequest.requiredErrorMsg}`),
    [temporaryStaySettlementReasonMvrKey.name]: Yup.string().required(`${temporaryStaySettlementReasonMvrKey.requiredErrorMsg}`),
    [temporaryStayReportedByMvrKey.name]: Yup.string().required(`${temporaryStayReportedByMvrKey.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [genderMvrKey.name]: Yup.string().required(`${genderMvrKey.requiredErrorMsg}`),
    [birthDate.name]: Yup.string().required(`${birthDate.requiredErrorMsg}`),
    [entranceDate.name]: Yup.string().required(`${entranceDate.requiredErrorMsg}`),
    [borderPointMvrKey.name]: Yup.string().required(`${borderPointMvrKey.requiredErrorMsg}`),
  }),

  Yup.object().shape({
    [shortTermStayPossibilityMvrKey.name]: Yup.string().required(`${shortTermStayPossibilityMvrKey.requiredErrorMsg}`),
  }),
  
];
