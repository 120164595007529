import { TemplateDTO } from "../models/templates/TemplateDTO";
import axiosInstance from "../network/AxiosInstance";
import { AuthService } from "./AuthService";

export class TemplateService {

    static async getTemplatesForForeignCitizen(): Promise<Array<TemplateDTO>> {
        const response = await axiosInstance.get<Array<TemplateDTO>>(`${AuthService.PREFIX}/foreign-citizens/templates/foreign-citizens`);
        const data = response.data;
        return data;
    }

    static async getTemplatesForAsylumRequests(): Promise<Array<TemplateDTO>> {
        const response = await axiosInstance.get<Array<TemplateDTO>>(`${AuthService.PREFIX}/foreign-citizens/templates/asylum-requests`);
        const data = response.data;
        return data;
    }

    static async getTemplatesForTemporaryStayRequests(): Promise<Array<TemplateDTO>> {
        const response = await axiosInstance.get<Array<TemplateDTO>>(`${AuthService.PREFIX}/foreign-citizens/templates/temporary-stay-requests`);
        const data = response.data;
        return data;
    }

    static async generateTemplate(id: number, name:string, fileName: string): Promise<void> {
        
        const url = `${AuthService.PREFIX}/foreign-citizens/registrations/${id}/generate-templates`;
        const params = { name: name };

        try {
            const response = await axiosInstance.post(url, null, {
            params: params,
            responseType: 'blob'
            });
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const fileURL = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = fileURL;
            link.download = fileName;
            document.body.appendChild(link);

            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(fileURL);
        } catch (error) {
        }
    }
}