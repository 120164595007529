import {styled} from "@mui/material/styles";
import {Tooltip, tooltipClasses} from "@mui/material";
import React from "react";

interface TooltipProps {
    title: string | undefined | any,
    children?: React.ReactNode | any,
    className?: string,
    followCursor?: boolean,
    arrow?: boolean
    customWidth?: string
}

export function LightTooltip({title, children, className, customWidth} : TooltipProps) {

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} children={children}/>
))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            fontSize: 12,
            width: customWidth && customWidth
        },
    }));

    return (
        <LightTooltip title={title} arrow={true} className={className}>
        {children}
        </LightTooltip>
)
}
