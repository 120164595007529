export class ArrayUtils {
    static first<T>(arr: T[] | undefined): T | undefined {
        if (arr === undefined) {
            return undefined;
        }

        if ((arr?.length ?? 0) === 0) {
            return undefined;
        }

        return arr[0];
    }
}
