import { PersonDataReducedDTO } from "../PersonDataReducedDTO";
import { UserReducedDTO } from "../UserReducedDTO";
import { EvidencyReasonDTO } from "../evidency/EvidencyReason";
import { SettlementReasonDTO } from "../settlement/SettlementReasonDTO";

export class ForeignCitizenReducedDTO{
    id : number;
    dateCreated : Date;
    dateModified : Date;
    createdBy : UserReducedDTO;
    active : boolean | null;
    evidencyReason : EvidencyReasonDTO;
    settlementReasonDTO : SettlementReasonDTO;
    personData : PersonDataReducedDTO;
    archiveNumber?:string;

    constructor(json:ForeignCitizenReducedDTO) {
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.createdBy = json.createdBy;
        this.active = json.active;
        this.evidencyReason = json.evidencyReason;
        this.settlementReasonDTO = json.settlementReasonDTO;
        this.personData = json.personData;
        this.archiveNumber = json.archiveNumber;
    }
}
