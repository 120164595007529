
import { DecisionCreationDTO } from "../models/decision/DecisionCreationDTO";
import { DecisionDTO } from "../models/decision/DecisionDTO";
import axiosInstance from "../network/AxiosInstance";
import { AuthService } from "./AuthService";
import { Page } from "../components/Page";
import { ComplaintCreationDTO } from "../models/complaint/ComplaintCreationDTO";
import { ComplaintDTO } from "../models/complaint/ComplaintDTO";
import { AppealCreationDTO } from "../models/appeal/AppealCreationDTO";
import { AppealDTO } from "../models/appeal/AppealDTO";
import { AppealUpdateDTO } from "../models/appeal/AppealUpdateDTO";
import { ComplaintUpdateDTO } from "../models/complaint/ComplaintUpdateDTO";

export class DecisionService {

    static async createDecision(id: number, decisionCreationDTO: DecisionCreationDTO, childRegistrationIds: number[]): Promise<DecisionDTO> {
        var params = new URLSearchParams();
        for(let i=0; i<childRegistrationIds.length; i++) {
            params.append("childRegistrationIds", childRegistrationIds[i].toString())
        }
        var request = {
            params: params
        }
        const response = await axiosInstance.post<DecisionDTO>(`${AuthService.PREFIX}/foreign-citizens/registrations/${id}/decision`, decisionCreationDTO, request);
        const data = response.data;
        return new DecisionDTO(data);
    }

    static async getAllDecisionsForRegistration(page: number, size: number, id: number) {
        const response = await axiosInstance.get<Page<DecisionDTO>>(`${AuthService.PREFIX}/foreign-citizens/registrations/${id}/decisions-paged`, {
            params: { page, size },
        });
        const data = response.data;
        return new Page<DecisionDTO>(
            data.content.map(decision => new DecisionDTO(decision)),
            data.totalElements
        )
    }

    static async getDecisionById(id: number, decisionId: number): Promise<DecisionDTO> {
        const response = await axiosInstance.get<DecisionDTO>(`/rest/foreign-citizens/registrations/${id}/decisions/${decisionId}`);
        const data = response.data;

        return new DecisionDTO(data);
    }

    static async createComplaintForDecision(id: number, data: ComplaintCreationDTO): Promise<ComplaintDTO> {
        const response = await axiosInstance.post<ComplaintDTO>(`${AuthService.PREFIX}/foreign-citizens/registrations/${id}/complaint`, data);
        const complaint = response.data;
        return new ComplaintDTO(complaint);
    }

    static async getAllComplaintsForRegistration(id: number, complaintNumber: string | null | undefined, decisionId: number, complaintDate: string | null | undefined,
        complaintDecisionMvrKey: string | null | undefined, page: number, size: number): Promise<Page<ComplaintDTO>> {
        const response = await axiosInstance.get<Page<ComplaintDTO>>(`${AuthService.PREFIX}/foreign-citizens/registrations/${id}/complaints-paged`, {
            params: { complaintNumber, decisionId, complaintDate, complaintDecisionMvrKey, page, size },
        });
        const data = response.data;
        return new Page<ComplaintDTO>(
            data.content.map(complaint => new ComplaintDTO(complaint)),
            data.totalElements
        )
    }

    static async createAppealForDecision(id: number, data: AppealCreationDTO): Promise<AppealDTO> {
        const response = await axiosInstance.post<AppealDTO>(`${AuthService.PREFIX}/foreign-citizens/registrations/${id}/appeal`, data);
        const appeal = response.data;
        return new AppealDTO(appeal);
    }

    static async getAllAppealsForRegistration(id: number, appealNumber: string | null | undefined, decisionId: number, appealDate: string | null | undefined,
        appealDecisionMvrKey: string | null | undefined, page: number, size: number): Promise<Page<AppealDTO>> {
        const response = await axiosInstance.get<Page<AppealDTO>>(`${AuthService.PREFIX}/foreign-citizens/registrations/${id}/appeals-paged`, {
            params: { appealNumber, decisionId, appealDate, appealDecisionMvrKey, page, size },
        });
        const data = response.data;
        return new Page<AppealDTO>(
            data.content.map(appeal => new AppealDTO(appeal)),
            data.totalElements
        )
    }
    
    static async getAppealById(id: number, appealId: number): Promise<AppealDTO> {
        const response = await axiosInstance.get<AppealDTO>(`${AuthService.PREFIX}/foreign-citizens/registrations/${id}/appeals/${appealId}`)
        const data = response.data;

        return new AppealDTO(data);
    }

    static async updateAppeal(id: number, appealId: number, appealUpdateDTO: AppealUpdateDTO): Promise<AppealDTO> {
        const response = await axiosInstance.put<AppealDTO>(`${AuthService.PREFIX}/foreign-citizens/registrations/${id}/appeals/${appealId}`,appealUpdateDTO)
        const data = response.data;
        return new AppealDTO(data);
    }

    static async getComplaintById(id: number, complaintId: number): Promise<ComplaintDTO> {
        const response = await axiosInstance.get<ComplaintDTO>(`${AuthService.PREFIX}/foreign-citizens/registrations/${id}/complaints/${complaintId}`)
        const data = response.data;

        return new ComplaintDTO(data);
    }

    static async updateComplaint(id: number, complaintId: number, complaintUpdateDTO: ComplaintUpdateDTO): Promise<ComplaintDTO> {
        const response = await axiosInstance.put<ComplaintDTO>(`${AuthService.PREFIX}/foreign-citizens/registrations/${id}/complaints/${complaintId}`,complaintUpdateDTO)
        const data = response.data;
        return new ComplaintDTO(data);
    }
}