import {PersonDTO} from "./PersonDTO";
import {RoleDTO} from "./RoleDTO";

export class UserDTO{
    id : number;
    dateCreated : Date;
    dateModified : Date;
    email : string;
    person : PersonDTO;
    roles : RoleDTO[];
    enabled : boolean;

    constructor(json:any) {
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.email = json.email;
        this.person = json.person;
        this.roles = json.roles;
        this.enabled = json.enabled;
    }
}
