import FormModel from './FormModel';
const {
  formField: {
    //region 1
    archiveNumber,
    archiveDate,
    fileNumber,
    evidencyReasonMvrKey,
    placeOfRequest,
    embg,
    lastName,
    maidenName,
    parentName,
    parentSurname,
    motherName,
    motherNameCyrillic,
    motherSurname,
    firstName,
    genderMvrKey,
    citizenShip,
    otherCitizenShip,
    birthDate,
    abroadBirthPlace,
    placeOfBirthAbroad,
    inRSMBirthPlace,
    inRSMBirthMunicipality,
    inRSMBirthPlacePlace,
    occupation,
    education,
    foreignLanguage,
    marriageStatus,
    previousLivingPlace,
    place,
    countryPreviousLiving,
    previousSettlementPlace,
    stayPlace,
    stayCountry,
    livingPlaceRSM,
    livingMunicipalityRSM,
    addressDateFrom,
    livingStreetRSM,
    livingStreetNumberRSM,
    livingApartmentEntranceRSM,
    livingApartmentRSM,

    //region2
    passportTypeMvrKey,
    countryPassport,
    passportNumber,
    arrivalDate,
    arrivalWay,
    illegalPlace,
    illegalPlaceMunicipality,
    illegalPlacePlace,
    discovererMvrKey,
    OVR,
    shelterForeigners,
    sourceOfLivelihood,
    employmentApproval,
    statusEmployment,
    employedAt,
    employmentArea,
    organizationEmployment,
    embsOrganization,
    nameOfOrganization,
    municipalityOrganization,
    placeOrganization,
    streetOrganization,
    numberStreetOrganization,
    engagedProperty,

    ///region 3
    settlementReasonMvrKey,
    familyRelationMvrKey,
    approvalDate,
    dateOfExtension,
    endDate,
    startDateStay,
    endDateStay,
    dateOfDeliveryOfDecision,
    decision,
    fileDecision,
    idForeignerNumber,
    dateIdForeignerNumber,
    company,
    companyName,
    companyPlace,
    companyCountry,
    spouseEmbg,
    spouseLastName,
    spouseFirstName,
    spouseBirthDate,
    spouseBirthPlace,
    spouseAbroadBirthPlace,
    placeOfBirthSpouseAbroad,
    municipalityRelative,
    placeRelative,
    streetRelative,
    numberStreetRelative,
    inRSM,
    numberChildren,
    numberOfForeignChildren,
    numberOfDomesticChildren,
    privateVisitPerson,
    embgPrivateVisitPerson,
    lastNamePrivateVisitPerson,
    firstNamePrivateVisitPerson,
    genderPrivateVisitPerson,
    citizen,
    addressPrivateVisitPerson,
    municipalityPrivateVisitPerson,
    placePrivateVisitPerson,
    streetPrivateVisitPerson,
    numberStreetPrivateVisitPerson,
    apartmentEntrancePrivateVisitPerson,
    apartmentPrivateVisitPerson,

    // region 4
    measureTakenType,
    measureTakenReason,
    placeMeasuresTaken,
    placeMeasuresTakenMunicipality,
    placeMeasuresTakenPlace,
    dateMeasuresTaken,
    OVRMeasuresTaken,
    attemptIllegal,
    attemptIllegalPlace,
    attemptIllegalDate,
    residenceBanStartDate,
    residenceBanEndDate,
    noteForeigner,
    dateEvidented,
    checksPerformed,
    firstNameCyrillic,
    lastNameCyrillic,
    parentNameCyrillic,
  }
} = FormModel;

export default {
  //region 1
  [archiveNumber.name]:'',
  [archiveDate.name]:'',
  [fileNumber.name]:'',
  [evidencyReasonMvrKey.name]:'',
  [placeOfRequest.name]:'',
  [embg.name]: '',
  [lastName.name]: '',
  [maidenName.name]: '',
  [parentName.name]: '',
  [parentSurname.name]:'',
  [motherName.name]:'',
  [motherNameCyrillic.name]:'',
  [motherSurname.name]:'',
  [firstName.name]: '',
  [genderMvrKey.name]: '',
  [citizenShip.name]: '',
  [otherCitizenShip.name]: '',
  [birthDate.name]: '',
  [abroadBirthPlace.name]: '',
  [placeOfBirthAbroad.name]:'',
  [inRSMBirthPlace.name]: '',
  [inRSMBirthMunicipality.name]: '',
  [inRSMBirthPlacePlace.name]: '',
  [occupation.name]: '',
  [education.name]: '',
  [foreignLanguage.name]: '',
  [marriageStatus.name]: '',
  [previousLivingPlace.name]: '',
  [place.name]: '',
  [countryPreviousLiving.name]: '',
  [previousSettlementPlace.name]: '',
  [stayPlace.name]: '',
  [stayCountry.name]: '',
  [livingPlaceRSM.name]: '',
  [livingMunicipalityRSM.name]: '',
  [addressDateFrom.name]: '',
  [livingStreetRSM.name]: '',
  [livingStreetNumberRSM.name]: '',
  [livingApartmentEntranceRSM.name]: '',
  [livingApartmentRSM.name]: '',

  //region 2
  [passportTypeMvrKey.name]: '',
  [countryPassport.name]: '',
  [passportNumber.name]: '',
  [arrivalDate.name]: '',
  [arrivalWay.name]: '',
  [illegalPlace.name]: '',
  [illegalPlaceMunicipality.name]: '',
  [illegalPlacePlace.name]: '',
  [discovererMvrKey.name]: '',
  [OVR.name]: '',
  [shelterForeigners.name]: '',
  [sourceOfLivelihood.name]: '',
  [employmentApproval.name]: '',
  [statusEmployment.name]: '',
  [employedAt.name]: '',
  [employmentArea.name]: '',
  [organizationEmployment.name]: '',
  [embsOrganization.name]:'',
  [nameOfOrganization.name]: '',
  [municipalityOrganization.name]:'',
  [placeOrganization.name]: '',
  [streetOrganization.name]: '',
  [numberStreetOrganization.name]:'',
  [apartmentEntrancePrivateVisitPerson.name]:'',
  [apartmentPrivateVisitPerson.name]:'',
  [engagedProperty.name]: '',

  //region 3
  [settlementReasonMvrKey.name]: '',
  [familyRelationMvrKey.name]: '',
  [approvalDate.name]: '',
  [dateOfExtension.name]: '',
  [endDate.name]: '',
  [startDateStay.name]: '',
  [endDateStay.name]: '',
  [dateOfDeliveryOfDecision.name]:'',
  [decision.name]:'',
  [fileDecision.name]:'',
  [idForeignerNumber.name]: '',
  [dateIdForeignerNumber.name]: '',
  [company.name]: '',
  [companyName.name]: '',
  [companyPlace.name]: '',
  [companyCountry.name]: '',
  [spouseEmbg.name]: '',
  [spouseLastName.name]: '',
  [spouseFirstName.name]: '',
  [spouseBirthDate.name]: '',
  [spouseBirthPlace.name]: '',
  [spouseAbroadBirthPlace.name]: '',
  [placeOfBirthSpouseAbroad.name]: '',
  [inRSM.name]: '',
  [numberChildren.name]: '',
  [numberOfForeignChildren.name]: '',
  [numberOfDomesticChildren.name]: '',
  [municipalityRelative.name]: '',
  [placeRelative.name]: '',
  [streetRelative.name]: '',
  [numberStreetRelative.name]: '',
  [privateVisitPerson.name]: '',
  [embgPrivateVisitPerson.name]: '',
  [lastNamePrivateVisitPerson.name]: '',
  [firstNamePrivateVisitPerson.name]: '',
  [genderPrivateVisitPerson.name]: '',
  [citizen.name]: '',
  [addressPrivateVisitPerson.name]: '',
  [municipalityPrivateVisitPerson.name]: '',
  [placePrivateVisitPerson.name]: '',
  [streetPrivateVisitPerson.name]: '',
  [numberStreetPrivateVisitPerson.name]: '',

  //region 4
  [measureTakenType.name]: '',
  [measureTakenReason.name]: '',
  [placeMeasuresTaken.name]: '',
  [placeMeasuresTakenMunicipality.name]: '',
  [placeMeasuresTakenPlace.name]: '',
  
  [dateMeasuresTaken.name]: '',
  [OVRMeasuresTaken.name]: '',
  [attemptIllegal.name]: '',
  [attemptIllegalPlace.name]: '',
  [attemptIllegalDate.name]: '',
  [residenceBanStartDate.name]: '',
  [residenceBanEndDate.name]: '',
  [noteForeigner.name]: '',
  [dateEvidented.name]: '',
  [checksPerformed.name]: '',
  [firstNameCyrillic.name]: '',
  [lastNameCyrillic.name]: '',
  [parentNameCyrillic.name]: '',
};
