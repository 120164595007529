import { Grid, Typography } from "@mui/material";
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { DatePickerField } from "../../applications/formFields/DatePickerFields";
import InputField from "../../applications/formFields/InputField";
import { NomenclatureService } from "../../../services/NomenclatureService";
import { useEffect, useState } from "react";
import { NomenclatureEntryDTO } from "../../../models/nomenclatures/NomenclatureEntryDTO";
import SelectField from "../../applications/formFields/SelectField";
import MultipleSelectField from "../../applications/formFields/MultipleSelectField";
import InputFieldCyrillic from "../../applications/formFields/InputFieldCyrillic";
import { PaginatedSelect } from "../../../components/PaginatedSelect";
import { useFormikContext } from "formik";
import { strings } from "../../../localization/Localization";
import { NomenclatureConstants } from "../../../constants/NomenclatureConstants";
import { PaginatedMultiSelect } from "../../../components/PaginatedMultiSelect";


export function DataFormAsylumOne(props:any) {
  const [genders, setGenders] = useState<NomenclatureEntryDTO[]>([]);
  const [keyGender, setKeyGender] = useState<string>("genders");
  const [citizenships, setCitizenships] = useState<NomenclatureEntryDTO[]>([]);
  const [keyCitizenship, setKeyCitizenship] = useState<string>("citizenship")
  const [countries,setCountries] = useState<NomenclatureEntryDTO[]>([])
  const [keyCountry, setKeyCountry] = useState<string>("country");
  const [places, setPlaces] = useState<NomenclatureEntryDTO[]>([]);
  const [keyPlace, setKeyPlace] = useState<string>("place");
  const [marriageStatuses, setMarriageStatuses] = useState<NomenclatureEntryDTO[]>([]);
  const [keyMarriageStatus, setKeyMarriageStatus] = useState<string>("marriage_statuses");
  const [ethnicOrg, setEthnicOrg] = useState<NomenclatureEntryDTO[]>([]);
  const [keyEthnicOrg, setKeyEthnicOrg] = useState<string>("ethnicity");
  const [foreignLanguages, setForeignLanguages] = useState<NomenclatureEntryDTO[]>([]);
  const [keyForeignLanguages, setKeyForeignLanguages] = useState<string>("foreign_languages");
  const [religionNom, setReligionNom] = useState<NomenclatureEntryDTO[]>([]);
  const [keyReligion, setKeyReligion] = useState<string>("religion");
  const [intentionNom, setintentionNom] = useState<NomenclatureEntryDTO[]>([]);
  const [keyIntention, setKeyIntention] = useState<string>("intention");
  const [placeOfRequest, setplaceOfRequest] = useState<NomenclatureEntryDTO[]>([]);
  const [keyPlaceOfRequest, setKeyPlaceOfRequest] = useState<string>("place_of_request");
  const formikProps = useFormikContext<any>()
  const [disabledAbroad, setDisabledAbroad] = useState<boolean>();
  const [disabledRSM, setDisabledRSM] = useState<boolean>();

  const {
    formField: {
      lastName,
      maidenName,
      fathersName,
      name,
      gender,
      citizenShip,
      birthDate,
      otherNameOrNickName,
      countryAndCityOfBirth,
      maritalStatus,
      ethnicOrigin,
      surnameAndNameOfTheSpouse,
      identityDocuments,
      nationalPassport,
      identificationDocument,
      drivingLicense,
      others,
      motherTongueAndOtherLanguages,
      countryOfBirth,
      placeOfBirth,
      inRSMBirthMunicipality,
      inRSMBirthPlacePlace,
      mothersName,
      religion,
      noneOfAbove,
      archiveNumber,
      dossierNumber,
      archiveDate,
      about,
      embg,
      existingMacEmbg,
      placeOfSubbmision,
      nameCyrillic,
      surnameCyrillic,
      fathersNameCyrillic,
      mothersNameCyrillic,
      fathersLastName,
      mothersLastName
    }
  } = props;


  async function getAllEntriesForNomenclatureForeignLanguage(): Promise<void> {
    const foreignLanguages = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyForeignLanguages);
    setForeignLanguages(foreignLanguages)

  }

  async function getAllEntriesForNomenclatureGender(): Promise<void> {
    const genders = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyGender);
    setGenders(genders)
  }

  async function getAllEntriesForNomenclatureCitizenship(): Promise<void> {
    const citizenships = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyCitizenship);
    setCitizenships(citizenships)
  }

  async function getAllEntriesForNomenclatureCountry(): Promise<void> {
    const countries = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyCountry);
    setCountries(countries)
  }

  async function getAllEntriesForNomenclaturePlace(): Promise<void> {
    const places = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyPlace);
    setPlaces(places)
  }

  async function getAllEntriesForNomenclatureMarriageStatus(): Promise<void> {
    const marriage_statuses = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyMarriageStatus);
    setMarriageStatuses(marriage_statuses)
  }

  async function getAllEntriesForNomenclatureEthnicity(): Promise<void> {
    const ethnic_org = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyEthnicOrg);
    setEthnicOrg(ethnic_org)
  }

  async function getAllEntriesForNomenclatureReligion(): Promise<void> {
    const religion = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyReligion);
    setReligionNom(religion)
  }

  async function getAllEntriesForNomenclatureIntention(): Promise<void> {
    const intention = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyIntention);
    setintentionNom(intention)
  }

  async function getAllEntriesForNomenclaturePlaceOfRequest(): Promise<void> {
    const placeData = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyPlaceOfRequest);
    setplaceOfRequest(placeData)
  }
  useEffect(()=>{
    if(formikProps.values.countryOfBirth || formikProps.values.placeOfBirth){
      setDisabledRSM(true);
    }else{
      setDisabledRSM(false);
    }
    if(formikProps.values.inRSMBirthMunicipality || formikProps.values.inRSMBirthPlacePlace){
      setDisabledAbroad(true);
    }else{
      setDisabledAbroad(false)
    }
  },[formikProps.values.countryOfBirth, formikProps.values.placeOfBirth, formikProps.values.inRSMBirthMunicipality,formikProps.values.inRSMBirthPlacePlace, disabledAbroad, disabledRSM])
  useEffect(()=>{
    getAllEntriesForNomenclatureGender()
    getAllEntriesForNomenclatureCitizenship()
    getAllEntriesForNomenclatureCountry()
    getAllEntriesForNomenclaturePlace()
    getAllEntriesForNomenclatureMarriageStatus()
    getAllEntriesForNomenclatureForeignLanguage()
    getAllEntriesForNomenclatureEthnicity()
    getAllEntriesForNomenclatureReligion()
    getAllEntriesForNomenclatureIntention()
    getAllEntriesForNomenclaturePlaceOfRequest()
  },[])
  useEffect(()=>{
    props.func(!(formikProps.values.name || formikProps.values.lastName || formikProps.values.archiveNumber));
  },[formikProps.values.name,formikProps.values.lastName,formikProps.values.archiveNumber])
  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {/* Grid 1 */}
        <Grid item xs={12} sm={4} md={6}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "49%" }}>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom >
            {archiveNumber.label}
          </Typography>
          <InputField name={archiveNumber.name} label={archiveNumber.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          </div>
          <div style={{ width: "49%" }}>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom >
            {archiveDate.label}
          </Typography>
          <DatePickerField name={archiveDate.name} label={archiveDate.placeholder} className="pb-3" read={props.hasAuthority ? false : true} />
          </div>
          </div>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom >
            {placeOfSubbmision.label}
          </Typography>
          {/*<SelectField name={placeOfSubbmision.name} label={placeOfSubbmision.placeholder} data={placeOfRequest} className="pb-3" read={props.hasAuthority ? false : true} />*/}
          <PaginatedSelect<NomenclatureEntryDTO>
              value={formikProps.values.placeOfSubmission !== "" ? formikProps.values.placeOfSubmission : undefined}
              onChange={(value) => {formikProps.setFieldValue("placeOfSubmission", value)}}
              label={placeOfSubbmision.placeholder}
              valueMapper={(item) => item?.key && item?.key?.toString()}
              keyMapper={(item) => item.id.toString()}
              itemMapper={(item) => <>{item.name}</>}
              labelMapper={(item) => item.name}
              dataFetcher={(page, size, filter) => {
                return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                    page, size, NomenclatureConstants.organisationalUnit, filter ? filter : "", "", "", undefined, ""
                );
              }}
              name={placeOfSubbmision.name}
              className="pb-3"
              readOnly={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom >
            {about.label}
          </Typography>
          <SelectField className="pb-3"
            name={about.name}
            label={about.placeholder}
            data={intentionNom}
            read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom >
            {embg.label}
          </Typography>
          <InputField name={embg.name} label={embg.placeholder} fullWidth disabled className="pb-3" />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom >
            {existingMacEmbg.label}
          </Typography>
          <InputField name={existingMacEmbg.name} label={existingMacEmbg.placeholder} fullWidth className="pb-3" embgNumber={true}/>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "49%" }}>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom >
            {name.id + ". " + name.label}
          </Typography>
          <InputField name={name.name} label={name.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} icao="true" />
          </div>
          <div style={{ width: "49%" }}>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom >
            {nameCyrillic.label}
          </Typography>
          <InputFieldCyrillic name={nameCyrillic.name} label={nameCyrillic.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "49%" }}>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom >
            {lastName.id + ". " + lastName.label}
          </Typography>
          <InputField name={lastName.name} label={lastName.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} icao="true" />
          </div>
          <div style={{ width: "49%" }}>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom >
            {surnameCyrillic.label}
          </Typography>
          <InputFieldCyrillic name={surnameCyrillic.name} label={surnameCyrillic.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          </div>
          </div>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {maidenName.id + ". " + maidenName.label}
          </Typography>
          <InputField name={maidenName.name} label={maidenName.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {otherNameOrNickName.id + ". " + otherNameOrNickName.label}
          </Typography>
          <InputField name={otherNameOrNickName.name} label={otherNameOrNickName.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "49%" }}>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {fathersName.id + ". " + fathersName.label}
          </Typography>
          <InputField name={fathersName.name} label={fathersName.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} icao="true" />
          </div>
          <div style={{ width: "49%" }}>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {fathersLastName.label}
          </Typography>
          <InputField name={fathersLastName.name} label={fathersLastName.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} icao="true" />
          </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "49%" }}>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {mothersName.label}
          </Typography>
          <InputField name={mothersName.name} label={mothersName.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} icao="true"/>
          </div>
          <div style={{ width: "49%" }}>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {mothersLastName.label}
          </Typography>
          <InputField name={mothersLastName.name} label={mothersLastName.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} icao="true" />
           </div>
          </div>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {birthDate.id + ". " + birthDate.label}
          </Typography>
          <DatePickerField
            name={birthDate.name}
            label={birthDate.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
            maxDate={true}
          />
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {countryAndCityOfBirth.id + ". " + countryAndCityOfBirth.label}
          </Typography>
          <br/>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {countryOfBirth.id + ". " + countryOfBirth.label}
          </Typography>
          <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.countryOfBirth!=="" ? formikProps.values.countryOfBirth : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("countryOfBirth", value)
                          }
                        }
                        label={strings.countryBirth + "*"}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={countryOfBirth.name}
                        className="pb-3"
                        disabled={disabledAbroad}
                        readOnly={props.hasAuthority ? false : true}
                      />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {placeOfBirth.id + ". " + placeOfBirth.label}
          </Typography>
          <InputField name={placeOfBirth.name} label={placeOfBirth.placeholder} disabled={disabledAbroad} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />

            <Typography variant="subtitle1" gutterBottom>
              {inRSMBirthMunicipality.id + ". " + inRSMBirthMunicipality.label + "*"}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                value={formikProps.values.inRSMBirthMunicipality!=="" ? formikProps.values.inRSMBirthMunicipality : undefined}
                onChange={(value) => {
                  formikProps.setFieldValue("inRSMBirthMunicipality", value);
                  formikProps.setFieldValue("inRSMBirthPlacePlace", "");
                }
                }
                label={strings.municipality + "*"}
                valueMapper={(item) => item?.key && item?.key?.toString()}
                keyMapper={(item) => item.id.toString()}
                itemMapper={(item) => <>{item.name}</>}
                labelMapper={(item) => item.name}
                dataFetcher={(page, size, filter) => {
                  return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                      page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                  );
                }}
                name={inRSMBirthMunicipality.name}
                className="pb-3"
                disabled={disabledRSM}
                readOnly={props.hasAuthority ? false : true}
            />
            <Typography variant="subtitle1" gutterBottom>
              {inRSMBirthPlacePlace.id + ". " + inRSMBirthPlacePlace.label + "*"}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                value={formikProps.values.inRSMBirthPlacePlace!=="" ? formikProps.values.inRSMBirthPlacePlace : undefined}
                onChange={(value) => {
                  formikProps.setFieldValue("inRSMBirthPlacePlace", value)
                }
                }
                label={strings.place + "*"}
                valueMapper={(item) => item?.key && item?.key?.toString()}
                keyMapper={(item) => item.id.toString()}
                itemMapper={(item) => <>{item.name}</>}
                labelMapper={(item) => item.name}
                dataFetcher={(page, size, filter) => {
                  return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                      page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.inRSMBirthMunicipality.key}`, filter ? filter : ""
                  );
                }}
                name={inRSMBirthPlacePlace.name}
                className="pb-3"
                disabled={disabledRSM}
                readOnly={props.hasAuthority ? false : true}
            />
            {/*  working here*/}
          </CenteredCardLayout>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {gender.id + ". " + gender.label}
          </Typography>
          <SelectField className="pb-3"
            name={gender.name}
            label={gender.placeholder}
            data={genders}
            read={props.hasAuthority ? false : true}
          />
          </Grid>
        <Grid item xs={12} sm={4} md={6}>
            {/* Grid 2 */}
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {citizenShip.id + ". " + citizenShip.label}
          </Typography>
                <PaginatedSelect<NomenclatureEntryDTO>
                    value={formikProps.values.citizenShip !== "" ? formikProps.values.citizenShip : undefined}
                    onChange={(value) => {
                      formikProps.setFieldValue("citizenShip", value);
                    }}
                    label={strings.citizenship + "*"}
                    valueMapper={(item) => item?.name || ""}
                    keyMapper={(item) => item.id.toString()}
                    itemMapper={(item) => <>{item.name}</>}
                    labelMapper={(item) => item.name}
                    dataFetcher={(page, size, filter) => {
                      return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                        page, size, NomenclatureConstants.citizenship, filter ? filter : "", "", "", undefined, "",true
                      );
                    }}
                    name={citizenShip.name}
                    className="pb-3"
                    readOnly={props.hasAuthority ? false : true}
                  />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {ethnicOrigin.id + ". " + ethnicOrigin.label}
          </Typography>
          <InputField name={ethnicOrigin.name} label={ethnicOrigin.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} icao="true" />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {religion.id + ". " + religion.label}
          </Typography>
          <SelectField className="pb-3"
            name={religion.name}
            label={religion.placeholder}
            data={religionNom}
            read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
              {maritalStatus.id + ". " + maritalStatus.label}
            </Typography>
            <SelectField className="pb-3"
            name={maritalStatus.name}
            label={maritalStatus.placeholder}
            data={marriageStatuses}
            read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {surnameAndNameOfTheSpouse.id + ". " + surnameAndNameOfTheSpouse.label}
          </Typography>
          <InputField name={surnameAndNameOfTheSpouse.name} label={surnameAndNameOfTheSpouse.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {motherTongueAndOtherLanguages.id + ". " + motherTongueAndOtherLanguages.label}
          </Typography>
          <PaginatedMultiSelect<NomenclatureEntryDTO>
                        value={formikProps.values.motherTongueAndOtherLanguages!=="" ? formikProps.values.motherTongueAndOtherLanguages : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("motherTongueAndOtherLanguages", value)
                          }
                        }
                        label={motherTongueAndOtherLanguages.label}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.foreignLanguages, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={motherTongueAndOtherLanguages.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {identityDocuments.id + ". " + identityDocuments.label}
          </Typography>
          <br/>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {nationalPassport.id + ". " + nationalPassport.label}
          </Typography>
          <InputField name = {nationalPassport.name} label = {nationalPassport.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {identificationDocument.id + ". " + identificationDocument.label}
          </Typography>
          <InputField name = {identificationDocument.name} label = {identificationDocument.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {drivingLicense.id + ". " + drivingLicense.label}
          </Typography>
          <InputField name = {drivingLicense.name} label = {drivingLicense.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {others.id + ". " + others.label}
          </Typography>
          <InputField name = {others.name} label = {others.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {noneOfAbove.id + ". " + noneOfAbove.label}
          </Typography>
          <InputField name = {noneOfAbove.name} label = {noneOfAbove.label} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
          </CenteredCardLayout>
        </Grid>
      </Grid>
    </>
  )
}
