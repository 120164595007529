export class UserDetailsInfoResponse {
    username: string;
    fullName: string;
    email: string;
    workingUnit: string;
    constructor(json:UserDetailsInfoResponse){
        this.username = json.username;
        this.fullName = json.fullName;
        this.email = json.email;
        this.workingUnit = json.workingUnit;
    }
}