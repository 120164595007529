import {Box, Button, FormControl, Grid, SelectChangeEvent} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LightTooltip } from "../../components/LightTooltip";
import { SideBarPage } from "../../components/SideBarPage";
import { PageTable } from "../../components/table/PageTable";
import { strings } from "../../localization/Localization";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import { Routes } from "../../router/Routes";
import { ForeignCitizensService } from "../../services/ForeignCitizensService";
import { NumberParser } from "../../utils/NumberParser";
import { RegistrationReducedDTO } from "../../models/registration/RegistrationReducedDTO";
import { BooleanParser } from "../../utils/BooleanParser";
import { RegistrationTypeService } from "../../services/RegistrationTypeService";
import { NomenclatureEntryDTO } from "../../models/nomenclatures/NomenclatureEntryDTO";
import { NomenclatureService } from "../../services/NomenclatureService";
import {NomenclatureConstants} from "../../constants/NomenclatureConstants";
import {RegistrationTypes} from "../../constants/RegistrationTypes";
import {Dayjs} from "dayjs";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import { Privileges } from "../../models/nomenclatures/Privileges";
import { AuthService } from "../../services/AuthService";
import { StorageService } from "../../storage/StorageService";
import { WorkingVersionDTO } from "../../models/foreign_citizens/WorkingVersionDTO";
import ConfirmationModal from "../../components/ConfirmationModal";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

interface props {
  submitted: boolean
  title: string
}

export function ForeignersSubmittedShortStayForms({submitted, title}: props) {

  const [searchParams, setSearchParams] = useSearchParams();
  const page = NumberParser.parseNumber(searchParams.get("page"), 0);
  const rowsPerPage = NumberParser.parseNumber(searchParams.get("size"), 5);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [submittedForeignCitizensForms, setSubmittedForeignCitizensForms] = useState<RegistrationReducedDTO[]>([]);
  const [evidencyReasons, setEvidencyReasons] = useState<NomenclatureEntryDTO[]>([]);
  const [firstName, setFirstName] = useState<string>("");
  const [parentName, setParentname] = useState<string>("")
  const [motherName, setMotherName] = useState<string>("")
  const [citizenship, setCitizenship] = useState<NomenclatureEntryDTO | undefined>(undefined)
  // const [citizenships, setCitizenships] = useState<NomenclatureEntryDTO[]>([])
  const [registrationDateFrom, setRegistrationDateFrom] = useState<string | undefined | null>(null)
  const [registrationDateTo, setRegistrationDateTo] = useState<string | undefined | null>(null)
  const [lastName, setLastName] = useState<string>("");
  const active = BooleanParser.parseBooleanNullable(searchParams.get("active"));
  const email = searchParams.get("email") ?? "";
  const [embg, setEmbg] = useState<string>("");
  const [holder, setHolder] = useState<boolean | null | undefined>(undefined);
  const [activeSubmittedForm, setActiveSubmittedForm] = useState<boolean | null | undefined>(undefined);
  const [birthDate, setBirthDate] = useState<string | undefined | null>(null);
  const settlementReasonMvrKey = searchParams.get("settlementReasonMvrKey") ?? "";
  const [evidenceReasonMvrKey, setEvidencyReasonMvrKey] = useState<string>("");
  const [registrationTypeKey, setRegistrationTypeKey] = useState<string>("");
  const [evidencyReasonMvrKeySelectedValue, setEvidencyReasonMvrKeySelectedValue] = useState("");
  const [settlementReasonMvrKeySelectedValue, setSettlementReasonMvrKeySelectedValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [residenceReasons, setResidenceReasons] = useState<NomenclatureEntryDTO[]>([])
  const [hasAuthority, setHasAuthority] = useState<boolean>();
  const [keyEvidency, setKeyEvidency] = useState<string>(NomenclatureConstants.basis);
  const [keyReasons, setKeyReasons] = useState<string>(NomenclatureConstants.reasons);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openForeign, setOpenForeign] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [archiveNumber, setArchiveNumber] = useState<string>("")
  const [headerHidden,setHeaderHidden] = useState<boolean>(true)
  function setSearchParam(key: string, value: string | null | undefined) {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }

    setSearchParams(searchParams)
  }

  function setPage(page: number) {
    setSearchParam("page", page.toString());
  }

  function setRowsPerPage(rowsPerPage: number) {
    setSearchParam("size", rowsPerPage.toString());
  }

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleEmbgChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputEMBG = event.target.value;
    const numericRestriction = /^\d*$/;

    if(numericRestriction.test(inputEMBG) && inputEMBG.length <= 13){
      setEmbg(inputEMBG)
    }
    else {
      return
    }
    setPage(0);
  }

  function handleFirstNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFirstName(event.target.value);
    setPage(0);
  }
  function handleParentNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setParentname(event.target.value);
    setPage(0);
  }
  function handleMotherNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setMotherName(event.target.value);
    setPage(0);
  }
  function handleArchiveNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    setArchiveNumber(event.target.value);
    setPage(0);
  }

  function handleLastNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setLastName(event.target.value);
    setPage(0);
  }

  function handleHolder(holder: boolean | undefined): void {
    setHolder(holder);
    setPage(0);
  }


  function handleActiveSubmittedForm(activeSubmittedForm: boolean | undefined): void {
    setActiveSubmittedForm(activeSubmittedForm);
    setPage(0);
  }

  function handleBirthDateChange(date : Dayjs) {
    setBirthDate(DateTimeUtils.formatDateDayJs(date)?.toString() ?? null);
    setPage(0);
  }

  function handleCitizenshipChange(value: NomenclatureEntryDTO | undefined): void {
    setCitizenship(value);
    setPage(0);
  }

  function handleRegFromChange(date : Dayjs) {
    setRegistrationDateFrom(DateTimeUtils.formatDateDayJs(date)?.toString() ?? null);
    setPage(0);
  }

  function handleRegToChange(date : Dayjs) {
    setRegistrationDateTo(DateTimeUtils.formatDateDayJs(date)?.toString() ?? null);
    setPage(0);
  }

  function handleRemoveSettlementReasonFilter() {
    setSearchParam("settlementReasonMvrKey", "");
    setSettlementReasonMvrKeySelectedValue("")
    setPage(0);
  }


  const handleSettlementReasonChange = (event: SelectChangeEvent) => {
    // NomenclatureService.getNomenclatureEntryById(parseInt(event.target.value)).then((res) => {
    //   setSearchParam("settlementReasonMvrKey", res.key)
    //   setPage(0);
    // })
    setSearchParam("settlementReasonMvrKey", event.target.value)
    setPage(0);
  };


  const handleEvidencyReasonChange = (event: SelectChangeEvent) => {
    // NomenclatureService.getNomenclatureEntryById(parseInt(event.target.value)).then((res) => {
    //    setSearchParam("evidencyReasonMvrKey", res.key)
    //    setPage(0);
    //  })
    setSearchParam("evidencyReasonMvrKey", event.target.value)
    setPage(0);
  };
  function checkDateModified(workingVersion:WorkingVersionDTO | undefined){
    if(workingVersion?.newerThanMainVersion){
     return true
    }else {
     return false
    }
   }
   function handleCloseForeign(){
    setOpenForeign(false)
  }
  function navigateForeingUserDetails(id: number): any {
    setId(id)
    let route = Routes.EDIT_SHORT_TERM_STAY_APPLICATION;
    let workingVersion = submittedForeignCitizensForms.find((item)=>item.id === id)?.workingVersionRegistrationId;

    if(!submitted){
      if(workingVersion){
        route = route.replace(":id", workingVersion?.id!.toString());
      }
    }else{
      route = route.replace(":id", id.toString());
    }
    if(!checkDateModified(workingVersion)){
      navigateWithNavigatedFrom(route);
    }else{
      setOpenForeign(true)
    }
    
  }
  function navigateToRegistration(id: number) {
    let route = Routes.EDIT_SHORT_TERM_STAY_APPLICATION;
    route = route.replace(":id", id.toString());
    navigateWithNavigatedFrom(route);
  }
  
  function navigateToWorkingVersion(id: number) {
    let idWorkingVersion = submittedForeignCitizensForms.find((item) => item.id === id)?.workingVersionRegistrationId?.id;
      let route = Routes.EDIT_SHORT_TERM_STAY_APPLICATION;
      if (idWorkingVersion) {
        route = route.replace(":id", idWorkingVersion?.toString());
        navigate(route)
      }
    }
  
  const commonWidth = "10%"
  const headerRows = [
    // {
    //   id: "ordinalNumber",
    //   label: strings.ordinalNumber,
    //   width: "5%"
    // },
    {
      id: "еmbg",
      label: strings.embg,
      width: commonWidth
    },
    {
      id: "firstName",
      label: strings.firstName,
      width: commonWidth
    },

    // {
    //   id: "parentName",
    //   label: strings.fathersName,
    //   width: "10%"
    // },
    {
      id: "lastName",
      label: strings.lastName,
      width: commonWidth
    },
    {
      id: "archiveNumber",
      label: strings.archiveNumber,
      width: commonWidth
    },
    {
      id: "holder",
      label: strings.claimHolder,
      width: commonWidth
    },
    {
      id: "activeSubmittedForm",
      label: strings.activeSubmittedForm,
      width: commonWidth
    },
    {
      id: "birthDate",
      label: strings.birthDate,
      width: commonWidth
    },
    {
      id: "evidencyReasonMvrKey",
      label: strings.evidencyReason,
      width: commonWidth
    },
    {
      id: "settlementReasonMvrKey",
      label: strings.settlementReason,
      width: commonWidth
    },
    {
      id: "empty1",
      width: commonWidth
    },
    {
      id: "empty2",
      width: commonWidth
    },
    {
      id: "empty3",

      width: commonWidth
    },
    {
      id: "empty4",
      width: commonWidth
    }
  ]

  const firstSearchRow = [
    // {
    //   id: "id",
    //   width: "5%",
    // },
    {
      id: "embg",
      label: strings.embg,
      search: true,
      type: "text",
      value: embg,
      variant: "standard",
      handleChange: handleEmbgChange,
      width: commonWidth
    },
    {
      id: "firstName",
      label: strings.firstName,
      search: true,
      type: "text",
      value: firstName,
      variant: "standard",
      handleChange: handleFirstNameChange,
      width: commonWidth
    },
    // {
    //   id: "parentName",
    //   label: strings.fathersName,
    //   search: true,
    //   type: "text",
    //   value: parentName,
    //   variant: "standard",
    //   handleChange: handleParentNameChange,
    //   width: "10%"
    // },
    {
      id: "lastName",
      label: strings.lastName,
      search: true,
      type: "text",
      value: lastName,
      variant: "standard",
      handleChange: handleLastNameChange,
      width: commonWidth
    },
    {
      id: "archiveNumber",
      label: strings.archiveNumber,
      search: true,
      type: "text",
      value: archiveNumber,
      variant: "standard",
      handleChange: handleArchiveNumberChange,
      width: commonWidth
    },
    {
      id: "birthDate",
      label: strings.birthDate,
      search: true,
      type: "datePicker",
      variant: "standard",
      value: birthDate,
      handleChange: handleBirthDateChange,
      width: commonWidth
    },
    isLoading ?
    {id: "loader", value: isLoading , type: "loader", search: true} :
    {
      id: "searchFilter",
      value: strings.search,
      type: "button",
      variant: "contained",
      search: true,
      onClick: () => {findAllSubmittedForeignCitizensForms(page); setHeaderHidden(false)},
      width: commonWidth,
      disabled: false,
    }
    
  ]
  const secondSearchRow = [
    {
      id: "holder",
      type: "nullableCheckbox",
      search: true,
      label: strings.claimHolder,
      width: commonWidth,
      value: holder,
      handleChange: handleHolder,
    },
    {
      id: "activeSubmittedForm",
      type: "nullableCheckbox",
      search: true,
      label: strings.activeSubmittedForm,
      width: commonWidth,
      value: activeSubmittedForm,
      handleChange: handleActiveSubmittedForm
    },
    
    {
      id: "evidenceReasonMvrKey",
      value: evidencyReasonMvrKeySelectedValue,
      type: "dropdown",
      elements: evidencyReasons,
      enabled:true,
      variant: "standard",
      search: true,
      label: strings.evidencyReason,
      width: commonWidth,
      handleChange: handleEvidencyReasonChange,
      disabled: true
    },
    {
      id: "settlementReasonMvrKey",
      value: settlementReasonMvrKeySelectedValue,
      type: "dropdown",
      elements: residenceReasons,
      variant: "standard",
      search: true,
      label: strings.settlementReason,
      // handleChange: handleSettlementReasonChange,
      // handleRemoveFilter: handleRemoveSettlementReasonFilter,
      width: commonWidth,
      disabled: true
    },
    {
      id: "parentName",
      label: strings.parentName,
      search: true,
      type: "text",
      value: parentName,
      variant: "standard",
      handleChange: handleParentNameChange,
      width: commonWidth
    },
    {
      id: "motherName",
      label: strings.motherName,
      search: true,
      type: "text",
      value: motherName,
      variant: "standard",
      handleChange: handleMotherNameChange,
      width: commonWidth
    },
  ]

  const thirdSearchRow = [
    {
      id: "citizenshipMvrKey",
      label: strings.citizenship,
      search: true,
      // elements: citizenships,
      type: "paginatedSelect",
      value: citizenship,
      variant: "standard",
      valueMapper: (item: NomenclatureEntryDTO) => item?.key && item?.key?.toString(),
      keyMapper: (item: NomenclatureEntryDTO) => item.id.toString(),
      itemMapper: (item: NomenclatureEntryDTO) => <>{item.name}</>,
      labelMapper: (item: NomenclatureEntryDTO) => item.name,
      dataFetcher: (page: number, size: number, filter: string) => {
        return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
            page, size, NomenclatureConstants.citizenship, filter ? filter : "", "", "", undefined, "", true
        );
      },
      onChange: handleCitizenshipChange,
      name: strings.citizenship,
      width: commonWidth,
      placeholder: strings.citizenship,
      filterLabel: strings.citizenship,

    },
    {
      id: "registrationDateFrom",
      label: strings.registrationDateFrom,
      search: true,
      type: "datePicker",
      value: registrationDateFrom,
      variant: "standard",
      handleChange: handleRegFromChange,
      width: "20%"
    },
    {
      id: "registrationDateTo",
      label: strings.registrationDateTo,
      search: true,
      type: "datePicker",
      value: registrationDateTo,
      variant: "standard",
      handleChange: handleRegToChange,
      width: "20%"
    }
  ]

  const bottomRows = [
    {id: "empty"},
    {id: "empty1"},{id: "empty2"},{id: "empty3"},{id: "empty4"},{id: "empty5"},{id: "empty6"},
    isLoading ?
        {id: "loader", value: isLoading , type: "loader", search: true} :
    {
      id: "searchFilter",
      value: strings.search,
      type: "button",
      variant: "contained",
      search: true,
      onClick: () => findAllSubmittedForeignCitizensForms(page),
      width: "10%",
      disabled: false,
    }
  ]

  const rows = submittedForeignCitizensForms.map((submittedForm) => {
    return {
      id: submittedForm.id,
      values: [
        // {
        //   type: 'text',
        //   name: "id",
        //   width: "10%",
        //   value: <LightTooltip className={"tableTooltip"} title={submittedForm?.id}><span>{submittedForm?.id}</span></LightTooltip>
        // },
        {
          type: 'text',
          name: "embg",
          width: "10%",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.embg}><span>{submittedForm?.embg}</span></LightTooltip>
        },
        {
          type: 'text',
          name: "firstName",
          width: "10%",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.foreignCitizen.personData?.firstName}><span>{submittedForm?.foreignCitizen.personData?.firstName}</span></LightTooltip>
        },
        // {
        //   type: 'text',
        //   name: "parentName",
        //   width: "10%",
        //   value: <LightTooltip className={"tableTooltip"} title={submittedForm?.foreignCitizen.personData?.parentName}><span>{submittedForm?.foreignCitizen.personData?.parentName}</span></LightTooltip>
        // },
        {
          type: 'text',
          name: "lastName",
          width: "10%",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.foreignCitizen.personData?.lastName}><span>{submittedForm?.foreignCitizen.personData?.lastName}</span></LightTooltip>
        },
        {
          type: 'text',
          name: "archiveNumber",
          width: "10%",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.foreignCitizen.archiveNumber}><span>{submittedForm?.foreignCitizen.archiveNumber}</span></LightTooltip>
        },
        {
          type: 'boolean',
          name: "holder",
          value: submittedForm?.holder,
          width: "5%"
        },
        {
          type: 'boolean',
          name: "activeSubmittedForm",
          value: !submittedForm?.isRegistrationPassivated,
          color: submittedForm?.isRegistrationPassivated ? "error" : "success",
          width: "5%"
        },
        {
          type: 'text',
          name: "birthDate",
          width: "17%",
          value: DateTimeUtils.formatDate(submittedForm?.foreignCitizen?.personData?.birthDate?.toString())
        },
        {
          type: 'text',
          name: "evidencyReason",
          width: "10%",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.foreignCitizen.evidencyReason?.displayName}>
            <span>{submittedForm?.foreignCitizen.evidencyReason?.displayName?.length > 55 ? submittedForm?.foreignCitizen.evidencyReason?.displayName.substring(0, 55) + "..." :
                submittedForm?.foreignCitizen.evidencyReason?.displayName}</span></LightTooltip>
        },
        {
          type: 'text',
          name: "setllementReason",
          width: "10%",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.foreignCitizen.settlementReasonDTO?.displayName}>
            <span>{submittedForm?.foreignCitizen.settlementReasonDTO?.displayName?.length > 40 ? submittedForm?.foreignCitizen.settlementReasonDTO?.displayName.substring(0, 40) + "..." :
              submittedForm?.foreignCitizen.settlementReasonDTO?.displayName}</span></LightTooltip>
        },
        {
          type: 'text',
          name: "empty1",
          value: "",
          width: "15%"
        },
        {
          type: 'text',
          name: "empty2",
          value: "",
          width: "15%"
        },
        {
          type: 'text',
          name: "empty3",
          value: "",
          width: "15%"
        },
        {
          type: 'text',
          name: "empty4",
          value: "",
          width: "15%"
        },
      ]
    }

  })

  function navigateWithNavigatedFrom(pathname: string) {
    navigate({
      pathname: pathname,
      search: `?navigatedFrom=${location.pathname}`
    });
  }

  function addForeigner() {
    navigateWithNavigatedFrom(Routes.ADD_SHORT_TERM_STAY_APPLICATION);
  }

  useEffect(() => {
    async function getAllRegistrationTypes() {
      const registrationTypes = await RegistrationTypeService.getAllRegistrationTypes();
      const temporaryStayRegistrationType = registrationTypes.find((registrationType) => registrationType.mvrKey = RegistrationTypes.temporaryStay)
      setRegistrationTypeKey(temporaryStayRegistrationType!.mvrKey!)
    }


    async function getAllEntriesForNomenclatureEvidency(): Promise<void> {
      const evidencyReasons = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyEvidency);
      setEvidencyReasons(evidencyReasons)
    }

    async function getShortTermStayBasisNomenclature(){
      // const shortTermStayBasis = await NomenclatureService.getNomenclatureEntryByKeyAndNomenclatureKey(NomenclatureConstants.shortTermStayRegistrationKey, NomenclatureConstants.basis)
      // setEvidencyReasonMvrKeySelectedValue(shortTermStayBasis.id.toString())
      // setEvidencyReasonMvrKey(shortTermStayBasis.key)
    }
    getAllRegistrationTypes().then((_) => { });
    getAllEntriesForNomenclatureEvidency().then((_) => { });
    getShortTermStayBasisNomenclature().then(()=>{})

  }, []);

  function clearFilter(){
    setFirstName("");
    setLastName("");
    setEmbg("");
    setHolder(undefined);
    setActiveSubmittedForm(undefined);
    setArchiveNumber("");
    setBirthDate(null);
    setHeaderHidden(true);
    setParentname("");
    setMotherName("")
    setCitizenship(undefined);
    setRegistrationDateFrom(null)
    setRegistrationDateTo(null)
  }

  function findAllSubmittedForeignCitizensForms(page: number, resetFilter: boolean = false) {
    setIsLoading(true);
    if(resetFilter){
      clearFilter()
      ForeignCitizensService.findAllSubmittedForeignCitizensForms(page, rowsPerPage, false, registrationTypeKey, "", parentName, "", active, email, "", undefined, "", settlementReasonMvrKey, undefined, null, submitted, "", motherName, citizenship?.key, registrationDateFrom, registrationDateTo).then((data) => {
        setSubmittedForeignCitizensForms(data.content);
        setTotalElements(data.totalElements);
        setIsLoading(false);
      })
    }else{
      ForeignCitizensService.findAllSubmittedForeignCitizensForms(page, rowsPerPage, false, registrationTypeKey, firstName, parentName, lastName, active, email, embg, holder, "", settlementReasonMvrKey, activeSubmittedForm, birthDate, submitted, archiveNumber, motherName, citizenship?.key, registrationDateFrom, registrationDateTo).then((data) => {
        setSubmittedForeignCitizensForms(data.content);
        setTotalElements(data.totalElements);
        setIsLoading(false);
      })
    }
  }

  useEffect(() => {
    if(registrationTypeKey){
      findAllSubmittedForeignCitizensForms(page);
    }
  }, [page])

  useEffect(() => {
    if(registrationTypeKey){
      findAllSubmittedForeignCitizensForms(page, true);
    }
  }, [submitted])

  useEffect(() => {

    async function getAllResidenceReasons(){
      const residenceReasons =  await NomenclatureService.findAllNomenclatureEntriesForNomenclatureList(keyReasons,undefined, `basis=${evidenceReasonMvrKey}`);
      setResidenceReasons(residenceReasons);
    }
    
    if(evidenceReasonMvrKey){
      getAllResidenceReasons();
    }

  }, []);

  useEffect(() => {
    async function userHasAuthority(): Promise<void> {
      const userRoles: string[] | null = StorageService.getUserRoles();
      if(userRoles !== null) {
        if(userRoles.includes(Privileges.MANAGE_SHORT_TERM_STAY))
        {
          setHasAuthority(true)
        }
      } else {
        const authorization = await AuthService.getAuthorization();
        if(authorization.roles.includes(Privileges.MANAGE_SHORT_TERM_STAY)) {
          setHasAuthority(true)
        }
      }
    }

    userHasAuthority().then((_) => { });

  }, []);
  

  return (
    <SideBarPage pageTitle={title} component={
      <React.Fragment>
          <Button style={{float: "left"}} variant={"contained"} onClick={clearFilter} color={"success"} sx={{ height: 40 }} className={"mt-4"}>Исчисти ги филтрите <FilterAltOffIcon fontSize={"medium"} color={"inherit"}/></Button>

        <Box component="div" display={"flex"} justifyContent={"end"} className={"mt-4"}>

          <FormControl>
            {hasAuthority && <Button variant={"contained"} style={{ height: "54.34px" }} onClick={addForeigner}>
              {strings.add}
            </Button>}
          </FormControl>

        </Box>
        <form onSubmit={() => findAllSubmittedForeignCitizensForms(page)}>
            <PageTable
                headerRows={headerRows}
                firstSearchRow={firstSearchRow}
                secondSearchRow={secondSearchRow}
                thirdSearchRow={thirdSearchRow}
                bottomRows={bottomRows}
                rows={rows}
                page={page}
                rowsPerPage={rowsPerPage}
                totalElements={totalElements}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleOpen={navigateForeingUserDetails}
                clickable={true}
                headerHidden={headerHidden}
            />
        </form>
        <ConfirmationModal
          open={openForeign}
          onClose={true}
          handleClose={handleCloseForeign}
          handleConfirm={()=>navigateToRegistration(id)}
          title={strings.workingVerionRegistration}
          content={
            <div>
             {strings.navigateWorkingVersionOrRegistration} 
             <div className="d-flex justify-content-between mt-3">
             <Button onClick={()=>navigateToRegistration(id)}>{strings.navigateRegistration}</Button>
            <Button onClick={()=>navigateToWorkingVersion(id)}>{strings.navigateWorkingVersion}</Button>
            </div>
            </div>
          }
        />

      </React.Fragment>
    } />

  )
}
