import { AddressCreationDTO } from "./AddressCreationDTO";
import { CitizenshipCreationDTO } from "./CitizenshipCreationDTO";
import { CountryCreationDTO } from "./CountryCreationDTO";
import { EducationInstitutionCreationDTO } from "./EducationInstitutionCreationDTO";
import { PersonLanguageCreationDTO } from "./PersonLanguageCreationDTO";
import { PlaceCreationDTO } from "./PlaceCreationDTO";

export class PersonDataCreationDTO {
     citizenshipCreationDTO?:CitizenshipCreationDTO;
     otherCitizenshipCreationDTO?:CitizenshipCreationDTO;
     embg:string;
     existingMacEmbg?:string;
     firstName:string;
     lastName:string;
     nickname?:string;
     genderMvrKey:string | null;
     birthDate:Date | string;
     birthPlaceDTO?:PlaceCreationDTO;
     maidenName?:string | null;
     parentName?:string | null;
     parentSurname?:string | null;
     fatherFullName?: string;
     motherName?: string;
     motherSurname?: string;
     occupationMvrKey?:string | null;
     professionalTrainingMvrKey?:string | null;
     marriageStatusMvrKey?:string | null;
     residenceTypeMvrKey?: string | null;
     addressDTO?:AddressCreationDTO;
     addressDateFrom?: Date | string;
     pastAddress?:AddressCreationDTO;
     collectiveCenterMvrKey?: string;
     otherResidence?: string;
     numberOfAdultFamilyMembers?:number | null;
     numberOfForeignChildren?:number | null;
     numberOfDomesticChildren?:number | null;
     ageGroupMvrKey?:string | null;
     personLanguageCreationDTOs?:PersonLanguageCreationDTO[];
     ethnicityMvrKey?: string;
     religionMvrKey?:string;
     spouseFullName?:string;
     educationInstitutionDTO?: EducationInstitutionCreationDTO;
     educationDateFinishedDate?: Date | string;
     phoneNumber?: string;
     countryDTO?: CountryCreationDTO;
     birthCountryDTO?: CountryCreationDTO;
     hotel?: string;
     firstNameCyrillic?:string;
     lastNameCyrillic?:string;
     parentNameCyrillic?:string;
     shortTermStayPossibilityMvrKey?: string;
     motherNameCyrillic?:string;

     constructor(json:PersonDataCreationDTO){
        this.citizenshipCreationDTO = json.citizenshipCreationDTO ?  new CitizenshipCreationDTO(json.citizenshipCreationDTO) : undefined;
        this.otherCitizenshipCreationDTO = json.otherCitizenshipCreationDTO ? new CitizenshipCreationDTO(json.otherCitizenshipCreationDTO) : undefined;
        this.embg =json.embg;
        this.existingMacEmbg = json.existingMacEmbg;
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.nickname = json.nickname;
        this.genderMvrKey =json.genderMvrKey;
        this.birthDate = json.birthDate;
        this.birthPlaceDTO = json.birthPlaceDTO ?  new PlaceCreationDTO(json.birthPlaceDTO) : undefined;
        this.maidenName = json.maidenName;
        this.parentName = json.parentName;
        this.parentSurname = json.parentSurname
        this.fatherFullName = json.fatherFullName;
        this.motherName = json.motherName;
        this.motherSurname = json.motherSurname;
        this.occupationMvrKey = json.occupationMvrKey;
        this.professionalTrainingMvrKey = json.professionalTrainingMvrKey;
        this.marriageStatusMvrKey  = json.marriageStatusMvrKey;
        this.residenceTypeMvrKey = json.residenceTypeMvrKey;
        this.addressDTO = json.addressDTO ?  new AddressCreationDTO(json.addressDTO) :  undefined;
        this.addressDateFrom = json.addressDateFrom;
        this.pastAddress = json.pastAddress ? new AddressCreationDTO(json.pastAddress) : undefined;
        this.collectiveCenterMvrKey = json.collectiveCenterMvrKey;
        this.otherResidence = json.otherResidence;
        this.numberOfAdultFamilyMembers = json.numberOfAdultFamilyMembers;
        this.numberOfForeignChildren = json.numberOfForeignChildren;
        this.numberOfDomesticChildren = json.numberOfDomesticChildren;
        this.ageGroupMvrKey = json.ageGroupMvrKey;
        this.personLanguageCreationDTOs = json.personLanguageCreationDTOs?.map((item) => new PersonLanguageCreationDTO(item)) ?? [];
        this.ethnicityMvrKey = json.ethnicityMvrKey;
        this.religionMvrKey = json.religionMvrKey;
        this.spouseFullName = json.spouseFullName;
        this.educationInstitutionDTO = json.educationInstitutionDTO ? new EducationInstitutionCreationDTO(json.educationInstitutionDTO) : undefined;
        this.educationDateFinishedDate  = json.educationDateFinishedDate;
        this.phoneNumber = json.phoneNumber;
        this.countryDTO = json.countryDTO ? new CountryCreationDTO(json.countryDTO) : undefined;
        this.birthCountryDTO = json.birthCountryDTO ? new CountryCreationDTO(json.birthCountryDTO) : undefined;
        this.hotel = json.hotel;
        this.firstNameCyrillic = json.firstNameCyrillic;
        this.lastNameCyrillic = json.lastNameCyrillic;
        this.parentNameCyrillic = json.parentNameCyrillic;
        this.shortTermStayPossibilityMvrKey = json.shortTermStayPossibilityMvrKey;
        this.parentSurname = json.parentSurname;
        this.motherName = json.motherName;
        this.motherSurname = json.motherSurname;
        this.motherNameCyrillic = json.motherNameCyrillic;
     }

    
}
