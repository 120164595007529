import { Button, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { DatePickerField } from "../../applications/formFields/DatePickerFields";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useEffect, useState } from "react";
import { strings } from "../../../localization/Localization";
import { useFormikContext } from "formik";
import CustomSelect from "../../applications/formFields/CustomSelect";
import { getAllResidenceReasons } from "../../../common/NomenclatureMethods";
import { FileService } from "../../../services/FileService";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import { showErrorDialog, showSuccessDialog } from "../../../common/Dialogues";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
const list = [
  {
    value: true,
    label: 'Позитивно'
  },
  {
    value: false,
    label: 'Негативно'
  },
];
export default function DiplomatsSecondData(props:any) {
  const {
    formField: {
      approvalDate,
      dateOfExtension,
      endDate,
      startDateStay,
      endDateStay,
      dateOfDeliveryOfDecision,
      decision,
      fileDecision,
    }
  } = props;
  const formikProps = useFormikContext<any>()
  const [disabledSpouseAbroad, setDisabledSpouseAbroad] = useState<boolean>();
  const [disabledSpouseRSM, setDisabledSpouseRSM] = useState<boolean>();
  useEffect(()=>{
     async function loadData(): Promise<void> {
      if(formikProps.values.evidencyReasonMvrKey){
      await getAllResidenceReasons(formikProps.values.evidencyReasonMvrKey);
      }
    }
    loadData().then((_) => {
    });
  },[])
  useEffect(()=>{
    if(formikProps.values.spouseAbroadBirthPlace || formikProps.values.placeOfBirthSpouseAbroad){
      setDisabledSpouseRSM(true);
    }else{
      setDisabledSpouseRSM(false);
    }
    if(formikProps.values.familyMemberBirthMunicipality || formikProps.values.familyMemberBirthPlace){
      setDisabledSpouseAbroad(true);
    }else{
      setDisabledSpouseAbroad(false)
    }

  },[formikProps.values.spouseAbroadBirthPlace, formikProps.values.placeOfBirthSpouseAbroad, formikProps.values.familyMemberBirthMunicipality,formikProps.values.familyMemberBirthPlace, disabledSpouseAbroad, disabledSpouseRSM])

function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString().split(";base64,")[1];
      if (base64String) {
        resolve(base64String);
      } else {
        reject(new Error("Failed to convert file to Base64"));
      }
    };
    reader.readAsDataURL(file);
  });
}
async function  handleFileRead  (event:any) {
  const file = event.target.files[0]
  const base64 = await fileToBase64(file)
  const mimeType = file.type
  formikProps.setFieldValue("fileDecision", {
    name: file.name,
    contentBase64: base64,
    mimeType: mimeType,
  });
}
async function downloadFileDecision(id:number, name:string) {
  await FileService.downloadFileContent(id,name).catch((e) => {
    const errorMessage = ErrorHandler.parseErrorMessage(e);
    showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
  });
}

function handleChange(event: SelectChangeEvent){
  formikProps.setFieldValue("decision", event.target.value)
  if(event.target.value){
    showSuccessDialog(strings.opinionPositiveTitle, strings.opinionPositive, strings.ok).then(_ => {
    });
  }
};
    return (
      <>
        <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {/* Grid 1 */}
        <Grid item xs={12} sm={4} md={6}>
        <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
        Податоци за генерирање матичен број
      </Typography>
        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"}  style={{border:"1px solid #3f51b5"}}>
        <Typography variant="subtitle1" gutterBottom>
            {approvalDate.id + ". " + approvalDate.label}
        </Typography>
        <DatePickerField
            name={approvalDate.name}
            label={approvalDate.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
          />
           <Typography variant="subtitle1" gutterBottom>
            {dateOfExtension.id + ". " + dateOfExtension.label}
        </Typography>
        <DatePickerField
            name={dateOfExtension.name}
            label={dateOfExtension.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
          />
        <Typography variant="subtitle1" gutterBottom>
            {endDate.id + ". " + endDate.label}
        </Typography>  
        <CenteredCardLayout maxWidth={"100%"} minWidth={"100%"} className="pb-3">
        <Typography variant="subtitle1" gutterBottom>
            {startDateStay.id + ". " + startDateStay.label}
        </Typography>  
        <DatePickerField
            name={startDateStay.name}
            label={startDateStay.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
          />
           <Typography variant="subtitle1" gutterBottom>
            {endDateStay.id + ". " + endDateStay.label}
        </Typography>  
           <DatePickerField
            name={endDateStay.name}
            label={endDateStay.placeholder}
            className="pb-3"
            minDate={formikProps?.values?.startDateStay}
            read={props.hasAuthority ? false : true}
          />
          </CenteredCardLayout>
          <Typography variant="subtitle1" gutterBottom>
            {dateOfDeliveryOfDecision.id + ". " + dateOfDeliveryOfDecision.label}
        </Typography> 
        <DatePickerField
            name={dateOfDeliveryOfDecision.name}
            label={dateOfDeliveryOfDecision.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" gutterBottom>
            {decision.id + ". " + decision.label}
        </Typography> 
        <CustomSelect className="pb-3"
            name={decision.name}
            label={decision.placeholder}
            data={list}
            read={props.hasAuthority ? false : true}
            onChange={handleChange}
          />
              <input style={{ display: "none" }} onChange={handleFileRead} name={fileDecision.name}
                accept="*"
                id="raised-button-file"
                type="file"
              />
              <a onClick={()=>downloadFileDecision(formikProps?.values?.fileDecision?.id, formikProps?.values?.fileDecision?.name)} className="cursor-pointer fileLink"> {formikProps.values.fileDecision && <InsertDriveFileIcon/>}{formikProps?.values?.fileDecision?.name}</a>
              <label htmlFor="raised-button-file" className="w-100">
                {props.hasAuthority && <Button component="span" endIcon={<AttachFileIcon />} variant="contained" style={{ float: "right" }} className="mb-3" >
                  Прикачи решение
                </Button>}
              </label>
          </CenteredCardLayout>
        </Grid>
      </Grid>
      </>
    )
}
