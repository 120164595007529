import { Grid, Typography } from "@mui/material";
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { NomenclatureEntryDTO } from "../../../models/nomenclatures/NomenclatureEntryDTO";
import { useEffect, useState } from "react";
import InputField from "../../applications/formFields/InputField";
import SelectField from "../../applications/formFields/SelectField";
import {DatePickerField} from "../../applications/formFields/DatePickerFields";
import {
  getAllEntriesForNomenclatureCitizenship,
  getAllEntriesForNomenclatureGender,
  getAllEntriesForNomenclatureMunicipality,
  getAllEntriesForNomenclaturePlace,
  getAllEntriesForNomenclatureStreets, getAllTypesOfObjectsForStay
} from "../../../common/NomenclatureMethods";
import {strings} from "../../../localization/Localization";
import {useFormikContext} from "formik";
import { NomenclatureService } from "../../../services/NomenclatureService";
import { PaginatedSelect } from "../../../components/PaginatedSelect";
import { NomenclatureConstants } from "../../../constants/NomenclatureConstants";
import InputFieldCyrillic from "../../applications/formFields/InputFieldCyrillic";
import {ExternalService} from "../../../services/ExternalService";
import {ErrorHandler} from "../../../utils/ErrorHandler";
import {showErrorDialog} from "../../../common/Dialogues";

export function ShortTermSettleDataForm(props:any) {
  const {
    formField: {
      privateVisitPerson,
      embgPrivateVisitPerson,
      lastNamePrivateVisitPerson,
      firstNamePrivateVisitPerson,
      genderPrivateVisitPerson,
      citizen,
      addressPrivateVisitPerson,
      municipalityPrivateVisitPerson,
      placePrivateVisitPerson,
      streetPrivateVisitPerson,
      numberStreetPrivateVisitPerson,
      apartmentEntrancePrivateVisitPerson, 
      apartmentPrivateVisitPerson,
      pastApartmentPrivateVisitPerson,
      pastMunicipalityPrivateVisitPerson,
      pastAddressPrivateVisitPerson,
      pastApartmentEntrancePrivateVisitPerson,
      pastNumberStreetPrivateVisitPerson,
      pastPlacePrivateVisitPerson,
      pastStreetPrivateVisitPerson,
      noteForeigner,
      approvedStayDate,
      registration,
      timeStart,
      timeEnd,
      privateVisitObject,
      hotel,
      shortTermStayPossibilityMvrKey,
    }
  } = props;
  const [genders, setGenders] = useState<NomenclatureEntryDTO[]>([]);
  const [citizenships, setCitizenships] = useState<NomenclatureEntryDTO[]>([]);
  const [municipalities, setMunicipalities] = useState<NomenclatureEntryDTO[]>([]);
  const [permanentResidenceReasons,setPermanentResidenceReasons] = useState<NomenclatureEntryDTO[]>([]);
  const [places, setPlaces] = useState<NomenclatureEntryDTO[]>([]);
  const [streets, setStreets] = useState<NomenclatureEntryDTO[]>([]);
  const [temporaryResidenceReasons, setTemporaryResidenceReasons] = useState<NomenclatureEntryDTO[]>([]);
  const [typeObject, setTypeObject] = useState<NomenclatureEntryDTO[]>([]);
  const formikProps = useFormikContext<any>()

  // const [active, setActive] = useState<boolean>();
  useEffect(()=>{
    getAllEntriesForNomenclatureCitizenship().then((res)=>{setCitizenships(res)})
    getAllEntriesForNomenclatureGender().then((res)=>{setGenders(res)})
    getAllEntriesForNomenclatureMunicipality().then((res)=>{setMunicipalities(res)})
    getAllEntriesForNomenclaturePlace().then((res)=>{setPlaces(res)})
    getAllEntriesForNomenclatureStreets().then((res)=>setStreets(res))
    getAllTypesOfObjectsForStay().then((res) => setTypeObject(res))

      let newList:NomenclatureEntryDTO[] = [];
      temporaryResidenceReasons.map((element)=>{
        newList.push(element);
      })
      permanentResidenceReasons.map((element)=>{
        newList.push(element);
      })
     async function loadData(): Promise<void> {
       getAllEntriesForNomenclatureGender();
       getAllEntriesForNomenclatureCitizenship();
       getAllEntriesForNomenclatureMunicipality();
       getAllEntriesForNomenclaturePlace();
       getAllEntriesForNomenclatureStreets();
       getAllTypesOfObjectsForStay();
    }
    loadData().then((_) => {
    });

  },[])

  async function getPersonDataByEmbg(embg:string){
    await ExternalService.getPersonInformationByEmbg(embg).then((res)=>{
      formikProps.setFieldValue("firstNamePrivateVisitPerson", res.firstName);
      formikProps.setFieldValue("lastNamePrivateVisitPerson", res.lastName);
    }).catch((e)=>{
      const errorMessage = ErrorHandler.parseErrorMessage(e);
      showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
    })
  }
    return (
      <>
        <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
          <Grid item xs={12} sm={4} md={6}>
        <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
          {strings.privateVisitPerson}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {privateVisitObject.id + ". " + privateVisitObject.label}
        </Typography>
        <SelectField className="pb-3"
                     name={shortTermStayPossibilityMvrKey.name}
                     label={shortTermStayPossibilityMvrKey.label}
                     data={typeObject}
                     read={props.hasAuthority ? false : true}
        />
        <Typography variant="subtitle1" gutterBottom>
            {privateVisitPerson.id + ". " + privateVisitPerson.label}
        </Typography>  
        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
        <Typography variant="subtitle1" gutterBottom>
            {embgPrivateVisitPerson.id + ". " + embgPrivateVisitPerson.label}
        </Typography>
          <InputField name={embgPrivateVisitPerson.name} label={embgPrivateVisitPerson.placeholder}
                      disabled={formikProps.getFieldProps("shortTermStayPossibilityMvrKey").value === "1"}
                      icon={(formikProps.values.embgPrivateVisitPerson && formikProps.values.embgPrivateVisitPerson.length === 13) ? true : false}
                      onClick={()=>getPersonDataByEmbg(formikProps.values.embgPrivateVisitPerson)}
                      className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true} embgNumber={true}></InputField>

              <Typography variant="subtitle1" gutterBottom>
                {lastNamePrivateVisitPerson.id + ". " + lastNamePrivateVisitPerson.label}
              </Typography>
              <InputField disabled={formikProps.getFieldProps("shortTermStayPossibilityMvrKey").value === "1"}
                  name={lastNamePrivateVisitPerson.name} label={lastNamePrivateVisitPerson.placeholder} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true}/>

              <Typography variant="subtitle1" gutterBottom>
                {firstNamePrivateVisitPerson.id + ". " + strings.firstName}
              </Typography>
              <InputField disabled={formikProps.getFieldProps("shortTermStayPossibilityMvrKey").value === "1"}
                          name={firstNamePrivateVisitPerson.name} label={strings.firstName + "*"} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true}/>

        <Typography variant="subtitle1" gutterBottom>
            {genderPrivateVisitPerson.id + ". " + genderPrivateVisitPerson.label}
        </Typography>  
        <SelectField className="pb-3"
            name={genderPrivateVisitPerson.name}
            label={genderPrivateVisitPerson.placeholder}
            data={genders}
            disabled={formikProps.getFieldProps("shortTermStayPossibilityMvrKey").value === "1"}
            read={props.hasAuthority ? false : true}
          />
        <Typography variant="subtitle1" gutterBottom>
            {citizen.id + ". " + citizen.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
            disabled={formikProps.getFieldProps("shortTermStayPossibilityMvrKey").value === "1"}
                        value={formikProps.values.citizen!=="" ? formikProps.values.citizen : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("citizen", value)
                          }
                        }
                        label={citizen.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.citizenship, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={citizen.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        </CenteredCardLayout>
        <Typography variant="subtitle1" gutterBottom>
            {addressPrivateVisitPerson.id + ". " + addressPrivateVisitPerson.label}
        </Typography>  
        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
          <Typography variant="subtitle1" gutterBottom>
            {hotel.id + ". " + hotel.label}
          </Typography>
          <InputField disabled={formikProps.getFieldProps("shortTermStayPossibilityMvrKey").value === "2"}
                      name={hotel.name} label={hotel.label}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {municipalityPrivateVisitPerson.id + ". " + municipalityPrivateVisitPerson.label}
        </Typography> 
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.municipalityPrivateVisitPerson!=="" ? formikProps.values.municipalityPrivateVisitPerson : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("municipalityPrivateVisitPerson", value);
                          formikProps.setFieldValue("placePrivateVisitPerson", "");
                          formikProps.setFieldValue("streetPrivateVisitPerson", "");
                        }
                        }
                        label={municipalityPrivateVisitPerson.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={municipalityPrivateVisitPerson.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {placePrivateVisitPerson.id + ". " + placePrivateVisitPerson.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.placePrivateVisitPerson!=="" ? formikProps.values.placePrivateVisitPerson : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("placePrivateVisitPerson", value);
                          formikProps.setFieldValue("streetPrivateVisitPerson", "");
                        }
                        }
                        label={placePrivateVisitPerson.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.municipalityPrivateVisitPerson.key}`, filter ? filter : ""
                          );
                        }}
                        name={placePrivateVisitPerson.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {streetPrivateVisitPerson.id + ". " + streetPrivateVisitPerson.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.streetPrivateVisitPerson!=="" ? formikProps.values.streetPrivateVisitPerson : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("streetPrivateVisitPerson", value)
                          }
                        }
                        label={streetPrivateVisitPerson.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.street, true, `place=${formikProps.values.placePrivateVisitPerson?.key}`, filter ? filter : ""
                          );
                        }}
                        name={streetPrivateVisitPerson.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {numberStreetPrivateVisitPerson.id + ". " + numberStreetPrivateVisitPerson.label}
        </Typography>  
        <InputField name={numberStreetPrivateVisitPerson.name} label={numberStreetPrivateVisitPerson.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {apartmentEntrancePrivateVisitPerson.id + ". " + apartmentEntrancePrivateVisitPerson.label}
        </Typography>  
        <InputField name={apartmentEntrancePrivateVisitPerson.name} label={apartmentEntrancePrivateVisitPerson.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {apartmentPrivateVisitPerson.id + ". " + apartmentPrivateVisitPerson.label}
        </Typography>  
        <InputField name={apartmentPrivateVisitPerson.name} label={apartmentPrivateVisitPerson.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
        </CenteredCardLayout>
        </Grid>
          <Grid item xs={12} sm={4} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              {pastAddressPrivateVisitPerson.id + ". " + pastAddressPrivateVisitPerson.label}
            </Typography>
            <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
              <Typography variant="subtitle1" gutterBottom>
                {pastMunicipalityPrivateVisitPerson.id + ". " + pastMunicipalityPrivateVisitPerson.label}
              </Typography>
              <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.pastMunicipalityPrivateVisitPerson!=="" ? formikProps.values.pastMunicipalityPrivateVisitPerson : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("pastMunicipalityPrivateVisitPerson", value);
                          formikProps.setFieldValue("pastPlacePrivateVisitPerson", "");
                          formikProps.setFieldValue("pastStreetPrivateVisitPerson", "");
                        }
                        }
                        label={pastMunicipalityPrivateVisitPerson.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={pastMunicipalityPrivateVisitPerson.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
              <Typography variant="subtitle1" gutterBottom>
                {pastPlacePrivateVisitPerson.id + ". " + pastPlacePrivateVisitPerson.label}
              </Typography>
              <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.pastPlacePrivateVisitPerson!=="" ? formikProps.values.pastPlacePrivateVisitPerson : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("pastPlacePrivateVisitPerson", value);
                          formikProps.setFieldValue("pastStreetPrivateVisitPerson", "");
                        }
                        }
                        label={pastPlacePrivateVisitPerson.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.pastMunicipalityPrivateVisitPerson?.key}`, filter ? filter : ""
                          );
                        }}
                        name={pastPlacePrivateVisitPerson.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
              <Typography variant="subtitle1" gutterBottom>
                {pastStreetPrivateVisitPerson.id + ". " + pastStreetPrivateVisitPerson.label}
              </Typography>
              <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.pastStreetPrivateVisitPerson!=="" ? formikProps.values.pastStreetPrivateVisitPerson : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("pastStreetPrivateVisitPerson", value)
                          }
                        }
                        label={pastStreetPrivateVisitPerson.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.street, true, `place=${formikProps.values.pastPlacePrivateVisitPerson?.key}`, filter ? filter : ""
                          );
                        }}
                        name={pastStreetPrivateVisitPerson.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
              <Typography variant="subtitle1" gutterBottom>
                {pastNumberStreetPrivateVisitPerson.id + ". " + pastNumberStreetPrivateVisitPerson.label}
              </Typography>
              <InputField name={pastNumberStreetPrivateVisitPerson.name} label={pastNumberStreetPrivateVisitPerson.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
              <Typography variant="subtitle1" gutterBottom>
                {pastApartmentEntrancePrivateVisitPerson.id + ". " + pastApartmentEntrancePrivateVisitPerson.label}
              </Typography>
              <InputField name={pastApartmentEntrancePrivateVisitPerson.name} label={pastApartmentEntrancePrivateVisitPerson.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
              <Typography variant="subtitle1" gutterBottom>
                {pastApartmentPrivateVisitPerson.id + ". " + pastApartmentPrivateVisitPerson.label}
              </Typography>
              <InputField name={pastApartmentPrivateVisitPerson.name} label={pastApartmentPrivateVisitPerson.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
            </CenteredCardLayout>
            <Typography variant="subtitle1" gutterBottom>
              {approvedStayDate.id + ". " + approvedStayDate.label}
            </Typography>
            <DatePickerField
                name={approvedStayDate.name}
                label={approvedStayDate.placeholder}
                className="pb-3"
                read={props.hasAuthority ? false : true}
            />
            <Typography variant="subtitle1" gutterBottom>
              {registration.id + ". " + registration.label}
            </Typography>
            <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
              <Typography variant="subtitle1" gutterBottom>
                {timeStart.id + ". " + timeStart.label}
              </Typography>
              <DatePickerField
                  name={timeStart.name}
                  label={timeStart.placeholder}
                  className="pb-3"
                  read={props.hasAuthority ? false : true}
              />
              <Typography variant="subtitle1" gutterBottom>
                {timeEnd.id + ". " + timeEnd.label}
              </Typography>
              <DatePickerField
                  name={timeEnd.name}
                  label={timeEnd.placeholder}
                  className="pb-3"
                  read={props.hasAuthority ? false : true}
              />
            </CenteredCardLayout>
            <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-1">
              Г. Забелешка за странецот
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {noteForeigner.id + ". " + noteForeigner.label}
            </Typography>
            <InputField name={noteForeigner.name} label={noteForeigner.placeholder} fullWidth className="pb-3" multiline={true} rows={4} readOnly={props.hasAuthority ? false : true} />
          </Grid>
          </Grid>
      </>
    )
}
