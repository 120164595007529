import { CountryCreationDTO } from "./CountryCreationDTO";

export class CitizenshipCreationDTO{
    mvrKey:string | null;
    statKey:string | null;
    isoName:string | null;
    iso2:string | null ;
    iso3:string | null;
    nameSq:string | null;
    nameEn:string | null;
    countryDTO?:CountryCreationDTO;
    constructor(json:CitizenshipCreationDTO){
        this.mvrKey = json.mvrKey;
        this.statKey = json.statKey;
        this.isoName = json.isoName;
        this.iso2 = json.iso2;
        this.iso3 = json.iso3;
        this.nameSq = json.nameSq;
        this.nameEn = json.nameEn;
        this.countryDTO =  json.countryDTO ? new CountryCreationDTO(json.countryDTO) : undefined;

    }
}