import {RegistrationReducedDTO} from "./RegistrationReducedDTO";
import {DecisionDTO} from "../decision/DecisionDTO";

export class RegistrationReducedWithDecisionDTO {
    registration: RegistrationReducedDTO;
    decision: DecisionDTO;

    constructor(json: RegistrationReducedWithDecisionDTO) {
        this.registration = new RegistrationReducedDTO(json.registration);
        this.decision = new DecisionDTO(json.decision);
    }
}
