import {CircularProgress, FormControlLabel, TextField} from "@mui/material";
import {NullableCheckbox} from "../NullableCheckbox";
import { CustomSelect } from "../CustomSelect";
import {NullableDatePicker} from "../NullableDatePicker";
import {Button} from "@mui/material";
import React from "react";
import useStyles from "../../pages/ShortTermStayForm/styles/styles";
import {PaginatedSelectOriginal} from "../PaginatedSelectOriginal/PaginatedSelectOriginal";

export function TableFilter({row}:any){
    const classes = useStyles();

    return (
        <div>
            {row.type === "text" &&
            <TextField label={row.label} onChange={row.handleChange} value={row.value} variant={row.variant}/>
            } 
            {row.type === "number" &&
            <TextField label={row.label} onChange={row.handleChange} variant={row.variant} value={row.value} type="number"/>
            }
            {row.type === "nullableCheckbox" &&
                <FormControlLabel
                    label={row.label}
                    control={
                        <NullableCheckbox
                            value={row.value}
                            onValueChanged={row.handleChange}
                        />
                    }
                    style = {{marginTop:"20px"}}
                />
            }
            {row.type === "dropdown" &&
                <CustomSelect disabled={row.disabled} styling={row.styling} id={row.id} label={row.label} inputLabel={row.inputLabel} customWidth={row.customWidth} elements={row.elements} handleChange={row.handleChange} variant={row.variant} handleRemoveFilter={row.handleRemoveFilter} value={row.value} />
            }
            {row.type === "datePicker" &&
                <NullableDatePicker label={row.label} value={row.value} variant={row.variant} onChange={row.handleChange}></NullableDatePicker>
            }
            {row.type === "paginatedSelect" &&
                <PaginatedSelectOriginal valueMapper={row.valueMapper} keyMapper={row.keyMapper} value={row.value} filterLabel={row.filterLabel} placeholder={row.placeholder}
                                 itemMapper={row.itemMapper} labelMapper={row.labelMapper} dataFetcher={row.dataFetcher} onChange={row.onChange}/>
            }
            {
                row.type === "button" && <Button variant={row.variant} type={"submit"} onClick={row.onClick} style={{float:"right"}}>{row.value}</Button>
            }
            {row.type === "loader" && row.value &&
                <CircularProgress
                    style={{float:"right"}}
                    size={32}
                    className={classes.buttonProgressEMBGModal}
                />
            }
        </div>
    )   

}
