import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { ShortTermSettleDataForm } from './forms/ShortTermSettleDataForm';
import { SideBarPage } from '../../components/SideBarPage';
import { CenteredCardLayout } from '../../components/CenteredCardLayout';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Step, StepButton, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useStyles from './styles/styles';
import ShortTermBasicDataForm from './forms/ShortTermBasicDataForm';
import { ForeignCitizenService } from '../../services/registrations/ForeignCitizenService';
import { showErrorDialog, showSuccessDialog } from '../../common/Dialogues';
import { strings } from '../../localization/Localization';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../../router/Routes';
import { ErrorHandler } from '../../utils/ErrorHandler';
import { ShortTermUserData } from './ShortTermUserData';
import ShortTermFormValidationSchema from "./formModel/ShortTermFormValidationSchema";
import ShortTermFormInitialValues from "./formModel/ShortTermFormInitialValues";
import ShortTermFormModel from "./formModel/ShortTermFormModel";
import { RegistrationDTO } from "../../models/foreign_citizens/RegistrationDTO";
import { ForeignCitizenReducedDTO } from "../../models/registration/ForeignCitizenReducedDTO";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { ForeignCitizenDTO } from "../../models/foreign_citizens/ForeignCitizenDTO";
import RightSidePanel from "../../components/right_side_component/RightSidePanel";
import { CreatedByInfo } from "../../components/CreatedByInfo";
import { PassivationService } from '../../services/PassivationService';
import ConfirmationModal from '../../components/ConfirmationModal';
import {
  getAllEntriesForNomenclatureCitizenship,
  getAllEntriesForNomenclatureCountry,
  getAllEntriesForNomenclatureMunicipality,
  getAllEntriesForNomenclaturePlace,
  getAllEntriesForNomenclatureStreets,
  getAllEntriesForOrganisationalUnit
} from '../../common/NomenclatureMethods';
import { UserDetailsInfoResponse } from '../../models/UserDetailsInfoResponse';
import { AuthService } from '../../services/AuthService';
import { Privileges } from '../../models/nomenclatures/Privileges';
import { StorageService } from '../../storage/StorageService';
import { TemplateDTO } from '../../models/templates/TemplateDTO';
import { TemplateService } from '../../services/TemplateService';
import {RegistrationCreationDTO} from "../../models/foreign_citizens/RegistrationCreationDTO";
import { EMBGModal } from "../../components/EMBGModal";
import { NomenclatureConstants } from "../../constants/NomenclatureConstants";
import { LightTooltip } from '../../components/LightTooltip';
import {AutoScroller} from "../../components/AutoScroller";
let steps = ['Лични податоци за странецот', 'Податоци за место на престој'].map((n=>{
  return {name:n, completed:false }
}));

const { formId, formField } = ShortTermFormModel;
export default function ShortTermUserForm() {
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = ShortTermFormValidationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const classes = useStyles();
  const formikRef = useRef<any>(null);
  const navigate = useNavigate();
  let { id } = useParams();
  const [versions, setVersions] = useState<ForeignCitizenReducedDTO[] | undefined>([])
  const [version, setVersion] = useState<string>();
  const [values, setValues] = useState<any>();
  const isAddMode = !id;
  const [lastSubmittedValues, setLastSubmittedValues] = useState<{} | null>(null);
  const [registrationData, setRegistrationData] = useState<RegistrationDTO>();
  const [foreignCitizenData, setForeignCitizenData] = useState<ForeignCitizenDTO>();
  const [openRequest, setOpenRequest] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserDetailsInfoResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [openBasicDataChangesModal, setOpenBasicDataChangesModal] = useState<boolean>(false);
  const [hasAuthority, setHasAuthority] = useState<boolean>();
  const [copyToForeignCitizen, setCopyToForeignCitizen] = useState<boolean>();
  const [shortTermTemplates, setShortTermTemplates] = useState<TemplateDTO[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [historyActions, setHistoryActions] = useState<any>()
  const [openActivateRequest, setOpenActivateRequest] = useState<boolean>(false);
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [openCheckModal, setOpenCheckModal] = useState<boolean>(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>()

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  function getHistory(id: number) {
    ForeignCitizenService.getHistoryActions(id).then(data => {
      setHistoryActions(data)
    });
  }

  function handleChange(event: SelectChangeEvent) {
    setVersion(event.target.value)
  }

  useEffect(() => {
    if (id) {
      getHistory(parseInt(id))
    }
    async function userHasAuthority(): Promise<void> {
      const userRoles: string[] | null = StorageService.getUserRoles();
      if (userRoles !== null) {
        if (userRoles.includes(Privileges.MANAGE_SHORT_TERM_STAY)) {
          setHasAuthority(true)
        }
        if (userRoles.includes(Privileges.MANAGE_FOREIGN_CITIZEN)) {
          setCopyToForeignCitizen(true)
        }
      } else {
        const authorization = await AuthService.getAuthorization();
        if (authorization.roles.includes(Privileges.MANAGE_SHORT_TERM_STAY)) {
          setHasAuthority(true)
        }
        if (authorization.roles.includes(Privileges.MANAGE_FOREIGN_CITIZEN)) {
          setCopyToForeignCitizen(true)
        }
      }
    }

    userHasAuthority().then((_) => { });

  }, []);

  async function getTemplatesForTemporaryStayRequests(): Promise<void> {
    const temporaryStayTemplates: TemplateDTO[] = await TemplateService.getTemplatesForTemporaryStayRequests();
    setShortTermTemplates(temporaryStayTemplates)
  }

  useEffect(() => {
    if (!isAddMode && id) {
      getTemplatesForTemporaryStayRequests().then((_) => { });
    }
    const schema = ShortTermFormValidationSchema;
    for(let i=0;i<steps.length;i++){
      if(schema[i].isValidSync(formikRef?.current?.values)){
        steps[i].completed = true;
      }else {
        steps[i].completed = false;
      }
    }
  }, [formikRef?.current?.values]);
  const pull_data = (data: any) => {
    setDisabledSaveButton(data)
  }
  function renderStepContent(step: number) {
    switch (step) {
      case 0:
        return <ShortTermBasicDataForm hasAuthority={hasAuthority} formField={formField} func={pull_data}/>;
      case 1:
        return <ShortTermSettleDataForm hasAuthority={hasAuthority} formField={formField} />;
      default:
        return <div>Not Found</div>;
    }
  }

  async function getVersion(id: number) {
    const data = await ForeignCitizenService.getForeignCitizenVersionForRegistration(+id, version && version);
    setForeignCitizenData(data)
  }

    function handleOpenRequest(){
      setOpenRequest(true)
    }

  function handleCloseRequest(actionPerformed: boolean) {
    setOpenRequest(false);
    setOpenActivateRequest(false);

    if (actionPerformed) {
      refreshData()
    }
  }
  async function addShortTermRegistration(values : any) {
    let data = ShortTermUserData(values)
    data.submitted = true;
    if (registrationData?.workingVersionRegistrationId?.id) {
      data.workingVersionId = registrationData?.workingVersionRegistrationId?.id.toString();
    }
    await ForeignCitizenService.createForeignCitizen(data).then((res) => {
      let route = Routes.EDIT_SHORT_TERM_STAY_APPLICATION;
      route = route.replace(":id", res.id.toString());
      showSuccessDialog(strings.addForeignCitizenSuccess, strings.addForeignCitizenSuccess, strings.ok).then(_ => {
        setActiveStep(0)
        if (res?.versions) {
          setVersions(res?.versions)
          setVersion(res?.versions[0]?.id.toString() || '')
          navigate(route);
        }
      });
    })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
      });
  }
  async function updateShortTermRegistration(values : any, correctionOfPersonData? : boolean | null){
      let data = ShortTermUserData(values, correctionOfPersonData)
      setValues(values)
      if(registrationData?.id){
          id = registrationData.id.toString();
      }

      if(id){
          await ForeignCitizenService.updateForeignCitizen(+id, data).then((res)=>{
              showSuccessDialog(strings.editForeignCitizenSuccess, strings.addForeignCitizenSuccess, strings.ok).then(_ => {
                  setActiveStep(0)
                  if(res?.versions){
                      setVersions(res?.versions)
                      setVersion(res?.versions[0]?.id.toString() || '')
                      let data = ShortTermUserData(values, null)
                      navigate(Routes.EDIT_SHORT_TERM_STAY_APPLICATION.replace(":id", id!.toString()));
                  }
              });
          })
              .catch((e) => {
                  const errorMessage = ErrorHandler.parseErrorMessage(e);
                  showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
              });
  }
}
    async function editShortTermRegistration(values : any){
        let data = ShortTermUserData(values)
        setValues(values)

        if(checkIfBasicChangesExist(foreignCitizenData, data)){
            setOpenBasicDataChangesModal(true)
        }else{
            await updateShortTermRegistration(values);
        }
    }

  async function submitForm(values: {}, actions: any) {
    setIsLoading(true)
    if(!registrationData?.id) {
        await addShortTermRegistration(values);
        setLastSubmittedValues(values);
    }else{
        await editShortTermRegistration(values);
        setLastSubmittedValues(values);
    }
    actions.setSubmitting(false);
    setIsLoading(false)
    for (let step of steps) {
      step.completed = false;
    }
    setActiveStep(activeStep + 1);
  }

  function handleSubmit(values: {}, actions: any) {
    setIsLoading(true)
    if (isLastStep) {
      let doSubmit = true;

      for (let index = 0; index < steps.length - 1; index++) {
        if (!steps[index].completed) {
          actions.setSubmitting(false);
          setActiveStep(index);
          doSubmit = false;
          break;
        }
      }
      if (doSubmit) {
        submitForm(values, actions);
      }
    } else {
      setActiveStep(activeStep + 1);
      steps[activeStep].completed = true;
      actions.setTouched({});
      actions.setSubmitting(false);
    }
    setIsLoading(false)
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  const handleStepValidation =  async(stepIndex:number) => {
    steps[activeStep].completed = true;
    try {
      await currentValidationSchema.validate(formikRef?.current?.values);
    } catch (e) {
      steps[activeStep].completed = false;
    }
    setActiveStep(stepIndex);
  };

    function handleCloseBasicDataChangesModal(): any {
        setOpenBasicDataChangesModal(false);
        setActiveStep(steps.length - 1)
    }

    async function handleClickCorrection(){
        setOpenBasicDataChangesModal(false);

        if (!openCheckModal){
            await updateShortTermRegistration(lastSubmittedValues, true);
        }
    }
    async function handleClickChange(){
        setOpenBasicDataChangesModal(false);

        if (!openCheckModal){
            await updateShortTermRegistration(lastSubmittedValues, false);
        }

    }
    function checkIfBasicChangesExist(oldData : ForeignCitizenDTO | undefined, newData : RegistrationCreationDTO) : boolean{
        if((oldData?.personData?.firstName !== newData?.foreignCitizenData?.personDataCreationDTO?.firstName) ||
            (oldData?.personData?.lastName !== newData?.foreignCitizenData?.personDataCreationDTO?.lastName)){
            return true;
        }
        return false;
    }
  function getUserDetails() {
    AuthService.getUserDetails().then((res) => {
      setUserDetails(res)
    })
  }
  useEffect(() => {
    if (formikRef?.current?.values?.evidencyReasonMvrKey !== undefined) {
      if (formikRef?.current?.values.evidencyReasonMvrKey === null || formikRef?.current?.values.evidencyReasonMvrKey !== foreignCitizenData?.evidencyReason?.mvrKey) {
        formikRef?.current?.setFieldValue('settlementReasonMvrKey', null);
      }
    }
    getUserDetails();
  }, [formikRef?.current?.values?.evidencyReasonMvrKey]);
  useEffect(() => {
    async function getRegistrationById(id: number) {
      setIsLoadingData(true)
      const registration = await ForeignCitizenService.getRegistrationById(id);
      setVersions(registration.versions);
      setIsLoadingData(false)
      if (registration.versions) {
        if (!version && (registration.versions?.length ?? 0) > 0) {
          setVersion(registration.versions[0].id.toString());
        }

      }
      setRegistrationData(registration);
    }

    async function getData() {
      if (id && !isAddMode) {
        await getRegistrationById(parseInt(id));
        if (version) {
          await getVersion(parseInt(id));
        }
      }
    }

    getData().then(_ => {
    });
  }, [id, isAddMode, version])

  useEffect(() => {
    if (foreignCitizenData) {
      getAllEntriesForNomenclatureCitizenship().then((res) => {
        const citizen = res.find((item) => item.key === foreignCitizenData.host?.citizenshipCreationDTO?.mvrKey)
        formikRef?.current?.setFieldValue("citizen", citizen)
      })
      getAllEntriesForNomenclatureCountry().then((res) => {
        const abroadBirthPlace = res.find((item) => item.key === foreignCitizenData.personData?.birthPlaceDTO?.countryDTO?.mvrKey)
        const countryPassport = res.find((item) => item.key === foreignCitizenData.passport?.countryDTO?.mvrKey)
        const countryVisa = res.find((item) => item.key === foreignCitizenData.visa?.country?.mvrKey)
        if (foreignCitizenData.personData?.birthPlaceDTO?.countryDTO?.mvrKey !== NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("abroadBirthPlace", abroadBirthPlace);
        }
        formikRef?.current?.setFieldValue("countryPassport", countryPassport);
        formikRef?.current?.setFieldValue("countryVisa", countryVisa);
      })
      getAllEntriesForNomenclatureMunicipality().then((res) => {
        const inRSMBirthMunicipality = res.find((item) => item.key === foreignCitizenData.personData?.birthPlaceDTO?.municipalityMvrKey)
        const municipalityPrivateVisitPerson = res.find((item) => item.key === foreignCitizenData.host?.addressDTO?.streetDTO.placeDTO?.municipalityMvrKey)
        const pastMunicipalityPrivateVisitPerson = res.find((item) => item.key === foreignCitizenData?.host?.pastAddress?.streetDTO.placeDTO?.municipalityMvrKey)
        formikRef?.current?.setFieldValue("inRSMBirthMunicipality", inRSMBirthMunicipality);
        formikRef?.current?.setFieldValue("municipalityPrivateVisitPerson", municipalityPrivateVisitPerson);
        formikRef?.current?.setFieldValue("pastMunicipalityPrivateVisitPerson", pastMunicipalityPrivateVisitPerson);
      })
      getAllEntriesForNomenclaturePlace().then((res) => {
        const inRSMBirthPlacePlace = res.find((item) => item.key === foreignCitizenData.personData?.birthPlaceDTO?.mvrKey)
        const placePrivateVisitPerson = res.find((item) => item.key === foreignCitizenData.host?.addressDTO?.streetDTO.placeDTO?.mvrKey)
        const pastPlacePrivateVisitPerson = res.find((item) => item.key === foreignCitizenData?.host?.pastAddress?.streetDTO.placeDTO?.mvrKey)
        formikRef?.current?.setFieldValue("inRSMBirthPlacePlace", inRSMBirthPlacePlace)
        formikRef?.current?.setFieldValue("placePrivateVisitPerson", placePrivateVisitPerson);
        formikRef?.current?.setFieldValue("pastPlacePrivateVisitPerson", pastPlacePrivateVisitPerson);
      })

      getAllEntriesForNomenclatureStreets().then((res) => {
        const streetPrivateVisitPerson = res.find((item) => item.key === foreignCitizenData.host?.addressDTO?.streetDTO.mvrKey)
        const pastStreetPrivateVisitPerson = res.find((item) => item.key === foreignCitizenData?.host?.pastAddress?.streetDTO.mvrKey)
        formikRef?.current?.setFieldValue("streetPrivateVisitPerson", streetPrivateVisitPerson)
        formikRef?.current?.setFieldValue("pastStreetPrivateVisitPerson", pastStreetPrivateVisitPerson)
      })

      getAllEntriesForOrganisationalUnit().then((res) => {
        const placeOfRequest = res.find((item) => item.key === registrationData?.placeOfRequest?.mvrKey)
        formikRef?.current?.setFieldValue('placeOfRequest', placeOfRequest)
      })

      formikRef?.current?.setFieldValue('evidencyReasonMvrKey', foreignCitizenData.evidencyReason?.mvrKey);
      formikRef?.current?.setFieldValue("lastName", foreignCitizenData.personData?.lastName)
      formikRef?.current?.setFieldValue("firstName", foreignCitizenData.personData?.firstName);
      formikRef?.current?.setFieldValue("genderMvrKey", foreignCitizenData.personData?.genderMvrKey);
      formikRef?.current?.setFieldValue("birthDate", foreignCitizenData.personData?.birthDate)
      formikRef?.current?.setFieldValue("parentName", foreignCitizenData.personData?.parentName)
      if (foreignCitizenData.personData?.countryDTO?.mvrKey) {
        // formikRef?.current?.setFieldValue("abroadBirthPlace", foreignCitizenData.personData?.countryDTO?.mvrKey)
      }
      if (foreignCitizenData.host?.addressDTO?.entranceNumber) {
        formikRef?.current?.setFieldValue("apartmentEntrancePrivateVisitPerson", foreignCitizenData.host?.addressDTO?.entranceNumber)
      }
      if (foreignCitizenData.host?.addressDTO?.apartmentNumber) {
        formikRef?.current?.setFieldValue("apartmentPrivateVisitPerson", foreignCitizenData.host?.addressDTO?.apartmentNumber)
      }
      formikRef?.current?.setFieldValue("settlementReasonMvrKey", foreignCitizenData.settlementReasonMvrKey);
      formikRef?.current?.setFieldValue("noteForeigner", foreignCitizenData.note)
      formikRef?.current?.setFieldValue("passportNumber", foreignCitizenData.passport?.passportNumber);
      formikRef?.current?.setFieldValue("passportTypeMvrKey", foreignCitizenData.passport?.passportTypeMvrKey);
      formikRef?.current?.setFieldValue("firstNamePrivateVisitPerson", foreignCitizenData.host?.firstName);
      // formikRef?.current?.setFieldValue("firstNamePrivateVisitPersonCyrillic", foreignCitizenData.host?.firstNameCyrillic);

      formikRef?.current?.setFieldValue("lastNamePrivateVisitPerson", foreignCitizenData.host?.lastName);
      // formikRef?.current?.setFieldValue("lastNamePrivateVisitPersonCyrillic", foreignCitizenData.host?.lastNameCyrillic);

      formikRef?.current?.setFieldValue("genderPrivateVisitPerson", foreignCitizenData.host?.genderMvrKey);
      formikRef?.current?.setFieldValue("embgPrivateVisitPerson", foreignCitizenData.host?.embg)
      formikRef?.current?.setFieldValue("citizen", foreignCitizenData.host?.citizenshipCreationDTO?.mvrKey)
      // formikRef?.current?.setFieldValue("streetPrivateVisitPerson", foreignCitizenData.host?.addressDTO?.streetDTO.mvrKey)
      formikRef?.current?.setFieldValue("numberStreetPrivateVisitPerson", foreignCitizenData.host?.addressDTO?.streetNumber);
      formikRef?.current?.setFieldValue("apartmentEntrancePrivateVisitPerson", foreignCitizenData.host?.addressDTO?.entranceNumber);
      formikRef?.current?.setFieldValue("apartmentPrivateVisitPerson", foreignCitizenData.host?.addressDTO?.apartmentNumber);

      // za kratkorocen prestoj novi polinja ke bidat tuka
      formikRef?.current?.setFieldValue("visaNumber", foreignCitizenData?.visa?.visaNumber);
      formikRef?.current?.setFieldValue("visaTypeMvrKey", foreignCitizenData?.visa?.visaType?.mvrKey);
      formikRef?.current?.setFieldValue("borderPointMvrKey", foreignCitizenData.entranceData?.borderPointMvrKey);
      formikRef?.current?.setFieldValue("entranceDate", foreignCitizenData?.entranceData?.entranceDate);
      formikRef?.current?.setFieldValue("pastNumberStreetPrivateVisitPerson", foreignCitizenData?.host?.pastAddress?.streetNumber);
      formikRef?.current?.setFieldValue("pastApartmentEntrancePrivateVisitPerson", foreignCitizenData?.host?.pastAddress?.entranceNumber)
      formikRef?.current?.setFieldValue("pastApartmentPrivateVisitPerson", foreignCitizenData?.host?.pastAddress?.apartmentNumber)

      formikRef?.current?.setFieldValue("approvedStayDate", foreignCitizenData?.approvedStayDate);
      formikRef?.current?.setFieldValue("timeStart", foreignCitizenData?.timeStart);
      formikRef?.current?.setFieldValue("timeEnd", foreignCitizenData?.timeEnd);

      formikRef?.current?.setFieldValue("hotel", foreignCitizenData?.personData?.hotel);
      formikRef?.current?.setFieldValue("shortTermStayPossibilityMvrKey", foreignCitizenData?.personData?.shortTermStayPossibilityMvrKey)
      formikRef?.current?.setFieldValue('temporaryStayReportedByMvrKey', foreignCitizenData?.temporaryStayReportedByMvrKey)
      formikRef?.current?.setFieldValue('temporaryStaySettlementReasonMvrKey', foreignCitizenData?.temporaryStaySettlementReasonMvrKey)

    }

  }, [registrationData, foreignCitizenData])

  function activateRequest() {
    if (id) {
      PassivationService.activateRegistration(id).then(() => {
        showSuccessDialog(strings.successfullyActivatedRequest, strings.successfullyActivatedRequest, strings.ok).then(_ => {
        });
        refreshData()
        setOpenActivateRequest(false)
      })
        .catch((e) => {
          const errorMessage = ErrorHandler.parseErrorMessage(e);
          showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
        });
    }
  }

  function handleOpenActivateRequest() {
    setOpenActivateRequest(true)
  }

  function handleCloseActivateRequest(actionPerformed: boolean) {
    setOpenActivateRequest(false);

    if (actionPerformed) {
      refreshData();
    }
  }

  async function getRegistrationById(id: number) {
    setIsLoadingData(true)
    const registration = await ForeignCitizenService.getRegistrationById(id);
    setVersions(registration.versions);
    setIsLoadingData(false)
    if (registration.versions) {
      if (!version && (registration.versions?.length ?? 0) > 0) {
        setVersion(registration.versions[0].id.toString());
      }
      setRegistrationData(registration);
    }
    // setRegistrationData(registration);
  }

  function refreshData(): any {
    async function getData() {
      if (id && !isAddMode) {
        await getRegistrationById(parseInt(id));
        await getHistory(parseInt(id));
        if (version) {
          await getVersion(parseInt(id));
        }

      }
    }
    getData().then(_ => {
    });
  }

  async function createOrUpdateWorkingVersion() {
    setIsLoadingSave(true)
    let data = ShortTermUserData(formikRef?.current?.values)
    data.submitted = false;
    if (registrationData?.id) {
      data.id = registrationData?.id.toString();
    }
    if (registrationData?.workingVersionRegistrationId?.id) {
      data.workingVersionId = registrationData.workingVersionRegistrationId.id.toString();
    }
    await ForeignCitizenService.createOrUpdateWorkingVersion(data)
      .then((res) => {
        let route = Routes.EDIT_SHORT_TERM_STAY_APPLICATION;
        if (res.workingVersionRegistrationId?.id) {
          route = route.replace(":id", res?.workingVersionRegistrationId?.id.toString());
          localStorage.setItem("workingVersionId", res?.workingVersionRegistrationId?.id.toString())
        }
        showSuccessDialog(strings.successfullyAddedWorkingVersionShortTerm, strings.successfullyAddedWorkingVersionShortTerm, strings.ok).then(_ => {
          setIsLoadingSave(false)
          if (res?.versions) {
            setVersions(res?.versions)
            setVersion(res?.versions[0]?.id.toString() || '')
            navigate(route)
          }
        });
      })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
        setActiveStep(steps.length - 1)
      });
  }

  function moveToForeigner(data: RegistrationDTO | undefined) {
    let route = Routes.APPLICATION
    navigate(route, { state: { data, flag: true } })
  }
  const isAnyFieldEntered = formikRef.current?.values?.firstName ||  formikRef.current?.values?.lastName ||  formikRef.current?.values?.archiveNumber;
  return (
    <div>
      {isLoadingData &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoadingData}
        >
          <CircularProgress size={60} style={{ color: "#3f51b5" }} />
        </Backdrop>
      }
      <SideBarPage pageTitle={strings.shortTermStay} component={
        <>
          {!isAddMode && <Box component="div" display={"flex"} justifyContent={"space-between"} className="mt-3 mb-4">
            <Box component="div">
              {registrationData?.submitted ? <Box component="span" fontWeight={"bold"} textTransform={"uppercase"}>
                 Поднесена (официјална) верзија на регистрацијата
              </Box> : <LightTooltip customWidth='1000px' title={<div>
                Ве молиме преминете на поднесената (официјална) верзија, доколку сакате да:
                <ul>
                  <li>преземете акции поврзани со матичните броеви</li>
                  <li>пасивизирате/активирате барање</li>
                </ul> 
              </div>}>
              <Box component="span" fontWeight={"bold"} textTransform={"uppercase"}>
                 Работна верзија на регистрацијата
              </Box>
              </LightTooltip>}
            </Box>
            <div style={{ float: 'right' }}>
              {hasAuthority && registrationData?.submitted && (!registrationData?.passivated ? (
                <Button style={{ marginRight: '10px' }} variant='contained' onClick={handleOpenRequest}>{strings.passivize}</Button>
              ) :
                (
                  <Button style={{ marginRight: '10px' }} variant='contained' onClick={handleOpenActivateRequest}>{strings.activate}</Button>
                )
              )}
              {registrationData?.submitted && copyToForeignCitizen && registrationData?.registrationType?.mvrKey === NomenclatureConstants.shortTermStayMvrKey &&
                <Button variant='contained' onClick={() => moveToForeigner(registrationData ? registrationData : undefined)}>{strings.moveShortTermToForeigner}</Button>
              }
            </div>
          </Box>}
          {!isAddMode && 
            <Box component="div" display="flex" justifyContent="start" className='pb-4'>
            <FormControl sx={{ minWidth: 300 }}>
              <InputLabel id="typeOfForms">{strings.version}</InputLabel>
              <Select value={versions && versions.length > 0 && version && versions.some(el => el.id.toString() == version) ? String(version) : ""} onChange={handleChange} labelId="versionOfForms" label={"Верзија"} style={{ textAlign: "left" }} defaultValue=''
                  MenuProps={{
                    classes: {
                      paper: classes.dropdown,
                    },
                  }}
                >
                {versions?.map((item) => (
                  <MenuItem value={item?.id} key={item?.id}>
                    {DateTimeUtils.formatDate(item?.dateCreated.toString()) + " - "}
                    {item?.archiveNumber ?
                      item?.archiveNumber + " - " :
                      ""}
                    {item?.evidencyReason?.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
             </Box>}
          {!isAddMode && (
            <>

            <CreatedByInfo
              label={strings.deceased + ":"}
              status={registrationData?.deadInfo !== null ? registrationData?.deadInfo : "Нема информација"}
            />
            <CreatedByInfo
              label={registrationData?.registrationStatus ? strings.statusEmbg + ":" : undefined}
              status={registrationData?.registrationStatus ? registrationData?.registrationStatus?.name : undefined}
            />
            <CreatedByInfo
              label={strings.statusRegistration + ":"}
              status={registrationData?.passivated ? strings.passive : strings.activeStatus}
              statusSuccessful={!registrationData?.passivated}
            />
            <div className='d-flex pb-4'>
              {strings.workingUnit + ":" + " " + userDetails?.workingUnit}
            </div>
            </>
          )}
          {isLoadingSave && (
            <CircularProgress
              style={{ float: "right" }} className={"mb-2"}
              size={32}
            />
          )
          }
          <AutoScroller style={{float: "right", marginBottom: "20px"}} direction={false} />

          {!registrationData?.submitted && <Button style={{ float: "right", marginRight: "5px" }} className='mb-2' disabled={isLoadingSave || !!disabledSaveButton} type="submit" variant="contained" color="primary" onClick={createOrUpdateWorkingVersion}>{strings.save}</Button>}
          {
            !isAddMode &&
            (
              <div>
                <Button style={{ float: "left" }} type="submit" variant="contained" color="primary" onClick={handleClickOpen}>{strings.historyOfRegistrationStatuses}</Button>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth={"md"} fullWidth
                >
                  <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
                    {strings.historyOfRegistrationStatuses}
                  </DialogTitle>
                  <DialogContent>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 500 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell> {strings.action}</TableCell>
                            <TableCell> {strings.reason}</TableCell>
                            <TableCell> {strings.dateModified}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            historyActions?.registrationOrEmbgHistoryActions?.map((item: any, index: number) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell>{item.action}</TableCell>
                                  <TableCell>{item.reason}</TableCell>
                                  <TableCell>{DateTimeUtils.formatDateObject(item.dateModified)}</TableCell>
                                </TableRow>
                              )
                            })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </DialogContent>
                  <DialogActions>
                    <Button className='m-2' type="submit" variant="contained" color="error" onClick={handleCloseDialog}>{strings.close}</Button>
                  </DialogActions>
                </Dialog>
              </div>
            )
          }
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pt-2">
            <div style={{fontSize:"12px", color:"red"}}>Формата содржи задолжителни полиња на 1-та и 2-та страница означени со ѕвезда (*)</div>
            <React.Fragment>
              <Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
                {steps.map((step, index) => (
                  <Step key={index} completed={step.completed}>
                    <StepButton onClick={() => handleStepValidation(index)}><div style={{ fontWeight: activeStep === index ? 'bold' : 'normal' }}>{step.name}</div></StepButton>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {isLoading ? (
                  <div className="mt-3" style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress
                      size={40}
                    /></div>
                ) :
                  activeStep === steps.length ? (
                    <div className='p-3'>

        </div>
        ) : (
          <Formik
            initialValues={lastSubmittedValues || ShortTermFormInitialValues || {}}
            validationSchema={currentValidationSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
            validateOnChange={true}
          >
             
            {({ isSubmitting }) => {
              return (
              <Form id={formId}>
                {renderStepContent(activeStep)}
                
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                        {strings.prev}
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                  {isLastStep && !hasAuthority ? <Box /> : <Button
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            {isLastStep ? strings.end : strings.next}
                          </Button>}
                    {(isSubmitting || isLoading) && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
              )
    }}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
        <ConfirmationModal
            open={openBasicDataChangesModal}
            handleClose={handleCloseBasicDataChangesModal}
            title={strings.saveChanges}
            content={
                <div>
                    {strings.correctionChangeMessage}
                    <div className="d-flex justify-content-between mt-3">
                        <Button onClick={() => {handleClickChange()}}>{strings.change}</Button>
                        <Button onClick={() => {handleClickCorrection()}}>{strings.correction}</Button>
                    </div>
                </div>
            }
            variant={"contained"}
            color={"success"}
        />

          </CenteredCardLayout>
          {!isAddMode && id && <RightSidePanel templateDTOs={shortTermTemplates} id={parseInt(id)}/>}
          {!registrationData?.passivated ?
            <EMBGModal open={openRequest} handleClose={handleCloseRequest} isPassivateRequest={true} />
            :
            <ConfirmationModal
              open={openActivateRequest}
              handleClose={() => handleCloseActivateRequest(false)}
              handleConfirm={activateRequest}
              title={strings.activateRequest}
              content={strings.activateRequestConfirm}
              actionButtonName={strings.activate}
              variant={"contained"}
              color={"success"}
            />}
          <AutoScroller style={{float: "right", marginTop: "20px"}} direction={true}/>

        </>
      }
      />
    </div>

  );
}
