import { AddressCreationDTO } from "./AddressCreationDTO";
import { PlaceCreationDTO } from "./PlaceCreationDTO";

export class OrganizationCreationDTO{
     name:string;
     placeDTO?:PlaceCreationDTO;
     addressDTO?: AddressCreationDTO;
     constructor(json:OrganizationCreationDTO){
        this.name = json.name;
        this.placeDTO = json.placeDTO ? new PlaceCreationDTO(json.placeDTO) : undefined;
        this.addressDTO = json.addressDTO ? new AddressCreationDTO(json.addressDTO) : undefined;
     }
}
