import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import React, {useEffect, useState} from "react";
import { LightTooltip } from "../../components/LightTooltip";
import { SideBarPage } from "../../components/SideBarPage";
import { PageTable } from "../../components/table/PageTable";
import { strings } from "../../localization/Localization";
import {NomenclatureService} from "../../services/NomenclatureService";
import {NomenclatureEntryDTO} from "../../models/nomenclatures/NomenclatureEntryDTO";
import {NomenclatureDTO} from "../../models/nomenclatures/NomenclatureDTO";
import {useNavigate, useSearchParams} from "react-router-dom";
import {NumberParser} from "../../utils/NumberParser";
import {Routes} from "../../router/Routes";
import {BooleanParser} from "../../utils/BooleanParser";
import { Privileges } from "../../models/nomenclatures/Privileges";
import { AuthService } from "../../services/AuthService";
import { StorageService } from "../../storage/StorageService";


export function Nomenclatures() {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("size"), 5);
    const [totalElements, setTotalElements] = useState<number>(0);

    const [nomenclatureEntries, setNomenclatureEntries] = useState<NomenclatureEntryDTO[]>([]);
    const [nomenclatures, setNomenclatures] = useState<NomenclatureDTO[]>([]);
    const key = searchParams.get("key") ?? "";
    const nomenclatureID = NumberParser.parseNumberNullable(searchParams.get("nomenclatureId"));
    const active = BooleanParser.parseBooleanNullable(searchParams.get("active"));
    const nomenclatureEntryKey = searchParams.get("nomenclatureEntryKey") ?? "";
    const name = searchParams.get("name") ?? "";
    const nameSq = searchParams.get("nameSq") ?? "";
    const nameEn = searchParams.get("nameEn") ?? "";
    const [hasAuthority, setHasAuthority] = useState<boolean>();
    const navigate = useNavigate();

    
    useEffect(() => {
        async function userHasAuthority(): Promise<void> {
          const userRoles: string[] | null = StorageService.getUserRoles();
          if(userRoles !== null) {
            if(userRoles.includes(Privileges.MANAGE_NOMENCLATURES))
            {
              setHasAuthority(true)
            }
          } else {
            const authorization = await AuthService.getAuthorization();
            if(authorization.roles.includes(Privileges.MANAGE_NOMENCLATURES)) {
              setHasAuthority(true)
            }
          }
        }
    
        userHasAuthority().then((_) => { });
    
      }, []);
      
    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function setRowsPerPage(rowsPerPage: number) {
        setSearchParam("size", rowsPerPage.toString());
    }

    function setKey(value: string): void {
        setSearchParam("key", value);
    }

    function setNomenclatureId(value: string): void {
        setSearchParam("nomenclatureId", value);
    }

    function setActive(active: boolean | undefined): void {
        setSearchParam("active", active?.toString() ?? "");
        setPage(0);
    }

    const handleNomenclatureChange = (event: SelectChangeEvent) => {
        const nomenclatureId = event.target.value;
        
        if (nomenclatureId) {
            const nomenclature = nomenclatures.find((nomenclature) => nomenclature.id === +nomenclatureId);
            if (nomenclature) {
                setKey(nomenclature.key);
                setNomenclatureId(nomenclature.id.toString());
            }
        } else {
            setKey("");
        }
        
        setPage(0);
    };

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage)
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleKeyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("nomenclatureEntryKey", event.target.value);
        setPage(0);
    }

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("name", event.target.value);
        setPage(0);
    }

    function handleNameSqChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("nameSq", event.target.value);
        setPage(0);
    }

    function handleNameEnChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParam("nameEn", event.target.value);
        setPage(0);
    }

    function navigateScrapingScheduleRow(id: number): any {
        let route = Routes.NOMENCLATURE_DETAILS;
        route = route.replace(":id", id.toString());
        // route = route.replace(":n_id", nomenclatureID ? nomenclatureID.toString() : '');
        navigate(route);
    }

    function navigateToAddNomenclatureEntry(): any {
        let route = Routes.NOMENCLATURE_ENTRY_NEW;
        route = route.replace(":n_id", nomenclatureID ? nomenclatureID.toString() : '');
        navigate(route);
    }

    useEffect(() => {
        async function findAllNomenclatureEntriesForNomenclature(page: number): Promise<void> {
            NomenclatureService.findAllNomenclatureEntriesForNomenclatureForAdmin(page, rowsPerPage, String(key), name, nameSq, nameEn, active, nomenclatureEntryKey).then((data) => {
                setNomenclatureEntries(data.content);
                setTotalElements(data.totalElements);
            })
        }

        if (key) {
            findAllNomenclatureEntriesForNomenclature(page).then(_ => {
            });
        }
    }, [page, rowsPerPage, key, active, name, nameSq, nameEn, nomenclatureEntryKey]);

    useEffect(() => {
        async function getAllNomenclatures(): Promise<void> {
            NomenclatureService.getAllNomenclatures("", name, nameSq, nameEn).then((data) => {
                setNomenclatures(data);
                if (!nomenclatureID && data.length > 0) {
                    setNomenclatureId(data[0].id.toString());
                    setKey(data[0].key);
                }
            })
        }

        async function loadData(): Promise<void> {
            await getAllNomenclatures();
        }

        loadData().then((_) => {
        });

    }, [])


    const headerRows = [
        {
            id: "ordinalNumber",
            label: strings.ordinalNumber,
            search: true,
            width: "14%"
        },
        {
            id: "key",
            label: strings.key,
            width: "21%"
        },
        {
            id: "name",
            label: strings.labelMk,
            width: "20%"
        },
        {
            id: "nameSq",
            label: strings.labelAlb,
            width: "20%"
        },
        {
            id: "nameEn",
            label: strings.labelEn,
            width: "20%"
        },
        {
            id: "active",
            label: strings.active,
            width: "20%"
        },
    ]

    const searchRows = [
        {
            id: "ordinalNumber",
            // label: strings.ordinalNumber,
            search: false,
            type: "text",
            variant: "standard",
            // handleChange: handleChangeOrdinalNumber,
            width: "14%",
        },
        {
            id: "key",
            label: strings.key,
            search: true,
            type: "text",
            variant: "standard",
            handleChange: handleKeyChange,
            width: "21%"
        },
        {
            id: "name",
            label: strings.labelMk,
            search: true,
            type: "text",
            variant: "standard",
            handleChange: handleNameChange,
            width: "20%",
        },
        {
            id: "nameSq",
            label: strings.labelAlb,
            search: true,
            type: "text",
            variant: "standard",
            handleChange: handleNameSqChange,
            width: "20%"
        },
        {
            id: "nameEn",
            label: strings.labelEn,
            search: true,
            type: "text",
            variant: "standard",
            handleChange: handleNameEnChange,
            width: "25%"
        },
        {
            id: "active",
            label: strings.active,
            search: true,
            type: "nullableCheckbox",
            value:active,
            handleChange:setActive,
        }
    ]

    const rows = nomenclatureID && nomenclatureEntries.map((nomenclature) => {
        return {
                id: nomenclature.id,
                values: [
                    {
                        type: 'text',
                        name: "id",
                        value: <LightTooltip className={"tableTooltip"} title={nomenclature?.id}><span>{nomenclature?.id}</span></LightTooltip>
                    },
                    {
                        type: 'text',
                        name: "key",
                        value: <LightTooltip className={"tableTooltip"} title={nomenclature?.key}><span>{nomenclature?.key}</span></LightTooltip>
                    },
                    {
                        type: 'text',
                        name: "name",
                        value: <LightTooltip className={"tableTooltip"} title={nomenclature?.name}><span>{nomenclature?.name}</span></LightTooltip>
                    },
                    {
                        type: 'text',
                        name: "nameSq",
                        value: <LightTooltip className={"tableTooltip"} title={nomenclature?.nameSq}><span>{nomenclature?.nameSq}</span></LightTooltip>
                    },
                    {
                        type: 'text',
                        name: "nameEn",
                        value: <LightTooltip className={"tableTooltip"} title={nomenclature?.nameEn}><span>{nomenclature?.nameEn}</span></LightTooltip>
                    },
                    {
                        type: 'boolean',
                        name: "active",
                        value: nomenclature?.active
                    }
                ]
            }

    })

    return (
        <SideBarPage pageTitle={strings.nomenclatures} component={
            <React.Fragment>
                <Box component="div" display={"flex"} justifyContent={"space-between"} className={"mt-4"}>
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel id="typeOfForms">Номенклатури</InputLabel>
                        <Select value={nomenclatures.length === 0 ? "" : (nomenclatureID?.toString() ?? "")} onChange={handleNomenclatureChange} label={strings.typeOfForms}>
                            {nomenclatures?.map((nomenclature) => (
                                <MenuItem disableRipple key={nomenclature.id} value={nomenclature.id}>
                                    {nomenclature?.name}
                                </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    {hasAuthority && <Button variant={"contained"} onClick={navigateToAddNomenclatureEntry}>{strings.addNomenclatureEntry}</Button>}
                </Box>
                <PageTable
                    headerRows={headerRows}
                    searchRows={searchRows}
                    rows={rows}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    totalElements={totalElements}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleOpen={navigateScrapingScheduleRow}
                    clickable={true}
                />
            </React.Fragment>
        }/>

    )
}
