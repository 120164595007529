export class FileDTO {
    id: number;
    dateCreated: Date | string;
    dateModified: Date | string;
    name: string;
    mimeType: string;
    constructor(json:FileDTO){
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.name = json.name;
        this.mimeType = json.mimeType;
    }

}