import {SideBarPage} from "../components/SideBarPage";
import {strings} from "../localization/Localization";
import {CenteredCardLayout} from "../components/CenteredCardLayout";
import {TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {UserDTO} from "../models/UserDTO";
import {useParams} from "react-router-dom";
import {AuthService} from "../services/AuthService";
import {UserDetailsInfoResponse} from "../models/UserDetailsInfoResponse";



export function Profile(){
    const [userData, setUserData] = useState<UserDTO>();
    const [userDetails,setUserDetails] = useState<UserDetailsInfoResponse>();

    useEffect(() => {
        function getUserDetails(){
            AuthService.getUserDetails().then((res)=>{
                setUserDetails(res)
            })
        }

        async function getData(){
            await getUserDetails();
        }

        getData().then(_ => {
        });

    }, [])

    return (
        <SideBarPage pageTitle={strings.user} component={
            <CenteredCardLayout minWidth={"30%"} maxWidth={"30%"} className={"mt-5"}>
            <form>
                <Typography className="mb-2" style={{fontSize:"20px"}}>Kорисник</Typography>
                <Typography className="mb-2" style={{fontSize:"16px", color:"grey"}}>Детали за најавениот корисник</Typography>
                <TextField
                    className="mb-3 mt-3"
                    sx = {{width:'100%'}}
                    fullWidth
                    id="username"
                    value={userDetails?.username || strings.username }
                    disabled
                />
                <TextField
                    className="mb-3 mt-3"
                    sx = {{width:'100%'}}
                    fullWidth
                    id="firstName"
                    value={userDetails?.fullName || strings.firstName + " " + strings.lastName }
                    disabled
                />
                <TextField
                    className="mb-3 mt-3"
                    sx = {{width:'100%'}}
                    fullWidth
                    id="email"
                    value={userDetails?.email || strings.email}
                    disabled
                />
                <TextField
                    className="mb-3 mt-3"
                    sx = {{width:'100%'}}
                    fullWidth
                    id="email"
                    value={userDetails?.workingUnit || strings.workingUnit}
                    disabled
                />

            </form>
            </CenteredCardLayout>
        }
        />
    )
}