import FormModel from './ShortTermFormModel';
const {
  formField: {
    evidencyReasonMvrKey,
    placeOfRequest,
    temporaryStaySettlementReasonMvrKey,
    temporaryStayReportedByMvrKey,
    lastName,
    parentName,
    firstName,
    genderMvrKey,
    birthDate,
    abroadBirthPlace,
    inRSMBirthPlacePlace,
    inRSMBirthMunicipality,
    dateAndPlaceOfBorderCrossing,
    borderPointMvrKey,
    entranceDate,
    pastApartmentPrivateVisitPerson,
    pastMunicipalityPrivateVisitPerson,
    pastAddressPrivateVisitPerson,
    pastApartmentEntrancePrivateVisitPerson,
    pastNumberStreetPrivateVisitPerson,
    pastPlacePrivateVisitPerson,
    pastStreetPrivateVisitPerson,
    passportTypeMvrKey,
    countryPassport,
    passportNumber,
    visaTypeMvrKey,
    countryVisa,
    visaNumber,
    hotel,
    privateVisitPerson,
    embgPrivateVisitPerson,
    lastNamePrivateVisitPerson,
    firstNamePrivateVisitPerson,
    genderPrivateVisitPerson,
    citizen,
    addressPrivateVisitPerson,
    placePrivateVisitPerson,
    streetPrivateVisitPerson,
    numberStreetPrivateVisitPerson,
    approvedStayDate,
    timeStart,
    timeEnd,
    noteForeigner,
    municipalityPrivateVisitPerson,
    apartmentEntrancePrivateVisitPerson,
    apartmentPrivateVisitPerson,
    shortTermStayPossibilityMvrKey,
  }
} = FormModel;

export default {
  [evidencyReasonMvrKey.name]:'',
  [placeOfRequest.name]: '',
  [temporaryStaySettlementReasonMvrKey.name]: '',
  [temporaryStayReportedByMvrKey.name]: '',
  [lastName.name]: '',
  [parentName.name]: '',
  [firstName.name]: '',
  [genderMvrKey.name]: '',
  [birthDate.name]: '',
  [abroadBirthPlace.name]: '',
  [inRSMBirthPlacePlace.name]: '',
  [inRSMBirthMunicipality.name]: '',
  [dateAndPlaceOfBorderCrossing.name]: '',
  [borderPointMvrKey.name]: '',
  [entranceDate.name]: '',
  [shortTermStayPossibilityMvrKey.name]: '',
  [hotel.name]: '',
  [passportTypeMvrKey.name]: '',
  [countryPassport.name]: '',
  [passportNumber.name]: '',
  [visaTypeMvrKey.name]: '',
  [countryVisa.name]: '',
  [visaNumber.name]: '',
  [privateVisitPerson.name]: '',
  [embgPrivateVisitPerson.name]: '',
  [lastNamePrivateVisitPerson.name]: '',
  [firstNamePrivateVisitPerson.name]: '',
  [genderPrivateVisitPerson.name]: '',
  [citizen.name]: '',
  [addressPrivateVisitPerson.name]: '',
  [placePrivateVisitPerson.name]: '',
  [streetPrivateVisitPerson.name]: '',
  [numberStreetPrivateVisitPerson.name]: '',
  [pastStreetPrivateVisitPerson.name]: '',
  [pastMunicipalityPrivateVisitPerson.name]: '',
  [pastAddressPrivateVisitPerson.name]: '',
  [pastApartmentPrivateVisitPerson.name]: '',
  [pastAddressPrivateVisitPerson.name]: '',
  [pastPlacePrivateVisitPerson.name]: '',
  [pastNumberStreetPrivateVisitPerson.name]: '',
  [pastApartmentEntrancePrivateVisitPerson.name]: '',
  [approvedStayDate.name]: '',
  [timeStart.name]: '',
  [timeEnd.name]: '',
  [noteForeigner.name]: '',
  [municipalityPrivateVisitPerson.name]: '',
  [apartmentPrivateVisitPerson.name]: '',
  [apartmentEntrancePrivateVisitPerson.name]:'',
};
