import { styled, useTheme } from "@mui/material/styles";
import { Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Grid } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { DecisionDTO } from "../../models/decision/DecisionDTO";
import { TemplateDTO } from "../../models/templates/TemplateDTO";
import { strings } from "../../localization/Localization";
import "./RightSidePanel.css"
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import * as Yup from 'yup';
import { NomenclatureEntryDTO } from "../../models/nomenclatures/NomenclatureEntryDTO";
import { getAllEntriesForNomenclatureAppealDecisions, getAllEntriesForNomenclatureComplaintDecisions, getAllEntriesForNomenclatureDecisionTypes, getAllEntriesForNomenclatureIdentificationDocuments } from "../../common/NomenclatureMethods";
import React from "react";
import { Form, Formik } from "formik";
import SelectField from "../../pages/applications/formFields/SelectField";
import InputField from "../../pages/applications/formFields/InputField";
import { DatePickerField } from "../../pages/applications/formFields/DatePickerFields";
import { DecisionService } from "../../services/DecisionService";
import { showErrorDialog, showSuccessDialog } from "../../common/Dialogues";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { PageTable } from "../table/PageTable";
import { LightTooltip } from "../LightTooltip";
import { ComplaintDTO } from "../../models/complaint/ComplaintDTO";
import { AppealDTO } from "../../models/appeal/AppealDTO";
import { NomenclatureConstants } from "../../constants/NomenclatureConstants";
import { RegistrationReducedDTO } from "../../models/registration/RegistrationReducedDTO";
import { RegistrationDTO } from "../../models/foreign_citizens/RegistrationDTO";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import useStyles from '../../pages/ShortTermStayForm/styles/styles';
import { TemplateService } from "../../services/TemplateService";
import { FileDTO } from "../../models/foreign_citizens/FileDTO";
import { FileService } from "../../services/FileService";

const Root = styled('div')({
    position: 'absolute',
    height: 60,
    right: 0,
    top: 160,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    opacity: 0.9,
    padding: 0,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    zIndex: 999,
    color: "white",
    backgroundColor: "darkblue",
    '&:hover': {
        backgroundColor: "grey",
        opacity: 1,
    },

    '& .settingsButton': {
        '& > span': {
            animation: 'rotating 3s linear infinite',
        },
    },
    '@keyframes rotating': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
});

const ValidationSchema = Yup.object().shape({
    decisionTypeMvrKey: Yup.string().required("Полето е задолжително"),
    decisionDate: Yup.string().required("Полето е задолжително")
});

interface SettingsPanelProps {
    id?: number,
    asylumForm?: boolean,
    foreignCitizenForm?: boolean,
    diplomatForm?: boolean,
    decisionDTOs?: DecisionDTO[],
    templateDTOs?: TemplateDTO[],
    registrationFiles?: FileDTO[],
    registration?: RegistrationDTO,
    updateChildReg?: (data: number[]) => void,
    hasAuthority?: boolean,
    workingVersionId?: number | undefined
}

interface CustomButtonProps {
    selectedChildrenRegistration: number[],
    children: ReactNode,
    add?: boolean,
    disabled?: boolean
}

const CustomButton = ({ selectedChildrenRegistration, children, add, disabled }: CustomButtonProps) => {
    const isButtonDisabled = selectedChildrenRegistration.length === 0

    return (
        <>
            {isButtonDisabled ?
                <LightTooltip title="Немате селектирано ниту една регистрација">
                    <Box component={"span"}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={true}
                        >
                            {add ? strings.add : strings.edit}
                        </Button>
                    </Box>
                </LightTooltip> : <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={disabled ? true : false}
                >
                    {add ? strings.add : strings.edit}
                </Button>}
        </>
    );
};



function RightSidePanel(props: SettingsPanelProps) {
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false)
    const [openDecisionForm, setOpenDecisionForm] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [complaintsPage, setComplaintsPage] = useState<number>(0)
    const [appealsPage, setAppealsPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(5)
    const [complaintsPerPage, setComplaintsPerPage] = useState<number>(2)
    const [appealsPerPage, setAppealsPerPage] = useState<number>(2)
    const [registrationDecisions, setRegistrationDecisions] = useState<DecisionDTO[]>([])
    const [registrationComplaints, setRegistrationComplaints] = useState<ComplaintDTO[]>([])
    const [registrationAppeals, setRegistrationAppeals] = useState<AppealDTO[]>([])
    const [totalElements, setTotalElements] = useState<number>(0)
    const [totalComplaintsForDecision, setTotalComplaintsForDecision] = useState<number>(0)
    const [totalAppealsForDecision, setTotalAppealsForDecision] = useState<number>(0)
    const [decisionTypes, setDecisionTypes] = useState<NomenclatureEntryDTO[]>([])
    const [appealDecisions, setAppealDecisions] = useState<NomenclatureEntryDTO[]>([])
    const [complaintDecisions, setComplaintDecisions] = useState<NomenclatureEntryDTO[]>([])
    const [identificationDocuments, setIdentificationDocuments] = useState<NomenclatureEntryDTO[]>([])
    const [decisionData, setDecisionData] = useState<DecisionDTO>()
    const [complaintData, setComplaintData] = useState<ComplaintDTO>()
    const [appealData, setAppealData] = useState<AppealDTO>()
    const [openEditDecisionForm, setOpenEditDecisionForm] = useState<boolean>(false)
    const [openComplaintForm, setOpenComplaintForm] = useState<boolean>(false)
    const [openAppealForm, setOpenAppealForm] = useState<boolean>(false)
    const [openEditAppealForm, setOpenEditAppealForm] = useState<boolean>(false)
    const [openEditComplaintForm, setOpenEditComplaintForm] = useState<boolean>(false)
    const [decisionIdTemporary, setDecisionIdTemporary] = useState<number | null>(null)
    const [temporaryAppealId, setTemporaryAppealId] = useState<number | null>(null)
    const [temporaryComplaintId, setTemporaryComplaintId] = useState<number | null>(null)
    const [selectedChildrenRegistration, setSelectedChildrenRegistrations] = useState<number[]>([])
    const [loadingDecision, setLoadingDecision] = useState<boolean>(false)
    const [loadingDecisionAppeal, setLoadingDecisionAppeal] = useState<boolean>(false)
    const [loadingDecisionComplaint, setLoadingDecisionComplaint] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [loadingComplaint, setLoadingComplaint] = useState<boolean>(false)
    const [isDisabledComplaint, setIsDisabledComplaint] = useState<boolean>(false)
    const [loadingAppeal, setLoadingAppeal] = useState<boolean>(false)
    const [isDisabledAppeal, setIsDisabledAppeal] = useState<boolean>(false)
    const [loadingComplaintEdit, setLoadingComplaintEdit] = useState<boolean>(false)
    const [isDisabledComplaintEdit, setIsDisabledComplaintEdit] = useState<boolean>(false)
    const [loadingAppealEdit, setLoadingAppealEdit] = useState<boolean>(false)
    const [isDisabledAppealEdit, setIsDisabledAppealEdit] = useState<boolean>(false)
    const [isTemplateGenerating, setIsTemplateGenerating] = useState(false)
    const classes = useStyles()
    const registration: RegistrationDTO = props.registration ?? {} as RegistrationDTO
    const childRegistrations = props.registration?.childRegistrations ? props.registration.childRegistrations : []
    const parentAndChildRegistrations = [registration, ...childRegistrations]
    const decisionTypesDefault: string[] = [NomenclatureConstants.decisionTypePersonWithRefugeeStatus, NomenclatureConstants.decisionTypePersonWithSubsidiaryProtectionStatus,
    NomenclatureConstants.decisionTypeRegularRejected, NomenclatureConstants.decisionTypeUrgentRejected, NomenclatureConstants.decisionTypeDecisionOnStoppedProcedure, NomenclatureConstants.decisionTypeTemporaryProtection]

    useEffect(() => {
        async function loadData(): Promise<void> {
            if (props.asylumForm) {
                await getAllEntriesForNomenclatureDecisionTypes().then((res) => {
                    if (registrationDecisions.length > 0) {
                        setDecisionTypes(res)
                    } else {
                        const filteredDecisionTypes = res.filter((decisionType) => {
                            return decisionTypesDefault.includes(decisionType.key)
                        })
                        setDecisionTypes(filteredDecisionTypes)
                    }
                })
            }
        }

        loadData().then((_) => { });
    }, [registrationDecisions])

    async function getAllRegistrationDecisions() {
        if (props.workingVersionId && props.asylumForm) {
            await DecisionService.getAllDecisionsForRegistration(page, rowsPerPage, props.workingVersionId).then((response) => {
                setRegistrationDecisions(response.content);
                setTotalElements(response.totalElements);
            })
        } 
        else if (props.id !== undefined && props.asylumForm) {
            await DecisionService.getAllDecisionsForRegistration(page, rowsPerPage, props.id).then((response) => {
                setRegistrationDecisions(response.content);
                setTotalElements(response.totalElements);
            })
        } else {
            return;
        }
    }

    async function getAllComplaintsRegistrations(decisionId: number) {
        if (props.workingVersionId && props.asylumForm) {
            await DecisionService.getAllComplaintsForRegistration(props.workingVersionId, null, decisionId, null, null, complaintsPage, complaintsPerPage).then((response) => {
                setRegistrationComplaints(response.content);
                setTotalComplaintsForDecision(response.totalElements);
            })
        }
        else if (props.id !== undefined && props.asylumForm) {
            await DecisionService.getAllComplaintsForRegistration(props.id, null, decisionId, null, null, complaintsPage, complaintsPerPage).then((response) => {
                setRegistrationComplaints(response.content);
                setTotalComplaintsForDecision(response.totalElements);
            })
        } else {
            return;
        }
    }

    async function getAllApealsRegistrations(decisionId: number) {
        if (props.workingVersionId !== undefined && props.asylumForm) {
            await DecisionService.getAllAppealsForRegistration(props.workingVersionId, null, decisionId, null, null, appealsPage, appealsPerPage).then((response) => {
                setRegistrationAppeals(response.content);
                setTotalAppealsForDecision(response.totalElements);
            })
        }
        else if (props.id && props.asylumForm) {
            await DecisionService.getAllAppealsForRegistration(props.id, null, decisionId, null, null, appealsPage, appealsPerPage).then((response) => {
                setRegistrationAppeals(response.content);
                setTotalAppealsForDecision(response.totalElements);
            })
        } 
        else {
            return;
        }
    }

    useEffect(() => {
        async function loadPassportTypes(): Promise<void> {
            if (props.asylumForm) {
                await getAllEntriesForNomenclatureIdentificationDocuments().then((res) => { setIdentificationDocuments(res) })
            }
        }

        loadPassportTypes().then((_) => { });

    }, []);

    useEffect(() => {

        getAllRegistrationDecisions().then((_) => { });

    }, [page, rowsPerPage, props.workingVersionId, props.id]);

    useEffect(() => {
        if (decisionIdTemporary) {
            getAllComplaintsRegistrations(decisionIdTemporary).then((_) => { });
        }
    }, [complaintsPage, complaintsPerPage, props.workingVersionId, props.id]);

    useEffect(() => {
        if (decisionIdTemporary) {
            getAllApealsRegistrations(decisionIdTemporary).then((_) => { });
        }
    }, [appealsPage, appealsPerPage, props.workingVersionId, props.id]);

    async function createDecision(values: any, id: number) {
        setLoading(true)
        setIsDisabled(true)
        await DecisionService.createDecision(id, values, selectedChildrenRegistration)
            .then(() => {
                if (openDecisionForm) {
                    setOpenDecisionForm(false)
                }
                if (openEditDecisionForm) {
                    setOpenEditDecisionForm(false)
                }
                setPage(0)
                getAllRegistrationDecisions()
                setDecisionData(undefined)
                setLoading(false)
                setIsDisabled(false)
                if (props.updateChildReg) {
                    props.updateChildReg(selectedChildrenRegistration)
                }
                showSuccessDialog(strings.succesfullyAddedDecision, strings.succesfullyAddedDecision, strings.ok).then(_ => {
                    setSelectedChildrenRegistrations([])
                });
            })
            .catch((e) => {
                const errorMessage = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {
                })
                setOpenDecisionForm(false)
                setSelectedChildrenRegistrations([])
                setLoading(false)
                setIsDisabled(false)
            });
    }

    async function createComplaint(values: any, id: number) {
        setLoadingComplaint(true)
        setIsDisabledComplaint(true)
        await DecisionService.createComplaintForDecision(id, values)
            .then(() => {
                setOpenComplaintForm(false)
                if (decisionIdTemporary) {
                    handleOpenDecision(decisionIdTemporary)
                }
                setComplaintsPage(0)
                setLoadingComplaint(false)
                setIsDisabledComplaint(false)
                showSuccessDialog(strings.successfullyAddedComplaint, strings.successfullyAddedComplaint, strings.ok).then(_ => {
                });
            })
            .catch((e) => {
                const errorMessage = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {
                })
                setOpenComplaintForm(false)
                setLoadingComplaint(false)
                setIsDisabledComplaint(false)
            });
    }

    async function createAppeal(values: any, id: number) {
        setLoadingAppeal(true)
        setIsDisabledAppeal(true)
        await DecisionService.createAppealForDecision(id, values)
            .then(() => {
                setOpenAppealForm(false)
                if (decisionIdTemporary) {
                    handleOpenDecision(decisionIdTemporary)
                }
                setAppealsPage(0)
                setLoadingAppeal(false)
                setIsDisabledAppeal(false)
                showSuccessDialog(strings.successfullyAddedAppeal, strings.successfullyAddedAppeal, strings.ok).then(_ => {

                });
            })
            .catch((e) => {
                const errorMessage = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {
                })
                setOpenAppealForm(false)
                setLoadingAppeal(false)
                setIsDisabledAppeal(false)
            });
    }

    async function editAppeal(values: any, id: number, appealId: number) {
        setLoadingAppealEdit(true)
        setIsDisabledAppealEdit(true)
        await DecisionService.updateAppeal(id, appealId, values)
            .then(() => {
                setOpenEditAppealForm(false)
                if (decisionIdTemporary) {
                    getAllApealsRegistrations(decisionIdTemporary)
                }
                setLoadingAppealEdit(false)
                setIsDisabledAppealEdit(false)
                showSuccessDialog(strings.successfullyEditedAppeal, strings.successfullyEditedAppeal, strings.ok).then(_ => {
                });
            })
            .catch((e) => {
                const errorMessage = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {
                })
                setOpenEditAppealForm(false)
                setLoadingAppealEdit(false)
                setIsDisabledAppealEdit(false)
            });
    }

    async function editComplaint(values: any, id: number, complaintId: number) {
        setLoadingComplaintEdit(true)
        setIsDisabledComplaintEdit(true)
        await DecisionService.updateComplaint(id, complaintId, values)
            .then(() => {
                setOpenEditComplaintForm(false)
                if (decisionIdTemporary) {
                    getAllComplaintsRegistrations(decisionIdTemporary)
                }
                setLoadingComplaintEdit(false)
                setIsDisabledComplaintEdit(false)
                showSuccessDialog(strings.successfullyEditedComplaint, strings.successfullyEditedComplaint, strings.ok).then(_ => {
                });
            })
            .catch((e) => {
                const errorMessage = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {
                })
                setOpenEditComplaintForm(false)
                setLoadingComplaintEdit(false)
                setIsDisabledComplaintEdit(false)
            });
    }

    async function handleSubmit(values: {}, actions: any) {
        if (props.id) {
            await createDecision(values, props.id);
        }
    }

    function handleSubmitComplaint(values: {}, actions: any) {
        if (decisionIdTemporary && props.id) {
            createComplaint(values, props.id)
        }
    }

    function handleSubmitAppeal(values: {}, actions: any) {
        if (decisionIdTemporary && props.id) {
            createAppeal(values, props.id)
        }
    }

    function handleEditAppeal(values: {}, actions: any) {
        if (decisionIdTemporary && props.id && temporaryAppealId) {
            editAppeal(values, props.id, temporaryAppealId)
        }
    }

    function handleEditComplaint(values: {}, actions: any) {
        if (decisionIdTemporary && props.id && temporaryComplaintId) {
            editComplaint(values, props.id, temporaryComplaintId)
        }
    }


    function handleClickOpenDecisionForm() {
        setOpenDecisionForm(true);
    }

    function handleClickCloseDecisionForm() {
        setOpenDecisionForm(false);
        setSelectedChildrenRegistrations([])
    }

    function handleClickOpenComplaintForm() {
        setOpenComplaintForm(true);
        if (complaintDecisions.length === 0) {
            getAllEntriesForNomenclatureComplaintDecisions().then((res) => { setComplaintDecisions(res) })
        }
    }

    function handleClickCloseComplaintForm() {
        setOpenComplaintForm(false);
    }

    function handleClickOpenAppealForm() {
        setOpenAppealForm(true);
        if (appealDecisions.length === 0) {
            getAllEntriesForNomenclatureAppealDecisions().then((res) => { setAppealDecisions(res) })
        }
    }

    function handleClickCloseAppealForm() {
        setOpenAppealForm(false);
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleChangeComplaintsPage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setComplaintsPage(newPage);
    }

    function handleChangeComplaintsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setComplaintsPerPage(parseInt(event.target.value));
        setComplaintsPage(0);
    }

    function handleChangeAppealsPage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setAppealsPage(newPage);
    }

    function handleChangeAppealsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setAppealsPerPage(parseInt(event.target.value));
        setAppealsPage(0);
    }

    const headerRows = [
        {
            id: "dateCreated",
            label: strings.dateCreated,
            width: "10%"
        },
        {
            id: "decisionTypeMvrKey",
            label: strings.decisionTypeMvrKey,
            width: "12.5%"
        },
        {
            id: "decisionDate",
            label: strings.decisionDate,
            width: "12.5%"
        }
    ]

    const complaintsRows = [
        {
            id: "complaintNumber",
            label: strings.complaintNumber,
            width: "10%"
        },
        {
            id: "complaintDate",
            label: strings.complaintDate,
            width: "10%"
        },
        {
            id: "complaintDecisionMvrKey",
            label: strings.complaintDecision,
            width: "12.5%"
        }
    ]

    const appealsRows = [
        {
            id: "appealNumber",
            label: strings.appealNumber,
            width: "10%"
        },
        {
            id: "appealDate",
            label: strings.appealDate,
            width: "10%"
        },
        {
            id: "appealDecisionMvrKey",
            label: strings.appealDecision,
            width: "12.5%"
        }
    ]

    const registrationRows = [
        {
            id: "checkbox",
            width: "3%"
        },
        {
            id: "id",
            label: strings.ordinalNumber,
            width: "5%"
        },
        {
            id: "firstName",
            label: strings.firstName,
            search: false,
            type: "text",
            variant: "standard",
            width: "10%"
        },
        {
            id: "lastName",
            label: strings.lastName,
            search: false,
            type: "text",
            variant: "standard",
            width: "10%"
        },
        {
            id: "holder",
            label: strings.claimHolder,
            width: "3%"
        },
    ]


    const rows = registrationDecisions.map((decision) => {
        return {
            key: decision.id,
            id: decision.id,
            values: [
                {
                    type: 'text',
                    name: "startDate",
                    value: <LightTooltip className={"tableTooltip"} title={decision?.dateCreated ? DateTimeUtils.formatDateObject(decision?.dateCreated) : ""}><span>{decision?.dateCreated ? DateTimeUtils.formatDateObject(decision?.dateCreated) : ""}</span></LightTooltip>

                },
                {
                    type: 'text',
                    name: "decisionTypeMvrKey",
                    value: <LightTooltip className={"tableTooltip"} title={decision.decisionType?.displayName}><span>
                        {decision.decisionType !== null && decision.decisionType?.displayName ? decision.decisionType?.displayName.length > 60 ? decision.decisionType.displayName.substring(0, 60) + "..."
                            : decision.decisionType.displayName : ""}
                    </span></LightTooltip>
                },
                {
                    type: 'text',
                    name: "lastName",
                    value: <LightTooltip className={"tableTooltip"} title={decision?.decisionDate ? decision?.decisionDate?.toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, ".") : ""}>
                        <span>{decision?.decisionDate ? decision?.decisionDate?.toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, ".") : ""}</span></LightTooltip>
                }
            ]
        }
    })

    const complaintRows = registrationComplaints.map((complaint) => {
        return {
            key: complaint.id,
            id: complaint.id,
            values: [
                {
                    type: 'text',
                    name: "complaintNumber",
                    value: <LightTooltip className={"tableTooltip"} title={complaint?.complaintNumber}><span>{complaint?.complaintNumber}</span></LightTooltip>

                },
                {
                    type: 'text',
                    name: "complaintDate",
                    value: <LightTooltip className={"tableTooltip"} title={complaint?.complaintDate ? complaint?.complaintDate?.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, ".") : ""}>
                        <span>{complaint?.complaintDate ? complaint?.complaintDate?.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, ".") : ""}</span></LightTooltip>
                },
                {
                    type: 'text',
                    name: "complaintDecisionMvrKey",
                    value: <LightTooltip className={"tableTooltip"} title={complaint.complaintDecision?.displayName}>
                        <span>{complaint.complaintDecision !== null && complaint.complaintDecision?.displayName ? complaint.complaintDecision?.displayName.length > 60 ? complaint.complaintDecision.displayName.substring(0, 60) + "..."
                            : complaint.complaintDecision.displayName : ""}</span></LightTooltip>
                }
            ]
        }
    })

    const appealRows = registrationAppeals.map((appeal) => {
        return {
            key: appeal.id,
            id: appeal.id,
            values: [
                {
                    type: 'text',
                    name: "appealNumber",
                    value: <LightTooltip className={"tableTooltip"} title={appeal?.appealNumber}><span>{appeal?.appealNumber}</span></LightTooltip>

                },
                {
                    type: 'text',
                    name: "appealDate",
                    value: <LightTooltip className={"tableTooltip"} title={appeal?.appealDate ? appeal?.appealDate?.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, ".") : ""}>
                        <span>{appeal?.appealDate ? appeal?.appealDate?.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, ".") : ""}</span></LightTooltip>
                },
                {
                    type: 'text',
                    name: "appealDecisionMvrKey",
                    value: <LightTooltip className={"tableTooltip"} title={appeal.appealDecision?.displayName}>
                        <span>{appeal.appealDecision !== null && appeal.appealDecision?.displayName ? appeal.appealDecision?.displayName.length > 60 ? appeal.appealDecision.displayName.substring(0, 60) + "..."
                            : appeal.appealDecision.displayName : ""}</span></LightTooltip>
                }
            ]
        }
    })

    const registrationValues = parentAndChildRegistrations?.map((registration) => {
        const values = [];
        const rowId = registration.id;

        const isChecked = selectedChildrenRegistration.includes(rowId);

        const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, registrationId: number) => {
            const isChecked = event.target.checked;

            setSelectedChildrenRegistrations((prevSelectedChildrenRegistrations) => {
                if (isChecked) {
                    return [...prevSelectedChildrenRegistrations, registrationId];
                } else {
                    return prevSelectedChildrenRegistrations.filter((id: number) => id !== registrationId);
                }
            });
        };

        if (registration instanceof RegistrationReducedDTO) {
            values.push({
                type: 'text',
                name: 'checkbox',
                value: decisionData === undefined ? (
                    <Checkbox onChange={(event) => handleCheckboxChange(event, rowId)} checked={isChecked} />
                ) : decisionData.registrationDecisionDTOList?.some((item) => item.registrationId === registration.id) ? (
                    props.hasAuthority ? (
                        <Checkbox onChange={(event) => handleCheckboxChange(event, rowId)} checked={isChecked} />
                    ) : (
                        <Checkbox onChange={(event) => handleCheckboxChange(event, rowId)} checked={isChecked} disabled />
                    )
                ) : props.hasAuthority ? (
                    <Checkbox onChange={(event) => handleCheckboxChange(event, rowId)} checked={isChecked} />
                ) : (
                    <Checkbox disabled />
                ),
            });
        } else if (registration instanceof RegistrationDTO) {
            values.push({
                type: 'text',
                name: 'checkbox',
                value: decisionData === undefined ? (
                    <Checkbox onChange={(event) => handleCheckboxChange(event, rowId)} checked={isChecked} />
                ) : decisionData.registrationDecisionDTOList?.some((item) => item.registrationId === registration.id) ? (
                    props.hasAuthority ? (
                        <Checkbox onChange={(event) => handleCheckboxChange(event, rowId)} checked={isChecked} />
                    ) : (
                        <Checkbox onChange={(event) => handleCheckboxChange(event, rowId)} checked={isChecked} disabled />
                    )
                ) : props.hasAuthority ? (
                    <Checkbox onChange={(event) => handleCheckboxChange(event, rowId)} checked={isChecked} />
                ) : (
                    <Checkbox disabled />
                ),
            });
        }
        values.push({
            type: 'text',
            name: 'id',
            value: <LightTooltip className={'tableTooltip'} title={registration?.id}><span>{registration?.id}</span></LightTooltip>,
        },
            {
                type: 'text',
                name: 'firstName',
                value: <LightTooltip className={'tableTooltip'} title={registration?.foreignCitizen?.personData?.firstName}><span>{registration?.foreignCitizen?.personData?.firstName}</span></LightTooltip>,
            },
            {
                type: 'text',
                name: 'lastName',
                value: <LightTooltip className={'tableTooltip'} title={registration?.foreignCitizen?.personData?.lastName}><span>{registration?.foreignCitizen?.personData?.lastName}</span></LightTooltip>,
            },
        )
        if (registration instanceof RegistrationReducedDTO) {
            values.push({
                type: 'text',
                name: 'holder',
                value: <CancelIcon />
            });
        }
        else if (registration instanceof RegistrationDTO) {
            values.push({
                type: 'text',
                name: 'holder',
                value: <CheckCircleIcon />
            });
        }
        return {
            key: registration.id,
            id: registration.id,
            values: values,
        };
    });


    function handleOpen() {
        setOpen(!open);
    };

    function handleClose() {
        setOpen(false);
    };

    async function handleOpenDecision(decisionId: number): Promise<void> {
        if (!openDecisionForm) {
            setOpenEditDecisionForm(true)
        }
        if (decisionIdTemporary === null) {
            setDecisionIdTemporary(decisionId)
        }
        setLoadingDecision(true)
        if (props.id) {
            await DecisionService.getDecisionById(props.id, decisionId).then((response) => {
                setDecisionData(response)
                const decisionChildrenRegistrations: any = response.registrationDecisionDTOList?.map(reg => {
                    const registrationId = reg.registrationId;
                    return registrationId;
                });
                setSelectedChildrenRegistrations(decisionChildrenRegistrations);
            })
        }
        if (props.id && decisionId) {
            await getAllComplaintsRegistrations(decisionId).then((_) => { });
            await getAllApealsRegistrations(decisionId).then((_) => { });
        }
        setLoadingDecision(false)
    };

    async function generateTemplate(path: string, name: string) {
        setIsTemplateGenerating(true)
        if (props.id) {
            await TemplateService.generateTemplate(props.id, path, name).catch((e) => {
                const errorMessage = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
            });
        }
        setIsTemplateGenerating(false)
    };

    function handleClickCloseEditDecisionForm() {
        setDecisionIdTemporary(null)
        setDecisionData(undefined)
        setOpenEditDecisionForm(false)
        setAppealData(undefined)
        setComplaintData(undefined)
        setSelectedChildrenRegistrations([])
        setAppealsPage(0)
        setComplaintsPage(0)
    }

    function handleClickCloseEditAppealForm() {
        setOpenEditAppealForm(false)
        setAppealData(undefined)
    }

    function handleClickCloseEditComplaintForm() {
        setOpenEditComplaintForm(false)
        setComplaintData(undefined)
    }

    async function handleOpenAppeal(appealId: number): Promise<void> {
        if (!openEditAppealForm) {
            setOpenEditAppealForm(true)
            setTemporaryAppealId(appealId)
        }
        setLoadingDecisionAppeal(true)
        if (appealDecisions.length === 0) {
            getAllEntriesForNomenclatureAppealDecisions().then((res) => { setAppealDecisions(res) })
        }
        if (props.id) {
            await DecisionService.getAppealById(props.id, appealId).then((response) => {
                setAppealData(response)
            })
        }
        setLoadingDecisionAppeal(false)
    };

    async function handleOpenComplaint(complaintId: number): Promise<void> {
        if (!openEditComplaintForm) {
            setOpenEditComplaintForm(true)
            setTemporaryComplaintId(complaintId)
        }
        setLoadingDecisionComplaint(true)
        if (complaintDecisions.length === 0) {
            getAllEntriesForNomenclatureComplaintDecisions().then((res) => { setComplaintDecisions(res) })
        }
        if (props.id) {
            await DecisionService.getComplaintById(props.id, complaintId).then((response) => {
                setComplaintData(response)
            })
        }
        setLoadingDecisionComplaint(false)
    };

    async function downloadFileForRegistration(id: number, name: string) {
        await FileService.downloadFileContent(id, name).catch((e) => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
        });
    }

    return (
        <>
            {isTemplateGenerating &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isTemplateGenerating}
                >
                    <CircularProgress size={60} style={{ color: "#3f51b5" }} />
                </Backdrop>}
            <Root id="fuse-settings-schemes" className="buttonWrapper">
                <Button
                    className="min-w-40 w-40 h-40 m-0"
                    onClick={handleOpen}
                    variant="text"
                    color="inherit"
                >
                    {strings.decisionsTemplates}
                </Button>
            </Root>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={handleClose}
            >
                <Box className="box-panel">
                    {props.asylumForm && <>
                        <h5>{strings.decisions}</h5>
                        <div className="pr-2">
                            <PageTable
                                reducedOptions={true}
                                headerRows={headerRows}
                                rows={rows}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                totalElements={totalElements}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                handleOpen={handleOpenDecision}
                                clickable={true}
                            />
                        </div> <br /></>
                    }
                    {props.asylumForm && props.hasAuthority && props.registration?.submitted && <><Button variant={"contained"} onClick={handleClickOpenDecisionForm} className="button-panel btn-sm">{strings.addDecision}</Button><br /><br /><br /></>}
                    {props.templateDTOs && props.templateDTOs?.length > 0 && <h5>{strings.templates}</h5>}
                    {props.templateDTOs?.map((template) => {
                        return (
                            <div key={template.name}>
                                <a onClick={() => generateTemplate(template.path, template.name)} className='cursor-pointer fileLink'>{template.name}</a>
                            </div>
                        )
                    })}
                    {props.foreignCitizenForm && (props.registrationFiles && props.registrationFiles.length > 0) && <div className="mt-4">
                        <h5>{strings.attachedFiles}</h5>
                        {props.registrationFiles?.map((file) => {
                            return (
                                <div key={file.id}>
                                    <a onClick={() => downloadFileForRegistration(file.id, file.name)} className='cursor-pointer fileLink'>{file.name}</a>
                                </div>
                            )
                        })}
                    </div>}
                    {props.diplomatForm && (props.registrationFiles && props.registrationFiles.length > 0) && <div className="mt-4">
                        <h5>{strings.attachedFiles}</h5>
                        {props.registrationFiles?.map((file) => {
                            return (
                                <div key={file.id}>
                                    <a onClick={() => downloadFileForRegistration(file.id, file.name)} className='cursor-pointer fileLink'>{file.name}</a>
                                </div>
                            )
                        })}
                    </div>}
                </Box>
                <Dialog open={openDecisionForm} onClose={handleClickCloseDecisionForm} maxWidth="md" fullWidth>
                    <DialogTitle>{strings.createDecision}</DialogTitle>
                    <DialogContent>
                        <br />
                        <React.Fragment>
                            <Formik
                                initialValues={{
                                    decisionTypeMvrKey: '',
                                    decisionDate: '',
                                    identificationDocumentTypeMvrKey: '',
                                    idCardNumber: '',
                                    expirationDate: '',
                                    documentExtensionDate: ''
                                }}
                                validationSchema={ValidationSchema}
                                onSubmit={handleSubmit}
                                validateOnChange={true}
                                enableReinitialize={true}
                            >
                                {({ errors, values }) => {
                                    return (
                                        <Form>
                                            <>
                                                <Grid container rowSpacing={1}
                                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <SelectField className="pb-3"
                                                            name="decisionTypeMvrKey"
                                                            label={strings.decisionTypeMvrKeyMandatoryField}
                                                            data={decisionTypes}
                                                            onChange={(event) => {
                                                                const decisionTypeValue = event.target.value;
                                                                if (decisionTypeValue === null) {
                                                                    setSelectedChildrenRegistrations([]);
                                                                }
                                                            }}
                                                        />
                                                        <DatePickerField
                                                            name="decisionDate"
                                                            label={strings.decisionDateMandatoryField}
                                                            className="pb-3"
                                                        />
                                                        <SelectField className="pb-3"
                                                            name="identificationDocumentTypeMvrKey"
                                                            label={strings.documentType}
                                                            data={identificationDocuments}
                                                        />
                                                        <InputField name="idCardNumber" label={strings.numberID} fullWidth className="pb-3" />
                                                        <DatePickerField
                                                            name="expirationDate"
                                                            label={strings.dateExpired}
                                                            className="pb-3"
                                                        />
                                                        <DatePickerField
                                                            name="documentExtensionDate"
                                                            label={strings.documentExtensionDate}
                                                            className="pb-3"
                                                        />
                                                        {values.decisionTypeMvrKey && <Box>
                                                            <div>
                                                                <PageTable
                                                                    headerRows={registrationRows}
                                                                    rows={registrationValues}
                                                                    totalElements={totalComplaintsForDecision}
                                                                    withoutPagination={true}
                                                                    clickable={false} />
                                                            </div><br />
                                                        </Box>}
                                                    </Grid>
                                                </Grid>
                                                <DialogActions>
                                                    <Button onClick={handleClickCloseDecisionForm}>{strings.close}</Button>
                                                    <div className={classes.wrapper}>
                                                        <CustomButton disabled={isDisabled} selectedChildrenRegistration={selectedChildrenRegistration} add={true}>{strings.add}</CustomButton>
                                                        {loading && (
                                                            <CircularProgress
                                                                size={24}
                                                                className={classes.buttonProgress}
                                                            />
                                                        )}
                                                    </div>
                                                </DialogActions>

                                            </>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </React.Fragment>
                    </DialogContent>
                </Dialog>
                {(openEditDecisionForm && loadingDecision) ?
                    <div style={{ display: 'flex', justifyContent: 'center', position: 'fixed', zIndex: "9999", top: "70px", left: 0, width: '100%', height: '100%' }}>
                        <CircularProgress />
                    </div> :
                    <Dialog open={openEditDecisionForm} onClose={handleClickCloseEditDecisionForm} maxWidth={"xl"} fullWidth>
                        <DialogTitle>{strings.editDecision}</DialogTitle>
                        <DialogContent>
                            <br />
                            <React.Fragment>
                                <Formik
                                    initialValues={{
                                        decisionTypeMvrKey: decisionData?.decisionType ? decisionData?.decisionType?.mvrKey : "",
                                        decisionDate: decisionData?.decisionDate ? decisionData.decisionDate : "",
                                        identificationDocumentTypeMvrKey: decisionData?.identificationDocumentTypeDTO?.mvrKey ? decisionData.identificationDocumentTypeDTO.mvrKey : "",
                                        idCardNumber: decisionData?.idCardNumber ? decisionData?.idCardNumber : "",
                                        expirationDate: decisionData?.expirationDate ? decisionData.expirationDate : "",
                                        documentExtensionDate: decisionData?.documentExtensionDate ? decisionData.documentExtensionDate : "",
                                    }}
                                    validationSchema={ValidationSchema}
                                    onSubmit={handleSubmit}
                                    validateOnChange={true}
                                    enableReinitialize={true}
                                >
                                    {({ errors, values }) => {
                                        return (
                                            <Form>
                                                <>
                                                    <Grid container rowSpacing={1}
                                                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                        <Grid item xs={12} sm={4} md={6} marginTop={5}>
                                                            <SelectField className="pb-3"
                                                                name="decisionTypeMvrKey"
                                                                label={strings.decisionTypeMvrKeyMandatoryField}
                                                                data={decisionTypes}
                                                                read={props.hasAuthority ? false : true}
                                                                onChange={(event) => {
                                                                    const decisionTypeValue = event.target.value;
                                                                    if (decisionTypeValue === null) {
                                                                        setSelectedChildrenRegistrations([]);
                                                                    }
                                                                }}
                                                            />
                                                            <DatePickerField
                                                                name="decisionDate"
                                                                label={strings.decisionDateMandatoryField}
                                                                className="pb-3"
                                                                read={props.hasAuthority ? false : true}
                                                            />
                                                            <SelectField className="pb-3"
                                                                name="identificationDocumentTypeMvrKey"
                                                                label={strings.documentType}
                                                                data={identificationDocuments}
                                                                read={props.hasAuthority ? false : true}
                                                            />
                                                            <InputField name="idCardNumber" label={strings.numberID} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
                                                            <DatePickerField
                                                                name="expirationDate"
                                                                label={strings.dateExpired}
                                                                className="pb-3"
                                                                read={props.hasAuthority ? false : true}
                                                            />
                                                            <DatePickerField
                                                                name="documentExtensionDate"
                                                                label={strings.documentExtensionDate}
                                                                className="pb-3"
                                                                read={props.hasAuthority ? false : true}
                                                            />
                                                            <br />
                                                            {values.decisionTypeMvrKey && <Box>
                                                                <div>
                                                                    <PageTable
                                                                        headerRows={registrationRows}
                                                                        rows={registrationValues}
                                                                        withoutPagination={true}
                                                                        clickable={false} />
                                                                </div>
                                                            </Box>}
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={6}>
                                                            <Box>
                                                                <h5>{strings.appeals}</h5>
                                                                <div>
                                                                    <PageTable
                                                                        minimalOptions={true}
                                                                        headerRows={appealsRows}
                                                                        rows={appealRows}
                                                                        page={appealsPage}
                                                                        rowsPerPage={appealsPerPage}
                                                                        totalElements={totalAppealsForDecision}
                                                                        handleChangePage={handleChangeAppealsPage}
                                                                        handleChangeRowsPerPage={handleChangeAppealsPerPage}
                                                                        handleOpen={handleOpenAppeal}
                                                                        clickable={true} />
                                                                </div><br />
                                                                <Box component="div" justifyContent={"end"} display={"flex"}>
                                                                    {props.hasAuthority && <Button style={{ marginLeft: "10px" }} variant="contained" onClick={handleClickOpenAppealForm}>{strings.createAppeal}</Button>}
                                                                </Box>
                                                            </Box>
                                                            <br />
                                                            <Box>
                                                                <h5>{strings.complaints}</h5>
                                                                <div>
                                                                    <PageTable
                                                                        minimalOptions={true}
                                                                        headerRows={complaintsRows}
                                                                        rows={complaintRows}
                                                                        page={complaintsPage}
                                                                        rowsPerPage={complaintsPerPage}
                                                                        totalElements={totalComplaintsForDecision}
                                                                        handleChangePage={handleChangeComplaintsPage}
                                                                        handleChangeRowsPerPage={handleChangeComplaintsPerPage}
                                                                        handleOpen={handleOpenComplaint}
                                                                        clickable={true} />
                                                                </div><br />
                                                                <Box component="div" justifyContent={"end"} display={"flex"}>
                                                                    {props.hasAuthority && <Button style={{ marginLeft: "10px" }} variant="contained" onClick={handleClickOpenComplaintForm}>{strings.createComplaint}</Button>}
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    <br /><br />
                                                    <DialogActions>
                                                        <Button onClick={handleClickCloseEditDecisionForm}>{strings.close}</Button>
                                                        {props.hasAuthority &&
                                                            <div className={classes.wrapper}>
                                                                <CustomButton disabled={isDisabled} selectedChildrenRegistration={selectedChildrenRegistration}>{strings.edit}</CustomButton>
                                                                {loading && (
                                                                    <CircularProgress
                                                                        size={24}
                                                                        className={classes.buttonProgress}
                                                                    />
                                                                )}
                                                            </div>}
                                                    </DialogActions>

                                                </>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </React.Fragment>
                        </DialogContent>
                    </Dialog>}
                <Dialog open={openComplaintForm} onClose={handleClickCloseComplaintForm} maxWidth="md" fullWidth>
                    <DialogTitle>{strings.createComplaint}</DialogTitle>
                    <DialogContent>
                        <br />
                        <React.Fragment>
                            <Formik
                                initialValues={{
                                    decisionId: decisionIdTemporary,
                                    complaintNumber: '',
                                    complaintDate: '',
                                    complaintDecisionMvrKey: '',
                                }}
                                onSubmit={handleSubmitComplaint}
                                validateOnChange={true}
                                enableReinitialize={true}
                            >
                                {({ errors }) => {
                                    return (
                                        <Form>
                                            <>
                                                <Grid container rowSpacing={1}
                                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <InputField name="complaintNumber" label={strings.complaintNumber} fullWidth className="pb-3" />
                                                        <DatePickerField
                                                            name="complaintDate"
                                                            label={strings.complaintDate}
                                                            className="pb-3"
                                                        />
                                                        <SelectField className="pb-3"
                                                            name="complaintDecisionMvrKey"
                                                            label={strings.complaintDecision}
                                                            data={complaintDecisions}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <DialogActions>
                                                    <Button onClick={handleClickCloseComplaintForm}>{strings.close}</Button>
                                                    <div className={classes.wrapper}>
                                                        <Button disabled={isDisabledComplaint} type="submit" color="primary" variant="contained">{strings.add}</Button>
                                                        {loadingComplaint && (
                                                            <CircularProgress
                                                                size={24}
                                                                className={classes.buttonProgress}
                                                            />
                                                        )}
                                                    </div>
                                                </DialogActions>

                                            </>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </React.Fragment>
                    </DialogContent>
                </Dialog>
                {(openEditComplaintForm && loadingDecisionComplaint) ?
                    <div style={{ display: 'flex', justifyContent: 'center', position: 'fixed', zIndex: "9999", top: "70px", left: 0, width: '100%', height: '100%' }}>
                        <CircularProgress />
                    </div> :
                    <Dialog open={openEditComplaintForm} onClose={handleClickCloseEditComplaintForm} maxWidth="md" fullWidth>
                        <DialogTitle>{strings.editComplaint}</DialogTitle>
                        <DialogContent>
                            <br />
                            <React.Fragment>
                                <Formik
                                    initialValues={{
                                        decisionId: decisionIdTemporary,
                                        complaintNumber: complaintData?.complaintNumber ? complaintData.complaintNumber : "",
                                        complaintDate: complaintData?.complaintDate ? complaintData.complaintDate : "",
                                        complaintDecisionMvrKey: complaintData?.complaintDecision ? complaintData?.complaintDecision?.mvrKey : "",
                                    }}
                                    onSubmit={handleEditComplaint}
                                    validateOnChange={true}
                                    enableReinitialize={true}
                                >
                                    {({ errors }) => {
                                        return (
                                            <Form>
                                                <>
                                                    <Grid container rowSpacing={1}
                                                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <InputField name="complaintNumber" label={strings.complaintNumber} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
                                                            <DatePickerField
                                                                name="complaintDate"
                                                                label={strings.complaintDate}
                                                                className="pb-3"
                                                                read={props.hasAuthority ? false : true}
                                                            />
                                                            <SelectField className="pb-3"
                                                                name="complaintDecisionMvrKey"
                                                                label={strings.complaintDate}
                                                                data={complaintDecisions}
                                                                read={props.hasAuthority ? false : true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <DialogActions>
                                                        <Button onClick={handleClickCloseEditComplaintForm}>{strings.close}</Button>
                                                        {props.hasAuthority &&
                                                            <div className={classes.wrapper}>
                                                                <Button disabled={isDisabledComplaintEdit} type="submit" color="primary" variant="contained">{strings.edit}</Button>
                                                                {loadingComplaintEdit && (
                                                                    <CircularProgress
                                                                        size={24}
                                                                        className={classes.buttonProgress}
                                                                    />
                                                                )}
                                                            </div>}
                                                    </DialogActions>

                                                </>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </React.Fragment>
                        </DialogContent>
                    </Dialog>}
                <Dialog open={openAppealForm} onClose={handleClickCloseAppealForm} maxWidth="md" fullWidth>
                    <DialogTitle>{strings.createAppeal}</DialogTitle>
                    <DialogContent>
                        <br />
                        <React.Fragment>
                            <Formik
                                initialValues={{
                                    decisionId: decisionIdTemporary,
                                    appealNumber: '',
                                    appealDate: '',
                                    appealDecisionMvrKey: '',
                                }}
                                onSubmit={handleSubmitAppeal}
                                validateOnChange={true}
                                enableReinitialize={true}
                            >
                                {({ errors }) => {
                                    return (
                                        <Form>
                                            <>
                                                <Grid container rowSpacing={1}
                                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <InputField name="appealNumber" label={strings.appealNumber} fullWidth className="pb-3" />
                                                        <DatePickerField
                                                            name="appealDate"
                                                            label={strings.appealDate}
                                                            className="pb-3"
                                                        />
                                                        <SelectField className="pb-3"
                                                            name="appealDecisionMvrKey"
                                                            label={strings.appealDecision}
                                                            data={appealDecisions}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <DialogActions>
                                                    <Button onClick={handleClickCloseAppealForm}>{strings.close}</Button>
                                                    <div className={classes.wrapper}>
                                                        <Button disabled={isDisabledAppeal} type="submit" color="primary" variant="contained">{strings.add}</Button>
                                                        {loadingAppeal && (
                                                            <CircularProgress
                                                                size={24}
                                                                className={classes.buttonProgress}
                                                            />
                                                        )}
                                                    </div>
                                                </DialogActions>
                                            </>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </React.Fragment>
                    </DialogContent>
                </Dialog>
                {(openEditAppealForm && loadingDecisionAppeal) ?
                    <div style={{ display: 'flex', justifyContent: 'center', position: 'fixed', zIndex: "9999", top: "70px", left: 0, width: '100%', height: '100%' }}>
                        <CircularProgress />
                    </div> :
                    <Dialog open={openEditAppealForm} onClose={handleClickCloseEditAppealForm} maxWidth="md" fullWidth>
                        <DialogTitle>{strings.editAppeal}</DialogTitle>
                        <DialogContent>
                            <br />
                            <React.Fragment>
                                <Formik
                                    initialValues={{
                                        decisionId: decisionIdTemporary,
                                        appealNumber: appealData?.appealNumber ? appealData.appealNumber : "",
                                        appealDate: appealData?.appealDate ? appealData.appealDate : "",
                                        appealDecisionMvrKey: appealData?.appealDecision ? appealData?.appealDecision?.mvrKey : "",
                                    }}
                                    onSubmit={handleEditAppeal}
                                    validateOnChange={true}
                                    enableReinitialize={true}
                                >
                                    {({ errors }) => {
                                        return (
                                            <Form>
                                                <>
                                                    <Grid container rowSpacing={1}
                                                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <InputField name="appealNumber" label={strings.appealNumber} fullWidth className="pb-3" readOnly={props.hasAuthority ? false : true} />
                                                            <DatePickerField
                                                                name="appealDate"
                                                                label={strings.appealDate}
                                                                className="pb-3"
                                                                read={props.hasAuthority ? false : true}
                                                            />
                                                            <SelectField className="pb-3"
                                                                name="appealDecisionMvrKey"
                                                                label={strings.appealDecision}
                                                                data={appealDecisions}
                                                                read={props.hasAuthority ? false : true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <DialogActions>
                                                        <Button onClick={handleClickCloseEditAppealForm}>{strings.close}</Button>
                                                        {props.hasAuthority &&
                                                            <div className={classes.wrapper}>
                                                                <Button disabled={isDisabledAppealEdit} type="submit" color="primary" variant="contained">{strings.edit}</Button>
                                                                {loadingAppealEdit && (
                                                                    <CircularProgress
                                                                        size={24}
                                                                        className={classes.buttonProgress}
                                                                    />
                                                                )}
                                                            </div>}
                                                    </DialogActions>

                                                </>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </React.Fragment>
                        </DialogContent>
                    </Dialog>}
            </Drawer>
        </>
    )
}

export default RightSidePanel;