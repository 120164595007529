export class ProcedureTypeDTO{
    id:number;
    mvrKey:string | null;
    name:string | null;
    nameSq:string | null;
    nameEn:string | null;
    displayName: string | null;
    constructor(json:ProcedureTypeDTO){
        this.id = json.id
        this.mvrKey = json.mvrKey;
        this.name = json.name;
        this.nameSq = json.nameSq;
        this.nameEn = json.nameEn;
        this.displayName = json.displayName;
    }
}