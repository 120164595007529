import { GenderDTO } from "./GenderDTO";
import { CitizenshipCreationDTO } from "./foreign_citizens/CitizenshipCreationDTO";
import {CountryCreationDTO} from "./foreign_citizens/CountryCreationDTO";
import { PlaceCreationDTO } from "./foreign_citizens/PlaceCreationDTO";

export class PersonDataReducedDTO{
    existingMacEmbg?:string
    firstName : string;
    parentName: string;
    lastName : string;
    gender: GenderDTO;
    birthDate: Date;
    birthCountryDTO?: CountryCreationDTO;
    firstNameCyrillic?:string;
    lastNameCyrillic?:string;
    parentNameCyrillic?:string;
    birthPlaceDTO? : PlaceCreationDTO;
    citizenshipDTO?: CitizenshipCreationDTO;

    constructor(json:PersonDataReducedDTO) {
        this.existingMacEmbg = json.existingMacEmbg;
        this.firstName = json.firstName;
        this.parentName = json.parentName;
        this.lastName = json.lastName;
        this.gender = json.gender;
        this.birthDate = new Date(json.birthDate);
        this.birthCountryDTO = json.birthCountryDTO ? new CountryCreationDTO(json.birthCountryDTO) : undefined;
        this.firstNameCyrillic = json.firstNameCyrillic;
        this.lastNameCyrillic = json.lastNameCyrillic;
        this.parentNameCyrillic = json.parentNameCyrillic;
        this.birthPlaceDTO = json.birthPlaceDTO ? new PlaceCreationDTO(json.birthPlaceDTO) : undefined;
        this.citizenshipDTO = json.citizenshipDTO ? new CitizenshipCreationDTO(json.citizenshipDTO) : undefined;

    }
}
