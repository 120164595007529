import Swal from "sweetalert2";
import {strings} from "../localization/Localization";
import '../css/global.css'

export function showErrorDialog(title: string, text: string, confirmButtonText: string | undefined | null): Promise<any> {
    return Swal.fire({
        title: title,
        text: text,
        confirmButtonText: confirmButtonText ?? strings.ok,
        icon: "error",
    });
}

export function showSuccessDialog(title: string, text: string, confirmButtonText: string | undefined | null): Promise<any> {
    return Swal.fire({
        title: title,
        text: text,
        icon: "success",
        confirmButtonText: confirmButtonText ?? strings.ok,
        
    });
}
