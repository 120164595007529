export default {
    formId: "form",
    formField: {
      haveChildren:{
        id:"110",
        name:"haveChildren",
        label:"Има/нема деца",
        placeholder:"Има/нема деца",
      },
      archiveNumber:{
        id:"111",
        name:"archiveNumber",
        label:"Архивски број",
        placeholder:"Архивски број",
        requiredErrorMsg: "Полето е задолжително"
      },
      dossierNumber:{
        id:"112",
        name:"dossierNumber",
        label:"Досие",
        placeholder:"Досие*",
        requiredErrorMsg: "Полето е задолжително"
      },
      archiveDate:{
        id:"113",
        name:"archiveDate",
        label:"Архивски датум",
        placeholder:"Архивски датум",
        requiredErrorMsg: "Полето е задолжително"
      },
      about:{
        id:"114",
        name:"about",
        label:"Барање за азил",
        placeholder:"Барање за азил",
        requiredErrorMsg: "Полето е задолжително"
      },
      embg:{
        id:"115",
        name:"embg",
        label:"Матичен број",
        placeholder:"Матичен број",
        requiredErrorMsg: "Полето е задолжително"
      },
        existingMacEmbg:{
            id:"115",
            name:"existingMacEmbg",
            label:"Македонски матичен број (доколку има таков)",
            placeholder:"Македонски матичен број (доколку има таков)",
            requiredErrorMsg: "Полето е задолжително"
        },
      placeOfSubbmision:{
        id:"116",
        name:"placeOfSubmission",
        label:"Место на поднесување на барањето",
        placeholder:"Место на поднесување на барањето",
      },
      nameCyrillic:{
        id:"117",
        name:"nameCyrillic",
        label:"Име (кирилица)",
        placeholder:"Име (кирилица)*",
        requiredErrorMsg: "Полето е задолжително"
      },
      surnameCyrillic:{
        id:"118",
        name:"surnameCyrillic",
        label:"Презиме (кирилица)",
        placeholder:"Презиме (кирилица)*",
        requiredErrorMsg: "Полето е задолжително"
      },
      fathersNameCyrillic:{
        id:"119",
        name:"fathersNameCyrillic",
        label:"Име и презиме на таткото (кирилица)",
        placeholder:"Име и презиме на таткото (кирилица)",
      },
     mothersNameCyrillic:{
        id:"119",
        name:"mothersNameCyrillic",
        label:"Име и презиме на мајката (кирилица)",
        placeholder:"Име и презиме на мајката (кирилица)",
      },
      name: {
        id:"1",
        name: "name",
        label: "Име",
        placeholder:"Име*",
        requiredErrorMsg: "Името е задолжително"
      },
      lastName: {
        id:"2",
        name: "lastName",
        label: "Презиме",
        placeholder: "Презиме*",
        requiredErrorMsg: "Презимето е задолжително",
      },
      maidenName: {
        id:"3",
        name: "maidenName",
        label: "Презиме пред склучување брак",
        placeholder:"Презиме пред склучување брак"
      },
      otherNameOrNickName: {
        id:"4",
        name: "otherNameOrNickName",
        label: "Друго име/прекар",
        placeholder:"Друго име/прекар",
      },
      fathersName: {
        id:"5",
        name: "fathersName",
        label: "Име на таткото",
        placeholder:"Име на таткото",
      },
      fathersLastName: {
        id:"555",
        name: "fathersLastName",
        label: "Презиме на таткото",
        placeholder:"Презиме на таткото",
      },
      mothersName: {
        id:"6",
        name: "mothersName",
        label: "Име на мајката",
        placeholder:"Име на мајката",
      },
      mothersLastName: {
        id:"613",
        name: "mothersLastName",
        label: "Презиме на мајката",
        placeholder:"Презиме на мајката",
      },
      birthDate: {
        id:"7",
        name: "birthDate",
        label: "Датум на раѓање*",
        placeholder:"Датум на раѓање*",
        requiredErrorMsg: "Датумот на раѓање е задолжителен",
      },
      countryAndCityOfBirth: {
        id:"8",
        name: "countryAndCityOfBirth",
        label: "Држава/Град на раѓање*",
      },
      countryOfBirth: {
        id:"8.1",
        name: "countryOfBirth",
        label: "Држава на раѓање*",
        placeholder:"Држава на раѓање*",
        requiredErrorMsg:"Задолжително внесете држава на раѓање",
      },
      placeOfBirth: {
        id:"8.2",
        name: "placeOfBirth",
        label: "Место на раѓање",
        placeholder:"Место на раѓање",
        requiredErrorMsg: "Задолжително внесете место на раѓање",
      },
        inRSMBirthMunicipality: {
            id:"8.3",
            name: "inRSMBirthMunicipality",
            label: "Општина на раѓање во РСМ",
            placeholder:"Општина на раѓање во РСМ*",
            requiredErrorMsg: "Задолжително внесете општина на раѓање",
        },
        inRSMBirthPlacePlace: {
            id:"8.4",
            name: "inRSMBirthPlacePlace",
            label: "Место на раѓање во РСМ",
            placeholder:"Место на раѓање во РСМ*",
            requiredErrorMsg: "Задолжително внесете место на раѓање",
        },
      gender: {
        id:"9",
        name: "gender",
        label: "Пол*",
        placeholder:"Пол*",
        requiredErrorMsg: "Полето е задолжително",
      },
      citizenShip: {
        id:"10",
        name: "citizenShip",
        label: "Државјанство*",
        placeholder:"Државјанство*",
        requiredErrorMsg: "Полето е задолжително"
      },
      ethnicOrigin:{
        id:"11",
        name: "ethnicOrigin",
        label: "Етничка припадност",
        placeholder:"Етничка припадност"
      },
      religion:{
        id:"12",
        name: "religion",
        label: "Религија",
        placeholder:"Религија"
      },
      maritalStatus: {
        id:"13",
        name: "maritalStatus",
        label: "Брачна состојба",
        placeholder:"Брачна состојба",
      },
      surnameAndNameOfTheSpouse:{
        id:"14",
        name:"surnameAndNameOfTheSpouse",
        label:"Име и презиме на брачниот другар",
        placeholder:"Име и презиме на брачниот другар"
      },
      motherTongueAndOtherLanguages:{
        id:"15",
        name:"motherTongueAndOtherLanguages",
        label:"Мајчин јазик и други јазици кои го зборува",
        placeholder:"Мајчин јазик и други јазици кои го зборува"
      },
      identityDocuments:{
        id:"16",
        name:"identityDocuments",
        label:"Документ за идентификација"
      },
          nationalPassport:{
            id:"16.1",
            name:"nationalPassport",
            label:"Национален пасош",
            placeholder:"Национален пасош"
          },
          identificationDocument:{
            id:"16.2",
            name:"identificationDocument",
            label:"Лична карта",
            placeholder:"Лична карта"
          },
          drivingLicense:{
            id:"16.3",
            name:"drivingLicense",
            label:"Возачка дозвола",
            placeholder:"Возачка дозвола"
          },
        others:{
          id:"16.4",
          name:"others",
          label:"Друго",
          placeholder:"Друго"
        },
        noneOfAbove:{
          id:"16.5",
          name:"noneOfAbove",
          label:"Доколку нема документ за идентификација, зошто и каде се наоѓаат: ",
          placeholder:"Доколку нема документ за идентификација, зошто и каде се наоѓаат: "
        },
      education: {
        id:"17",
        name: "education",
        label: "Стручна спрема/образование",
        placeholder:"Стручна спрема/образование",
      },
          nameOfTheInstitution:{
            id:"17.1",
            name: "nameOfTheInstitution",
            label: "Назив на образовната установа",
            placeholder:"Назив на образовната установа",
            requiredErrorMsg: "Полето е задолжително",
          },
          placeWhereCompleted:{
            id:"17.2",
            name: "placeWhereCompleted",
            label: "Град/Место каде е завршено образованието",
            placeholder:"Град/Место каде е завршено образованието",
            requiredErrorMsg: "Полето е задолжително",
          },
          yearOfCompletion:{
            id:"17.3",
            name: "yearOfCompletion",
            label: "Година на завршување на образованието",
            placeholder:"Година на завршување на образованието",
          },
          employement: {
            id:"18",
            name: "employement",
            label: "Вработувања",
          },
          employementList:[
            {
              from:"",
              to:"",
              institution:"",
              place:"",
              state:""
            }
          ],
        addressInTheCountryOfOrigin: {
          id:"19",
          name: "addressInTheCountryOfOrigin",
          label: "Адреса во земјата на потекло и надвор од неа во последните 10 години",
         },
         livingPlacesList:[{
          fromAddr:"",
          toAddr:"",
          countryOrigin:"",
          city:"",
          streetAddr:"",
          streetNumberAddr:""
         }],
              // fromAddr:{
              //   id:"19.1",
              //   name: "from",
              //   label: "Од",
              //   placeholder:"Од*",
              //   requiredErrorMsg: "Полето е задолжително",
              // },
              // toAddr:{
              //   id:"19.2",
              //   name: "to",
              //   label: "До",
              //   placeholder:"До",
              // },
              // countryOrigin:{
              //   id:"19.3",
              //   name: "countryOrigin",
              //   label: "Држава",
              //   placeholder:"Држава",
              // },
              // cityOrStateAddr:{
              //   id:"19.4",
              //   name:"cityOrState",
              //   label:"Град",
              //   placeholder:"Град"
              // },
              // streetAndNumber:{
              //   id:"19.5",
              //   name:"streetAndNumber",
              //   label:"Улица",
              //   placeholder:"Улица"
              // },
              // streetNumberOrigin:{
              //   id:"19.5",
              //   name:"streetNumber",
              //   label:"Број на улица",
              //   placeholder:"Број на улица"
              // },

        //регион 2
        currentAddressOfPlace: {
          id:"20",
          name: "currentAddressOfPlace",
          label: "Сегашна адреса на престојување во Република Северна Македонија",
         },
         typeOfAccomodation:{
          id:"20.1",
          name:"typeOfAccomodation",
          label:"Тип на престој",
          placeholder:"Тип на престој*",
          requiredErrorMsg: "Полето е задолжително",
         },
              placeAccomodation:{
                id:"20.2",
                name: "placeAccomodation",
                label: "Населено место",
                placeholder:"Населено место*",
                requiredErrorMsg: "Полето е задолжително"
              },
              municipalityAccomodation:{
                id:"20.3",
                name: "municipalityAccomodation",
                label: "Општина",
                placeholder:"Општина*",
                requiredErrorMsg: "Полето е задолжително"
              },
                  street:{
                    id:"20.1.1",
                    name:"street",
                    label:"Улица",
                    placeholder:"Улица*",
                    requiredErrorMsg: "Полето е задолжително"
                  },
                  streetNumber:{
                    id:"20.1.2",
                    name:"streetNumber",
                    label:"Број",
                    placeholder:"Број*",
                    requiredErrorMsg: "Полето е задолжително"
                  },
                  addressDateFrom:{
                    id:"20.1.3",
                    name:"addressDateFrom",
                    label:"Датум од",
                    placeholder:"Датум од*",
                    requiredErrorMsg: "Полето е задолжително"
                  },
                  phoneNumber:{
                    id:"20.1.4",
                    name:"phoneNumber",
                    label:"Телефонски број",
                    placeholder:"Телефонски број"
                  },
                collectiveCenterTitle:{
                  id:"20.4",
                  name:"collectiveCenter",
                  label:"Колективен/прифатен центар",
                },
                    collectiveCenter:{
                      id:"20.3.1",
                      name:"collectiveCenter",
                      label:"Колективен/прифатен центар",
                      placeholder:"Колективен/прифатен центар"
                    },
                    otherAddr:{
                      id:"20.5",
                      name:"otherAddr",
                      label:"Друго",
                      placeholder:"Друго"
                    },
            relativesInNorthMacedonia: {
              id:"21",
              name: "relativesInNorthMacedonia",
              label: "Роднини во Република Северна Македонија",
              },
              relativesList:[{
                embg:"",
                relativeName:"",
                relativeLastName:"",
                relativeRelationshipWithApplicant:"",
                municipalityRelatives:"",
                placeRelativesInNorthMacedonia:"",
                relativeAddressOfLiving:"",
                relativeCitizenship:"",
              }],
              requiredMessage:"Задолжително поле",
          militaryService:{
            id:"23",
            name:"militaryService",
            label:"Воена служба",
          },
              yearOfService:{
                id:"23.1",
                name:"yearOfService",
                label:"Година на служење",
                placeholder:"Година на служење"
              },
              sectionOfService:{
                id:"23.2",
                name:"sectionOfService",
                label:"Род",
                placeholder:"Род"
              },
              placeOfServing:{
                id:"23.3",
                name:"placeOfServing",
                label:"Место на служење",
                placeholder:"Место на служење"
              },
            politicalActivity:{
              id:"23",
              name:"politicalActivity",
              label:"Политичка активност",
            },
                  politicalParty:{
                    id:"23.1",
                    name:"politicalParty",
                    label:"Политичка партија",
                    placeholder:"Политичка партија"
                  },
                  politicalFunction:{
                    id:"23.2",
                    name:"politicalFunction",
                    label:"Функција",
                    placeholder:"Функција"
                  },
                  otherPolitical:{
                    id:"23.3",
                    name:"otherPolitical",
                    label:"Друго",
                    placeholder:"Друго"
                  },
          familyMembersAcompany: {
            id:"24",
            name: "familyMembersAcompany",
            label: "Членови на семејстовото кои го придружуваат барателот на право на азил",
            },
            familyMembersAcompanyList:[{
              memberEmbg:"",
              memberExistingMacEmbg:"",
              id:"",
              memberSurname:"",
              memberName:"",
              memberDateOfBirth:"",
              memberStateOfBirth:"",
              memberPlaceOfBirth:"",
              memberInRSMMunicipality: "",
              memberInRSMPlace: "",
              memberCitizenShip:"",
              relationshipsOrSex:"",
              memberNameCyrillic:"",
              memberSurnameCyrillic: ""
            }],
                // memberSurname:{
                //   id:"24.1",
                //   name: "memberSurname",
                //   label: "Презиме",
                //   placeholder:"Презиме",
                // },
                //     memberName:{
                //       id:"24.2",
                //       name: "MemeberName",
                //       label: "Име",
                //       placeholder:"Име",
                //     },
                //     memberDateOfBirth:{
                //       id:"24.3",
                //       name:"memberDateOfBirth",
                //       label:"Датум на раѓање",
                //       placeholder:"Датум на раѓање"
                //     },
                //     memberPlaceOfBirth:{
                //       id:"24.4",
                //       name:"memberPlaceOfBirth",
                //       label:"Држава на раѓање",
                //       placeholder:"Држава на раѓање"
                //     },
                //     relationshipsOrSex:{
                //       id:"24.5",
                //       name:"relationshipsOrSex",
                //       label:"Пол",
                //       placeholder:"Пол"
                //     },
                  familyMembersInTheCountryOfOrigin: {
                    id:"25",
                    name: "familyMembersInTheCountryOfOrigin",
                    label: "Членови на семејстовото кои живеат во матичната држава или во странство",
                    },
                    familyMembersOriginCountryOrAbroadList:[{
                      memberSurnameOG:"",
                      memberNameOG:"",
                      memberDateOfBirthOG:"",
                      memberStateOfBirthOG:"",
                      memberPlaceOfBirthOG:"",
                      memberCitizenShipOG:"",
                      relationshipsOrSexOG:"",
        
                    }],
                          // memberSurnameOG:{
                          //   id:"25.1",
                          //   name: "memberSurname",
                          //   label: "Презиме",
                          //   placeholder:"Презиме",
                          // },
                          //     memberNameOG:{
                          //       id:"25.2",
                          //       name: "MemeberName",
                          //       label: "Име",
                          //       placeholder:"Име",
                          //     },
                          //     memberDateOfBirthOG:{
                          //       id:"25.3",
                          //       name:"memberDateOfBirth",
                          //       label:"Датум на раѓање",
                          //       placeholder:"Датум на раѓање"
                          //     },
                          //     memberPlaceOfBirthOG:{
                          //       id:"25.4",
                          //       name:"memberPlaceOfBirth",
                          //       label:"Држава во која живеат",
                          //       placeholder:"Држава во која живеат"
                          //     },
                          //     relationshipsOrSexOG:{
                          //       id:"25.5",
                          //       name:"relationshipsOrSex",
                          //       label:"Пол",
                          //       placeholder:"Пол"
                          //     },
                      appliedForTheRightToAsylumInRSM: {
                        id:"26",
                        name: "appliedForTheRightToAsylumInRSM",
                        label: "Дали барателот на право на азил претходно има поднесено барање на признавање на азил во Република Северна Македонија?",
                        },
                                dateOfSubbmission:{
                                  id:"26.1",
                                  name: "dateOfSubbmission",
                                  label: "Датум на поднесување",
                                  placeholder:"Датум на поднесување",
                                },
                                decision:{
                                  id:"26.2",
                                  name:"decision",
                                  label:"Вид на одлука",
                                  placeholder:"Вид на одлука"
                                },
                        appliedForTheRightToAsylumInOtherCountry: {
                          id:"27",
                          name: "appliedForTheRightToAsylumInOtherCountry",
                          label: "Дали барателот на право на азил претходно има поднесено барање на признавање на азил во странство?",
                          },
                                countryWhereYouApplied:{
                                    id:"27.1",
                                    name: "countryWhereYouApplied",
                                    label: "Држава",
                                    placeholder:"Држава",
                                  },
                                  decisionOtherCountry:{
                                    id:"27.2",
                                    name:"decisionOtherCountry",
                                    label:"Вид на одлука",
                                    placeholder:"Вид на одлука"
                                  },
                    mannerOfArival:{
                      id:"28",
                      name:"mannerOfArival",
                      label:"Податоци за начин на доаѓање во Република Северна Македонија",
                    },
                        dateOfArrival:{
                          id:"28.1",
                          name:"dateOfArrival",
                          label:"Датум на доаѓање",
                          placeholder:"Датум на доаѓање"
                        },
                        manner:{
                          id:"28.2",
                          name:"manner",
                          label:"Начин",
                          placeholder:"Начин"
                        },
                        borderPoint:{
                          id:"28.3",
                          name:"borderPoint",
                          label:"Граничен премин",
                          placeholder:"Граничен премин"
                        },
                        placeWhereYouEntered:{
                          id:"28.4",
                          name:"placeWhereYouEntered",
                          label:"Место на влез",
                          placeholder:"Место на влез"
                        },
                        transportationMeans:{
                          id:"28.5",
                          name:"transportationMeans",
                          label:"Превозно средство",
                          placeholder:"Превозно средство"
                        },
                      residenceStatusPriorToSubmissionOfTheApplication:{
                        id:"29",
                        name:"residenceStatusPriorToSubmissionOfTheApplication",
                        label:"Податоци за престојот пред поднесување на барањето",
                      },
                          legal:{
                            id:"29.1",
                            name:"legal",
                            label:"Легален",
                            placeholder:"Легален"
                          },
                          temporary:{
                            id:"29.2",
                            name:"temporary",
                            label:"Привремен",
                            placeholder:"Привремен"
                          },
                          permanent:{
                            id:"29.3",
                            name:"permanent",
                            label:"Постојан",
                            placeholder:"Постојан"
                          },
                          temporaryProtection:{
                            id:"29.4",
                            name:"temporaryProtection",
                            label:"Привремена заштита",
                            placeholder:"Привремена заштита"
                          },
                          otherResidenceStatus:{
                            id:"29.5",
                            name:"otherResidenceStatus",
                            label:"Друго",
                            placeholder:"Друго"
                          },
              briefSummaryAboutTheReasons:{
                id:"30",
                name:"briefSummaryAboutTheReasons",
                label:"Краток исказ на барателот на право на азил за причините за напуштање на земјата на потекло и причините поради кои го поднел барањето за меѓународна заштита во Република Серверна Македонија",
              },
              freeLegalAid:{
                id:"31",
                name:"freeLegalAid",
                label:"Дали барателот на азил е информиран за бесплатна правна помош? ",
              },
              sameGenderOfficial:{
                id:"32",
                name:"sameGenderOfficial",
                label:"Дали барателот на право на азил сака постапката за признавање на право на азил да му ја води службено лице од ист пол?",
              },
              procedureType:{
                id:"33",
                name:"procedureType",
                label:"Вид на постапка",
              },
              idOfIdentification:{
                id:"34",
                name:"idOfIdentification",
                label:"Број на идентификациона исправа",
              },
              expireDate:{
                id:"35",
                name:"expireDate",
                label:"Важност до",
              },
              resultDecision:{
                id:"36",
                name:"resultDecision",
                label:"Вид на одлука",
              },
              completedFormData:{
                id:"37",
                name:"completedFormData",
                label:"Податоци за пополнето барање",
              },
              checksByUBK:{
                id:"37.1",
                name:"checksByUBK",
                label:"Одговор од АНБ",
              },
              dateOfSubmissionForm:{
                id:"37.2",
                name:"dateOfSubmissionForm",
                label:"Датум на пополнување",
              },
            limitedMovementTitle:{
              id:"38",
              name:"limitedMovementTitle",
              label:"Ограничена слобода на движење",
            },
            limitedMovementList:[{
              proposer:"",
              agentMvr:"",
              placeOfLimitedMovement:"",
              dateOfLimitedMovement:"",
              dateOfExtension:"",
              termOfLimitedMovement:"",
              startDateOfLimitedMovement:"",
              terminationBeforeTerm:""
            }]
            // proposer:{
            //   id:"38",
            //   name:"proposer",
            //   label:"Предлагач",
            // },
            // placeOfLimitedMovement:{
            //   id:"39",
            //   name:"placeOfLimitedMovement",
            //   label:"Место на ограничена слобода на движење",
            // },
            // dateToLimitedMovement:{
            //   id:"40",
            //   name:"dateToLimitedMovement",
            //   label:"Датум до кој е ограничено движењето",
            // },
            // dateOfExtension:{
            //   id:"41",
            //   name:"dateOfExtension",
            //   label:"Датум на продолжување",
            // },
          }
   };
