export const domainMessages_mk = {
    app: {
        "projectName": "Search Admin",
        "version": "v 1.0",
        "title": "Search Admin",
        inactivity: {
            title: "Неактивност",
            body:
                "Корисникот е автоматски одјавен од системот поради континуирана неактивност од 15 мин, т.е. временски интервал кој е дефиниран од страна на Агенција за Заштита на Лични Податоци. ",
            login: "Логирај се",
        },
        unauthorized: {
            title: "Неовластено",
            body: "Немате привилегии за пристап",
            logout: "Врати се на портал",
            addGroupScope: "Додадете група на опсег (group scope) за клиентот",
            addRolesToUser: "Додадете барем една улога за вашата корисничка сметка"
        },
        welcome:{
            welcomeMessage: "Добредојдовте на ",
            applicationName: "Demo Апликација за тестирање на функционалности",
            mvr: "Министерство за внатрешни работи"
        },
        appBar:{
            clientApplicationName: "Апликација за пребарување",
        },
        drawer: {            
            profilling: "Тест UM API",
            profileManagement: "Улоги во User Management",
            rolesManagement: "Улоги за апликација",
            reportManagement: "Тест Database CRUD",
            reportReasons: "Причина"
        },
        profile: "Профил",
        signOut: "Одјави се",
        docs: "Упатство",
        generic: {
            form: {
                required: "Полето е задолжително",
            },
            create: "Креирај",
            details: "Детали",
            edit: "Измени",
            delete: "Избриши",
            loading: "Се вчитува...",
            cancel: "Откажи",
            save: "Зачувај",
            testSQL: "Тест SQL",
            testRest: "Тест (Rest Endpoint)",
            noItems: "Не се пронајдени записи!",
            addNew: "Додади нов",
            add: "Додади",
            deleteWarning: "Дали сте сигурни дека сакате да го избришете овој запис?",
            ok: "ОК",
            confirmWarning: "Предупредување",
            editLink: "Креирај/Измени Линк",
            unsavedChanges: "Промените не се зачувани! Дали сте сигурни дека сакате да го напуштите прозорецот?",
            undefined: "Недефиниран",
            notifications: {
                itemCreated: "Записот ${itemName} е успешно зачуван!",
                itemUpdated: "Записот ${itemName} е успешно променет!",
                itemDeleted: "Записот ${itemName} е успешно избришан!",
            },
            table:{
                export: "ЕКСПОРТ",
                exportPassword: "Лозинка",
                rowsPerPage: "Редови по страница:",
                info: "{from}-{to} од {count}",
            }
        },

        roles: {
            role: "Улога",
            roleId: "ID на улога",
            roleName: "Име на улога",
            name: "Назив",
            description: "Опис",
            application: "Апликација",
            noItemsInfoText: "Немате избрано улоги",
            domainName: "Домен-назив",
            applicationName: "Апликација-назив",
            selector: "Улога селектор",
            selectedRoles: "Избрани улоги",
            notifications:{
                successfullyUpdated: "Успешно се зачувани промените во улогата!",
                problemUpdating: "Настана проблем при промена на улогата!",
                fetchError: "Настана проблем при вчитување на улогите!",
                roleAdded: "Улогата е успешно додадена! Зачувајте ја формата за да се зачува промената!",
                roleExists: "Улогата е веќе додадена!",
                roleRemoved: "Улогата е отстранета! Зачувајте ја формата за да се зачува промената!"
            }
        },
        searchReasons: {
            description: "Опис",
            notifications:{
                successfullyCreated: "Успешно е додадена нова причина за пребарување!",
                problemCreating: "Настана проблем при додавање на нова причина за пребарување!",
                successfullyUpdated: "Успешно се зачувани промените во причината за пребарување!",
                problemUpdating: "Настана проблем при промена на причина за пребарување!",
                successfullyDeleted: "Успешно е избришана причината за пребарување!",
                problemDeleting: "Настана проблем при бришење на причината за пребарување!",
                searchReasonExists: "Веќе постои причина за пребарување со ист опис!"
            },
            yup:{
                descriptionAlphanumeric: "Полето (Опис) дозволува само алфанумерички знаци!",
                descriptionRequired: "Полето (Опис) е задолжително!"
            }
        }
    }
}
