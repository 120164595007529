import {
    Box,
    Button, Checkbox, CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {strings} from "../localization/Localization";
import {NullableDatePicker} from "./NullableDatePicker";
import React, {useEffect, useState} from "react";
import {PassivationCreationDTO} from "../models/foreign_citizens/PassivationCreationDTO";
import {Dayjs} from "dayjs";
import {NomenclatureEntryDTO} from "../models/nomenclatures/NomenclatureEntryDTO";
import {NomenclatureService} from "../services/NomenclatureService";
import {NomenclatureConstants} from "../constants/NomenclatureConstants";
import {PassivationService} from "../services/PassivationService";
import {ErrorHandler} from "../utils/ErrorHandler";
import {showErrorDialog, showSuccessDialog} from "../common/Dialogues";
import {useNavigate, useParams} from "react-router-dom";
import {ForeignCitizenService} from "../services/registrations/ForeignCitizenService";
import {Routes} from "../router/Routes";
import {PageTable} from "./table/PageTable";
import {LightTooltip} from "./LightTooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {RegistrationReducedDTO} from "../models/registration/RegistrationReducedDTO";
import CancelIcon from "@mui/icons-material/Cancel";
import {RegistrationDTO} from "../models/foreign_citizens/RegistrationDTO";
import {RegistrationStatuses} from "../constants/RegistrationStatuses";
import {OnHoldCreationDTO} from "../models/OnHoldCreationDTO";
import {PassivationRegistrationCreationDTO} from "../models/PassivationRegistrationCreationDTO";
import useStyles from "../pages/ShortTermStayForm/styles/styles";
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

interface IProps {
    open: boolean,
    handleClose: () => void,
    registrationData?: RegistrationDTO,
    isAsylum?: boolean,
    isForeign?: boolean,
    isDeceased?: boolean
}

export function DeceasedModal({open, handleClose, registrationData, isAsylum, isForeign, isDeceased} : IProps) {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [checked, setChecked] = useState<Array<RegistrationReducedDTO | RegistrationDTO>>([]);
    let {id} = useParams();
    const navigate = useNavigate();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function markAsDeceased(registrationIds : Array<number>){
        setIsLoading(true);
        await PassivationService.markAsDeceased(registrationIds).then(() => {
            handleClose();
            setChecked([]);
            let embgs: any;
            if(!isAsylum){
                embgs = registrationData?.embg
            }else{
                embgs = checked.map((embg: any) => {
                    return embg?.embg;
                })
            }
            showSuccessDialog(strings.successfullyMarkedAsDeceased, strings.successfullyMarkedAsDeceased + " " + embgs, strings.ok).then(_ => {
                setIsLoading(false);
                // window.location.reload();
            });
        })
            .catch((e) => {
                const errorMessage = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
            });
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage)
    }
    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const registrationRows = [
        {
            id: "checkbox",
            width: "3%"
        },
        {
            id: "embg",
            label: strings.embg,
            width: "5%"
        },
        {
            id: "firstName",
            label: strings.firstName,
            search: false,
            type: "text",
            variant: "standard",
            width: "10%"
        },
        {
            id: "lastName",
            label: strings.lastName,
            search: false,
            type: "text",
            variant: "standard",
            width: "10%"
        },
        {
            id: "registrationStatus",
            label: strings.statusEmbgShort,
            search: false,
            type: "text",
            variant: "standard",
            width: "10%"
        },
        {
            id: "holder",
            label: strings.claimHolder,
            width: "3%"
        },
    ]

    let registrationValues: any = [
        {
            key: registrationData?.id,
            id: registrationData?.id,
            values: [
                {

                    type: 'text',
                    name: 'checkbox',
                    value: <Checkbox
                        checked={((isDeceased && registrationData ? isChecked(registrationData) : false) || registrationData?.isDead) || false
                }
                onClick={() => {
        if (!registrationData) {
            return;
        }

        if (isChecked(registrationData)) {
            setChecked(checked.filter(item => item.id !== registrationData.id));
        } else {
            setChecked([
                ...checked,
                registrationData,
            ]);
        }
    }}
    disabled={(isDeceased && registrationData?.isDead) || false
}
    />
},
    {
        type: 'text',
            name: 'embg',
        value: <LightTooltip className={'tableTooltip'} title={registrationData?.embg}><span>{registrationData?.embg}</span></LightTooltip>,
    },
    {
        type: 'text',
            name: 'firstName',
        value: <LightTooltip className={'tableTooltip'} title={registrationData?.foreignCitizen?.personData?.firstName}><span>{registrationData?.foreignCitizen?.personData?.firstName}</span></LightTooltip>,
    },
    {
        type: 'text',
            name: 'lastName',
        value: <LightTooltip className={'tableTooltip'} title={registrationData?.foreignCitizen?.personData?.lastName}><span>{registrationData?.foreignCitizen?.personData?.lastName}</span></LightTooltip>,
    },
    {
        type: 'text',
            name: 'registrationStatus',
        value: <LightTooltip className={'tableTooltip'} title={registrationData?.registrationStatus?.displayName}><span>{registrationData?.registrationStatus?.displayName}</span></LightTooltip>,
    },
    {
        type: 'text',
            name: 'holder',
        value: <CheckCircleIcon />
    }
]
}

]

    let registrationChildValues = registrationData?.childRegistrations?.map((registration) => {
        const values = []
        values.push({
            type: 'text',
            name: 'checkbox',
            value: <Checkbox
                checked={(isDeceased && (isChecked(registration)) || registration.isDeceased()) || false}
        onClick={() => {
            if (isChecked(registration)) {
                setChecked(checked.filter(item => item.id !== registration.id));
            } else {
                setChecked([...checked, registration]);
            }
        }}
        disabled={(isDeceased && registration.isDead) || false}
        />
    })

        values.push({
            type: 'text',
            name: 'embg',
            value: <LightTooltip className={'tableTooltip'} title={registration?.embg}><span>{registration?.embg}</span></LightTooltip>,
    },
        {
            type: 'text',
                name: 'firstName',
            value: <LightTooltip className={'tableTooltip'} title={registration?.foreignCitizen?.personData?.firstName}><span>{registration?.foreignCitizen?.personData?.firstName}</span></LightTooltip>,
        },
        {
            type: 'text',
                name: 'lastName',
            value: <LightTooltip className={'tableTooltip'} title={registration?.foreignCitizen?.personData?.lastName}><span>{registration?.foreignCitizen?.personData?.lastName}</span></LightTooltip>,
        },
        {
            type: 'text',
                name: 'registrationStatus',
            value: <LightTooltip className={'tableTooltip'} title={registration?.registrationStatus?.displayName}><span>{registration?.registrationStatus?.displayName}</span></LightTooltip>,
        },
    )
        if (registration instanceof RegistrationReducedDTO) {
            values.push({
                type: 'text',
                name: 'holder',
                value: <CancelIcon />
            });
        }

        return {
            key: registration?.id,
            id: registration?.id,
            values: values,
        };
    })

    function isChecked(registration: RegistrationReducedDTO | RegistrationDTO): boolean {
        return checked.find(item => item.id === registration.id) !== undefined;
    }

    registrationValues.push(...registrationChildValues ?? []);
    return (
        <>
            <Modal
                open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box sx={style}>
    <Typography variant={'h6'} className = 'mb-3'>{(isDeceased && (isAsylum || isForeign) && strings.deceasedForm)}</Typography>
        {isAsylum && <div>
            <PageTable
                headerRows={registrationRows}
                rows={registrationValues}
                rowsPerPage={rowsPerPage}
                totalElements={totalElements}
                handleChangePage={handleChangePage}
                page={page}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                withoutPagination={true}
                clickable={false} />
        </div>}
        <div className={classes.wrapper} style={{marginTop: "40px"}}>
            <Button onClick={handleClose} style={{float:"left", background: "green"}} variant='contained' className='mb-4'>{strings.cancel}</Button>
                                    {/*{isDeceased && (isAsylum || isForeign) && <Button onClick={() => markAsDeceased(checked.length > 0 ? checked.map(item => item.id) : Array.of(+id!))} style={{float:"right"}} variant='contained' className='mb-4'>{strings.markDeceased}</Button>}*/}
                                        {isLoading && (
                                            <CircularProgress
                                                size={24}
                                            className={classes.buttonProgressEMBGModal}
                                            />
                                        )}
                                        </div>
                                        </Box>
                                        </Modal>
                                        </>
                                    )
                                    }