import axios from '../network/AxiosInstance';
import {Buffer} from "buffer";
import {AxiosResponse} from "axios";
import { UserDetailsInfoResponse } from '../models/UserDetailsInfoResponse';
import axiosInstance from '../network/AxiosInstance';
import { AuthorizationResponse } from '../models/AuthorizationResponse';

export class AuthService {
    static readonly PREFIX = "/rest";

    static async login(username: string, password: string): Promise<string> {
        const auth = new Buffer(`${username}:${password}`).toString('base64');

        const response = await axios.post<string>(`${AuthService.PREFIX}/login`, null, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + auth,
            }
        });

        return response.data;
    }

    static async authorize(token: string): Promise<void> {
        await axios.get(`${AuthService.PREFIX}/authorize`, {
            headers: {
                token,
            }
        });
    }

    static async resetPassword(email: string) : Promise<AxiosResponse<string>> {
        return await axios.post<string>(`/rest/userroles/user/reset-password`, {},  {
            params: {email},
        });
    }

    static async confirmResetPassword(token: string | null, password: string) : Promise<void>{
        await axios.post<string>(`/rest/userroles/user/confirm-password-reset?token=${token}`, {}, {
            headers: {password}
        });
    }
    static async getUserDetails() : Promise<UserDetailsInfoResponse> {
       const response = await axiosInstance.get<UserDetailsInfoResponse>(`/rest/user-management/userDetails`);
       const data = response.data;
       return new UserDetailsInfoResponse(data);
    }

    static async getAuthorization() : Promise<AuthorizationResponse> {
        const response = await axiosInstance.get<AuthorizationResponse>(`/rest/user-management/data`);
       const data = response.data;
       return new AuthorizationResponse(data);
    }
}
