import {Dayjs} from "dayjs";

export class PassivationCreationDTO{
    decisionNumber:string | null;
    passivationDate:Dayjs | null;
    passivationReasonMvrKey:string | undefined;
    constructor(json: PassivationCreationDTO){
        this.decisionNumber = json.decisionNumber;
        this.passivationDate = json.passivationDate;
        this.passivationReasonMvrKey = json.passivationReasonMvrKey;
    }
}