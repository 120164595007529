import {UserDTO} from "../models/UserDTO";
import axiosInstance from "../network/AxiosInstance";
import {Page} from "../pagination/Page";
import {UserFilter} from "../models/filters/UserFilter";
import {LoginSessionDTO} from "../models/LoginSessionDTO";
import {strings} from "../localization/Localization";


export class UserService {
    static readonly PREFIX = "/rest";


    static async getById(id: number): Promise<UserDTO> {
        const response = await axiosInstance.get<UserDTO>(
            `/rest/userroles/user/get/${id}`, {}
        );
        const data = response.data;
        return new UserDTO(data);
    }

    static async findAllPaged(page: number, size: number, userFilter: UserFilter): Promise<Page<UserDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString()
        });

        const response = await axiosInstance.post<Page<UserDTO>>(
            `/rest/userroles/user/all-paged`, userFilter, {params: params},
        );
        const data = response.data;

        return new Page(
            data.content.map((user) => new UserDTO(user)),
            data.totalElements,
        );
    }
    static async findAllLoginSessionsByUser(page: number, size: number,id: number): Promise<Page<LoginSessionDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString()
        })

        const response = await axiosInstance.get<Page<LoginSessionDTO>>(
            `/rest/userroles/user/${id}/login-sessions`, {params: params},
        );

        const data = response.data;

        return new Page<LoginSessionDTO>(
            data.content.map(loginSession => new LoginSessionDTO(loginSession)),
            data.totalElements
        )

    }

    static async logLogoutDate () : Promise<void> {
        await axiosInstance.post(`/rest/userroles/user/logout-logged-user`)
    }

    static async exportLoginSessions(id:number | string | undefined): Promise<void> {
        const response = await axiosInstance.get(`/rest/userroles/user/${id}/export-login-sessions`, {responseType: 'blob'})
        const blob = response.data;
        let fileName = response.headers['Content-Disposition']?.split("filename=")[1].split('.')[0].replace(/"/g, '');
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName ? fileName : `login_session_${id}`;
        alink.click();
    }
}
