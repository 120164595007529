import {NomenclatureEntryDTO} from "../models/nomenclatures/NomenclatureEntryDTO";

export class NomenclatureUtils {
    static findNomenclatureEntryOrGetDefault(entries: NomenclatureEntryDTO[], key: string | null | undefined): NomenclatureEntryDTO | undefined {
        let entry = entries.find((item) => item.key === key);

        if (!entry && key) {
            return NomenclatureEntryDTO.fromKey(key);
        }

        return entry;
    }
}
