export class IdentityCardDataDTO {
    cardNumber:string;
    date:Date | string;
    approvalDate:Date | string;
    finalDateOfStay:Date | string;
    validUntil?: Date | string;
    constructor(json:IdentityCardDataDTO){
        this.cardNumber = json.cardNumber;
        this.date = json.date;
        this.approvalDate = json.approvalDate;
        this.finalDateOfStay = json.finalDateOfStay;
        this.validUntil = json.validUntil;
    }
}