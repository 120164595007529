import {
    Card,
    CircularProgress,
    IconButton,
    MenuItem, Popover,
    StandardTextFieldProps,
    TablePagination,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Page} from "../pagination/Page";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {at} from "lodash";
import { useField } from "formik";
import { strings } from "../localization/Localization";
import { transliterateToCyrillic } from "../common/AutoTransliterationCyrillic";
import useDebounce from "../utils/UseDebounce";

type OnValueChangedFunction = {
    (value: any): void
};

type ValueMapper = {
    (value: any): string
};

type KeyMapper = {
    (item: any): string
};

type ItemMapper = {
    (value: any): React.ReactElement;
}

type Fetcher = {
    (page: number, size: number, filter: string | undefined): Promise<Page<any>>;
}

type LabelMapper = {
    (items: any): string;
}

interface PaginatedSelectProps {
    label?: string;
    filterLabel?: string;
    value: any[];
    valueMapper: ValueMapper;
    keyMapper: KeyMapper;
    itemMapper: ItemMapper;
    labelMapper: LabelMapper;
    dataFetcher: Fetcher;
    onChange: OnValueChangedFunction;
    closeOnSelect?: boolean;
    maxSelectedItems?: number;
    inputProps?: StandardTextFieldProps;
    name: string;
    className?: string;
    disabled?:boolean;
    readOnly?: boolean;
    
}

export function PaginatedMultiSelect<T>(props: PaginatedSelectProps) {
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [data, setData] = useState<Page<any>>(new Page([], 0));
    const [filter, setFilter] = useState<string>("");
    const [filterValue, setFilterValue] = useState<string>("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { label,  name, filterLabel, value, keyMapper, itemMapper,valueMapper, labelMapper, dataFetcher, onChange,disabled, readOnly, closeOnSelect, maxSelectedItems, inputProps,...rest } = props;
    const [field, meta, helpers] = useField(props);
    const { value: selectedValue } = field;
    const { setValue } = helpers;
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error && true;
    const [loading, setLoading] = useState<boolean>(true);
    function _renderHelperText() {
      const [touched, error] = at(meta, 'touched', 'error');
      if (touched && error) {
        return error;
      }
    }
    useEffect(() => {
        async function fetchAndSetData() {
          setLoading(true); 
          try {
            const newData = await props.dataFetcher(page, size, filter ? filter : undefined);
            setData(newData);
          } catch (error) {
          } finally {
            setLoading(false);
          }
        }

        if (open && !readOnly) {
          fetchAndSetData();
        }
      
        if (disabled || readOnly) { 
          setAnchorEl(null);
        } 
      }, [page, size, filter,open, readOnly]);

    const labelDisplayedRows = ({ from, to, count }: any) => {
        return `${from}-${to} од ${count}`;
    };

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void {
        setPage(newPage);
    }

    function handleChangeSize(event: React.ChangeEvent<HTMLInputElement>): void {
        setSize(parseInt(event.target.value, 10));
        setPage(0);
    }

    const setFilterDebounced = useDebounce((filter: string) => {
        setFilter(filter);
        setPage(0);
    }, 500, null);

    function handleFilterChanged(event: React.ChangeEvent<HTMLInputElement>): void {
        const value = transliterateToCyrillic(event.target.value);
        setFilterValue(value);
        setFilterDebounced(value);
    }

    function openDropdown(event: React.MouseEvent<HTMLElement>): void {
        setAnchorEl(event.currentTarget);
    }

    function closeDropdown(): void {
        if (anchorEl) {
            anchorEl.focus();
        }

        setAnchorEl(null);
    }
    function handleValueSelected(item: any): void {
        const updatedItems = props.value ? [...props.value] : [];
        const index = updatedItems.findIndex(presentItem => props.valueMapper(presentItem) === props.valueMapper(item));

        if (index === -1) {
            updatedItems.push(item);
        } else {
            updatedItems.splice(index, 1);
        }

        if (props.maxSelectedItems !== undefined && updatedItems.length > props.maxSelectedItems) {
            updatedItems.splice(0, updatedItems.length - props.maxSelectedItems);
        }
        props.onChange(updatedItems); 
        const selectedLabel = updatedItems.map((item) => props.labelMapper(item)).join(", ");
       
        if (props.closeOnSelect) {
            closeDropdown();
        }
    }
    function clearValue(): void {
        if(props.onChange){
        props.onChange([]);
        }
        field.onChange({ target: { name: props.name, value: ""} });
    }

    function valueContains(item: T): boolean {
        const foundItem = props.value?.find(presentItem => props.valueMapper(presentItem) === props.valueMapper(item));
        return foundItem !== undefined;
    }

    function buildLabel(): string {
        return props?.value?.map(item => item ? props.labelMapper(item) : "")?.join(", ") ?? "";
    }

    function isSelected(item: T): boolean {
        return valueContains(item);
    }
    
    return (
        <>
            <TextField
                fullWidth
                label={props.label}
                helperText={_renderHelperText()}
                className={props.className}
                onClick={openDropdown}
                error={isError}
                disabled={disabled}
                InputProps={{
                    readOnly: props.readOnly ? true : false,
                    endAdornment: (
                        <>
                            {
                                (!props.readOnly && props.value && props.value.length > 0 && !disabled) && <IconButton onClick={clearValue}>
                                    <ClearIcon/>
                                </IconButton>
                            }
                            <ArrowDropDownIcon/>
                        </>
                    ),
                    
                }}
                InputLabelProps={{ shrink: props.value && props.value.length > 0 && !disabled }}
                {...field}
                {...rest}
                value={selectedValue ? buildLabel() : ""}
            />
           

             
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={closeDropdown}
            >
               
                <Card
                    sx={{
                        minWidth: anchorEl?.offsetWidth,
                    }}
                >
                     {loading ? <div className="mt-5" style={{display:"flex", justifyContent:"center", alignItems:"center"}}><CircularProgress/></div>:
                     <>
                    <div className="mx-3 mb-3 mt-3">
                        <TextField
                            autoFocus={true}
                            variant="standard"
                            fullWidth
                            label={props.filterLabel}
                            value={filterValue}
                            onChange={handleFilterChanged}
                            // InputProps={{
                            //     readOnly: props.readOnly ? true : false
                            //   }}
                        />
                    </div>
                   
                    {data.content.map((item) => (
                        <MenuItem
                            key={props.keyMapper(item)}
                            value={props.valueMapper(item)}
                            onClick={() => handleValueSelected(item)}
                            selected={isSelected(item)}
                        >
                            
                            <div
                                className={isSelected(item) ? "me-3" : ""}
                            >
                               {props.itemMapper(item)}
                            </div>
                            
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                }}
                            >
                                {isSelected(item) &&
                                    <CheckCircleIcon
                                        color="primary"
                                        style={{
                                            marginLeft: "auto",
                                        }}
                                    />
                                }
                            </div>
                        </MenuItem>
                    ))}
                    </>
                            }

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        labelRowsPerPage={strings.rowsPerPage}
                        labelDisplayedRows={labelDisplayedRows}
                        component="div"
                        count={data.totalElements}
                        rowsPerPage={size}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeSize}
                        sx={{
                            "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
                                marginTop: "15px"
                            }
                        }}
                    />
                </Card>
            </Popover>
        </>
    );
   
}
