import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {translate} from "../../lang/translate-wrapper";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {properties} from "../../../config/properties";

UnauthorizedPage.propTypes = {
};

function UnauthorizedPage(props) {

    const dispatch = useDispatch();
    const[additionalMessage, setAdditionalMessage] = React.useState(props.additionalMessage);

    return (
        <body style={{backgroundColor: "#fafafa"}}>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >

            <Grid item xs={3}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {translate("app.unauthorized.title")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {translate("app.unauthorized.body")}
                        </Typography>
                        <Typography variant="body2" color="error" component="p">
                            {additionalMessage}
                        </Typography>
                    </CardContent>
                    <CardActions>
                    <Button size="small" color="primary"  href={`${properties.portalApp}`}>
                            {translate("app.unauthorized.logout")}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        </body>
    );
}

export default UnauthorizedPage;
