import { at } from 'lodash';
import { useField } from 'formik';
import { InputAdornment, TextField } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
interface Props {
  name: string;
  label: string;
  className?: string,
  fullWidth:boolean,
  multiline?:boolean, 
  rows?:number,
  errorText?:string | undefined,
  disabled?:boolean,
  readOnly?: boolean,
  onClick?: () => void;
  icon?:boolean,
  embgNumber?: boolean,
  icao?: boolean | string,
}
export default function InputField(props:Props) {
  const { errorText, multiline, rows, disabled, readOnly, icon, embgNumber, onClick,...rest } = props;
  const [field, meta, helpers] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = embgNumber ? event.target.value.replace(/[^\d]/g, '').slice(0, 13) : event.target.value;
      helpers.setValue(value);
  };

  const { onChange, ...fieldProps } = field;
  
  return (
    <TextField
      type="text"
      error={isError}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      helperText={_renderHelperText()}
      InputProps={{
        endAdornment:
          icon && field.value && (
            <InputAdornment position="start">
              <GetAppIcon onClick={onClick} className="cursor-pointer" />
            </InputAdornment>
          ),
        readOnly: props.readOnly ? true : false,
      }}
      
      onKeyPress={(event) => {
        if (props.icao && !/^[A-Za-z0-9\s.*\-\_\/+]+$/.test(event.key)) {
          event.preventDefault();
        }
      }}
      inputProps={{
        maxLength: embgNumber ? 13 : undefined, 
        pattern: embgNumber ? '[0-9]*' : undefined, 
      }}
      onChange={handleInputChange}
      {...fieldProps} 
      {...rest}
      value={fieldProps.value ? fieldProps.value : ''}
    />
  );
}
