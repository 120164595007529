import {PassivationCreationDTO} from "../models/foreign_citizens/PassivationCreationDTO";
import axiosInstance from "../network/AxiosInstance";
import {RegistrationDTO} from "../models/foreign_citizens/RegistrationDTO";
import {OnHoldDTO} from "../models/OnHoldDTO";
import {OnHoldCreationDTO} from "../models/OnHoldCreationDTO";
import {PassivationRegistrationCreationDTO} from "../models/PassivationRegistrationCreationDTO";
import {AuthService} from "./AuthService";

export class PassivationService {
    static async passivateEMBG(passivationCreationDTO : PassivationCreationDTO, registrationIds : Array<number>) : Promise<PassivationCreationDTO> {

        const params = new URLSearchParams();
        for(const regId of registrationIds) {
            params.append("registrationIds", regId.toString())
        }
        const response = await axiosInstance.put<PassivationCreationDTO>(
            `/rest/foreign-citizens/registrations/passivate-embg`, passivationCreationDTO,
            {params}
        )

        const data = response.data;
        return new PassivationCreationDTO(data);
    }

    static async onHoldEMBG(onHoldDTO: OnHoldCreationDTO | undefined, registrationIds : Array<number> | undefined) : Promise<RegistrationDTO> {
        const params = new URLSearchParams();
        if(registrationIds !== undefined){
            for(const regId of registrationIds) {
                params.append("registrationIds", regId.toString())
            }
        }

        const response = await axiosInstance.put<RegistrationDTO>(
            `/rest/foreign-citizens/registrations/set-embg-on-hold`, onHoldDTO, {params}
        )

        const data = response.data;
        return new RegistrationDTO(data);
    }

    static async passivateRegistration(id : number, passivationRegistrationCreationDTO: PassivationRegistrationCreationDTO) : Promise<void>{
     await axiosInstance.put(`/rest/foreign-citizens/registrations/${id}/passivate`, passivationRegistrationCreationDTO) ;
    }

    static async activateRegistration(id:number | string | undefined) : Promise<void>{
        await axiosInstance.put(`/rest/foreign-citizens/registrations/${id}/activate`) ;
    }

    static async markAsDeceased(registrationIds: Array<number>): Promise<void> {
        const params = new URLSearchParams();
        for(const regId of registrationIds) {
            params.append("registrationIds", regId.toString())
        }
        const response = await axiosInstance.post<RegistrationDTO>( `${AuthService.PREFIX}/foreign-citizens/registrations/mark-as-dead`, null, {params});
    }

}