import { RegistrationTypes } from "../../constants/RegistrationTypes";
import { StorageService } from "../../storage/StorageService";
import {NomenclatureConstants} from "../../constants/NomenclatureConstants";

export function ForeignUserData(values:any, correctionOfPersonData? : boolean | null){
const foreignCitizenCreationDTO = values;
let languages = [];
if (foreignCitizenCreationDTO.foreignLanguage !== "") {
  languages = foreignCitizenCreationDTO.foreignLanguage.map((element: any) => {
    return { languageMvrKey: element.key }
  })
}
let formData =  new FormData();
formData.append("resolutionFile", foreignCitizenCreationDTO.fileDecision as File)
const data =  {
processedBy:StorageService.getJWTPayload()?.sub,
registrationTypeMvrKey:RegistrationTypes.foreignCitizen,
placeOfRequestMvrKey: foreignCitizenCreationDTO.placeOfRequest?.key,
embg: foreignCitizenCreationDTO.embg ? foreignCitizenCreationDTO.embg : null,
foreignCitizenData:{
active:true,
evidencyReasonMvrKey:foreignCitizenCreationDTO.evidencyReasonMvrKey,
settlementReasonMvrKey:foreignCitizenCreationDTO.settlementReasonMvrKey,
familyRelationMvrKey: foreignCitizenCreationDTO.familyRelationMvrKey,
personDataCreationDTO:{
  citizenshipCreationDTO:{
      mvrKey:foreignCitizenCreationDTO.citizenShip?.key,
      statKey:null,
      isoName:null,
      iso2:null,
      iso3:null,
      nameSq:null,
      nameEn:null,
      countryDTO:{
          mvrKey:"",
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          }
  },
  otherCitizenshipCreationDTO:{
      mvrKey:foreignCitizenCreationDTO.otherCitizenShip?.key,
      statKey:null,
      isoName:null,
      iso2:null,
      iso3:null,
      nameSq:null,
      nameEn:null,
      countryDTO:{
          mvrKey:"",
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          }
  },
  embg:foreignCitizenCreationDTO.embg,
  existingMacEmbg:foreignCitizenCreationDTO.existingMacEmbg,
  firstName:foreignCitizenCreationDTO.firstName,
  lastName:foreignCitizenCreationDTO.lastName,
  genderMvrKey:foreignCitizenCreationDTO.genderMvrKey,
  birthDate:foreignCitizenCreationDTO.birthDate,
  birthPlaceDTO:{
      mvrKey:foreignCitizenCreationDTO.abroadBirthPlace ? foreignCitizenCreationDTO.placeOfBirthAbroad :  foreignCitizenCreationDTO?.inRSMBirthPlacePlace?.key,
      countryDTO:{
          mvrKey:foreignCitizenCreationDTO.abroadBirthPlace ? foreignCitizenCreationDTO?.abroadBirthPlace?.key : NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:foreignCitizenCreationDTO.inRSMBirthMunicipality?.key,
          name:null,
          nameSq:null,
          nameEn:null
  },
  maidenName:foreignCitizenCreationDTO.maidenName,
  parentName:foreignCitizenCreationDTO.parentName,
  occupationMvrKey:foreignCitizenCreationDTO.occupation?.key,
  professionalTrainingMvrKey:foreignCitizenCreationDTO.education,
  marriageStatusMvrKey:foreignCitizenCreationDTO.marriageStatus,
  addressDTO:{
      streetDTO:{
          placeDTO:{
      mvrKey:foreignCitizenCreationDTO.livingPlaceRSM?.key,
      countryDTO:{
          mvrKey: NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:"",
          municipalityMvrKey:foreignCitizenCreationDTO.livingMunicipalityRSM?.key,
          name:null,
          nameSq:null,
          nameEn:null
      },
      mvrKey:foreignCitizenCreationDTO.livingStreetRSM?.key,
      name:null,
      nameSq:null,
      nameEn:null
      },
      streetNumber:foreignCitizenCreationDTO.livingStreetNumberRSM,
      snMvrKey:null,
      entranceNumber: foreignCitizenCreationDTO.livingApartmentEntranceRSM,
      apartmentNumber: foreignCitizenCreationDTO.livingApartmentRSM
  },
  numberOfAdultFamilyMembers:null,
  numberOfForeignChildren:null,
  numberOfDomesticChildren:null,
  ageGroupMvrKey:null,
  personLanguageCreationDTOs: languages,
  addressDateFrom: foreignCitizenCreationDTO.addressDateFrom,
  firstNameCyrillic: foreignCitizenCreationDTO?.firstNameCyrillic,
  lastNameCyrillic: foreignCitizenCreationDTO?.lastNameCyrillic,
  parentSurname: foreignCitizenCreationDTO?.parentSurname,
  motherName: foreignCitizenCreationDTO?.motherName,
  motherSurname:foreignCitizenCreationDTO?.motherSurname,
},
previousLivingPlaceDTO:{
      mvrKey:foreignCitizenCreationDTO.place,
      countryDTO:{
          mvrKey:foreignCitizenCreationDTO.countryPreviousLiving?.key,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:"",
          name:null,
          nameSq:null,
          nameEn:null
  },
previousSettlementPlaceDTO:{
      mvrKey:foreignCitizenCreationDTO.stayPlace,
      countryDTO:{
          mvrKey:foreignCitizenCreationDTO.stayCountry?.key,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:"",
          name:null,
          nameSq:null,
          nameEn:null
  },
passportCreationDTO:{
  passportTypeMvrKey:foreignCitizenCreationDTO.passportTypeMvrKey,
  countryDTO:{
          mvrKey:foreignCitizenCreationDTO.countryPassport?.key,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
  passportNumber:foreignCitizenCreationDTO.passportNumber
},
entranceDataCreationDTO:{
  entranceDate:foreignCitizenCreationDTO.arrivalDate,
  entranceMannerMvrKey:foreignCitizenCreationDTO.arrivalWay,
  placeDTO:{
      mvrKey:foreignCitizenCreationDTO.illegalPlacePlace?.key,
      countryDTO:{
          mvrKey: NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:foreignCitizenCreationDTO.illegalPlaceMunicipality?.key,
          name:null,
          nameSq:null,
          nameEn:null
      },
  discovererMvrKey:foreignCitizenCreationDTO.discovererMvrKey,
  ovrMvrKey:foreignCitizenCreationDTO.OVR?.key,
  visitedForeignerShelter:foreignCitizenCreationDTO.shelterForeigners,
  visitedRefugeeShelter:foreignCitizenCreationDTO.shelterRefugees,
  financialSourceTypeMvrKey:foreignCitizenCreationDTO.sourceOfLivelihood

},
employmentDataCreationDTO:{
  employmentApproval:foreignCitizenCreationDTO.employmentApproval,
  employmentStatusMvrKey:foreignCitizenCreationDTO.statusEmployment,
  employmentSectorMvrKey:foreignCitizenCreationDTO.employedAt,
  occupationFieldMvrKey:foreignCitizenCreationDTO.employmentArea?.key,
  organizationCreationDTO:{
      name:foreignCitizenCreationDTO.nameOfOrganization,
      addressDTO:{
        streetDTO:{
              placeDTO:{
                mvrKey:foreignCitizenCreationDTO.placeOrganization?.key,
                countryDTO:{
                    mvrKey: NomenclatureConstants.macedoniaMvrKey,
                    isoName:null,
                    iso2:null,
                    iso3:null,
                    nameSq:null,
                    nameEn:null
                },
                municipalityName:null,
                municipalityMvrKey:foreignCitizenCreationDTO.municipalityOrganization?.key,
                name:null,
                nameSq:null,
                nameEn:null
              },
              mvrKey: foreignCitizenCreationDTO.streetOrganization?.key,
              name: null,
              nameSq: null,
              nameEn: null
          },
          streetNumber: foreignCitizenCreationDTO.numberStreetOrganization,
          snMvrKey: null,
          entranceNumber: null,
          apartmentNumber: null,
      },
  },
  employmentPositionMvrKey:foreignCitizenCreationDTO.occupationPlace,
  engagementTypeMvrKey:foreignCitizenCreationDTO.engagedProperty?.key
},
identityCardDataDTO:{
  cardNumber:foreignCitizenCreationDTO.idForeignerNumber,
  date:foreignCitizenCreationDTO.dateIdForeignerNumber,
  approvalDate:foreignCitizenCreationDTO.approvalDate,
  finalDateOfStay:foreignCitizenCreationDTO.endDateStay
},
foreignOrganizationDTO:{
      name:foreignCitizenCreationDTO.companyName,
      placeDTO:{
      mvrKey:"",
      countryDTO:{
          mvrKey:foreignCitizenCreationDTO.companyCountry?.key,
          isoName:null,
          iso2:null,
          iso3:null, 
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:null,
          name:foreignCitizenCreationDTO.companyPlace,
          nameSq:null,
          nameEn:null
      }
  },
familyMemberDTO:{
  citizenshipCreationDTO:{
      mvrKey:"",
      statKey:null,
      isoName:null,
      iso2:null,
      iso3:null,
      nameSq:null,
      nameEn:null,
      countryDTO:{
          mvrKey:"",
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          }
  },
  otherCitizenshipCreationDTO:{
      mvrKey:"",
      statKey:null,
      isoName:null,
      iso2:null,
      iso3:null,
      nameSq:null,
      nameEn:null,
      countryDTO:{
          mvrKey:"",
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          }
  },
  // embg:foreignCitizenCreationDTO.spouseEmbg,
  embg:"",
  firstName:foreignCitizenCreationDTO.spouseFirstName,
  lastName:foreignCitizenCreationDTO.spouseLastName,
  genderMvrKey:"",
  birthDate:foreignCitizenCreationDTO.spouseBirthDate,
  birthPlaceDTO:{
      mvrKey:foreignCitizenCreationDTO.spouseAbroadBirthPlace ? foreignCitizenCreationDTO.placeOfBirthSpouseAbroad : foreignCitizenCreationDTO.familyMemberBirthPlace?.key,
      countryDTO:{
          mvrKey:foreignCitizenCreationDTO.spouseAbroadBirthPlace ? foreignCitizenCreationDTO.spouseAbroadBirthPlace?.key : (foreignCitizenCreationDTO.familyMemberBirthPlace?.key || foreignCitizenCreationDTO.familyMemberBirthMunicipality?.key) ?  NomenclatureConstants.macedoniaMvrKey : null,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:foreignCitizenCreationDTO.familyMemberBirthMunicipality?.key,
          name:null,
          nameSq:null,
          nameEn:null
  },
  addressDTO:{
      streetDTO:{
          placeDTO:{
      mvrKey:foreignCitizenCreationDTO.placeRelative?.key,
      countryDTO:{
          mvrKey: NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:foreignCitizenCreationDTO?.municipalityRelative?.key,
          name:null,
          nameSq:null,
          nameEn:null
      },
      mvrKey:foreignCitizenCreationDTO.streetRelative?.key,
      name:null,
      nameSq:null,
      nameEn:null
      },
      streetNumber:foreignCitizenCreationDTO.numberStreetRelative,
      snMvrKey:null,
      entranceNumber: null,
      apartmentNumber: null,
  },
  numberOfAdultFamilyMembers:null,
  numberOfForeignChildren:foreignCitizenCreationDTO.numberOfForeignChildren,
  numberOfDomesticChildren:foreignCitizenCreationDTO.numberOfDomesticChildren,
  ageGroupMvrKey:null,
  personLanguageCreationDTOs: undefined
},
hostDTO:{
  citizenshipCreationDTO:{
      mvrKey:foreignCitizenCreationDTO.citizen?.key,
      statKey:null,
      isoName:null,
      iso2:null,
      iso3:null,
      nameSq:null,
      nameEn:null,
      countryDTO:{
          mvrKey:"",
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          }
  },
  otherCitizenshipCreationDTO:{
      mvrKey:"",
      statKey:null,
      isoName:null,
      iso2:null,
      iso3:null,
      nameSq:null,
      nameEn:null,
      countryDTO:{
          mvrKey:"",
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          }
  },
  // embg:foreignCitizenCreationDTO.embgPrivateVisitPerson,
  embg:"",
  firstName:foreignCitizenCreationDTO.firstNamePrivateVisitPerson,
  lastName:foreignCitizenCreationDTO.lastNamePrivateVisitPerson,
  genderMvrKey:foreignCitizenCreationDTO.genderPrivateVisitPerson,
  birthDate:null,
  birthPlaceDTO:{
      mvrKey:"",
      countryDTO:{
          mvrKey:"",
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:"",
          name:null,
          nameSq:null,
          nameEn:null
  },
  maidenName:null,
  parentName:null,
  occupationMvrKey:"",
  professionalTrainingMvrKey:"",
  marriageStatusMvrKey:"",
  addressDTO:{
      streetDTO:{
          placeDTO:{
      mvrKey:foreignCitizenCreationDTO.placePrivateVisitPerson?.key,
      countryDTO:{
          mvrKey: NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:foreignCitizenCreationDTO.municipalityPrivateVisitPerson?.key,
          name:null,
          nameSq:null,
          nameEn:null
      },
      mvrKey:foreignCitizenCreationDTO.streetPrivateVisitPerson?.key,
      name:null,
      nameSq:null,
      nameEn:null
      },
      streetNumber:foreignCitizenCreationDTO.numberStreetPrivateVisitPerson,
      snMvrKey:null,
      entranceNumber: foreignCitizenCreationDTO.apartmentEntrancePrivateVisitPerson,
      apartmentNumber: foreignCitizenCreationDTO.apartmentPrivateVisitPerson,
  },
  numberOfAdultFamilyMembers:null,
  numberOfForeignChildren:null,
  numberOfDomesticChildren:null,
  ageGroupMvrKey:null
},
measuresDataCreationDTO:{
 placeDTO:{
     mvrKey:foreignCitizenCreationDTO.placeMeasuresTakenPlace?.key,
     countryDTO:{
         mvrKey: NomenclatureConstants.macedoniaMvrKey,
         isoName:null,
         iso2:null,
         iso3:null,
         nameSq:null,
         nameEn:null
         },
         municipalityName:null,
         municipalityMvrKey:foreignCitizenCreationDTO.placeMeasuresTakenMunicipality?.key,
          name:null,
          nameSq:null,
          nameEn:null
      },
    ovrMvrKey:foreignCitizenCreationDTO.ovrMvrKey,
    measureDate:foreignCitizenCreationDTO.dateMeasuresTaken,
  illegalLeavePlaceDTO:{
      mvrKey:foreignCitizenCreationDTO.attemptIllegalPlace?.key,
      countryDTO:{
          mvrKey: NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:"",
          name:null,
          nameSq:null,
          nameEn:null
      },
  illegalLeaveDate:foreignCitizenCreationDTO.attemptIllegalDate,
  measureCreationDTOs:[
    {measureReasonMvrKey:foreignCitizenCreationDTO.measureTakenReason,
        measureTypeMvrKey:foreignCitizenCreationDTO.measureTakenType,
    }
  ],
},
note:foreignCitizenCreationDTO.noteForeigner,
archiveNumber: foreignCitizenCreationDTO.archiveNumber,
archiveDate: foreignCitizenCreationDTO.archiveDate,
dossierNumber: foreignCitizenCreationDTO.fileNumber,
timeStart: null,
timeEnd: null,
approvedStayDate: foreignCitizenCreationDTO.approvalDate,
restrictedStayDataCreationDTOS:[{
startDateOfStay:foreignCitizenCreationDTO.startDateStay,
finalDateOfStay:foreignCitizenCreationDTO.endDateStay,
extendedDateOfStay:foreignCitizenCreationDTO.dateOfExtension,
stoppedDateOfStay:null,
proposer:null,
restrictedStayPlaceMvrKey:null,
}],
resolutionCreationDTO:{
dateHanded: foreignCitizenCreationDTO.dateOfDeliveryOfDecision,
opinionPositive: foreignCitizenCreationDTO?.decision,
resolutionFile: foreignCitizenCreationDTO.fileDecision !=="" ? foreignCitizenCreationDTO.fileDecision : null,
entranceRestrictionDateFrom: foreignCitizenCreationDTO.residenceBanStartDate,
entranceRestrictionDateTo: foreignCitizenCreationDTO.residenceBanEndDate,
evidencyDate: foreignCitizenCreationDTO.dateEvidented,
isChecked: null,
},
asylumDataCreationDTO:{
    isInformedAboutLegalHelp:null,
    requestedSameGenderProcessor:null,
    requestReason:null,
    dateFilled:null,
    anbAnswerMvrKey: foreignCitizenCreationDTO.checksPerformed,
},
},
parentRegistrationId:null,
correctionOfPersonData: correctionOfPersonData,
oldRegistrationId: null,
active: true,
submitted: false, 
workingVersionId: "",
id:""
}

return data;
}
