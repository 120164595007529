import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@mui/material";
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { DatePickerField } from "../../applications/formFields/DatePickerFields";
import InputField from "../../applications/formFields/InputField";
import { useEffect, useState } from "react";
import { NomenclatureEntryDTO } from "../../../models/nomenclatures/NomenclatureEntryDTO";
import { NomenclatureService } from "../../../services/NomenclatureService";
import SelectField from "../../applications/formFields/SelectField";
import { FieldArray, useFormikContext } from "formik"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from "react-router-dom";
import InputFieldCyrillic from "../../applications/formFields/InputFieldCyrillic";

export function DataFormAsylumFive(props: any) {

  const [restrictedStayPlaces, setrestrictedStayPlaces] = useState<NomenclatureEntryDTO[]>([]);
  const [keyrestrictedStayPlaces, setKeyrestrictedStayPlaces] = useState<string>("restricted_stay_places")
  const [agent, setAgent] = useState<NomenclatureEntryDTO[]>([]);
  const [keyAgent, setKeyAgent] = useState<string>("agent")
  const [proposer, setProposer] = useState<NomenclatureEntryDTO[]>([]);
  const [keyProposer, setKeyProposer] = useState<string>("proposer")
  const [counter, setCounter] = useState <number>(0)
  let { id } = useParams();
  const isAddMode = !id;
  const formikProps = useFormikContext<any>()

  async function getAllEntriesForNomenclatureRestrictedStayPlaces(): Promise<void> {
    const restrictedStayPlacesData = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyrestrictedStayPlaces);
    setrestrictedStayPlaces(restrictedStayPlacesData)
  }

  async function getAllEntriesForNomenclatureAgent(): Promise<void> {
    const agentData = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyAgent);
    setAgent(agentData)
  }

  async function getAllEntriesForNomenclatureProposer(): Promise<void> {
    const proposerData = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyProposer);
    setProposer(proposerData)
  }

  useEffect(() => {
    getAllEntriesForNomenclatureRestrictedStayPlaces();
    getAllEntriesForNomenclatureAgent();
    getAllEntriesForNomenclatureProposer();
    if (!isAddMode){
      if ( formikProps.values.limitedMovementList !== undefined &&   formikProps.values.limitedMovementList.length>0){
        setCounter(formikProps.values.limitedMovementList.length)
      }
    }
  }, [counter])

  const {
    formField: {
      limitedMovementTitle
    }
  } = props;

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{}}
      >
        {/* Grid 1 */}
        <Grid item xs={12} sm={4} md={6}>
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {limitedMovementTitle.id + "." + limitedMovementTitle.label}
          </Typography>
          <FieldArray
            name="limitedMovementList"
            render={({ remove, push, form: { values, setFieldValue } }) => (
              <div>
                {values.limitedMovementList && values.limitedMovementList.length > 0 && (
                  values.limitedMovementList.map((form: any, index: any) => (
                    <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 " key={index}>
                      <Accordion className="sm:mt-14 mb-4" key={index} defaultExpanded={!isAddMode ? true : false}>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="bg-white sm:mt-8"
                          expandIcon={<ExpandMoreIcon />}
                          style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {limitedMovementTitle.label}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="bg-white">
                          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {"Предлагач"}
                          </Typography>
                          <SelectField name={`limitedMovementList[${index}].proposer`} label={"Предлагач"} data={proposer} className="pb-3" read={props.hasAuthority ? false : true}/>
                          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {"Орган кој го носи решението"}
                          </Typography>
                          <SelectField name={`limitedMovementList[${index}].agentMvr`} label={"Орган кој го носи решението"} data={agent} className="pb-3" read={props.hasAuthority ? false : true}/>
                          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {"Рок на ограничена слобода"}
                          </Typography>
                          <InputField name={`limitedMovementList[${index}].termOfLimitedMovement`} label={"Рок на ограничена слобода"} fullWidth className="pb-3"readOnly={props.hasAuthority ? false : true} />
                          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {"Почеток на ограничена слобода"}
                          </Typography>
                          <DatePickerField
                            name={`limitedMovementList[${index}].startDateOfLimitedMovement`}
                            label={"Почеток на ограничена слобода"}
                            className="pb-3"
                            read={props.hasAuthority ? false : true}
                          />
                          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {"Место на ограничена слобода на движење"}
                          </Typography>
                          <SelectField
                            name={`limitedMovementList[${index}].placeOfLimitedMovement`}
                            label={"Место на ограничена слобода на движење"}
                            data={restrictedStayPlaces}
                            read={props.hasAuthority ? false : true}
                          />
                          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {"Датум до кој е ограничено движењето"}
                          </Typography>
                          <DatePickerField
                            name={`limitedMovementList[${index}].dateOfLimitedMovement`}
                            label={"Датум до кој е ограничено движењето"}
                            className="pb-3"
                            read={props.hasAuthority ? false : true}
                          />
                          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {"Датум на продолжување"}
                          </Typography>
                          <DatePickerField
                            name={`limitedMovementList[${index}].dateOfExtension`}
                            label={"Датум на продолжување"}
                            className="pb-3"
                            read={props.hasAuthority ? false : true}
                          />
                          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {"Престанок на мерката"}
                          </Typography>
                          <DatePickerField
                            name={`limitedMovementList[${index}].terminationBeforeTerm`}
                            label={"Престанок на мерката"}
                            className="pb-3"
                            read={props.hasAuthority ? false : true}
                          />
                        </AccordionDetails>
                      </Accordion>
                      {props.hasAuthority && <Button className="mb-4" variant="contained" color="error" onClick={() =>{
                        remove(index);
                        counter>0 && setCounter(counter-1)
                      } }>Избриши</Button>}
                    </CenteredCardLayout>
                  ))
                )}
                {props.hasAuthority && <Button
                  type="button"
                  variant="contained"
                  onClick={() => {
                    push({
                      proposer: "",
                      agent: "",
                      placeOfLimitedMovement: "",
                      dateOfLimitedMovement: "",
                      dateOfExtension: "",
                    });
                    setCounter(counter+1);
                  }}
                  disabled={counter==3 && true}
                >
                  +
                </Button>}
              </div>
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}
