export class CountryCreationDTO{
    mvrKey:string | null;
    isoName:string | null;
    iso2:string | null;
    iso3:string | null;
    nameSq:string | null;
    nameEn:string | null;
    constructor(json:CountryCreationDTO){
        this.mvrKey = json.mvrKey;
        this.isoName = json.isoName;
        this.iso2 = json.iso2;
        this.iso3 = json.iso3;
        this.nameSq = json.nameSq;
        this.nameEn = json.nameEn;

    }
}