import { CustomUserDetailsResponse } from "./CustomUserDetailsResponse";

export class AuthorizationResponse {
    customUserDetails?: CustomUserDetailsResponse;
    roles: string[];
    permissions: string[];

    constructor(json:AuthorizationResponse){
        this.customUserDetails = json.customUserDetails ? new CustomUserDetailsResponse(json.customUserDetails) : undefined;
        this.roles = json.roles;
        this.permissions = json.permissions;
    }
}