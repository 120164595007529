import {BooleanParser} from "../utils/BooleanParser";

export class EnvironmentUtils {
    static internalAuth(): boolean {
        return BooleanParser.parseBooleanNullable(process.env.REACT_APP_INTERNAL_AUTH) ?? false;
    }

    static routerBase(): string {
        return process.env.REACT_APP_ROUTER_BASE ?? "/";
    }
}
