import {AppVersionDTO} from "../../models/AppVersionDTO";
import axiosInstance from "../../network/AxiosInstance";
import {EnvironmentUtils} from "../../environment/EnvironmentUtils";

export class VersionService {
    static readonly PREFIX = "/rest";

    static async getApiVersion (): Promise<AppVersionDTO> {
        const response = await axiosInstance.get(`${this.PREFIX}/version/`);
        const data = response.data;
        return new AppVersionDTO(data);
    }

    static async getApplicationVersion (): Promise<AppVersionDTO> {
        return fetch(`${EnvironmentUtils.routerBase()}/info.json`)
            .then((r) => r.json())
            .then((data) =>{
                return new AppVersionDTO(data)
            })
    }
}
