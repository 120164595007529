import { RegistrationTypeDTO } from "../models/registration/RegistrationTypeDTO";
import axiosInstance from "../network/AxiosInstance";

export class RegistrationTypeService {

    static async getAllRegistrationTypes(): Promise<Array<RegistrationTypeDTO>> {
        const response = await axiosInstance.get<Array<RegistrationTypeDTO>>(`/rest/foreign-citizens/registration-types/`);
        const data = response.data;
        return data;
    }
}