import React, { useState } from "react";
import { SideBarPage } from "../../components/SideBarPage";
import { strings } from "../../localization/Localization";
import {Box, Button, Grid, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {NullableDatePicker} from "../../components/NullableDatePicker";
import {Dayjs} from "dayjs";
import CustomSelect from "../applications/formFields/CustomSelect";


export function AsylumReports() {
    const [year, setYear] = useState<Dayjs | null>(null);
    const [month, setMonth] = useState<string>("");
    function handleChangeDatePicker(date: Dayjs | null) {
        setYear(date)
    }

    function handleMonthChange(event: SelectChangeEvent){
        setMonth(event.target.value);
    }

    const months = [
        {
            value: 1,
            label: 'Јануари'
        },
        {
            value: 2,
            label: "Февруари"
        },
        {
            value: 3,
            label: 'Март'
        },
        {
            value: 4,
            label: "Април"
        },
        {
            value: 5,
            label: 'Мај'
        },
        {
            value: 6,
            label: "Јуни"
        },
        {
            value: 7,
            label: 'Јули'
        },
        {
            value: 8,
            label: "Август"
        },
        {
            value: 9,
            label: 'Септември'
        },
        {
            value: 10,
            label: "Октомври"
        },
        {
            value: 11,
            label: 'Ноември'
        },
        {
            value: 12,
            label: "Декември"
        },
    ]


    return (
        <SideBarPage
            component={
                <Box component="div" display="flex" justifyContent="center" width="100%">
                    <Box component="div" width={"50%"} maxWidth="md" className="mt-3" justifyContent={"space-between"}>
                        <Grid xs={8}>
                            <NullableDatePicker
                                value={year}
                                onChange={handleChangeDatePicker}
                                label={"Година"} views={true}/>
                        </Grid>

                        <Grid xs={8}>
                            <Select
                                value={month}
                                label={"Месец"}
                                onChange={handleMonthChange}
                                fullWidth
                                className={"mt-2"}
                            >
                                {months.map(({value, label}, index) => {
                                    return (
                                        <MenuItem key={index} value={value}>{label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Box component="div" className="mt-3" display="flex" justifyContent="end">
                            <Button style={{marginRight:'10px'}} variant="contained" >{strings.generate}</Button>
                            <Button style={{background: "green"}} variant="contained" >{strings.exportMk}</Button>
                        </Box>
                    </Box>
                </Box>}
            pageTitle={"Лица со статус на признаен бегалец"}
        />

    )
}