import * as Yup from 'yup';
import DiplomatsFormModel from './DiplomatsFormModel';
const {
  formField: {
    lastName,
    firstName,
    genderMvrKey,
    birthDate,
    lastNameCyrillic,
    firstNameCyrillic,
    citizenship,
    abroadBirthPlace,
    placeOfBirthAbroad,
    inRSMBirthMunicipality,
    inRSMBirthPlacePlace,
    livingMunicipalityRSM,
    addressDateFrom,
    livingPlaceRSM,
    livingStreetRSM,
    livingStreetNumberRSM,
    approvalDate

  }
} = DiplomatsFormModel;


export default [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [firstNameCyrillic.name]: Yup.string().required(`${firstNameCyrillic.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [lastNameCyrillic.name]: Yup.string().required(`${lastNameCyrillic.requiredErrorMsg}`),
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [genderMvrKey.name]: Yup.string().required(`${genderMvrKey.requiredErrorMsg}`),
    [citizenship.name]: Yup.mixed().transform((v) => !v ? undefined : v).required(`${citizenship.requiredErrorMsg}`),
    [birthDate.name]: Yup.string().required(`${birthDate.requiredErrorMsg}`),
    [livingMunicipalityRSM.name]: Yup.mixed().required(`${livingMunicipalityRSM.requiredErrorMsg}`),
    [addressDateFrom.name]: Yup.string().required(`${addressDateFrom.requiredErrorMsg}`),
    [livingPlaceRSM.name]: Yup.mixed().required(`${livingPlaceRSM.requiredErrorMsg}`),
    [livingStreetRSM.name]: Yup.mixed().required(`${livingStreetRSM.requiredErrorMsg}`),
    [livingStreetNumberRSM.name]: Yup.string().required(`${livingStreetNumberRSM.requiredErrorMsg}`),
    [abroadBirthPlace.name]: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['placeOfBirthAbroad', 'inRSMBirthMunicipality', 'inRSMBirthPlacePlace'], {
      is: (placeOfBirthAbroadValue: any, inRSMBirthMunicipalityValue: any, inRSMBirthPlacePlaceValue: any) => (!placeOfBirthAbroadValue || placeOfBirthAbroadValue !== "") && !inRSMBirthMunicipalityValue && !inRSMBirthPlacePlaceValue,
      then: (schema) => schema.required(`${abroadBirthPlace.requiredErrorMsg}`),
    })),
    [placeOfBirthAbroad.name]: Yup.lazy(() => Yup.string().when(['abroadBirthPlace', 'inRSMBirthMunicipality', 'inRSMBirthPlacePlace'], {
      is: (abroadBirthPlaceValue: any, inRSMBirthMunicipalityValue: any, inRSMBirthPlacePlaceValue: any) => (!abroadBirthPlaceValue || abroadBirthPlaceValue !== "") && !inRSMBirthMunicipalityValue && !inRSMBirthPlacePlaceValue,
      then: (schema) => schema.required(`${placeOfBirthAbroad.requiredErrorMsg}`),
      otherwise: (schema) => schema.notRequired()
   
    })),
    [inRSMBirthMunicipality.name]: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['placeOfBirthAbroad', 'abroadBirthPlace', 'inRSMBirthPlacePlace'], {
      is: (placeOfBirthAbroadValue: any, abroadBirthPlaceValue: any, inRSMBirthPlacePlaceValue: any) => !placeOfBirthAbroadValue && !abroadBirthPlaceValue && (!inRSMBirthPlacePlaceValue || inRSMBirthPlacePlaceValue !== ""),
      then: (schema) => schema.required(`${inRSMBirthMunicipality.requiredErrorMsg}`),
      otherwise: (schema) => schema.notRequired()
    })),
    [inRSMBirthPlacePlace.name]: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['abroadBirthPlace', 'inRSMBirthMunicipality', 'placeOfBirthAbroad'], {
      is: (abroadBirthPlaceValue: any, inRSMBirthMunicipalityValue: any, placeOfBirthAbroadValue: any) => !abroadBirthPlaceValue && (!inRSMBirthMunicipalityValue || inRSMBirthMunicipalityValue !== "") && !placeOfBirthAbroadValue,
      then: (schema) => schema.required(`${inRSMBirthPlacePlace.requiredErrorMsg}`),
      otherwise: (schema) => schema.notRequired()
    })),
  }),

  Yup.object().shape({
    [approvalDate.name]: Yup.string().required(`${approvalDate.requiredErrorMsg}`),
  }),
  
];
