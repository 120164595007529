import FormModel from './FormModel';
const {
  formField: {
    //region 1
    lastName,
    maidenName,
    fathersName,
    name,
    gender,
    citizenShip,
    birthDate,
    countryAndCityOfBirth,
    education,
    maritalStatus,
    addressInTheCountryOfOrigin,
    appliedForTheRightToAsylumInRSM,
    otherNameOrNickName,
    borderPoint,
    collectiveCenter,
    appliedForTheRightToAsylumInOtherCountry,
    currentAddressOfPlace,
    dateOfArrival,
    dateOfSubbmission,
    decision,
    drivingLicense,
    employement,
    ethnicOrigin,
    familyMembersAcompany,
    familyMembersInTheCountryOfOrigin,
    politicalFunction,
    identificationDocument,
    identityDocuments,
    legal,
    manner,
    mannerOfArival,
    militaryService,
    motherTongueAndOtherLanguages,
    nameOfTheInstitution,
    nationalPassport,
    otherAddr,
    otherPolitical,
    otherResidenceStatus,
    others,
    permanent,
    phoneNumber,
    placeOfServing,
    placeWhereCompleted,
    politicalActivity,
    politicalParty,
    placeAccomodation,
    procedureType,
    relativesInNorthMacedonia,
    residenceStatusPriorToSubmissionOfTheApplication,
    sectionOfService,
    street,
    streetNumber,
    surnameAndNameOfTheSpouse,
    temporary,
    temporaryProtection,
    transportationMeans,
    yearOfCompletion,
    yearOfService,
    countryOfBirth,
    placeOfBirth,
    mothersName,
    religion,
    noneOfAbove,
    typeOfAccomodation,
    dossierNumber,
    archiveNumber,
    idOfIdentification,
    haveChildren,
    checksByUBK,
    placeOfSubbmision,
    employementList,
    livingPlacesList,
    relativesList,
    familyMembersAcompanyList,
    familyMembersOriginCountryOrAbroadList,
    municipalityAccomodation,
    limitedMovementList,
    nameCyrillic,
    surnameCyrillic,
    fathersNameCyrillic,
    mothersNameCyrillic,
    embg,
    fathersLastName,
    mothersLastName,
    addressDateFrom,
  }
} = FormModel;

export default {
  
  //region 1
  [embg.name]:'',
  [lastName.name]: '',
  [maidenName.name]: '',
  [fathersName.name]: '',
  [fathersLastName.name]:'',
  [name.name]: '',
  [surnameCyrillic.name]: '',
  [fathersNameCyrillic.name]: '',
  [nameCyrillic.name]: '',
  [mothersNameCyrillic.name]:'',
  [gender.name]: '',
  [citizenShip.name]: '',
  [birthDate.name]: '',
  [otherNameOrNickName.name]: '',
  [countryAndCityOfBirth.name]: '',
  [education.name]: '',
  [maritalStatus.name]: '',
  [ethnicOrigin.name] : '',
  [surnameAndNameOfTheSpouse.name]: '',
  [identityDocuments.name]:'',
  [nationalPassport.name] : '',
  [identificationDocument.name]:'',
  [drivingLicense.name]:'',
  [others.name]:'',
  [motherTongueAndOtherLanguages.name]:'',
  [nameOfTheInstitution.name]:'',
  [placeWhereCompleted.name]:'',
  [yearOfCompletion.name]: '',
  [employement.name]:'',
  [addressInTheCountryOfOrigin.name]:'',
  [mothersName.name]:'',
  [mothersLastName.name]:'',
  [religion.name]:'',
  [noneOfAbove.name]:'',
  [placeOfBirth.name]:'',
  [countryOfBirth.name]:'',
  [dossierNumber.name]:'',
  [archiveNumber.name]:'',
  [idOfIdentification.name]:'',
  [haveChildren.name]:'',
   [`${employementList.map((list) => list.from)}`]: '' ,
   [`${employementList.map((list) => list.to)}`]: '' ,
   [`${employementList.map((list) => list.institution)}`]: '' ,
   [`${employementList.map((list) => list.place)}`]: '' ,
   [`${employementList.map((list) => list.state)}`]: '' ,
   [`${livingPlacesList.map((list) => list.fromAddr)}`]: '' ,
   [`${livingPlacesList.map((list) => list.toAddr)}`]: '' ,
   [`${livingPlacesList.map((list) => list.city)}`]: '' ,
   [`${livingPlacesList.map((list) => list.countryOrigin)}`]: '' ,
   [`${livingPlacesList.map((list) => list.streetAddr)}`]: '' ,
   [`${livingPlacesList.map((list) => list.streetNumberAddr)}`]: '' ,
   [`${relativesList.map((list) => list.placeRelativesInNorthMacedonia)}`]: '' ,
   [`${relativesList.map((list) => list.relativeAddressOfLiving)}`]: '' ,
   [`${relativesList.map((list) => list.relativeCitizenship)}`]: '' ,
   [`${relativesList.map((list) => list.relativeName)}`]: '' ,
   [`${relativesList.map((list) => list.relativeRelationshipWithApplicant)}`]: '' ,
   [`${relativesList.map((list) => list.relativeLastName)}`]: '' ,
   [`${relativesList.map((list) => list.embg)}`]: '' ,
   [`${familyMembersAcompanyList.map((list) => list.memberEmbg)}`]: '' ,
   [`${familyMembersAcompanyList.map((list) => list.memberDateOfBirth)}`]: '' ,
   [`${familyMembersAcompanyList.map((list) => list.memberName)}`]: '' ,

   [`${familyMembersAcompanyList.map((list) => list.memberStateOfBirth)}`]: '' ,
   [`${familyMembersAcompanyList.map((list) => list.memberPlaceOfBirth)}`]: '' ,
   [`${familyMembersAcompanyList.map((list) => list.memberInRSMPlace)}`]: '' ,
   [`${familyMembersAcompanyList.map((list) => list.memberInRSMMunicipality)}`]: '' ,

  [`${familyMembersAcompanyList.map((list) => list.memberCitizenShip)}`]: '' ,
   [`${familyMembersAcompanyList.map((list) => list.memberSurname)}`]: '' ,
   [`${familyMembersAcompanyList.map((list) => list.relationshipsOrSex)}`]: '' ,
   [`${familyMembersAcompanyList.map((list) => list.memberNameCyrillic)}`]: '' ,
   [`${familyMembersAcompanyList.map((list) => list.memberSurnameCyrillic)}`]: '' ,
   [`${familyMembersOriginCountryOrAbroadList.map((list) => list.memberDateOfBirthOG)}`]: '' ,
   [`${familyMembersOriginCountryOrAbroadList.map((list) => list.memberNameOG)}`]: '' ,
   [`${familyMembersOriginCountryOrAbroadList.map((list) => list.memberStateOfBirthOG)}`]: '' ,
   [`${familyMembersOriginCountryOrAbroadList.map((list) => list.memberPlaceOfBirthOG)}`]: '' ,
   [`${familyMembersOriginCountryOrAbroadList.map((list) => list.memberCitizenShipOG)}`]: '' ,
   [`${familyMembersOriginCountryOrAbroadList.map((list) => list.memberSurnameOG)}`]: '' ,
   [`${familyMembersOriginCountryOrAbroadList.map((list) => list.relationshipsOrSexOG)}`]: '' ,
   [`${limitedMovementList.map((list) => list.dateOfExtension)}`]: '' ,
   [`${limitedMovementList.map((list) => list.dateOfLimitedMovement)}`]: '' ,
   [`${limitedMovementList.map((list) => list.placeOfLimitedMovement)}`]: '' ,
   [`${limitedMovementList.map((list) => list.proposer)}`]: '' ,
  [`${limitedMovementList.map((list) => list.agentMvr)}`]: '' ,

  //region 2
  
  [currentAddressOfPlace.name]:'',
  [placeAccomodation.name]:'',
  [municipalityAccomodation.name]:'',
  [street.name]:'',
  [streetNumber.name]:'',
  [phoneNumber.name]:'',
  [collectiveCenter.name]:'',
  [otherAddr.name]:'',
  [relativesInNorthMacedonia.name]:'',
  [militaryService.name]:'',
  [yearOfService.name]:'',
  [sectionOfService.name]:'',
  [placeOfServing.name]:'',
  [politicalActivity.name]:'',
  [politicalParty.name]:'',
  [politicalFunction.name]:'',
  [otherPolitical.name]:'',
  [familyMembersAcompany.name]:'',
  [familyMembersInTheCountryOfOrigin.name]:'',
  [appliedForTheRightToAsylumInRSM.name]:'',
  [appliedForTheRightToAsylumInOtherCountry.name]:'',
  [dateOfSubbmission.name]:'',
  [decision.name]:'',
  [mannerOfArival.name]:'',
  [dateOfArrival.name]:'',
  [manner.name]:'',
  [borderPoint.name]:'',
  [transportationMeans.name]:'',
  [residenceStatusPriorToSubmissionOfTheApplication.name]:'',
  [legal.name]:'',
  [temporary.name]:'',
  [permanent.name]:'',
  [temporaryProtection.name]:'',
  [otherResidenceStatus.name]:'',
  [procedureType.name]:'',
  [typeOfAccomodation.name]: '',
  [checksByUBK.name]:'',
  [placeOfSubbmision.name]:'',

  //region 3
  [addressDateFrom.name]: ''
};
