import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { DatePickerField } from "../formFields/DatePickerFields";
import InputField from "../formFields/InputField";
import SelectField from "../formFields/SelectField";
import { useEffect, useState } from "react";
import { NomenclatureEntryDTO } from "../../../models/nomenclatures/NomenclatureEntryDTO";
import CustomSelect from "../formFields/CustomSelect";
import { useFormikContext } from "formik";
import {getAllEntriesForNomenclatureEmloymentSector, getAllEntriesForNomenclatureEmploymentStatus, getAllEntriesForNomenclatureEntranceTypes, getAllEntriesForNomenclatureFinancialSources, getAllEntriesForNomenclatureMunicipality, getAllEntriesForNomenclaturePassportType, getAllEntriesForNomenclaturePlace } from "../../../common/NomenclatureMethods";
import { NomenclatureConstants } from "../../../constants/NomenclatureConstants";
import { NomenclatureService } from "../../../services/NomenclatureService";
import { PaginatedSelect } from "../../../components/PaginatedSelect";
import { ExternalService } from "../../../services/ExternalService";
import { showErrorDialog } from "../../../common/Dialogues";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import { strings } from "../../../localization/Localization";
import InputFieldCyrillic from "../formFields/InputFieldCyrillic";

  const employmentApprovalList = [
    {
      value: true,
      label: 'Мислење од АВРСМ позитивно'
    },
    {
      value: false,
      label: 'Негативно'
    },
  ];

export function EmploymentDataForm(props:any) {
  const {
    formField: {
    passportTypeMvrKey,
    countryPassport,
    passportNumber,
    arrivalDate,
    arrivalWay,
    sourceOfLivelihood,
    employmentApproval,
    statusEmployment,
    employedAt,
    employmentArea,
    organizationEmployment,
    embsOrganization,
    nameOfOrganization,
    municipalityOrganization,
    placeOrganization,
    streetOrganization,
    numberStreetOrganization,
    engagedProperty
    }
  } = props;
  const [employmentSectors, setEmploymentSectors] = useState<NomenclatureEntryDTO[]>([]);
  const [employmentStatuses, setEmploymentStatuses] = useState<NomenclatureEntryDTO[]>([]);
  const [entranceTypes, setEntranceTypes] = useState<NomenclatureEntryDTO[]>([]);
  const [financialSources, setFinancialSources] = useState<NomenclatureEntryDTO[]>([]);
  const [passportTypes, setPassportTypes] = useState<NomenclatureEntryDTO[]>([]);
  const formikProps = useFormikContext<any>();
  useEffect(()=>{
    getAllEntriesForNomenclaturePassportType().then((res)=>{setPassportTypes(res)});
    getAllEntriesForNomenclatureEntranceTypes().then((res)=>{setEntranceTypes(res)});
    getAllEntriesForNomenclatureFinancialSources().then((res)=>{setFinancialSources(res)});
    getAllEntriesForNomenclatureEmploymentStatus().then((res)=>{setEmploymentStatuses(res)});
    getAllEntriesForNomenclatureEmloymentSector().then((res)=>{setEmploymentSectors(res)});
  
  },[])

  function getCompanyByEmbs(embs:string){
     ExternalService.getCompanyByEmbs(embs).then((res)=>{
      getAllEntriesForNomenclatureMunicipality().then((municipalities)=>{
        const item = municipalities.find(el => el.key === res.opstinaSifraMvr)
        formikProps.setFieldValue("municipalityOrganization",item)
      })
      getAllEntriesForNomenclaturePlace().then((places)=>{
        const item = places.find(el => el.key === res.mestoSifraMvr)
        formikProps.setFieldValue("placeOrganization",item)
      })
      formikProps.setFieldValue("nameOfOrganization", res.naziv);
     }).catch((e)=>{
       const errorMessage = ErrorHandler.parseErrorMessage(e);
       showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
     })
  }
  function handleChange(event: SelectChangeEvent){
    formikProps.setFieldValue("employmentApproval", event.target.value)
  };
    return (
      <>
        <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {/* Grid 1 */}
        <Grid item xs={12} sm={4} md={6}>
        <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
       Б. Патна исправа
      </Typography>
        <Typography variant="subtitle1" gutterBottom>
            {passportTypeMvrKey.id + ". " + passportTypeMvrKey.label + "*"}
        </Typography>  
         <SelectField className="pb-3"
            name={passportTypeMvrKey.name}
            label={passportTypeMvrKey.placeholder + "*"}
            data={passportTypes}
            read={props.hasAuthority ? false : true}
          />
        <Typography variant="subtitle1" gutterBottom>
            {countryPassport.id + ". " + countryPassport.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.countryPassport!=="" ? formikProps.values.countryPassport : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("countryPassport", value)
                          }
                        }
                        label={countryPassport.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={countryPassport.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {passportNumber.id + ". " + passportNumber.label}
        </Typography>  
        <InputField name={passportNumber.name} label={passportNumber.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField>
        <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
       В. Доаѓање во РСМ
      </Typography>
        <Typography variant="subtitle1" gutterBottom>
            {arrivalDate.id + ". " + arrivalDate.label}
        </Typography>  
        <DatePickerField
            name={arrivalDate.name}
            label={arrivalDate.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
            //maxDate={true}
          />
        <Typography variant="subtitle1" gutterBottom>
            {arrivalWay.id + ". " + arrivalWay.label}
        </Typography>  
        <SelectField className="pb-3"
            name={arrivalWay.name}
            label={arrivalWay.placeholder}
            data={entranceTypes}
            read={props.hasAuthority ? false : true}
          />
        <Typography variant="subtitle1" gutterBottom>
            {sourceOfLivelihood.id + ". " + sourceOfLivelihood.label}
        </Typography>  
        <SelectField className="pb-3"
            name={sourceOfLivelihood.name}
            label={sourceOfLivelihood.placeholder}
            data={financialSources}
            read={props.hasAuthority ? false : true}
          />
        </Grid>

        {/* Grid2 */}
        <Grid item xs={12} sm={4} md={6}>
         <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
       Г. Податоци за вработување 
      </Typography>
        <Typography variant="subtitle1" gutterBottom>
            {employmentApproval.id + ". " + employmentApproval.label}
        </Typography>  
        <CustomSelect className="pb-3"
            name={employmentApproval.name}
            label={employmentApproval.placeholder}
            data={employmentApprovalList}
            read={props.hasAuthority ? false : true}
            onChange={handleChange}
          />
        <Typography variant="subtitle1" gutterBottom>
            {statusEmployment.id + ". " + statusEmployment.label}
        </Typography>  
        <SelectField className="pb-3"
            name={statusEmployment.name}
            label={statusEmployment.placeholder}
            data={employmentStatuses}
            read={props.hasAuthority ? false : true}
          />
        <Typography variant="subtitle1" gutterBottom>
            {employedAt.id + ". " + employedAt.label}
        </Typography>  
        <SelectField className="pb-3"
            name={employedAt.name}
            label={employedAt.placeholder}
            data={employmentSectors}
            read={props.hasAuthority ? false : true}
          />
        <Typography variant="subtitle1" gutterBottom>
            {employmentArea.id + ". " + employmentArea.label}
        </Typography> 
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.employmentArea!=="" ? formikProps.values.employmentArea : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("employmentArea", value)
                          }
                        }
                        label={employmentArea.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.occupations, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={employmentArea.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {organizationEmployment.id + ". " + organizationEmployment.label}
        </Typography>  
        <CenteredCardLayout minWidth={"100%"} maxWidth={'100%'} className="pb-3">
        <Typography variant="subtitle1" gutterBottom>
            {embsOrganization.id + ". " + embsOrganization.label}
        </Typography>  
        <InputField name={embsOrganization.name} label={embsOrganization.placeholder}  className="pb-3" fullWidth icon={true} onClick={() => getCompanyByEmbs(formikProps.values.embsOrganization)} readOnly={props.hasAuthority ? false : true}></InputField>
        <Typography variant="subtitle1" gutterBottom>
            {nameOfOrganization.id + ". " + nameOfOrganization.label}
        </Typography>  
        <InputField name={nameOfOrganization.name} label={nameOfOrganization.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {municipalityOrganization.id + ". " + municipalityOrganization.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.municipalityOrganization!=="" ? formikProps.values.municipalityOrganization : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("municipalityOrganization", value);
                          formikProps.setFieldValue("placeOrganization", "");
                          formikProps.setFieldValue("streetOrganization", "");
                          }
                        }
                        label={municipalityOrganization.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={municipalityOrganization.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {placeOrganization.id + ". " + placeOrganization.label}
        </Typography> 
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.placeOrganization!=="" ? formikProps.values.placeOrganization : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("placeOrganization", value);
                          formikProps.setFieldValue("streetOrganization", "");
                        }
                        }
                        label={placeOrganization.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.municipalityOrganization.key}`, filter ? filter : ""
                          );
                        }}
                        name={placeOrganization.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
           <Typography variant="subtitle1" gutterBottom>
            {streetOrganization.id + ". " + streetOrganization.label}
        </Typography> 
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.streetOrganization!=="" ? formikProps.values.streetOrganization : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("streetOrganization", value)
                          }
                        }
                        label={streetOrganization.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.street, true, `place=${formikProps.values.placeOrganization?.key}`, filter ? filter : ""
                          );
                        }}
                        name={streetOrganization.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
              <Typography variant="subtitle1" gutterBottom>
            {numberStreetOrganization.id + ". " + numberStreetOrganization.label}
            <InputField name={numberStreetOrganization.name} label={numberStreetOrganization.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
        </Typography> 
       
        </CenteredCardLayout>
        <Typography variant="subtitle1" gutterBottom>
            {engagedProperty.id + ". " + engagedProperty.label}
        </Typography>   
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.engagedProperty!=="" ? formikProps.values.engagedProperty : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("engagedProperty", value)
                          }
                        }
                        label={engagedProperty.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.occupations, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={engagedProperty.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        </Grid>
      </Grid>
      </>
    )
}

