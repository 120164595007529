import axiosInstance from "../network/AxiosInstance";

export class FileService {
    static async downloadFileContent(id: number, name:string) {
        await axiosInstance.get(`/rest/files/${id}/content`, {responseType: 'blob'}).then((response) => {
            var blob = response.data
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = name;
            alink.click();
        })
    }

}