import {Page} from "../pagination/Page";
import {PaginatedMultiSelect} from "./PaginatedMultiSelect";
import React from "react";

type OnValueChangedFunction<T> = {
    (value: T | undefined): void
};

type ValueMapper<T> = {
    (value: T): string
};

type KeyMapper<T> = {
    (item: T): string
};

type ItemMapper<T> = {
    (value: T): React.ReactElement;
}

type Fetcher<T> = {
    (page: number, size: number, filter: string | undefined): Promise<Page<T>>;
}

type LabelMapper<T> = {
    (item: T): string;
}

interface PaginatedSelectProps<T> {
    label?: string;
    filterLabel?: string;
    value?: T;
    valueMapper: ValueMapper<T>;
    keyMapper: KeyMapper<T>;
    itemMapper: ItemMapper<T>;
    labelMapper: LabelMapper<T>;
    dataFetcher: Fetcher<T>;
    onChange?: OnValueChangedFunction<T>;
    name: string;
    className?: string;
    disabled?:boolean;
    readOnly?: boolean;
}

export function PaginatedSelect<T>(props: PaginatedSelectProps<T>) {
    function onChange(value: any): void {
        if (value.length > 0) {
            if(props.onChange){
            props.onChange(value[0]);
            }
        } else {
            if(props.onChange){
            props.onChange(undefined);
            }
        }
    }

    return <PaginatedMultiSelect<T>
        label={props.label}
        filterLabel={props.filterLabel}
        value={props.value !== undefined ? [props.value] : []}
        valueMapper={props.valueMapper}
        keyMapper={props.keyMapper}
        itemMapper={props.itemMapper}
        labelMapper={props.labelMapper}
        dataFetcher={props.dataFetcher}
        onChange={onChange}
        closeOnSelect={true}
        maxSelectedItems={1}
        name={props.name}
        className={props.className}
        disabled={props.disabled}
        readOnly={props.readOnly}
    />
}
