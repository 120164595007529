import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { DataFormAsylumOne } from './forms/DataFormAsylumOne';
import { DataFormAsylumTwo } from './forms/DataFormAsylumTwo';
import FormModel from './formModel/FormModel';
import ValidationSchema from './formModel/ValidationSchema';
import FormInitialValues from './formModel/FormInitialValues';
import { SideBarPage } from '../../components/SideBarPage';
import { CenteredCardLayout } from '../../components/CenteredCardLayout';
import useStyles from "./styles/styles"
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Step,
  StepButton,
  Stepper
} from '@mui/material';
import { strings } from '../../localization/Localization';
import { DataFormAsylumThree } from './forms/DataFormAsylumThree';
import DataFormAsylumFour from './forms/DataFormAsylumFour';
import { DataFormAsylumFive } from './forms/DataFormAsylumFive';
import { AsylumForeignData } from '../AsylumSeekers/AsylumUserData';
import { useRef } from 'react';
import { ForeignCitizenService } from '../../services/registrations/ForeignCitizenService';
import { showErrorDialog, showSuccessDialog } from '../../common/Dialogues';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ErrorHandler } from '../../utils/ErrorHandler';
import { Routes } from '../../router/Routes';
import { RegistrationDTO } from '../../models/foreign_citizens/RegistrationDTO';
import { ForeignCitizenReducedDTO } from '../../models/registration/ForeignCitizenReducedDTO';
import { ForeignCitizenDTO } from '../../models/foreign_citizens/ForeignCitizenDTO';
import { DateTimeUtils } from '../../utils/DateTimeUtils';
import RightSidePanel from '../../components/right_side_component/RightSidePanel';
import { TemplateDTO } from '../../models/templates/TemplateDTO';
import ConfirmationModal from "../../components/ConfirmationModal";
import { PassivationService } from "../../services/PassivationService";
import { CreatedByInfo } from "../../components/CreatedByInfo";
import { EMBGModal } from "../../components/EMBGModal";
import { UserDetailsInfoResponse } from '../../models/UserDetailsInfoResponse';
import { AuthService } from '../../services/AuthService';
import { Privileges } from '../../models/nomenclatures/Privileges';
import {
  getAllEntriesForNomenclatureCitizenship,
  getAllEntriesForNomenclatureCountry,
  getAllEntriesForNomenclatureEthnicity,
  getAllEntriesForNomenclatureForeignLanguage,
  getAllEntriesForNomenclatureMunicipality,
  getAllEntriesForNomenclaturePlace,
  getAllEntriesForNomenclatureStreets, getAllEntriesForOrganisationalUnit
} from '../../common/NomenclatureMethods';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { TemplateService } from '../../services/TemplateService';
import { StorageService } from '../../storage/StorageService';
import { RegistrationReducedDTO } from "../../models/registration/RegistrationReducedDTO";
import { NomenclatureConstants } from '../../constants/NomenclatureConstants';
import {RegistrationCreationDTO} from "../../models/foreign_citizens/RegistrationCreationDTO";
import {DeceasedModal} from "../../components/DeceasedModal";
import { LightTooltip } from '../../components/LightTooltip';
import {AutoScroller} from "../../components/AutoScroller";
import {NomenclatureUtils} from "../../utils/NomenclatureUtils";

let steps = ['Општи податоци', 'Стручна спрема и вработувања', 'Адреси на живеење, роднински врски и активности', 'Членови на семејство и податоци за азил', "Ограничена слобода на движење"].map((v=>{
  return {name:v, completed:false}
}));
const { formId, formField } = FormModel;


export default function AsylumSeekersForm() {

  interface FormElement {
    id: string;
    data: JSX.Element;
  }

  const [employmentRegionForm, setEmploymentRegionForm] = useState<FormData[]>([]);
  const [addressInTheOgCountry, setAddressInTheOgCountry] = useState<FormData[]>([]);
  const [relativesInRM, setRelativesInRM] = useState<FormData[]>([]);
  const [familyMembersAcompany, setFamilyMembersAcompany] = useState<FormData[]>([]);
  const [familyMembersOG, setFamilyMembersOG] = useState<FormData[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = ValidationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const classes = useStyles();
  const [lastSubmittedValues, setLastSubmittedValues] = useState<{} | null>(null);
  const [registrationData, setRegistrationData] = useState<RegistrationDTO>();
  const [version, setVersion] = useState<string>();
  const [versions, setVersions] = useState<ForeignCitizenReducedDTO[] | undefined>([])
  const [asylumSeekerData, setAsylumSeekerData] = useState<ForeignCitizenDTO>();
  const [haveChildrenState, setHaveChildrenState] = useState<boolean>(false)
  const [userDetails, setUserDetails] = useState<UserDetailsInfoResponse>();
  const [hasAuthority, setHasAuthority] = useState<boolean>(false)
  const [readAsylumSensitiveData, setReadAsylumSensitiveData] = useState<boolean>(false)
  const [copyToForeignCitizen, setCopyToForeignCitizen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [asylumSeekersTemplates, setAsylumSeekersTemplates] = useState<TemplateDTO[]>([]);
  const [values, setValues] = useState<any>();
  const [openCheckModal, setOpenCheckModal] = useState<boolean>(false);
  const [openBasicDataChangesModal, setOpenBasicDataChangesModal] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialog2, setOpenDialog2] = React.useState(false);
  const [historyActions, setHistoryActions] = useState<any>()
  const [historyActionsEMBG, setHistoryActionsEMBG] = useState<any>()
  const [isPasivise, setIsPasivise] = useState<boolean>(true)
  const [fromikPropsValues, setFromikPropsValues] = useState<any>()
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [correctionOfPerData, setCorrectionOfPerData] = useState<boolean | null>(null);
  const [openDeceasedModal, setOpenDeceasedModal] = useState<boolean>(false);
  const [workingVersionId, setWorkingVersionId] = useState<number | undefined>(undefined)
  const formikRef = useRef<any>(null);
  const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>()
  const [open, setOpen] = useState<boolean>(false);
  const [openRetireEMBGModal, setOpenRetireEMBGModal] = useState<boolean>(false);
  const [openActivateEMBGModal, setOpenActivateEMBGModal] = useState<boolean>(false);
  const [openRequest, setOpenRequest] = useState<boolean>(false); //ova e za pasiviziranje
  const [openActivateRequest, setOpenActivateRequest] = useState<boolean>(false);

  const { state } = useLocation();
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen2 = () => {
    setOpenDialog2(true);
  };

  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };

  let { id } = useParams();
  const isAddMode = !id;

  async function pullChildRegistrations(data: number[]) {
    if (data.length > 0 && id) {
      const registration = await ForeignCitizenService.getRegistrationById(parseInt(id));
      setRegistrationData(registration)
    }
  }

  const pull_data = (data: any) => {
    setFromikPropsValues(data)
  }
  const getDisabledPropForSaveButton = (data: any) => {
    setDisabledSaveButton(data)
  }
  function renderStepContent(step: number) {

    switch (step) {
      case 0:
        return <DataFormAsylumOne hasAuthority={hasAuthority} formField={formField} func={getDisabledPropForSaveButton} />;
      case 1:
        return <DataFormAsylumTwo hasAuthority={hasAuthority} formField={formField} setState={setEmploymentRegionForm} employmentRegionForm={employmentRegionForm} />;
      case 2:
        return <DataFormAsylumThree hasAuthority={hasAuthority} formField={formField} setState={setAddressInTheOgCountry} addressInTheOgCountry={addressInTheOgCountry}
                                    setStateRelatives={setRelativesInRM} relativesInRM={relativesInRM}
        />;
      case 3:
        return <DataFormAsylumFour func={pull_data} hasAuthority={hasAuthority} childrenState={haveChildrenState} formField={formField} setStateAcompany={setFamilyMembersAcompany} familyMembersAcompany={familyMembersAcompany}
                                   setStateFamilyMembersOG={setFamilyMembersOG} familyMembersOG={familyMembersOG} readAsylumSensitiveData={readAsylumSensitiveData} copyToForeignCitizen={copyToForeignCitizen}
        />
      case 4:
        return <DataFormAsylumFive hasAuthority={hasAuthority} formField={formField} />
      default:
        return <div>Not Found</div>;
    }
  }

  function handleChange(event: SelectChangeEvent) {
    setVersion(event.target.value)
  }

  async function getVersion(id: number) {
    const data = await ForeignCitizenService.getForeignCitizenVersionForRegistration(+id, version && version);
    setAsylumSeekerData(data);
  }

  function getUserDetails() {
    AuthService.getUserDetails().then((res) => {
      setUserDetails(res)
    })
  }


  async function getRegistrationById(id: number) {
    setIsLoadingData(true)
    const registration = await ForeignCitizenService.getRegistrationById(id);
    setVersions(registration.versions);
    setIsLoadingData(false)
    if (registration.versions) {
      if (!version && (registration.versions?.length ?? 0) > 0) {
        setVersion(registration.versions[0].id.toString());
      }
      setRegistrationData(registration)
    }

  }

  useEffect(() => {
    async function copyData() {
      if (isAddMode && state?.data) {
        setIsLoadingData(true)
        state.data.submitted = false;
        state.data.id = null;
        state.data.workingVersionId = null;
        state.data.workingVersionRegistrationId = null;
        if(state.data.foreignCitizen?.personData?.birthPlaceDTO?.countryDTO?.mvrKey === NomenclatureConstants.macedoniaMvrKey){
          if(state.data.foreignCitizen?.personData?.birthPlaceDTO?.municipalityMvrKey){
            // let res = await NomenclatureService.getNomenclatureEntryByKeyAndNomenclatureKey(state.data.foreignCitizen.personData.birthPlaceDTO.municipalityMvrKey, NomenclatureConstants.place)
            state.data.foreignCitizen.personData.birthPlaceDTO.mvrKey = null;
          }else{
            state.data.foreignCitizen.personData.birthPlaceDTO.countryDTO = null
          }
        }
        setAsylumSeekerData(state.data?.foreignCitizen);
        setRegistrationData(state.data);
        setIsLoadingData(false)
      }
    }

    async function getData() {
      if (id && !isAddMode) {
        await getRegistrationById(parseInt(id));
        if (version) {
          await getVersion(parseInt(id));
        }

      }
    }
    getData().then(_ => {
    });
    getUserDetails();
    copyData().then(_ => { })
  }, [id, isAddMode, version])

  useEffect(() => {
    async function userHasAuthority(): Promise<void> {
      const userRoles: string[] | null = StorageService.getUserRoles();
      if (userRoles !== null) {
        if (userRoles.includes(Privileges.MANAGE_ASYLUM_REQUEST)) {
          setHasAuthority(true)
        }
        if (userRoles.includes(Privileges.READ_SENSITIVE_DATA_ASYLUM)) {
          setReadAsylumSensitiveData(true)
        }
        if (userRoles.includes(Privileges.MANAGE_FOREIGN_CITIZEN)) {
          setCopyToForeignCitizen(true)
        }
      } else {
        const authorization = await AuthService.getAuthorization();
        if (authorization.roles.includes(Privileges.MANAGE_ASYLUM_REQUEST)) {
          setHasAuthority(true)
        }
        if (authorization.roles.includes(Privileges.READ_SENSITIVE_DATA_ASYLUM)) {
          setReadAsylumSensitiveData(true)
        }
        if (authorization.roles.includes(Privileges.MANAGE_FOREIGN_CITIZEN)) {
          setCopyToForeignCitizen(true)
        }
      }
    }

    userHasAuthority().then((_) => { });

  }, []);

  async function getTemplatesForAsylumRequests(): Promise<void> {
    const asylumSeekersTemplates: TemplateDTO[] = await TemplateService.getTemplatesForAsylumRequests();
    setAsylumSeekersTemplates(asylumSeekersTemplates)
  }

  useEffect(() => {
    if (!isAddMode && id) {
      getTemplatesForAsylumRequests().then((_) => { });
    }
    const schema = ValidationSchema;
    for(let i=0;i<steps.length;i++){
      if(schema[i].isValidSync(formikRef?.current?.values)){
        steps[i].completed = true;
      }else {
        steps[i].completed = false;
      }
    }
  }, [formikRef?.current?.values]);

  function getHistory(id: number) {
    ForeignCitizenService.getHistoryActions(id).then(data => {
      setHistoryActions(data)
    });
  }

  function getHistoryEMBG(id: number) {
    ForeignCitizenService.getHistoryEMBG(id).then(data => {
      setHistoryActionsEMBG(data)
    });
  }

  useEffect(() => {
    if (id) {
      getHistory(parseInt(id))
      getHistoryEMBG(parseInt(id))
    }

    if (asylumSeekerData) {
      const countryKeyPerson = asylumSeekerData.personData?.birthPlaceDTO?.countryDTO.mvrKey;
      const municipalityKeyPerson = asylumSeekerData.personData?.birthPlaceDTO?.municipalityMvrKey;
      let municipalityChildKey:any = null;
      let countryChildKey:any = null;
      getAllEntriesForNomenclatureCountry().then((res) => {
        let country = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, asylumSeekerData.personData?.birthPlaceDTO?.countryDTO?.mvrKey);

        if (!country) {
          country = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, asylumSeekerData.personData?.birthCountryDTO?.mvrKey);
        }

        if (country && country?.key !== NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("countryOfBirth", country);
        } else if (country && country?.key === NomenclatureConstants.macedoniaMvrKey && municipalityKeyPerson) {
          formikRef?.current?.setFieldValue("countryOfBirth", "");
        }

        const countryWhereYouApplied = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, asylumSeekerData.foreignPastAsylumRequest?.country?.mvrKey)
        if (countryWhereYouApplied)
          formikRef?.current?.setFieldValue("countryWhereYouApplied", countryWhereYouApplied);

        if (asylumSeekerData.employmentInfoDTOList) {
          asylumSeekerData?.employmentInfoDTOList?.map((elem: any, index: number) => {
            const countryEducation = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem.organization?.placeDTO?.countryDTO?.mvrKey)
            formikRef?.current?.setFieldValue(`employementList[${index}].state`, countryEducation);
          })
        }

        if (asylumSeekerData.livingPlaceDTOList) {
          asylumSeekerData?.livingPlaceDTOList?.map((elem: any, index: number) => {
            const countryLivingPlaces = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem.address?.streetDTO?.placeDTO?.countryDTO?.mvrKey)
            formikRef?.current?.setFieldValue(`livingPlacesList[${index}].countryOrigin`, countryLivingPlaces)
          })
        }

        if (registrationData?.childRegistrations) {
          registrationData?.childRegistrations?.map((elem: any, index: number) => {
            const countryFamilyMember = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem.foreignCitizen?.personData?.birthPlaceDTO?.countryDTO?.mvrKey)
            if (countryFamilyMember && countryFamilyMember?.key !== NomenclatureConstants.macedoniaMvrKey) {
              formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberStateOfBirth`, countryFamilyMember)
            } else if (countryFamilyMember && countryFamilyMember?.key === NomenclatureConstants.macedoniaMvrKey && municipalityChildKey) {
              formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberStateOfBirth`, "")
            }

          })
        }

        if (asylumSeekerData?.familyMemberDTOList) {
          asylumSeekerData?.familyMemberDTOList?.map((elem: any, index: number) => {
            const countryFamilyMemberOG = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem.personData?.birthPlaceDTO?.countryDTO?.mvrKey)
            formikRef?.current?.setFieldValue(`familyMembersOriginCountryOrAbroadList[${index}].memberStateOfBirthOG`, countryFamilyMemberOG)
          })
        }
      })

      getAllEntriesForNomenclaturePlace().then((res) => {

        const placeCurrentAddress = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, asylumSeekerData.personData?.addressDTO?.streetDTO.placeDTO?.mvrKey)
        formikRef?.current?.setFieldValue(`placeAccomodation`, placeCurrentAddress)


        if (asylumSeekerData.relativeDTOList) {
          asylumSeekerData.relativeDTOList?.map((elem: any, index: number) => {
            const placeRelatives = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem.personData.addressDTO.streetDTO.placeDTO.mvrKey)
            formikRef?.current?.setFieldValue(`relativesList[${index}].placeRelativesInNorthMacedonia`, placeRelatives)
          })
        }
        if (registrationData?.childRegistrations) {
          registrationData?.childRegistrations?.map((elem: any, index: number) => {
            const placeOfBirthFamilyMember = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem.foreignCitizen?.personData?.birthPlaceDTO?.mvrKey)
            countryChildKey = elem.foreignCitizen?.personData?.birthPlaceDTO?.countryDTO?.mvrKey
            if (placeOfBirthFamilyMember && countryChildKey === NomenclatureConstants.macedoniaMvrKey) {
              formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberInRSMPlace`, placeOfBirthFamilyMember)
              formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberPlaceOfBirth`, "")
            } else if (countryChildKey !== NomenclatureConstants.macedoniaMvrKey && !municipalityKeyPerson) {
              formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberPlaceOfBirth`, elem.foreignCitizen?.personData?.birthPlaceDTO?.mvrKey)
              formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberInRSMPlace`, "")
            }
            // formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberPlaceOfBirth`, placeOfBirthFamilyMember)
          })
        }
        if (registrationData?.childRegistrations) {
          registrationData?.childRegistrations?.map((elem: any, index: number) => {
            const placeFamilyMember = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem.foreignCitizen?.personData?.birthPlaceDTO?.mvrKey)
            formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberInRSMPlace`, placeFamilyMember)
          })
        }
        const inRSMBirthPlacePlace = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, asylumSeekerData.personData?.birthPlaceDTO?.mvrKey)
        if (inRSMBirthPlacePlace && countryKeyPerson === NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("inRSMBirthPlacePlace", inRSMBirthPlacePlace)
          formikRef?.current?.setFieldValue("placeOfBirth", "")
        } else if (countryKeyPerson !== NomenclatureConstants.macedoniaMvrKey && !municipalityKeyPerson) {
          formikRef?.current?.setFieldValue("placeOfBirth", asylumSeekerData.personData?.birthPlaceDTO?.mvrKey)
          formikRef?.current?.setFieldValue("inRSMBirthPlacePlace", "")
        }
      })


      getAllEntriesForNomenclatureStreets().then((res) => {

        const streetCurrentAddress = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, asylumSeekerData.personData?.addressDTO?.streetDTO?.mvrKey)
        formikRef?.current?.setFieldValue(`street`, streetCurrentAddress)

        if (asylumSeekerData.relativeDTOList) {
          asylumSeekerData.relativeDTOList?.map((elem: any, index: number) => {
            const streetRelatives = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem?.personData?.addressDTO?.streetDTO?.mvrKey)
            formikRef?.current?.setFieldValue(`relativesList[${index}].relativeAddressOfLiving`, streetRelatives)
          })
        }

      })

      getAllEntriesForNomenclatureCitizenship().then((res) => {
        const citizenship = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, asylumSeekerData?.personData?.citizenshipCreationDTO?.mvrKey)
        formikRef?.current?.setFieldValue("citizenShip", citizenship);


        if (registrationData?.childRegistrations) {
          registrationData?.childRegistrations?.map((elem: any, index: number) => {
            const citizenshipFamilyMember = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem.foreignCitizen?.personData?.citizenshipDTO?.mvrKey)
            formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberCitizenShip`, citizenshipFamilyMember)
          })
        }

        if (asylumSeekerData?.familyMemberDTOList) {
          asylumSeekerData?.familyMemberDTOList?.map((elem: any, index: number) => {
            const citizenshipFamilyMemberOG = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem.personData?.citizenshipCreationDTO?.mvrKey)
            formikRef?.current?.setFieldValue(`familyMembersOriginCountryOrAbroadList[${index}].memberCitizenShipOG`, citizenshipFamilyMemberOG)
          })
        }
      })


      getAllEntriesForNomenclatureForeignLanguage().then((res) => {
        const languages = res;
        const languageKeys = asylumSeekerData.personData?.personLanguageCreationDTOs?.map(language => language.languageMvrKey);
        if (languageKeys && languageKeys.length > 0) {
          const selectedLanguages = languageKeys.map(key => NomenclatureUtils.findNomenclatureEntryOrGetDefault(languages, key));
          formikRef?.current?.setFieldValue('motherTongueAndOtherLanguages', selectedLanguages);
        } else {
          formikRef?.current?.setFieldValue('motherTongueAndOtherLanguages', "");
        }
      });

      getAllEntriesForNomenclatureMunicipality().then((res) => {
        if (asylumSeekerData.relativeDTOList) {
          asylumSeekerData.relativeDTOList?.map((elem: any, index: number) => {
            const municipalityRelatives = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem.personData?.addressDTO?.streetDTO?.placeDTO?.municipalityMvrKey)
            formikRef?.current?.setFieldValue(`relativesList[${index}].municipalityRelatives`, municipalityRelatives)
          })
        }
        const municipalityCurrentAddress = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, asylumSeekerData.personData?.addressDTO?.streetDTO.placeDTO?.municipalityMvrKey)
        formikRef?.current?.setFieldValue(`municipalityAccomodation`, municipalityCurrentAddress)

        if (registrationData?.childRegistrations) {
          registrationData?.childRegistrations?.map((elem: any, index: number) => {
            countryChildKey = elem.foreignCitizen?.personData?.birthPlaceDTO?.countryDTO?.mvrKey
            const placeFamilyMember = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, elem.foreignCitizen?.personData?.birthPlaceDTO?.municipalityMvrKey)
            if (placeFamilyMember && countryChildKey === NomenclatureConstants.macedoniaMvrKey) {
              formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberInRSMMunicipality`, placeFamilyMember)
            } else if (countryChildKey !== NomenclatureConstants.macedoniaMvrKey) {
              formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberInRSMMunicipality`, "")
            }

          })
        }

        const inRSMBirthMunicipality = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, asylumSeekerData.personData?.birthPlaceDTO?.municipalityMvrKey)
        if (inRSMBirthMunicipality && countryKeyPerson === NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("inRSMBirthMunicipality", inRSMBirthMunicipality)
        } else if (countryKeyPerson !== NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("inRSMBirthMunicipality", "")
        }
      })

      formikRef?.current?.setFieldValue('embg', registrationData?.embg);
      formikRef?.current?.setFieldValue('dossierNumber', asylumSeekerData.dossierNumber);
      formikRef?.current?.setFieldValue('archiveNumber', asylumSeekerData.archiveNumber);
      formikRef?.current?.setFieldValue('archiveDate', asylumSeekerData.archiveDate);
      getAllEntriesForOrganisationalUnit().then((res) => {
        const placeOfRequest = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, registrationData?.placeOfRequest?.mvrKey)
        formikRef?.current?.setFieldValue('placeOfSubmission', placeOfRequest)
      })
      formikRef?.current?.setFieldValue('about', asylumSeekerData.asylumRequestIntention?.mvrKey);
      formikRef?.current?.setFieldValue('existingMacEmbg', asylumSeekerData.personData?.existingMacEmbg);
      formikRef?.current?.setFieldValue('name', asylumSeekerData.personData?.firstName);
      formikRef?.current?.setFieldValue('nameCyrillic', asylumSeekerData.personData?.firstNameCyrillic);
      formikRef?.current?.setFieldValue('lastName', asylumSeekerData.personData?.lastName);
      formikRef?.current?.setFieldValue('surnameCyrillic', asylumSeekerData.personData?.lastNameCyrillic);
      formikRef?.current?.setFieldValue('maidenName', asylumSeekerData.personData?.maidenName);
      formikRef?.current?.setFieldValue('otherNameOrNickName', asylumSeekerData.personData?.nickname);
      formikRef?.current?.setFieldValue('fathersName', asylumSeekerData.personData?.parentName);
      formikRef?.current?.setFieldValue('fathersLastName', asylumSeekerData.personData?.parentSurname);
      formikRef?.current?.setFieldValue('fathersNameCyrillic', asylumSeekerData.personData?.parentNameCyrillic);
      formikRef?.current?.setFieldValue('mothersName', asylumSeekerData.personData?.motherName);
      formikRef?.current?.setFieldValue('mothersLastName', asylumSeekerData.personData?.motherSurname);
      formikRef?.current?.setFieldValue('birthDate', asylumSeekerData.personData?.birthDate);
      // formikRef?.current?.setFieldValue('countryOfBirth', asylumSeekerData.personData?.birthPlaceDTO?.countryDTO?.mvrKey);
      // formikRef?.current?.setFieldValue('placeOfBirth', asylumSeekerData.personData?.birthPlaceDTO?.mvrKey);
      // formikRef?.current?.setFieldValue('inRSMBirthMunicipality', asylumSeekerData.personData?.birthPlaceDTO?.mvrKey);
      // formikRef?.current?.setFieldValue('inRSMBirthPlacePlace', asylumSeekerData.personData?.birthPlaceDTO?.mvrKey);

      formikRef?.current?.setFieldValue('gender', asylumSeekerData.personData?.genderMvrKey);
      formikRef?.current?.setFieldValue('citizenShip', asylumSeekerData.personData?.citizenshipCreationDTO?.mvrKey);
      formikRef?.current?.setFieldValue('ethnicOrigin', asylumSeekerData.personData?.ethnicityMvrKey);
      formikRef?.current?.setFieldValue('religion', asylumSeekerData.personData?.religionMvrKey);
      formikRef?.current?.setFieldValue('maritalStatus', asylumSeekerData.personData?.marriageStatusMvrKey);
      formikRef?.current?.setFieldValue('surnameAndNameOfTheSpouse', asylumSeekerData.personData?.spouseFullName);
      formikRef?.current?.setFieldValue('addressDateFrom', asylumSeekerData.personData?.addressDateFrom)
      if (asylumSeekerData.personData?.personLanguageCreationDTOs?.length !== 0) {
        formikRef?.current?.setFieldValue('motherTongueAndOtherLanguages', asylumSeekerData.personData?.personLanguageCreationDTOs?.map(language => language.languageMvrKey));
      } else {
        formikRef?.current?.setFieldValue('motherTongueAndOtherLanguages', "");
      }
      formikRef?.current?.setFieldValue('employementList', asylumSeekerData.employmentInfoDTOList)
      asylumSeekerData?.employmentInfoDTOList?.map((item: any, index: number) => {
        formikRef?.current?.setFieldValue(`employementList[${index}].from`, item.dateFrom)
        formikRef?.current?.setFieldValue(`employementList[${index}].to`, item.dateTo)
        formikRef?.current?.setFieldValue(`employementList[${index}].institution`, item.organization.name)
        formikRef?.current?.setFieldValue(`employementList[${index}].state`, item.organization?.placeDTO?.countryDTO?.mvrKey)
        formikRef?.current?.setFieldValue(`employementList[${index}].place`, item.organization?.placeDTO?.mvrKey)
      })

      formikRef?.current?.setFieldValue('livingPlacesList', asylumSeekerData.livingPlaceDTOList)
      asylumSeekerData?.livingPlaceDTOList?.map((item: any, index: number) => {
        formikRef?.current?.setFieldValue(`livingPlacesList[${index}].fromAddr`, item.dateFrom)
        formikRef?.current?.setFieldValue(`livingPlacesList[${index}].toAddr`, item.dateTo)
        formikRef?.current?.setFieldValue(`livingPlacesList[${index}].countryOrigin`, item.address?.streetDTO?.placeDTO?.countryDTO?.mvrKey)
        formikRef?.current?.setFieldValue(`livingPlacesList[${index}].city`, item.address?.streetDTO?.placeDTO?.mvrKey)
        formikRef?.current?.setFieldValue(`livingPlacesList[${index}].streetAddr`, item.address?.streetDTO?.mvrKey)
        formikRef?.current?.setFieldValue(`livingPlacesList[${index}].streetNumberAddr`, item.address?.streetNumber)
      })


      formikRef?.current?.setFieldValue('relativesList', asylumSeekerData.relativeDTOList)
      asylumSeekerData?.relativeDTOList?.map((item: any, index: number) => {
        formikRef?.current?.setFieldValue(`relativesList[${index}].relativeName`, item.personData?.firstName)
        formikRef?.current?.setFieldValue(`relativesList[${index}].relativeLastName`, item.personData?.lastName)
        formikRef?.current?.setFieldValue(`relativesList[${index}].relativeRelationshipWithApplicant`, item.relativeTypeMvrKey)
        formikRef?.current?.setFieldValue(`relativesList[${index}].placeRelativesInNorthMacedonia`, item.personData?.addressDTO?.streetDTO?.placeDTO?.mvrKey)
        formikRef?.current?.setFieldValue(`relativesList[${index}].relativeAddressOfLiving`, item.personData?.addressDTO?.streetDTO?.mvrKey)
        formikRef?.current?.setFieldValue(`relativesList[${index}].relativeCitizenship`, item.personData?.citizenshipCreationDTO?.mvrKey)
      })

      if (registrationData?.childRegistrations?.length !== 0) {
        setHaveChildrenState(true)
      } else {
        setHaveChildrenState(false)
      }

      formikRef?.current?.setFieldValue('familyMembersAcompanyList', registrationData?.childRegistrations)
      registrationData?.childRegistrations?.map((item: any, index: number) => {
        item.id ? formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].id`, item.id) : formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].id`, undefined)
        formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberExistingMacEmbg`, item.foreignCitizen?.personData?.existingMacEmbg)
        formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberEmbg`, item.embg)
        formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberName`, item.foreignCitizen?.personData?.firstName)
        formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberSurname`, item.foreignCitizen?.personData?.lastName)
        formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberNameCyrillic`, item.foreignCitizen?.personData?.firstNameCyrillic)
        formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberSurnameCyrillic`, item.foreignCitizen?.personData?.lastNameCyrillic)
        formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberDateOfBirth`, item.foreignCitizen?.personData?.birthDate)
        formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].relationshipsOrSex`, item.foreignCitizen?.personData?.gender?.mvrKey)
        // formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberStateOfBirth`, item.foreignCitizen?.personData?.birthPlaceDTO?.countryDTO?.mvrKey)
        // formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberPlaceOfBirth`, item.foreignCitizen?.personData?.birthPlaceDTO?.municipalityName)
        // formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberInRSMMunicipality`, item.foreignCitizen?.personData?.birthPlaceDTO?.municipality?.mvrKey)
        // formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberInRSMPlace`, item.foreignCitizen?.personData?.birthPlaceDTO?.mvrKey)
        formikRef?.current?.setFieldValue(`familyMembersAcompanyList[${index}].memberCitizenShip`, item.foreignCitizen?.personData?.citizenshipDTO?.mvrKey)

      })


      formikRef?.current?.setFieldValue('familyMembersOriginCountryOrAbroadList', asylumSeekerData?.familyMemberDTOList)
      asylumSeekerData?.familyMemberDTOList?.map((item: any, index: number) => {
        formikRef?.current?.setFieldValue(`familyMembersOriginCountryOrAbroadList[${index}].memberNameOG`, item.personData?.firstName)
        formikRef?.current?.setFieldValue(`familyMembersOriginCountryOrAbroadList[${index}].memberSurnameOG`, item.personData?.lastName)
        formikRef?.current?.setFieldValue(`familyMembersOriginCountryOrAbroadList[${index}].memberDateOfBirthOG`, item.personData?.birthDate)
        formikRef?.current?.setFieldValue(`familyMembersOriginCountryOrAbroadList[${index}].relationshipsOrSexOG`, item.personData?.genderMvrKey)
        formikRef?.current?.setFieldValue(`familyMembersOriginCountryOrAbroadList[${index}].memberPlaceOfBirthOG`, item.personData?.birthPlaceDTO?.municipalityName)
        formikRef?.current?.setFieldValue(`familyMembersOriginCountryOrAbroadList[${index}].memberStateOfBirthOG`, item.personData?.birthPlaceDTO?.countryDTO?.mvrKey)
        formikRef?.current?.setFieldValue(`familyMembersOriginCountryOrAbroadList[${index}].memberCitizenShipOG`, item.personData?.citizenshipCreationDTO?.mvrKey)
      })

      formikRef?.current?.setFieldValue('identificationDocument', asylumSeekerData.identificationDocumentData?.idCardNumber);
      formikRef?.current?.setFieldValue('nationalPassport', asylumSeekerData.identificationDocumentData?.passportNumber);
      formikRef?.current?.setFieldValue('drivingLicense', asylumSeekerData.identificationDocumentData?.drivingLicenseNumber);
      formikRef?.current?.setFieldValue('others', asylumSeekerData.identificationDocumentData?.other);
      formikRef?.current?.setFieldValue('noneOfAbove', asylumSeekerData.identificationDocumentData?.noneOfAbove);
      formikRef?.current?.setFieldValue('education', asylumSeekerData.personData?.professionalTrainingMvrKey);
      formikRef?.current?.setFieldValue('nameOfTheInstitution', asylumSeekerData.personData?.educationInstitutionDTO?.name);
      formikRef?.current?.setFieldValue('placeWhereCompleted', asylumSeekerData.personData?.educationInstitutionDTO?.place);
      formikRef?.current?.setFieldValue('yearOfCompletion', asylumSeekerData.personData?.educationDateFinishedDate);
      formikRef?.current?.setFieldValue('typeOfAccomodation', asylumSeekerData.personData?.residenceTypeMvrKey);
      formikRef?.current?.setFieldValue('placeAccomodation', asylumSeekerData.personData?.addressDTO?.streetDTO.placeDTO?.municipalityMvrKey);
      formikRef?.current?.setFieldValue('street', asylumSeekerData.personData?.addressDTO?.streetDTO.mvrKey);
      formikRef?.current?.setFieldValue('streetNumber', asylumSeekerData.personData?.addressDTO?.streetNumber);
      formikRef?.current?.setFieldValue('collectiveCenter', asylumSeekerData.personData?.collectiveCenterMvrKey);
      formikRef?.current?.setFieldValue('otherAddr', asylumSeekerData.personData?.otherResidence);
      formikRef?.current?.setFieldValue('yearOfService', asylumSeekerData.militaryService?.year);
      formikRef?.current?.setFieldValue('sectionOfService', asylumSeekerData.militaryService?.type);
      formikRef?.current?.setFieldValue('placeOfServing', asylumSeekerData.militaryService?.placeName);
      formikRef?.current?.setFieldValue('politicalParty', asylumSeekerData.politicalActivity?.politicalParty);
      formikRef?.current?.setFieldValue('politicalFunction', asylumSeekerData.politicalActivity?.position);
      formikRef?.current?.setFieldValue('otherPolitical', asylumSeekerData.politicalActivity?.other);
      formikRef?.current?.setFieldValue('dateOfSubbmission', asylumSeekerData.domesticPastAsylumRequest?.date);
      formikRef?.current?.setFieldValue('decision', asylumSeekerData.domesticPastAsylumRequest?.decisionType?.mvrKey);
      formikRef?.current?.setFieldValue('countryWhereYouApplied', asylumSeekerData.foreignPastAsylumRequest?.country?.mvrKey);
      formikRef?.current?.setFieldValue('decisionOtherCountry', asylumSeekerData.foreignPastAsylumRequest?.decisionResult?.mvrKey);
      formikRef?.current?.setFieldValue('briefSummaryAboutTheReasons', asylumSeekerData.applicantStatement);
      formikRef?.current?.setFieldValue('freeLegalAid', asylumSeekerData.asylumData?.isInformedAboutLegalHelp);
      formikRef?.current?.setFieldValue('sameGenderOfficial', asylumSeekerData.asylumData?.requestedSameGenderProcessor);
      formikRef?.current?.setFieldValue('procedureType', asylumSeekerData.asylumData?.procedureType?.mvrKey);
      formikRef?.current?.setFieldValue('idOfIdentification', asylumSeekerData.identityCardData?.cardNumber);
      formikRef?.current?.setFieldValue('expireDate', asylumSeekerData.identityCardData?.validUntil);
      formikRef?.current?.setFieldValue('dateOfSubmissionForm', asylumSeekerData.asylumData?.dateFilled ? asylumSeekerData.asylumData?.dateFilled : new Date())
      formikRef?.current?.setFieldValue('checksByUBK', asylumSeekerData.asylumData?.anbAnswer?.mvrKey);
      formikRef?.current?.setFieldValue('dateOfArrival', asylumSeekerData.entranceData?.entranceDate);
      formikRef?.current?.setFieldValue('manner', asylumSeekerData.entranceData?.entranceMannerMvrKey);
      formikRef?.current?.setFieldValue('borderPoint', asylumSeekerData.entranceData?.borderPointMvrKey);
      formikRef?.current?.setFieldValue('transportationMeans', asylumSeekerData.entranceData?.vehicle);
      formikRef?.current?.setFieldValue('legal', asylumSeekerData.previousStayData?.legalStay);
      formikRef?.current?.setFieldValue('temporary', asylumSeekerData.previousStayData?.temporaryStay);
      formikRef?.current?.setFieldValue('permanent', asylumSeekerData.previousStayData?.permanentStay);
      formikRef?.current?.setFieldValue('temporaryProtection', asylumSeekerData.previousStayData?.temporaryProtection);
      formikRef?.current?.setFieldValue('limitedMovementList', asylumSeekerData?.restrictedStayData)
      asylumSeekerData?.restrictedStayData?.map((item: any, index: number) => {
        formikRef?.current?.setFieldValue(`limitedMovementList[${index}].proposer`, item.proposer?.mvrKey)
        formikRef?.current?.setFieldValue(`limitedMovementList[${index}].agentMvr`, item.agent?.mvrKey)
        registrationData?.foreignCitizen?.restrictedStayData?.map((element: any) => {
          formikRef?.current?.setFieldValue(`limitedMovementList[${index}].placeOfLimitedMovement`, element.restrictedStayPlace?.mvrKey)
        })
        formikRef?.current?.setFieldValue(`limitedMovementList[${index}].dateOfLimitedMovement`, item.finalDateOfStay)
        formikRef?.current?.setFieldValue(`limitedMovementList[${index}].dateOfExtension`, item.extendedDateOfStay)
        formikRef?.current?.setFieldValue(`limitedMovementList[${index}].termOfLimitedMovement`, item.deadline)
        formikRef?.current?.setFieldValue(`limitedMovementList[${index}].startDateOfLimitedMovement`, item.startDateOfStay)
        formikRef?.current?.setFieldValue(`limitedMovementList[${index}].terminationBeforeTerm`, item.stoppedDateOfStay)

      })
    }

  }, [registrationData, asylumSeekerData])


  const navigate = useNavigate();

  async function addAsylumForm(values: any) {
    let data = AsylumForeignData(values)
    data.submitted = true;
    if (registrationData?.workingVersionRegistrationId?.id) {
      data.workingVersionId = registrationData?.workingVersionRegistrationId?.id.toString();
    }
    await ForeignCitizenService.createForeignCitizen(data)
        .then((res) => {
          let route = Routes.ASYLUM_SEEKERS_EDIT;
          route = route.replace(":id", res.id.toString());
          showSuccessDialog(strings.addForeignCitizenSuccess, strings.addForeignCitizenSuccess, strings.ok).then(_ => {
            setActiveStep(0)
            if (res?.versions) {
              setVersions(res?.versions)
              setVersion(res?.versions[0]?.id.toString() || '')
              navigate(route);
            }
          });
        })
        .catch((e) => {
          const errorMessage = ErrorHandler.parseErrorMessage(e);
          showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
        });
  }

  async function editAsylumForm(values: any, correctionOfPersonData? : boolean | null) {
    let data = AsylumForeignData(values, correctionOfPersonData !== undefined ? correctionOfPersonData : correctionOfPerData);
    data.submitted = true;
    if (registrationData?.id) {
      id = registrationData.id.toString();
      setWorkingVersionId(undefined)
    }
    if (id && isPasivise) {
      try {
        setIsLoadingData(true)
        const res = await ForeignCitizenService.updateForeignCitizen(+id, data);
        showSuccessDialog(strings.editForeignCitizenSuccess, strings.addForeignCitizenSuccess, strings.ok).then(() => {
          setActiveStep(0);
          if (res?.versions) {
            setVersions(res.versions);
            setVersion(res.versions[0]?.id.toString() || '');
            let data = AsylumForeignData(values, null);
            setCorrectionOfPerData(null)
            navigate(Routes.ASYLUM_SEEKERS_EDIT.replace(":id", id!.toString()));

          }
        });
      } catch (e) {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        showErrorDialog(strings.fail, errorMessage, strings.ok).then(() => { });
      }
      setIsLoadingData(false)
    }
  }
  function checkIfAtLeastOneChildHasEmbg(childRegistrations: RegistrationReducedDTO[] | undefined): boolean {
    if (childRegistrations !== undefined) {
      for (var i = 0; i < childRegistrations?.length; i++) {
        if (childRegistrations[i].embg !== null) {
          return true;
        }
      }
    }
    return false;
  }
  function checkIfBasicChangesExist(oldData : ForeignCitizenDTO | undefined, newData : RegistrationCreationDTO, childRegistrations: RegistrationReducedDTO[] | undefined) : boolean{
    if((oldData?.personData?.firstName !== newData?.foreignCitizenData?.personDataCreationDTO?.firstName) ||
        (oldData?.personData?.lastName !== newData?.foreignCitizenData?.personDataCreationDTO?.lastName) ||
        (oldData?.personData?.firstNameCyrillic !== newData?.foreignCitizenData?.personDataCreationDTO?.firstNameCyrillic) ||
        (oldData?.personData?.lastNameCyrillic !== newData?.foreignCitizenData?.personDataCreationDTO?.lastNameCyrillic)){
      return true;
    }
    if(childRegistrations){
      for(const childRegistration of childRegistrations){
        const child = newData.childRegistrations?.find((child:RegistrationCreationDTO) => child.id === childRegistration.id);

        if(child){
          if((child.foreignCitizenData?.personDataCreationDTO?.firstName !== childRegistration?.foreignCitizen?.personData?.firstName) ||
              (child.foreignCitizenData?.personDataCreationDTO?.lastName !==childRegistration?.foreignCitizen?.personData?.lastName) ||
              (child.foreignCitizenData?.personDataCreationDTO?.firstNameCyrillic !== childRegistration?.foreignCitizen?.personData?.firstNameCyrillic) ||
              (child.foreignCitizenData?.personDataCreationDTO?.lastNameCyrillic !== childRegistration?.foreignCitizen?.personData?.lastNameCyrillic)){
            return true;
          }
        }
      }
    }
    return false;
  }

  async function editAsylumCitizen(values: any) {
    let data = AsylumForeignData(values);
    data.submitted = true;
    setValues(values);
    if (id) {
      let showModal = false;
      let showChildModal = false;

      if (registrationData?.embg || checkIfAtLeastOneChildHasEmbg(registrationData?.childRegistrations)) {
        if (
            registrationData?.registrationStatus?.key === 'passive' &&
            (asylumSeekerData?.personData?.genderMvrKey !== data?.foreignCitizenData?.personDataCreationDTO?.genderMvrKey ||
                asylumSeekerData?.personData?.birthDate !== data?.foreignCitizenData?.personDataCreationDTO?.birthDate)
        ) {

          showModal = true;
          setIsPasivise(true);
        } else if (
            registrationData?.registrationStatus !== undefined && registrationData?.registrationStatus?.key !== 'passive' &&
            (asylumSeekerData?.personData?.genderMvrKey !== data?.foreignCitizenData?.personDataCreationDTO?.genderMvrKey ||
                asylumSeekerData?.personData?.birthDate !== data?.foreignCitizenData?.personDataCreationDTO?.birthDate)
        ) {
          showModal = true;
          setIsPasivise(false);
        }
        if (fromikPropsValues !== undefined) {
          for (const childRegistration of registrationData?.childRegistrations || []) {
            const child = fromikPropsValues.familyMembersAcompanyList.find(
                (child: any) => child.id === childRegistration.id
            );

            if (
                childRegistration.registrationStatus?.key === 'passive' &&
                (childRegistration.foreignCitizen.personData.gender.mvrKey !== child.relationshipsOrSex ||
                    childRegistration.foreignCitizen.personData.birthDate !== child.memberDateOfBirth)
            ) {
              showChildModal = true;
              setIsPasivise(true);
            } else if (
                childRegistration?.registrationStatus !== undefined && childRegistration.registrationStatus?.key !== 'passive' &&
                (childRegistration.foreignCitizen.personData.gender.mvrKey !== child.relationshipsOrSex ||
                    childRegistration.foreignCitizen.personData.birthDate !== child.memberDateOfBirth)
            ) {
              showChildModal = true;
              setIsPasivise(false);
            }
          }
        }
        let checkBasicInfoChanges = checkIfBasicChangesExist(asylumSeekerData, data, registrationData?.childRegistrations);
        if (checkBasicInfoChanges){
          setOpenBasicDataChangesModal(true);
        }
        if (showModal || showChildModal) {
          setOpenCheckModal(true);
        }
        if (!checkBasicInfoChanges && !showModal && !showChildModal){
          await editAsylumForm(values);
        }
      } else {
        await editAsylumForm(values);
      }
    }
  }

  async function submitForm(values: any, actions: any) {
    setIsLoading(true);
    if (!registrationData?.id) {
      await addAsylumForm(values);
      setLastSubmittedValues(values);
    } else {
      await editAsylumCitizen(values);
      setLastSubmittedValues(values);
    }

    actions.setSubmitting(false);
    setIsLoading(false);
    for (let step of steps) {
      step.completed = false;
    }
    setActiveStep(activeStep + 1);
  }

  function handleSubmit(values: any, actions: any) {
    setIsLoading(true)
    if (isLastStep) {
      let doSubmit = true;

      for (let index = 0; index < steps.length - 1; index++) {
        if (!steps[index].completed) {
          actions.setSubmitting(false);
          setActiveStep(index);
          doSubmit = false;
          break;
        }
      }
      if (doSubmit) {
        submitForm(values, actions);
      }
    } else {
      setActiveStep(activeStep + 1);
      steps[activeStep].completed = true;
      actions.setTouched({});
      actions.setSubmitting(false);
    }
    setIsLoading(false)
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  const handleStepValidation =  async(stepIndex:number) => {
    steps[activeStep].completed = true;
    try {
      await currentValidationSchema.validate(formikRef?.current?.values);
    } catch (e) {
      steps[activeStep].completed = false;
    }
    setActiveStep(stepIndex);
  };


  const handleOpen = () => {
    setOpen(true);
  }

  function refreshData(): any {
    async function getData() {
      if (id && !isAddMode) {
        await getRegistrationById(parseInt(id));
        await getHistory(parseInt(id));
        await getHistoryEMBG(parseInt(id));
        if (version) {
          await getVersion(parseInt(id));
        }

      }
    }
    getData().then(_ => {
    });
  }

  const handleRetireEMBGOpen = () => setOpenRetireEMBGModal(true);
  const handleActivateEMBGOpen = () => setOpenActivateEMBGModal(true);

  function handleClose(actionPerformed: boolean): any {
    setOpen(false);

    if (actionPerformed) {
      refreshData();
    }
  }

  function handleCloseCheckModal(): any {
    setOpenCheckModal(false);
    setActiveStep(steps.length - 1)
    setIsPasivise(true);
  }
  function handleCloseBasicDataChangesModal(): any {
    setOpenBasicDataChangesModal(false);
    setActiveStep(steps.length - 1)
  }

  function handleRetireEMBGModalClose(actionPerformed: boolean): any {
    setOpenRetireEMBGModal(false);

    if (actionPerformed) {
      refreshData();
    }
  }

  function handleActivateEMBGModalClose(actionPerformed: boolean): any {
    setOpenActivateEMBGModal(false);

    if (actionPerformed) {
      refreshData();
    }
  }
  function handleOpenRequest() {
    setOpenRequest(true)
  }
  function handleOpenActivateRequest() {
    setOpenActivateRequest(true)
  }

  function handleCloseRequest(actionPerformed: boolean) {
    setOpenRequest(false);

    if (actionPerformed) {
      refreshData();
    }
  }

  function handleOpenDeceasedModal() {
    setOpenDeceasedModal(true)
  }

  function handleCloseDeceasedModal() {
    setOpenDeceasedModal(false)
    refreshData()
  }

  function handleCloseActivateRequest(actionPerformed: boolean) {
    setOpenActivateRequest(false);

    if (actionPerformed) {
      refreshData();
    }
  }
  async function handleClickCorrection(){
    setCorrectionOfPerData(true);
    setOpenBasicDataChangesModal(false);

    if (!openCheckModal){
      await editAsylumForm(values, true);
    }
  }
  async function handleClickChange(){
    setCorrectionOfPerData(false);
    setOpenBasicDataChangesModal(false);

    if (!openCheckModal){
      await editAsylumForm(values, false);
    }

  }
  function activateRequest() {
    if (id) {
      PassivationService.activateRegistration(id).then(() => {
        showSuccessDialog(strings.successfullyActivatedRequest, strings.successfullyActivatedRequest, strings.ok).then(_ => {
          handleCloseActivateRequest(true)
          refreshData()
        });
      })
          .catch((e) => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
          });
    }
  }
  async function createOrUpdateWorkingVersion() {
    setIsLoadingSave(true)
    let data = AsylumForeignData(formikRef?.current?.values)
    data.submitted = false;
    if (registrationData?.id) {
      data.id = registrationData?.id.toString();
    }
    if (registrationData?.workingVersionRegistrationId?.id) {
      data.workingVersionId = registrationData.workingVersionRegistrationId.id.toString();
    }
    await ForeignCitizenService.createOrUpdateWorkingVersion(data)
        .then((res) => {
          let route = Routes.ASYLUM_SEEKERS_EDIT;
          if (res.workingVersionRegistrationId?.id) {
            route = route.replace(":id", res?.workingVersionRegistrationId?.id.toString());
            localStorage.setItem("workingVersionId", res?.workingVersionRegistrationId?.id.toString())
            setWorkingVersionId(res?.workingVersionRegistrationId?.id)
          }
          showSuccessDialog(strings.successfullyAddedWorkingVersionAsyl, strings.successfullyAddedWorkingVersionAsyl, strings.ok).then(_ => {
            setIsLoadingSave(false)
            if (res?.versions) {
              setVersions(res?.versions)
              setVersion(res?.versions[0]?.id.toString() || '')
              navigate(route)
            }
          });
        })
        .catch((e) => {
          const errorMessage = ErrorHandler.parseErrorMessage(e);
          showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
          setActiveStep(steps.length - 1)
        });
  }

  function moveToForeigner(data: RegistrationDTO | undefined) {
    let route = Routes.APPLICATION
    navigate(route, { state: { data, flag: true } })
  }
  const isAnyFieldEntered = formikRef.current?.values?.firstName ||  formikRef.current?.values?.lastName ||  formikRef.current?.values?.archiveNumber;

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    window.scrollTo({top: 100, left: 0, behavior: 'smooth'});
  }, []);

  return (
      <div>
        {isLoadingData &&
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoadingData}
            >
              <CircularProgress size={60} style={{ color: "#3f51b5" }} />
            </Backdrop>
        }
        <SideBarPage pageTitle={strings.applicationForAsylum} component={
          <>
            {!isAddMode && <Box component="div" display={"flex"} justifyContent={"space-between"} className="mt-3 mb-4">
              {registrationData?.submitted ? <Box component="span" fontWeight={"bold"} textTransform={"uppercase"} display={"flex"} justifyContent={"start"}>
                Поднесена (официјална) верзија на регистрацијата
              </Box> : <LightTooltip customWidth='1000px' title={<div>
                Ве молиме преминете на поднесената (официјална) верзија, доколку сакате да:
                <ul>
                  <li>преземете акции поврзани со матичните броеви</li>
                  <li>пасивизирате/активирате барање</li>
                  <li>креирате одлука за регистрацијата и соодветно тужби/жалби за конкретна одлука</li>
                </ul>
              </div>}>
                <Box component="span" fontWeight={"bold"} textTransform={"uppercase"}>
                  Работна верзија на регистрацијата
                </Box>
              </LightTooltip>}
              <div style={{ float: 'right' }}>
                {hasAuthority && registrationData?.submitted && (!registrationData?.passivated ? (
                            <Button style={{ marginRight: '10px' }} variant='contained' onClick={handleOpenRequest}>{strings.passivize}</Button>
                        ) :
                        (
                            <Button style={{ marginRight: '10px' }} variant='contained' onClick={handleOpenActivateRequest}>{strings.activateRequest}</Button>
                        )
                )}
                {registrationData?.submitted && copyToForeignCitizen && registrationData?.registrationType?.mvrKey === NomenclatureConstants.asylumMvrKey &&
                    <Button variant='contained' onClick={() => moveToForeigner(registrationData ? registrationData : undefined)}>{strings.moveAsylumToForeigner}</Button>
                }
              </div>
            </Box>}
            {!isAddMode &&
                <Box component="div" display="flex" justifyContent="start" className='pb-4'>
                  <FormControl sx={{ minWidth: 300 }}>
                    <InputLabel id="typeOfForms">{strings.version}</InputLabel>
                    <Select value={versions && versions.length > 0 && version && versions.some(el => el.id.toString() == version) ? String(version) : ""} onChange={handleChange} labelId="versionOfForms" label={strings.version} style={{ textAlign: "left" }} defaultValue=''
                            MenuProps={{
                              classes: {
                                paper: classes.dropdown,
                              },
                            }}
                    >
                      {versions?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {DateTimeUtils.formatDate(item?.dateCreated.toString()) + " - "}
                            {item?.archiveNumber ?
                                item?.archiveNumber :
                                ""}

                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>}
            {!isAddMode && (
                <CreatedByInfo
                    label={strings.statusRegistration + ":"}
                    status={registrationData?.passivated ? strings.passive : strings.activeStatus}
                    statusSuccessful={!registrationData?.passivated}
                />
            )}

            {!isAddMode && registrationData && (
                <Accordion style={{ width: "48%" }}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                  >
                    <Typography>Членови на семејството и статус на нивните матични броеви</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 500 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{strings.firstName}</TableCell>
                            <TableCell>{strings.lastName}</TableCell>
                            <TableCell>{strings.claimHolder}</TableCell>
                            <TableCell>{strings.embg}</TableCell>
                            <TableCell>{strings.statusEmbg}</TableCell>
                            <TableCell>{strings.deceased}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{
                                registrationData?.foreignCitizen?.personData?.firstName
                            }</TableCell>
                            <TableCell>{
                              registrationData?.foreignCitizen?.personData?.lastName
                              }</TableCell>
                            <TableCell>{
                                registrationData?.foreignCitizen?.personData?.firstName + " " +
                                registrationData?.foreignCitizen?.personData?.lastName
                            }</TableCell>
                            <TableCell>{registrationData?.embg}</TableCell>
                            <TableCell>{registrationData?.registrationStatus?.name ?? strings.noInformation}</TableCell>
                            <TableCell>{registrationData?.deadInfo !== null ? registrationData.deadInfo : strings.noInformation}</TableCell>

                          </TableRow>
                          {registrationData?.childRegistrations?.map((row,index) => (
                              <TableRow
                                  key = {index}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {
                                      row.foreignCitizen.personData.firstName + " " + row.foreignCitizen.personData.lastName
                                  }
                                </TableCell>
                                <TableCell>{row.parentRegistrationReducedDTO?.foreignCitizen.personData.firstName + " " + row.parentRegistrationReducedDTO?.foreignCitizen.personData.lastName}</TableCell>
                                <TableCell>{row.embg}</TableCell>
                                <TableCell>{row.registrationStatus?.name}</TableCell>
                                <TableCell>{row.deadInfo !== null ? row.deadInfo : strings.noInformation}</TableCell>
                              </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
            )}
            {!isAddMode &&
                <div className='d-flex pb-4 pt-3'>
                  {strings.workingUnit + ":" + " " + userDetails?.workingUnit}
                </div>
            }
            <div style={{ float: "left", display: "flex" }}>
              {!isAddMode && id && hasAuthority && registrationData?.submitted && registrationData?.isAtLeastOneActive() &&
                  <div>
                    <Button variant='contained' className='m-2' onClick={handleOpen}>{strings.passivateEMBG}</Button>
                    <Button variant='contained' className='m-2' onClick={handleRetireEMBGOpen}>{strings.onHoldEMBG}</Button>
                  </div>
              }
              {
                  !isAddMode &&
                  <div>
                    <Button className='m-2' type="submit" variant="contained" color="primary" onClick={handleClickOpen}>{strings.historyOfRegistrationStatuses}</Button>
                    <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={"md"} fullWidth
                    >
                      <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
                        {strings.historyOfRegistrationStatuses}
                      </DialogTitle>
                      <DialogContent>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 500 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell> {strings.action}</TableCell>
                                <TableCell> {strings.reason}</TableCell>
                                <TableCell> {strings.dateModified}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                historyActions?.registrationOrEmbgHistoryActions?.map((item: any, index: number) => {
                                  return (
                                      <TableRow
                                          key={index}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      >
                                        <TableCell>{item.action}</TableCell>
                                        <TableCell>{item.reason}</TableCell>
                                        <TableCell>{DateTimeUtils.formatDateObject(item.dateModified)}</TableCell>
                                      </TableRow>
                                  )
                                })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </DialogContent>
                      <DialogActions>
                        <Button className='m-2' type="submit" variant="contained" color="error" onClick={handleCloseDialog}>{strings.close}</Button>
                      </DialogActions>
                    </Dialog>

                    <Button className='m-2' type="submit" variant="contained" color="primary" onClick={handleClickOpen2}>{strings.historyOfEmbgs}</Button>
                    <Dialog
                        open={openDialog2}
                        onClose={handleCloseDialog2}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={"md"} fullWidth
                    >
                      <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
                        {strings.historyOfEmbgs}
                      </DialogTitle>
                      <DialogContent>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 500 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell> {strings.firstName}</TableCell>
                                <TableCell> {strings.embg}</TableCell>
                                <TableCell> {strings.action}</TableCell>
                                <TableCell> {strings.reason}</TableCell>
                                <TableCell> {strings.dateModified}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                historyActionsEMBG?.mainRegistration.registrationOrEmbgHistoryActions?.map((item: any, index: number) => {
                                  return (
                                      <TableRow
                                          key={`${item.embg}-${index}`}
                                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      >
                                        <TableCell>{historyActionsEMBG?.mainRegistration?.firstName + " " + historyActionsEMBG.mainRegistration.lastName}</TableCell>
                                        <TableCell>{item.embg}</TableCell>
                                        <TableCell>{item.action}</TableCell>
                                        <TableCell>{item.reason}</TableCell>
                                        <TableCell>{DateTimeUtils.formatDateObject(item.dateModified)}</TableCell>
                                      </TableRow>
                                  )
                                })
                              }
                              {
                                historyActionsEMBG?.childRegistrations?.map((item: any, index: number) => {
                                  return (
                                      item?.registrationOrEmbgHistoryActions?.map((elem: any, indexChild:number) => {
                                        return (
                                            <TableRow
                                                key={`${elem.embg}-${indexChild}`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                              <TableCell>{item.firstName + " " + item.lastName}</TableCell>
                                              <TableCell>{elem.embg}</TableCell>
                                              <TableCell>{elem.action}</TableCell>
                                              <TableCell>{elem.reason}</TableCell>
                                              <TableCell>{DateTimeUtils.formatDate(elem.dateModified)}</TableCell>
                                            </TableRow>
                                        )

                                      })
                                  )
                                })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </DialogContent>
                      <DialogActions>
                        <Button className='m-2' type="submit" variant="contained" color="error" onClick={handleCloseDialog2}>{strings.close}</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
              }

              {!isAddMode && id && hasAuthority && registrationData?.submitted && (!(registrationData?.isOnHoldAndAllChildrenOnHold() ?? false) || !(registrationData?.isActiveAndAllChildrenActive() ?? false)) && (registrationData?.isAtLeastOneWithGeneratedEmbg() ?? false) &&
                  <Button disabled={registrationData?.isActiveAndAllChildrenActive()} style={{ float: "left" }} variant='contained' className='m-2' onClick={handleActivateEMBGOpen}>{strings.activateEmbg}</Button>
              }
            </div>
            <AutoScroller style={{float: "right", marginBottom: "20px"}} direction={false} />

            <div>
            </div>
            {isLoadingSave && (
                <CircularProgress
                    style={{float: "right"}} className={"mb-2"}
                    size={32}
                />
            )
            }
            {!registrationData?.submitted && !state?.flag && <Button style={{float:"right", marginRight: "5px"}} className='mb-2' disabled={isLoadingSave || !!disabledSaveButton} type="submit" variant="contained" color="primary" onClick={createOrUpdateWorkingVersion}>{strings.save}</Button>}
            <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"}>
              <div style={{fontSize:"12px", color:"red"}}>Формата содржи задолжителни полиња на 1-та и 3-та страница означени со ѕвезда (*)</div>
              <React.Fragment>
                <Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
                  {steps.map((step, index) => (
                      <Step key={step.name} completed={step.completed}>
                        <StepButton onClick={() => handleStepValidation(index)}> <div style={{ fontWeight: activeStep === index ? 'bold' : 'normal' }}>{step.name}</div></StepButton>
                      </Step>
                  ))}
                </Stepper>
                <React.Fragment>
                  {isLoading ? (
                          <div className="mt-3" style={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress
                                size={40}
                            /></div>
                      ) :
                      activeStep === steps.length ? (
                          <div className='p-3'>

                          </div>
                      ) : (
                          <Formik
                              innerRef={formikRef}
                              initialValues={lastSubmittedValues || FormInitialValues || {}}
                              validationSchema={currentValidationSchema}
                              onSubmit={handleSubmit}
                          >
                            {({ isSubmitting }) => (
                                <Form id={formId}>
                                  {renderStepContent(activeStep)}

                                  <div className={classes.buttons}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} className={classes.button}>
                                          Претходна
                                        </Button>
                                    )}
                                    <div className={classes.wrapper}>
                                      {isLastStep && !hasAuthority ? <Box /> : <Button
                                          disabled={isSubmitting}
                                          type="submit"
                                          variant="contained"
                                          color="primary"
                                          className={classes.button}
                                      >
                                        {isLastStep ? strings.end : strings.next}
                                      </Button>}
                                      {(isSubmitting || isLoading) && (
                                          <CircularProgress
                                              size={24}
                                              className={classes.buttonProgress}
                                          />
                                      )}
                                    </div>
                                  </div>
                                </Form>
                            )}
                          </Formik>
                      )}
                </React.Fragment>
              </React.Fragment>
            </CenteredCardLayout>
            {!isAddMode && id && <RightSidePanel hasAuthority={hasAuthority} asylumForm={true} id={parseInt(id)} templateDTOs={asylumSeekersTemplates}
                                                 registration={registrationData} updateChildReg={pullChildRegistrations} workingVersionId={workingVersionId}/>}
            {/* templates are hardcoded */}
            {registrationData && id && <EMBGModal open={open} handleClose={handleClose} registrationData={registrationData} isForeign={false} isAsylum={true} isPasivise={true} />}
            <EMBGModal open={openRetireEMBGModal} handleClose={handleRetireEMBGModalClose} registrationData={registrationData} isForeign={false} isAsylum={true} isOnHold={true} />
            <EMBGModal open={openActivateEMBGModal} handleClose={handleActivateEMBGModalClose} registrationData={registrationData} isForeign={false} isAsylum={true} isActivate={true} />
            <DeceasedModal open={openDeceasedModal} handleClose={handleCloseDeceasedModal} registrationData={registrationData} isForeign={false} isAsylum={true} isDeceased={true}/>

            <ConfirmationModal
                open={openActivateRequest}
                handleClose={() => handleCloseActivateRequest(false)}
                handleConfirm={activateRequest}
                title={strings.activateRequest}
                content={strings.activateRequestConfirm}
                actionButtonName={strings.activate}
                variant={"contained"}
                color={"success"}

            />
            <EMBGModal open={openRequest} handleClose={handleCloseRequest} isPassivateRequest={true} isAsylum={true} isForeign={false} />
            <ConfirmationModal
                open={openCheckModal}
                handleClose={handleCloseCheckModal}
                handleConfirm={() => {
                  setOpenCheckModal(false);
                  editAsylumForm(values);
                }}
                title={strings.saveChanges}
                content={isPasivise ? strings.checkBirthDateAndGender : strings.notPassivatedcheckBirthDateAndGender}
                actionButtonName={strings.submit}
                variant={"contained"}
                color={"success"}
                isPassive={isPasivise}
            />
            <ConfirmationModal
                open={openBasicDataChangesModal}
                handleClose={handleCloseBasicDataChangesModal}
                title={strings.saveChanges}
                content={
                  <div>
                    {strings.correctionChangeMessage}
                    <div className="d-flex justify-content-between mt-3">
                      <Button onClick={() => {handleClickChange()}}>{strings.change}</Button>
                      <Button onClick={() => {handleClickCorrection()}}>{strings.correction}</Button>
                    </div>
                  </div>
                }
                variant={"contained"}
                color={"success"}
            />
            <AutoScroller style={{float: "right", marginTop: "20px"}} direction={true}/>

          </>
        }

        />

      </div>

  )
}
