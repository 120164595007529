export class Privileges {
    static readonly MANAGE_NOMENCLATURES = "UPRAVUVA_NOMENKLATURI";
    static readonly READ_FOREIGN_CITIZEN = "CITA_STRANEC";
    static readonly MANAGE_FOREIGN_CITIZEN = "UPRAVUVA_STRANEC";
    static readonly READ_ASYLUM_REQUEST = "CITA_AZIL";
    static readonly MANAGE_ASYLUM_REQUEST = "UPRAVUVA_AZIL";
    static readonly READ_SHORT_TERM_STAY = "CITA_KRATKOROCEN_PRESTOJ";
    static readonly MANAGE_SHORT_TERM_STAY = "UPRAVUVA_KRATKOROCEN_PRESTOJ";
    static readonly READ_SENSITIVE_DATA_ASYLUM = "CITA_SENZITIVNI_PODATOCI_AZIL";
    static readonly READ_REPORTS_ASYLUM = "CITA_IZVESTAI_AZIL"; //use only on backend
    static readonly READ_REPORTS_FOREIGN_CITIZEN = "CITA_IZVESTAI_STRANCI"; //use only on backend
    static readonly READ_REPORTS_SHORT_TERM_STAY = "CITA_IZVESTAI_KRATKOROCEN_PRESTOJ"; //use only on backend
    static readonly MANAGE_DIPLOMAT = "UPRAVUVA_DIPLOMAT";
}
