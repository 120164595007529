import IconButton from '@mui/material/IconButton';
import { Close } from "@mui/icons-material";
import { MenuItem, Select, FormControl, InputLabel, MenuProps } from "@mui/material";


export function CustomSelect(props: any) {

    function getCustomMenuProps(): Partial<MenuProps> {
        return {
            PaperProps: {
                style: {
                    width: "37%"
                },
            },
        };
    }

    return (
        <>
            <FormControl className="relative" style={{ width: "102%" }}>
                <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
                <Select
                    fullWidth
                    endAdornment={
                        <>
                            {
                                props.value && <IconButton onClick={props.handleRemoveFilter} style={{ marginRight: "15px", height: "15px" }}>
                                    <Close fontSize="small" />
                                </IconButton>
                            }
                        </>
                    }
                    name={String(props.id)}
                    labelId="demo-simple-select-label"
                    label={props.label}
                    disabled={props.disabled}
                    sx={props.styling}
                    onChange={props.handleChange}
                    value={props.value ? props.value.toString() : ""}
                    defaultValue={""}
                    variant={props.variant}
                    MenuProps={getCustomMenuProps()}
                    SelectDisplayProps={{
                        style: {
                            minWidth: 100,
                            maxWidth: 220
                        }
                    }}
                >
                    {props.elements.map((element: any) => (
                        <MenuItem key={element.id} value={element.id} disabled={element.id === 0}>{element.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}
