import { FileDTO } from "./FileDTO";

export class ResolutionDTO {
    id: number;
    dateHanded: Date | string;
    opinionPositive: boolean;
    resolutionFile?: FileDTO;
    entranceRestrictionDateFrom: Date | string;
    entranceRestrictionDateTo: Date | string;
    evidencyDate: Date | string;
    isChecked: boolean;
    constructor(json:ResolutionDTO){
        this.id = json.id;
        this.dateHanded = json.dateHanded;
        this.opinionPositive = json.opinionPositive;
        this.resolutionFile = json.resolutionFile ? new FileDTO(json.resolutionFile) : undefined;
        this.entranceRestrictionDateFrom = json.entranceRestrictionDateFrom;
        this.entranceRestrictionDateTo = json.entranceRestrictionDateTo;
        this.evidencyDate = json.evidencyDate;
        this.isChecked = json.isChecked;
    }
}