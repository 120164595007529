import {Page} from "../../components/Page";
import {FormEvent, useState} from "react";
import {AppController} from "../../controllers/AppController";
import {useNavigate} from "react-router-dom";
import {Localization, strings} from "../../localization/Localization";
import {Button, TextField, Typography} from "@mui/material";
import {CenteredCardLayout} from "../../components/CenteredCardLayout";
import {VisibilityEye} from "../../components/VisibilityEye";
import mvrLogo from "../../assets/mvr.png";
import {SideBarPage} from "../../components/SideBarPage";

export function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    async function login(event: FormEvent): Promise<void> {
        event.preventDefault();
        try {
            return await AppController.login(navigate, email, password);
        } catch (e) {
            alert('Invalid username or password.');
        }
    }

    function setLanguage(language: string): void {
        Localization.setLanguage(language);
    }

    return (
        <SideBarPage pageTitle="Login"
              component={
                  <CenteredCardLayout minWidth={"35%"} maxWidth={"35%"} className={"mt-5"}>
                      <form onSubmit={login}>
                          <div className={"d-flex justify-content-md-center align-items-center"}>
                              <img src={mvrLogo} className={"logo h-50 w-50"}/>
                          </div>
                          <Typography variant={"body1"} className="GIGA d-flex justify-content-md-center align-items-center mb-2 fw-bold" style={{fontSize: "13px"}}>
                              {strings.welcome}
                          </Typography>
                          <Typography className="d-flex justify-content-md-center align-items-center mb-2 fw-bold" style={{fontSize: "12px"}}>
                              {strings.mvr}
                          </Typography>
                          <div>
                              <div className="mt-3 mb-3 d-flex justify-content-md-center align-items-center">
                                  <Button variant={"contained"} className="btn-sm" onClick={() => setLanguage(Localization.LANGUAGE_EN)}>EN</Button>
                                  <Button variant={"contained"} className="btn-sm mx-2" onClick={() => setLanguage(Localization.LANGUAGE_MK)}>MK</Button>
                                  <Button variant={"contained"} className="btn-sm">SQ</Button>
                              </div>
                              <div>
                                  <Button variant="contained" className="btn-round w-100 mt-3 btn btn-primary"
                                          type="submit" onClick={login}>{strings.login}</Button>
                              </div>
                          </div>
                      </form>
                  </CenteredCardLayout>
              }
        />
    );
}
