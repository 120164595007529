import React, { ReactElement, useEffect, useState } from "react";
import { Guard } from "../router/guards/Guard";

export interface GuardedComponentProps {
  component: ReactElement;
  guards: Guard[];
  fallback: ReactElement;
}

export function GuardedComponent(props: GuardedComponentProps) {
  const [renderComponent, setRenderComponent] = useState<ReactElement | null>(
    null
  );

  useEffect(() => {
    async function checkGuards() {
      for (const guard of props.guards) {
        const guardCheckResult = await guard.check();
        if (!guardCheckResult) {
          setRenderComponent(props.fallback);
          return;
        }
      }

      setRenderComponent(props.component);
    }

    checkGuards();
  }, [props.component, props.fallback, props.guards]);

  return renderComponent;
}