import * as Yup from 'yup';
import FormModel from './FormModel';
import { NomenclatureConstants } from '../../../constants/NomenclatureConstants';
const {
  formField: {

    //region 1
    evidencyReasonMvrKey,
    placeOfRequest,
    lastName,
    firstName,
    genderMvrKey,
    birthDate,
    citizenShip,
    inRSMBirthMunicipality,
    inRSMBirthPlacePlace,
    abroadBirthPlace,
    placeOfBirthAbroad,
    education,
    countryPreviousLiving,
    livingPlaceRSM,
    livingMunicipalityRSM,
    addressDateFrom,
    livingStreetRSM,
    livingStreetNumberRSM,
    lastNameCyrillic,
    firstNameCyrillic,

    //region 2
    arrivalDate,
    arrivalWay,
    passportTypeMvrKey,

    ///region 3 
    settlementReasonMvrKey,
    approvalDate,
  
 
  }
} = FormModel;


export default [
  Yup.object().shape({
    [evidencyReasonMvrKey.name]: Yup.string().required(`${evidencyReasonMvrKey.requiredErrorMsg}`),
    [placeOfRequest.name]: Yup.object().required(`${placeOfRequest.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [genderMvrKey.name]: Yup.string().required(`${genderMvrKey.requiredErrorMsg}`),
    [birthDate.name]: Yup.string().required(`${birthDate.requiredErrorMsg}`),
    [education.name]: Yup.string().required(`${education.requiredErrorMsg}`),
    [countryPreviousLiving.name]: Yup.mixed().required(`${countryPreviousLiving.requiredErrorMsg}`),
    [livingMunicipalityRSM.name]: Yup.mixed().required(`${livingMunicipalityRSM.requiredErrorMsg}`),
    [addressDateFrom.name]: Yup.string().required(`${addressDateFrom.requiredErrorMsg}`),
    [livingPlaceRSM.name]: Yup.mixed().required(`${livingPlaceRSM.requiredErrorMsg}`),
    [livingStreetRSM.name]: Yup.mixed().required(`${livingStreetRSM.requiredErrorMsg}`),
    [livingStreetNumberRSM.name]: Yup.string().required(`${livingStreetNumberRSM.requiredErrorMsg}`),
    [firstNameCyrillic.name]: Yup.string().required(`${firstNameCyrillic.requiredErrorMsg}`),
    [lastNameCyrillic.name]: Yup.string().required(`${lastNameCyrillic.requiredErrorMsg}`),
    [citizenShip.name]: Yup.mixed().transform((v) => !v ? undefined : v).required(`${citizenShip.requiredErrorMsg}`),
    [abroadBirthPlace.name]: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['placeOfBirthAbroad', 'inRSMBirthMunicipality', 'inRSMBirthPlacePlace'], {
      is: (placeOfBirthAbroadValue: any, inRSMBirthMunicipalityValue: any, inRSMBirthPlacePlaceValue: any) => (!placeOfBirthAbroadValue || placeOfBirthAbroadValue !== "") && !inRSMBirthMunicipalityValue && !inRSMBirthPlacePlaceValue,
      then: (schema) => schema.required(`${abroadBirthPlace.requiredErrorMsg}`),
    })),
    [placeOfBirthAbroad.name]: Yup.lazy(() => Yup.string().when(['abroadBirthPlace', 'inRSMBirthMunicipality', 'inRSMBirthPlacePlace'], {
      is: (abroadBirthPlaceValue: any, inRSMBirthMunicipalityValue: any, inRSMBirthPlacePlaceValue: any) => (!abroadBirthPlaceValue || abroadBirthPlaceValue !== "") && !inRSMBirthMunicipalityValue && !inRSMBirthPlacePlaceValue,
      then: (schema) => schema.required(`${placeOfBirthAbroad.requiredErrorMsg}`),
      otherwise: (schema) => schema.notRequired()
   
    })),
    [inRSMBirthMunicipality.name]: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['placeOfBirthAbroad', 'abroadBirthPlace', 'inRSMBirthPlacePlace'], {
      is: (placeOfBirthAbroadValue: any, abroadBirthPlaceValue: any, inRSMBirthPlacePlaceValue: any) => !placeOfBirthAbroadValue && !abroadBirthPlaceValue && (!inRSMBirthPlacePlaceValue || inRSMBirthPlacePlaceValue !== ""),
      then: (schema) => schema.required(`${inRSMBirthMunicipality.requiredErrorMsg}`),
      otherwise: (schema) => schema.notRequired()
    })),
    [inRSMBirthPlacePlace.name]: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['abroadBirthPlace', 'inRSMBirthMunicipality', 'placeOfBirthAbroad'], {
      is: (abroadBirthPlaceValue: any, inRSMBirthMunicipalityValue: any, placeOfBirthAbroadValue: any) => !abroadBirthPlaceValue && (!inRSMBirthMunicipalityValue || inRSMBirthMunicipalityValue !== "") && !placeOfBirthAbroadValue,
      then: (schema) => schema.required(`${inRSMBirthPlacePlace.requiredErrorMsg}`),
      otherwise: (schema) => schema.notRequired()
    })),
  }),

  Yup.object().shape({
    [arrivalDate.name]: Yup.string().required(`${arrivalDate.requiredErrorMsg}`),
    [arrivalWay.name]: Yup.string().required(`${arrivalWay.requiredErrorMsg}`),
    [passportTypeMvrKey.name]: Yup.string().required(`${passportTypeMvrKey.requiredErrorMsg}`),
  }),

  Yup.object().shape({
    [approvalDate.name]: Yup.string().required(`${approvalDate.requiredErrorMsg}`),
    [settlementReasonMvrKey.name]:Yup.string().nullable().default<string|null>(null).when("evidencyReasonMvrKey",{
      is: (value:string) => value && value === NomenclatureConstants.measureTakenKey,
      then: (schema) => schema.required(`${settlementReasonMvrKey.requiredErrorMsg}`)
    }),
  }),

  Yup.object().shape({

  }),
  
];
