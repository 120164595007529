import FormModel from './DiplomatsFormModel';
const {
  formField: {
    lastName,
    parentName,
    firstName,
    genderMvrKey,
    birthDate,
    abroadBirthPlace,
    inRSMBirthPlace,
    addressDateFrom,
    approvalDate,
    archiveDate,
    archiveNumber,
    birthPlace,
    citizenship,
    dateOfDeliveryOfDecision,
    dateOfExtension,
    decision,
    dossierNumber,
    embg,
    endDate,
    endDateStay,
    fileDecision,
    identityCardNumber,
    inRSMBirthMunicipality,
    inRSMBirthPlacePlace,
    livingApartmentEntranceRSM,
    livingApartmentRSM,
    livingMunicipalityRSM,
    livingPlaceOrStayRSM,
    livingPlaceRSM,
    livingStreetNumberRSM,
    livingStreetRSM,
    motherName,
    placeOfBirthAbroad,
    startDateStay,
    firstNameCyrillic,
    lastNameCyrillic,
    countryPassport,
    motherSurname,
    parentSurname,
    passportTypeMvrKey
  }
} = FormModel;

export default {
  [lastName.name]: '',
  [lastNameCyrillic.name]: '',
  [parentName.name]: '',
  [firstName.name]: '',
  [firstNameCyrillic.name]: '',
  [genderMvrKey.name]: '',
  [birthDate.name]: '',
  [abroadBirthPlace.name]: '',
  [inRSMBirthPlace.name]: '',
  [addressDateFrom.name]: '',
  [approvalDate.name]: '',
  [archiveDate.name]: '',
  [archiveNumber.name]: '',
  [birthPlace.name]: '',
  [citizenship.name]: '',
  [dateOfDeliveryOfDecision.name]: '',
  [dateOfExtension.name]: '',
  [decision.name]: '',
  [dossierNumber.name]: '',
  [embg.name]: '',
  [endDate.name]: '',
  [endDateStay.name]: '',
  [fileDecision.name]: '',
  [identityCardNumber.name]: '',
  [inRSMBirthMunicipality.name]: '',
  [inRSMBirthPlacePlace.name]: '',
  [livingApartmentEntranceRSM.name]: '',
  [livingApartmentRSM.name]: '',
  [livingMunicipalityRSM.name]: '',
  [livingPlaceOrStayRSM.name]: '',
  [livingPlaceRSM.name]: '',
  [livingStreetNumberRSM.name]: '',
  [livingStreetRSM.name]: '',
  [motherName.name]: '',
  [placeOfBirthAbroad.name]: '',
  [startDateStay.name]: '',
  [motherSurname.name]: '',
  [countryPassport.name]: '',
  [parentSurname.name]: '',
  [passportTypeMvrKey.name]: '',
  
};
