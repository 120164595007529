// @ts-nocheck
import './App.css';
import { RouterProvider } from 'react-router-dom';
import {CreateRouter} from './router/Router';
import { createTheme, ThemeProvider } from '@mui/material';
import { Localization } from './localization/Localization';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/global.css'
import React from "react";
import { Provider } from 'react-redux';
import store from './store';
import userManager from './util/auth/userManager';
import { OidcProvider } from 'redux-oidc';
import commonsConfiguration from "asseco-commons/dist/CommonsConfiguration";

const theme = createTheme({
  palette:{
      primary:{
          main : "#3f51b5"
      },
      secondary : {
          main : "#e4e4e2"
      }
  },
  typography:{
      fontFamily : 'Tahoma, sans-serif',
      fontSize : 13,
      fontWeightLight : 400,
      fontWeightRegular : 500,
      fontWeightMedium : 600,
      fontWeightBold : 700
  }
})
function App() {
  Localization.initialize();
    return (
    <Provider store={store}>
    <ThemeProvider theme = {theme}>
    <OidcProvider store={store} userManager={userManager}>
        <div className='App'>
            <RouterProvider router={CreateRouter()}/>
        </div>
    </OidcProvider>
    </ThemeProvider>
    </Provider>
  );
}

export const getToken = function() {
    return _.get(store.getState(), "oidc.user.access_token")
}

commonsConfiguration.setup({
    getToken: ()=>getToken(),
    translate: (text)=>translate(text)
});


export default App;
