export class AppVersionDTO{
     name : string;
     description: string;
     version: string
     buildTimestamp: string;

     constructor(json: AppVersionDTO) {
         this.name = json.name;
         this.description = json.description;
         this.version = json.version;
         this.buildTimestamp = json.buildTimestamp;
     }
}
