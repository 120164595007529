import { PlaceCreationDTO } from "./PlaceCreationDTO";

export class StreetCreationDTO{
    placeDTO?:PlaceCreationDTO;
    mvrKey:string | null;
    name:string | null;
    nameSq:string | null;
    nameEn:string | null;
    constructor(json:StreetCreationDTO){
        this.placeDTO = json.placeDTO ? new PlaceCreationDTO(json.placeDTO) : undefined;
        this.mvrKey = json.mvrKey;
        this.name =json.name;
        this.nameSq = json.nameSq;
        this.nameEn = json.nameEn;
    }
}