import { PlaceCreationDTO } from "./PlaceCreationDTO";

export class MilitaryServiceDTO{
    id:number;
    year: number;
    type:string;
    placeName?: string;
    constructor(json:MilitaryServiceDTO){
        this.id = json.id;
        this.year = json.year;
        this.type = json.type;
        this.placeName = json.placeName;
    }
}
