import {strings} from "../localization/Localization";
import {Button} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Routes} from "../router/Routes";

export function ErrorRoute() {
    const navigate = useNavigate();

    function goToHomePage(): void {
        navigate(Routes.HOME);
    }

    return (
        <div id="error-page" className="mt-3">
            <h1>{strings.errorRouteTitle}</h1>
            <p>{strings.noAccessToThisPage}</p>

            <Button variant={"contained"} sx={{ height: 40}} className="btn-sm" onClick={goToHomePage}>
                {strings.goToHomePage}
            </Button>
        </div>
    );
}
