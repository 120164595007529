import { strings } from "../../../localization/Localization";

export default {
    formId: "form",
    formField: {
      evidencyReasonMvrKey: {
        id:"",
        name: "evidencyReasonMvrKey",
        label: strings.evidencyReasonMvrKey,
        requiredErrorMsg: "Полето е задолжително",
        placeholder:  strings.evidencyReasonMvrKey + "*"
      },
      placeOfRequest:{
        id:"",
        name:"placeOfRequest",
        label:"Место на поднесување на барањето",
        requiredErrorMsg: "Полето е задолжително",
        placeholder:"Место на поднесување на барањето*",
      },
      temporaryStaySettlementReasonMvrKey: {
        id:"",
        name: "temporaryStaySettlementReasonMvrKey",
        label: strings.temporaryStaySettlementReasonMvrKey,
        requiredErrorMsg: "Полето е задолжително",
        placeholder:  strings.temporaryStaySettlementReasonMvrKey + "*"
      },
      temporaryStayReportedByMvrKey: {
        id:"",
        name: "temporaryStayReportedByMvrKey",
        label: strings.temporaryStayReportedByMvrKey,
        requiredErrorMsg: "Полето е задолжително",
        placeholder:  strings.temporaryStayReportedByMvrKey + "*"
      },
      lastName: {
        id:"1",
        name: "lastName",
        label: strings.lastName,
        placeholder: strings.lastName + "*",
        requiredErrorMsg: "Презимето е задолжително",
      },
      parentName: {
        id:"2",
        name: "parentName",
        label: strings.parentName,
        placeholder: strings.parentName,
        requiredErrorMsg: "Полето е задолжително"
      },
      firstName: {
        id:"3",
        name: "firstName",
        label: "Име",
        placeholder:strings.firstName + "*",
        requiredErrorMsg: "Името е задолжително"
      },
      genderMvrKey: {
        id:"4",
        name: "genderMvrKey",
        label: strings.gender,
        placeholder:strings.gender + "*",
        requiredErrorMsg: "Полето е задолжително",
      },
      birthDate: {
        id:"5",
        name: "birthDate",
        label: "Датум на раѓање",
        placeholder:"Датум на раѓање*",
        requiredErrorMsg: "Датумот на раѓање е задолжителен",
      },
      birthPlace: {
        id:"6",
        name: "birthPlace",
        label: "Место на раѓање",
        placeholder:"Место на раѓање",
        requiredErrorMsg: "Полето е задолжително",
      },
      abroadBirthPlace:{
        id:"6.1",
        name: "abroadBirthPlace",
        label: "Во странство",
        placeholder:"Во странство",
        requiredErrorMsg: "Полето е задолжително",
      },
      inRSMBirthPlace:{
        id:"6.2",
        name: "inRSMBirthPlace",
        label: "Во РСМ",
        placeholder:"Назив на местото, општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      inRSMBirthMunicipality:{
        id:"6.2.1",
        name: "inRSMBirthMunicipality",
        label: "Општина",
        placeholder:"Општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      inRSMBirthPlacePlace:{
        id:"6.2.2",
        name: "inRSMBirthPlacePlace",
        label: "Населено место",
        placeholder:"Населено место",
        requiredErrorMsg: "Полето е задолжително",
      },
      passportTypeMvrKey:{
        id:"7",
        name: "passportTypeMvrKey",
        label: "Вид на патен документ",
        placeholder:"Назив на патниот документ",
        requiredErrorMsg: "Полето е задолжително",
      },
      countryPassport:{
        id:"8",
        name: "countryPassport",
        label: "Држава која го издала патниот документ",
        placeholder:"Назив на државата",
        requiredErrorMsg: "Полето е задолжително",
      },
      passportNumber:{
        id:"9",
        name: "passportNumber",
        label: "Број на патен документ",
        placeholder:"Број на патен документ",
        requiredErrorMsg: "Полето е задолжително",
      },
      visaTypeMvrKey:{
        id:"10",
        name: "visaTypeMvrKey",
        label: "Вид на виза",
        placeholder: "Назив на виза",
        requiredErrorMsg: "Полето е задолжително",
      },
      countryVisa:{
        id: "11",
        name: "countryVisa",
        label: "Држава која ја издала визата",
        placeholder:"Назив на државата",
        requiredErrorMsg: "Полето е задолжително"
      },
      visaNumber:{
        id:"12",
        name: "visaNumber",
        label: "Број на виза",
        placeholder:"Број на виза",
        requiredErrorMsg: "Полето е задолжително",
      },
      dateAndPlaceOfBorderCrossing:{
        id:"13",
        name: "dateAndPlaceOfBorderCrossing",
        label: "Место и датум на преминување граница",
        placeholder:"Место и датум на преминување граница",
        requiredErrorMsg: "Полето е задолжително",
      },
      borderPointMvrKey:{
        id:"13.1",
        name: "borderPointMvrKey",
        label: "Место на преминување граница",
        placeholder:"Место на преминување граница",
        requiredErrorMsg: "Полето е задолжително",
      },
      entranceDate:{
        id:"13.2",
        name: "entranceDate",
        label: "Датум на преминување граница",
        placeholder:"Датум на преминување граница",
        requiredErrorMsg: "Полето е задолжително",
      },
      //STRANA 2 START
      privateVisitObject: {
        id: "14",
        name: "privateVisitObject",
        label: "Објект на краткорочен престој",
        requiredErrorMsg: "Полето е задолжително",
      },
      shortTermStayPossibilityMvrKey: {
        id: "14.1",
        name: "shortTermStayPossibilityMvrKey",
        label: "Тип на објект*",
        requiredErrorMsg: "Полето е задолжително",
      },
      privateVisitPerson:{
        id:"15",
        name: "privateVisitPerson",
        label: " Лице кај кого престојува во посета во краткорочен престој",
        // placeholder:" Лице кај кого престојува во посета во краткорочен престој",
        requiredErrorMsg: "Полето е задолжително",
      },
      embgPrivateVisitPerson:{
        id:"15.1",
        name: "embgPrivateVisitPerson",
        label: "Матичен број",
        placeholder:"Матичен број",
        requiredErrorMsg: "Полето е задолжително",
      },
      lastNamePrivateVisitPerson:{
        id:"15.2",
        name: "lastNamePrivateVisitPerson",
        label: "Презиме",
        placeholder:"Презиме",
        requiredErrorMsg: "Полето е задолжително",
      },
      // lastNamePrivateVisitPersonCyrillic:{
      //   id:"",
      //   name: "lastNamePrivateVisitPersonCyrillic",
      //   label: "Презиме (кирилица)",
      //   placeholder:"Презиме (кирилица)",
      //   requiredErrorMsg: "Полето е задолжително",
      // },
      firstNamePrivateVisitPerson:{
        id:"15.3",
        name: "firstNamePrivateVisitPerson",
        label: "Име",
        placeholder:"Име",
        requiredErrorMsg: "Полето е задолжително",
      },
      // firstNamePrivateVisitPersonCyrillic:{
      //   id:"15.3",
      //   name: "firstNamePrivateVisitPersonCyrillic",
      //   label: "Име (кирилица)",
      //   placeholder:"Име (кирилица)",
      //   requiredErrorMsg: "Полето е задолжително",
      // },
      genderPrivateVisitPerson:{
        id:"15.4",
        name: "genderPrivateVisitPerson",
        label: "Пол",
        placeholder:"Пол",
        requiredErrorMsg: "Полето е задолжително",
      }, 
      citizen:{
        id:"15.5",
        name: "citizen",
        label: "Државјанин",
        placeholder:"Државјанин",
        requiredErrorMsg: "Полето е задолжително",
      },
      addressPrivateVisitPerson:{
        id:"16",
        name: "addressPrivateVisitPerson",
        label: "Адреса на лицето кај кого престојува во посета",
        placeholder:"Адреса на лицето кај кого престојува во посета",
        requiredErrorMsg: "Полето е задолжително",
      },
      hotel:{
        id:"16.1",
        name: "hotel",
        label: "Име на хотел",
        placeholder:"Хотел",
        requiredErrorMsg: "Полето е задолжително",
      },
      municipalityPrivateVisitPerson:{
        id:"16.2",
        name: "municipalityPrivateVisitPerson",
        label: "Општина",
        placeholder:"Општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      placePrivateVisitPerson:{
        id:"16.3",
        name: "placePrivateVisitPerson",
        label: "Населено место",
        placeholder:"Населено место",
        requiredErrorMsg: "Полето е задолжително",
      },
      streetPrivateVisitPerson:{
        id:"16.4",
        name: "streetPrivateVisitPerson",
        label: "Улица",
        placeholder:"Улица",
        requiredErrorMsg: "Полето е задолжително",
      },
      numberStreetPrivateVisitPerson:{
        id:"16.5",
        name: "numberStreetPrivateVisitPerson",
        label: "Број",
        placeholder:"Број",
        requiredErrorMsg: "Полето е задолжително",
      },
      apartmentEntrancePrivateVisitPerson:{
        id:"16.6",
        name: "apartmentEntrancePrivateVisitPerson",
        label: "Влез",
        placeholder:"Влез",
        requiredErrorMsg: "Полето е задолжително",
      },
      apartmentPrivateVisitPerson:{
        id:"16.7",
        name: "apartmentPrivateVisitPerson",
        label: "Стан",
        placeholder:"Стан",
        requiredErrorMsg: "Полето е задолжително",
      },
      pastAddressPrivateVisitPerson:{
        id:"17",
        name: "pastAddressPrivateVisitPerson",
        label: "Адреса на лицето кај кого престојувало во посета",
        placeholder:"Адреса на лицето кај кого престојувало во посета",
        requiredErrorMsg: "Полето е задолжително",
      },
      pastMunicipalityPrivateVisitPerson:{
        id:"17.1",
        name: "pastMunicipalityPrivateVisitPerson",
        label: "Општина",
        placeholder:"Општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      pastPlacePrivateVisitPerson:{
        id:"17.2",
        name: "pastPlacePrivateVisitPerson",
        label: "Населено место",
        placeholder:"Населено место",
        requiredErrorMsg: "Полето е задолжително",
      },
      pastStreetPrivateVisitPerson:{
        id:"17.3",
        name: "pastStreetPrivateVisitPerson",
        label: "Улица",
        placeholder:"Улица",
        requiredErrorMsg: "Полето е задолжително",
      },
      pastNumberStreetPrivateVisitPerson:{
        id:"17.4",
        name: "pastNumberStreetPrivateVisitPerson",
        label: "Број",
        placeholder:"Број",
        requiredErrorMsg: "Полето е задолжително",
      },
      pastApartmentEntrancePrivateVisitPerson:{
        id:"17.5",
        name: "pastApartmentEntrancePrivateVisitPerson",
        label: "Влез",
        placeholder:"Влез",
        requiredErrorMsg: "Полето е задолжително",
      },
      pastApartmentPrivateVisitPerson:{
        id:"17.6",
        name: "pastApartmentPrivateVisitPerson",
        label: "Стан",
        placeholder:"Стан",
        requiredErrorMsg: "Полето е задолжително",
      },
      approvedStayDate:{
        id:"18",
        name: "approvedStayDate",
        label: "Престој одобрен до",
        placeholder:"Престој одобрен до",
        requiredErrorMsg: "Полето е задолжително",
      },
      registration:{
        id:"19",
        name: "registration",
        label: "Пријава",
        placeholder:"Пријава",
        requiredErrorMsg: "Полето е задолжително",
      },
      timeStart:{
        id:"19.1",
        name: "timeStart",
        label: "Датум на пријавувањето",
        placeholder:"Датум на пријавувањето",
        requiredErrorMsg: "Полето е задолжително",
      },
      timeEnd:{
        id:"19.2",
        name: "timeEnd",
        label: "Датум на одјавувањето",
        placeholder:"Датум на одјавувањето",
        requiredErrorMsg: "Полето е задолжително",
      },
      noteForeigner:{
        id:"20",
        name: "noteForeigner",
        label: "Забелешка",
        placeholder:"Забелешка",
        requiredErrorMsg: "Полето е задолжително",
      },
    }
  };
