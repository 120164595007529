import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../router/Routes";
import { SideBarPage } from "../../components/SideBarPage";
import { CenteredCardLayout } from "../../components/CenteredCardLayout";
import {
    Button,
    CardActions,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationModal from "../../components/ConfirmationModal";
import { NomenclatureService } from "../../services/NomenclatureService";
import { NomenclatureEntryDTO } from "../../models/nomenclatures/NomenclatureEntryDTO";
import { Controller, useForm } from "react-hook-form";
import { NomenclatureEntryCreationDTO } from "../../models/nomenclatures/NomenclatureEntryCreationDTO";
import { showErrorDialog, showSuccessDialog } from "../../common/Dialogues";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { strings } from "../../localization/Localization";
import { Privileges } from "../../models/nomenclatures/Privileges";
import { StorageService } from "../../storage/StorageService";
import { AuthService } from "../../services/AuthService";

export function NomenclatureDetails() {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [nomenclatureEntryData, setNomenclatureEntryData] =
        useState<NomenclatureEntryDTO | null>(null);
    const [hasAuthority, setHasAuthority] = useState<boolean>();
    let { id } = useParams();
    let { n_id } = useParams();
    const isAddMode = !id && n_id;
    const navigate = useNavigate();

    useEffect(() => {
        async function userHasAuthority(): Promise<void> {
          const userRoles: string[] | null = StorageService.getUserRoles();
          if(userRoles !== null) {
            if(userRoles.includes(Privileges.MANAGE_NOMENCLATURES))
            {
              setHasAuthority(true)
            }
          } else {
            const authorization = await AuthService.getAuthorization();
            if(authorization.roles.includes(Privileges.MANAGE_NOMENCLATURES)) {
              setHasAuthority(true)
            }
          }
        }
    
        userHasAuthority().then((_) => { });
    
      }, []);

    function onSubmit() {
        return isAddMode ? addNomenclatureEntry() : editNomenclatureEntry();
    }

    function handleBack() {
        navigate(-1);
    }

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: { errors },
    } = useForm<NomenclatureEntryCreationDTO>({
        defaultValues: {
            key: "",
            name: "",
            nameSq: "",
            nameEn: "",
            active: null,
        },
        mode: "onChange",
    });
    watch();

    useEffect(() => {
        function getNomenclatureEntryById(id: number) {
            NomenclatureService.getNomenclatureEntryById(id).then((res) => {
                setNomenclatureEntryData(res);
                setValue("key", res.key ?? "");
                setValue("name", res.name ?? "");
                setValue("nameSq", res.nameSq ?? "");
                setValue("nameEn", res.nameEn ?? "");
                setValue("active", res.active ?? null);
            });
        }

        async function getData() {
            if (id) {
                await getNomenclatureEntryById(parseInt(id));
            }
        }

        getData().then((_) => { });
    }, [id, setValue]);

    function refreshData() : any {
        async function getData() {
            if (id && !isAddMode) {
                await getValues("id");
            }
        }
        getData().then(_ => {
        });
    }

    async function deleteNomenclatureEntry(): Promise<void> {
        setOpenModal(false);

        if (nomenclatureEntryData === null) {
            return;
        }

        try {
            await NomenclatureService.deleteNomenclatureEntry(
                nomenclatureEntryData.id
            );
            showSuccessDialog(
                strings.deleteNomenclatureEntrySuccess,
                strings.deleteNomenclatureEntrySuccess,
                strings.ok
            ).then((_) => {
                navigate(Routes.NOMENCLATURES);
            });
        } catch (e: any) {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.fail, errorMessage, strings.ok).then((_) => { });
        }
    }

    function addNomenclatureEntry() {
        const nomenclatureEntry = new NomenclatureEntryCreationDTO(
            undefined,
            getValues("key"),
            getValues("name"),
            getValues("nameSq"),
            getValues("nameEn"),
            getValues("active")
        );

        NomenclatureService.createNomenclature(nomenclatureEntry, +n_id!)
            .then((res) => {
                let route = Routes.NOMENCLATURE_DETAILS;
                route = route.replace(":id", res.id?.toString());
                showSuccessDialog(
                    strings.addNomenclatureEntrySuccess,
                    strings.addNomenclatureEntrySuccess,
                    strings.ok
                ).then((_) => {
                    navigate(route);
                });
            })
            .catch((e) => {
                const errorMessage = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.fail, errorMessage, strings.ok).then((_) => { });
            });
    }

    function editNomenclatureEntry() {
        const nomenclatureEntry = new NomenclatureEntryCreationDTO(
            getValues("id"),
            getValues("key"),
            getValues("name"),
            getValues("nameSq"),
            getValues("nameEn"),
            getValues("active")
        );

        if (nomenclatureEntryData === null) {
            return;
        }

        NomenclatureService.editNomenclatureEntry(
            nomenclatureEntry,
            nomenclatureEntryData.id
        )
            .then(() => {
                showSuccessDialog(
                    strings.editNomenclatureEntrySuccessful,
                    strings.editNomenclatureEntrySuccessful,
                    strings.ok
                ).then((_) => {
                    refreshData();
                });
            })
            .catch((e) => {
                const errorMessage = ErrorHandler.parseErrorMessage(e);
                showErrorDialog(strings.fail, errorMessage, strings.ok).then((_) => { });
            });
    }

    function handleCancel(): void {
        navigate(Routes.NOMENCLATURES, {});
    }

    function handleClickOpen(): any {
        setOpenModal(true);
    }

    function handleClose(): any {
        setOpenModal(false);
    }

    return (
        <SideBarPage
            pageTitle={
                isAddMode ? strings.addNomenclatureEntry : strings.editNomenclatureEntry
            }
            component={
                <>
                    <CenteredCardLayout>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {/*<Typography className="mb-3" style={{fontSize:"20px"}}>{isAddMode ? strings.addNomenclatureEntry : strings.editNomenclatureEntry}</Typography>*/}
                            <Controller
                                name="key"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        value={field.value !== undefined ? field.value : ""}
                                        id={"name"}
                                        label={strings.key}
                                        className={"w-100"}
                                        style={{ marginBottom: 15 }}
                                        error={errors.key !== undefined}
                                        InputProps={{
                                            readOnly: hasAuthority ? false : true,
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                name="name"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        value={field.value}
                                        id={"name"}
                                        label={strings.labelMk}
                                        className={"w-100"}
                                        style={{ marginBottom: 15 }}
                                        error={errors.name !== undefined}
                                        InputProps={{
                                            readOnly: hasAuthority ? false : true,
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                name="nameSq"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        value={field.value}
                                        id={"name"}
                                        label={strings.labelAlb}
                                        className={"w-100"}
                                        style={{ marginBottom: 15 }}
                                        error={errors.nameSq !== undefined}
                                        InputProps={{
                                            readOnly: hasAuthority ? false : true,
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                name="nameEn"
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        value={field.value}
                                        id={"name"}
                                        label={strings.labelEn}
                                        className={"w-100"}
                                        style={{ marginBottom: 15 }}
                                        error={errors.nameEn !== undefined}
                                        InputProps={{
                                            readOnly: hasAuthority ? false : true,
                                        }}
                                    />
                                )}
                            />

                            <FormControlLabel
                                control={
                                    <Controller
                                        name="active"
                                        control={control}
                                        defaultValue={true}
                                        render={({ field: { value, ref, ...field } }) => (
                                            <Checkbox
                                                {...field}
                                                inputRef={ref}
                                                checked={!!value}
                                                color="primary"
                                                size={"medium"}
                                                disableRipple
                                                disabled={hasAuthority ? false : true}
                                            />
                                        )}
                                    />
                                }
                                label={strings.active}
                                labelPlacement="end"
                            />

                            {hasAuthority && (
                                <CardActions className="d-flex p-0 mt-4">
                                    {isAddMode ? (
                                        <Button
                                            variant="contained"
                                            onClick={handleCancel}
                                            color={"warning"}
                                            className="w-50"
                                        >
                                            {strings.cancel}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            startIcon={<DeleteIcon />}
                                            onClick={handleClickOpen}
                                            color={"error"}
                                            className="w-50"
                                        >
                                            {strings.delete}
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        startIcon={<EditIcon />}
                                        color={"primary"}
                                        className="w-50"
                                    >
                                        {strings.saveChanges}
                                    </Button>
                                    <ConfirmationModal
                                        open={openModal}
                                        handleClose={handleClose}
                                        handleConfirm={deleteNomenclatureEntry}
                                        title={strings.deleteNomenclature}
                                        content={strings.deleteNomenclatureText}
                                        actionButtonName={strings.delete}
                                    />
                                </CardActions>
                            )}
                            {!hasAuthority && (
                                <CardActions className="d-flex p-0 mt-4">
                                <Button onClick={handleBack} className={"contained"} fullWidth>
                                    {strings.back}
                                </Button>
                                </CardActions>
                            )}
                        </form>
                    </CenteredCardLayout>
                </>
            }
        />
    );
}
