import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {IconButton, TextField, Tooltip} from "@mui/material";
import {Clear} from "@mui/icons-material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import {Dayjs} from "dayjs";

interface props {
    onChange?: (value: Dayjs | null) => void | Promise<void>,
    label?: string | boolean,
    value?: string | Dayjs | null,
    variant? : string,
    views? : boolean,
    error?: string | boolean | undefined
}

export function NullableDatePicker({onChange, label, value, variant, views = false, error} : props) {
    const changeHandler = (date: Dayjs | null) => {
        if(onChange){
            onChange(date);
        }
    };

    return(
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="mk">
                <DatePicker
                    onChange={changeHandler}
                    value={value}
                    inputFormat={!views ? "DD.MM.YYYY" : "YYYY"}
                    views={[views ? 'year' : 'day']}
                    renderInput={(params: any) => (
                        <Tooltip title={label}>
                            <TextField
                                fullWidth={true}
                                style = {{marginTop:"20px"}}
                                variant={variant}
                                {...params}
                                error={error}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: label,
                                }}
                                // onKeyDown={e => e.preventDefault()}
                            />
                        </Tooltip>
                    )}

                    InputProps={{
                        endAdornment: value && (
                            <IconButton sx={{height: "10px", width: "10px"}}
                                        onClick={() => changeHandler(null)}>
                                <Clear/>
                            </IconButton>
                        ),
                    }}
                    InputAdornmentProps={{
                        position: "start",
                    }}></DatePicker>
            </LocalizationProvider>
        </>
    )
}
