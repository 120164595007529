import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Input, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { DatePickerField } from "../../applications/formFields/DatePickerFields";
import InputField from "../../applications/formFields/InputField";
import { v4 as uuidv4 } from "uuid";
import { NomenclatureEntryDTO } from "../../../models/nomenclatures/NomenclatureEntryDTO";
import { NomenclatureService } from "../../../services/NomenclatureService";
import SelectField from "../../applications/formFields/SelectField";
import {FieldArray, useFormikContext} from "formik"
import { useParams } from "react-router-dom";
import { PaginatedSelect } from "../../../components/PaginatedSelect";
import { NomenclatureConstants } from "../../../constants/NomenclatureConstants";
import { strings } from "../../../localization/Localization";
import InputFieldNumber from "../../applications/formFields/InputFieldNumber";
import { showErrorDialog } from "../../../common/Dialogues";
import { ExternalService } from "../../../services/ExternalService";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import InputFieldCyrillic from "../../applications/formFields/InputFieldCyrillic";

export function DataFormAsylumThree(props: any) {

    interface FormElementRelatives {
        id: string;
        data: JSX.Element;
    }

    const [newFormRelatives, setNewFormRelatives] = useState<FormElementRelatives[]>([]);
    const [countries,setCountries] = useState<NomenclatureEntryDTO[]>([])
    const [keyCountry, setKeyCountry] = useState<string>("country");
    const [places, setPlaces] = useState<NomenclatureEntryDTO[]>([]);
    const [keyPlace, setKeyPlace] = useState<string>("place");
    const [streets, setStreets] = useState<NomenclatureEntryDTO[]>([]);
    const [keyStreet, setKeyStreet] = useState<string>("street");
    const [citizenships, setCitizenships] = useState<NomenclatureEntryDTO[]>([]);
    const [keyCitizenship, setKeyCitizenship] = useState<string>("citizenship")
    const [residenceType, setResidenceType] = useState<NomenclatureEntryDTO[]>([]);
    const [keyResidenceType, setKeyResidenceType] = useState<string>("residence_type")
    const [collectiveCenterNom, setCollectiveCenterNom] = useState<NomenclatureEntryDTO[]>([]);
    const [keyCollectiveCenter, setKeyCollectiveCenter] = useState<string>("collective_center")
    const formikProps = useFormikContext<any>()
    let {id} = useParams();
    const isAddMode = !id;
    
    const {
        formField: {
            currentAddressOfPlace,
            street,
            streetNumber,
            phoneNumber,
            collectiveCenter,
            otherAddr,
            relativesInNorthMacedonia,
            relativeNameAndSurname,
            relativeRelationshipWithApplicant,
            relativeAddressOfLiving,
            relativeCitizenship,
            relativePhoneNumber,
            militaryService,
            yearOfService,
            sectionOfService,
            placeOfServing,
            politicalActivity,
            politicalParty,
            politicalFunction,
            otherPolitical,
            addressInTheCountryOfOrigin,
            fromAddr,
            toAddr,
            streetAndNumber,
            cityOrStateAddr,
            placeAccomodation,
            municipalityAccomodation,
            typeOfAccomodation,
            collectiveCenterTitle,
            countryOrigin,
            placeRelativesInNorthMacedonia,
            streetNumberOrigin,
            livingPlacesList,
            relativesList,
            addressDateFrom
        }
    } = props;

    async function getAllEntriesForNomenclatureCountry(): Promise<void> {
        const countries = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyCountry);
        setCountries(countries)
      }
    
      async function getAllEntriesForNomenclaturePlace(): Promise<void> {
        const places = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyPlace);
        setPlaces(places) 
      }

      async function getAllEntriesForNomenclatureStreets(): Promise<void> {
        const streets = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyStreet);
        setStreets(streets)     
      }

      async function getAllEntriesForNomenclatureCitizenship(): Promise<void> {
        const citizenships = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyCitizenship);
        setCitizenships(citizenships)
      }

      async function getAllEntriesForNomenclatureResidenceType(): Promise<void> {
        const residence = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyResidenceType);
        setResidenceType(residence)
      }

      async function getAllEntriesForNomenclatureCollectiveCenter(): Promise<void> {
        const collectiveCenterData = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyCollectiveCenter);
        setCollectiveCenterNom(collectiveCenterData)
      }

      useEffect(()=>{
        getAllEntriesForNomenclatureCountry();
        getAllEntriesForNomenclaturePlace();
        getAllEntriesForNomenclatureStreets();
        getAllEntriesForNomenclatureCitizenship();
        getAllEntriesForNomenclatureResidenceType();
        getAllEntriesForNomenclatureCollectiveCenter();
      },[])

    const addNewRegionForAddressInOriginCountry = () => {
        const uuid = uuidv4();
        const newFormElement = {
            id: uuid, data:
                <>
                    <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 " key={uuid}>
                        <Accordion className="sm:mt-14 mb-4" key={uuid}>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                className="bg-white sm:mt-8"
                                expandIcon={<ExpandMoreIcon />}
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    {addressInTheCountryOfOrigin.label}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="bg-white">
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ width: "49%" }}>
                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                            {fromAddr.label}
                                        </Typography>
                                        <DatePickerField
                                            name={fromAddr.name + uuid}
                                            label={fromAddr.label}
                                            className="pb-3"
                                            key={`${uuid}-from`}
                                            read={props.hasAuthority ? false : true}
                                        />
                                    </div>
                                    <div style={{ width: "49%" }}>
                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                            {toAddr.label}
                                        </Typography>
                                        <DatePickerField
                                            name={toAddr.name + uuid}
                                            label={toAddr.placeholder}
                                            className="pb-3"
                                            key={`${uuid}-to`}
                                            read={props.hasAuthority ? false : true}
                                        />
                                    </div>
                                </div>
                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    {countryOrigin.label}
                                </Typography>
                                <SelectField name={countryOrigin.name + uuid} 
                                label={countryOrigin.label}
                                data={countries}
                                read={props.hasAuthority ? false : true}
                                 />
                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    {cityOrStateAddr.label}
                                </Typography>
                                 <InputField name={cityOrStateAddr.name + uuid} label={cityOrStateAddr.label} fullWidth readOnly={props.hasAuthority ? false : true}/>
                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    {streetAndNumber.label}
                                </Typography>
                                <InputField name={streetAndNumber.name + uuid} label={streetAndNumber.label} fullWidth readOnly={props.hasAuthority ? false : true}/>
                                  <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    {streetNumberOrigin.label}
                                </Typography>
                                <InputField name={streetNumberOrigin.name + uuid} label={streetNumberOrigin.placeholder} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                            </AccordionDetails>
                        </Accordion>
                        {props.hasAuthority && <Button className="mb-4" variant="contained" color="error" onClick={() => handleRemoveFormForAddressInOriginCountry(uuid)}>Избриши</Button>}
                    </CenteredCardLayout>
                </>
        }

        props.setState((newForm:any) => [...newForm, newFormElement]);
    };
    const handleRemoveFormForAddressInOriginCountry = (indexToRemove: string) => {
        props.setState((newForm:any) =>
            newForm.filter((form:any) => form.id !== indexToRemove)
        );
    };

    const addNewRegionForRelativesInRSM = () => {
        const uuid = uuidv4();
        const newFormElement = {
            id: uuid, data:
                <>
                    <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 " key={uuid}>
                        <Accordion className="sm:mt-14 mb-4">
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="bg-white sm:mt-8"
                                expandIcon={<ExpandMoreIcon />}
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {relativesInNorthMacedonia.label}
                        </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="bg-white">
                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    {relativeNameAndSurname.label}
                                </Typography>
                                <InputField name={relativeNameAndSurname.name + uuid} label={relativeNameAndSurname.placeholder} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    {relativeRelationshipWithApplicant.label}
                                </Typography>
                                <InputField name={relativeRelationshipWithApplicant.name + uuid} label={relativeRelationshipWithApplicant.placeholder} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    {placeRelativesInNorthMacedonia.label}
                                </Typography>
                                <SelectField 
                                name={placeRelativesInNorthMacedonia.name + uuid} 
                                label={placeRelativesInNorthMacedonia.placeholder} 
                                data={places}
                                read={props.hasAuthority ? false : true}
                                />
                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    {relativeAddressOfLiving.label}
                                </Typography>
                                <SelectField
                                name={relativeAddressOfLiving.name + uuid}
                                label={relativeAddressOfLiving.label}
                                data={streets}
                                read={props.hasAuthority ? false : true}
                                 />
                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    {relativeCitizenship.label}
                                </Typography>
                                <SelectField 
                                    name={relativeCitizenship.name + uuid}
                                    label={relativeCitizenship.placeholder}
                                    data={citizenships}
                                    read={props.hasAuthority ? false : true}
                                />
                            </AccordionDetails>
                        </Accordion>
                       {props.hasAuthority &&  <Button className="mb-4" variant="contained" color="error" onClick={() => handleRemoveFormForRelativesInRSM(uuid)}>Избриши</Button>}
                    </CenteredCardLayout>
                </>
        }

        props.setStateRelatives((newFormRelatives:any) => [...newFormRelatives, newFormElement]);
    };
    const handleRemoveFormForRelativesInRSM = (indexToRemove: string) => {
        props.setStateRelatives((newFormRelatives:any) =>
            newFormRelatives.filter((form:any) => form.id !== indexToRemove)
        );
    };


    async function getPersonInformationByEmbg(embs:string, index:any){
        ExternalService.getPersonInformationByEmbg(embs).then((res)=>{
            formikProps.setFieldValue(`relativesList[${index}].relativeName`, res.firstName);
            formikProps.setFieldValue(`relativesList[${index}].relativeLastName`, res.lastName);
        }).catch((e)=>{
          const errorMessage = ErrorHandler.parseErrorMessage(e);
          showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
        })
     }

    return (
        <>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                {/* Grid 1 */}
                <Grid item xs={12} sm={4} md={6}>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {addressInTheCountryOfOrigin.id + ". " + addressInTheCountryOfOrigin.label}
                        </Typography>
                        <FieldArray
                        name="livingPlacesList"
                        render={({ remove, push, form: { values, setFieldValue } }) => (
                          <div>
                            {values.livingPlacesList && values.livingPlacesList.length > 0 && (
                              values.livingPlacesList.map((form:any, index:any) => (
                                <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 " key={index}>
                                <Accordion className="sm:mt-14 mb-4" key={index} defaultExpanded={!isAddMode? true : false}>
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        className="bg-white sm:mt-8"
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{ display: "flex", justifyContent: "flex-end" }}
                                    >
                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                            {addressInTheCountryOfOrigin.label}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="bg-white">
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div style={{ width: "49%" }}>
                                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                    {'Од'}
                                                </Typography>
                                                <DatePickerField
                                                    name={`livingPlacesList[${index}].fromAddr`}
                                                    label={'Од'}
                                                    className="pb-3"
                                                    read={props.hasAuthority ? false : true}
                                                />
                                            </div>
                                            <div style={{ width: "49%" }}>
                                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                    {'До'}
                                                </Typography>
                                                <DatePickerField
                                                    name={`livingPlacesList[${index}].toAddr`}
                                                    label={'До'}
                                                    className="pb-3"
                                                    minDate={formikProps.values.livingPlacesList[index].fromAddr}
                                                    read={props.hasAuthority ? false : true}
                                                />
                                            </div>
                                        </div>
                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                  {"Држава"}
                </Typography>
                <PaginatedSelect<NomenclatureEntryDTO>
                    value={formikProps.values.livingPlacesList[index].countryOrigin !== ""? formikProps.values.livingPlacesList[index].countryOrigin : undefined}
                    onChange={(value) => formikProps.setFieldValue(`livingPlacesList[${index}].countryOrigin`, value)}
                    label={strings.country}
                    valueMapper={(item) => item?.name || ""}
                    keyMapper={(item) => item.id.toString()}
                    itemMapper={(item) => <>{item.name}</>}
                    labelMapper={(item) => item.name}
                    dataFetcher={(page, size, filter) => {
                      return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                        page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                      );
                    }}
                    name={`livingPlacesList[${index}].countryOrigin`}
                    className="pb-3"
                    readOnly={props.hasAuthority ? false : true}
                  />
                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                            {'Град'}
                                        </Typography>
                                         <InputField name={`livingPlacesList[${index}].city`} label={'Град'} readOnly={props.hasAuthority ? false : true} fullWidth/>
                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                            {'Улица'}
                                        </Typography>
                                        <InputField name={`livingPlacesList[${index}].streetAddr`} label={'Улица'} readOnly={props.hasAuthority ? false : true} fullWidth/>
                                          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                            {'Број'}
                                        </Typography>
                                        <InputField name={`livingPlacesList[${index}].streetNumberAddr`} label={"Број"} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                                    </AccordionDetails>
                                </Accordion>
                                {props.hasAuthority && <Button className="mb-4" variant="contained" color="error" onClick={() => remove(index)}>Избриши</Button>}
                            </CenteredCardLayout>
                              ))
                            ) }
                            {props.hasAuthority && <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                push({ fromAddr:"",
                                toAddr:"",
                                countryOrigin:"",
                                city:"",
                                streetAddr:"",
                                streetNumberAddr:"" });
                              }}
                            >
                              +
                            </Button>}
                            </div>
                        )}
                      />
                    <br />
                    {props.addressInTheOgCountry && props.addressInTheOgCountry.map((form:any) => (
                        <div key={form.id}>
                            {form.data}
                        </div>
                    ))}
                    <br />
                    <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 ">
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {currentAddressOfPlace.id + ". " + currentAddressOfPlace.label}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {typeOfAccomodation.id + ". " + typeOfAccomodation.label}
                        </Typography>
                        <SelectField className="pb-3"
                                name={typeOfAccomodation.name}
                                label={typeOfAccomodation.placeholder}
                                data={residenceType}
                                read={props.hasAuthority ? false : true}
                                />
                                 <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {municipalityAccomodation.id + ". " + municipalityAccomodation.label}
                        </Typography>
                        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.municipalityAccomodation!=="" ? formikProps.values.municipalityAccomodation : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("municipalityAccomodation", value);
                          formikProps.setFieldValue("placeAccomodation", "");
                          formikProps.setFieldValue("street", "");
                        }
                        }
                        label={strings.municipality + "*"}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.municipality, true, " ", filter ? filter : ""
                          );
                        }}
                        name={municipalityAccomodation.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {placeAccomodation.id + ". " + placeAccomodation.label}
                        </Typography>
                        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.placeAccomodation!=="" ? formikProps.values.placeAccomodation : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("placeAccomodation", value);
                          formikProps.setFieldValue("street", "");
                          }
                        }
                        label={strings.place + "*"}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.municipalityAccomodation?.key}`, filter ? filter : ""
                          );
                        }}
                        name={placeAccomodation.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {street.id + ". " + street.label}
                        </Typography>
                        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.street!=="" ? formikProps.values.street : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("street", value)
                          }
                        }
                        label={strings.street + "*"}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.street, true, `place=${formikProps.values.placeAccomodation?.key}`, filter ? filter : ""
                          );
                        }}
                        name={street.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {streetNumber.id + ". " + streetNumber.label}
                        </Typography>
                        <InputField name={streetNumber.name} label={streetNumber.placeholder} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {addressDateFrom.id + ". " + addressDateFrom.label}
                        </Typography>
                        <DatePickerField 
                            name={addressDateFrom.name} 
                            label={addressDateFrom.placeholder}
                            className="pb-3"
                            read={props.hasAuthority ? false : true}
                            />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                    {collectiveCenter.label}
                                </Typography>
                                <SelectField name={collectiveCenter.name} 
                                label={collectiveCenter.label}
                                data={collectiveCenterNom}
                                read={props.hasAuthority ? false : true}
                                 />
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom></Typography>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {otherAddr.id + ". " + otherAddr.label}
                        </Typography>
                        <InputField name={otherAddr.name} label={otherAddr.placeholder} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                    </CenteredCardLayout>
                </Grid>
                <Grid item xs={12} sm={4} md={6}>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {relativesInNorthMacedonia.id + ". " + relativesInNorthMacedonia.label}
                        </Typography>
                    <FieldArray
                        name="relativesList"
                        render={({ remove, push, form: { values, setFieldValue } }) => (
                          <div>
                            {values.relativesList && values.relativesList.length > 0 && (
                              values.relativesList.map((form:any, index:any) => (
                                <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 " key={index}>
                                <Accordion className="sm:mt-14 mb-4" key={index} defaultExpanded={!isAddMode? true : false}>
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        className="bg-white sm:mt-8"
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{ display: "flex", justifyContent: "flex-end" }}
                                    >
                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                            {relativesInNorthMacedonia.label}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="bg-white">
                                    <Typography variant="subtitle1" gutterBottom>
            {"ЕМБГ"}
        </Typography>
        <InputField name={`relativesList[${index}].embg`} label={"ЕМБГ"}  className="pb-3" fullWidth icon={(formikProps.values.relativesList[index]?.embg && formikProps.values.relativesList[index]?.embg.length === 13) ? true : false}
        onClick={() => getPersonInformationByEmbg(formikProps.values.relativesList[index].embg, index)} readOnly={props.hasAuthority ? false : true} embgNumber={true}></InputField>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div style={{ width: "49%" }}>
                                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                    {'Име'}
                                                </Typography>
                                                <InputField
                                                    name={`relativesList[${index}].relativeName`}
                                                    label={'Име'}
                                                    className="pb-3"
                                                    readOnly={props.hasAuthority ? false : true}
                                                    fullWidth
                                                />
                                            </div>
                                            <div style={{ width: "49%" }}>
                                                <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                                    {'Презиме'}
                                                </Typography>
                                                <InputField
                                                    name={`relativesList[${index}].relativeLastName`}
                                                    label={'Презиме'}
                                                    className="pb-3"
                                                    readOnly={props.hasAuthority ? false : true}
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                            {'Сродство со барателот'}
                                        </Typography>
                                        <InputField name={`relativesList[${index}].relativeRelationshipWithApplicant`} 
                                        label={'Сродство со барателот'}
                                        readOnly={props.hasAuthority ? false : true}
                                        fullWidth
                                         />
                                         <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {strings.municipality}
                        </Typography>
                        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.relativesList[index].municipalityRelatives!=="" ? formikProps.values.relativesList[index].municipalityRelatives : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue(`relativesList[${index}].municipalityRelatives`, value);
                          formikProps.setFieldValue(`relativesList[${index}].placeRelativesInNorthMacedonia`, "");
                          formikProps.setFieldValue(`relativesList[${index}].relativeAddressOfLiving`, "");
                        }
                        }
                        label={strings.municipality}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.municipality, true, " ", filter ? filter : ""
                          );
                        }}
                        name={`relativesList[${index}].municipalityRelatives`}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                            {strings.place}
                                        </Typography>
                                         <PaginatedSelect<NomenclatureEntryDTO>
                                                value={formikProps.values.relativesList[index].placeRelativesInNorthMacedonia!=="" ? formikProps.values.relativesList[index].placeRelativesInNorthMacedonia : undefined}
                                                onChange={(value) => {
                                                    formikProps.setFieldValue(`relativesList[${index}].placeRelativesInNorthMacedonia`, value)
                                                    formikProps.setFieldValue(`relativesList[${index}].relativeAddressOfLiving`, "");
                                                }}
                                                label={strings.place}
                                                valueMapper={(item) => item?.key && item?.key?.toString()}
                                                keyMapper={(item) => item.id.toString()}
                                                itemMapper={(item) => <>{item.name}</>}
                                                labelMapper={(item) => item.name}
                                                dataFetcher={(page, size, filter) => {
                                                return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                                                    page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.relativesList[index].municipalityRelatives?.key}`, filter ? filter : ""
                                                );
                                                }}
                                                name={`relativesList[${index}].placeRelativesInNorthMacedonia`}
                                                className="pb-3"
                                                readOnly={props.hasAuthority ? false : true}
                                            />
                                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                            {'Улица'}
                                        </Typography>
                                        <PaginatedSelect<NomenclatureEntryDTO>
                                            value={formikProps.values.relativesList[index].relativeAddressOfLiving!=="" ? formikProps.values.relativesList[index].relativeAddressOfLiving : undefined}
                                            onChange={(value) => {
                                            formikProps.setFieldValue(`relativesList[${index}].relativeAddressOfLiving`, value)
                                            }
                                            }
                                            label={strings.street}
                                            valueMapper={(item) => item?.key && item?.key?.toString()}
                                            keyMapper={(item) => item.id.toString()}
                                            itemMapper={(item) => <>{item.name}</>}
                                            labelMapper={(item) => item.name}
                                            dataFetcher={(page, size, filter) => {
                                            return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                                                page, size, NomenclatureConstants.street, true, `place=${formikProps.values.relativesList[index].placeRelativesInNorthMacedonia?.key}`, filter ? filter : ""
                                            );
                                            }}
                                            name={`relativesList[${index}].relativeAddressOfLiving`}
                                            className="pb-3"
                                            readOnly={props.hasAuthority ? false : true}
                                        />
                                          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                                            {'Државјанство'}
                                        </Typography>
                                        <SelectField name={`relativesList[${index}].relativeCitizenship`} label={"Државјанство"} data={citizenships} className="pb-3" read={props.hasAuthority ? false : true}/>
                                    </AccordionDetails>
                                </Accordion>
                                {props.hasAuthority && <Button className="mb-4" variant="contained" color="error" onClick={() => remove(index)}>Избриши</Button>}
                            </CenteredCardLayout>
                              ))
                            ) }
                            {props.hasAuthority && <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                push({ 
                                relativeName:"",
                                relativeLastName:"",
                                relativeRelationshipWithApplicant:"",
                                placeRelativesInNorthMacedonia:"",
                                relativeAddressOfLiving:"",
                                relativeCitizenship:"" });
                              }}
                            >
                              +
                            </Button>}
                            </div>
                        )}
                      />
                    <br />
                    {props.relativesInRM && props.relativesInRM.map((form:any) => (
                        <div key={form.id}>
                            {form.data}
                        </div>
                    ))}
                    <br />

                    <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 ">
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {militaryService.id + ". " + militaryService.label}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {yearOfService.id + ". " + yearOfService.label}
                        </Typography>
                        <InputFieldNumber name={yearOfService.name} label={yearOfService.placeholder} className="pb-3" fullWidth/>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {sectionOfService.id + ". " + sectionOfService.label}
                        </Typography>
                        <InputField name={sectionOfService.name} label={sectionOfService.placeholder} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {placeOfServing.id + ". " + placeOfServing.label}
                        </Typography>
                        <InputField name={placeOfServing.name} label={placeOfServing.placeholder} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                    </CenteredCardLayout>

                    <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3 ">
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {politicalActivity.id + ". " + politicalActivity.label}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {politicalParty.id + ". " + politicalParty.label}
                        </Typography>
                        <InputField name={politicalParty.name} label={politicalParty.placeholder} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {politicalFunction.id + ". " + politicalFunction.label}
                        </Typography>
                        <InputField name={politicalFunction.name} label={politicalFunction.placeholder} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                        <Typography variant="subtitle1" fontWeight="500" gutterBottom>
                            {otherPolitical.id + ". " + otherPolitical.label}
                        </Typography>
                        <InputField name={otherPolitical.name} label={otherPolitical.placeholder} className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth></InputField> 
                    </CenteredCardLayout>
                </Grid>
            </Grid>
        </>
    )
}
