export class NomenclatureDTO {
    id: number;
    key: string;
    name: string;
    nameSq: string;
    nameEn: string;

    constructor(json: any) {
        this.id = json.id;
        this.key = json.key;
        this.name = json.name;
        this.nameSq = json.nameSq;
        this.nameEn = json.nameEn;
    }
}
