import { EvidencyReasonDTO } from "../evidency/EvidencyReason";
import { PassportTypeDTO } from "../passport/PassportTypeDTO";
import { DecisionResultDTO } from "./DecisionResultDTO";
import { DecisionTypeDTO } from "./DecisionTypeDTO";
import { RegistrationDecisionDTO } from "./RegistrationDecisionDTO";
import { IdentificationDocumentTypeDTO } from "./identificationDocumentTypeDTO";

export class DecisionDTO{
    id : number;
    dateCreated?: Date | null;
    evidencyReason?: EvidencyReasonDTO | null;
    decisionType?: DecisionTypeDTO | null;
    decisionResult?: DecisionResultDTO | null;
    decisionDate?: Date | string | null | undefined;
    startDate?: Date | string | null;
    deadlineDate?: Date | string | null | undefined;
    embg?: string | null;
    idCardNumber?: string | null;
    expirationDate?: Date | string | null;
    documentExtensionDate?: Date | string | null;
    identificationDocumentTypeDTO?: IdentificationDocumentTypeDTO | null;
    registrationDecisionDTOList?: RegistrationDecisionDTO[] | null;


    constructor(json:DecisionDTO) {
        this.id = json.id;
        this.dateCreated = json.dateCreated ? new Date(json.dateCreated) : undefined;
        this.evidencyReason = json.evidencyReason;
        this.decisionType = json.decisionType;
        this.decisionResult = json.decisionResult;
        this.decisionDate = json.decisionDate ? new Date(json.decisionDate) : undefined;
        this.startDate = json.startDate;
        this.deadlineDate = json.deadlineDate;
        this.embg = json.embg;
        this.idCardNumber = json.idCardNumber;
        this.expirationDate = json.expirationDate;
        this.documentExtensionDate = json.documentExtensionDate;
        this.identificationDocumentTypeDTO = json.identificationDocumentTypeDTO;
        this.registrationDecisionDTOList = json.registrationDecisionDTOList;
    }
}
