import {useState} from "react";
import {Collapse, ListItem} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Link, useLocation} from "react-router-dom";
import List from "@mui/material/List";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {SideBarItem} from "../models/SideBarItem";
import {GuardedComponent} from "./GuardedComponent";
import {PrivilegeGuard} from "../router/guards/PrivilegeGuard";
import {Guard} from "../router/guards/Guard";


export interface SideBarProps {
    item: SideBarItem
}

export function SideBar(props: SideBarProps) {
    const [isOpen, setIsOpen] = useState(false);
    const handleIsOpen = () => {
        setIsOpen(!isOpen);
    }

    const guards: Guard[] = [];
    const location = useLocation()


    if (props.item.privilegeGuardData) {
        guards.push(new PrivilegeGuard(
            props.item.privilegeGuardData.privileges,
            props.item.privilegeGuardData.mode,
        ));
    }

    const checkIsChildOpened = () => {
        let open = isOpen;
        props.item?.children.map((element, index) => {if(location.pathname === element.path) open = true;});
        return open;
    }

    return (
        <GuardedComponent
            component={
                <List>
                    {
                        props.item.children.length === 0 ? (
                            <ListItem key={props.item.name} disablePadding>
                                <ListItemButton component={Link} to={props.item.path} selected={props.item.path === location.pathname}  sx={{"&.Mui-selected, &.Mui-selected:hover, &.MuiListItemButton-root:hover": {
                                        backgroundColor: "#3f51b5",
                                        color: "#FFFFFF"
                                    }}}>
                                    <ListItemIcon style={{color: props.item.path === location.pathname ? "#FFFFFF" : "#000000"}}>
                                        {props.item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={props.item.name}/>
                                </ListItemButton>
                            </ListItem>) : (
                            <>
                                <ListItemButton selected={checkIsChildOpened()} onClick={() => handleIsOpen()} sx={{"&.Mui-selected, &.Mui-selected:hover, &.MuiListItemButton-root:hover": {
                                        backgroundColor: "#3f51b5",
                                        color: "#FFFFFF"
                                }}}>
                                    <ListItemIcon style={{color: checkIsChildOpened() ? "#FFFFFF" : "#000000"}}>
                                        {props.item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={props.item.name}/>
                                    {checkIsChildOpened() ? <ExpandLess/> : <ExpandMore/>}
                                </ListItemButton>
                                {
                                    props.item.children.map((element, index) => (
                                        <Collapse in={checkIsChildOpened()} timeout="auto" unmountOnExit key = {index}>
                                            <div className="ms-4">
                                                <SideBar item={element}/>
                                            </div>
                                        </Collapse>
                                    ))
                                }
                            </>
                        )
                    }
                </List>
            }
            guards={guards}
            fallback={
                <></>
            }
        />
    )
}
