import { RegistrationTypes } from "../../constants/RegistrationTypes";
import { StorageService } from "../../storage/StorageService";
import {NomenclatureConstants} from "../../constants/NomenclatureConstants";
export function AsylumForeignData(values:any, correctionOfPersonData? : boolean | null){
const  asylumCreationDTO = values;
let languages = [];
if (asylumCreationDTO.motherTongueAndOtherLanguages !== "") {
  languages = asylumCreationDTO.motherTongueAndOtherLanguages.map((element: any) => {
    return { languageMvrKey: element?.key}
  })
}
let employementInfo = [];
if (asylumCreationDTO.employementList !== undefined){
  employementInfo = asylumCreationDTO.employementList.map((item:any)=>{
    return {
      dateFrom: item.from,
      dateTo: item.to,
      organization:{
        name: item.institution,
          placeDTO:{
            mvrKey:item.place,
            countryDTO:{
                mvrKey:item.state?.key,
                isoName:null,
                iso2:null,
                iso3:null,
                nameSq:"",
                nameEn:""
            },
            municipalityName:"",
            municipalityMvrKey:"",
            name:"",
            nameSq:"",
            nameEn:"",
        }
      }
    }
  })
}

let livingPlacesDataList = [];

if (asylumCreationDTO.livingPlacesList !== undefined){
  livingPlacesDataList = asylumCreationDTO.livingPlacesList.map((item:any)=>{
    return {
      dateFrom: item.fromAddr,
      dateTo: item.toAddr,
  address:{
    streetDTO:{
        placeDTO:{
    mvrKey:item.city,
    countryDTO:{
        mvrKey:item.countryOrigin?.key,
        isoName:null,
        iso2:null,
        iso3:null,
        nameSq:null,
        nameEn:null
        },
        municipalityName:null,
        municipalityMvrKey:null,
        name:null,
        nameSq:null,
        nameEn:null
    },
    mvrKey:item.streetAddr,
    name:null,
    nameSq:null,
    nameEn:null
    },
    streetNumber:item.streetNumberAddr,
    snMvrKey:null,
    entranceNumber: null,
    apartmentNumber: null
},
    }
  })
}

let relativesDataList = [];

if (asylumCreationDTO.relativesList !== undefined){
  relativesDataList = asylumCreationDTO.relativesList.map((item:any)=>{
    return{
      relativeTypeMvrKey:item.relativeRelationshipWithApplicant,
      personData:{
        addressDTO:{
            streetDTO:{
                placeDTO:{
            mvrKey:item.placeRelativesInNorthMacedonia?.key,
            countryDTO:{
                mvrKey: NomenclatureConstants.macedoniaMvrKey,
                isoName:null,
                iso2:null,
                iso3:null,
                nameSq:null,
                nameEn:null
                },
                municipalityName:"",
                municipalityMvrKey:item.municipalityRelatives?.key,
                name:null,
                nameSq:null,
                nameEn:null
            },
            mvrKey:item.relativeAddressOfLiving?.key,
            name:null,
            nameSq:null,
            nameEn:null
            },
            streetNumber:"",
            snMvrKey:null,
            entranceNumber: null,
            apartmentNumber: null
        },
          firstName:item.relativeName,
          lastName: item.relativeLastName,
          place: asylumCreationDTO.placeRelativesInNorthMacedonia,
          citizenshipCreationDTO:{
            mvrKey: item.relativeCitizenship,
            statKey:null,
            isoName:null,
            iso2:null,
            iso3:null,
            nameSq:null,
            nameEn:null,
            countryDTO:{
                mvrKey: NomenclatureConstants.macedoniaMvrKey,
                isoName:null,
                iso2:null,
                iso3:null,
                nameSq:null,
                nameEn:null
                }
        },
          embg:"",
          genderMvrKey:"",
          birthDate:"",
          numberOfAdultFamilyMembers:null,
          numberOfForeignChildren:null,
          numberOfDomesticChildren:null,
          ageGroupMvrKey:""
      }
    }
  })
}

let familyMembersAcompanyDataList = [];

if (asylumCreationDTO.familyMembersAcompanyList !== undefined){
  familyMembersAcompanyDataList = asylumCreationDTO.familyMembersAcompanyList.map((item:any)=>{
    return {
        id: item.id? item.id : null,
        workingVersionId: item.workingVersionRegistrationId?.id ?  item.workingVersionRegistrationId.id : null,
        registrationTypeMvrKey: RegistrationTypes.asylum,
        foreignCitizenData: {
            personDataCreationDTO: {
                citizenshipCreationDTO: {
                    mvrKey: item.memberCitizenShip?.key,
                    statKey: null,
                    isoName: null,
                    iso2: null,
                    iso3: null,
                    nameSq: null,
                    nameEn: null,
                    countryDTO: {
                        mvrKey: "",
                        isoName: null,
                        iso2: null,
                        iso3: null,
                        nameSq: null,
                        nameEn: null,
                    },
                },
                maidenName: "",
                parentName: "",
                professionalTrainingMvrKey: "",
                otherCitizenshipCreationDTO: {
                    mvrKey: "",
                    statKey: null,
                    isoName: null,
                    iso2: null,
                    iso3: null,
                    nameSq: null,
                    nameEn: null,
                    countryDTO: {
                        mvrKey: "",
                        isoName: null,
                        iso2: null,
                        iso3: null,
                        nameSq: null,
                        nameEn: null
                    }
                },
                embg: "",
                existingMacEmbg:item.memberExistingMacEmbg,
                firstName: item.memberName,
                lastName: item.memberSurname,
                firstNameCyrillic:item.memberNameCyrillic,
                lastNameCyrillic:item.memberSurnameCyrillic,
                genderMvrKey: item.relationshipsOrSex,
                birthDate: item.memberDateOfBirth,
              //   birthPlaceDTO:{
              //     mvrKey:"",
              //     countryDTO:{
              //         mvrKey:item.memberStateOfBirth?.key,
              //         isoName:null,
              //         iso2:null,
              //         iso3:null,
              //         nameSq:null,
              //         nameEn:null
              //         },
              //         municipalityName:item.memberPlaceOfBirth,
              //         municipalityMvrKey:null,
              //         name:null,
              //         nameSq:null,
              //         nameEn:null
              // },
                birthPlaceDTO:{
                    mvrKey:item.memberStateOfBirth ? item.memberPlaceOfBirth : item.memberInRSMPlace?.key,
                    countryDTO:{
                        mvrKey:item.memberStateOfBirth ? item.memberStateOfBirth?.key : NomenclatureConstants.macedoniaMvrKey,
                        isoName:null,
                        iso2:null,
                        iso3:null,
                        nameSq:null,
                        nameEn:null
                    },
                    municipalityName:null,
                    municipalityMvrKey:item.memberInRSMMunicipality?.key,
                    name:null,
                    nameSq:null,
                    nameEn:null
                },
                birthCountryDTO: {
                    mvrKey:"",
                    isoName: null,
                    iso2: null,
                    iso3: null,
                    nameSq: null,
                    nameEn: null
                },
                spouseFullName: "",
                addressDTO: {
                    streetDTO: {
                        placeDTO: {
                            mvrKey: "",
                            countryDTO: {
                                mvrKey: "",
                                isoName: null,
                                iso2: null,
                                iso3: null,
                                nameSq: null,
                                nameEn: null
                            },
                            municipalityName: null,
                            municipalityMvrKey: "",
                            name: null,
                            nameSq: null,
                            nameEn: null
                        },
                        mvrKey: "",
                        name: null,
                        nameSq: null,
                        nameEn: null
                    },
                    streetNumber: "",
                    snMvrKey: null,
                    entranceNumber: null,
                    apartmentNumber: null,
                }
            },
            numberOfAdultFamilyMembers: null,
            numberOfForeignChildren: null,
            numberOfDomesticChildren: null,
            ageGroupMvrKey: null,
            personLanguageCreationDTOs: undefined,
            otherCitizenshipCreationDTO: {
                mvrKey: "",
                statKey: null,
                isoName: null,
                iso2: null,
                iso3: null,
                nameSq: null,
                nameEn: null,
                countryDTO: {
                    mvrKey: "",
                    isoName: null,
                    iso2: null,
                    iso3: null,
                    nameSq: null,
                    nameEn: null
                }
            },
            marriageStatusMvrKey: "",
            addressDTO: {
                streetDTO: {
                    placeDTO: {
                        mvrKey: "",
                        countryDTO: {
                            mvrKey: "",
                            isoName: null,
                            iso2: null,
                            iso3: null,
                            nameSq: null,
                            nameEn: null
                        },
                        municipalityName: null,
                        municipalityMvrKey: "",
                        name: null,
                        nameSq: null,
                        nameEn: null
                    },
                    mvrKey: "",
                    name: null,
                    nameSq: null,
                    nameEn: null
                },
                streetNumber: null,
                snMvrKey: null,
                entranceNumber: null,
                apartmentNumber: null,
            },
        }
    }
  })
}

let familyMembersOriginOrAbroadList = [];

if (asylumCreationDTO.familyMembersOriginCountryOrAbroadList){
  familyMembersOriginOrAbroadList = asylumCreationDTO.familyMembersOriginCountryOrAbroadList.map((item:any)=>{
    return {
      personData:{
        firstName: item.memberNameOG,
        lastName: item.memberSurnameOG,
        genderMvrKey:item.relationshipsOrSexOG,
        birthDate:item.memberDateOfBirthOG,
        citizenshipCreationDTO: {
          mvrKey: item.memberCitizenShipOG?.key,
          statKey: null,
          isoName: null,
          iso2: null,
          iso3: null,
          nameSq: null,
          nameEn: null,
          countryDTO: {
              mvrKey: "",
              isoName: null,
              iso2: null,
              iso3: null,
              nameSq: null,
              nameEn: null,
          },
      },
        birthPlaceDTO:{
          mvrKey:"",
          countryDTO:{
              mvrKey:item.memberStateOfBirthOG?.key,
              isoName:null,
              iso2:null,
              iso3:null,
              nameSq:null,
              nameEn:null
              },
              municipalityName:item.memberPlaceOfBirthOG,
              municipalityMvrKey:null,
              name:null,
              nameSq:null,
              nameEn:null
      },
      },
    }
  })
}

let restrictedStayList = [];
if (asylumCreationDTO.limitedMovementList){
  restrictedStayList = asylumCreationDTO.limitedMovementList.map((item:any)=>{
    return{     
      startDateOfStay:item.startDateOfLimitedMovement,
      finalDateOfStay:item.dateOfLimitedMovement,
      extendedDateOfStay:item.dateOfExtension,
      stoppedDateOfStay:item.terminationBeforeTerm,
      restrictedStayPlaceMvrKey:item.placeOfLimitedMovement,
      proposerMvrKey:item.proposer,
      agentMvrKey:item.agentMvr,
      deadline:item.termOfLimitedMovement
    }
  })
}


const data =  {
processedBy:StorageService.getJWTPayload()?.sub,
registrationTypeMvrKey:RegistrationTypes.asylum,
embg: asylumCreationDTO.embg ? asylumCreationDTO.embg : null,
foreignCitizenData:{
active:true,
evidencyReasonMvrKey:"",
settlementReasonMvrKey:"",
personDataCreationDTO:{
  citizenshipCreationDTO:{
      mvrKey:asylumCreationDTO?.citizenShip?.key,
      statKey:null,
      isoName:null,
      iso2:null,
      iso3:null,
      nameSq:null,
      nameEn:null,
      countryDTO:{
          mvrKey: NomenclatureConstants?.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          }
  },
  birthPlaceDTO:{
      mvrKey:asylumCreationDTO.placeOfBirth ? asylumCreationDTO.placeOfBirth : asylumCreationDTO.inRSMBirthPlacePlace?.key,
      countryDTO:{
          mvrKey:asylumCreationDTO.countryOfBirth ? asylumCreationDTO.countryOfBirth?.key : NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:asylumCreationDTO.inRSMBirthMunicipality?.key,
          name:null,
          nameSq:null,
          nameEn:null
  },
  addressDTO:{
    streetDTO:{
        mvrKey: asylumCreationDTO.street?.key,
        name:"",
        nameSq:"",
        nameEn:"",
        placeDTO:{
            mvrKey:asylumCreationDTO.placeAccomodation?.key,
            countryDTO:{
                mvrKey: NomenclatureConstants.macedoniaMvrKey,
                isoName:null,
                iso2:null,
                iso3:null,
                nameSq:"",
                nameEn:""
            },
            municipalityName:"",
            municipalityMvrKey:asylumCreationDTO.municipalityAccomodation?.key,
            name:"",
            nameSq:"",
            nameEn:"",
        }
    },
    streetNumber:asylumCreationDTO?.streetNumber,
    snMvrKey:"",
    entranceNumber:null,
    apartmentNumber:null,
  },
  educationInstitutionDTO:{
    name:asylumCreationDTO.nameOfTheInstitution,
    place:asylumCreationDTO.placeWhereCompleted
  },
  embg: "",
  existingMacEmbg:asylumCreationDTO.existingMacEmbg,
  firstName:asylumCreationDTO.name,
  lastName:asylumCreationDTO.lastName,
  firstNameCyrillic:asylumCreationDTO.nameCyrillic,
  lastNameCyrillic:asylumCreationDTO.surnameCyrillic,
  parentNameCyrillic: asylumCreationDTO.fathersNameCyrillic,
  nickname: asylumCreationDTO.otherNameOrNickName,
  genderMvrKey:asylumCreationDTO.gender,
  birthDate:asylumCreationDTO.birthDate,
  maidenName:asylumCreationDTO.maidenName,
  parentName:asylumCreationDTO.fathersName,
  parentSurname:asylumCreationDTO.fathersLastName,
  motherName: asylumCreationDTO.mothersName,
  motherSurname: asylumCreationDTO.mothersLastName,
  collectiveCenterMvrKey: asylumCreationDTO.collectiveCenter,
  otherResidence: asylumCreationDTO.otherAddr,
  occupationMvrKey:"",
  residenceTypeMvrKey:asylumCreationDTO.typeOfAccomodation,
  marriageStatusMvrKey: asylumCreationDTO.maritalStatus,
  numberOfAdultFamilyMembers:null,
  numberOfForeignChildren:null,
  numberOfDomesticChildren:null,
  ageGroupMvrKey:null,
  personLanguageCreationDTOs:languages,
  ethnicityMvrKey:asylumCreationDTO.ethnicOrigin,
  religionMvrKey:asylumCreationDTO.religion,
  spouseFullName: asylumCreationDTO.surnameAndNameOfTheSpouse,
  educationDateFinishedDate:asylumCreationDTO.yearOfCompletion,
  professionalTrainingMvrKey:asylumCreationDTO.education,
  addressDateFrom:asylumCreationDTO.addressDateFrom
},
restrictedStayDataCreationDTOS:restrictedStayList,

domesticPastAsylumRequestCreationDTO:{
  country:{
    mvrKey: NomenclatureConstants.macedoniaMvrKey,
    isoName:null,
    iso2:null,
    iso3:null,
    nameSq:null,
    nameEn:null
},
decisionTypeMvrKey:asylumCreationDTO.decision,
date:asylumCreationDTO.dateOfSubbmission
},

foreignPastAsylumRequestCreationDTO:{
  country:{
    mvrKey:asylumCreationDTO.countryWhereYouApplied?.key,
    isoName:null,
    iso2:null,
    iso3:null,
    nameSq:null,
    nameEn:null
},
decisionResultMvrKey:asylumCreationDTO.decisionOtherCountry,
date:""
},

applicantStatement:asylumCreationDTO.briefSummaryAboutTheReasons,

identityCardDataDTO:{
    cardNumber:asylumCreationDTO.idOfIdentification,
    date:"",
    approvalDate:"",
    finalDateOfStay:"",
    validUntil:asylumCreationDTO.expireDate
},

identificationDocumentDataCreationDTO:{
  passportNumber:asylumCreationDTO.nationalPassport,
  idCardNumber: asylumCreationDTO.identificationDocument,
  drivingLicenseNumber:asylumCreationDTO.drivingLicense,
  other: asylumCreationDTO.others,
  noneOfAbove: asylumCreationDTO.noneOfAbove,
},

employmentInfos: employementInfo,

asylumRequestIntentionMvrKey:asylumCreationDTO.about,

livingPlaces: livingPlacesDataList,
relatives:relativesDataList,

militaryServiceCreationDTO:{
  year: asylumCreationDTO.yearOfService,
  type: asylumCreationDTO.sectionOfService,
  placeName: asylumCreationDTO.placeOfServing,
},
politicalActivityCreationDTO:{
  politicalParty: asylumCreationDTO.politicalParty,
  position: asylumCreationDTO.politicalFunction,
  other: asylumCreationDTO.otherPolitical,
},
asylumDataCreationDTO:{
    isInformedAboutLegalHelp: asylumCreationDTO.freeLegalAid,
    requestedSameGenderProcessor:asylumCreationDTO.sameGenderOfficial,
    procedureTypeMvrKey:asylumCreationDTO.procedureType,
    dateFilled: asylumCreationDTO.dateOfSubmissionForm,
    anbAnswerMvrKey: asylumCreationDTO.checksByUBK,
},
timeStart:asylumCreationDTO.startTime,
timeEnd: asylumCreationDTO.endTime,
archiveNumber:asylumCreationDTO.archiveNumber,
dossierNumber:asylumCreationDTO.dossierNumber,
archiveDate:asylumCreationDTO.archiveDate,
addressDTO:{
    streetDTO:{
        placeDTO:{
    mvrKey:NomenclatureConstants.macedoniaMvrKey,
    countryDTO:{
        mvrKey: NomenclatureConstants.macedoniaMvrKey,
        isoName:null,
        iso2:null,
        iso3:null,
        nameSq:null,
        nameEn:null
        },
        municipalityName:null,
        municipalityMvrKey:null,
        name:null,
        nameSq:null,
        nameEn:null
    },
    mvrKey:null,
    name:null,
    nameSq:null,
    nameEn:null
    },
    streetNumber:null,
    snMvrKey:null,
    entranceNumber: null,
    apartmentNumber: null
},

entranceDataCreationDTO:{
  entranceDate:asylumCreationDTO.dateOfArrival,
  entranceMannerMvrKey:asylumCreationDTO.manner,
  placeDTO:{
      mvrKey:"",
      countryDTO:{
          mvrKey: NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:"",
          name:null,
          nameSq:null,
          nameEn:null
      },
  borderPointMvrKey: asylumCreationDTO.borderPoint,
  vehicle: asylumCreationDTO.transportationMeans,
  discovererMvrKey:"",
  ovrMvrKey:"",
  visitedForeignerShelter:null,
  visitedRefugeeShelter:null,
  financialSourceTypeMvrKey:null,

},
previousStayDataCreationDTO:{
  legalStay: asylumCreationDTO.legal,
  temporaryStay:asylumCreationDTO.temporary,
  permanentStay:asylumCreationDTO.permanent,
  temporaryProtection:asylumCreationDTO.temporaryProtection
},
employmentDataCreationDTO:{
  employmentApproval:null,
  employmentStatusMvrKey:"",
  employmentSectorMvrKey:"",
  occupationFieldMvrKey:"",
  organizationCreationDTO:{
      name:asylumCreationDTO.nameOfOrganization,
      placeDTO:{
      mvrKey:asylumCreationDTO.placeOrganization,
      countryDTO:{
          mvrKey: NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:"",
          name:null,
          nameSq:null,
          nameEn:null
      }
  },
  employmentPositionMvrKey:"",
  engagementTypeMvrKey:""
},
measuresDataCreationDTO:{
 placeDTO:{
     mvrKey:"",
     countryDTO:{
         mvrKey: NomenclatureConstants.macedoniaMvrKey,
         isoName:null,
         iso2:null,
         iso3:null,
         nameSq:null,
         nameEn:null
         },
         municipalityName:null,
         municipalityMvrKey:"",
          name:null,
          nameSq:null,
          nameEn:null
      },
  measureDate:null,
  ovrMvrKey:null,
  illegalLeavePlaceDTO:{
      mvrKey:null,
      countryDTO:{
          mvrKey: NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:"",
          name:null,
          nameSq:null,
          nameEn:null
      },
  illegalLeaveDate:null,
  measureCreationDTOs:[
    {measureReasonMvrKey:null, measureTypeMvrKey:null}
  ],
},
note:null,
ovrMvrKey:null,
// archiveNumber: null
familyMembers:familyMembersOriginOrAbroadList,

},
asylumRequestData:null,
parentRegistrationId:null,
correctionOfPersonData: correctionOfPersonData,
childRegistrations:familyMembersAcompanyDataList,
placeOfRequestMvrKey: asylumCreationDTO.placeOfSubmission?.key,
submitted: false, 
workingVersionId: "",
id:""
}

return data;
}
