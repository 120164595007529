export class CountryDTO {
    id: number;
    mvrKey: string;
    isoName: string;
    iso2: string;
    iso3: string;
    nameSq: string;
    nameEn: string;
    displayName: string;
    constructor(json:CountryDTO){
        this.id = json.id;
        this.mvrKey = json.mvrKey;
        this.isoName = json.isoName;
        this.iso2 = json.iso2;
        this.iso3 = json.iso3;
        this.nameSq = json.nameSq;
        this.nameEn = json.nameEn;
        this.displayName = json.displayName;
    }
}