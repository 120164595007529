import { useTheme } from "@mui/material";

type Info = {
label?:string;
firstName?:string;
lastName?:string;
email?:string
status?:string;
statusSuccessful?:boolean;
}
export function CreatedByInfo({label, firstName, lastName, email, status, statusSuccessful}:Info){
  const theme = useTheme();

  return (
    <>
    <div className="pb-4" style={{ textAlign:"left"}}>
      {label} {firstName} {lastName} {email} <span style={{color: statusSuccessful === undefined ? theme.palette.text.primary : statusSuccessful ? theme.palette.success.main : theme.palette.error.main}}>{status}</span>
    </div>
    </>
  );
}
