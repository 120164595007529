export class LivingPlaceResponseDTO {
    sifOpstina: string | undefined;
    sifMesto: string | undefined;
    sifUlica: string | undefined;
    brojUlica: string | undefined;

    constructor(json: LivingPlaceResponseDTO) {
        this.sifOpstina = json.sifOpstina;
        this.sifMesto = json.sifMesto;
        this.sifUlica = json.sifUlica;
        this.brojUlica = json.brojUlica;
    }
}