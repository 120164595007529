export class DecisionTypeDTO{
    id : number;
    mvrKey : string;
    name : string;
    nameSq : string;
    nameEn : string;
    displayName: string;

    constructor(json:DecisionTypeDTO) {
        this.id = json.id;
        this.mvrKey = json.mvrKey;
        this.name = json.name;
        this.nameSq = json.nameSq;
        this.nameEn = json.nameEn;
        this.displayName = json.displayName;
    }
}
