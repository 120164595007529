import {Dayjs} from "dayjs";

export class OnHoldCreationDTO{
    decisionNumber:string | null;
    onHoldDate:Dayjs | null;
    onHoldReasonMvrKey:string | undefined;
    constructor(json: OnHoldCreationDTO){
        this.decisionNumber = json.decisionNumber;
        this.onHoldDate = json.onHoldDate;
        this.onHoldReasonMvrKey = json.onHoldReasonMvrKey;
    }
}