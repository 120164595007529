import { OrganizationalUnitSimplifiedResponse } from "./OrganizationalUnitSimplifiedResponse";

export class RolesWithOUResponse {
    roleId: string;
    roleName: string;
    applicationName: string;
    organizationalUnitList?: OrganizationalUnitSimplifiedResponse[]

    constructor(json:RolesWithOUResponse){
        this.roleId = json.roleId;
        this.roleName = json.roleName;
        this.applicationName = json.applicationName;
        this.organizationalUnitList = json.organizationalUnitList?.map(item => new OrganizationalUnitSimplifiedResponse(item));
    }
}