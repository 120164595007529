import { CallbackComponent } from "redux-oidc";
import userManager from "../../util/auth/userManager";
import {useNavigate} from "react-router-dom";
import {Routes} from "../../router/Routes";
import {connect} from "react-redux";

function CallbackPage(props: any) {
    const navigate = useNavigate();

    function goToErrorPage() {
        navigate(Routes.NOT_FOUND);
    }

    return (
        <CallbackComponent
            userManager={userManager}
            successCallback={() => {
                let url = sessionStorage.getItem("redirectUri");


                if (!url || url.trim().length === 0) {
                    url = Routes.HOME;
                }

                if (url) {
                    navigate(url);
                } else {
                    goToErrorPage();
                }
            }}
            errorCallback={error => {
                goToErrorPage();
            }}
            {...props}
        >
            <div>Redirecting...</div>
        </CallbackComponent>
    );
}

export default connect()(CallbackPage);
