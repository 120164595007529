import React from 'react';
import HasAuth from "./has-auth"
import UnauthorizedPage from "./comps/UnauthorizedPage";
import {loginAction} from "./auth-helper";
import _ from "lodash";
import {properties} from "../../config/properties";
import {translate} from "../lang/translate-wrapper";
import {EnvironmentUtils} from "../../environment/EnvironmentUtils";


const AuthorizedPage = (props) => {
    let reasonMessage = "";

    if (EnvironmentUtils.internalAuth()) {
        return props.component;
    }

    return (
        <>
            <HasAuth condition={({oidc})=> {
                // debugger;
                //ako e false ili nevneseno, propusti bez proverka
                if(!properties.checkGroupMatch)
                    return true;

                const groupName = properties.groupName?properties.groupName:properties.oidcClientId;
                const groups = _.get(oidc,"user.profile.groups");
                const administratorForbiddenRoutes = [ "/roles"]
                const roleAssignerForbiddenRoutes = [
                    "/applications",
                    "/searches",
                    "/datasets/create",
                    "/datasetstype",
                    "/domains",
                    "/searchReason",
                    "/lovs"
                ];

                if(!groups)
                {
                    // TODO: this is suspicious, it should be checked
                    reasonMessage = translate("app.unauthorized.addGroupScope")
                    return false;
                }

                // if (!userRoles) {
                //     reasonMessage = translate("app.unauthorized.addRolesToUser")
                //     return false;
                // } else if (groups.includes(groupName) && !userRoles.some(role => ['COMMANDER','SHIFT_MANAGER'].includes(role))) {
                //     return false;
                // }

                // if (groups.includes(groupName)) {

                //     // check appropriate role
                //     if (userRoles.some(role => ['SHIFT_MANAGER'].includes(role)) && !userRoles.some(role => ['COMMANDER'].includes(role))) {
                //         if (roleAssignerForbiddenRoutes.includes(props.location.pathname)) {
                //             return false;
                //         } else {
                //             return true;
                //         }
                //     } else if(!userRoles.some(role => ['SHIFT_MANAGER'].includes(role)) && userRoles.some(role => ['COMMANDER'].includes(role))){
                //         if (administratorForbiddenRoutes.includes(props.location.pathname)) {
                //             return false;
                //         } else {
                //             return true;
                //         }
                //     } else {
                //         return true;
                //     }
                // } else {
                //     return false;
                // }

                return true;
            }} onFail={({reason,oidc})=>{
                //ako e logiran znaci ne e avtoriziran,
                //ako ne e logiran redirektiraj na login
                if(oidc.user){
                    return <UnauthorizedPage additionalMessage={reasonMessage}/>
                }
                else
                {
                    loginAction();

                }
            }}>
                {props.component}
            </HasAuth>
        </>
    )
}
export default AuthorizedPage;
