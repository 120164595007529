import { strings } from "../../../localization/Localization";

export default {
  formId: "form",
  formField: {
    embg:{
      id:"1",
      name:"embg",
      label: "ЕМБГ",
      placeholder:"ЕМБГ"
    },
    existingMacEmbg:{
      id:"115",
      name:"existingMacEmbg",
      label:"Македонски матичен број (доколку има таков)",
      placeholder:"Македонски матичен број (доколку има таков)",
      requiredErrorMsg: "Полето е задолжително"
    },
      lastName: {
        id:"2",
        name: "lastName",
        label: strings.lastName,
        placeholder: strings.lastName + "*",
        requiredErrorMsg: "Презимето е задолжително",
      },
      lastNameCyrillic:{
        id:"",
        name: "lastNameCyrillic",
        label: "Презиме (кирилица)",
        placeholder:"Презиме",
        requiredErrorMsg: "Полето е задолжително",
      },
      parentName: {
        id:"3",
        name: "parentName",
        label: strings.parentName,
        placeholder: strings.parentName,
      },
      parentSurname: {
        id:"4",
        name: "parentSurname",
        label: strings.parentSurname,
        placeholder: strings.parentSurname,
      },
    motherName: {
      id:"5",
      name: "motherName",
      label: strings.motherName,
      placeholder: strings.motherName,
    },
    motherSurname: {
      id:"6",
      name: "motherSurname",
      label: strings.motherSurname,
      placeholder: strings.motherSurname,
    },
      firstName: {
        id:"7",
        name: "firstName",
        label: "Име",
        placeholder:strings.firstName + "*",
        requiredErrorMsg: "Името е задолжително"
      },
      firstNameCyrillic:{
        id:"",
        name: "firstNameCyrillic",
        label: "Име (кирилица)",
        placeholder:"Име",
        requiredErrorMsg: "Полето е задолжително",
      },
      genderMvrKey: {
        id:"8",
        name: "genderMvrKey",
        label: strings.gender,
        placeholder:strings.gender + "*",
        requiredErrorMsg: "Полето е задолжително",
      },
    citizenship: {
      id:"9",
      name: "citizenship",
      label: strings.citizenship,
      placeholder: strings.citizenship,
      requiredErrorMsg:"Полето е задолжително"
    },
      birthDate: {
        id:"10",
        name: "birthDate",
        label: "Датум на раѓање",
        placeholder:"Датум на раѓање*",
        requiredErrorMsg: "Датумот на раѓање е задолжителен",
      },
      birthPlace: {
        id:"11",
        name: "birthPlace",
        label: "Место на раѓање",
        placeholder:"Место на раѓање",
        requiredErrorMsg: "Полето е задолжително",
      },
      abroadBirthPlace:{
        id:"11.1",
        name: "abroadBirthPlace",
        label: "Во странство",
        placeholder:"Во странство",
        requiredErrorMsg: "Полето е задолжително",
      },
      placeOfBirthAbroad:{
        id:"11.2",
        name: "placeOfBirthAbroad",
        label: strings.place,
        placeholder:strings.place,
        requiredErrorMsg: "Полето е задолжително",
      },
      inRSMBirthPlace:{
        id:"11.3",
        name: "inRSMBirthPlace",
        label: "Во РСМ",
        placeholder:"Назив на местото, општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      inRSMBirthMunicipality:{
        id:"11.3.1",
        name: "inRSMBirthMunicipality",
        label: "Општина",
        placeholder:"Општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      inRSMBirthPlacePlace:{
        id:"11.3.2",
        name: "inRSMBirthPlacePlace",
        label: "Населено место",
        placeholder:"Населено место",
        requiredErrorMsg: "Полето е задолжително",
      },
      archiveNumber:{
        id:"114",
        name:"archiveNumber",
        label: "Архивски број",
        placeholder:"Архивски број"
      },
      archiveDate:{
        id:"113",
        name:"archiveDate",
        label: "Архивски датум",
        placeholder:"Архивски датум"
      },
      dossierNumber:{
        id:"115",
        name:"dossierNumber",
        label: "Број на досие",
        placeholder:"Број на досие"
      },

      livingPlaceOrStayRSM: {
        id:"12",
        name: "livingPlaceOrStayRSM",
        label: "Место на живеење или престој во РСМ*",
        placeholder: "Место на живеење или престој во РСМ*",
      },
      addressDateFrom:{
        id:"12.1",
        name: "addressDateFrom",
        label: "Датум од",
        placeholder:"Датум од*",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingMunicipalityRSM:{
        id:"12.2",
        name: "livingMunicipalityRSM",
        label: "Општина",
        placeholder:"Назив на општината*",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingPlaceRSM:{
        id:"12.3",
        name: "livingPlaceRSM",
        label: "Населено место",
        placeholder:"Назив на местото*",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingStreetRSM:{
        id:"12.4",
        name: "livingStreetRSM",
        label: "Улица",
        placeholder:"Улица*",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingStreetNumberRSM:{
        id:"12.5",
        name: "livingStreetNumberRSM",
        label: "Број",
        placeholder:"Број*",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingApartmentEntranceRSM:{
        id:"12.6",
        name: "livingApartmentEntranceRSM",
        label: "Влез",
        placeholder:"Влез",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingApartmentRSM:{
        id:"12.7",
        name: "livingApartmentRSM",
        label: "Стан",
        placeholder:"Стан",
        requiredErrorMsg: "Полето е задолжително",
      },
      passportTypeMvrKey:{
        id:"13",
        name: "passportTypeMvrKey",
        label: "Вид на патен документ",
        placeholder:"",
        requiredErrorMsg: "Полето е задолжително",
      },
      countryPassport:{
        id:"13.1",
        name: "countryPassport",
        label: "Држава која го издала патниот документ",
        placeholder:"Назив на државата",
        requiredErrorMsg: "Полето е задолжително",
      },
      identityCardNumber:{
        id:"13.2",
        name: "identityCardNumber",
        label: strings.idCardNumber,
        placeholder: strings.idCardNumber,
      },
      approvalDate:{
        id:"14",
        name: "approvalDate",
        label: "Датум на одобрување",
        placeholder:"Датум на одобрување*",
        requiredErrorMsg: "Полето е задолжително",
      },
      dateOfExtension:{
        id:"14.1",
        name: "dateOfExtension",
        label: "Датум на продолжување",
        placeholder:"Датум на продолжување",
        requiredErrorMsg: "Полето е задолжително",
      },
      endDate:{
        id:"15",
        name: "endDate",
        label: "Важност на престојот",
        placeholder:"Важност на престојот",
        requiredErrorMsg: "Полето е задолжително",
      },
      startDateStay:{
        id:"15.1",
        name: "startDateStay",
        label: "Важност на престојот од: датум",
        placeholder:"Важност на престојот од: датум",
        requiredErrorMsg: "Полето е задолжително",
      },
      endDateStay:{
        id:"15.2",
        name: "endDateStay",
        label: "Важност на престојот до: датум",
        placeholder:"Важност на престојот до: датум",
        requiredErrorMsg: "Полето е задолжително",
      },
      dateOfDeliveryOfDecision:{
        id:"16",
        name: "dateOfDeliveryOfDecision",
        label: "Датум на врачување на решение",
        placeholder:"Датум на врачување на решение",
        requiredErrorMsg: "Полето е задолжително",
      },
      decision:{
        id:"16.1",
        name: "decision",
        label: "Позитивно / негативно",
        placeholder:"Позитивно/негативно",
        requiredErrorMsg: "Полето е задолжително",
      },
      fileDecision:{
        id:"17.2",
        name: "fileDecision",
        label: "Прикачи решение",
        placeholder:"Прикачи решение",
        requiredErrorMsg: "Полето е задолжително",
      },
    }
    };
