import {StorageKeys} from "./StorageKeys";
import {JWTPayload} from "../models/JWTPayload";
import jwtDecode from "jwt-decode";
import {getToken} from "../App";

export class StorageService {
    static saveInternalToken(token: string): void {
        localStorage.setItem(StorageKeys.INTERNAL_TOKEN, token);
    }

    static hasAuthToken(): boolean {
        const token = StorageService.getAuthToken();
        return token !== null && token.length > 0;
    }

    static clearAuthToken(): void {
        localStorage.removeItem(StorageKeys.AUTH_TOKEN);
    }

    static setLanguage(language: string): void {
        localStorage.setItem(StorageKeys.ACTIVE_LANGUAGE, language);
    }

    static getLanguage(): string | null {
        return localStorage.getItem(StorageKeys.ACTIVE_LANGUAGE);
    }

    static hasLanguage(): boolean {
        return this.getLanguage() !== null;
    }

    static getAuthToken(): string | null {
        return getToken() ?? null;
    }

    static getInternalToken(): string | null {
        return localStorage.getItem(StorageKeys.INTERNAL_TOKEN);
    }

    static hasInternalToken(): boolean {
        const token = StorageService.getInternalToken();
        return token !== null && token.length > 0;
    }

    static clearInternalAuthToken(): void {
        localStorage.removeItem(StorageKeys.INTERNAL_TOKEN);
    }

    static getJWTPayload(): JWTPayload | null {
        const token = StorageService.getAuthToken();

        if (!token) {
            return null;
        }

        return jwtDecode<JWTPayload>(token);
    }

    static saveUserRoles(userPrivileges: string[]): void {
        sessionStorage.setItem(StorageKeys.USER_ROLES, JSON.stringify(userPrivileges));
    }

    static getUserRoles(): string[] | null {
        const userPrivileges = sessionStorage.getItem(StorageKeys.USER_ROLES);
        let storedPrivileges = null;
        if (userPrivileges) {
            storedPrivileges = JSON.parse(userPrivileges);
          }
          return storedPrivileges
    }

    static removeUserRoles(): void {
        sessionStorage.clear()
    }
}
