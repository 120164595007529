import React, {useEffect, useRef, useState} from 'react';
import { Formik, Form} from 'formik';
import DiplomatsBasicData from './forms/DiplomatsBasicData';
import { SideBarPage } from '../../components/SideBarPage';
import { CenteredCardLayout } from '../../components/CenteredCardLayout';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Step,
    StepButton,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from '@mui/material';
import  useStyles from './styles/styles';
import DiplomatsSecondData from './forms/DiplomatsSecondData';
import { ForeignCitizenService } from '../../services/registrations/ForeignCitizenService';
import { showErrorDialog, showSuccessDialog } from '../../common/Dialogues';
import { strings } from '../../localization/Localization';
import {useNavigate, useParams} from 'react-router-dom';
import { Routes } from '../../router/Routes';
import { ErrorHandler } from '../../utils/ErrorHandler';
import { DiplomatsUserData } from './DiplomatsUserData';
import DiplomatsFormValidationSchema from './formModel/DiplomatsFormValidationSchema';
import DiplomatsFormInitalValues from './formModel/DiplomatsFormInitalValues';
import DiplomatsFormModel from './formModel/DiplomatsFormModel';
import {RegistrationDTO} from "../../models/foreign_citizens/RegistrationDTO";
import {ForeignCitizenReducedDTO} from "../../models/registration/ForeignCitizenReducedDTO";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {ForeignCitizenDTO} from "../../models/foreign_citizens/ForeignCitizenDTO";
import {CreatedByInfo} from "../../components/CreatedByInfo";
import { getAllEntriesForNomenclatureCitizenship, getAllEntriesForNomenclatureCountry, getAllEntriesForNomenclatureMunicipality, getAllEntriesForNomenclaturePlace, getAllEntriesForNomenclatureStreets } from '../../common/NomenclatureMethods';
import { UserDetailsInfoResponse } from '../../models/UserDetailsInfoResponse';
import { AuthService } from '../../services/AuthService';
import { Privileges } from '../../models/nomenclatures/Privileges';
import { StorageService } from '../../storage/StorageService';
import {EMBGModal} from "../../components/EMBGModal";
import { NomenclatureConstants } from '../../constants/NomenclatureConstants';
import { ArrayUtils } from '../../utils/ArrayUtils';
import ConfirmationModal from "../../components/ConfirmationModal";
import {PassivationService} from "../../services/PassivationService";
import {RegistrationStatuses} from "../../constants/RegistrationStatuses";
import RightSidePanel from '../../components/right_side_component/RightSidePanel';
import { TemplateDTO } from '../../models/templates/TemplateDTO';
import { FileDTO } from '../../models/foreign_citizens/FileDTO';
import {RegistrationCreationDTO} from "../../models/foreign_citizens/RegistrationCreationDTO";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LightTooltip } from '../../components/LightTooltip';
import {AutoScroller} from "../../components/AutoScroller";
 let steps = ['Лични податоци', 'Податоци за генерирање матичен број'].map((n=>{
  return {name:n, completed: false}
 }));
 
const { formId, formField } = DiplomatsFormModel;
export default function DiplomatsUserForm() {
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = DiplomatsFormValidationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const classes = useStyles();
  const formikRef = useRef<any>(null);
  const navigate = useNavigate();
  let {id} = useParams();
  const [versions, setVersions] = useState<ForeignCitizenReducedDTO[] | undefined>([])
  const [version, setVersion] = useState<string>();
  const isAddMode = !id;
  const [lastSubmittedValues, setLastSubmittedValues] = useState<{} | null>(null);
  const [registrationData, setRegistrationData] = useState<RegistrationDTO>();
  const [foreignCitizenData, setForeignCitizenData] = useState<ForeignCitizenDTO>();
  const [openRequest, setOpenRequest] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserDetailsInfoResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [hasAuthority, setHasAuthority] = useState<boolean>();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [historyActions, setHistoryActions] = useState<any>();
  const [historyActionsEMBG, setHistoryActionsEMBG] = useState<any>();
  const [openActivateRequest, setOpenActivateRequest] = useState<boolean>(false);
  const [openDeceasedModal, setOpenDeceasedModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openDialog2, setOpenDialog2] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [openActivateModal, setOpenActivateModal] = useState<boolean>(false);
  const [values, setValues] = useState<any>();
  const [openBasicDataChangesModal, setOpenBasicDataChangesModal] = useState<boolean>(false);
  const [isPasivise, setIsPasivise] = useState<boolean>(true)
  const [openCheckModal, setOpenCheckModal] = useState<boolean>(false);
  const [diplomatsTemplates, setDiplomatsTemplates] = useState<TemplateDTO[]>([]);
  const [registrationFiles, setRegistrationFiles] = useState<FileDTO[]>([]);
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [correctionOfPerData, setCorrectionOfPerData] = useState<boolean | null>(null);
  const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>()

    const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialog2 = () => {
      setOpenDialog2(false);
  };
  function getHistoryEMBG(id: number) {
      ForeignCitizenService.getHistoryEMBG(id).then(data => {
          setHistoryActionsEMBG(data)
      });
  }

  function getHistory(id: number) {
    ForeignCitizenService.getHistoryActions(id).then(data => {
      setHistoryActions(data)
    });
  }

    function handleChange(event: SelectChangeEvent) {
        setVersion(event.target.value)
    }
    
    useEffect(() => {
      if (id){
        getHistory(parseInt(id))
      }
      if (id) {
          getHistoryEMBG(parseInt(id))
      }
      async function userHasAuthority(): Promise<void> {
        const userRoles: string[] | null = StorageService.getUserRoles();
        if(userRoles !== null) {
          if(userRoles.includes(Privileges.MANAGE_DIPLOMAT))
          {
            setHasAuthority(true)
          }
        } else {
          const authorization = await AuthService.getAuthorization();
          if(authorization.roles.includes(Privileges.MANAGE_DIPLOMAT)) {
            setHasAuthority(true)
          }
        }
      }
  
      userHasAuthority().then((_) => { });
  
    }, []);

    const pull_data = (data: any) => {
      setDisabledSaveButton(data)
    }
    function renderStepContent(step:number) {
      switch (step) {
        case 0:
          return <DiplomatsBasicData hasAuthority={hasAuthority} formField={formField} func={pull_data} />;
        case 1:
          return <DiplomatsSecondData hasAuthority={hasAuthority} formField={formField}  />;
        default:
          return <div>Not Found</div>;
      }
    }

    async function getVersion(id:number) {
        const data = await ForeignCitizenService.getForeignCitizenVersionForRegistration(+id, version && version);
        setForeignCitizenData(data)
    }

    const handleClickOpen2 = () => {
        setOpenDialog2(true);
    };
    function handleOpenRequest(){
      setOpenRequest(true)
    }
    function handleCloseRequest(actionPerformed: boolean){
        setOpenRequest(false);

        if (actionPerformed) {
            refreshData();
        }
    }
    function handleOpenActivateRequest(){
        setOpenActivateRequest(true)
    }
    function handleCloseActivateRequest(actionPerformed: boolean){
        setOpenActivateRequest(false);

        if (actionPerformed) {
            refreshData();
        }
    }

    //not needed
    async function getTemplatesForDiplomats(): Promise<void> {
      // const diplomatsTemplates: TemplateDTO[] = await TemplateService.getTemplatesForForeignCitizen();
      // setDiplomatsTemplates(diplomatsTemplates)
    }

    async function getFilesForRegistration(id: number): Promise<void> {
      const files : FileDTO[] = await ForeignCitizenService.getFilesForRegistration(id);
      setRegistrationFiles(files)
    }

    useEffect(() => {
      if (!isAddMode && id) {
        getTemplatesForDiplomats().then((_) => { });
        getFilesForRegistration(parseInt(id)).then((_) => { });

      }
      const schema = DiplomatsFormValidationSchema;
      for (let i = 0; i < steps.length; i++) {
        if(schema[i].isValidSync(formikRef?.current?.values)){
          steps[i].completed = true;
        } else {
          steps[i].completed = false;
        }
      }
    }, [formikRef?.current?.values]);

  async function addDiplomat(values:any) {
    let data = DiplomatsUserData(values)
    data.submitted = true;
    if(registrationData?.workingVersionRegistrationId?.id){
      data.workingVersionId = registrationData?.workingVersionRegistrationId?.id.toString();
    }
    await ForeignCitizenService.createForeignCitizen(data).then((res) => {
        let route = Routes.DIPLOMATS_FORM_EDIT;
        route = route.replace(":id", res.id.toString());
        showSuccessDialog(strings.addForeignCitizenSuccess, strings.addForeignCitizenSuccess, strings.ok).then(_ => {
          setActiveStep(0)
          if(res?.versions){
            setVersions(res?.versions)
            setVersion(res?.versions[0]?.id.toString() || '')
            navigate(route);
          }
        });
      })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
      });
  }
   function checkIfBasicChangesExist(oldData : ForeignCitizenDTO | undefined, newData : RegistrationCreationDTO) : boolean{
       if((oldData?.personData?.firstName !== newData?.foreignCitizenData?.personDataCreationDTO?.firstName) ||
           (oldData?.personData?.lastName !== newData?.foreignCitizenData?.personDataCreationDTO?.lastName) || 
           (oldData?.personData?.firstNameCyrillic !== newData?.foreignCitizenData?.personDataCreationDTO?.firstNameCyrillic) ||
           (oldData?.personData?.lastNameCyrillic !== newData?.foreignCitizenData?.personDataCreationDTO?.lastNameCyrillic)){
           return true;
       }
       return false;
   }
  async function editDiplomat(values:any){
      let data = DiplomatsUserData(values)
      setValues(values)
      if (id) {
          let showModal = false;
          if (registrationData?.embg) {
              if (
                  registrationData.registrationStatus?.key === "passive" &&
                  (foreignCitizenData?.personData?.genderMvrKey !== data?.foreignCitizenData?.personDataCreationDTO?.genderMvrKey ||
                      foreignCitizenData?.personData?.birthDate !== data?.foreignCitizenData?.personDataCreationDTO?.birthDate)
              ) {
                  showModal = true;
                  setIsPasivise(true);
              } else if (
                  registrationData?.registrationStatus !== undefined && registrationData.registrationStatus?.key !== 'passive' &&
                  ( foreignCitizenData?.personData?.genderMvrKey !== data?.foreignCitizenData?.personDataCreationDTO?.genderMvrKey ||
                      foreignCitizenData?.personData?.birthDate !== data?.foreignCitizenData?.personDataCreationDTO?.birthDate)
              ){
                  showModal = true;
                  setIsPasivise(false);
              }
              let checkBasicInfoChanges = checkIfBasicChangesExist(foreignCitizenData, data);
              if (checkBasicInfoChanges){
                  setOpenBasicDataChangesModal(true);
              }
              if (showModal) {
                  setOpenCheckModal(true);
              }
              if (!checkBasicInfoChanges && !showModal){
                  await updateDiplomat(values);
              }
          } else {
              await updateDiplomat(values);
          }
      }
  }
  async function updateDiplomat(values : any, correctionOfPersonData? : boolean | null){

      let data = DiplomatsUserData(values, correctionOfPersonData !== undefined ? correctionOfPersonData : correctionOfPerData)
      setValues(values)
      if(registrationData?.id){
          id = registrationData.id.toString();
      }
      if(id){
          await ForeignCitizenService.updateForeignCitizen(+id, data).then((res)=>{
              showSuccessDialog(strings.editForeignCitizenSuccess, strings.addForeignCitizenSuccess, strings.ok).then(_ => {
                  setActiveStep(0)
                  if(res?.versions){
                      setVersions(res?.versions)
                      setVersion(res?.versions[0]?.id.toString() || '')
                      setCorrectionOfPerData(null)
                      let data = DiplomatsUserData(values, null);
                      navigate(Routes.DIPLOMATS_FORM_EDIT.replace(":id", id!.toString()));
                  }
                  getFilesForRegistration(parseInt(id!))
              });
          })
              .catch((e) => {
                  const errorMessage = ErrorHandler.parseErrorMessage(e);
                  showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
              });


      }
  }

  async function submitForm(values:{}, actions:any) {
    setIsLoading(true)
    if(!registrationData?.id) {
       await addDiplomat(values);
        setLastSubmittedValues(values);
    }else{
        await editDiplomat(values);
        setLastSubmittedValues(values);
    }
    actions.setSubmitting(false);
    setIsLoading(false)
    for (let step of steps) {
      step.completed = false;
    }
    setActiveStep(activeStep + 1);
  }
    function handleCloseBasicDataChangesModal(): any {
        setOpenBasicDataChangesModal(false);
        setActiveStep(steps.length - 1)
    }

    async function handleClickCorrection(){
        setCorrectionOfPerData(true);
        setOpenBasicDataChangesModal(false);

        if (!openCheckModal){
            await await updateDiplomat(values, true);
        }
    }
    async function handleClickChange(){
        setCorrectionOfPerData(false);
        setOpenBasicDataChangesModal(false);

        if (!openCheckModal){
            await await updateDiplomat(values, false);
        }

    }

  function handleSubmit(values:{}, actions:any) {
    setIsLoading(true)
    if (isLastStep) {
      let doSubmit = true;

      for (let index = 0; index < steps.length - 1; index++) {
        if (!steps[index].completed) {
          actions.setSubmitting(false);
          setActiveStep(index);
          doSubmit = false;
          break;
        }
      }
      if (doSubmit) {
        submitForm(values, actions);
      }
    } else {
      setActiveStep(activeStep + 1);
      steps[activeStep].completed = true;
      actions.setTouched({});
      actions.setSubmitting(false);
    }
    setIsLoading(false)
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  const handleStepValidation =  async(stepIndex:number) => {
    steps[activeStep].completed = true;
    try {
      await currentValidationSchema.validate(formikRef?.current?.values);
    } catch (e) {
      steps[activeStep].completed = false;
    }
    setActiveStep(stepIndex);
  };

  function getUserDetails(){
    AuthService.getUserDetails().then((res)=>{
      setUserDetails(res)
    })
  }
    useEffect(() => {
        if (formikRef?.current?.values?.evidencyReasonMvrKey !== undefined) {
            if (formikRef?.current?.values.evidencyReasonMvrKey === null || formikRef?.current?.values.evidencyReasonMvrKey!==foreignCitizenData?.evidencyReason?.mvrKey) {
                formikRef?.current?.setFieldValue('settlementReasonMvrKey', null);
            }
        }
        getUserDetails();
    }, [formikRef?.current?.values?.evidencyReasonMvrKey]);
    async function getRegistrationById(id: number) {
        setIsLoadingData(true)
        const registration = await ForeignCitizenService.getRegistrationById(id);
        setVersions(registration.versions);
        setIsLoadingData(false)
        if(registration.versions){
            if (!version && (registration.versions?.length ?? 0) > 0) {
                setVersion(registration.versions[0].id.toString());
            }

        }
        setRegistrationData(registration)
    }
    useEffect(()=>{

        async function getData(){
            if (id && !isAddMode) {
                await getRegistrationById(parseInt(id));
                if (version) {
                  await getVersion(parseInt(id));
                }
            }
        }

        getData().then(_ => {
        });
    },[id, isAddMode, version])
    function refreshData() : any {
        async function getData() {
            if (id && !isAddMode) {
                await getRegistrationById(parseInt(id));
                await getHistory(parseInt(id));
                await getHistoryEMBG(parseInt(id));
                if (version) {
                    await getVersion(parseInt(id));
                }

            }
        }
        getData().then(_ => {
        });
    }
    function activateRequest(){
        if(id){
            PassivationService.activateRegistration(id).then(()=>{
                showSuccessDialog(strings.successfullyActivatedRequest, strings.successfullyActivatedRequest, strings.ok).then(_ => {
                    handleCloseActivateRequest(true)
                    refreshData()
                });
            })
                .catch((e) => {
                    const errorMessage = ErrorHandler.parseErrorMessage(e);
                    showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
                });
        }
    }

    function handleOpenDeceasedModal() {
        setOpenDeceasedModal(true)
    }

    function handleCloseDeceasedModal() {
        setOpenDeceasedModal(false)
        refreshData()
    }

    function markAsDeceased(){
        if(id){
            PassivationService.markAsDeceased(Array.of(+id)).then(()=>{
                showSuccessDialog(strings.successfullyMarkedAsDeceased, strings.successfullyMarkedAsDeceased, strings.ok).then(_ => {
                    handleCloseDeceasedModal()
                    refreshData()
                });
            })
                .catch((e) => {
                    const errorMessage = ErrorHandler.parseErrorMessage(e);
                    showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
                });
        }
    }


    const handleOpen = () => setOpen(true);

    function handleClose(actionPerformed: boolean): any {
        setOpen(false);

        if (actionPerformed) {
            refreshData();
        }
    }

    function handleOpenConfirmationModal(): any {
        setOpenConfirmation(true);
    }

    function handleCloseConfirmationModal(actionPerformed: boolean): any {
        setOpenConfirmation(false);

        if (actionPerformed) {
            refreshData();
        }
    }

    function handleOpenActivateEMBG() {
        setOpenActivateModal(true)
    }
    function handleCloseCheckModal(): any {
        setOpenCheckModal(false);
        setActiveStep(steps.length-1)
    }
    function handleCloseActivateEMBG(actionPerformed: boolean) {
        setOpenActivateModal(false);

        if (actionPerformed) {
            refreshData();
        }
    }

    useEffect(()=>{
      if (registrationData){
        formikRef?.current?.setFieldValue('embg', registrationData?.embg);
    }
        if(foreignCitizenData){
            const countryKeyPerson = foreignCitizenData.personData?.birthPlaceDTO?.countryDTO?.mvrKey;
            const municipalityKeyPerson = foreignCitizenData?.personData?.birthPlaceDTO?.municipalityMvrKey;
           getAllEntriesForNomenclatureCitizenship().then((res)=>{
            const citizenship = res.find((item) => item.key === foreignCitizenData?.personData?.citizenshipCreationDTO?.mvrKey)
            formikRef?.current?.setFieldValue("citizenship", citizenship)
           })
           getAllEntriesForNomenclatureCountry().then((res)=>{

             const abroadBirthPlace = res.find((item) => item.key === foreignCitizenData.personData?.birthPlaceDTO?.countryDTO?.mvrKey)
             const countryPassport = res.find((item) => item.key === foreignCitizenData.passport?.countryDTO?.mvrKey)
             if (abroadBirthPlace && abroadBirthPlace?.key !== NomenclatureConstants.macedoniaMvrKey) {
                formikRef?.current?.setFieldValue("abroadBirthPlace", abroadBirthPlace);
              } else if (abroadBirthPlace && abroadBirthPlace?.key === NomenclatureConstants.macedoniaMvrKey && municipalityKeyPerson) {
                formikRef?.current?.setFieldValue("abroadBirthPlace", "");
              }
             formikRef?.current?.setFieldValue("countryPassport", countryPassport);
           })
           getAllEntriesForNomenclatureMunicipality().then((res)=>{
            const livingMunicipalityRSM = res.find((item) => item.key === foreignCitizenData.personData?.addressDTO?.streetDTO.placeDTO?.municipalityMvrKey)

             const inRSMBirthMunicipality = res.find((item) => item.key === foreignCitizenData.personData?.birthPlaceDTO?.municipalityMvrKey)

             if(inRSMBirthMunicipality && countryKeyPerson === NomenclatureConstants.macedoniaMvrKey){
             formikRef?.current?.setFieldValue("inRSMBirthMunicipality", inRSMBirthMunicipality)
             }else if(countryKeyPerson !== NomenclatureConstants.macedoniaMvrKey){
               formikRef?.current?.setFieldValue("inRSMBirthMunicipality", "")
             }
             formikRef?.current?.setFieldValue("inRSMBirthMunicipality", inRSMBirthMunicipality);
             formikRef?.current?.setFieldValue("livingMunicipalityRSM", livingMunicipalityRSM)
           })

           getAllEntriesForNomenclaturePlace().then((res)=>{
            const inRSMBirthPlacePlace = res.find((item) => item.key === foreignCitizenData.personData?.birthPlaceDTO?.mvrKey)
            const livingPlaceRSM = res.find((item) => item.key === foreignCitizenData.personData?.addressDTO?.streetDTO.placeDTO?.mvrKey)
            formikRef?.current?.setFieldValue("livingPlaceRSM", livingPlaceRSM)
            if(inRSMBirthPlacePlace && countryKeyPerson === NomenclatureConstants.macedoniaMvrKey){
              formikRef?.current?.setFieldValue("inRSMBirthPlacePlace", inRSMBirthPlacePlace)
              formikRef?.current?.setFieldValue("placeOfBirthAbroad", "")
            }else if(countryKeyPerson !==NomenclatureConstants.macedoniaMvrKey && !municipalityKeyPerson){
              formikRef?.current?.setFieldValue("placeOfBirthAbroad", foreignCitizenData.personData?.birthPlaceDTO?.mvrKey)
              formikRef?.current?.setFieldValue("inRSMBirthPlacePlace", "")
            }
           })

           getAllEntriesForNomenclatureStreets().then((res)=>{
            const livingStreetRSM = res.find((item) => item.key === foreignCitizenData?.personData?.addressDTO?.streetDTO?.mvrKey)
            formikRef?.current?.setFieldValue("livingStreetRSM", livingStreetRSM)
           })
            formikRef?.current?.setFieldValue('existingMacEmbg', foreignCitizenData.personData?.existingMacEmbg);
            formikRef?.current?.setFieldValue("lastName", foreignCitizenData.personData?.lastName)
            formikRef?.current?.setFieldValue("lastNameCyrillic", foreignCitizenData.personData?.lastNameCyrillic)
            formikRef?.current?.setFieldValue("firstName", foreignCitizenData.personData?.firstName);
            formikRef?.current?.setFieldValue("firstNameCyrillic", foreignCitizenData.personData?.firstNameCyrillic);
            formikRef?.current?.setFieldValue("genderMvrKey", foreignCitizenData.personData?.genderMvrKey);
            if (registrationData?.embg) {
                formikRef?.current?.setFieldValue("embg", registrationData?.embg);
            }
            formikRef?.current?.setFieldValue("birthDate", foreignCitizenData.personData?.birthDate)
            formikRef?.current?.setFieldValue("parentName", foreignCitizenData.personData?.parentName)
            formikRef?.current?.setFieldValue("parentSurname", foreignCitizenData.personData?.parentSurname)
            formikRef?.current?.setFieldValue("motherName", foreignCitizenData.personData?.motherName)
            formikRef?.current?.setFieldValue("motherSurname", foreignCitizenData.personData?.motherSurname)

            formikRef?.current?.setFieldValue("identityCardNumber", foreignCitizenData.passport?.passportNumber);
            formikRef?.current?.setFieldValue("passportTypeMvrKey", foreignCitizenData.passport?.passportTypeMvrKey);

            formikRef?.current?.setFieldValue("addressDateFrom", foreignCitizenData?.personData?.addressDateFrom)
            formikRef?.current?.setFieldValue("approvalDate",  foreignCitizenData?.approvedStayDate);
            formikRef?.current?.setFieldValue("livingStreetNumberRSM", foreignCitizenData?.personData?.addressDTO?.streetNumber)
            if(foreignCitizenData.personData?.addressDTO?.entranceNumber){
                formikRef?.current?.setFieldValue("livingApartmentEntranceRSM", foreignCitizenData?.personData?.addressDTO?.entranceNumber)
                }
            if(foreignCitizenData.personData?.addressDTO?.apartmentNumber){
                    formikRef?.current?.setFieldValue("livingApartmentRSM", foreignCitizenData?.personData?.addressDTO?.apartmentNumber)
            }
            formikRef?.current?.setFieldValue("passportNumber", foreignCitizenData.passport?.passportNumber);
            formikRef?.current?.setFieldValue("passportTypeMvrKey", foreignCitizenData.passport?.passportTypeMvrKey)
            formikRef?.current?.setFieldValue("archiveDate", foreignCitizenData.archiveDate);
            formikRef?.current?.setFieldValue("archiveNumber", foreignCitizenData.archiveNumber);
            formikRef?.current?.setFieldValue("dossierNumber", foreignCitizenData?.dossierNumber);
            formikRef?.current?.setFieldValue("dateOfDeliveryOfDecision", foreignCitizenData.resolution?.dateHanded);
            formikRef?.current?.setFieldValue("startDateStay", ArrayUtils.first(foreignCitizenData?.restrictedStayData)?.startDateOfStay);
            formikRef?.current?.setFieldValue("endDateStay", ArrayUtils.first(foreignCitizenData?.restrictedStayData)?.finalDateOfStay);
            formikRef?.current?.setFieldValue("dateOfExtension", ArrayUtils.first(foreignCitizenData?.restrictedStayData)?.extendedDateOfStay);
            formikRef?.current?.setFieldValue("decision", foreignCitizenData.resolution?.opinionPositive);
            formikRef?.current?.setFieldValue("fileDecision", foreignCitizenData.resolution?.resolutionFile)

        }

    },[registrationData, foreignCitizenData])

    async function createOrUpdateWorkingVersion(){
        setIsLoadingSave(true)
        let data = DiplomatsUserData(formikRef?.current?.values)
      data.submitted = false;
      if(registrationData?.id){
        data.id = registrationData?.id.toString();
       }
       if(registrationData?.workingVersionRegistrationId?.id){
         data.workingVersionId = registrationData.workingVersionRegistrationId.id.toString();
       }
       await ForeignCitizenService.createOrUpdateWorkingVersion(data)
        .then((res) => {
          let route = Routes.DIPLOMATS_FORM_EDIT;
          if(res.workingVersionRegistrationId?.id){
          route = route.replace(":id", res?.workingVersionRegistrationId?.id.toString());
          localStorage.setItem("workingVersionId", res?.workingVersionRegistrationId?.id.toString())
          }
          showSuccessDialog(strings.successfullyAddedWorkingVersionDiplomat, strings.successfullyAddedWorkingVersionDiplomat, strings.ok).then(_ => {
            setIsLoadingSave(false)
            setActiveStep(0)
            if(res?.versions){
              setVersions(res?.versions)
              setVersion(res?.versions[0]?.id.toString() || '')
              navigate(route)
            }
          });
        })
        .catch((e) => {
          const errorMessage = ErrorHandler.parseErrorMessage(e);
          showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
          setActiveStep(steps.length-1)
        });
      }
      const isAnyFieldEntered = formikRef.current?.values?.firstName ||  formikRef.current?.values?.lastName ||  formikRef.current?.values?.archiveNumber;
  return (
    <div>
    {isLoadingData &&
       <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingData}
      >
        <CircularProgress size={60} style={{color:"#3f51b5"}} />
      </Backdrop>
     }
    <SideBarPage pageTitle={strings.diplomatsForm} component={
      <>
          { !isAddMode && <Box component="div" display={"flex"} justifyContent={"space-between"} className="mt-3 mb-4">
          <Box component="div">
              {registrationData?.submitted ? <Box component="span" fontWeight={"bold"} textTransform={"uppercase"}>
                 Поднесена (официјална) верзија на регистрацијата
              </Box> : <LightTooltip customWidth='1000px' title={<div>
                Ве молиме преминете на поднесената (официјална) верзија, доколку сакате да:
                <ul>
                  <li>преземете акции поврзани со матичните броеви</li>
                  <li>пасивизирате/активирате барање</li>
                </ul>
              </div>}>
              <Box component="span" fontWeight={"bold"} textTransform={"uppercase"}>
                 Работна верзија на регистрацијата
              </Box>
              </LightTooltip>}
            </Box>
              <Grid>
                  {hasAuthority && registrationData?.submitted && ( !registrationData?.passivated ? (
                              <Button style={{float:"right"}} variant='contained' onClick={handleOpenRequest}>{strings.passivize}</Button>
                          ) :
                          ( <Button style={{float:"right"}} variant='contained' onClick={handleOpenActivateRequest}>{strings.activate}</Button> )
                  )}
              </Grid>
          </Box> }
          {!isAddMode &&
            <Box component="div" display="flex" justifyContent="start" className='pb-4'>
            <FormControl sx={{ minWidth: 300 }}>
          <InputLabel id="typeOfForms">{strings.version}</InputLabel>
          <Select value={versions && versions.length > 0 && version && versions.some(el => el.id.toString() == version) ? String(version) : ""} onChange={handleChange} labelId="versionOfForms" label={"Верзија"} style={{textAlign:"left"}} defaultValue=''
                  MenuProps={{
                      classes: {
                          paper: classes.dropdown,
                      },
                  }}>
              {versions?.map((item) => (
                <MenuItem value={item?.id} key={item?.id}>
                  {DateTimeUtils.formatDate(item?.dateCreated.toString()) + " - "}
                  {item?.archiveNumber ?
                   item?.archiveNumber + " - " :
                    ""}
                  {item?.evidencyReason?.displayName}
                </MenuItem>
              ))}
          </Select>
      </FormControl>

          </Box> }


          {!isAddMode && registrationData && (
              <Accordion className='mb-3' style={{ width: "41%" }} defaultExpanded={true}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"

                  >
                      <Typography>Статус на матичен број</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 500 }} aria-label="simple table">
                              <TableHead>
                                  <TableRow>
                                      <TableCell>{strings.name}</TableCell>
                                      <TableCell>{strings.embg}</TableCell>
                                      <TableCell>{strings.statusEmbg}</TableCell>
                                      <TableCell>{strings.deceased}</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  <TableRow>
                                      <TableCell>{
                                          registrationData?.foreignCitizen?.personData?.firstName
                                      }
                                      <TableCell>{registrationData?.foreignCitizen?.personData?.lastName}</TableCell>
                                      </TableCell>
                                      <TableCell>{registrationData?.embg}</TableCell>
                                      <TableCell>{registrationData?.registrationStatus?.name ?? strings.noInformation}</TableCell>
                                      <TableCell>{registrationData?.deadInfo !== null ? registrationData.deadInfo : strings.noInformation}</TableCell>
                                  </TableRow>
                              </TableBody>
                          </Table>
                      </TableContainer>
                  </AccordionDetails>
              </Accordion>
          )}
          {!isAddMode && (
          <CreatedByInfo
            label={strings.statusRegistration + ":"}
            status={registrationData?.passivated ? strings.passive : strings.activeStatus}
            statusSuccessful={!registrationData?.passivated}
          />
        )}
       
         {!isAddMode && 
          <div className='d-flex pb-4'>
            {strings.workingUnit + ":" + " " + userDetails?.workingUnit}
          </div>
        }
        {
          !isAddMode &&
          (
            <div>
                <Button style={{float: "left", marginTop: "8px"}} className={"mx-1"} type="submit" variant="contained" color="primary" onClick={handleClickOpen}>{strings.historyOfRegistrationStatuses}</Button>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth={"md"} fullWidth
                >
                  <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
                    {strings.historyOfRegistrationStatuses}
                  </DialogTitle>
                  <DialogContent>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 500 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell> {strings.action}</TableCell>
                            <TableCell> {strings.reason}</TableCell>
                            <TableCell> {strings.dateModified}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            historyActions?.registrationOrEmbgHistoryActions?.map((item:any, index:number)=>{
                              return (
                                <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell>{item.action}</TableCell>
                                <TableCell>{item.reason}</TableCell>
                                <TableCell>{DateTimeUtils.formatDateObject(item.dateModified)}</TableCell>
                              </TableRow>
                              )
                            })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </DialogContent>
                  <DialogActions>
                    <Button className='m-2' type="submit" variant="contained" color="error" onClick={handleCloseDialog}>{strings.close}</Button>
                  </DialogActions>
                </Dialog>
            </div>
          )
        }
          {!isAddMode &&
              <div>
                  <Button style={{float: "left", marginTop: "8px"}} className='m-2' type="submit" variant="contained" color="primary" onClick={handleClickOpen2}>{strings.historyOfEmbgs}</Button>
                  <Dialog
                      open={openDialog2}
                      onClose={handleCloseDialog2}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth={"md"} fullWidth
                  >
                      <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
                          {strings.historyOfEmbgs}
                      </DialogTitle>
                      <DialogContent>
                          <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                  <TableHead>
                                      <TableRow>
                                          <TableCell> {strings.firstName}</TableCell>
                                          <TableCell> {strings.embg}</TableCell>
                                          <TableCell> {strings.action}</TableCell>
                                          <TableCell> {strings.reason}</TableCell>
                                          <TableCell> {strings.dateModified}</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {
                                          historyActionsEMBG?.mainRegistration.registrationOrEmbgHistoryActions?.map((item: any, index: number) => {
                                              return (
                                                  <TableRow
                                                      key={index}
                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                  >
                                                      <TableCell>{historyActionsEMBG?.mainRegistration?.firstName + " " + historyActionsEMBG.mainRegistration.lastName}</TableCell>
                                                      <TableCell>{item.embg}</TableCell>
                                                      <TableCell>{item.action}</TableCell>
                                                      <TableCell>{item.reason}</TableCell>
                                                      <TableCell>{DateTimeUtils.formatDateObject(item.dateModified)}</TableCell>
                                                  </TableRow>
                                              )
                                          })
                                      }
                                      {
                                          historyActionsEMBG?.childRegistrations?.map((item: any, index: number) => {
                                              return (
                                                  item?.registrationOrEmbgHistoryActions?.map((elem: any) => {
                                                      return (
                                                          <TableRow
                                                              key={index}
                                                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                          >
                                                              <TableCell>{item.firstName + " " + item.lastName}</TableCell>
                                                              <TableCell>{elem.embg}</TableCell>
                                                              <TableCell>{elem.action}</TableCell>
                                                              <TableCell>{elem.reason}</TableCell>
                                                              <TableCell>{DateTimeUtils.formatDate(elem.dateModified)}</TableCell>
                                                          </TableRow>
                                                      )

                                                  })
                                              )
                                          })
                                      }
                                  </TableBody>
                              </Table>
                          </TableContainer>
                      </DialogContent>
                      <DialogActions>
                          <Button className='m-2' type="submit" variant="contained" color="error" onClick={handleCloseDialog2}>{strings.close}</Button>
                      </DialogActions>
                  </Dialog>
              </div>
          }
          {!isAddMode && hasAuthority && registrationData?.submitted && registrationData?.embg && (!(registrationData.registrationStatus?.key === "active")) &&
              <div style={{ clear: "both" }}>
                  <Button disabled={registrationData?.registrationStatus?.key === RegistrationStatuses.ACTIVE || registrationData?.registrationStatus?.key === RegistrationStatuses.PASSIVE} style={{ float: "left" }} variant='contained' className='m-2' onClick={handleOpenActivateEMBG}>{strings.activateEmbg}</Button>
              </div>
          }
          {
              !isAddMode && id && hasAuthority && registrationData?.submitted &&
              <div style={{ float: "left" }}>
                  <Button disabled={registrationData?.registrationStatus?.key !== RegistrationStatuses.ACTIVE} variant='contained' className='m-2' onClick={handleOpen}>{strings.passivateEMBGForeign}</Button>
                  <Button disabled={registrationData?.registrationStatus?.key !== RegistrationStatuses.ACTIVE} variant='contained' className='m-2' onClick={handleOpenConfirmationModal}>{strings.onHoldEMBG}</Button>
              </div>
          }

          {isLoadingSave && (
              <CircularProgress
                  style={{ float: "right" }} className={"mb-2"}
                  size={32}
              />
          )
        }
          <AutoScroller style={{float: "right", marginBottom: "20px"}} direction={false} />

          {!registrationData?.submitted && <Button style={{float:"right", marginRight: "5px"}} className='mb-2' type="submit" variant="contained" color="primary" disabled={isLoadingSave || !!disabledSaveButton} onClick={createOrUpdateWorkingVersion}>{strings.save}</Button>}
        {!isAddMode && id &&
      <div className="sticky top-0 z-99">
      <RightSidePanel diplomatForm={true} templateDTOs={diplomatsTemplates} registrationFiles={registrationFiles} id={parseInt(id)}/>
      </div>
      }

      <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"}>
    <React.Fragment>
        <div style={{fontSize:"12px", color:"red"}}>Формата содржи задолжителни полиња на 1-та и 2-та страница означени со ѕвезда (*)</div>
        <Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
        {steps.map((step, index) => (
          <Step key={index} completed={step.completed}>
            <StepButton onClick={()=>handleStepValidation(index)}><div style={{ fontWeight: activeStep === index ? 'bold' : 'normal' }}>{step.name}</div></StepButton>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
      {isLoading ? (
         <div className="mt-3" style={{display:"flex", justifyContent:"center"}}>
          <CircularProgress
           size={40}
       /></div>
        ) :
        activeStep === steps.length ? (
        <div className='p-3'>

        </div>
        ) : (
          <Formik
            initialValues={lastSubmittedValues || DiplomatsFormInitalValues || {}}
            validationSchema={currentValidationSchema}
            onSubmit={handleSubmit} 
            innerRef={formikRef}
            validateOnChange={true}
          >
             
            {({ isSubmitting }) => {
              return (
              <Form id={formId}>
                {renderStepContent(activeStep)}
                
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                        {strings.prev}
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                  {isLastStep && !hasAuthority ? <Box /> : <Button
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            {isLastStep ? strings.end : strings.next}
                          </Button>}
                    {(isSubmitting || isLoading) && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
              )
    }}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
    </CenteredCardLayout>
          <EMBGModal open={openRequest} handleClose={handleCloseRequest} isPassivateRequest={true}/>
          <EMBGModal registrationData={registrationData} open={open} handleClose={handleClose} isAsylum={false} isPasivise={true} isForeign={true} />
          <EMBGModal registrationData={registrationData} open={openConfirmation} handleClose={handleCloseConfirmationModal} isAsylum={false} isOnHold={true} isForeign={true} />
          <EMBGModal registrationData={registrationData} open={openActivateModal} handleClose={handleCloseActivateEMBG} isAsylum={false} isForeign={true} isActivate={true} />
          <ConfirmationModal
              open={openActivateRequest}
              handleClose={() => handleCloseActivateRequest(false)}
              handleConfirm={activateRequest}
              title={strings.activateRequest}
              content={strings.activateRequestConfirm}
              actionButtonName={strings.activate}
              variant={"contained"}
              color={"success"}
          />
          <ConfirmationModal
              open={openDeceasedModal}
              handleClose={handleCloseDeceasedModal}
              handleConfirm={markAsDeceased}
              title={strings.deceasedForm}
              content={strings.deceasedText}
              actionButtonName={strings.mark}
              variant={"contained"}
              color={"success"}
          />
          <ConfirmationModal
              open={openCheckModal}
              handleClose={handleCloseCheckModal}
              handleConfirm={() => {
                  setOpenCheckModal(false);
                  updateDiplomat(values);
              }}
              title={strings.saveChanges}
              content={isPasivise? strings.checkBirthDateAndGender : strings.notPassivatedcheckBirthDateAndGender}
              actionButtonName={strings.submit}
              variant={"contained"}
              color={"success"}
              isPassive = {isPasivise}
          />
          <ConfirmationModal
              open={openBasicDataChangesModal}
              handleClose={handleCloseBasicDataChangesModal}
              title={strings.saveChanges}
              content={
                  <div>
                      {strings.correctionChangeMessage}
                      <div className="d-flex justify-content-between mt-3">
                          <Button onClick={() => {handleClickChange()}}>{strings.change}</Button>
                          <Button onClick={() => {handleClickCorrection()}}>{strings.correction}</Button>
                      </div>
                  </div>
              }
              variant={"contained"}
              color={"success"}
          />
          <AutoScroller style={{float: "right", marginTop: "20px"}} direction={true}/>
      </>

    }

    />
    </div>

  );
}
