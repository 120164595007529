
export class LoginSessionDTO{
    id : number;
    loginDate : string;
    logoutDate : string;


    constructor(json:any) {
        this.id = json.id;
        this.loginDate = json.loginDate;
        this.logoutDate = json.logoutDate;

    }
}