import * as React from 'react';
import {ReactElement, useEffect, useState} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { strings } from "../localization/Localization";
import { Routes } from "../router/Routes";
import {AccountCircle, HistoryEdu} from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { StorageService } from "../storage/StorageService"
import { SideBar } from "./SideBar";
import { SideBarItem } from '../models/SideBarItem';
import LogoutIcon from '@mui/icons-material/Logout';
import Person2Icon from '@mui/icons-material/Person2';
import TransliterationIcon from '@mui/icons-material/Language';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate } from "react-router-dom";
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import NotesIcon from '@mui/icons-material/Notes';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { UserService } from "../services/UserService";
import { EnvironmentUtils } from "../environment/EnvironmentUtils";
import {TransliterationService} from "../services/TransliterationService";
import HistoryIcon from '@mui/icons-material/History';
import {UserDetailsInfoResponse} from "../models/UserDetailsInfoResponse";
import {AuthService} from "../services/AuthService";
const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}
const MainContent = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

interface PersistentDrawerLeftProps {
    title: string;
    component: ReactElement;
    hasAuthority?: boolean;
    manageDiplomats?: boolean;
    readShortTerm?: boolean;
    manageNomenclatures?: boolean;
    permitsReports?: boolean;
}

export default function PersistentDrawerLeft(props: PersistentDrawerLeftProps) {
    const navigate = useNavigate();

    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const auth = true;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [userDetails,setUserDetails] = useState<UserDetailsInfoResponse>();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        function getUserDetails(){
            AuthService.getUserDetails().then((res)=>{
                setUserDetails(res)
            })
        }

        async function getData(){
            await getUserDetails();
        }

        getData().then(_ => {
        });

    }, [])

    async function logoutInternalUser(): Promise<void> {
        await UserService.logLogoutDate();
        StorageService.clearAuthToken();
        StorageService.clearInternalAuthToken();
        StorageService.removeUserRoles();
        window.location.href = EnvironmentUtils.routerBase();
    }

    function logoutExternalUser(): void {
        navigate(Routes.LOGOUT);
    }

    const handleLogout = async () => {
        if (EnvironmentUtils.internalAuth()) {
            await logoutInternalUser();
        } else {
            logoutExternalUser();
        }
    }

    //maybe we can use it when page is open
    function buildItems(): SideBarItem[] {
        const items: SideBarItem[] = []

        if (props.hasAuthority) {
            items.push(
                new SideBarItem(
                    strings.foreignersSubmittedForms,
                    Routes.FOREIGNERS_SUBMITTED_FORMS,
                    <AppRegistrationIcon />,
                    []
                )
            );
        }

        if (props.hasAuthority) {
            items.push(
                new SideBarItem(
                    strings.foreignersSubmittedFormsPending,
                    Routes.FOREIGNERS_SUBMITTED_FORMS_PENDING,
                    <HistoryIcon />,
                    []
                )
            );
        }

        if (props.manageDiplomats) {
            items.push(
                new SideBarItem(
                    strings.diplomatsRegister,
                    Routes.DIPLOMATS_SUBMITTED_FORMS,
                    <AppRegistrationIcon />,
                    []
                )
            );
        }

        if (props.manageDiplomats) {
            items.push(
                new SideBarItem(
                    strings.diplomatsRegisterPending,
                    Routes.DIPLOMATS_SUBMITTED_FORMS_PENDING,
                    <HistoryIcon />,
                    []
                )
            );
        }

        if (props.readShortTerm) {
            items.push(
                new SideBarItem(
                    strings.shortStayForms,
                    Routes.FOREIGNERS_SUBMITTED_SHORT_STAY_FORMS,
                    <AppRegistrationIcon />,
                    []
                )
            );
        }

        if (props.readShortTerm) {
            items.push(
                new SideBarItem(
                    strings.shortStayFormsPending,
                    Routes.FOREIGNERS_SUBMITTED_SHORT_STAY_FORMS_PENDING,
                    <HistoryIcon />,
                    []
                )
            );
        }

        if (props.hasAuthority) {
            items.push(
                new SideBarItem(
                    strings.registrationDecisionsRegister,
                    Routes.FOREIGNERS_SUBMITTED_FORM_DECISIONS,
                    <HistoryEdu />,
                    []
                )
            );
        }

        if (props.manageNomenclatures) {
            items.push(
                new SideBarItem(
                    strings.nomenclatures,
                    "",
                    <NotesIcon />,
                    [
                        new SideBarItem(
                            strings.editNomenclatures,
                            Routes.NOMENCLATURES,
                            <EditAttributesIcon />,
                            []
                        ),
                    ]
                )
            );
        }

        if(props.permitsReports) {
        items.push(
            new SideBarItem(
                strings.reports,
                "",
                <PrintIcon />,
                [
                    new SideBarItem(
                        strings.reportsNumberOfPermits,
                        Routes.REPORTS_NUMBER_OF_PERMITS,
                        <PrintIcon />,
                        []
                    ),
                    // new SideBarItem(
                    //     "Азил - Лице со статус на признаен бегалец кои во тековниот месец имаат активен статус",
                    //     Routes.ASYLUM_REPORTS,
                    //     <PrintIcon />,
                    //     []
                    // ),
                ]
            )
        );
        }
        
        return items;

    }

    const items = buildItems();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    function handleOpenProfile() {
        navigate(Routes.PROFILE);
    }
    function handleOpenTransliteration() {
        async function getTransliterationFile(){
            await TransliterationService.getTransliterationFile();
        }

        getTransliterationFile().then(()=>{})
    }

    function getProfileString(): string {
        if (!userDetails) {
            return "";
        }

        if (userDetails.fullName) {
            return `${userDetails.fullName} - ${userDetails.username}`;
        }

        return userDetails.username ?? "";
    }

    return (
        <>
            <Box sx={{ display: 'flex', minHeight: "900px" }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {props.title}
                        </Typography>
                        {auth && (
                            <div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <div style={{paddingRight: "7px", display: "flex", alignItems: "center"}}>
                                        <div>
                                            {getProfileString()}
                                        </div>
                                    </div>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                            </div>

                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >

                                    <MenuItem onClick={handleOpenProfile}>
                                        <ListItemIcon>
                                            <Person2Icon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {strings.profile}
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={handleOpenTransliteration}>
                                        <ListItemIcon>
                                            <TransliterationIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {strings.transliteration}
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                    >
                                        <ListItemIcon>
                                            <TextSnippetIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {strings.guideline}
                                        </ListItemText>
                                    </MenuItem>

                                    <MenuItem
                                        onClick={handleLogout}
                                    >
                                        <ListItemIcon>
                                            <LogoutIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {strings.logout}
                                        </ListItemText>
                                    </MenuItem>

                                </Menu>
                            </div>
                        )}
                    </Toolbar>

                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    {items.map((sideBarItem, index) => (
                        <SideBar item={sideBarItem} key={index} />
                    ))}
                </Drawer>
                <MainContent open={open}>
                    <DrawerHeader />
                    {/* <BreadcrumbsComponent listLinks={props.breadcrumbs}/> */}
                    {props.component}
                </MainContent>
            </Box>
        </>
    );
}
