import { RegistrationHistoryActionsDetailsDTO } from "./RegistrationHistoryActionsDetailsDTO";

export class RegistrationHistoryActionsDTO {
    mainRegistration: RegistrationHistoryActionsDetailsDTO | undefined;
    childRegistrations?: RegistrationHistoryActionsDetailsDTO[];
    constructor(json:RegistrationHistoryActionsDTO){
        this.mainRegistration = json.mainRegistration ? new RegistrationHistoryActionsDetailsDTO(json.mainRegistration) : undefined;
        this.childRegistrations = json.childRegistrations?.map((item:any)=>new RegistrationHistoryActionsDetailsDTO(item)?? [])

    }
    }