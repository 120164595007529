import { OrganizationCreationDTO } from "./OrganizationCreationDTO";

export class EmploymentDataCreationDTO{
    employmentApproval:boolean | null;
    employmentStatusMvrKey:string;
    employmentSectorMvrKey:string;
    occupationFieldMvrKey:string;
    organizationCreationDTO:OrganizationCreationDTO;
    employmentPositionMvrKey:string;
    engagementTypeMvrKey:string;
    constructor(json:EmploymentDataCreationDTO){
        this.employmentApproval = json.employmentApproval;
        this.employmentStatusMvrKey = json.employmentStatusMvrKey;
        this.employmentSectorMvrKey = json.employmentSectorMvrKey;
        this.occupationFieldMvrKey = json.occupationFieldMvrKey;
        this.organizationCreationDTO = json.organizationCreationDTO;
        this.employmentPositionMvrKey = json.employmentPositionMvrKey;
        this.engagementTypeMvrKey = json.engagementTypeMvrKey;
    }
}