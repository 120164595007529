import {concatenateUrlPaths} from "asseco-utils";

const getUrlRoot = () => {
    return concatenateUrlPaths(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
        `${process.env.REACT_APP_ROUTER_BASE && process.env.REACT_APP_ROUTER_BASE !== "/" ? process.env.REACT_APP_ROUTER_BASE : ``}`);
}

export const properties = {
    oidcSilentRenew: concatenateUrlPaths(getUrlRoot(),`silent_renew`),
    oidcLogoutRedirect: getUrlRoot(),
    oidcRedirectUrl: concatenateUrlPaths(getUrlRoot(),`callback`),
    oidcAuthority: process.env.REACT_APP_AUTHORITY,
    //oidcClientId: process.env.KEYCLOAK_CLIENT_ID,
    oidcClientId: 'stranci-azil-app',
    oidcScope: 'openid profile',
    portalApp: process.env.REACT_APP_PORTAL,
    api: {
        mock: false,
        root: process.env.REACT_APP_API_ROOT
    },
    auth: {
        mock: false
    },
    maxInactivityTime: 900000,//900000ms = 15 min
    checkGroupMatch: true,
    //groupName: "search_users",
}
