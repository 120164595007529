import { RegistrationTypes } from "../../constants/RegistrationTypes";
import { StorageService } from "../../storage/StorageService";
import {NomenclatureConstants} from "../../constants/NomenclatureConstants";
export function ShortTermUserData(values:any, correctionOfPersonData? : boolean | null){
const foreignCitizenCreationDTO = values;
let languages = [];
if (foreignCitizenCreationDTO.foreign_languages !== undefined) {
  languages = foreignCitizenCreationDTO.foreignLanguage.map((element: any) => {
    return { languageMvrKey: element }
  })
}
const data =  {
processedBy:StorageService.getJWTPayload()?.sub,
registrationTypeMvrKey: RegistrationTypes.temporaryStay,
placeOfRequestMvrKey: foreignCitizenCreationDTO.placeOfRequest?.key,
foreignCitizenData:{
active:true,
evidencyReasonMvrKey:foreignCitizenCreationDTO.evidencyReasonMvrKey,
settlementReasonMvrKey:foreignCitizenCreationDTO.settlementReasonMvrKey,
temporaryStayReportedByMvrKey: foreignCitizenCreationDTO.temporaryStayReportedByMvrKey,
temporaryStaySettlementReasonMvrKey: foreignCitizenCreationDTO.temporaryStaySettlementReasonMvrKey,
personDataCreationDTO:{
  embg:foreignCitizenCreationDTO.embg,
  firstName:foreignCitizenCreationDTO.firstName,
  firstNameCyrillic: foreignCitizenCreationDTO.firstNameCyrillic,
  lastName:foreignCitizenCreationDTO.lastName,
  lastNameCyrillic: foreignCitizenCreationDTO.lastNameCyrillic,
  genderMvrKey:foreignCitizenCreationDTO.genderMvrKey,
  birthDate:foreignCitizenCreationDTO.birthDate,
  countryDTO: {
      mvrKey: "",
      isoName:null,
      iso2:null,
      iso3:null,
      nameSq:null,
      nameEn:null,
  },
  birthPlaceDTO:{
      mvrKey:foreignCitizenCreationDTO.inRSMBirthPlacePlace?.key,
      countryDTO:{
          mvrKey:foreignCitizenCreationDTO.abroadBirthPlace ? foreignCitizenCreationDTO?.abroadBirthPlace?.key : NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:foreignCitizenCreationDTO.inRSMBirthMunicipality?.key,
          name:null,
          nameSq:null,
          nameEn:null
  },
    shortTermStayPossibilityMvrKey: foreignCitizenCreationDTO.shortTermStayPossibilityMvrKey,
    hotel: foreignCitizenCreationDTO.hotel,
    maidenName:foreignCitizenCreationDTO.maidenName,
  parentName:foreignCitizenCreationDTO.parentName,
  parentNameCyrillic: foreignCitizenCreationDTO.parentNameCyrillic,
  occupationMvrKey:foreignCitizenCreationDTO.occupation,
  professionalTrainingMvrKey:foreignCitizenCreationDTO.education,
  marriageStatusMvrKey:foreignCitizenCreationDTO.marriageStatus,
  numberOfAdultFamilyMembers:null,
  numberOfForeignChildren:null,
  numberOfDomesticChildren:null,
  ageGroupMvrKey:null,
  personLanguageCreationDTOs: languages,

},
passportCreationDTO:{
  passportTypeMvrKey:foreignCitizenCreationDTO.passportTypeMvrKey,
  countryDTO:{
          mvrKey:foreignCitizenCreationDTO.countryPassport?.key,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
  passportNumber:foreignCitizenCreationDTO.passportNumber
},
visaCreationDTO:{
    visaTypeMvrKey:foreignCitizenCreationDTO.visaTypeMvrKey,
    countryCreationDTO:{
        mvrKey:foreignCitizenCreationDTO?.countryVisa?.key,
        isoName:null,
        iso2:null,
        iso3:null,
        nameSq:null,
        nameEn:null
    },
    visaNumber:foreignCitizenCreationDTO.visaNumber
},
    approvedStayDate: foreignCitizenCreationDTO.approvedStayDate,
entranceDataCreationDTO:{
  entranceDate:foreignCitizenCreationDTO.entranceDate,
  borderPointMvrKey:foreignCitizenCreationDTO.borderPointMvrKey
},
hostDTO:{
  citizenshipCreationDTO:{
      mvrKey:foreignCitizenCreationDTO.citizen?.key,
      statKey:null,
      isoName:null,
      iso2:null,
      iso3:null,
      nameSq:null,
      nameEn:null,
      countryDTO:{
          mvrKey:"",
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          }
  },
  // embg:foreignCitizenCreationDTO.embgPrivateVisitPerson,
  embg:"",
  firstName:foreignCitizenCreationDTO.firstNamePrivateVisitPerson,
  lastName:foreignCitizenCreationDTO.lastNamePrivateVisitPerson,
  genderMvrKey:foreignCitizenCreationDTO.genderPrivateVisitPerson,
  birthDate:null,
  birthPlaceDTO:{
      mvrKey:"",
      countryDTO:{
          mvrKey:"",
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:"",
          name:null,
          nameSq:null,
          nameEn:null
  },
  maidenName:null,
  parentName:null,
    addressDTO:{
      streetDTO:{
          placeDTO:{
      mvrKey:foreignCitizenCreationDTO.placePrivateVisitPerson?.key,
      countryDTO:{
          mvrKey: NomenclatureConstants.macedoniaMvrKey,
          isoName:null,
          iso2:null,
          iso3:null,
          nameSq:null,
          nameEn:null
          },
          municipalityName:null,
          municipalityMvrKey:foreignCitizenCreationDTO.municipalityPrivateVisitPerson?.key,
          name:null,
          nameSq:null,
          nameEn:null
      },
      mvrKey:foreignCitizenCreationDTO.streetPrivateVisitPerson?.key,
      name:null,
      nameSq:null,
      nameEn:null
      },
      streetNumber:foreignCitizenCreationDTO.numberStreetPrivateVisitPerson,
      snMvrKey:null,
      entranceNumber: foreignCitizenCreationDTO.apartmentEntrancePrivateVisitPerson,
      apartmentNumber: foreignCitizenCreationDTO.apartmentPrivateVisitPerson
  },
    pastAddress:{
        streetDTO:{
            placeDTO:{
                mvrKey:foreignCitizenCreationDTO.pastPlacePrivateVisitPerson?.key,
                countryDTO:{
                    mvrKey: NomenclatureConstants.macedoniaMvrKey,
                    isoName:null,
                    iso2:null,
                    iso3:null,
                    nameSq:null,
                    nameEn:null
                },
                municipalityName:null,
                municipalityMvrKey:foreignCitizenCreationDTO.pastMunicipalityPrivateVisitPerson?.key,
                name:null,
                nameSq:null,
                nameEn:null
            },
            mvrKey:foreignCitizenCreationDTO.pastStreetPrivateVisitPerson?.key,
            name:null,
            nameSq:null,
            nameEn:null
        },
        streetNumber:foreignCitizenCreationDTO.pastNumberStreetPrivateVisitPerson,
        snMvrKey:null,
        entranceNumber: foreignCitizenCreationDTO.pastApartmentEntrancePrivateVisitPerson,
        apartmentNumber: foreignCitizenCreationDTO.pastApartmentPrivateVisitPerson
    },
  ageGroupMvrKey:null
},
timeStart: foreignCitizenCreationDTO.timeStart,
timeEnd: foreignCitizenCreationDTO.timeEnd,
note:foreignCitizenCreationDTO.noteForeigner,
},
submitted: false, 
workingVersionId: "",
correctionOfPersonData: correctionOfPersonData,
id: "",
}
return data;
}
