/**
 * Table body for PageTable
 *
 * @param {Object} props Function properties.
 * @param {String} props.link Link for the on click action for the row.
 * @param {Array} props.rows Table rows.
 *

 */

import {Box, Icon, Switch, TableBody, TableCell, TableRow, Tooltip, Typography} from "@mui/material";
import React, {useLayoutEffect} from "react";
import {useNavigate} from "react-router-dom";

import Button from "@mui/material/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {strings} from "../../localization/Localization";

export function PageTableBody(props: any) {
    const navigate = useNavigate();
    const [showAllItems, setShowAllItems] = React.useState(true);

    function handleOpen(id?: number, workingVersionId?: number) {
        props.handleOpen(id, workingVersionId)
    }

    function handleShowAllItems(id: number) {
        //  showAllItems === id ? setShowAllItems("") : setShowAllItems(id);
    };

    function hasData(): boolean {
        return (props.rows?.length ?? 0) > 0;
    }

    return (
        <TableBody>
            {
                !hasData() && <TableRow>
                    <TableCell colSpan={props.columns}>
                        <Box display="flex" justifyContent="center" width="100%">
                            <Typography variant={"h6"}>
                                {props.noDataText ?? strings.noDataFound}
                            </Typography>
                        </Box>
                    </TableCell>
                </TableRow>
            }
            {hasData() && props.rows?.map((row: any, index: number) => {
                return (
                    <TableRow
                        className="h-72 cursor-pointer"
                        hover
                        tabIndex={-1}
                        key={index}
                        onClick={() => (props.clickable ? handleOpen(row.id, row?.workingVersionId) : null)}
                    >
                        {row.values.map((value: any) => {
                            return (
                                <React.Fragment key={value.name}>
                                    {value.type === "text" && (
                                        <TableCell
                                            className="p-4 sm:p-16"
                                            component="th"
                                            scope="row"
                                        >
                                            {value.value}
                                        </TableCell>
                                    )}
                                    {value.type === "boolean" && (
                                        <TableCell
                                            className="p-4 sm:p-16"
                                            component="th"
                                            scope="row"
                                            align="left"
                                        >
                                            {value.value ? (
                                                <CheckCircleIcon color={value.color}/>
                                            ) : (
                                                <CancelIcon color={value.color}/>
                                            )}
                                        </TableCell>
                                    )}
                                    {value.type === "list" && (
                                        <TableCell
                                            className="p-4 sm:p-16"
                                            component="th"
                                            scope="row"
                                            onClick={() => handleShowAllItems(row.id)}
                                        >
                                            <ol>
                                                {value.value.map((item: any, index: number) => {
                                                    return (
                                                        <li key={index}>
                                                            {item}
                                                        </li>
                                                    );
                                                })}
                                            </ol>
                                        </TableCell>
                                    )}

                                    {value.type === "button" && (
                                        <TableCell
                                            align={value.align}
                                        >
                                            {value.buttons.map((but: any) => {
                                                return (
                                                    <Button
                                                        key={but.id}
                                                        variant="contained"
                                                        color={but.color || 'primary'}
                                                        onClick={() => but.fun(row.id)}
                                                        className="mr-20"
                                                        aria-label="button"
                                                    >
                                                        {but.value}
                                                    </Button>
                                                )
                                            })}

                                        </TableCell>
                                    )}
                                    {value.type === "switch" && (
                                        <TableCell>
                                            {value.buttons.map((but: any) => {
                                                return (
                                                    <>
                                                        {value.value + " "}

                                                        <Switch
                                                            color="success"
                                                            checked={but.checked}
                                                            onChange={() => but.fun(row.id)}
                                                        />
                                                    </>
                                                )
                                            })}
                                        </TableCell>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );
}

export default PageTableBody;

