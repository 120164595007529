import { PersonDTO } from "./PersonDTO";

export class UserReducedDTO{
    id : number;
    dateCreated : Date;
    dateModified : Date;
    email : string;
    personDTO?: PersonDTO;

    constructor(json:UserReducedDTO) {
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.email = json.email;
        this.personDTO = json.personDTO ? new PersonDTO(json.personDTO) : undefined;
    }
}
