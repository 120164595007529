import { CompanyResponseDTO } from "../models/CompanyResponseDTO";
import { PersonDataReducedDTO } from "../models/PersonDataReducedDTO";
import axiosInstance from "../network/AxiosInstance";
import { AuthService } from "./AuthService";
import {LivingPlaceResponseDTO} from "../models/LivingPlaceResponseDTO";

export class ExternalService {
    static async getPersonInformationByEmbg(embg:string):Promise<PersonDataReducedDTO>{
      const response = await axiosInstance.get<PersonDataReducedDTO>(`${AuthService.PREFIX}/external-service/person-data/${embg}`);
      const data = response.data;
      return new PersonDataReducedDTO(data);
    }

    static async getLivingPlaceByEmbg(embg:string):Promise<LivingPlaceResponseDTO>{
        const response = await axiosInstance.get<LivingPlaceResponseDTO>(`${AuthService.PREFIX}/external-service/living-place/${embg}`);
        const data = response.data;
        return new LivingPlaceResponseDTO(data);
    }

    static async getCompanyByEmbs(embs:string):Promise<CompanyResponseDTO>{
      const response =   await axiosInstance.get<CompanyResponseDTO>(`${AuthService.PREFIX}/external-service/company-info/${embs}`);
      const data = response.data;
      return new CompanyResponseDTO(data);
    }
}