import {Paper, Table, TableContainer, TableHead, TablePagination} from "@mui/material";
import PageTableHead from "./PageTableHead";
import PageTableBody from "./PageTableBody";
import {useLayoutEffect} from "react";
import { strings } from "../../localization/Localization";

export function PageTable(props:any) {
    useLayoutEffect(() => {
    }, [props.firstSearchRow, props.secondSearchRow, props.bottomRows])

    function handleOpen(id?:number, workingVersionId?: number) {
        props.handleOpen(id, workingVersionId)
    }

    const labelDisplayedRows = ({ from, to, count }: any) => {
        return `${from}-${to} од ${count}`;
      };

    return (
        <div className="w-full flex flex-col">
                <TableContainer component={Paper} elevation={3} className={"mt-3"}>
                <Table stickyHeader className="min-w-xl" aria-labelledby="tableTitle">
                    <TableHead>
                        <PageTableHead headerRows={props.headerRows} firstSearchRow={props.firstSearchRow} secondSearchRow={props.secondSearchRow} thirdSearchRow={props.thirdSearchRow}  bottomRows={props.bottomRows} headerHidden={props.headerHidden}/>
                    </TableHead>
                    <PageTableBody
                                   rows={props.rows}
                                   link={props.link}
                                   handleOpen={handleOpen}
                                   clickable={props.clickable}
                                   data={props.data}
                                   noDataText={props.noDataText}
                                   columns={props.headerRows?.length ?? 0}
                                   modalComponent={props.modalComponent}
                                   modalFunction={props.modalFunction}
                                   />
                </Table>
                {!props.withoutPagination &&
                <TablePagination
                    component="div"
                    rowsPerPageOptions={ props.minimalOptions ? [2, 3, 5] : props.reducedOptions ? [5, 10] : [5, 10, 25]}
                    labelRowsPerPage={strings.rowsPerPage}
                    labelDisplayedRows={labelDisplayedRows}
                    count={props.totalElements}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleChangeRowsPerPage}
                    sx={{
                        "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
                            marginTop: "15px"
                        }
                    }}
                />}
                </TableContainer>
        </div>
    )
}

