export class PersonDTO{
    id : number;
    firstName : string;
    lastName : string;
    phoneNumber : string;

    constructor(json:any) {
        this.id = json.id;
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.phoneNumber = json.phoneNumber;
    }
}
