import SideBarWithContent from "./SideBarWithContent";
import { ReactElement, useEffect, useState } from "react";
import { Page } from "./page/Page";
import { Privileges } from "../models/nomenclatures/Privileges";
import { StorageService } from "../storage/StorageService";
import { AuthService } from "../services/AuthService";
import * as React from "react";
import {VersionService} from "../services/registrations/VersionService";
import {strings} from "../localization/Localization";

export interface SideBarPageProps {
    component: ReactElement,
    pageTitle: string,
    titleBarTitle?: string,
}

export function SideBarPage(props: SideBarPageProps) {
    const [hasAuthority, setHasAuthority] = useState<boolean>();
    const [readShortTerm, setReadShortTerm] = useState<boolean>();
    const [manageNomenclatures, setManageNomenclatures] = useState<boolean>();
    const [permitsReports, setPermitsReports] = useState<boolean>();
    const [manageDiplomats, setManageDiplomats] = useState<boolean>();

    const [appVersion, setAppVersion] = useState<string>();
    const [apiVersion,setApiVersion] = useState<string>();

    useEffect(() => {
        async function userHasAuthority(): Promise<void> {
            const userRoles: string[] | null = StorageService.getUserRoles();
            if (userRoles !== null) {
                if (userRoles.includes(Privileges.READ_ASYLUM_REQUEST) || userRoles.includes(Privileges.READ_FOREIGN_CITIZEN)
                    || userRoles.includes(Privileges.READ_SHORT_TERM_STAY) || userRoles.includes(Privileges.MANAGE_FOREIGN_CITIZEN) || userRoles.includes(Privileges.MANAGE_NOMENCLATURES) || userRoles.includes(Privileges.MANAGE_DIPLOMAT)) {
                    if (userRoles.includes(Privileges.READ_ASYLUM_REQUEST) || userRoles.includes(Privileges.READ_FOREIGN_CITIZEN)) {
                        setHasAuthority(true)
                    }
                    if (userRoles.includes(Privileges.READ_SHORT_TERM_STAY)) {
                        setReadShortTerm(true)
                    }
                    if (userRoles.includes(Privileges.READ_REPORTS_FOREIGN_CITIZEN)){
                        setPermitsReports(true)
                    }
                    if(userRoles.includes(Privileges.MANAGE_NOMENCLATURES)) {
                        setManageNomenclatures(true)
                    }
                    if(userRoles.includes(Privileges.MANAGE_DIPLOMAT)) {
                        setManageDiplomats(true)
                    }
                } 
            } else {
                const authorization = await AuthService.getAuthorization();
                if (authorization.roles.includes(Privileges.READ_ASYLUM_REQUEST) || authorization.roles.includes(Privileges.READ_FOREIGN_CITIZEN) 
                    || authorization.roles.includes(Privileges.READ_SHORT_TERM_STAY) || authorization.roles.includes(Privileges.MANAGE_FOREIGN_CITIZEN) || authorization.roles.includes(Privileges.MANAGE_NOMENCLATURES) || authorization.roles.includes(Privileges.MANAGE_DIPLOMAT)) {
                    if (authorization.roles.includes(Privileges.READ_ASYLUM_REQUEST) || authorization.roles.includes(Privileges.READ_FOREIGN_CITIZEN)) {
                        setHasAuthority(true)
                    }
                    if (authorization.roles.includes(Privileges.READ_SHORT_TERM_STAY)) {
                        setReadShortTerm(true)
                    }
                    if (authorization.roles.includes(Privileges.MANAGE_NOMENCLATURES)) {
                        setManageNomenclatures(true)
                    }
                    if (authorization.roles.includes(Privileges.READ_REPORTS_FOREIGN_CITIZEN)){
                        setPermitsReports(true)
                    }
                    if(authorization.roles.includes(Privileges.MANAGE_DIPLOMAT)) {
                        setManageDiplomats(true)
                    }
                }
            }
        }

        userHasAuthority().then((_) => { });
        VersionService.getApplicationVersion().then((data) => setAppVersion(data.version));
        VersionService.getApiVersion().then((data) => setApiVersion(data.version));
    }, []);


    return (
        <Page
            title={props.pageTitle}
            component={
                <div style={{height: "100%"}}>
                    <SideBarWithContent
                        hasAuthority={hasAuthority}
                        manageDiplomats={manageDiplomats}
                        readShortTerm={readShortTerm}
                        manageNomenclatures={manageNomenclatures} 
                        permitsReports={permitsReports}
                        title={props.titleBarTitle ?? props.pageTitle}
                        component={props.component}
                    />
                    <div className="me-4">
                        <div className="text-end" style={{bottom: 0, width: "100%"}}><h6 className="text-black-50"><span className="fw-bold" >{strings.app} </span>{appVersion} <span className="fw-bold" >{strings.api} </span>{apiVersion} </h6></div>
                    </div>
                </div>
            }
        />
    )
}
