import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import { FormControl, FormHelperText, IconButton, InputLabel, MenuItem, MenuProps, OutlinedInput, Select } from '@mui/material';
import { Clear } from '@mui/icons-material';
import {NomenclatureEntryDTO} from "../../../models/nomenclatures/NomenclatureEntryDTO";
type Element = {
  id?: number;
  dateCreated?: string;
  dateModified?: string;
  key?: string;
  name?: string;
  nameSq?: string;
  nameEn?: string;
  active?: boolean | null;
}
interface Props {
  name: string;
  label: string;
  data: Element[],
  className?: string,
  disabled?: boolean;
  read?: boolean;
  onChange?: (event: any) => void;
  onResetFamilyRelation?: (event: any) => void;
}
function SelectField(props: Props) {
  const { label, data, name, disabled, read, onChange, onResetFamilyRelation, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const { setValue } = helpers;
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }
  function getCustomMenuProps(): Partial<MenuProps> {
    return {
      PaperProps: {
        style: {
          width: "37%"
        },
      },
    };
  }
  const handleChange = (event: any) => {
    setValue(event.target.value as string);

    if (onResetFamilyRelation) {
      onResetFamilyRelation(event.target.value); 
    }

    if (onChange) {
      onChange(event);
    }
  };

  const handleClear = () => {
    setValue(null);
    if (onChange) {
      const event = {
        target: {
          name: name,
          value: null,
        },
      };
      onChange(event);
    }
  };

  const enrichedData = [
      ...data,
  ];

  if (selectedValue && !enrichedData.find((item) => item.key === selectedValue)) {
    enrichedData.push(NomenclatureEntryDTO.fromKey(selectedValue));
  }

  return (
      <FormControl {...rest} error={isError} fullWidth className={props.className} disabled={disabled}>
        <InputLabel shrink={selectedValue ? true : false}>{label}</InputLabel>
        <Select {...field} value={enrichedData.length > 0 && selectedValue ? String(selectedValue) : ""} defaultValue={""} endAdornment={
            (selectedValue && !disabled) && (
                !read && <IconButton
                    onClick={handleClear}
                >
                  <Clear />
                </IconButton>
            )
        }
                MenuProps={getCustomMenuProps()}
                SelectDisplayProps={{
                  style: {
                    minWidth: 100,
                    maxWidth: 720
                  }
                }}

                input={
                  <OutlinedInput
                      label={label}
                      notched={selectedValue ? true : false}
                  />
                }
                readOnly={read ? true : false}
                onChange={handleChange}
        >
          {enrichedData.map((item: any, index: number) => (
              <MenuItem key={item.id} value={item.key} disableRipple>
                {item.name}
              </MenuItem>
          ))}
        </Select>
        {_renderHelperText()}
      </FormControl>
  );
}

SelectField.defaultProps = {
  data: []
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired
};

export default SelectField;
