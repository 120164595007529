export const mkStrings = {
    dashboard:'Почетна',
    logout:"Одјава",
    profile:"Профил",
    guideline:"Упатство",
    userSynchronization:"Синхронизација на корисници",
    roles:"Улоги",
    applications:"Апликации",
    groups:"Групи",
    authorizations:"Авторизации",
    authentications:"Автентикации",
    reports:"Извештаи",
    managingUsersByGroups:"Менацирање на корисници по групи",
    viewUsersByGroup:"Преглед на корисници по група",
    user:"Корисник",
    username:"Корисничко име",
    name:"Име и презиме",
    foreignersSubmittedForms: "Регистар за странци/азил",
    foreignersSubmittedFormsPending: "Регистар за странци/азил (во изработка/неподнесени)",
    ordinalNumber: "Реден број",
    email:"Е-пошта",
    embg: "Матичен број",
    firstName: "Име",
    lastName: "Презиме",
    fathersName: "Татково име",
    parentName:"Татково име",
    carrierName: "Име и презиме на носител",
    requesterName:"Име и презиме на барател",
    typeOfForms: "Тип на форми",
    all: "Сите",
    asylum: "Азил",
    foreigners: "Странци",
    password: "Лозинка",
    welcome: "WELCOME во системот за регистрирање на странци",
    login: "Најава",
    mvr:"Министерство за внатрешни работи",
    rowsPerPage: "Редици по страна:",
    add: "Додади",
    evidencyReasonMvrKey:"Основ за евидентирање",
    maidenName:"Моминско презиме",
    gender:"Пол",
    citizenship:"Државјанство",
    otherCitizenship:"Друго државјанство",
    countryName:"Назив на државата",
    birthDate:"Датум на раѓање",
    birthPlace:"Место на раѓање",
    countryBirth:"Држава на раѓање",
    occupation:"Занимање",
    education:"Стручна спрема",
    foreignLanguage:"Знаење на странски јазик",
    marriageStatus:"Брачна состојба",
    previousLivingPlace:"Живеалиште пред доаѓањето во РСМ",
    place:"Место",
    country:"Држава",
    previousSettlementPlace:"Престој пред доаѓањето во РСМ",
    municipality:"Општина",
    populatedPlace:"Населено место",
    street:"Улица",
    numberStreet:"Број",
    apartmentEntrance:"Влез",
    apartment:"Стан",
    livingPlaceRM:"Место на живеење или престој во РСМ",
    successOnHold: "Успешно ставен во мирување ЕМБГ со број ",
    successPassivate: "Успешно пасивизиран овој ЕМБГ",
    ok: "OK",
    key: "Клуч",
    labelMk: "Лабела МКД",
    labelAlb: "Лабела АЛБ",
    labelEn: "Лабела АНГ",
    success: "Успешно извршено!",
    deleteNomenclatureEntrySuccess: "Успешно избришан запис!",
    fail: "Неуспешно извршено!",
    addNomenclatureEntrySuccess: "Успешно креиран записот во номенклатура!",
    addNomenclatureEntry: "Додади запис во номенклатура",
    editNomenclatureEntry: "Измени запис во номенклатура",
    editNomenclatureEntrySuccessful: "Успешно извршени промени во номенклатурата",
    active: "Active",
    cancel: "Откажи",
    delete: "Избриши",
    saveChanges: "Зачувај промени",
    deleteNomenclature: "Избриши запис во номенклатура",
    deleteNomenclatureText: "Дали сте сигурни дека сакате да го избришете овој запис?",
    users:"Корисници",
    ovr:"ОВР/СВР",
    groupsAndRoles:"Групи и улоги",
    administration: "Администрација",
    nomenclatures: "Номенклатури",
    editNomenclatures: "Измени номенклатури",
    addForeignCitizenSuccess:"Успешно поднесени податоци",
    editForeignCitizenSuccess:"Успешно изменети податоци",
    passivize:"Пасивизирај барање",
    pasiviseEMBG: "Пасивизирај ЕМБГ",
    days:"Денови",
    hours:"Часови",
    minutes:"Минути",
    seconds:"Секунди",
    s:"s",
    m:"m",
    h:"h",
    personalData: "Лични податоци",
    loginSessions: "Историја на најавувања",
    applicationForAsylum:"Барање за азил",
    export:"Export",
    error:"Error",
    archiveNumber:"Архивски број",
    archiveDate:"Архивски датум",
    fileNumber:"Број на досие",
    claimHolder: "Носител",
    evidencyReason: "Основ за евидентирање",
    settlementReason: "Причина",
    shortStayForms: "Регистар за краткорочен престој",
    shortStayFormsPending: "Регистар за краткорочен престој (во изработка/неподнесени)",
    registrationDecisionsRegister: "Регистар за одлуки",
    decisions: "Одлуки",
    templates: "Обрасци",
    decisionTypeMvrKey: "Вид на одлука",
    decisionTypeMvrKeyMandatoryField: "Вид на одлука*",
    decisionResultMvrKey: "Резултат на одлука",
    decisionDate: "Датум на врачување",
    decisionDateLong: "Датум на врачување на одлуката",
    decisionDateMandatoryField: "Датум на врачување*",
    deadlineDate: "Краен датум",
    startDate: "Почетен датум",
    numberID: "Број на документ",
    dateExpired: "Важност на документ",
    createDecision: "Додади одлука",
    succesfullyAddedDecision: "Успешно креирана одлука",
    dateCreated: "Датум на креирање",
    addAsylum: "Додади азил",
    addForeignCitizen: "Додади странец",
    addShortTermStay: "Додади краткорочен престој",
    generate: "Генерирај",
    generateEMBG:"Генерирај матичен број",
    version:"Верзија",
    foreignRegistration:"Регистрација на странци",
    applicationForForeigner:"Барање за воведување во евиденција за одредени странци ",
    activateEmbg:"Активирај матичен број",
    activate: "Активирај",
    activateRequest: "Активирај барање",
    successfullyGeneratedEMBG:"Успешно генериран матичен број",
    successfullyActivatedEMBG:"Успешно активиран матичен број",
    decisionsTemplates:"Одлуки/обрасци",
    applicationForForeigners: "Барање за странци",
    applicationForShortTermStay: "Барање за краткорочен престој",
    identificationData: "А. Идентификациони податоци",
    travelDocuments: "Б. Патна исправа",
    privateVisitPerson : "В. Лице кај кого престојува во посета во краткорочен престој",
    shortTermStay: "Барање за краткорочен престој",
    end: "Поднеси",
    next: "Следна",
    prev: "Претходна",
    editDecision: "Уреди одлука",
    close: "Затвори",
    create: "Креирај",
    edit: "Уреди",
    complaintDate: "Датум на жалба",
    complaintDecision: "Одлука по жалба",
    createComplaint: "Додади жалба",
    complaintNumber: "Број на жалба",
    createAppeal: "Додади тужба",
    complaints: "Жалби за одлука",
    appeals: "Тужби за одлука",
    appealNumber: "Број на тужба",
    appealDate: "Датум на тужба",
    appealDecision: "Одлука по тужба",
    createdBy:"Креирано од",
    versionCreatedBy:"Верзија креирана од",
    passivateEMBG:"Пасивизирај ЕМБГ",
    passivateEMBGForeign:"Поништи ЕМБГ",
    onHoldEMBG: "Мирување ЕМБГ",
    decisionNumber: "Број на одлука",
    passivationDate: "Датум на пасивизација",
    onHoldDate: "Датум на одлука за мирување",
    passivationReason: "Причина за пасивизација*",
    passivationReasonForeign: "Причина за понишутвање*",
    onHoldReason: "Причина за мирување*",
    passivationForm: "Форма за поништување на матичен број",
    passivationFormAsylum: "Форма за пасивизирање на матичен број",
    activationForm: "Форма за активација на матичен број",
    onHoldForm: "Форма за мирување на матичен број",
    passivateRequest: "Форма за пасивизирање барање",
    passivateRequestForeign: "Форма за понишутање на ЕМБГ",
    successfullyAddedDecision:"Успешно додадена одлука",
    successfullyAddedNewDecision:"Успешно додадена нова одлука",
    successfullyAddedComplaint: "Успешно додадена жалба за одлуката",
    successfullyEditedComplaint: "Успешно променета жалба",
    successfullyAddedAppeal: "Успешно додадена тужба за одлуката",
    successfullyEditedAppeal: "Успешно променета тужба",
    addDecision: "Додади одлука",
    documentType: "Тип на документ",
    documentExtensionDate: "Датум на продолжување на важност на документ",
    editAppeal: "Уреди тужба",
    editComplaint: "Уреди жалба",
    status: "Статус",
    successfullyPassivateRequest: "Успешно пасивизирано барање",
    successfullyActivatedRequest: "Успешно активирано барање",
    passivizeRequestConfirm:"Дали сте сигурни дека сакате да го пасивизирате барањето?",
    activateRequestConfirm:"Дали сте сигурни дека сакате да го активирате барањето?",
    statusEmbg:"Статус на матичен број",
    statusEmbgShort:"Статус (ембг)",
    statusRegistration:"Статус на барање",
    passive:"Пасивно",
    activeStatus:"Активно",
    activateEMBGConfirm:"Дали сте сигурни дека сакате да го активирате овој ЕМБГ?",
    lastNamePrivateVisitPersonCyrillic: "",
    activeSubmittedForm:"Активни",
    opinionPositiveTitle:"Барањето го означивте со позитивен статус.",
    opinionPositive:"При клик на крај на формата за внес, на подносителот ќе му се генерира матичен број.",
    measureTaken:"Преземена мерка",
    workingUnit:"Организациона единица",
    back: "Назад",
    checkBirthDateAndGender:"Барањето содржи ажуриран датум на раѓање или пол на барателот. Со зачувување на барањето, тековниот ЕМБГ на барателот ќе биде поништен и ќе му биде доделен нов. Дали сте сигурни дека сакате да продолжите?",
    notPassivatedcheckBirthDateAndGender:"Не може да се направи промена на пол или датум на раѓање без да се пасивизира матичниот број. Доколку сакате да ја направите оваа промена, прво мора да го пасивизирате матичниот број!  ",
    submit:"Поднеси",
    putOnHold: "Стави во мирување",
    cancelEMBG: "Поништи ЕМБГ",
    transliteration: "Транслитерација",
    app: "app",
    api: "api",
    parentSurname:"Татково презиме",
    motherName:"Име на мајка",
    motherSurname:"Презиме на мајка",
    save:"Зачувај привремено",
    successfullyAddedWorkingVersionForeign:"Успешно поднесена работна верзија за странец",
    successfullyAddedWorkingVersionAsyl:"Успешно поднесена работна верзија за азилант",
    successfullyAddedWorkingVersionShortTerm:"Успешно поднесена работна верзија за краткорочен престој",
    successfullyAddedWorkingVersionDiplomat:"Успешно поднесена работна верзија за дипломат",
    navigateWorkingVersionOrRegistration:"Дали сакате да ја отворите работната верзија или регистрацијата?",
    navigateRegistration:"Кон регистрација",
    navigateWorkingVersion:"Кон работна верзија",
    workingVerionRegistration:"Регистрација/работна верзија",
    search: "Пребарај",
    historyOfRegistrationStatuses: "Историја на регистрација",
    action:"Акции",
    reason:"Причина",
    dateModified:"Датум на модифицирање",
    historyOfEmbgs: "Историја на ЕМБГ",
    reportsNumberOfPermits: "Преглед на број на дозволи за матичен број",
    permitNumber: "Број на дозвола",
    permitDate: "Датум на издавање на дозвола",
    viewOfPermitData:"Преглед на дозволи",
    generatePermitData: "Преземи дозволи",
    exportMk: "Експортирај",
    thisEmbg: "Матичниот број ",
    willBeActivated: " ќе биде активиран",
    willbePassivated: " ќе биде поништен",
    willBeOnHold: " ќе биде поставен во мирување",
    attachedFiles: "Прикачени решенија",
    idCardNumber:"Број на лична карта",
    diplomatsForm:"Дипломати",
    diplomatsRegister:"Регистaр за дипломати",
    diplomatsRegisterPending:"Регистaр за дипломати (во изработка/неподнесени)",
    addDiplomat:"Додади дипломат",
    typeOfStay: "Вид престој",
    change: "Промена",
    correction: "Корекција",
    correctionChangeMessage: "Дали правите промена на име/презиме поради погрешно внесени податоци претходно или настаната е реална промена на истите?",
    moveForeignerToAsylum: "Преземи во азил",
    moveAsylumToForeigner: "Преземи во странци",
    moveShortTermToForeigner: "Преземи во странци",
    deceasedForm: "Форма за означување починати лица",
    deceasedText: "Означи лице како починато?",
    mark: "Означи",
    markDeceased: "Означи починат",
    successfullyMarkedAsDeceased: "Успешно означен како починат",
    idPersonalDocument: "Лична карта",
    deceased: "Статус на лице",
    temporaryStaySettlementReasonMvrKey: "Причина за престој",
    temporaryStayReportedByMvrKey: "Субјект кој го пријавува престојот",
    role: "Улога",
    invalidDate:"Датумот не е валиден",
    placeOfRequest: "Место на поднесување на барањето",
    expiryDate: "Датум на истек",
    registrationDateFrom: "Датум на регистрација од",
    registrationDateTo: "Датум на регистрација до",
    clearFilter: "Исчисти ги филтрите",
    goToHomePage: "Назад кон главната страна",
    errorRouteTitle: "Грешка!",
    noAccessToThisPage: "Немате овозможено пристап кон оваа страна.",
    noInformation: "Нема информација",
    noDataFound: "Не се пронајдени податоци",
}
