import { UserReducedDTO } from "../UserReducedDTO";
import { ForeignCitizenReducedDTO } from "./ForeignCitizenReducedDTO";
import { RegistrationTypeDTO } from "./RegistrationTypeDTO";
import {RegistrationStatusDTO} from "../foreign_citizens/RegistrationStatusDTO";
import {RegistrationStatuses} from "../../constants/RegistrationStatuses";
import { WorkingVersionDTO } from "../foreign_citizens/WorkingVersionDTO";

export class RegistrationReducedDTO {
    id: number;
    dateCreated: Date;
    dateModified: Date;
    key: string;
    createdBy: UserReducedDTO;
    embg: string;
    registrationType: RegistrationTypeDTO;
    foreignCitizen: ForeignCitizenReducedDTO;
    holder: boolean;
    parentRegistrationReducedDTO?: RegistrationReducedDTO;
    registrationStatus?: RegistrationStatusDTO;
    isRegistrationPassivated: boolean;
    submitted?:boolean;
    workingVersionRegistrationId?:WorkingVersionDTO;
    isDead?: boolean;
    deadInfo?: string;
    archiveNumber:string;

    constructor(json: RegistrationReducedDTO) {
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.key = json.key;
        this.createdBy = json.createdBy;
        this.embg = json.embg;
        this.registrationType = json.registrationType;
        this.foreignCitizen = json.foreignCitizen;
        this.holder = json.holder;
        this.parentRegistrationReducedDTO = json.parentRegistrationReducedDTO? new RegistrationReducedDTO(json.parentRegistrationReducedDTO) : undefined;
        this.registrationStatus = json.registrationStatus ? new RegistrationStatusDTO(json.registrationStatus) : undefined;
        this.isRegistrationPassivated = json.isRegistrationPassivated;
        this.workingVersionRegistrationId = json.workingVersionRegistrationId ? new WorkingVersionDTO(json.workingVersionRegistrationId) : undefined;
        this.isDead = json.isDead;
        this.deadInfo = json.deadInfo;
        this.archiveNumber = json.archiveNumber;
    }

    isDeceased(): boolean {
        return !!this.isDead;
    }

    isActive(): boolean {
        return this.registrationStatus !== undefined && this.registrationStatus.key === RegistrationStatuses.ACTIVE;
    }

    isOnHoldActive(): boolean {
        return this.registrationStatus !== undefined && this.registrationStatus.key === RegistrationStatuses.ON_HOLD;
    }
}
