import {IconButton, TableCell, tableCellClasses, TableRow} from "@mui/material";

import { TableFilter } from "./TableFilter";
import { useState } from "react";
import {FilterAlt } from "@mui/icons-material";

 export function PageTableHead(props:any) {
    const { searchRows } = props;

    // const splitSearchRows = [
    //   searchRows?.slice(0, Math.ceil(searchRows.length / 2)),
    //   searchRows?.slice(Math.ceil(searchRows.length / 2))
    // ];
    
     const [showFilters, setShowFilters] = useState(false);

     const handleToggleFilters = () => {
         setShowFilters(!showFilters);
     };
     return (
        <>
         {props.firstSearchRow && <TableRow className="h-48 sm:h-64" sx={props?.bottomRows && {
             [`& .${tableCellClasses.root}`]: {
                 borderBottom: "none"
             }
         }}>
        {props.firstSearchRow?.map((row:any) => (
          <TableCell
            className="p-4 md:p-16"
            key={row.id}
            align={row.align}
            padding={row.disablePadding ? "none" : "normal"}
            width={row.width}
            colSpan={2}
          >
            {!row.search && row.label}
            {row.search && <TableFilter row={row} />}
          </TableCell>
          
        ))}
           <TableCell
          className="p-4 md:p-16"
          align="right"
          padding="normal"
          width="5%"
        >
          {props.firstSearchRow && props.secondSearchRow &&
          <IconButton onClick={handleToggleFilters}>
            <FilterAlt fontSize="large" />
          </IconButton>
          }
        </TableCell>
      </TableRow>}
      {showFilters &&
          <><TableRow
              sx={props?.bottomRows && {
                  [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none"
                  }
              }}
          >

              {props.secondSearchRow?.map((row: any) => (
                  <TableCell
                      className="p-4 md:p-16"
                      key={row.id}
                      align={row.align}
                      padding={row.disablePadding ? "none" : "normal"}
                      width={row.width}
                      colSpan={2}
                  >
                      {!row.search && row.label}
                      {row.search && showFilters && <TableFilter row={row}/>}
                  </TableCell>
              ))}

          </TableRow><TableRow
              sx={props?.bottomRows && {
                  [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none"
                  }
              }}
          >

              {props.thirdSearchRow?.map((row: any) => (
                  <TableCell
                      className="p-4 md:p-16"
                      key={row.id}
                      align={row.align}
                      padding={row.disablePadding ? "none" : "normal"}
                      width={row.width}
                      colSpan={3}
                  >
                      {!row.search && row.label}
                      {row.search && showFilters && <TableFilter row={row}/>}
                  </TableCell>
              ))}

          </TableRow></>
 }
         {/* <TableRow className="h-48 sm:h-64" sx={props?.bottomRows && {
             [`& .${tableCellClasses.root}`]: {
                 borderBottom: "none"
             }
         }}>
             {props.searchRows?.map((row:any) => {
                 return (
                     <TableCell
                     className="p-4 md:p-16"
                     key={row.id}
                     align={row.align}
                     padding={row.disablePadding ? 'none' : 'normal'}
                     width={row.width}
                 >
                     {!row.search && row.label}
                     {row.search &&
                     <TableFilter row={row}/>
                     }
                 </TableCell>
                 )
             })}
             </TableRow> */}
            {/* <TableRow>
                {props.bottomRows?.map((bottomRow:any) => {
                    return (
                        <TableCell
                            className="p-4 md:p-16"
                            key={bottomRow.id}
                            align={bottomRow.align}
                            padding={bottomRow.disablePadding ? 'none' : 'normal'}
                            width={bottomRow.width}
                            sx={{
                                [`& .${tableCellClasses.root}`]: {
                                    float: "end"
                                }
                            }}
                        >
                            {!bottomRow.search && bottomRow.label}
                            {bottomRow.search &&
                                <TableFilter row={bottomRow}/>
                            }
                        </TableCell>
                    )
                })}
            </TableRow> */}
            {!props.headerHidden && 
             <TableRow className="h-48 sm:h-64">
             {props.headerRows?.map((row:any) => {
                 return (
                    <TableCell
                    className="p-4 md:p-16"
                    key={row.id}
                    align={row.align}
                    padding={row.disablePadding ? 'none' : 'normal'}
                    width={row.width}
                >
                    {row.label}
                </TableCell>
             )
             })}
             </TableRow>
            }
             </>
     )
 }
 
 export default PageTableHead;
 
