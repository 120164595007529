import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import { FormControl, FormHelperText, IconButton, InputLabel, MenuItem, MenuProps, OutlinedInput, Select } from '@mui/material';
import { Clear } from '@mui/icons-material';
type Element = {
  value: number | string | boolean | undefined;
  label: string;
}
interface Props {
  name: string;
  label: string;
  data: Element[],
  className?: string,
  disabled?:boolean;
  read?: boolean;
  onChange?:(event:any) => void;

}
function CustomSelect(props: Props) {
  const { label, data,disabled,read,onChange, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const { setValue } = helpers;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }
  return (
    <FormControl {...rest} error={isError} fullWidth className={props.className} disabled={disabled}>
      <InputLabel >{label}</InputLabel>
      <Select {...field} value={data.length > 0 && selectedValue !== null ? selectedValue : ""} defaultValue={""} endAdornment={
        (selectedValue !== null && selectedValue !== "" && !disabled) ? (
          !read && <IconButton onClick={() => setValue(null)}>
            <Clear />
          </IconButton>
        ) : null
      }
                      
        input={
          <OutlinedInput
            label={label}
          />
        }
        readOnly={read ? true : false}
        onChange={onChange}
      >
        {data.map((item: any, index: number) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

CustomSelect.defaultProps = {
  data: []
};

CustomSelect.propTypes = {
  data: PropTypes.array.isRequired
};

export default CustomSelect;
