import { DecisionResultDTO } from "../decision/DecisionResultDTO";
import { DecisionTypeDTO } from "../decision/DecisionTypeDTO";
import { CountryDTO } from "./CountryDTO";

export class PastAsylumRequestDTO {
    id?:number;
    country?: CountryDTO;
    decisionType?: DecisionTypeDTO;
    date?:Date | string;
    decisionResult?: DecisionResultDTO;
    constructor(json:PastAsylumRequestDTO){
        this.country = json.country ?  new CountryDTO(json.country) : undefined;
        this.decisionType =  json.decisionType? new DecisionTypeDTO(json.decisionType): undefined;
        this.id =  json.id;
        this.date = json.date;
        this.decisionResult =  json.decisionResult? new DecisionResultDTO(json.decisionResult): undefined;

    }

}