import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {StorageService} from "../storage/StorageService";
import {AppHeaders} from "./AppHeaders";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*'
    },
});

axiosInstance.interceptors.request.use(
    config => {
        const token = StorageService.getAuthToken();

        if (token) {
            if (!config.headers.has(AppHeaders.AUTHORIZATION)) {
                config.headers.set(AppHeaders.AUTHORIZATION, `Bearer ${token}`);
            }
        }

        return config;
    },
    error => Promise.reject(error),
);

interface RetryConfig extends AxiosRequestConfig {
    retries?: number;
}

const retryDelayInMilliseconds = 2000;
const maxRetries = 2;

axiosInstance.interceptors.response.use(
    (config) => config, (error: AxiosError) => {
        const retryConfig: RetryConfig = (error.config ?? {}) as (RetryConfig);

        if (!retryConfig.retries) {
            retryConfig.retries = 0;
        }

        if (error.response?.status === 401) {
            // Unauthorized
            StorageService.clearAuthToken();

            window.location.href = "/";

            return Promise.reject(error);
        } else if (error.response?.status === 400 || error.response?.status === 500) {
            // Bad request
            if (retryConfig.retries >= maxRetries) {
                console.log("Max retries exceeded");
                return Promise.reject(error);
            }

            retryConfig.retries++;

            const delayRetryRequest = new Promise<void>((resolve) => {
                setTimeout(() => {
                    console.log(`Retrying bad request (attempt ${(retryConfig.retries ?? 0) + 1} of ${maxRetries + 1})`);
                    resolve();
                }, retryDelayInMilliseconds)
            });

            return delayRetryRequest.then(() => axiosInstance(retryConfig));
        }

        // For all other responses, reject the error
        return Promise.reject(error);
    }
);

export default axiosInstance;
