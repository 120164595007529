import { AppealDecisionDTO } from "./AppealDecisionDTO";

export class AppealDTO {
    id: number;
    dateCreated: Date | string | null;
    dateModified: Date | string | null;
    decisionId: number;
    appealNumber: string;
    appealDate?: Date | null | undefined;
    appealDecision?: AppealDecisionDTO;
    constructor(json:AppealDTO){
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.decisionId = json.decisionId;
        this.appealNumber = json.appealNumber;
        this.appealDate = json.appealDate ? new Date(json.appealDate) : undefined;
        this.appealDecision = json.appealDecision ? new AppealDecisionDTO(json.appealDecision) : undefined;
    }

}