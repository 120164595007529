
export class IdentificationDocumentDataDTO {
    id:number;
    passportNumber:string;
    idCardNumber: string;
    drivingLicenseNumber:string;
    other:string;
    noneOfAbove?: string;
    constructor(json:IdentificationDocumentDataDTO){
        this.id = json.id;
        this.passportNumber= json.passportNumber;
        this.idCardNumber = json.idCardNumber;
        this.drivingLicenseNumber = json.drivingLicenseNumber;
        this.other = json.other;
        this.noneOfAbove = json.noneOfAbove;
    }
}
