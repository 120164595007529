import { MeasureCreationDTO } from "./MeasureCreationDTO";
import { PlaceCreationDTO } from "./PlaceCreationDTO";

export class MeasureDataCreationDTO{
    placeDTO?:PlaceCreationDTO | undefined;
    measureDate:Date;
    ovrMvrKey:string;
    illegalLeavePlaceDTO:PlaceCreationDTO;
    illegalLeaveDate:Date;
    measureCreationDTOs:MeasureCreationDTO[];
    constructor(json:MeasureDataCreationDTO){
        this.placeDTO = json.placeDTO ? new PlaceCreationDTO(json.placeDTO) : undefined;
        this.measureDate = json.measureDate;
        this.ovrMvrKey  = json.ovrMvrKey;
        this.illegalLeavePlaceDTO = json.illegalLeavePlaceDTO;
        this.illegalLeaveDate = json.illegalLeaveDate;
        this.measureCreationDTOs =  json.measureCreationDTOs?.map((item) => new MeasureCreationDTO(item));  
    }
}