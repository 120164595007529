
export class PreviousStayDataDTO {
    id:number;
    legalStay:string;
    temporaryStay: string;
    permanentStay:string;
    temporaryProtection:string;
    constructor(json:PreviousStayDataDTO){
        this.id = json.id;
        this.legalStay= json.legalStay;
        this.temporaryStay = json.temporaryStay;
        this.permanentStay = json.permanentStay;
        this.temporaryProtection = json.temporaryProtection;
    }
}
