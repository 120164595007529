import { UserReducedDTO } from "../UserReducedDTO";
import { EvidencyReasonDTO } from "../evidency/EvidencyReason";
import { RegistrationReducedDTO } from "../registration/RegistrationReducedDTO";
import { EmploymentDataCreationDTO } from "./EmploymentDataCreationDTO";
import { EntraceDataCreationDTO } from "./EntraceDataCreationDTO";
import { IdentityCardDataDTO } from "./IdentityCardDataDTO";
import { MeasureDataCreationDTO } from "./MeasureDataCreationDTO";
import { OrganizationCreationDTO } from "./OrganizationCreationDTO";
import { PassportCreationDTO } from "./PassportCreationDTO";
import { PersonDataCreationDTO } from "./PersonDataCreationDTO";
import { PlaceCreationDTO } from "./PlaceCreationDTO";
import {VisaDTO} from "./VisaDTO";
import { RestrictedStayDataDTO } from "./RestrictedStayDataDTO";
import { ResolutionDTO } from "./ResolutionDTO";
import { AsylumRequestIntentionDTO } from "./AsylumRequestIntentionDTO";
import { PreviousStayDataDTO } from "./PreviousStayDataDTO";
import { IdentificationDocumentDataDTO } from "./IdentificationDocumentDataDTO";
import { PastAsylumRequestDTO } from "./PastAsylumRequestDTO";
import { MilitaryServiceDTO } from "./MilitaryServiceDTO";
import { PoliticalActivityDTO } from "./PoliticalActivityDTO";
import { AsylumDataDTO } from "./AsylumDataDTO";
import { EmploymentInfoDTO } from "./EmploymentInfoDTO";
import { LivingPlaceDTO } from "./LivingPlaceDTO";
import { RelativeDTO } from "./RelativeDTO";
import { FamilyMemberDTO } from "./FamilyMemberDTO";
import {PlaceOfRequestDTO} from "./PlaceOfRequestDTO";

export class ForeignCitizenDTO {
    id:number;
    dateCreated:Date | string;
    dateModified:Date | string;
    createdBy?: UserReducedDTO;
    active: boolean;
    evidencyReason?: EvidencyReasonDTO;
    settlementReasonMvrKey:string;
    personData?: PersonDataCreationDTO;
    previousLivingPlace?: PlaceCreationDTO;
    previousSettlementPlace?: PlaceCreationDTO;
    passport?: PassportCreationDTO;
    entranceData?: EntraceDataCreationDTO;
    employmentData?: EmploymentDataCreationDTO;
    identityCardData?: IdentityCardDataDTO;
    foreignOrganization?: OrganizationCreationDTO;
    familyMember?: PersonDataCreationDTO;
    host?:PersonDataCreationDTO;
    measuresData?: MeasureDataCreationDTO;
    note?:string;
    ovrMvrKey?: string;
    familyRelationMvrKey?: string | null | undefined;
    temporaryStayReportedByMvrKey?: string | null | undefined ;
    temporaryStaySettlementReasonMvrKey?: string | null | undefined;
    approvedStayDate?: string;
    timeStart?: string;
    timeEnd?: string;
    previousRegistration?: RegistrationReducedDTO;
    visa?: VisaDTO;
    restrictedStayData?: RestrictedStayDataDTO[];
    archiveDate?: Date | string;
    dossierNumber?: string;
    resolution?: ResolutionDTO;
    archiveNumber?:string;
    asylumRequestIntention?:AsylumRequestIntentionDTO;
    previousStayData?: PreviousStayDataDTO;
    identificationDocumentData?:IdentificationDocumentDataDTO;
    domesticPastAsylumRequest?: PastAsylumRequestDTO;
    foreignPastAsylumRequest?: PastAsylumRequestDTO;
    militaryService?:MilitaryServiceDTO;
    politicalActivity?: PoliticalActivityDTO;
    applicantStatement?: string;
    asylumData?: AsylumDataDTO;
    employmentInfoDTOList?: EmploymentInfoDTO[];
    livingPlaceDTOList?: LivingPlaceDTO[];
    relativeDTOList?: RelativeDTO[];
    familyMemberDTOList?: FamilyMemberDTO[];
    constructor(json:ForeignCitizenDTO){
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.active = json.active;
        this.evidencyReason = json.evidencyReason ? new EvidencyReasonDTO(json.evidencyReason) : undefined;
        this.settlementReasonMvrKey = json.settlementReasonMvrKey;
        this.personData = json.personData ? new PersonDataCreationDTO(json.personData) : undefined;
        this.previousLivingPlace = json.previousLivingPlace ?  new PlaceCreationDTO(json.previousLivingPlace) : undefined;
        this.previousSettlementPlace = json.previousSettlementPlace ? new PlaceCreationDTO(json.previousSettlementPlace) : undefined;
        this.passport = json.passport ? new PassportCreationDTO(json.passport) : undefined;
        this.entranceData = json.entranceData ? new EntraceDataCreationDTO(json.entranceData) : undefined;
        this.employmentData = json.employmentData ? new EmploymentDataCreationDTO(json.employmentData) : undefined;
        this.identityCardData = json.identityCardData ? new IdentityCardDataDTO(json.identityCardData) : undefined;
        this.foreignOrganization = json.foreignOrganization ? new OrganizationCreationDTO(json.foreignOrganization) : undefined;
        this.familyMember = json.familyMember ? new PersonDataCreationDTO(json.familyMember) : undefined;
        this.host = json.host ? new PersonDataCreationDTO(json.host) : undefined;
        this.measuresData = json.measuresData ? new MeasureDataCreationDTO(json.measuresData) : undefined;
        this.note = json.note;
        this.ovrMvrKey = json.ovrMvrKey;
        this.familyRelationMvrKey = json.familyRelationMvrKey;
        this.temporaryStayReportedByMvrKey = json.temporaryStayReportedByMvrKey;
        this.temporaryStaySettlementReasonMvrKey = json.temporaryStaySettlementReasonMvrKey;
        this.previousRegistration = json.previousRegistration ? new RegistrationReducedDTO(json.previousRegistration) : undefined;
        this.approvedStayDate = json.approvedStayDate;
        this.visa = json.visa ? new VisaDTO(json.visa) : undefined;
        this.restrictedStayData = json.restrictedStayData?.map(item => new RestrictedStayDataDTO(item));
        this.timeStart = json.timeStart;
        this.timeEnd = json.timeEnd;
        this.archiveDate = json.archiveDate;
        this.dossierNumber = json.dossierNumber;
        this.resolution = json.resolution ? new ResolutionDTO(json.resolution) : undefined;
        this.archiveNumber = json.archiveNumber;
        this.asylumRequestIntention = json.asylumRequestIntention? new AsylumRequestIntentionDTO(json.asylumRequestIntention):undefined;
        this.previousStayData = json.previousStayData? new PreviousStayDataDTO(json.previousStayData):undefined;
        this.identificationDocumentData = json.identificationDocumentData? new IdentificationDocumentDataDTO(json.identificationDocumentData):undefined;
        this.domesticPastAsylumRequest = json.domesticPastAsylumRequest? new PastAsylumRequestDTO (json.domesticPastAsylumRequest) : undefined;
        this.foreignPastAsylumRequest = json.foreignPastAsylumRequest? new PastAsylumRequestDTO (json.foreignPastAsylumRequest) : undefined;
        this.militaryService = json.militaryService? new MilitaryServiceDTO (json.militaryService) : undefined;
        this.politicalActivity = json.politicalActivity? new PoliticalActivityDTO (json.politicalActivity) : undefined;
        this.applicantStatement= json.applicantStatement;
        this.asylumData = json.asylumData? new AsylumDataDTO (json.asylumData) : undefined;
        this.employmentInfoDTOList = json.employmentInfoDTOList? json.employmentInfoDTOList.map((item:any)=> new EmploymentInfoDTO(item)) : undefined;
        this.livingPlaceDTOList = json.livingPlaceDTOList?.map((item:any)=>new LivingPlaceDTO(item)?? [])
        this.relativeDTOList = json.relativeDTOList?.map((item:any)=>new RelativeDTO(item)?? [])
        this.familyMemberDTOList = json.familyMemberDTOList?.map((item:any)=>new FamilyMemberDTO(item)?? [])
    }


}