import {
  Box,
  Button, FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LightTooltip } from "../../../components/LightTooltip";
import { SideBarPage } from "../../../components/SideBarPage";
import { PageTable } from "../../../components/table/PageTable";
import { strings } from "../../../localization/Localization";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import { Routes } from "../../../router/Routes";
import { ForeignCitizensService } from "../../../services/ForeignCitizensService";
import { NumberParser } from "../../../utils/NumberParser";
import { RegistrationReducedDTO } from "../../../models/registration/RegistrationReducedDTO";
import { BooleanParser } from "../../../utils/BooleanParser";
import { RegistrationTypeService } from "../../../services/RegistrationTypeService";
import { RegistrationTypeDTO } from "../../../models/registration/RegistrationTypeDTO";
import {NomenclatureConstants} from "../../../constants/NomenclatureConstants";
import {RegistrationTypes} from "../../../constants/RegistrationTypes";
import {DateTimeUtils} from "../../../utils/DateTimeUtils";
import {Dayjs} from "dayjs";
import { Privileges } from "../../../models/nomenclatures/Privileges";
import { StorageService } from "../../../storage/StorageService";
import { AuthService } from "../../../services/AuthService";
import { WorkingVersionDTO } from "../../../models/foreign_citizens/WorkingVersionDTO";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {NomenclatureEntryDTO} from "../../../models/nomenclatures/NomenclatureEntryDTO";
import {NomenclatureService} from "../../../services/NomenclatureService";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

interface props{
  submitted: boolean,
  title: string
}

export function DiplomatsSubmittedForms({submitted, title}: props) {

  const [searchParams, setSearchParams] = useSearchParams();
  const page = NumberParser.parseNumber(searchParams.get("page"), 0);
  const rowsPerPage = NumberParser.parseNumber(searchParams.get("size"), 5);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [registrationTypes, setRegistrationTypes] = useState<RegistrationTypeDTO[]>([]);
  const [submittedDiplomatsForms, setSubmittedDiplomatsForms] = useState<RegistrationReducedDTO[]>([]);
  const [firstName, setFirstName] = useState<string>("");
  const [parentName, setParentname] = useState<string>("")
  const [motherName, setMotherName] = useState<string>("")
  const [citizenship, setCitizenship] = useState<NomenclatureEntryDTO | undefined>(undefined)
  // const [citizenships, setCitizenships] = useState<NomenclatureEntryDTO[]>([])
  const [registrationDateFrom, setRegistrationDateFrom] = useState<string | undefined | null>(null)
  const [registrationDateTo, setRegistrationDateTo] = useState<string | undefined | null>(null)
  const [lastName, setLastName] = useState<string>("");
  const active = BooleanParser.parseBooleanNullable(searchParams.get("active"));
  const email = searchParams.get("email") ?? "";
  const [embg, setEmbg] = useState<string>("");
  const [holder, setHolder] = useState<boolean | null | undefined>(undefined);
  const [activeSubmittedForm, setActiveSubmittedForm] = useState<boolean | null | undefined>(undefined);
  const [birthDate, setBirthDate] = useState<string | undefined | null>(null);
  const [settlementReasonMvrKey, setSettlementReasonMvrKey] = useState<string>("");
  const [archiveNumber, setArchiveNumber] = useState<string>("");
  const [openForeign, setOpenForeign] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [keyReasons] = useState<string>(NomenclatureConstants.reasons);
  const navigate = useNavigate();
  const location = useLocation();
  const [manageDiplomats, setManageDiplomats] = useState <boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [headerHidden,setHeaderHidden] = useState<boolean>(true);
  function setSearchParam(key: string, value: string | null | undefined) {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }

    setSearchParams(searchParams)
  }

  function setPage(page: number) {
    setSearchParam("page", page.toString());
  }

  function setRowsPerPage(rowsPerPage: number) {
    setSearchParam("size", rowsPerPage.toString());
  }

  function handleRemoveBirthDateFilter() {
    setBirthDate(null)
    setPage(0);
  }

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleEmbgChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmbg(event.target.value);
    setPage(0);
  }

  function handleFirstNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFirstName(event.target.value);
    setPage(0);
  }
  function handleParentNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setParentname(event.target.value);
    setPage(0);
  }
  function handleMotherNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setMotherName(event.target.value);
    setPage(0);
  }

  function handleCitizenshipChange(value: NomenclatureEntryDTO | undefined): void {
    setCitizenship(value);
    setPage(0);
  }

  function handleRegFromChange(date : Dayjs) {
    setRegistrationDateFrom(DateTimeUtils.formatDateDayJs(date)?.toString() ?? null);
    setPage(0);
  }

  function handleRegToChange(date : Dayjs) {
    setRegistrationDateTo(DateTimeUtils.formatDateDayJs(date)?.toString() ?? null);
    setPage(0);
  }
  function handleArchiveNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    setArchiveNumber(event.target.value);
    setPage(0);
  }

  function handleLastNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setLastName(event.target.value);
    setPage(0);
  }

  function handleHolder(holder: boolean | undefined): void {
    setHolder(holder);
    setPage(0);
  }


  function handleActiveSubmittedForm(activeSubmittedForm: boolean | undefined): void {
    setActiveSubmittedForm(activeSubmittedForm);
    setPage(0);
  }

  function handleBirthDateChange(date? : Dayjs) {
    setBirthDate(DateTimeUtils.formatDateDayJs(date)?.toString() ?? null);
    setPage(0);
  }
  function handleCloseForeign(){
    setOpenForeign(false)
  }
  function checkDateModified(workingVersion:WorkingVersionDTO | undefined){
   if(workingVersion?.newerThanMainVersion){
    return true
   }else {
    return false
   }
  }
  function navigateForeingUserDetails(id: number): any {
    setId(id)
    let registrationType = submittedDiplomatsForms.find((item)=>item.id === id)?.registrationType.mvrKey;
    let workingVersion = submittedDiplomatsForms.find((item)=>item.id === id)?.workingVersionRegistrationId;
    if (registrationType === RegistrationTypes.diplomat) {
      let route = Routes.DIPLOMATS_FORM_EDIT;
      if(!submitted){
        if(workingVersion){
          route = route.replace(":id", workingVersion?.id!.toString());
        }
      }else{
        route = route.replace(":id", id.toString());
      }
      if(!checkDateModified(workingVersion)){
        navigate(route);
      }else{
        setOpenForeign(true)
      }
    }
  }

  const commonWidth =  "10%";
  const headerRows = [
    {
      id: "еmbg",
      label: strings.embg,
      width: commonWidth
    },
    {
      id: "firstName",
      label: strings.firstName,
      width: commonWidth
    },
    {
      id: "lastName",
      label: strings.lastName,
      width: commonWidth
    },
    {
      id:"archiveNumber",
      label:strings.archiveNumber,
      width:commonWidth
    },
    {
      id: "holder",
      label: strings.claimHolder,
      width: commonWidth
    },
    {
      id: "activeSubmittedForm",
      label: strings.activeSubmittedForm,
      width: commonWidth
    },
    {
      id: "birthDate",
      label: strings.birthDate,
      width: commonWidth
    },
    {
      id: "nameOfTheCarrier",
      label: strings.carrierName,
      width: commonWidth
    },
    {
      id: "nameOfTheRequester",
      label: strings.requesterName,
      width: commonWidth
    },
    {
      id:"empty1",
      width:commonWidth
    },
    {
      id:"empty2",
      width:commonWidth
    },
    {
      id:"empty3",
      width:commonWidth
    },
    {
      id:"empty4",
      width:commonWidth
    },

  ]

  const bottomRows = [
    {id: "empty"},
    {id: "empty1"},{id: "empty2"},{id: "empty3"},{id: "empty4"},{id: "empty5"},{id: "empty6"},
      isLoading ?
    {id: "loader", value: isLoading , type: "loader", search: true} :
    {
      id: "searchFilter",
      value: strings.search,
      type: "button",
      variant: "contained",
      search: true,
      onClick: () => findAllSubmittedForeignCitizensForms(page),
      width: "15%",
      disabled: false,
    }
  ]

  const firstSearchRow = [
    {
      id: "embg",
      label: strings.embg,
      value: embg,
      search: true,
      type: "text",
      variant: "standard",
      handleChange: handleEmbgChange,
      width: commonWidth
    },
    {
      id: "firstName",
      label: strings.firstName,
      search: true,
      type: "text",
      value: firstName,
      variant: "standard",
      handleChange: handleFirstNameChange,
      width: commonWidth
    },
    {
      id: "lastName",
      label: strings.lastName,
      search: true,
      type: "text",
      value: lastName,
      variant: "standard",
      handleChange: handleLastNameChange,
      width: commonWidth
    },
    {
      id: "archiveNumber",
      label: strings.archiveNumber,
      search: true,
      type: "text",
      value: archiveNumber,
      variant: "standard",
      handleChange: handleArchiveNumberChange,
      width: commonWidth
    },
    {
      id: "birthDate",
      label: strings.birthDate,
      search: true,
      type: "datePicker",
      variant: "standard",
      value: birthDate,
      handleChange: handleBirthDateChange,
      handleRemoveFilter: handleRemoveBirthDateFilter,
      width: commonWidth
    },
    isLoading ?
    {id: "loader", value: isLoading , type: "loader", search: true} :
    {
      id: "searchFilter",
      value: strings.search,
      type: "button",
      variant: "contained",
      search: true,
      onClick: () => {findAllSubmittedForeignCitizensForms(page);setHeaderHidden(false)},
      width: commonWidth,
      disabled: false,
    }
  ]
  const secondSearchRow = [
    {
      id: "holder",
      type: "nullableCheckbox",
      search: true,
      label: strings.claimHolder,
      width: commonWidth,
      value: holder,
      handleChange: handleHolder
    },
    {
      id: "activeSubmittedForm",
      type: "nullableCheckbox",
      search: true,
      label: strings.activeSubmittedForm,
      width: commonWidth,
      value: activeSubmittedForm,
      handleChange: handleActiveSubmittedForm
    },
    {
      id: "parentName",
      label: strings.parentName,
      search: true,
      type: "text",
      value: parentName,
      variant: "standard",
      handleChange: handleParentNameChange,
      width: commonWidth
    },
    {
      id: "motherName",
      label: strings.motherName,
      search: true,
      type: "text",
      value: motherName,
      variant: "standard",
      handleChange: handleMotherNameChange,
      width: commonWidth
    },
    {
      id: "citizenshipMvrKey",
      label: strings.citizenship,
      search: true,
      placeholder: strings.citizenship,
      // elements: citizenships,
      type: "paginatedSelect",
      value: citizenship,
      variant: "standard",
      filterLabel: strings.citizenship,
      valueMapper: (item: NomenclatureEntryDTO) => item?.key && item?.key?.toString(),
      keyMapper: (item: NomenclatureEntryDTO) => item.id.toString(),
      itemMapper: (item: NomenclatureEntryDTO) => <>{item.name}</>,
      labelMapper: (item: any) => item.name,
      dataFetcher: (page: number, size: number, filter: string) => {
        return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
            page, size, NomenclatureConstants.citizenship, filter ? filter : "", "", "", undefined, "", true
        );
      },
      onChange: handleCitizenshipChange,
      name: strings.citizenship,
      width: commonWidth,
    },
  ]

  const thirdSearchRow = [
    {
      id: "registrationDateFrom",
      label: strings.registrationDateFrom,
      search: true,
      type: "datePicker",
      value: registrationDateFrom,
      variant: "standard",
      handleChange: handleRegFromChange,
      width: "20%"
    },
    {
      id: "registrationDateTo",
      label: strings.registrationDateTo,
      search: true,
      type: "datePicker",
      value: registrationDateTo,
      variant: "standard",
      handleChange: handleRegToChange,
      width: "20%"
    }
  ]

  const rows = submittedDiplomatsForms.map((submittedForm) => {
    return {
      id: submittedForm.id,
      key: submittedForm.id,
      values: [
        {
          type: 'text',
          name: "embg",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.embg}><span>{submittedForm?.embg}</span></LightTooltip>,
          width: "10%"
        },
        {
          type: 'text',
          name: "firstName",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.foreignCitizen.personData?.firstName}><span>{submittedForm?.foreignCitizen.personData?.firstName}</span></LightTooltip>,
          width: "10%"
        },
        {
          type: 'text',
          name: "lastName",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.foreignCitizen.personData?.lastName}><span>{submittedForm?.foreignCitizen.personData?.lastName}</span></LightTooltip>,
          width: "10%"
        },
        {
          type: 'text',
          name: "archiveNumber",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.foreignCitizen.archiveNumber}><span>{submittedForm?.foreignCitizen.archiveNumber}</span></LightTooltip>,
          width: "10%"
        },
        {
          type: 'boolean',
          name: "holder",
          value: submittedForm?.holder,
          width: "5%"
        },
        {
          type: 'boolean',
          name: "activeSubmittedForm",
          value: !submittedForm?.isRegistrationPassivated,
          width: "5%"
        },
        {
          type: 'text',
          name: "birthDate",
          value: DateTimeUtils.formatDate(submittedForm?.foreignCitizen?.personData?.birthDate?.toString()),
          width: "17%"
        },
        {
          type: 'text',
          name: "nameOfTheCarrier",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.parentRegistrationReducedDTO?.foreignCitizen?.personData?.firstName ?? ''}><span>{
            submittedForm?.parentRegistrationReducedDTO !== undefined ? (submittedForm?.parentRegistrationReducedDTO?.foreignCitizen?.personData?.firstName ?? '') + " " + (submittedForm?.parentRegistrationReducedDTO?.foreignCitizen?.personData?.lastName ?? '')  : (submittedForm?.foreignCitizen?.personData?.firstName ?? '') + " " + (submittedForm?.foreignCitizen?.personData?.lastName ?? '')
            }</span></LightTooltip>,
          width: "10%"
        },
        {
          type: 'text',
          name: "nameOfTheRequester",
          value: <LightTooltip className={"tableTooltip"} title={submittedForm?.foreignCitizen?.personData?.lastName ?? ''}><span>{
            (submittedForm?.foreignCitizen?.personData?.firstName ?? '') + " " + (submittedForm?.foreignCitizen?.personData?.lastName ?? '')
            }</span></LightTooltip>,
          width: "10%"
        },
        {
          type: 'text',
          name: "empty1",
          value: "",
          width: "15%"
        },
        {
          type: 'text',
          name: "empty2",
          value: "",
          width: "15%"
        },
        {
          type: 'text',
          name: "empty3",
          value: "",
          width: "15%"
        },
        {
          type: 'text',
          name: "empty4",
          value: "",
          width: "15%"
        },
      ]
    }

  })

  function addDiplomat() {
    navigate(Routes.DIPLOMATS_FORM);
  }

  useEffect(() => {
    async function getAllRegistrationTypes() {
      await RegistrationTypeService.getAllRegistrationTypes().then((response) => {
        setRegistrationTypes(response.filter(rt=>rt.mvrKey !== RegistrationTypes.diplomat));
      })
    }
  
    getAllRegistrationTypes().then((_) => {});
  }, []);
  
  

  function clearFilter(){
    setFirstName("");
    setLastName("");
    setEmbg("");
    setHolder(undefined);
    setActiveSubmittedForm(undefined);
    setArchiveNumber("")
    setBirthDate(null);
    setHeaderHidden(true)
    setParentname("");
    setMotherName("")
    setCitizenship(undefined);
    setRegistrationDateFrom(null)
    setRegistrationDateTo(null)
  }

  function findAllSubmittedForeignCitizensForms(page: number, resetFilter: boolean = false) {
    setIsLoading(true);
    if(resetFilter){
      clearFilter()
      ForeignCitizensService.findAllSubmittedForeignCitizensForms(page, rowsPerPage, false, RegistrationTypes.diplomat, "", parentName, "", active, email, "", undefined, "", settlementReasonMvrKey, undefined, null, submitted, "", motherName, citizenship?.key, registrationDateFrom, registrationDateTo).then((data) => {
        setSubmittedDiplomatsForms(data.content);
        setTotalElements(data.totalElements);
        setIsLoading(false);
      })
    }else{
      ForeignCitizensService.findAllSubmittedForeignCitizensForms(page, rowsPerPage, true, RegistrationTypes.diplomat, firstName, parentName, lastName, active, email, embg, holder, "", settlementReasonMvrKey, activeSubmittedForm, birthDate, submitted,archiveNumber, motherName, citizenship?.key, registrationDateFrom, registrationDateTo).then((data) => {
        setSubmittedDiplomatsForms(data.content);
        setTotalElements(data.totalElements);
        setIsLoading(false);
      })
    }
  }
  
  useEffect(() => {
      findAllSubmittedForeignCitizensForms(page);
    
  }, [page, rowsPerPage, registrationTypes])

  useEffect(() => {
      findAllSubmittedForeignCitizensForms(page, true);
  }, [submitted])

  useEffect(() => {
     async function userHasAuthority(): Promise<void> {
      const userRoles: string[] | null = StorageService.getUserRoles();
      if (userRoles !== null) {
        if (userRoles.includes(Privileges.MANAGE_DIPLOMAT)) {
          setManageDiplomats(true)
        }
      } else {
        const authorization = await AuthService.getAuthorization();
        if (authorization.roles.includes(Privileges.MANAGE_DIPLOMAT)) {
          setManageDiplomats(true)
        }
      }
    }
    userHasAuthority().then((_) => { });

}, []);

function navigateToRegistration(id:number){
  let registrationType = submittedDiplomatsForms.find((item)=>item.id === id)?.registrationType.mvrKey;
    if (registrationType === RegistrationTypes.diplomat) {
        let route = Routes.DIPLOMATS_FORM_EDIT;
        route = route.replace(":id", id.toString());
        navigate(route)
    } 
}

  function navigateToWorkingVersion(id: number) {
    let registrationType = submittedDiplomatsForms.find((item) => item.id === id)?.registrationType.mvrKey;
    let idWorkingVersion = submittedDiplomatsForms.find((item) => item.id === id)?.workingVersionRegistrationId?.id;
 if (registrationType === RegistrationTypes.diplomat) {
      let route = Routes.DIPLOMATS_FORM_EDIT;
      if (idWorkingVersion) {
        route = route.replace(":id", idWorkingVersion?.toString());
        navigate(route)
      }
    }
  }

  return (
      <SideBarPage pageTitle={title} component={
        <React.Fragment>
          <Button style={{float: "left"}} variant={"contained"} onClick={clearFilter} color={"success"} sx={{ height: 40 }} className={"mt-4"}>{strings.clearFilter} <FilterAltOffIcon fontSize={"medium"} color={"inherit"}/></Button>

          <Box component="div" display={"flex"} justifyContent={"end"} className={"mt-4"}>
            <FormControl>
              {manageDiplomats && <Button variant={"contained"} sx={{ height: 40, float:"right" }} className="btn-sm" onClick={addDiplomat}>
                {strings.addDiplomat}
              </Button>}
            </FormControl>

          </Box>
          <form onSubmit={() => findAllSubmittedForeignCitizensForms(page)}>
          <PageTable
              headerRows={headerRows}
              firstSearchRow={firstSearchRow}
              secondSearchRow={secondSearchRow}
              thirdSearchRow={thirdSearchRow}
              bottomRows={bottomRows}
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              totalElements={totalElements}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleOpen={navigateForeingUserDetails}
              clickable={true}
              headerHidden={headerHidden}
          />
          </form>
          <ConfirmationModal
          open={openForeign}
          onClose={true}
          handleClose={handleCloseForeign}
          handleConfirm={()=>navigateToRegistration(id)}
          title={strings.workingVerionRegistration}
          content={
            <div>
             {strings.navigateWorkingVersionOrRegistration} 
             <div className="d-flex justify-content-between mt-3">
            <Button onClick={()=>navigateToRegistration(id)}>{strings.navigateRegistration}</Button>
            <Button onClick={()=>navigateToWorkingVersion(id)}>{strings.navigateWorkingVersion}</Button>
            </div>
            </div>
          }
        />
        </React.Fragment>
      } />

  )
}

