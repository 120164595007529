import { strings } from "../../../localization/Localization";

export default {
    formId: "form",
    formField: {
      archiveNumber: {
        id:"",
        name: "archiveNumber",
        label: strings.archiveNumber,
        requiredErrorMsg: "Полето е задолжително",
        placeholder:  strings.archiveNumber
      },
      archiveDate: {
        id:"",
        name: "archiveDate",
        label: strings.archiveDate,
        requiredErrorMsg: "Полето е задолжително",
        placeholder:  strings.archiveDate
      },
      fileNumber: {
        id:"",
        name: "fileNumber",
        label: strings.fileNumber,
        requiredErrorMsg: "Полето е задолжително",
        placeholder:  strings.fileNumber
      },
      evidencyReasonMvrKey: {
        id:"",
        name: "evidencyReasonMvrKey",
        label: strings.evidencyReasonMvrKey,
        requiredErrorMsg: "Полето е задолжително",
        placeholder:  strings.evidencyReasonMvrKey + "*"
      },
      placeOfRequest:{
        id:"",
        name:"placeOfRequest",
        label:"Место на поднесување на барањето",
        requiredErrorMsg: "Полето е задолжително",
        placeholder:"Место на поднесување на барањето*",
      },
      embg: {
        id:"1",
        name: "embg",
        label: strings.embg,
        requiredErrorMsg: "Матичниот број е задолжителен",
        placeholder:  strings.embg
      },
      existingMacEmbg:{
        id:"115",
        name:"existingMacEmbg",
        label:"Македонски матичен број (доколку има таков)",
        placeholder:"Македонски матичен број (доколку има таков)",
        requiredErrorMsg: "Полето е задолжително"
      },
      lastName: {
        id:"2",
        name: "lastName",
        label: strings.lastName,
        placeholder: strings.lastName + "*",
        requiredErrorMsg: "Презимето е задолжително",
       
      },
      maidenName: {
        id:"3",
        name: "maidenName",
        label: strings.maidenName,
        placeholder: strings.maidenName
      },
      parentName: {
        id:"4",
        name: "parentName",
        label: strings.parentName,
        placeholder: strings.parentName,
        requiredErrorMsg: "Полето е задолжително"
      },
      parentNameCyrillic:{
        id:"",
        name: "parentNameCyrillic",
        label: "Татково име (кирилица)",
        placeholder:"Татково име",
        requiredErrorMsg: "Полето е задолжително",
      },
      parentSurname: {
        id:"5",
        name: "parentSurname",
        label: strings.parentSurname,
        placeholder: strings.parentSurname,
        requiredErrorMsg: "Полето е задолжително"
      },
      motherName: {
        id:"6",
        name: "motherName",
        label: strings.motherName,
        placeholder: strings.motherName,
        requiredErrorMsg: "Полето е задолжително"
      },
      motherNameCyrillic: {
        id:"",
        name: "motherNameCyrillic",
        label: "Име на мајка (кирилица)",
        placeholder: strings.motherName,
        requiredErrorMsg: "Полето е задолжително"
      },
      motherSurname: {
        id:"7",
        name: "motherSurname",
        label: strings.motherSurname,
        placeholder: strings.motherSurname,
        requiredErrorMsg: "Полето е задолжително"
      },
      firstName: {
        id:"8",
        name: "firstName",
        label: "Име",
        placeholder:strings.firstName + "*",
        requiredErrorMsg: "Името е задолжително"
      },
      genderMvrKey: {
        id:"9",
        name: "genderMvrKey",
        label: strings.gender,
        placeholder:strings.gender + "*",
        requiredErrorMsg: "Полето е задолжително",
      },
      citizenShip: {
        id:"10",
        name: "citizenShip",
        label: "Државјанство *",
        placeholder:"Назив на државата*",
        requiredErrorMsg: "Полето е задолжително"
      },
       otherCitizenShip: {
        id:"11",
        name: "otherCitizenShip",
        label: "Друго државјанство",
        placeholder:"Назив на државата"
      },
      birthDate: {
        id:"12",
        name: "birthDate",
        label: "Датум на раѓање",
        placeholder:"Датум на раѓање*",
        requiredErrorMsg: "Датумот на раѓање е задолжителен",
      },
      birthPlace: {
        id:"13",
        name: "birthPlace",
        label: "Место на раѓање",
        placeholder:"Место на раѓање",
        requiredErrorMsg: "Полето е задолжително",
      },
      abroadBirthPlace:{
        id:"13.1",
        name: "abroadBirthPlace",
        label: "Во странство",
        placeholder:"Во странство",
        requiredErrorMsg: "Полето е задолжително",
      },
      placeOfBirthAbroad:{
        id:"13.1",
        name: "placeOfBirthAbroad",
        label: strings.place,
        placeholder:strings.place,
        requiredErrorMsg: "Полето е задолжително",
      },
      inRSMBirthPlace:{
        id:"13.2",
        name: "inRSMBirthPlace",
        label: "Во РСМ",
        placeholder:"Назив на местото, општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      inRSMBirthMunicipality:{
        id:"13.2.1",
        name: "inRSMBirthMunicipality",
        label: "Општина",
        placeholder:"Општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      inRSMBirthPlacePlace:{
        id:"13.2.2",
        name: "inRSMBirthPlacePlace",
        label: "Населено место",
        placeholder:"Населено место",
        requiredErrorMsg: "Полето е задолжително",
      },
      occupation: {
        id:"14",
        name: "occupation",
        label: "Занимање",
        placeholder:"Назив на занимање*",
        requiredErrorMsg: "Полето е задолжително",
      },
      education: {
        id:"15",
        name: "education",
        label: "Стручна спрема",
        placeholder:"Стручна спрема*",
        requiredErrorMsg: "Полето е задолжително",
      },
      foreignLanguage: {
        id:"16",
        name: "foreignLanguage",
        label: "Знаење на странски јазик",
        placeholder:"Знаење на странски јазик",
      },
       marriageStatus: {
        id:"17",
        name: "marriageStatus",
        label: "Брачна состојба",
        placeholder:"Брачна состојба*",
        requiredErrorMsg: "Полето е задолжително",
      },
      previousLivingPlace: {
        id:"18",
        name: "previousLivingPlace",
        label: "Живеалиште пред доаѓањето во РСМ",
        placeholder:"Живеалиште пред доаѓањето во РСМ*",
        requiredErrorMsg: "Полето е задолжително",
      },
      countryPreviousLiving:{
        id:"18.1",
        name: "countryPreviousLiving",
        label: "Држава",
        placeholder:"Назив на државата*",
        requiredErrorMsg: "Полето е задолжително",
      },
      place:{
        id:"18.2",
        name: "place",
        label: "Место",
        placeholder:"Место",
        requiredErrorMsg: "Полето е задолжително",
      },
    
      previousSettlementPlace:{
        id:"19",
        name: "previousSettlementPlace",
        label: "Престој пред доаѓањето во РСМ",
        placeholder:"Престој пред доаѓањето во РСМ",
        requiredErrorMsg: "Полето е задолжително",
      },
      stayCountry:{
        id:"19.1",
        name: "stayCountry",
        label: "Држава",
        placeholder:"Назив на државата",
        requiredErrorMsg: "Полето е задолжително",
      },
      stayPlace:{
        id:"19.2",
        name: "stayPlace",
        label: "Место",
        placeholder:"Место",
        requiredErrorMsg: "Полето е задолжително",
      },
    
      livingPlaceOrStayRSM:{
        id:"20",
        name: "livingPlaceOrStayRSM",
        label: "Место на живеење или престој во РСМ",
        placeholder:"Место на живеење или престој во РСМ",
        requiredErrorMsg: "Полето е задолжително",
      },
      addressDateFrom:{
        id:"20.1",
        name: "addressDateFrom",
        label: "Датум од",
        placeholder:"Датум од*",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingMunicipalityRSM:{
        id:"20.2",
        name: "livingMunicipalityRSM",
        label: "Општина",
        placeholder:"Назив на општината*",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingPlaceRSM:{
        id:"20.3",
        name: "livingPlaceRSM",
        label: "Населено место",
        placeholder:"Назив на местото*",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingStreetRSM:{
        id:"20.4",
        name: "livingStreetRSM",
        label: "Улица",
        placeholder:"Улица*",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingStreetNumberRSM:{
        id:"20.5",
        name: "livingStreetNumberRSM",
        label: "Број",
        placeholder:"Број*",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingApartmentEntranceRSM:{
        id:"20.6",
        name: "livingApartmentEntranceRSM",
        label: "Влез",
        placeholder:"Влез",
        requiredErrorMsg: "Полето е задолжително",
      },
      livingApartmentRSM:{
        id:"20.7",
        name: "livingApartmentRSM",
        label: "Стан",
        placeholder:"Стан",
        requiredErrorMsg: "Полето е задолжително",
      },
      /// регион 2 
      passportTypeMvrKey:{
        id:"21",
        name: "passportTypeMvrKey",
        label: "Вид на патен документ",
        placeholder:"Назив на патниот документ",
        requiredErrorMsg: "Полето е задолжително",
      },
      countryPassport:{
        id:"22",
        name: "countryPassport",
        label: "Држава која го издала патниот документ",
        placeholder:"Назив на државата",
        requiredErrorMsg: "Полето е задолжително",
      },
      passportNumber:{
        id:"23",  
        name: "passportNumber",
        label: "Број на патен документ",
        placeholder:"Број на патен документ",
        requiredErrorMsg: "Полето е задолжително",
      },
      arrivalDate:{
        id:"24",
        name: "arrivalDate",
        label: "Датум на доаѓањето во РСМ",
        placeholder:"Датум на доаѓањето во РСМ*",
        requiredErrorMsg: "Полето е задолжително",
      },
      arrivalWay:{
        id:"25",
        name: "arrivalWay",
        label: "Начин на доаѓањето во РСМ",
        placeholder:"Начин на доаѓањето во РСМ*",
        requiredErrorMsg: "Полето е задолжително",
      },
      sourceOfLivelihood:{
        id:"26",
        name: "sourceOfLivelihood",
        label: "Извор на средства за издржување",
        placeholder:"Извор на средства за издржување",
        requiredErrorMsg: "Полето е задолжително",
      },
      employmentApproval:{
        id:"27",
        name: "employmentApproval",
        label: "Има одобрение за вработување",
        placeholder:"Има одобрение за вработување",
        requiredErrorMsg: "Полето е задолжително",
      },
      statusEmployment:{
        id:"28",
        name: "statusEmployment",
        label: "Статус на работен однос",
        placeholder:"Статус на работен однос",
        requiredErrorMsg: "Полето е задолжително",
      },
      employedAt:{
        id:"29",
        name: "employedAt",
        label: "Вработен во",
        placeholder:"Вработен во",
        requiredErrorMsg: "Полето е задолжително",
      },
      employmentArea:{
        id:'30',
        name: "employmentArea",
        label: "Област на дејноста",
        placeholder:"Назив на област на дејност",
        requiredErrorMsg: "Полето е задолжително",
      },
      organizationEmployment:{
        id:"31",
        name: "organizationEmployment",
        label: "Правно лице каде што е вработен",
        placeholder:"Правно лице каде што е вработен",
        requiredErrorMsg: "Полето е задолжително",
      },
      embsOrganization:{
        id:"31.1",
        name: "embsOrganization",
        label: "ЕМБС на фирма",
        placeholder:"ЕМБС на фирма",
        requiredErrorMsg: "Полето е задолжително",
      },
      nameOfOrganization:{
        id:"31.2",
        name: "nameOfOrganization",
        label: "Назив",
        placeholder:"Назив",
        requiredErrorMsg: "Полето е задолжително",
      },
      municipalityOrganization:{
        id:"31.3",
        name: "municipalityOrganization",
        label: "Oпштина",
        placeholder:"Општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      placeOrganization:{
        id:"31.4",
        name: "placeOrganization",
        label: "Населено место",
        placeholder:"Назив на местото",
        requiredErrorMsg: "Полето е задолжително",
      },
      streetOrganization:{
        id:"31.5",
        name: "streetOrganization",
        label: "Улица",
        placeholder:"Улица",
        requiredErrorMsg: "Полето е задолжително",
      },
      numberStreetOrganization:{
        id:"31.6",
        name: "numberStreetOrganization",
        label: "Број",
        placeholder:"Број",
        requiredErrorMsg: "Полето е задолжително",
      },
      engagedProperty:{
        id:"32",
        name: "engagedProperty",
        label: "Ангажиран во својство",
        placeholder:"Скратен назив на својството на ангажирањето",
        requiredErrorMsg: "Полето е задолжително",
      },

      ///регион 3
      settlementReasonMvrKey:{
        id:"33",
        name: "settlementReasonMvrKey",
        label: "Причини за престој",
        placeholder:"Скратен назив на причината",
        requiredErrorMsg: "Полето е задолжително",
      },
      familyRelationMvrKey:{
        id:"33.1",
        name: "familyRelationMvrKey",
        label: "Улога",
        placeholder:"Улога",
        requiredErrorMsg: "Полето е задолжително",
      }, 
      approvalDate:{
        id:"34",
        name: "approvalDate",
        label: "Датум на одобрување",
        placeholder:"Датум на одобрување*",
        requiredErrorMsg: "Полето е задолжително",
      },
      dateOfExtension:{
        id:"34.1",
        name: "dateOfExtension",
        label: "Датум на продолжување",
        placeholder:"Датум на продолжување",
        requiredErrorMsg: "Полето е задолжително",
      },
      
      endDate:{
        id:"35",
        name: "endDate",
        label: "Важност на престојот",
        placeholder:"Важност на престојот",
        requiredErrorMsg: "Полето е задолжително",
      },
      startDateStay:{
        id:"35.1",
        name: "startDateStay",
        label: "Важност на престојот од: датум",
        placeholder:"Важност на престојот од: датум",
        requiredErrorMsg: "Полето е задолжително",
      },
      endDateStay:{
        id:"35.2",
        name: "endDateStay",
        label: "Важност на престојот до: датум",
        placeholder:"Важност на престојот до: датум",
        requiredErrorMsg: "Полето е задолжително",
      },
      dateOfDeliveryOfDecision:{
        id:"36",
        name: "dateOfDeliveryOfDecision",
        label: "Датум на врачување на решение",
        placeholder:"Датум на врачување на решение",
        requiredErrorMsg: "Полето е задолжително",
      },
      decision:{
        id:"36.1",
        name: "decision",
        label: "Позитивно / негативно",
        placeholder:"Позитивно/негативно",
        requiredErrorMsg: "Полето е задолжително",
      },
      fileDecision:{
        id:"36.2",
        name: "fileDecision",
        label: "Прикачи решение",
        placeholder:"Прикачи решение",
        requiredErrorMsg: "Полето е задолжително",
      },
      idForeignerNumber:{
        id:"37",
        name: "idForeignerNumber",
        label: "Број на дозвола за престој на странец",
        placeholder:"Број на дозвола за престој на странец",
        requiredErrorMsg: "Полето е задолжително",
      },
      dateIdForeignerNumber:{
        id:"38",
        name: "dateIdForeignerNumber",
        label: "Датум на издавање на дозвола за престој на странец",
        placeholder:"Датум на издавање на дозвола за престој на странец",
        requiredErrorMsg: "Полето е задолжително",
      },
      company:{
        id:"39",
        name: "company",
        label: "Фирма во странство од која доаѓа",
        placeholder:"Фирма во странство од која доаѓа",
        requiredErrorMsg: "Полето е задолжително",
      },
      companyName:{
        id:"39.1",
        name: "companyName",
        label: "Назив",
        placeholder:"Назив",
        requiredErrorMsg: "Полето е задолжително",
      },
      companyCountry:{
        id:"39.2",
        name: "companyCountry",
        label: "Држава",
        placeholder:"Назив на државата",
        requiredErrorMsg: "Полето е задолжително",
      },
      companyPlace:{
        id:"39.3",
        name: "companyPlace",
        label: "Место",
        placeholder:"Место",
        requiredErrorMsg: "Полето е задолжително",
      },
    
      spouseEmbg:{
        id:"40",
        name: "spouseEmbg",
        label: "Матичен број",
        placeholder:"Матичен број",
        requiredErrorMsg: "Полето е задолжително",
      },
      spouseLastName:{
        id:"41",
        name: "spouseLastName",
        label: "Презиме",
        placeholder:"Презиме",
        requiredErrorMsg: "Полето е задолжително",
      },
      spouseFirstName:{
        id:"42",
        name: "spouseFirstName",
        label: "Име",
        placeholder:"Име",
        requiredErrorMsg: "Полето е задолжително",
      },
      spouseBirthDate:{
        id:"43",
        name: "spouseBirthDate",
        label: "Датум на раѓање",
        placeholder:"Датум на раѓање",
        requiredErrorMsg: "Полето е задолжително",
      },
      spouseBirthPlace:{
        id:"44",
        name: "spouseBirthPlace",
        label: "Место на раѓање",
        placeholder:"Место на раѓање",
        requiredErrorMsg: "Полето е задолжително",
      },
      spouseAbroadBirthPlace:{
        id:"44.1",
        name: "spouseAbroadBirthPlace",
        label: "Во странство",
        placeholder:"Во странство",
        requiredErrorMsg: "Полето е задолжително",
      },
      placeOfBirthSpouseAbroad:{
        id:"44.1",
        name: "placeOfBirthSpouseAbroad",
        label: strings.place,
        placeholder:strings.place,
        requiredErrorMsg: "Полето е задолжително",
      },
      inRSM:{
        id:"44.2",
        name: "inRSM",
        label: "Во РСМ",
        placeholder:"Назив на местото,општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      familyMemberBirthMunicipality:{
        id:"44.2.1",
        name: "familyMemberBirthMunicipality",
        label: "Општина",
        placeholder:"Општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      familyMemberBirthPlace:{
        id:"44.2.2",
        name: "familyMemberBirthPlace",
        label: "Населено место",
        placeholder:"Населено место",
        requiredErrorMsg: "Полето е задолжително",
      },
      addressRelative:{
        id:"45",
        name: "addressRelative",
        label: "Адреса на членовите на потесното семејство со кои престојува",
        placeholder:"Адреса на членовите на потесното семејство со кои престојува",
        requiredErrorMsg: "Полето е задолжително",
      },
      municipalityRelative:{
        id:"45.1",
        name: "municipalityRelative",
        label: "Општина",
        placeholder:"Општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      placeRelative:{
        id:"45.2",
        name: "placeRelative",
        label: "Населено место",
        placeholder:"Населено место",
        requiredErrorMsg: "Полето е задолжително",
      },
      streetRelative:{
        id:"45.3",
        name: "streetRelative",
        label: "Улица",
        placeholder:"Улица",
        requiredErrorMsg: "Полето е задолжително",
      },
      numberStreetRelative:{
        id:"45.4",
        name: "numberStreetRelative",
        label: "Број",
        placeholder:"Број",
        requiredErrorMsg: "Полето е задолжително",
      },
      numberChildren:{
        id:"46",
        name: "numberChildren",
        label: "Број на деца",
        placeholder:"Број на деца",
        requiredErrorMsg: "Полето е задолжително",
      },
      numberOfDomesticChildren:{
        id:"46.1",
        name: "numberOfDomesticChildren",
        label: "Државјанин на РСМ",
        placeholder:"Државјанин на РСМ",
        requiredErrorMsg: "Полето е задолжително",
      },
      numberOfForeignChildren:{
        id:"46.2",
        name: "numberOfForeignChildren",
        label: "Странски државјанин",
        placeholder:"Странски државјанин",
        requiredErrorMsg: "Полето е задолжително",
      },
    
      privateVisitPerson:{
        id:"47",
        name: "privateVisitPerson",
        label: "Лице кај кого престојува во посета",
        placeholder:"Лице кај кого престојува во посета",
        requiredErrorMsg: "Полето е задолжително",
      },
      embgPrivateVisitPerson:{
        id:"47.1",
        name: "embgPrivateVisitPerson",
        label: "Матичен број",
        placeholder:"Матичен број",
        requiredErrorMsg: "Полето е задолжително",
      },
      lastNamePrivateVisitPerson:{
        id:"47.2",
        name: "lastNamePrivateVisitPerson",
        label: "Презиме",
        placeholder:"Презиме",
        requiredErrorMsg: "Полето е задолжително",
      },
      firstNamePrivateVisitPerson:{
        id:"47.3",
        name: "firstNamePrivateVisitPerson",
        label: "Име",
        placeholder:"Име",
        requiredErrorMsg: "Полето е задолжително",
      }, 
      genderPrivateVisitPerson:{
        id:"47.4",
        name: "genderPrivateVisitPerson",
        label: "Пол",
        placeholder:"Пол",
        requiredErrorMsg: "Полето е задолжително",
      }, 
      citizen:{
        id:"47.5",
        name: "citizen",
        label: "Државјанин",
        placeholder:"Државјанин",
        requiredErrorMsg: "Полето е задолжително",
      },
      addressPrivateVisitPerson:{
        id:"48",
        name: "addressPrivateVisitPerson",
        label: "Адреса на лицето кај кого престојува во посета",
        placeholder:"Адреса на лицето кај кого престојува во посета",
        requiredErrorMsg: "Полето е задолжително",
      }, 
      municipalityPrivateVisitPerson:{
        id:"48.1",
        name: "municipalityPrivateVisitPerson",
        label: "Општина",
        placeholder:"Општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      placePrivateVisitPerson:{
        id:"48.2",
        name: "placePrivateVisitPerson",
        label: "Населено место",
        placeholder:"Населено место",
        requiredErrorMsg: "Полето е задолжително",
      },
      streetPrivateVisitPerson:{
        id:"48.3",
        name: "streetPrivateVisitPerson",
        label: "Улица",
        placeholder:"Улица",
        requiredErrorMsg: "Полето е задолжително",
      },
      numberStreetPrivateVisitPerson:{
        id:"48.4",
        name: "numberStreetPrivateVisitPerson",
        label: "Број",
        placeholder:"Број",
        requiredErrorMsg: "Полето е задолжително",
      },
      apartmentEntrancePrivateVisitPerson:{
        id:"48.5",
        name: "apartmentEntrancePrivateVisitPerson",
        label: "Влез",
        placeholder:"Влез",
        requiredErrorMsg: "Полето е задолжително",
      },
      apartmentPrivateVisitPerson:{
        id:"48.6",
        name: "apartmentPrivateVisitPerson",
        label: "Стан",
        placeholder:"Стан",
        requiredErrorMsg: "Полето е задолжително",
      },

      /// регион 4  - Податоци за превземените мерки
      measureTakenType:{
        id:"49",
        name: "measureTakenType",
        label: "Преземени мерки",
        placeholder:"Преземени мерки",
        requiredErrorMsg: "Полето е задолжително",
      },
      measureTakenReason:{
        id:"50",
        name: "measureTakenReason",
        label: "Причини за преземени мерки",
        placeholder:"Причини за преземени мерки",
        requiredErrorMsg: "Полето е задолжително",
      },
      placeMeasuresTaken:{
        id:"51",
        name: "placeMeasuresTaken",
        label: "Место каде е  преземена мерката",
        placeholder:"Назив на местото, општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      placeMeasuresTakenMunicipality:{
        id:"51.1",
        name: "placeMeasuresTakenMunicipality",
        label: "Општина",
        placeholder:"Општина",
        requiredErrorMsg: "Полето е задолжително",
      },
      placeMeasuresTakenPlace:{
        id:"51.2",
        name: "placeMeasuresTakenPlace",
        label: "Место",
        placeholder:"Назив на местото",
        requiredErrorMsg: "Полето е задолжително",
      },
      dateMeasuresTaken:{
        id:"52",
        name: "dateMeasuresTaken",
        label: "Датум на преземање на мерката",
        placeholder:"Датум на преземање на мерката",
        requiredErrorMsg: "Полето е задолжително",
      },
      OVRMeasuresTaken:{
        id:"53",
        name: "ovrMvrKey",
        label: "Организациска единица од МВР, која ја превзела мерката",
        placeholder:"Назив на органот и место",
        requiredErrorMsg: "Полето е задолжително",
      },
      illegalPlace:{
        id:"54",
        name: "illegalPlace",
        label: "Место на илегално влегување или место на откривање во РСМ",
        placeholder:'Назив на местото, општина',
        requiredErrorMsg: "Полето е задолжително",
      },
      illegalPlaceMunicipality:{
        id:"54.1",
        name: "illegalPlaceMunicipality",
        label: "Општина",
        placeholder:'Општина',
        requiredErrorMsg: "Полето е задолжително",
      },
      illegalPlacePlace:{
        id:"54.2",
        name: "illegalPlacePlace",
        label: "Населено место",
        placeholder:'Назив на местото',
        requiredErrorMsg: "Полето е задолжително",
      },
      discovererMvrKey:{
        id:"55",
        name: "discovererMvrKey",
        label: "Кој го открил",
        placeholder:"Кој го открил",
        requiredErrorMsg: "Полето е задолжително",
      },
       OVR:{
        id:"56",
        name: "OVR",
        label: "Организациска единица во МВР која ја покренала постапката",
        placeholder:"Назив на органот и место",
        requiredErrorMsg: "Полето е задолжително",
      },
       shelterForeigners:{
        id:"57",
        name: "shelterForeigners",
        label: "Поминат низ прифатен центар за странци",
        placeholder:"Поминат низ прифатен центар за странци",
        requiredErrorMsg: "Полето е задолжително",
      },
      attemptIllegal:{
        id:"58",
        name: "attemptIllegal",
        label: "Обид на илегално напуштање на РСМ",
        placeholder:"Обид на илегално напуштање на РСМ",
        requiredErrorMsg: "Полето е задолжително",
      },
      attemptIllegalPlace:{
        id:"58.1",
        name: "attemptIllegalPlace",
        label: "Место",
        placeholder:"Скратен назив на населеното место",
        requiredErrorMsg: "Полето е задолжително",
      },
      attemptIllegalDate:{
        id:"58.2",
        name: "attemptIllegalDate",
        label: "Датум",
        placeholder:"Датум",
        requiredErrorMsg: "Полето е задолжително",
      },
      residenceBan:{
        id:"59",
        name: "residenceBan",
        label: "Забрана за влез и престој во РСМ",
        placeholder:"Забрана за влез и престој во РСМ",
        requiredErrorMsg: "Полето е задолжително",
      },
      residenceBanStartDate:{
        id:"59.1",
        name: "residenceBanStartDate",
        label: "Датум од",
        placeholder:"Датум од",
        requiredErrorMsg: "Полето е задолжително",
      },
      residenceBanEndDate:{
        id:"59.2",
        name: "residenceBanEndDate",
        label: "Датум до",
        placeholder:"Датум до",
        requiredErrorMsg: "Полето е задолжително",
      },
      noteForeigner:{
        id:"60",
        name: "noteForeigner",
        label: "Забелешка",
        placeholder:"Забелешка",
        requiredErrorMsg: "Полето е задолжително",
      },
      dateEvidented:{
        id:"61",
        name: "dateEvidented",
        label: "Датум на воведување во евиденција",
        placeholder:"Датум на воведување во евиденција",
        requiredErrorMsg: "Полето е задолжително",
      },
      checksPerformed:{
        id:"62",
        name: "checksPerformed",
        label: "Одговор од АНБ",
        placeholder:"Одговор од АНБ",
        requiredErrorMsg: "Полето е задолжително",
      },
      firstNameCyrillic:{
        id:"",
        name: "firstNameCyrillic",
        label: "Име (кирилица)",
        placeholder:"Име",
        requiredErrorMsg: "Полето е задолжително",
      },
      lastNameCyrillic:{
        id:"",
        name: "lastNameCyrillic",
        label: "Презиме (кирилица)",
        placeholder:"Презиме",
        requiredErrorMsg: "Полето е задолжително",
      },
   

    }
  };
