export const NomenclatureConstants = {
    evidencyReason: "evidency_reasons",
    genders: "genders",
    citizenship: "citizenship",
    occupations: "occupations",
    professionalTrainings: "professional_trainings",
    foreignLanguages: "foreign_languages",
    marriageStatuses: "marriage_statuses",
    place: "place",
    country: "country",
    municipality: "municipality",
    street: "street",
    passportTypes: "passport_types",
    entranceTypes: "entrance_types",
    discoverers: "discoverers",
    organisationalUnit: "place_of_request",
    financialSources: "financial_sources",
    employmentStatuses: "employment_statuses",
    employmentSectors: "employment_sectors",
    occupationPosition: "occupation_position",
    propertyEngagement: "property_engagement",
    temporaryResidenceReasons: "temporary_residence_reasons",
    permanentResidenceReasons: "permanent_residence_reasons",
    identificationDocument: "identification_document",
    basis: "basis",
    reasons: "reasons",
    measuresTakenType: "measures_taken_type",
    measuresTakenReasons:"measures_taken_reason",
    cancellationReasons: "cancellation_reasons",
    licenceType: "licence_type",
    residenceStatus: "residence_status",
    decisionResults: "decision_results",
    decisionTypes: "decision_types",
    shortTermStayRegistrationKey: "13",
    borderPoints: "border_point",
    visaTypes: "visa_type",
    shortTermStayEvidencyReasonKey: "13",
    macedoniaMvrKey: "111",
    objectTypes: "short_term_stay_possibilities",
    appealDecisions: "appeal_decisions",
    complaintDecisions: "complaint_decisions",
    passivateReason: "passivation_reasons",
    mvrOrganizationalUnit:"mvr_organizational_unit",
    onHoldReason: "on_hold_reasons",
    passivateRequestReasons: "passivation_registration_reasons",
    anb_answers: "anb_answers",
    measureTakenKey:"5",
    temporaryStayKey:"4",
    permanentStayKey:"10",
    illegalEntranceTypeKey:"2",
    decisionTypePersonWithRefugeeStatus: "1",
    decisionTypePersonWithSubsidiaryProtectionStatus: "2",
    decisionTypeRegularRejected: "3",
    decisionTypeUrgentRejected: "4",
    decisionTypeDecisionOnStoppedProcedure: "5",
    decisionTypeTemporaryProtection: "15",
    placeOfRequest: "place_of_request",
    ethnicOrigin: "ethnicity",
    asylumMvrKey: "asylum",
    foreignCitizenMvrKey: "foreign_citizen",
    shortTermStayMvrKey: "temporary_stay",
    idKey:"11",
    familyRelations: "family_relations",
    temporaryStayReportedBy: "temporary_stay_reported_by",
    temporaryStaySettlementReasons: "temporary_stay_settlement_reasons",
    familyReunificationMvrKey: "7",
    familyReunificationShortTermStayMvrKey: "22"
}
