import { UserReducedDTO } from "../UserReducedDTO";
import { AppealDTO } from "../appeal/AppealDTO";
import { ComplaintDTO } from "../complaint/ComplaintDTO";
import { DecisionDTO } from "../decision/DecisionDTO";
import { ForeignCitizenReducedDTO } from "../registration/ForeignCitizenReducedDTO";
import { RegistrationReducedDTO } from "../registration/RegistrationReducedDTO";
import { RegistrationTypeDTO } from "../registration/RegistrationTypeDTO";
import { ForeignCitizenDTO } from "./ForeignCitizenDTO";
import { RegistrationStatusDTO } from "./RegistrationStatusDTO";
import {RegistrationStatuses} from "../../constants/RegistrationStatuses";
import { PlaceOfRequestDTO } from "./PlaceOfRequestDTO";
import { WorkingVersionDTO } from "./WorkingVersionDTO";

export class RegistrationDTO {
    id:number;
    dateCreated:string | Date;
    dateModified: string | Date;
    key?:string;
    createdBy?:UserReducedDTO;
    embg?: string;
    registrationType?: RegistrationTypeDTO;
    foreignCitizen?: ForeignCitizenDTO;
    versions?: ForeignCitizenReducedDTO[];
    decision?: DecisionDTO;
    appeal?: AppealDTO;
    complaint?:ComplaintDTO;
    oldRegistrationId?: number;
    registrationStatus?: RegistrationStatusDTO;
    childRegistrations?: RegistrationReducedDTO[];
    placeOfRequest?: PlaceOfRequestDTO;
    passivated?:boolean;
    submitted?:boolean;
    workingVersionRegistrationId?:WorkingVersionDTO;
    isDead?: boolean;
    deadInfo?: string;
    constructor(json:RegistrationDTO){
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.key = json.key;
        this.createdBy = json.createdBy ?  new UserReducedDTO(json.createdBy) : undefined;
        this.embg = json.embg;
        this.registrationType = json.registrationType ? new RegistrationTypeDTO(json.registrationType) : undefined;
        this.foreignCitizen = json.foreignCitizen ? new ForeignCitizenDTO(json.foreignCitizen) : undefined;
        this.oldRegistrationId = json.oldRegistrationId;
        this.versions = json.versions ? json.versions.map((item)=> new ForeignCitizenReducedDTO(item)) : undefined;
        this.decision = json.decision ? new DecisionDTO(json.decision) : undefined;
        this.registrationStatus = json.registrationStatus ? new RegistrationStatusDTO(json.registrationStatus) : undefined;
        this.childRegistrations = json.childRegistrations?.map((item:any)=>new RegistrationReducedDTO(item)?? [])
        this.passivated = json.passivated;
        this.placeOfRequest = json.placeOfRequest ? new PlaceOfRequestDTO(json.placeOfRequest) : undefined;
        this.submitted = json.submitted;
        this.workingVersionRegistrationId = json.workingVersionRegistrationId;
        this.workingVersionRegistrationId = json.workingVersionRegistrationId ? new WorkingVersionDTO(json.workingVersionRegistrationId) : undefined;
        this.isDead = json.isDead;
        this.deadInfo = json.deadInfo;
    }

    isActiveAndAllChildrenActive(): boolean {
        if (this.registrationStatus === undefined || this.registrationStatus.key !== RegistrationStatuses.ACTIVE) {
            return false;
        }

        if (this.childRegistrations !== undefined) {
            for (const child of this.childRegistrations) {
                if (!child.isActive()) {
                    return false;
                }
            }
        }

        return true;
    }

    isAtLeastOneActive(): boolean {
        if (this.registrationStatus !== undefined && this.registrationStatus.key === RegistrationStatuses.ACTIVE) {
            return true;
        }

        if (this.childRegistrations !== undefined) {
            for (const child of this.childRegistrations) {
                if (child.isActive()) {
                    return true;
                }
            }
        }

        return false;
    }

    isAllDeceased(): boolean {
        if (!this.isDead) {
            return false;
        }

        if (this.childRegistrations !== undefined) {
            for (const child of this.childRegistrations) {
                if (!child.isDeceased()) {
                    return false;
                }
            }
        }

        return true;
    }

    isOnHoldAndAllChildrenOnHold(): boolean {
        if (this.registrationStatus === undefined || this.registrationStatus.key !== RegistrationStatuses.ON_HOLD) {
            return false;
        }

        if (this.childRegistrations !== undefined) {
            for (const child of this.childRegistrations) {
                if (!child.isOnHoldActive()) {
                    return false;
                }
            }
        }

        return true;
    }
    isAtLeastOneWithGeneratedEmbg(): boolean {
        if (this.embg !== undefined && this.embg !== null) {
            return true;
        }
        if (this.childRegistrations !== undefined) {
            for (const child of this.childRegistrations) {
                if (child.embg !== undefined && child.embg !== null) {
                    return true;
                }
            }
        }
        return false;
    }
}
