import {Guard} from "./Guard";
import {StorageService} from "../../storage/StorageService";
import {Navigate} from "react-router-dom";
import {Routes} from "../Routes";
import {CollectionUtils} from "../../utils/CollectionUtils";
import { EnvironmentUtils } from "../../environment/EnvironmentUtils";
import { AuthService } from "../../services/AuthService";

export class PrivilegeGuardData {
    privileges: string[];
    mode: PrivilegeGuardMode;

    constructor(privileges: string[], mode: PrivilegeGuardMode) {
        this.privileges = privileges;
        this.mode = mode;
    }
}

export enum PrivilegeGuardMode {
    hasAny,
    hasAll
}

export class PrivilegeGuard extends Guard {
    privileges: string[];
    mode: PrivilegeGuardMode;
  
  
    constructor(privileges: string[], mode: PrivilegeGuardMode) {
      super((
        <Navigate replace={true} to={Routes.NOT_FOUND}/>
      ));
  
      this.privileges = privileges;
      this.mode = mode;
    }
  
    async check(): Promise<boolean> {
      if (!EnvironmentUtils.internalAuth() && !StorageService.hasAuthToken()) {
        return false;
      }
  
      if (this.mode === PrivilegeGuardMode.hasAny) {
        const userPrivileges = StorageService.getUserRoles();
        if(userPrivileges && userPrivileges?.length > 0) {
          return CollectionUtils.containsAny(userPrivileges, this.privileges);
        }
        else {
          const userRoles = (await AuthService.getAuthorization()).roles;
          if(userRoles && userRoles?.length > 0) {
            return CollectionUtils.containsAny(userRoles, this.privileges);
          }
        }
      } else if (this.mode === PrivilegeGuardMode.hasAll) {
        const userPrivileges = StorageService.getUserRoles();
        if(userPrivileges && userPrivileges?.length > 0) {
          return CollectionUtils.containsAll(userPrivileges, this.privileges);
        } else {
          const userRoles = (await AuthService.getAuthorization()).roles;
          if(userRoles && userRoles?.length > 0) {
            return CollectionUtils.containsAll(userRoles, this.privileges);
          }
        }
      }
  
      return false;
    }
  }