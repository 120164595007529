import { Grid, Typography } from "@mui/material";
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { strings } from "../../../localization/Localization";
import { useEffect, useState } from "react";
import { NomenclatureService } from "../../../services/NomenclatureService";
import { NomenclatureEntryDTO } from "../../../models/nomenclatures/NomenclatureEntryDTO";
import { useFormikContext } from "formik";
import { ForeignCitizenService } from "../../../services/registrations/ForeignCitizenService";
import { useParams } from "react-router-dom";
import InputField from "../../applications/formFields/InputField";
import {DatePickerField} from "../../applications/formFields/DatePickerFields";
import SelectField from "../../applications/formFields/SelectField";
import {
    getAllEntriesForNomenclatureBorderPoints,
    getAllEntriesForNomenclatureCitizenship,
    getAllEntriesForNomenclatureCountry,
    getAllEntriesForNomenclatureEvidency,
    getAllEntriesForNomenclatureGender,
    getAllEntriesForNomenclatureMunicipality, getAllEntriesForNomenclaturePassportType,
    getAllEntriesForNomenclaturePlace, getAllEntriesForNomenclaturePlaceOfRequest,
    getAllEntriesForNomenclatureStreets,
    getAllEntriesForNomenclatureVisaType,
    getAllEntriesForNomenclaturetemporaryStayReportedBy,
    getAllEntriesForNomenclaturetemporaryStaySettlementReasons
} from "../../../common/NomenclatureMethods";
import {NomenclatureConstants} from "../../../constants/NomenclatureConstants";
import { PaginatedSelect } from "../../../components/PaginatedSelect";
import InputFieldCyrillic from "../../applications/formFields/InputFieldCyrillic";

export default function ShortTermBasicDataForm(props:any) {
  const {
    formField: {
      evidencyReasonMvrKey,
      temporaryStaySettlementReasonMvrKey,
      temporaryStayReportedByMvrKey,
      lastName,
        placeOfRequest,
        lastNameCyrillic,
      parentName,
      parentNameCyrillic,
      firstName,
      firstNameCyrillic,
      genderMvrKey,
      birthDate,
      birthPlace,
      abroadBirthPlace,
      inRSMBirthPlace,
      inRSMBirthMunicipality,
      inRSMBirthPlacePlace,
      dateAndPlaceOfBorderCrossing,
      borderPointMvrKey,
      entranceDate,
      countryPassport,
      passportNumber,
      passportTypeMvrKey,
      visaTypeMvrKey,
      visaNumber,
      countryVisa

    }
  } = props;
  const [keyEvidency, setKeyEvidency] = useState<string>("evidency_reasons");
  const [countries,setCountries] = useState<NomenclatureEntryDTO[]>([]);
  const [borderPoints,setBorderPoints] = useState<NomenclatureEntryDTO[]>([]);
  const [evidencyReasons,setEvidencyReasons] = useState<NomenclatureEntryDTO[]>([]);
  const [genders, setGenders] = useState<NomenclatureEntryDTO[]>([]);
  const [municipalities, setMunicipalities] = useState<NomenclatureEntryDTO[]>([]);
  const [places, setPlaces] = useState<NomenclatureEntryDTO[]>([]);
  const [streets, setStreets] = useState<NomenclatureEntryDTO[]>([]);
  const [passportTypes, setPassportTypes] = useState<NomenclatureEntryDTO[]>([]);
  const [visaTypes, setVisaTypes] = useState<NomenclatureEntryDTO[]>([]);
  const [citizenships, setCitizenships] = useState<NomenclatureEntryDTO[]>([]);
  const [placeRequested, setplaceOfRequest] = useState<NomenclatureEntryDTO[]>([]);
  const [temporaryStayReportedBy, setTemporaryStayReportedBy] = useState<NomenclatureEntryDTO[]>([]);
  const [temporaryStaySettlementReasons, setTemporaryStaySettlementReasons] = useState<NomenclatureEntryDTO[]>([]);

    // const [active, setActive] = useState<boolean>();
  let {id} = useParams();
  const isAddMode = !id;
  const formikProps = useFormikContext<any>()
 
  useEffect(() => {
    async function getAllEntriesForNomenclatureEvidency(): Promise<void> {
      const evidencyReasons = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyEvidency);
      setEvidencyReasons(evidencyReasons)
      if(!formikProps.getFieldProps("evidencyReasonMvrKey").value && evidencyReasons.length > 0){
        formikProps.setFieldValue("evidencyReasonMvrKey", evidencyReasons.find(x=> x.key === NomenclatureConstants.shortTermStayEvidencyReasonKey)?.key);
      }
    }
    getAllEntriesForNomenclatureGender().then((res)=>{setGenders(res)})
    getAllEntriesForNomenclatureCitizenship().then((res)=>{setCitizenships(res)})
    getAllEntriesForNomenclatureCountry().then((res)=>{setCountries(res)})
    getAllEntriesForNomenclatureMunicipality().then((res)=>{setMunicipalities(res)})
    getAllEntriesForNomenclaturePlace().then((res)=>{setPlaces(res)})
    getAllEntriesForNomenclatureStreets().then((res)=>setStreets(res))
    getAllEntriesForNomenclatureBorderPoints().then((res) => {setBorderPoints(res)})
    getAllEntriesForNomenclaturePassportType().then((res) => {setPassportTypes(res)})
    getAllEntriesForNomenclatureVisaType().then((res) => {setVisaTypes(res)})
    getAllEntriesForNomenclaturePlaceOfRequest().then((res) => {setplaceOfRequest(res)})
    getAllEntriesForNomenclaturetemporaryStayReportedBy().then((res) => {setTemporaryStayReportedBy(res)})
    getAllEntriesForNomenclaturetemporaryStaySettlementReasons().then((res) => {setTemporaryStaySettlementReasons(res)})

      async function loadData(): Promise<void> {
      getAllEntriesForNomenclatureCountry();
      await getAllEntriesForNomenclatureEvidency();
      getAllEntriesForNomenclatureGender();
      getAllEntriesForNomenclatureMunicipality();
      getAllEntriesForNomenclaturePlace(); //
      getAllEntriesForNomenclatureStreets(); //
      getAllEntriesForNomenclaturePassportType();
      getAllEntriesForNomenclatureVisaType();
      getAllEntriesForNomenclatureBorderPoints()
    }
    loadData().then((_) => {
    });


  }, [])
  useEffect(()=>{
    props.func(!(formikProps.values.firstName || formikProps.values.lastName || formikProps.values.archiveNumber));
  },[formikProps.values.firstName,formikProps.values.lastName,formikProps.values.archiveNumber])
  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {/* Grid 1 */}
        <Grid item xs={12} sm={4} md={6}>
        <Typography variant="subtitle1" gutterBottom className={"pb-1"}>
            {evidencyReasonMvrKey.id  + strings.evidencyReasonMvrKey}
          </Typography>
           <SelectField className="pb-3"
            name={evidencyReasonMvrKey.name}
            label={strings.evidencyReasonMvrKey + "*"}
            data={evidencyReasons} disabled={true}
          />
            <Typography variant="subtitle1" fontWeight="500" gutterBottom >
                {placeOfRequest.label}
            </Typography>
            {/*<SelectField name={placeOfRequest.name} label={placeOfRequest.placeholder} data={placeRequested} className="pb-3" read={props.hasAuthority ? false : true} />*/}
            <PaginatedSelect<NomenclatureEntryDTO>
                value={formikProps.values.placeOfRequest !== "" ? formikProps.values.placeOfRequest : undefined}
                onChange={(value) => {formikProps.setFieldValue("placeOfRequest", value)}}
                label={placeOfRequest.placeholder}
                valueMapper={(item) => item?.key && item?.key?.toString()}
                keyMapper={(item) => item.id.toString()}
                itemMapper={(item) => <>{item.name}</>}
                labelMapper={(item) => item.name}
                dataFetcher={(page, size, filter) => {
                    return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                        page, size, NomenclatureConstants.organisationalUnit, filter ? filter : "", "", "", undefined, ""
                    );
                }}
                name={placeOfRequest.name}
                className="pb-3"
                readOnly={props.hasAuthority ? false : true}
            />
            <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {temporaryStaySettlementReasonMvrKey.id  + strings.temporaryStaySettlementReasonMvrKey}
          </Typography>
           <SelectField className="pb-3"
            name={temporaryStaySettlementReasonMvrKey.name}
            label={strings.temporaryStaySettlementReasonMvrKey + "*"}
            data={temporaryStaySettlementReasons} 
            read={props.hasAuthority ? false : true} 
          />
          <Typography variant="subtitle1" fontWeight="500" gutterBottom>
            {temporaryStayReportedByMvrKey.id  + strings.temporaryStayReportedByMvrKey}
          </Typography>
           <SelectField className="pb-3"
            name={temporaryStayReportedByMvrKey.name}
            label={strings.temporaryStayReportedByMvrKey + "*"}
            data={temporaryStayReportedBy} 
            read={props.hasAuthority ? false : true} 
          />
        <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-1">
       {/*1. Општи податоци*/}
      </Typography>
          <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
            {strings.identificationData}
      </Typography>
            <Typography variant="subtitle1" gutterBottom >
                {lastName.id + ". " + strings.lastName}
            </Typography>
            <InputField name={lastName.name} label={strings.lastName + "*"} fullWidth className="pb-3" icao="true" />
            <Typography variant="subtitle1" gutterBottom>
                {parentName.id + ". " + strings.parentName}
            </Typography>
            <InputField name={parentName.name} label={strings.parentName} fullWidth className="pb-3" icao="true" />
            <Typography variant="subtitle1" gutterBottom>
                {firstName.id + ". " + strings.firstName}
            </Typography>
            <InputField name={firstName.name} label={strings.firstName + "*"} fullWidth className="pb-3" icao="true" />
            <Typography variant="subtitle1" gutterBottom>
            {genderMvrKey.id + ". " + strings.gender}
          </Typography>
          <SelectField className="pb-3"
            name={genderMvrKey.name}
            label={strings.gender + "*"}
            data={genders}
            read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" gutterBottom>
            {birthDate.id + ". " + strings.birthDate}
          </Typography>
          <DatePickerField
            name={birthDate.name}
            label={strings.birthDate + "*"}
            className="pb-3"
            read={props.hasAuthority ? false : true}
            maxDate={true}
          />
          <Typography variant="subtitle1" gutterBottom>
            {birthPlace.id + ". " + strings.birthPlace}
          </Typography>
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
            <Typography variant="subtitle1" gutterBottom>
              {abroadBirthPlace.id + ". " + abroadBirthPlace.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                disabled={!!formikProps.getFieldProps("inRSMBirthMunicipality").value || !!formikProps.getFieldProps("inRSMBirthPlacePlace").value}
                        value={formikProps.values.abroadBirthPlace!=="" ? formikProps.values.abroadBirthPlace : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("abroadBirthPlace", value)
                          }
                        }
                        label={abroadBirthPlace.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={abroadBirthPlace.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
            <Typography variant="subtitle1" gutterBottom>
              {inRSMBirthPlace.id + ". " + inRSMBirthPlace.label}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              {inRSMBirthMunicipality.id + ". " + inRSMBirthMunicipality.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                disabled={!!formikProps.getFieldProps("abroadBirthPlace").value} 
                        value={formikProps.values.inRSMBirthMunicipality!=="" ? formikProps.values.inRSMBirthMunicipality : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("inRSMBirthMunicipality", value);
                          formikProps.setFieldValue("inRSMBirthPlacePlace", "")
                        }
                        }
                        label={strings.municipality}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={inRSMBirthMunicipality.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
            <Typography variant="subtitle1" gutterBottom>
              {inRSMBirthPlacePlace.id + ". " + inRSMBirthPlacePlace.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                disabled={!!formikProps.getFieldProps("abroadBirthPlace").value}
                        value={formikProps.values.inRSMBirthPlacePlace!=="" ? formikProps.values.inRSMBirthPlacePlace : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("inRSMBirthPlacePlace", value)
                          }
                        }
                        label={inRSMBirthPlacePlace.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.inRSMBirthMunicipality.key}`, filter ? filter : ""
                          );
                        }}
                        name={inRSMBirthPlacePlace.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
          </CenteredCardLayout>
        </Grid>

        {/* Grid 2 */}

        <Grid item xs={12} sm={4} md={6}>
          <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} style={{paddingBottom: "31px"}}>
            {strings.travelDocuments}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {passportTypeMvrKey.id + ". " + passportTypeMvrKey.label}
          </Typography>
          <SelectField className="pb-3"
                       name={passportTypeMvrKey.name}
                       label={passportTypeMvrKey.placeholder}
                       data={passportTypes}
                       read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" gutterBottom>
            {countryPassport.id + ". " + countryPassport.label}
          </Typography>
          <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.countryPassport!=="" ? formikProps.values.countryPassport : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("countryPassport", value)
                          }
                        }
                        label={countryPassport.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={countryPassport.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
          <Typography variant="subtitle1" gutterBottom>
            {passportNumber.id + ". " + passportNumber.label}
          </Typography>
          <InputField name={passportNumber.name} label={passportNumber.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField>

          <Typography variant="subtitle1" gutterBottom>
            {visaTypeMvrKey.id + ". " + visaTypeMvrKey.label}
          </Typography>
          <SelectField className="pb-3"
                       name={visaTypeMvrKey.name}
                       label={visaTypeMvrKey.placeholder}
                       data={visaTypes}
                       read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" gutterBottom>
            {countryVisa.id + ". " + countryVisa.label}
          </Typography>
          <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.countryVisa!=="" ? formikProps.values.countryVisa : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("countryVisa", value)
                          }
                        }
                        label={countryVisa.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={countryVisa.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
          <Typography variant="subtitle1" gutterBottom>
            {visaNumber.id + ". " + visaNumber.label}
          </Typography>
          <InputField name={visaNumber.name} label={visaNumber.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField>
          <Typography variant="subtitle1" gutterBottom>
            {dateAndPlaceOfBorderCrossing.id + ". " + dateAndPlaceOfBorderCrossing.label}
          </Typography>
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
            <Typography variant="subtitle1" gutterBottom>
              {borderPointMvrKey.id + ". " + borderPointMvrKey.label}
            </Typography>
            <SelectField className="pb-3"
                         name={borderPointMvrKey.name}
                         label={borderPointMvrKey.placeholder + "*"}
                         data={borderPoints}
                         read={props.hasAuthority ? false : true}
            />
            <Typography variant="subtitle1" gutterBottom>
              {entranceDate.id + ". " + entranceDate.label}
            </Typography>
            <DatePickerField
                name={entranceDate.name}
                label={entranceDate.placeholder + "*"}
                className="pb-3"
                read={props.hasAuthority ? false : true}
                //maxDate={true}
            />
          </CenteredCardLayout>
        </Grid>
      </Grid>
    </>
  )
}

