export class PoliticalActivityDTO {
    id: number;
    politicalParty: string;
    position: string;
    other?: string;
    constructor(json: PoliticalActivityDTO) {
        this.id = json.id
        this.politicalParty = json.politicalParty;
        this.position = json.position;
        this.other = json.other;
    }
}
