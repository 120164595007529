import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { EmploymentDataForm } from './forms/EmploymentDataForm';
import { SettleDataForm } from './forms/SettleDataForm';
import { NoteForeignerDataForm } from './forms/NoteForeignerDataForm';
import FormModel from './formModel/FormModel';
import ValidationSchema from './formModel/ValidationSchema';
import FormInitialValues from './formModel/FormInitialValues';
import { SideBarPage } from '../../components/SideBarPage';
import { CenteredCardLayout } from '../../components/CenteredCardLayout';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Step,
  StepButton,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useStyles from './styles/styles';
import BasicDataForm from './forms/BasicDataForm';
import { ForeignCitizenService } from '../../services/registrations/ForeignCitizenService';
import { showErrorDialog, showSuccessDialog } from '../../common/Dialogues';
import { strings } from '../../localization/Localization';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../../router/Routes';
import { ErrorHandler } from '../../utils/ErrorHandler';
import { ForeignUserData } from './ForeignUserData';
import { RegistrationDTO } from '../../models/foreign_citizens/RegistrationDTO';
import { ForeignCitizenReducedDTO } from '../../models/registration/ForeignCitizenReducedDTO';
import { DateTimeUtils } from '../../utils/DateTimeUtils';
import { ForeignCitizenDTO } from '../../models/foreign_citizens/ForeignCitizenDTO';
import RightSidePanel from '../../components/right_side_component/RightSidePanel';
import { TemplateDTO } from '../../models/templates/TemplateDTO';
import { CreatedByInfo } from '../../components/CreatedByInfo';
import {
  getAllEntriesForNomenclatureCitizenship,
  getAllEntriesForNomenclatureCountry,
  getAllEntriesForNomenclatureForeignLanguage,
  getAllEntriesForNomenclatureMunicipality,
  getAllEntriesForNomenclatureOVR,
  getAllEntriesForNomenclatureOccupation,
  getAllEntriesForNomenclaturePlace,
  getAllEntriesForNomenclatureStreets,
  getAllEntriesForOrganisationalUnit
} from "../../common/NomenclatureMethods";
import { ArrayUtils } from "../../utils/ArrayUtils";
import { RegistrationStatuses } from "../../constants/RegistrationStatuses";
import ConfirmationModal from "../../components/ConfirmationModal";
import { PassivationService } from '../../services/PassivationService';
import { EMBGModal } from "../../components/EMBGModal";
import { UserDetailsInfoResponse } from '../../models/UserDetailsInfoResponse';
import { AuthService } from '../../services/AuthService';
import { Privileges } from '../../models/nomenclatures/Privileges';
import { StorageService } from '../../storage/StorageService';
import { NomenclatureConstants } from '../../constants/NomenclatureConstants';
import { TemplateService } from '../../services/TemplateService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FileDTO } from '../../models/foreign_citizens/FileDTO';
import {RegistrationCreationDTO} from "../../models/foreign_citizens/RegistrationCreationDTO";
import { LightTooltip } from '../../components/LightTooltip';
import {AutoScroller} from "../../components/AutoScroller";
import {NomenclatureEntryDTO} from "../../models/nomenclatures/NomenclatureEntryDTO";
import {NomenclatureUtils} from "../../utils/NomenclatureUtils";
let steps = ['Општи податоци', 'Патна исправа', 'Постојано населување или привремен престој', 'Податоци за преземените мерки'].map(n => {
  return { name: n, completed: false };
});

export default function ForeignUserForm() {
  const { formId, formField } = FormModel;
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema:any = ValidationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const classes = useStyles();
  const formikRef = useRef<any>(null);
  const navigate = useNavigate();
  let { id } = useParams();
  const [versions, setVersions] = useState<ForeignCitizenReducedDTO[] | undefined>([])
  const isAddMode = !id;
  const [lastSubmittedValues, setLastSubmittedValues] = useState<{} | null>(null);
  const [registrationData, setRegistrationData] = useState<RegistrationDTO>();
  const [version, setVersion] = useState<string>();
  const [foreignCitizenData, setForeignCitizenData] = useState<ForeignCitizenDTO>();
  const [showGenerateBtn, setShowGenerateBtn] = useState<boolean>(false);
  const [openCheckModal, setOpenCheckModal] = useState<boolean>(false);
  const [openBasicDataChangesModal, setOpenBasicDataChangesModal] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openRequest, setOpenRequest] = useState<boolean>(false);
  const [openActivateRequest, setOpenActivateRequest] = useState<boolean>(false);
  const [openActivateModal, setOpenActivateModal] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserDetailsInfoResponse>();
  const [values, setValues] = useState<any>();
  const [hasAuthority, setHasAuthority] = useState<boolean>();
  const [copyToAsylum, setCopyToAsylum] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [foreignUsersTemplates, setForeignUsersTemplates] = useState<TemplateDTO[]>([]);
  const [registrationFiles, setRegistrationFiles] = useState<FileDTO[]>([]);
  const [historyActions, setHistoryActions] = useState<any>();
  const [historyActionsEMBG, setHistoryActionsEMBG] = useState<any>();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialog2, setOpenDialog2] = React.useState(false);
  const [isPasivise, setIsPasivise] = useState<boolean>(true)
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [correctionOfPerData, setCorrectionOfPerData] = useState<boolean | null>(null);
  const { state } = useLocation();
  const [openDeceasedModal, setOpenDeceasedModal] = useState<boolean>(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>()
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen2 = () => {
    setOpenDialog2(true);
  };

  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };

  function getHistory(id: number) {
    ForeignCitizenService.getHistoryActions(id).then(data => {
      setHistoryActions(data)
    });
  }

  function getHistoryEMBG(id: number) {
    ForeignCitizenService.getHistoryEMBG(id).then(data => {
      setHistoryActionsEMBG(data)
    });
  }

  useEffect(() => {
    if (id) {
      getHistory(parseInt(id))
    }
    if (id) {
      getHistoryEMBG(parseInt(id))
    }

    async function userHasAuthority(): Promise<void> {
      const userRoles: string[] | null = StorageService.getUserRoles();
      if (userRoles !== null) {
        if (userRoles.includes(Privileges.MANAGE_FOREIGN_CITIZEN)) {
          setHasAuthority(true)
        }
        if (userRoles.includes(Privileges.MANAGE_ASYLUM_REQUEST)) {
          setCopyToAsylum(true)
        }
      } else {
        const authorization = await AuthService.getAuthorization();
        if (authorization.roles.includes(Privileges.MANAGE_FOREIGN_CITIZEN)) {
          setHasAuthority(true)
        }
        if (authorization.roles.includes(Privileges.MANAGE_ASYLUM_REQUEST)) {
          setCopyToAsylum(true)
        }
      }
    }

    userHasAuthority().then((_) => { });

  }, []);

  const handleOpen = () => setOpen(true);
  function handleClose(actionPerformed: boolean): any {
    setOpen(false);

    if (actionPerformed) {
      refreshData();
    }
  }

  function handleOpenConfirmationModal(): any {
    setOpenConfirmation(true);
  }

  function handleCloseConfirmationModal(actionPerformed: boolean): any {
    setOpenConfirmation(false);

    if (actionPerformed) {
      refreshData();
    }
  }
  function handleOpenRequest() {
    setOpenRequest(true)
  }
  function handleCloseRequest(actionPerformed: boolean) {
    setOpenRequest(false);

    if (actionPerformed) {
      refreshData();
    }
  }
  function handleOpenActivateRequest() {
    setOpenActivateRequest(true)
  }
  function handleCloseActivateRequest(actionPerformed: boolean) {
    setOpenActivateRequest(false);

    if (actionPerformed) {
      refreshData();
    }
  }

  function handleOpenActivateEMBGModal() {
    setOpenActivateModal(true)
  }
  function handleCloseActivateEMBGModal(actionPerformed: boolean) {
    setOpenActivateModal(false);

    if (actionPerformed) {
      refreshData();
    }
  }
  function renderStepContent(step: number) {
    switch (step) {
      case 0:
        return <BasicDataForm hasAuthority={hasAuthority} formField={formField} func={pull_data} />;
      case 1:
        return <EmploymentDataForm hasAuthority={hasAuthority} formField={formField} />;
      case 2:
        return <SettleDataForm hasAuthority={hasAuthority} formField={formField} />;
      case 3:
        return <NoteForeignerDataForm hasAuthority={hasAuthority} formField={formField} />;
      default:
        return <div>Not Found</div>;
    }
  }

  function handleChange(event: SelectChangeEvent) {
    setVersion(event.target.value)
  }

  function handleCloseCheckModal(): any {
    setOpenCheckModal(false);
    setActiveStep(steps.length-1)
  }
  function handleCloseBasicDataChangesModal(): any {
    setOpenBasicDataChangesModal(false);
    setActiveStep(steps.length - 1)
  }

  async function handleClickCorrection(){
    setCorrectionOfPerData(true);
    setOpenBasicDataChangesModal(false);

    if (!openCheckModal){
      await await updateForeignCitizenData(values, true);
    }
  }
  async function handleClickChange(){
    setCorrectionOfPerData(false);
    setOpenBasicDataChangesModal(false);

    if (!openCheckModal){
      await await updateForeignCitizenData(values, false);
    }

  }
  async function addForeignCitizen(values: any) {
    let data = ForeignUserData(values)
    data.submitted = true;
    if (registrationData?.workingVersionRegistrationId?.id) {
      data.workingVersionId = registrationData?.workingVersionRegistrationId?.id.toString();
    }
    await ForeignCitizenService.createForeignCitizen(data)
      .then((res) => {
        let route = Routes.EDIT_APPLICATION;
        route = route.replace(":id", res.id.toString());
        showSuccessDialog(strings.addForeignCitizenSuccess, strings.addForeignCitizenSuccess, strings.ok).then(_ => {
          setActiveStep(0)
          if (res?.versions) {
            setVersions(res?.versions)
            setVersion(res?.versions[0]?.id.toString() || '')
            navigate(route);
          }
        });
      })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
        setActiveStep(steps.length - 1)
      });
  }
  function checkIfBasicChangesExist(oldData : ForeignCitizenDTO | undefined, newData : RegistrationCreationDTO) : boolean{
    if((oldData?.personData?.firstName !== newData?.foreignCitizenData?.personDataCreationDTO?.firstName) ||
        (oldData?.personData?.lastName !== newData?.foreignCitizenData?.personDataCreationDTO?.lastName) ||
        (oldData?.personData?.firstNameCyrillic !== newData?.foreignCitizenData?.personDataCreationDTO?.firstNameCyrillic) ||
        (oldData?.personData?.lastNameCyrillic !== newData?.foreignCitizenData?.personDataCreationDTO?.lastNameCyrillic)){
      return true;
    }
    return false;
  }

  async function updateForeignCitizenData(values:any, correctionOfPersonData? : boolean | null) {
    let data = ForeignUserData(values, correctionOfPersonData !== undefined ? correctionOfPersonData : correctionOfPerData);
    data.submitted = true;
    setValues(values)
    if(registrationData?.id){
      id = registrationData.id.toString();
    }
    if(id){
      await ForeignCitizenService.updateForeignCitizen(+id, data)
      .then((res) => {
        showSuccessDialog(strings.editForeignCitizenSuccess, strings.addForeignCitizenSuccess, strings.ok).then(() => {
          setActiveStep(0)
          if(res?.versions){
            setVersions(res?.versions)
            setVersion(res?.versions[0]?.id.toString() || '')
            setCorrectionOfPerData(null)
            let data = ForeignUserData(values, null);
            navigate(Routes.EDIT_APPLICATION.replace(":id", id!.toString()));
          }
          getFilesForRegistration(parseInt(id!))
        });
      })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        showErrorDialog(strings.fail, errorMessage, strings.ok).then(() => {});
        setActiveStep(steps.length - 1);
      });
  }
}

async function editForeignCitizen(values: any) {
  let data = ForeignUserData(values);
  data.submitted = true;
  setValues(values);
  if (id) {
    let showModal = false;
    if (registrationData?.embg) {
      if (
        registrationData.registrationStatus?.key === "passive" &&
        (foreignCitizenData?.personData?.genderMvrKey !== data?.foreignCitizenData?.personDataCreationDTO?.genderMvrKey ||
        foreignCitizenData?.personData?.birthDate !== data?.foreignCitizenData?.personDataCreationDTO?.birthDate)
      ) {
        showModal = true;
        setIsPasivise(true);
      } else if (
          registrationData?.registrationStatus !== undefined && registrationData.registrationStatus?.key !== 'passive' &&
       ( foreignCitizenData?.personData?.genderMvrKey !== data?.foreignCitizenData?.personDataCreationDTO?.genderMvrKey ||
        foreignCitizenData?.personData?.birthDate !== data?.foreignCitizenData?.personDataCreationDTO?.birthDate)
        ){
          showModal = true;
          setIsPasivise(false);
      }
      let checkBasicInfoChanges = checkIfBasicChangesExist(foreignCitizenData, data);
      if (checkBasicInfoChanges){
        setOpenBasicDataChangesModal(true);
      }
      if (showModal) {
        setOpenCheckModal(true);
      }
      if (!checkBasicInfoChanges && !showModal){
        await updateForeignCitizenData(values);
      }
    } else {
      await updateForeignCitizenData(values);
    }
  }
}

  async function submitForm(values: {}, actions: any) {
    setIsLoading(true);
    if (!registrationData?.id) {
      await addForeignCitizen(values);
      setLastSubmittedValues(values)
    } else {
      await editForeignCitizen(values);
      setLastSubmittedValues(values)
    }
    actions.setSubmitting(false);
    setIsLoading(false);
    for (let step of steps) {
      step.completed = false;
    }
    setActiveStep(activeStep + 1)

  }

   function handleSubmitForm(values: {}, actions: any) {
    setIsLoading(true)
    if (isLastStep) {
      let doSubmit = true;

      for (let index = 0; index < steps.length - 1; index++) {
        if (!steps[index].completed) {
          actions.setSubmitting(false);
          setActiveStep(index);
          doSubmit = false;
          break;
        }
      }
      if (doSubmit) {
        submitForm(values, actions);
      }
    } else {
      setActiveStep(activeStep + 1);
      steps[activeStep].completed = true;
      actions.setTouched({});
      actions.setSubmitting(false);
    }
    setIsLoading(false)
  }
  async function createOrUpdateWorkingVersion() {
    setIsLoadingSave(true)
    let data = ForeignUserData(formikRef?.current?.values)
    data.submitted = false;
    if (registrationData?.id) {
      data.id = registrationData?.id.toString();
    }
    if (registrationData?.workingVersionRegistrationId?.id) {
      data.workingVersionId = registrationData.workingVersionRegistrationId.id.toString();
    }
    await ForeignCitizenService.createOrUpdateWorkingVersion(data)
      .then((res) => {
        let route = Routes.EDIT_APPLICATION;
        if (res.workingVersionRegistrationId?.id) {
          route = route.replace(":id", res?.workingVersionRegistrationId?.id.toString());
          localStorage.setItem("workingVersionId", res?.workingVersionRegistrationId?.id.toString())
        }
        showSuccessDialog(strings.successfullyAddedWorkingVersionForeign, strings.successfullyAddedWorkingVersionForeign, strings.ok).then(_ => {
          setIsLoadingSave(false)
          if (res?.versions) {
            setVersions(res?.versions)
            setVersion(res?.versions[0]?.id.toString() || '')
            navigate(route)
          }
        });
      })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
        setActiveStep(steps.length - 1)
      });
  }
  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  const handleStepValidation =  async(stepIndex:number) => {
    steps[activeStep].completed = true;
    try {
      await currentValidationSchema.validate(formikRef?.current?.values);
    } catch (e) {
      steps[activeStep].completed = false;
    }
    setActiveStep(stepIndex);
  };

  async function getVersion(id: number) {
    const data = await ForeignCitizenService.getForeignCitizenVersionForRegistration(+id, version && version);
    setForeignCitizenData(data)
  }
  useEffect(() => {
    if (formikRef?.current?.values?.evidencyReasonMvrKey !== undefined) {
      if (formikRef?.current?.values.evidencyReasonMvrKey === null || formikRef?.current?.values.evidencyReasonMvrKey !== foreignCitizenData?.evidencyReason?.mvrKey) {
        formikRef?.current?.setFieldValue('settlementReasonMvrKey', "");
        formikRef?.current?.setFieldValue('measureTakenType', "");
      }
    }
  }, [formikRef?.current?.values?.evidencyReasonMvrKey]);

  async function getRegistrationById(id: number) {
    setIsLoadingData(true)
    const registration = await ForeignCitizenService.getRegistrationById(id);
    setVersions(registration.versions);
    if (registration.versions) {
      if (!version && (registration.versions?.length ?? 0) > 0) {
        setVersion(registration.versions[0].id.toString());
      }
    }
    setRegistrationData(registration)
    setIsLoadingData(false)
    if (registration.embg) {
      setShowGenerateBtn(false);
    } else {
      setShowGenerateBtn(true);
    }
  }
  const pull_data = (data: any) => {
    setDisabledSaveButton(data)
  }
  useEffect(() => {

    async function copyDataFromShortTermToForeignCitizen() {
      if (isAddMode && state?.data) {
        setIsLoadingData(true)
        state.data.submitted = false
        state.data.id = null
        state.data.workingVersionId = null
        state.data.workingVersionRegistrationId = null
        setForeignCitizenData(state.data?.foreignCitizen)
        setRegistrationData(state.data)
        setIsLoadingData(false)
      }
    }

    async function getData() {
      if (id && !isAddMode) {
        await getRegistrationById(parseInt(id));
        if (version) {
          await getVersion(parseInt(id));
        }
      }
    }
    getUserDetails();
    getData();
    copyDataFromShortTermToForeignCitizen().then(_ => { });
  }, [id, isAddMode, version])

  function refreshData(): any {
    async function getData() {
      if (id && !isAddMode) {
        await getRegistrationById(parseInt(id));
        await getHistory(parseInt(id));
        await getHistoryEMBG(parseInt(id));
        if (version) {
          await getVersion(parseInt(id));
        }

      }
    }
    getData().then(_ => {
    });
  }


  async function getTemplatesForForeignCitizen(): Promise<void> {
    const foreignUserTemplates: TemplateDTO[] = await TemplateService.getTemplatesForForeignCitizen();
    setForeignUsersTemplates(foreignUserTemplates)
  }

  async function getFilesForRegistration(id: number): Promise<void> {
    const files: FileDTO[] = await ForeignCitizenService.getFilesForRegistration(id);
    setRegistrationFiles(files)
  }

  useEffect(() => {
    if (!isAddMode && id) {
      getTemplatesForForeignCitizen().then((_) => { });
      getFilesForRegistration(parseInt(id)).then((_) => { });
    }
    const schema = ValidationSchema;
    for(let i=0;i<steps.length;i++){
      if(schema[i].isValidSync(formikRef?.current?.values)){
        steps[i].completed = true;
      }else {
        steps[i].completed = false;
      }
    }

  }, [formikRef?.current?.values]);

  function activateRequest() {
    if (id) {
      PassivationService.activateRegistration(id).then(() => {
        showSuccessDialog(strings.successfullyActivatedRequest, strings.successfullyActivatedRequest, strings.ok).then(_ => {
          handleCloseActivateRequest(true)
          refreshData()
        });
      })
        .catch((e) => {
          const errorMessage = ErrorHandler.parseErrorMessage(e);
          showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => { })
        });
    }
  }
  function getUserDetails() {
    AuthService.getUserDetails().then((res) => {
      setUserDetails(res)
    })
  }

  function markAsDeceased(){
    if(id){
      PassivationService.markAsDeceased(Array.of(+id)).then(()=>{
        showSuccessDialog(strings.successfullyMarkedAsDeceased, strings.successfullyMarkedAsDeceased, strings.ok).then(_ => {
          handleCloseDeceasedModal()
          refreshData()
        });
      })
          .catch((e) => {
            const errorMessage = ErrorHandler.parseErrorMessage(e);
            showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
          });
    }
  }

  function handleOpenDeceasedModal() {
    setOpenDeceasedModal(true)
  }

  function handleCloseDeceasedModal() {
    setOpenDeceasedModal(false)
    refreshData()
  }
  useEffect(() => {
    if (foreignCitizenData) {
      const countryKeyPerson = foreignCitizenData.personData?.birthPlaceDTO?.countryDTO.mvrKey;
      const countryKeyFamilyMember = foreignCitizenData.familyMember?.birthPlaceDTO?.countryDTO.mvrKey;
      const municipalityKeyPerson = foreignCitizenData.personData?.birthPlaceDTO?.municipalityMvrKey;
      const municipalityKeyFamilyMember = foreignCitizenData.familyMember?.birthPlaceDTO?.municipalityMvrKey;
      getAllEntriesForNomenclatureOccupation().then((res) => {
        const occupation = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, foreignCitizenData.personData?.occupationMvrKey);
        const employmentArea = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, foreignCitizenData.employmentData?.occupationFieldMvrKey);
        const engagedProperty = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, foreignCitizenData.employmentData?.engagementTypeMvrKey);

        formikRef?.current?.setFieldValue("engagedProperty", engagedProperty)
        if (occupation) {
          formikRef?.current?.setFieldValue("occupation", occupation);
        }
        formikRef?.current?.setFieldValue("employmentArea", employmentArea)
      })
      getAllEntriesForNomenclatureCitizenship().then((res) => {
        const citizenShip = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, foreignCitizenData.personData?.citizenshipCreationDTO?.mvrKey)
        const otherCitizenShip = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.personData?.otherCitizenshipCreationDTO?.mvrKey)
        const citizen = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.host?.citizenshipCreationDTO?.mvrKey)
        formikRef?.current?.setFieldValue("citizenShip", citizenShip);
        formikRef?.current?.setFieldValue("otherCitizenShip", otherCitizenShip)
        formikRef?.current?.setFieldValue("citizen", citizen)
      })
      getAllEntriesForNomenclatureCountry().then((res) => {
        const abroadBirthPlace = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, foreignCitizenData.personData?.birthPlaceDTO?.countryDTO?.mvrKey);
        const countryPreviousLiving = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, foreignCitizenData.previousLivingPlace?.countryDTO?.mvrKey);

        const stayCountry = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, foreignCitizenData.previousSettlementPlace?.countryDTO.mvrKey);
        const countryPassport = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, foreignCitizenData.passport?.countryDTO?.mvrKey);
        const companyCountry = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, foreignCitizenData.foreignOrganization?.placeDTO?.countryDTO?.mvrKey);
        const spouseAbroadBirthPlace = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res, foreignCitizenData.familyMember?.birthPlaceDTO?.countryDTO.mvrKey);

        if (abroadBirthPlace && abroadBirthPlace?.key !== NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("abroadBirthPlace", abroadBirthPlace);
        } else if (abroadBirthPlace && abroadBirthPlace?.key === NomenclatureConstants.macedoniaMvrKey && municipalityKeyPerson) {
          formikRef?.current?.setFieldValue("abroadBirthPlace", "");
        }
        formikRef?.current?.setFieldValue("countryPreviousLiving", countryPreviousLiving);
        if (stayCountry) {
          formikRef?.current?.setFieldValue("stayCountry", stayCountry)
        }
        formikRef?.current?.setFieldValue("countryPassport", countryPassport);
        formikRef?.current?.setFieldValue("companyCountry", companyCountry)
        formikRef?.current?.setFieldValue("spouseAbroadBirthPlace", spouseAbroadBirthPlace);
        if (spouseAbroadBirthPlace && spouseAbroadBirthPlace?.key !== NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("spouseAbroadBirthPlace", spouseAbroadBirthPlace);
        } else if (spouseAbroadBirthPlace && spouseAbroadBirthPlace?.key === NomenclatureConstants.macedoniaMvrKey && municipalityKeyFamilyMember) {
          formikRef?.current?.setFieldValue("spouseAbroadBirthPlace", "");
        }
      })
      getAllEntriesForNomenclatureMunicipality().then((res) => {
        const livingMunicipalityRSM = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.personData?.addressDTO?.streetDTO.placeDTO?.municipalityMvrKey)
        const inRSMBirthMunicipality = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.personData?.birthPlaceDTO?.municipalityMvrKey)
        const municipalityOrganization = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.employmentData?.organizationCreationDTO?.addressDTO?.streetDTO?.placeDTO?.municipalityMvrKey)
        const familyMemberBirthMunicipality = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.familyMember?.birthPlaceDTO?.municipalityMvrKey)
        const municipalityPrivateVisitPerson = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.host?.addressDTO?.streetDTO.placeDTO?.municipalityMvrKey)
        const municipalityRelative = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.familyMember?.addressDTO?.streetDTO.placeDTO?.municipalityMvrKey)
        const placeMeasuresTakenMunicipality = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData?.measuresData?.placeDTO?.municipalityMvrKey)
        if (inRSMBirthMunicipality && countryKeyPerson === NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("inRSMBirthMunicipality", inRSMBirthMunicipality)
        } else if (countryKeyPerson !== NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("inRSMBirthMunicipality", "")
        }

        formikRef?.current?.setFieldValue("livingMunicipalityRSM", livingMunicipalityRSM)
        formikRef?.current?.setFieldValue("municipalityOrganization", municipalityOrganization)
        if (familyMemberBirthMunicipality && countryKeyFamilyMember === NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("familyMemberBirthMunicipality", familyMemberBirthMunicipality);
        } else if (countryKeyFamilyMember !== NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("familyMemberBirthMunicipality", "")
        }

        formikRef?.current?.setFieldValue("municipalityPrivateVisitPerson", municipalityPrivateVisitPerson);
        formikRef?.current?.setFieldValue("municipalityRelative", municipalityRelative);

        if (placeMeasuresTakenMunicipality) {
          formikRef?.current?.setFieldValue("placeMeasuresTakenMunicipality", placeMeasuresTakenMunicipality)
        }
      })
      getAllEntriesForNomenclaturePlace().then((res) => {
        const livingPlaceRSM = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.personData?.addressDTO?.streetDTO.placeDTO?.mvrKey)
        const illegalPlaceMunicipality = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.entranceData?.placeDTO?.municipalityMvrKey)
        const illegalPlacePlace = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.entranceData?.placeDTO?.mvrKey)
        const placeOrganization = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.employmentData?.organizationCreationDTO?.addressDTO?.streetDTO?.placeDTO?.mvrKey)
        const placePrivateVisitPerson = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.host?.addressDTO?.streetDTO.placeDTO?.mvrKey)
        const placeRelative = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.familyMember?.addressDTO?.streetDTO.placeDTO?.mvrKey)

        const placeMeasuresTakenPlace = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.measuresData?.placeDTO?.mvrKey)
        const attemptIllegalPlace = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.measuresData?.illegalLeavePlaceDTO?.mvrKey)
        const inRSMBirthPlacePlace = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.personData?.birthPlaceDTO?.mvrKey)
        const familyMemberBirthPlace = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData?.familyMember?.birthPlaceDTO?.mvrKey)
        formikRef?.current?.setFieldValue("livingPlaceRSM", livingPlaceRSM)
        formikRef?.current?.setFieldValue("illegalPlaceMunicipality", illegalPlaceMunicipality)
        formikRef?.current?.setFieldValue("illegalPlacePlace", illegalPlacePlace)
        formikRef?.current?.setFieldValue("placeOrganization", placeOrganization)
        formikRef?.current?.setFieldValue("placePrivateVisitPerson", placePrivateVisitPerson);
        formikRef?.current?.setFieldValue("placeRelative", placeRelative);

        if (placeMeasuresTakenPlace) {
          formikRef?.current?.setFieldValue("placeMeasuresTakenPlace", placeMeasuresTakenPlace)
        }
        if (inRSMBirthPlacePlace && countryKeyPerson === NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("inRSMBirthPlacePlace", inRSMBirthPlacePlace)
          formikRef?.current?.setFieldValue("placeOfBirthAbroad", "")
        } else if (countryKeyPerson !== NomenclatureConstants.macedoniaMvrKey && !municipalityKeyPerson) {
          formikRef?.current?.setFieldValue("placeOfBirthAbroad", foreignCitizenData.personData?.birthPlaceDTO?.mvrKey)
          formikRef?.current?.setFieldValue("inRSMBirthPlacePlace", "")
        }
        formikRef?.current?.setFieldValue("attemptIllegalPlace", attemptIllegalPlace)
        if (familyMemberBirthPlace && countryKeyFamilyMember === NomenclatureConstants.macedoniaMvrKey) {
          formikRef?.current?.setFieldValue("familyMemberBirthPlace", familyMemberBirthPlace)
          formikRef?.current?.setFieldValue("placeOfBirthSpouseAbroad", "")
        } else if (countryKeyFamilyMember !== NomenclatureConstants.macedoniaMvrKey && !municipalityKeyFamilyMember) {
          formikRef?.current?.setFieldValue("placeOfBirthSpouseAbroad", foreignCitizenData.familyMember?.birthPlaceDTO?.mvrKey)
          formikRef?.current?.setFieldValue("familyMemberBirthPlace", "")
        }

      })
      getAllEntriesForNomenclatureStreets().then((res) => {
        const livingStreetRSM = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData?.personData?.addressDTO?.streetDTO?.mvrKey)
        const streetOrganization = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData?.employmentData?.organizationCreationDTO?.addressDTO?.streetDTO?.mvrKey)
        const streetPrivateVisitPerson = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.host?.addressDTO?.streetDTO?.mvrKey)
        const streetRelative = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData.familyMember?.addressDTO?.streetDTO?.placeDTO?.mvrKey)

        formikRef?.current?.setFieldValue("livingStreetRSM", livingStreetRSM)
        formikRef?.current?.setFieldValue("streetOrganization", streetOrganization)
        formikRef?.current?.setFieldValue("streetPrivateVisitPerson", streetPrivateVisitPerson)
        formikRef?.current?.setFieldValue("streetRelative", streetRelative)


      })
      getAllEntriesForNomenclatureOVR().then((res) => {
        const OVR = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData?.entranceData?.ovrMvrKey)
        const OVRPetitioner = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  foreignCitizenData?.ovrMvrKey)
        formikRef?.current?.setFieldValue("OVR", OVR)
        formikRef?.current?.setFieldValue("OVRPetitioner", OVRPetitioner)
      });
      getAllEntriesForNomenclatureForeignLanguage().then((res) => {
        const languages = res;
        const languageKeys = foreignCitizenData.personData?.personLanguageCreationDTOs?.map(language => language.languageMvrKey);
        if (languageKeys && languageKeys.length > 0) {
          const selectedLanguages = languageKeys?.map(key => NomenclatureUtils.findNomenclatureEntryOrGetDefault(languages, key));
          formikRef?.current?.setFieldValue('foreignLanguage', selectedLanguages);
        } else {
          formikRef?.current?.setFieldValue('foreignLanguage', "");
        }
      });
      getAllEntriesForOrganisationalUnit().then((res) => {
        let placeOfRequest = NomenclatureUtils.findNomenclatureEntryOrGetDefault(res,  registrationData?.placeOfRequest?.mvrKey)
        if (!placeOfRequest && registrationData?.placeOfRequest?.mvrKey) {
          placeOfRequest = NomenclatureEntryDTO.fromKey(registrationData.placeOfRequest.mvrKey);
        }
        formikRef?.current?.setFieldValue('placeOfRequest', placeOfRequest)
      })
      if (foreignCitizenData.evidencyReason?.mvrKey !== NomenclatureConstants.shortTermStayEvidencyReasonKey) {
        formikRef?.current?.setFieldValue('evidencyReasonMvrKey', foreignCitizenData.evidencyReason?.mvrKey);
      }
      if (foreignCitizenData.evidencyReason?.mvrKey === NomenclatureConstants.shortTermStayEvidencyReasonKey) {
        formikRef?.current?.setFieldValue('evidencyReasonMvrKey', "");
      }
      formikRef?.current?.setFieldValue("lastName", foreignCitizenData.personData?.lastName)
      formikRef?.current?.setFieldValue("firstName", foreignCitizenData.personData?.firstName);
      formikRef?.current?.setFieldValue('existingMacEmbg', foreignCitizenData.personData?.existingMacEmbg);

      formikRef?.current?.setFieldValue("genderMvrKey", foreignCitizenData.personData?.genderMvrKey);
      if (registrationData?.embg) {
        formikRef?.current?.setFieldValue("embg", registrationData?.embg);
      }
      formikRef?.current?.setFieldValue("maidenName", foreignCitizenData.personData?.maidenName);
      formikRef?.current?.setFieldValue("birthDate", foreignCitizenData.personData?.birthDate)
      if (foreignCitizenData.personData?.parentName) { formikRef?.current?.setFieldValue("parentName", foreignCitizenData.personData?.parentName) }
      if (foreignCitizenData.personData?.parentSurname) { formikRef?.current?.setFieldValue("parentSurname", foreignCitizenData.personData?.parentSurname) }
      if (foreignCitizenData.personData?.motherName) { formikRef?.current?.setFieldValue("motherName", foreignCitizenData.personData?.motherName) }
      if (foreignCitizenData.personData?.motherSurname) { formikRef?.current?.setFieldValue("motherSurname", foreignCitizenData.personData?.motherSurname) }
      formikRef?.current?.setFieldValue("education", foreignCitizenData?.personData?.professionalTrainingMvrKey)
      formikRef?.current?.setFieldValue("marriageStatus", foreignCitizenData?.personData?.marriageStatusMvrKey)
      formikRef?.current?.setFieldValue("livingStreetNumberRSM", foreignCitizenData?.personData?.addressDTO?.streetNumber)
      formikRef?.current?.setFieldValue("livingStreetNumberRSM", foreignCitizenData?.personData?.addressDTO?.streetNumber)
      formikRef?.current?.setFieldValue("addressDateFrom", foreignCitizenData?.personData?.addressDateFrom)
      if (foreignCitizenData.personData?.addressDTO?.entranceNumber) {
        formikRef?.current?.setFieldValue("livingApartmentEntranceRSM", foreignCitizenData?.personData?.addressDTO?.entranceNumber)
      }
      if (foreignCitizenData.personData?.addressDTO?.apartmentNumber) {
        formikRef?.current?.setFieldValue("livingApartmentRSM", foreignCitizenData?.personData?.addressDTO?.apartmentNumber)
      }
      if (foreignCitizenData.host?.addressDTO?.entranceNumber) {
        formikRef?.current?.setFieldValue("apartmentEntrancePrivateVisitPerson", foreignCitizenData.host?.addressDTO?.entranceNumber)
      }
      if (foreignCitizenData.host?.addressDTO?.apartmentNumber) {
        formikRef?.current?.setFieldValue("apartmentPrivateVisitPerson", foreignCitizenData.host?.addressDTO?.apartmentNumber)
      }
      if (foreignCitizenData.previousLivingPlace?.mvrKey) {
        formikRef?.current?.setFieldValue("place", foreignCitizenData?.previousLivingPlace?.mvrKey);
      }
      formikRef?.current?.setFieldValue("OVRMeasuresTaken", foreignCitizenData.ovrMvrKey)
      if (foreignCitizenData?.previousSettlementPlace?.mvrKey) { formikRef?.current?.setFieldValue("stayPlace", foreignCitizenData?.previousSettlementPlace?.mvrKey); }
      formikRef?.current?.setFieldValue("settlementReasonMvrKey", foreignCitizenData.settlementReasonMvrKey);
      formikRef?.current?.setFieldValue("idForeignerNumber", foreignCitizenData.identityCardData?.cardNumber)
      formikRef?.current?.setFieldValue("approvalDate", foreignCitizenData.identityCardData?.approvalDate)
      formikRef?.current?.setFieldValue("dateIdForeignerNumber", foreignCitizenData.identityCardData?.date?.toString())
      formikRef?.current?.setFieldValue("employmentApproval", foreignCitizenData.employmentData?.employmentApproval)
      formikRef?.current?.setFieldValue("occupationPlace", foreignCitizenData.employmentData?.employmentPositionMvrKey)
      formikRef?.current?.setFieldValue("employedAt", foreignCitizenData.employmentData?.employmentSectorMvrKey)
      formikRef?.current?.setFieldValue("statusEmployment", foreignCitizenData.employmentData?.employmentStatusMvrKey)
      formikRef?.current?.setFieldValue("nameOfOrganization", foreignCitizenData.employmentData?.organizationCreationDTO?.name)
      formikRef?.current?.setFieldValue("numberOfForeignChildren", foreignCitizenData.familyMember?.numberOfForeignChildren?.toString())
      formikRef?.current?.setFieldValue("numberOfDomesticChildren", foreignCitizenData.familyMember?.numberOfDomesticChildren?.toString())
      formikRef?.current?.setFieldValue("discovererMvrKey", foreignCitizenData.entranceData?.discovererMvrKey)
      formikRef?.current?.setFieldValue("arrivalDate", foreignCitizenData.entranceData?.entranceDate)
      formikRef?.current?.setFieldValue("arrivalWay", foreignCitizenData.entranceData?.entranceMannerMvrKey)
      formikRef?.current?.setFieldValue("sourceOfLivelihood", foreignCitizenData.entranceData?.financialSourceTypeMvrKey)
      formikRef?.current?.setFieldValue("shelterForeigners", foreignCitizenData.entranceData?.visitedForeignerShelter)
      formikRef?.current?.setFieldValue("shelterRefugees", foreignCitizenData.entranceData?.visitedRefugeeShelter)
      formikRef?.current?.setFieldValue("noteForeigner", foreignCitizenData.note)
      formikRef?.current?.setFieldValue("spouseFirstName", foreignCitizenData.familyMember?.firstName)
      formikRef?.current?.setFieldValue("spouseLastName", foreignCitizenData.familyMember?.lastName)
      formikRef?.current?.setFieldValue("spouseBirthDate", foreignCitizenData.familyMember?.birthDate)
      formikRef?.current?.setFieldValue("companyName", foreignCitizenData.foreignOrganization?.name)
      formikRef?.current?.setFieldValue("companyPlace", foreignCitizenData.foreignOrganization?.placeDTO?.name)
      formikRef?.current?.setFieldValue("attemptIllegalDate", foreignCitizenData.measuresData?.illegalLeaveDate)
      if (foreignCitizenData.measuresData?.measureCreationDTOs[0].measureTypeMvrKey) {
        formikRef?.current?.setFieldValue("measureTakenType", foreignCitizenData.measuresData?.measureCreationDTOs[0].measureTypeMvrKey)
      }
      if (foreignCitizenData.measuresData?.measureCreationDTOs[0].measureReasonMvrKey) {
        formikRef?.current?.setFieldValue("measureTakenReason", foreignCitizenData.measuresData?.measureCreationDTOs[0].measureReasonMvrKey)
      }
      formikRef?.current?.setFieldValue("dateMeasuresTaken", foreignCitizenData.measuresData?.measureDate);
      formikRef?.current?.setFieldValue("passportNumber", foreignCitizenData.passport?.passportNumber);
      formikRef?.current?.setFieldValue("passportTypeMvrKey", foreignCitizenData.passport?.passportTypeMvrKey);
      formikRef?.current?.setFieldValue("spouseEmbg", foreignCitizenData.familyMember?.embg);
      formikRef?.current?.setFieldValue("firstNamePrivateVisitPerson", foreignCitizenData.host?.firstName);
      formikRef?.current?.setFieldValue("lastNamePrivateVisitPerson", foreignCitizenData.host?.lastName);
      formikRef?.current?.setFieldValue("genderPrivateVisitPerson", foreignCitizenData.host?.genderMvrKey);
      formikRef?.current?.setFieldValue("embgPrivateVisitPerson", foreignCitizenData.host?.embg)
      formikRef?.current?.setFieldValue("numberStreetPrivateVisitPerson", foreignCitizenData.host?.addressDTO?.streetNumber);
      formikRef?.current?.setFieldValue("numberStreetRelative", foreignCitizenData?.familyMember?.addressDTO?.streetNumber);

      formikRef?.current?.setFieldValue("startDateStay", ArrayUtils.first(foreignCitizenData?.restrictedStayData)?.startDateOfStay);
      formikRef?.current?.setFieldValue("endDateStay", ArrayUtils.first(foreignCitizenData?.restrictedStayData)?.finalDateOfStay);
      formikRef?.current?.setFieldValue("dateOfExtension", ArrayUtils.first(foreignCitizenData?.restrictedStayData)?.extendedDateOfStay);
      formikRef?.current?.setFieldValue("archiveDate", foreignCitizenData.archiveDate);
      formikRef?.current?.setFieldValue("fileNumber", foreignCitizenData.dossierNumber);
      formikRef?.current?.setFieldValue("decision", foreignCitizenData.resolution?.opinionPositive);
      formikRef?.current?.setFieldValue("decision", foreignCitizenData.resolution?.opinionPositive);
      formikRef?.current?.setFieldValue("dateOfDeliveryOfDecision", foreignCitizenData.resolution?.dateHanded);
      formikRef?.current?.setFieldValue("dateEvidented", foreignCitizenData.resolution?.evidencyDate);
      formikRef?.current?.setFieldValue("residenceBanStartDate", foreignCitizenData.resolution?.entranceRestrictionDateFrom);
      formikRef?.current?.setFieldValue("residenceBanEndDate", foreignCitizenData.resolution?.entranceRestrictionDateTo);
      formikRef?.current?.setFieldValue("checksPerformed", foreignCitizenData.asylumData?.anbAnswer?.mvrKey);
      formikRef?.current?.setFieldValue("archiveNumber", foreignCitizenData.archiveNumber);
      formikRef?.current?.setFieldValue("streetOrganization", foreignCitizenData.employmentData?.organizationCreationDTO?.addressDTO?.streetDTO?.mvrKey)
      formikRef?.current?.setFieldValue("numberStreetOrganization", foreignCitizenData.employmentData?.organizationCreationDTO?.addressDTO?.streetNumber)
      formikRef?.current?.setFieldValue("fileDecision", foreignCitizenData.resolution?.resolutionFile)
      if (foreignCitizenData?.personData?.firstNameCyrillic) {
        formikRef?.current?.setFieldValue("firstNameCyrillic", foreignCitizenData?.personData?.firstNameCyrillic)
      }
      formikRef?.current?.setFieldValue("lastNameCyrillic", foreignCitizenData.personData?.lastNameCyrillic)
      formikRef?.current?.setFieldValue('ovrMvrKey', foreignCitizenData?.measuresData?.ovrMvrKey);
      if(foreignCitizenData?.familyRelationMvrKey && foreignCitizenData?.familyRelationMvrKey !== "" &&
        (foreignCitizenData?.settlementReasonMvrKey === NomenclatureConstants.familyReunificationMvrKey || foreignCitizenData?.settlementReasonMvrKey === NomenclatureConstants.familyReunificationShortTermStayMvrKey)) {
        formikRef.current.setFieldValue("familyRelationMvrKey", foreignCitizenData?.familyRelationMvrKey)
      } else if (foreignCitizenData?.settlementReasonMvrKey !== NomenclatureConstants.familyReunificationMvrKey && foreignCitizenData?.settlementReasonMvrKey !== NomenclatureConstants.familyReunificationShortTermStayMvrKey) {
        formikRef?.current?.setFieldValue("familyRelationMvrKey", "")

      }
    }

  }, [registrationData, foreignCitizenData, showGenerateBtn])

  function moveToAsylum(data: RegistrationDTO | undefined) {
    let route = Routes.ASYLUM_SEEKERS
    navigate(route, { state: { data, flag: true } })
  }
  const isAnyFieldEntered = formikRef.current?.values?.firstName ||  formikRef.current?.values?.lastName ||  formikRef.current?.values?.archiveNumber;
  return (
    <div>
      {isLoadingData &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoadingData}
        >
          <CircularProgress size={60} style={{ color: "#3f51b5" }} />
        </Backdrop>
      }
      <SideBarPage pageTitle={strings.foreignRegistration} component={
        <div>
          {!isAddMode && <Box component="div" display={"flex"} justifyContent={"space-between"} className="mt-3 mb-4">
          <Box component="div">
              {registrationData?.submitted ? <Box component="span" fontWeight={"bold"} textTransform={"uppercase"}>
                 Поднесена (официјална) верзија на регистрацијата
              </Box> : <LightTooltip customWidth='1000px' title={<div>
                Ве молиме преминете на поднесената (официјална) верзија, доколку сакате да:
                <ul>
                  <li>преземете акции поврзани со матичните броеви</li>
                  <li>пасивизирате/активирате барање</li>
                </ul>
              </div>}>
              <Box component="span" fontWeight={"bold"} textTransform={"uppercase"}>
                 Работна верзија на регистрацијата
              </Box>
              </LightTooltip>}
            </Box>
            <div style={{ float: 'right' }}>
              {hasAuthority && registrationData?.submitted && (!registrationData?.passivated ? (
                <Button style={{ marginRight: '10px' }} variant='contained' onClick={handleOpenRequest}>{strings.passivize}</Button>
              ) :
                (
                  <Button style={{ marginRight: '10px' }} variant='contained' onClick={handleOpenActivateRequest}>{strings.activateRequest}</Button>
                )
              )}
              {registrationData?.submitted && copyToAsylum && registrationData?.registrationType?.mvrKey === NomenclatureConstants.foreignCitizenMvrKey &&
                <Button variant='contained' onClick={() => moveToAsylum(registrationData ? registrationData : undefined)}>{strings.moveForeignerToAsylum}</Button>
              }
            </div>
          </Box>}
          {!isAddMode &&
            <Box component="div" display="flex" justifyContent="start" className='pb-4'>
          <FormControl sx={{ minWidth: 300 }}>
              <InputLabel id="typeOfForms">{strings.version}</InputLabel>
              <Select
                value={versions && versions.length > 0 && version && versions.some(el => el.id.toString() == version) ? String(version) : ""}
                onChange={handleChange}
                labelId="versionOfForms"
                label={strings.version}
                style={{ textAlign: "left" }}
                defaultValue=""
                MenuProps={{
                  classes: {
                    paper: classes.dropdown,
                  },
                }}
              >
                {versions?.map((item) => (
                  <MenuItem value={item?.id} key={item?.id}>
                    {DateTimeUtils.formatDate(item?.dateCreated.toString()) + " - "}
                    {item?.archiveNumber ? item?.archiveNumber + " - " : ""}
                    {item?.evidencyReason?.displayName}
                  </MenuItem>
                ))}
              </Select>

            </FormControl>
{/* 
            <div style={{ float: 'right' }}>
              {hasAuthority && <Button style={{marginRight: "15px"}} disabled={registrationData?.isDead} variant='contained' className='mb-4' onClick={handleOpenDeceasedModal}>{strings.markDeceased}</Button>}
              {hasAuthority && (!registrationData?.passivated ? (
                <Button style={{ marginRight: '10px' }} variant='contained' className='mb-4' onClick={handleOpenRequest}>{strings.passivize}</Button>
              ) :
                (
                  <Button style={{ marginRight: '10px' }} variant='contained' className='mb-4' onClick={handleOpenActivateRequest}>{strings.activateRequest}</Button>
                )
              )}
              {registrationData?.submitted && copyToAsylum && registrationData?.registrationType?.mvrKey === NomenclatureConstants.foreignCitizenMvrKey &&
                <Button variant='contained' className='mb-4' onClick={() => moveToAsylum(registrationData ? registrationData : undefined)}>{strings.moveForeignerToAsylum}</Button>
              }
            </div> */}
          </Box>}

          {!isAddMode && registrationData && (
            <Accordion className='mb-3' style={{ width: "41%" }} defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"

              >
                <Typography>Статус на матичен број</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 500 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{strings.firstName}</TableCell>
                        <TableCell>{strings.lastName}</TableCell>
                        <TableCell>{strings.embg}</TableCell>
                        <TableCell>{strings.statusEmbg}</TableCell>
                        <TableCell>{strings.deceased}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{
                          registrationData?.foreignCitizen?.personData?.firstName
                        }</TableCell>
                        <TableCell>{
                          registrationData?.foreignCitizen?.personData?.lastName
                        }</TableCell>
                        <TableCell>{registrationData?.embg}</TableCell>
                        <TableCell>{registrationData?.registrationStatus?.name ?? strings.noInformation}</TableCell>
                        <TableCell>{registrationData?.deadInfo !== null ? registrationData.deadInfo : strings.noInformation}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          )}

          {!isAddMode && (
            <CreatedByInfo
              label={strings.statusRegistration + ":"}
              status={registrationData?.passivated ? strings.passive : strings.activeStatus}
              statusSuccessful={!registrationData?.passivated}
            />
          )}

          {!isAddMode &&
            <div className='d-flex pb-4'>
              {strings.workingUnit + ":" + " " + userDetails?.workingUnit}
            </div>
          }
          <div style={{ display: "flex" }}>
            {
              !isAddMode &&
              (
                <div>
                  <Button className="m-2" style={{ float: "left" }} type="submit" variant="contained" color="primary" onClick={handleClickOpen}>{strings.historyOfRegistrationStatuses}</Button>
                  <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={"md"} fullWidth
                  >
                    <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
                      {strings.historyOfRegistrationStatuses}
                    </DialogTitle>
                    <DialogContent>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell> {strings.action}</TableCell>
                              <TableCell> {strings.reason}</TableCell>
                              <TableCell> {strings.dateModified}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              historyActions?.registrationOrEmbgHistoryActions?.map((item: any, index: number) => {
                                return (
                                  <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell>{item.action}</TableCell>
                                    <TableCell>{item.reason}</TableCell>
                                    <TableCell>{DateTimeUtils.formatDateObject(item.dateModified)}</TableCell>
                                  </TableRow>
                                )
                              })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </DialogContent>
                    <DialogActions>
                      <Button className='m-2' type="submit" variant="contained" color="error" onClick={handleCloseDialog}>{strings.close}</Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )
            }
            {!isAddMode &&
              <div>
                <Button className='m-2' type="submit" variant="contained" color="primary" onClick={handleClickOpen2}>{strings.historyOfEmbgs}</Button>
                <Dialog
                  open={openDialog2}
                  onClose={handleCloseDialog2}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth={"md"} fullWidth
                >
                  <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
                    {strings.historyOfEmbgs}
                  </DialogTitle>
                  <DialogContent>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 500 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell> {strings.firstName}</TableCell>
                            <TableCell> {strings.embg}</TableCell>
                            <TableCell> {strings.action}</TableCell>
                            <TableCell> {strings.reason}</TableCell>
                            <TableCell> {strings.dateModified}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            historyActionsEMBG?.mainRegistration.registrationOrEmbgHistoryActions?.map((item: any, index: number) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell>{historyActionsEMBG?.mainRegistration?.firstName + " " + historyActionsEMBG.mainRegistration.lastName}</TableCell>
                                  <TableCell>{item.embg}</TableCell>
                                  <TableCell>{item.action}</TableCell>
                                  <TableCell>{item.reason}</TableCell>
                                  <TableCell>{DateTimeUtils.formatDateObject(item.dateModified)}</TableCell>
                                </TableRow>
                              )
                            })
                          }
                          {
                            historyActionsEMBG?.childRegistrations?.map((item: any, index: number) => {
                              return (
                                item?.registrationOrEmbgHistoryActions?.map((elem: any) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                      <TableCell>{item.firstName + " " + item.lastName}</TableCell>
                                      <TableCell>{elem.embg}</TableCell>
                                      <TableCell>{elem.action}</TableCell>
                                      <TableCell>{elem.reason}</TableCell>
                                      <TableCell>{DateTimeUtils.formatDate(elem.dateModified)}</TableCell>
                                    </TableRow>
                                  )

                                })
                              )
                            })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </DialogContent>
                  <DialogActions>
                    <Button className='m-2' type="submit" variant="contained" color="error" onClick={handleCloseDialog2}>{strings.close}</Button>
                  </DialogActions>
                </Dialog>
              </div>
            }

            {!isAddMode && hasAuthority && registrationData?.submitted && registrationData?.embg && (!(registrationData.registrationStatus?.key === "active")) &&
              <div style={{ clear: "both" }}>
                <Button disabled={registrationData?.registrationStatus?.key === RegistrationStatuses.ACTIVE || registrationData?.registrationStatus?.key === RegistrationStatuses.PASSIVE} style={{ float: "left" }} variant='contained' className='m-2' onClick={handleOpenActivateEMBGModal}>{strings.activateEmbg}</Button>
              </div>
            }


            {/*{!isAddMode && id && showGenerateBtn && <div style={{ clear: "both" }}><Button style={{float:"left"}}  variant='contained' className='m-2' onClick={generateEMBG}>{strings.generateEMBG}</Button></div>}*/}
            {
              !isAddMode && id && !showGenerateBtn && hasAuthority && registrationData?.submitted &&
              <div style={{ float: "left" }}>
                <Button disabled={registrationData?.registrationStatus?.key !== RegistrationStatuses.ACTIVE} variant='contained' className='m-2' onClick={handleOpen}>{strings.passivateEMBGForeign}</Button>
                <Button disabled={registrationData?.registrationStatus?.key !== RegistrationStatuses.ACTIVE} variant='contained' className='m-2' onClick={handleOpenConfirmationModal}>{strings.onHoldEMBG}</Button>
              </div>
            }
          </div>

          {!isAddMode && id &&
            <div className="sticky top-0 z-99">
              <RightSidePanel foreignCitizenForm={true} templateDTOs={foreignUsersTemplates} registrationFiles={registrationFiles} id={parseInt(id)} />
            </div>
          }
          {isLoadingSave && (
            <CircularProgress
              style={{ float: "right" }} className={"mb-2"}
              size={32}
            />
          )
          }
          <AutoScroller style={{float: "right", marginBottom: "20px"}} direction={false} />

          {!registrationData?.submitted && !state?.flag && <Button style={{ float: "right", marginRight: "5px" }} className='mb-2' disabled={isLoadingSave || !!disabledSaveButton} type="submit" variant="contained" color="primary" onClick={createOrUpdateWorkingVersion}>{strings.save}</Button>}
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"}>
            <div style={{fontSize:"12px", color:"red"}}>Формата содржи задолжителни полиња на 1-та, 2-та и 3-та страница означени со ѕвезда (*)</div>
            <React.Fragment>
              <Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
                {steps.map((step, index) => (
                  <Step key={step.name} completed={step.completed}>
              <StepButton
                onClick={()=>handleStepValidation(index)}
              >
               <div style={{ fontWeight: activeStep === index ? 'bold' : 'normal' }}>{step.name}</div>
              </StepButton>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {isLoading ? (
                  <div className="mt-3" style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress
                      size={40}
                    /></div>
                )
                  :
                  activeStep === steps.length ? (
                    <div className='p-3'>

                    </div>
                  )
                    : (
                      <Formik
                        initialValues={lastSubmittedValues || FormInitialValues || {}}
                        validationSchema={currentValidationSchema}
                        onSubmit={handleSubmitForm}
                        innerRef={formikRef}
                      >

                        {({ isSubmitting, isValid }) => {
                          return (
                            <Form id={formId}>
                              {renderStepContent(activeStep)}

                              <div className={classes.buttons}>
                                {activeStep !== 0 && (
                                  <Button onClick={handleBack} className={classes.button}>
                                    {strings.prev}
                                  </Button>
                                )}
                                <div className={classes.wrapper}>
                                  {isLastStep && !hasAuthority ? <Box /> : <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                  >
                                    {isLastStep ? strings.end : strings.next}
                                  </Button>}
                                  {(isSubmitting || isLoading) && (
                                    <CircularProgress
                                      size={24}
                                      className={classes.buttonProgress}
                                    />
                                  )}
                                </div>
                              </div>
                            </Form>
                          )
                        }}
                      </Formik>
                    )}
              </React.Fragment>
            </React.Fragment>
          </CenteredCardLayout>
          <EMBGModal registrationData={registrationData} open={open} handleClose={handleClose} isAsylum={false} isPasivise={true} isForeign={true} />
          <EMBGModal registrationData={registrationData} open={openConfirmation} handleClose={handleCloseConfirmationModal} isAsylum={false} isOnHold={true} isForeign={true} />
          <EMBGModal registrationData={registrationData} open={openActivateModal} handleClose={handleCloseActivateEMBGModal} isAsylum={false} isForeign={true} isActivate={true} />
          <EMBGModal registrationData={registrationData} open={openRequest} handleClose={handleCloseRequest} isAsylum={false} isForeign={true} isPassivateRequest={true} />
          <ConfirmationModal
              open={openActivateRequest}
              handleClose={() => handleCloseActivateRequest(false)}
              handleConfirm={activateRequest}
              title={strings.activateRequest}
              content={strings.activateRequestConfirm}
              actionButtonName={strings.activate}
              variant={"contained"}
              color={"success"}
        />
        <ConfirmationModal
            open={openDeceasedModal}
            handleClose={handleCloseDeceasedModal}
            handleConfirm={markAsDeceased}
            title={strings.deceasedForm}
            content={strings.deceasedText}
            actionButtonName={strings.mark}
            variant={"contained"}
            color={"success"}
        />
        <ConfirmationModal
          open={openCheckModal}
          handleClose={handleCloseCheckModal}
          handleConfirm={() => {
            setOpenCheckModal(false);
            updateForeignCitizenData(values);
          }}
          title={strings.saveChanges}
          content={isPasivise? strings.checkBirthDateAndGender : strings.notPassivatedcheckBirthDateAndGender}
          actionButtonName={strings.submit}
          variant={"contained"}
          color={"success"}
          isPassive = {isPasivise}
        />
        <ConfirmationModal
            open={openBasicDataChangesModal}
            handleClose={handleCloseBasicDataChangesModal}
            title={strings.saveChanges}
            content={
              <div>
                {strings.correctionChangeMessage}
                <div className="d-flex justify-content-between mt-3">
                  <Button onClick={() => {handleClickChange()}}>{strings.change}</Button>
                  <Button onClick={() => {handleClickCorrection()}}>{strings.correction}</Button>
                </div>
              </div>
            }
            variant={"contained"}
            color={"success"}
        />
          <AutoScroller style={{float: "right", marginTop: "20px"}} direction={true}/>

        </div>

      }

    />

    </div>
  );

}


