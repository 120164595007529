import { PersonDataCreationDTO } from "./PersonDataCreationDTO";

export class RelativeDTO {
    id:number;
    relativeTypeMvrKey: string;
    personData?: PersonDataCreationDTO;
    constructor(json:RelativeDTO){
        this.id = json.id
        this.relativeTypeMvrKey = json.relativeTypeMvrKey;
        this.personData = json.personData ? new PersonDataCreationDTO(json.personData) : undefined;
    }
}