import {
    Box,
    Button,
    FormControl, Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LightTooltip } from "../../components/LightTooltip";
import { SideBarPage } from "../../components/SideBarPage";
import { PageTable } from "../../components/table/PageTable";
import { strings } from "../../localization/Localization";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import { Routes } from "../../router/Routes";
import { ForeignCitizensService } from "../../services/ForeignCitizensService";
import { NumberParser } from "../../utils/NumberParser";
import { BooleanParser } from "../../utils/BooleanParser";
import { RegistrationTypeService } from "../../services/RegistrationTypeService";
import { RegistrationTypeDTO } from "../../models/registration/RegistrationTypeDTO";
import IconButton from '@mui/material/IconButton';
import { Close } from "@mui/icons-material";
import { NomenclatureService } from "../../services/NomenclatureService";
import {NomenclatureConstants} from "../../constants/NomenclatureConstants";
import {NomenclatureEntryDTOWithKey} from "../../models/nomenclatures/NomenclatureEntryDTOWithKey";
import {RegistrationTypes} from "../../constants/RegistrationTypes";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {Dayjs} from "dayjs";
import { Privileges } from "../../models/nomenclatures/Privileges";
import { StorageService } from "../../storage/StorageService";
import { AuthService } from "../../services/AuthService";
import ConfirmationModal from "../../components/ConfirmationModal";
import { WorkingVersionDTO } from "../../models/foreign_citizens/WorkingVersionDTO";
import {NomenclatureEntryDTO} from "../../models/nomenclatures/NomenclatureEntryDTO";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {FullPageLoadingCircle} from "../../components/FullPageLoadingCircle";
import {RegistrationReducedWithDecisionDTO} from "../../models/registration/RegistrationReducedWithDecisionDTO";
import {StringUtils} from "../../utils/StringUtils";

export function ForeignersSubmittedFormDecisions() {
    const submitted = true;
    const title = strings.registrationDecisionsRegister;

    const [searchParams, setSearchParams] = useSearchParams();
    const page = NumberParser.parseNumber(searchParams.get("page"), 0);
    const rowsPerPage = NumberParser.parseNumber(searchParams.get("size"), 5);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [registrationTypes, setRegistrationTypes] = useState<RegistrationTypeDTO[]>([]);
    const [submittedForeignCitizensForms, setSubmittedForeignCitizensForms] = useState<RegistrationReducedWithDecisionDTO[]>([]);
    const [registrationTypeKey, setRegistrationTypeKey] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [parentName, setParentname] = useState<string>("")
    const [motherName, setMotherName] = useState<string>("")
    const [citizenship, setCitizenship] = useState<NomenclatureEntryDTO | undefined>(undefined)
    // const [citizenships, setCitizenships] = useState<NomenclatureEntryDTO[]>([])
    const [registrationDateFrom, setRegistrationDateFrom] = useState<string | undefined | null>(null)
    const [registrationDateTo, setRegistrationDateTo] = useState<string | undefined | null>(null)
    const [lastName, setLastName] = useState<string>("");
    const active = BooleanParser.parseBooleanNullable(searchParams.get("active"));
    const email = searchParams.get("email") ?? "";
    const [embg, setEmbg] = useState<string>("");
    const [holder, setHolder] = useState<boolean | null | undefined>(undefined);
    const [activeSubmittedForm, setActiveSubmittedForm] = useState<boolean | null | undefined>(undefined);
    const [birthDate, setBirthDate] = useState<string | undefined | null>(null);
    const [evidencyReasonMvrKey, setEvidencyReasonMvrKey] = useState<string>("");
    // const evidencyReasonMvrKey = searchParams.get("evidencyReasonMvrKey") ?? "";
    const [decisionTypeMvrKey, setDecisionTypeMvrKey] = useState<string>("");
    const [archiveNumber, setArchiveNumber] = useState<string>("");
    // const settlementReasonMvrKey = searchParams.get("settlementReasonMvrKey") ?? "";
    const [openForeign, setOpenForeign] = useState<boolean>(false);
    const [id, setId] = useState<number>(0);

    const [decisionDate, setDecisionDate] = useState<string | undefined | null>(null);

    const [type, setType] = useState<string>(() => {
        const registrationTypeValue = searchParams.get("registrationTypeKey");
        if (registrationTypeValue) {
            const registrationType = registrationTypes.find(
                (registrationType) => registrationType.mvrKey === registrationTypeValue
            );
            if (registrationType) {
                return registrationType.displayName;
            }
        }
        return "";
    });
    const [keyEvidency] = useState<string>(NomenclatureConstants.basis);
    const [keyDecisionTypes] = useState<string>(NomenclatureConstants.decisionTypes);
    const [evidencyReasons, setEvidencyReasons] = useState<NomenclatureEntryDTOWithKey[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [decisionTypes, setDecisionTypes] = useState<NomenclatureEntryDTOWithKey[]>([])
    const [manageAsylum, setManageAsylum] = useState<boolean>();
    const [manageForeignCitizen, setManageForeignCitizen] = useState<boolean>();
    const [manageShortTermStay, setManageShortTermStay] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [headerHidden,setHeaderHidden] = useState<boolean>(true);

    function setSearchParam(key: string, value: string | null | undefined) {
        if (value) {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }

        setSearchParams(searchParams)
    }

    function setPage(page: number) {
        setSearchParam("page", page.toString());
    }

    function setRowsPerPage(rowsPerPage: number) {
        setSearchParam("size", rowsPerPage.toString());
    }

    const handleRegistrationTypeChange = (event: SelectChangeEvent) => {
        const registrationTypeValue = event.target.value;

        if (registrationTypeValue) {
            const registrationType = registrationTypes.find((registrationType) => registrationType.displayName === registrationTypeValue);
            if (registrationType) {
                setRegistrationTypeKey(registrationType.mvrKey);
                setType(registrationType.displayName);
            }
        } else {
            setRegistrationTypeKey("");
        }

        setPage(0);
    };

    const handleDecisionTypeMvrKeyChange = (event: SelectChangeEvent) => {
        setDecisionTypeMvrKey(event.target.value)
        setPage(0);
    };

    function handleParentNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setParentname(event.target.value);
        setPage(0);
    }
    function handleMotherNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setMotherName(event.target.value);
        setPage(0);
    }

    function handleCitizenshipChange(value: NomenclatureEntryDTO | undefined): void {
        setCitizenship(value);
        setPage(0);
    }

    function handleRegFromChange(date : Dayjs) {
        setRegistrationDateFrom(DateTimeUtils.formatDateDayJs(date)?.toString() ?? null);
        setPage(0);
    }

    function handleRegToChange(date : Dayjs) {
        setRegistrationDateTo(DateTimeUtils.formatDateDayJs(date)?.toString() ?? null);
        setPage(0);
    }

    function handleDecisionDateChange(date: Dayjs) {
        setDecisionDate(DateTimeUtils.formatDateDayJs(date)?.toString() ?? null);
        setPage(0);
    }

    const handleEvidencyReasonChange = (event: SelectChangeEvent) => {
        setEvidencyReasonMvrKey(event.target.value)
        setPage(0);
    };


    function handleRemoveRegistrationTypeFIlter() {
        setRegistrationTypeKey("")
        setType("")
        setPage(0);
    }

    function handleRemoveEvidencyReasonFilter() {
        setEvidencyReasonMvrKey("");
        setPage(0);
    }
    function handleRemoveBirthDateFilter() {
        setBirthDate(null)
        setPage(0);
    }

    function handleRemoveDecisionTypeFilter() {
        setDecisionTypeMvrKey("")
        setPage(0);
    }


    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleEmbgChange(event: React.ChangeEvent<HTMLInputElement>) {
        const inputEMBG = event.target.value;
        const numericRestriction = /^\d*$/;

        if(numericRestriction.test(inputEMBG) && inputEMBG.length <= 13){
            setEmbg(inputEMBG)
        }
        else {
            return
        }
        setPage(0);
    }

    function handleFirstNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setFirstName(event.target.value);
        setPage(0);
    }
    function handleArchiveNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
        setArchiveNumber(event.target.value);
        setPage(0);
    }

    function handleLastNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setLastName(event.target.value);
        setPage(0);
    }

    function handleHolder(holder: boolean | undefined): void {
        setHolder(holder);
        setPage(0);
    }


    function handleActiveSubmittedForm(activeSubmittedForm: boolean | undefined): void {
        setActiveSubmittedForm(activeSubmittedForm);
        setPage(0);
    }

    function handleBirthDateChange(date? : Dayjs) {
        setBirthDate(DateTimeUtils.formatDateDayJs(date)?.toString() ?? null);
        setPage(0);
    }
    function handleCloseForeign(){
        setOpenForeign(false)
    }
    function checkDateModified(workingVersion:WorkingVersionDTO | undefined){
        if(workingVersion?.newerThanMainVersion){
            return true
        }else {
            return false
        }
    }
    function navigateForeingUserDetails(id: number, workingVersionId: number): any {
        setId(id)
        const registrations = submittedForeignCitizensForms.map((item) => item.registration);

        let workingVersion = registrations.find((item)=>item.id === id)?.workingVersionRegistrationId;
        let registrationType = registrations.find((item)=>item.id === id)?.registrationType.mvrKey;
        let isChild = registrations.find((item)=>item.id === id)
        if(id === null && workingVersionId !== null ){
            workingVersion = registrations.find((item)=>item.workingVersionRegistrationId?.id === workingVersionId)?.workingVersionRegistrationId;
            registrationType = registrations.find((item)=>item.workingVersionRegistrationId?.id === workingVersionId)?.registrationType.mvrKey;
            isChild = registrations.find((item)=>item.workingVersionRegistrationId?.id === workingVersionId)
        }

        if (registrationType === RegistrationTypes.foreignCitizen) {
            let route = Routes.EDIT_APPLICATION;
            if(!submitted){
                if(workingVersion){
                    route = route.replace(":id", workingVersion?.id!.toString());
                }
            }else{
                route = route.replace(":id", id.toString());
            }
            if(!checkDateModified(workingVersion)){
                navigate(route);
            }else{
                setOpenForeign(true)
            }
        } else if(registrationType=== RegistrationTypes.asylum) {
            if (isChild?.parentRegistrationReducedDTO !== undefined){
                let route = Routes.ASYLUM_SEEKERS_EDIT;
                if(!submitted){
                    if(workingVersion){
                        route = route.replace(":id", isChild?.parentRegistrationReducedDTO?.workingVersionRegistrationId!.id!.toString());
                    }
                }else{
                    route = route.replace(":id", isChild.parentRegistrationReducedDTO.id.toString());
                }
                if(!checkDateModified(workingVersion)){
                    navigate(route);
                }else{
                    setOpenForeign(true)
                }
            }
            else {
                let route = Routes.ASYLUM_SEEKERS_EDIT;
                if(!submitted){
                    if(workingVersion){
                        route = route.replace(":id", workingVersion?.id!.toString());
                    }
                }else{
                    route = route.replace(":id", id.toString());
                }
                if(!checkDateModified(workingVersion)){
                    navigate(route);
                }else{
                    setOpenForeign(true)
                }
            }
        } else{
            let route = Routes.EDIT_SHORT_TERM_STAY_APPLICATION;
            if(!submitted){
                if(workingVersion){
                    route = route.replace(":id", workingVersion?.id!.toString());
                }
            }else{
                route = route.replace(":id", id.toString());
            }
            if(!checkDateModified(workingVersion)){
                navigateWithNavigatedFrom(route);
            }else{
                setOpenForeign(true)
            }

        }
    }
    const commonWidth = "15%";

    const headerRows = [
        // {
        //   id: "ordinalNumber",
        //   label: strings.ordinalNumber,
        //   width: "5%"
        // },
        {
            id: "еmbg",
            label: strings.embg,
            width: commonWidth
        },
        {
            id: "firstName",
            label: strings.firstName,
            width: commonWidth
        },
        // {
        //   id: "parentName",
        //   label: strings.fathersName,
        //   width: "10%"
        // },
        {
            id: "lastName",
            label: strings.lastName,
            width: commonWidth
        },
        {
            id: "archiveNumber",
            label: strings.archiveNumber,
            width: commonWidth
        },

        {
            id: "birthDate",
            label: strings.birthDate,
            width: commonWidth
        },
        {
            id: "holder",
            label: strings.claimHolder,
            width: commonWidth
        },
        {
            id: "activeSubmittedForm",
            label: strings.activeSubmittedForm,
            width: commonWidth
        },

        {
            id: "nameOfTheCarrier",
            label: strings.carrierName,
            width: commonWidth
        },
        {
            id: "evidencyReasonMvrKey",
            label: strings.evidencyReason,
            width: commonWidth
        },
        {
            id: "decisionDate",
            label: strings.decisionDateLong,
            width: commonWidth
        },
        {
            id: "decisionTypeMvrKey",
            label: strings.decisionTypeMvrKey,
            width: commonWidth
        },
        {
            id:"empty1",
            width:commonWidth
        },
        {
            id:"empty2",
            width:commonWidth
        }

    ]

    const bottomRows = [
        {id: "empty"},
        {id: "empty1"},{id: "empty2"},{id: "empty3"},{id: "empty4"},{id: "empty5"},{id: "empty6"},{id: "empty7"}, {id: "empty8"},
        isLoading ?
            {id: "loader", value: isLoading , type: "loader", search: true} :
            {
                id: "searchFilter",
                value: strings.search,
                type: "button",
                variant: "contained",
                search: true,
                onClick: () => {findAllSubmittedForeignCitizensForms(page);setHeaderHidden(false)},
                width: "15%",
                disabled: false,
            }
    ]

    const firstSearchRow = [
        {
            id: "embg",
            label: strings.embg,
            value: embg,
            search: true,
            type: "text",
            variant: "standard",
            handleChange: handleEmbgChange,
            width: commonWidth
        },
        {
            id: "firstName",
            label: strings.firstName,
            search: true,
            type: "text",
            value: firstName,
            variant: "standard",
            handleChange: handleFirstNameChange,
            width: commonWidth
        },

        {
            id: "lastName",
            label: strings.lastName,
            search: true,
            type: "text",
            value: lastName,
            variant: "standard",
            handleChange: handleLastNameChange,
            width: commonWidth
        },
        {
            id: "archiveNumber",
            label: strings.archiveNumber,
            search: true,
            type: "text",
            value: archiveNumber,
            variant: "standard",
            handleChange: handleArchiveNumberChange,
            width: commonWidth
        },
        {
            id: "birthDate",
            label: strings.birthDate,
            search: true,
            type: "datePicker",
            variant: "standard",
            value: birthDate,
            handleChange: handleBirthDateChange,
            handleRemoveFilter: handleRemoveBirthDateFilter,
            width: commonWidth
        },
        isLoading ?
            {id: "loader", value: isLoading , type: "loader", search: true} :
            {
                id: "searchFilter",
                value: strings.search,
                type: "button",
                variant: "contained",
                search: true,
                onClick: () => {findAllSubmittedForeignCitizensForms(page);setHeaderHidden(false)},
                width: commonWidth,
                disabled: false,
            }


    ]
    const secondSearchRow = [
        {
            id: "holder",
            type: "nullableCheckbox",
            search: true,
            label: strings.claimHolder,
            width: commonWidth,
            value: holder,
            handleChange: handleHolder
        },
        {
            id: "activeSubmittedForm",
            type: "nullableCheckbox",
            search: true,
            label: strings.activeSubmittedForm,
            width: commonWidth,
            value: activeSubmittedForm,
            handleChange: handleActiveSubmittedForm
        },

        // {
        //   id: "nameOfTheCarrier",
        //   width: "5%"
        // },
        // {
        //   id: "nameOfTheRequester",
        //   width: "5%"
        // },
        {
            id: "evidenceReasonMvrKey",
            value: evidencyReasonMvrKey,
            type: "dropdown",
            elements: evidencyReasons,
            variant: "standard",
            search: true,
            label: strings.evidencyReason,
            handleChange: handleEvidencyReasonChange,
            handleRemoveFilter: handleRemoveEvidencyReasonFilter,
            width: commonWidth
        },
        {
            id: "decisionTypeMvrKey",
            value: decisionTypeMvrKey,
            type: "dropdown",
            elements: decisionTypes,
            variant: "standard",
            search: true,
            label: strings.decisionTypeMvrKey,
            handleChange: handleDecisionTypeMvrKeyChange,
            handleRemoveFilter: handleRemoveDecisionTypeFilter,
            width: commonWidth
        },
        {
            id: "parentName",
            label: strings.parentName,
            search: true,
            type: "text",
            value: parentName,
            variant: "standard",
            handleChange: handleParentNameChange,
            width: commonWidth
        },
        {
            id: "motherName",
            label: strings.motherName,
            search: true,
            type: "text",
            value: motherName,
            variant: "standard",
            handleChange: handleMotherNameChange,
            width: commonWidth
        },

    ]

    const thirdSearchRow = [
        {
            id: "citizenshipMvrKey",
            label: strings.citizenship,
            search: true,
            // elements: citizenships,
            type: "paginatedSelect",
            value: citizenship,
            variant: "standard",
            valueMapper: (item: NomenclatureEntryDTO) => item?.key && item?.key?.toString(),
            keyMapper: (item: NomenclatureEntryDTO) => item.id.toString(),
            itemMapper: (item: NomenclatureEntryDTO) => <>{item.name}</>,
            labelMapper: (item: NomenclatureEntryDTO) => item.name,
            dataFetcher: (page: number, size: number, filter: string) => {
                return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                    page, size, NomenclatureConstants.citizenship, filter ? filter : "", "", "", undefined, "", true
                );
            },
            onChange: handleCitizenshipChange,
            name: strings.citizenship,
            width: commonWidth,
            placeholder: strings.citizenship,
            filterLabel: strings.citizenship,
        },
        {
            id: "registrationDateFrom",
            label: strings.registrationDateFrom,
            search: true,
            type: "datePicker",
            value: registrationDateFrom,
            variant: "standard",
            handleChange: handleRegFromChange,
            width: "20%"
        },
        {
            id: "registrationDateTo",
            label: strings.registrationDateTo,
            search: true,
            type: "datePicker",
            value: registrationDateTo,
            variant: "standard",
            handleChange: handleRegToChange,
            width: "20%"
        },
        {
            id: "decisionDate",
            label: strings.decisionDateLong,
            search: true,
            type: "datePicker",
            value: decisionDate,
            variant: "standard",
            handleChange: handleDecisionDateChange,
            width: "20%",
        }
    ]

    const rows = submittedForeignCitizensForms.map((submittedForm) => {
        return {
            id: submittedForm.registration?.id  === null ? null : submittedForm.registration?.id ,
            workingVersionId: submittedForm.registration.workingVersionRegistrationId?.id,
            key: submittedForm.registration.id + "-" + submittedForm.decision.id,
            values: [
                {
                    type: 'text',
                    name: "embg",
                    value: <LightTooltip className={"tableTooltip"} title={submittedForm.registration?.embg}><span>{submittedForm.registration?.embg}</span></LightTooltip>,
                    width: "10%"
                },
                {
                    type: 'text',
                    name: "firstName",
                    value: <LightTooltip className={"tableTooltip"} title={submittedForm.registration?.foreignCitizen.personData?.firstName}><span>{submittedForm.registration?.foreignCitizen.personData?.firstName}</span></LightTooltip>,
                    width: "10%"
                },
                {
                    type: 'text',
                    name: "lastName",
                    value: <LightTooltip className={"tableTooltip"} title={submittedForm.registration?.foreignCitizen.personData?.lastName}><span>{submittedForm.registration?.foreignCitizen.personData?.lastName}</span></LightTooltip>,
                    width: "10%"
                },
                {
                    type: 'text',
                    name: "archiveNumber",
                    value: <LightTooltip className={"tableTooltip"} title={submittedForm.registration?.foreignCitizen.archiveNumber}><span>{submittedForm.registration?.foreignCitizen.archiveNumber}</span></LightTooltip>,
                    width: "10%"
                },
                {
                    type: 'text',
                    name: "birthDate",
                    value: DateTimeUtils.formatDate(submittedForm.registration?.foreignCitizen?.personData?.birthDate?.toString()),
                    width: "17%"
                },
                {
                    type: 'boolean',
                    name: "holder",
                    value: submittedForm.registration?.holder,
                    width: "5%"
                },
                {
                    type: 'boolean',
                    name: "activeSubmittedForm",
                    value: !submittedForm.registration?.isRegistrationPassivated,
                    color: submittedForm.registration?.isRegistrationPassivated ? "error" : "success",
                    width: "5%"
                },

                {
                    type: 'text',
                    name: "nameOfTheCarrier",
                    value: <LightTooltip className={"tableTooltip"} title={submittedForm.registration?.parentRegistrationReducedDTO?.foreignCitizen?.personData?.firstName ?? ''}><span>{
                        submittedForm.registration?.parentRegistrationReducedDTO !== undefined ? (submittedForm.registration?.parentRegistrationReducedDTO?.foreignCitizen?.personData?.firstName ?? '') + " " + (submittedForm.registration?.parentRegistrationReducedDTO?.foreignCitizen?.personData?.lastName ?? '')  : (submittedForm.registration?.foreignCitizen?.personData?.firstName ?? '') + " " + (submittedForm.registration?.foreignCitizen?.personData?.lastName ?? '')
                    }</span></LightTooltip>,
                    width: "10%"
                },
                {
                    type: 'text',
                    name: "evidencyReason",
                    value: <LightTooltip className={"tableTooltip"} title={submittedForm.registration?.foreignCitizen.evidencyReason?.displayName}>
            <span>{submittedForm.registration?.foreignCitizen.evidencyReason?.displayName?.length > 55 ? submittedForm.registration?.foreignCitizen.evidencyReason?.displayName.substring(0, 55) + "..." :
                submittedForm.registration?.foreignCitizen.evidencyReason?.displayName}</span></LightTooltip>,
                    width: "15%"
                },
                {
                    type: 'text',
                    name: "decisionDate",
                    value: <LightTooltip className={"tableTooltip"} title={DateTimeUtils.formatDateWithoutTime(submittedForm.decision.decisionDate)}>
            <span>{DateTimeUtils.formatDateWithoutTime(submittedForm.decision.decisionDate)}</span></LightTooltip>,
                    width: "15%"
                },
                {
                    type: 'text',
                    name: "decisionTypeMvrKey",
                    value: <LightTooltip className={"tableTooltip"} title={submittedForm.decision.decisionType?.displayName ?? ''}><span>{
                        StringUtils.truncateIfLargerThan(submittedForm.decision.decisionType?.displayName ?? '', 55)
                    }</span></LightTooltip>,
                    width: "10%"
                },
                {
                    type: 'text',
                    name: "empty1",
                    value: "",
                    width: "15%"
                },
                {
                    type: 'text',
                    name: "empty2",
                    value: "",
                    width: "15%"
                }


            ]
        }

    })

    function addForeigner() {
        navigate(Routes.APPLICATION);
    }

    function addAsylum() {
        navigate(Routes.ASYLUM_SEEKERS)
    }

    function navigateWithNavigatedFrom(pathname: string) {
        navigate({
            pathname: pathname,
            search: `?navigatedFrom=${location.pathname}`
        });
    }

    function addShortTermApplication() {
        navigateWithNavigatedFrom(Routes.ADD_SHORT_TERM_STAY_APPLICATION);
    }

    useEffect(() => {
        async function getAllRegistrationTypes() {
            await RegistrationTypeService.getAllRegistrationTypes().then((response) => {
                setRegistrationTypes(response.filter(rt=>rt.mvrKey !== RegistrationTypes.diplomat));
            })
        }

        async function getAllEntriesForNomenclatureEvidency(): Promise<void> {
            const evidencyReasons = await NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(keyEvidency);
            setEvidencyReasons(evidencyReasons.filter((er)=>er.key !== NomenclatureConstants.shortTermStayEvidencyReasonKey).map(reason => reason.mappedKeyToId()))
        }

        getAllRegistrationTypes().then((_) => { });
        getAllEntriesForNomenclatureEvidency().then((_) => { });

    }, []);

    function clearFilter(){
        setFirstName("");
        setLastName("");
        setEmbg("");
        setHolder(undefined);
        setActiveSubmittedForm(undefined);
        setBirthDate(null);
        setRegistrationTypeKey("");
        setEvidencyReasonMvrKey("");
        setArchiveNumber("");
        setDecisionTypeMvrKey("");
        setType("")
        setHeaderHidden(true)
        setParentname("");
        setMotherName("")
        setCitizenship(undefined);
        setRegistrationDateFrom(null);
        setRegistrationDateTo(null);
        setDecisionDate(null);
    }

    async function findAllSubmittedForeignCitizensForms(page: number, resetFilter : boolean = false) {
        setIsLoading(true)
        if(resetFilter){
            clearFilter()
            ForeignCitizensService.findAllSubmittedForeignCitizensFormsWithDecisions(page, rowsPerPage, false, "", "", parentName, "", active, email, "", undefined, "", "", undefined, null, submitted, "",motherName, citizenship?.key, registrationDateFrom, registrationDateTo, decisionTypeMvrKey, decisionDate).then((data) => {
                setSubmittedForeignCitizensForms(data.content);
                setTotalElements(data.totalElements);
                setIsLoading(false)
            })
        }else{
            ForeignCitizensService.findAllSubmittedForeignCitizensFormsWithDecisions(page, rowsPerPage, false, registrationTypeKey, firstName, parentName, lastName, active, email, embg, holder, evidencyReasonMvrKey, "", activeSubmittedForm, birthDate, submitted, archiveNumber,motherName, citizenship?.key, registrationDateFrom, registrationDateTo, decisionTypeMvrKey, decisionDate).then((data) => {
                setSubmittedForeignCitizensForms(data.content);
                setTotalElements(data.totalElements);
                setIsLoading(false)
            })
        }
    }

    useEffect(() => {
        findAllSubmittedForeignCitizensForms(page);
    }, [page, rowsPerPage, registrationTypes])

    useEffect(() => {
        findAllSubmittedForeignCitizensForms(page, true);
    }, [submitted])

    useEffect(() => {
        async function getAllDecisionTypes(){
            const entries =  await NomenclatureService.findAllNomenclatureEntriesForNomenclatureList(keyDecisionTypes,undefined);
            setDecisionTypes(entries.map(entry => entry.mappedKeyToId()));
        }

        getAllDecisionTypes().then(() => {});

    }, []);

    useEffect(() => {
        setSearchParams(searchParams);
        const registrationTypeValue = searchParams.get("registrationTypeKey");
        if (registrationTypeValue) {
            const registrationType = registrationTypes.find(
                (registrationType) => registrationType.mvrKey === registrationTypeValue
            );
            if (registrationType) {
                setType(registrationType.displayName);
            }
        }
    }, [registrationTypes, searchParams]);

    useEffect(() => {
        async function userHasAuthority(): Promise<void> {
            const userRoles: string[] | null = StorageService.getUserRoles();
            if (userRoles !== null) {
                if (userRoles.includes(Privileges.MANAGE_SHORT_TERM_STAY) || userRoles.includes(Privileges.MANAGE_ASYLUM_REQUEST) || userRoles.includes(Privileges.MANAGE_FOREIGN_CITIZEN)) {
                    if (userRoles.includes(Privileges.MANAGE_SHORT_TERM_STAY)) {
                        setManageShortTermStay(true)
                    }
                    if (userRoles.includes(Privileges.MANAGE_ASYLUM_REQUEST)) {
                        setManageAsylum(true)
                    }
                    if (userRoles.includes(Privileges.MANAGE_FOREIGN_CITIZEN)) {
                        setManageForeignCitizen(true)
                    }
                }
            } else {
                const authorization = await AuthService.getAuthorization();
                if (authorization.roles.includes(Privileges.MANAGE_SHORT_TERM_STAY) || authorization.roles.includes(Privileges.MANAGE_ASYLUM_REQUEST) || authorization.roles.includes(Privileges.MANAGE_FOREIGN_CITIZEN)) {
                    if (authorization.roles.includes(Privileges.MANAGE_SHORT_TERM_STAY)) {
                        setManageShortTermStay(true)
                    }
                    if (authorization.roles.includes(Privileges.MANAGE_ASYLUM_REQUEST)) {
                        setManageAsylum(true)
                    }
                    if (authorization.roles.includes(Privileges.MANAGE_FOREIGN_CITIZEN)) {
                        setManageForeignCitizen(true)
                    }
                }
            }
        }

        userHasAuthority().then((_) => { });

    }, []);
    function navigateToRegistration(id:number){
        const registrations = submittedForeignCitizensForms.map((item) => item.registration);

        let registrationType = registrations.find((item)=>item.id === id)?.registrationType.mvrKey;
        let isChild = registrations.find((item)=>item.id === id)
        if (registrationType === RegistrationTypes.foreignCitizen) {
            let route = Routes.EDIT_APPLICATION;
            route = route.replace(":id", id.toString());
            navigate(route)
        } else if(registrationType=== RegistrationTypes.asylum) {
            if (isChild?.parentRegistrationReducedDTO !== undefined){
                let route = Routes.ASYLUM_SEEKERS_EDIT;
                route = route.replace(":id", isChild.parentRegistrationReducedDTO.id.toString());
                navigate(route)
            }
            else {
                let route = Routes.ASYLUM_SEEKERS_EDIT;
                route = route.replace(":id", id.toString());
                navigate(route)
            }
        } else{
            let route = Routes.EDIT_SHORT_TERM_STAY_APPLICATION;
            route = route.replace(":id", id.toString());
            navigateWithNavigatedFrom(route);
        }
    }
    function navigateToWorkingVersion(id: number) {
        const registrations = submittedForeignCitizensForms.map((item) => item.registration);

        let registrationType = registrations.find((item) => item.id === id)?.registrationType.mvrKey;
        let isChild = registrations.find((item) => item.id === id)
        let idWorkingVersion = registrations.find((item) => item.id === id)?.workingVersionRegistrationId?.id;
        if (registrationType === RegistrationTypes.foreignCitizen) {
            let route = Routes.EDIT_APPLICATION;
            if (idWorkingVersion) {
                route = route.replace(":id", idWorkingVersion?.toString());
                navigate(route)
            }
        } else if (registrationType === RegistrationTypes.asylum) {
            if (isChild?.parentRegistrationReducedDTO !== undefined) {
                let route = Routes.ASYLUM_SEEKERS_EDIT;
                if (idWorkingVersion) {
                    if (isChild?.parentRegistrationReducedDTO?.workingVersionRegistrationId?.id !== undefined){
                        route = route.replace(":id", isChild?.parentRegistrationReducedDTO?.workingVersionRegistrationId?.id?.toString());
                        navigate(route)
                    }
                }
            }
            else {
                let route = Routes.ASYLUM_SEEKERS_EDIT;
                if (idWorkingVersion) {
                    route = route.replace(":id", idWorkingVersion?.toString());
                    navigate(route)
                }
            }
        } else {
            let route = Routes.EDIT_SHORT_TERM_STAY_APPLICATION;
            if (idWorkingVersion) {
                route = route.replace(":id", idWorkingVersion?.toString());
                navigate(route)
            }
        }
    }

    return (
        <SideBarPage pageTitle={title} component={
            <React.Fragment>
                <FullPageLoadingCircle
                    loading={isLoading}
                />
                <Box component="div" display={"flex"} justifyContent={"space-between"} className={"mt-4"}>
                    <Grid>
                        <FormControl sx={{ minWidth: 200 }}>

                            <InputLabel id="typeOfForms">{strings.typeOfForms}</InputLabel>
                            <Select value={type} labelId="typeOfForms" onChange={handleRegistrationTypeChange} label={strings.typeOfForms}
                                    endAdornment={
                                        <>
                                            {
                                                type && <IconButton onClick={handleRemoveRegistrationTypeFIlter} style={{ marginRight: "15px", height: "15px" }}>
                                                    <Close fontSize="small" />
                                                </IconButton>
                                            }
                                        </>
                                    }>
                                {registrationTypes?.map((registrationType) => (
                                    <MenuItem disableRipple key={registrationType.id} value={registrationType.displayName}>
                                        {registrationType?.displayName}
                                    </MenuItem>
                                ))
                                }
                            </Select>
                        </FormControl>

                        <Button variant={"contained"} onClick={clearFilter} color={"success"} sx={{ height: 40 }} className={"mx-3 mt-2"}>{strings.clearFilter} <FilterAltOffIcon fontSize={"medium"} color={"inherit"}/></Button>
                    </Grid>

                    <Box display="flex"
                         justifyContent="space-between"
                         alignItems="center">
                        {manageForeignCitizen && <Button variant={"contained"} sx={{ height: 40, marginRight: 3 }} className="btn-sm" onClick={addForeigner}>
                            {strings.addForeignCitizen}
                        </Button>}
                        {manageAsylum && <Button variant={"contained"} sx={{ height: 40, marginRight: 3 }} className="btn-sm" onClick={addAsylum}>
                            {strings.addAsylum}
                        </Button>}
                        {manageShortTermStay && <Button variant={"contained"} sx={{ height: 40}} className="btn-sm" onClick={addShortTermApplication}>
                            {strings.addShortTermStay}
                        </Button>}
                    </Box>
                </Box>
                <form onSubmit={() => findAllSubmittedForeignCitizensForms(page)}>
                    <PageTable
                        headerRows={headerRows}
                        firstSearchRow={firstSearchRow}
                        secondSearchRow={secondSearchRow}
                        thirdSearchRow={thirdSearchRow}
                        bottomRows={bottomRows}
                        rows={rows}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        totalElements={totalElements}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleOpen={navigateForeingUserDetails}
                        clickable={true}
                        headerHidden={headerHidden}
                    />
                </form>
                <ConfirmationModal
                    open={openForeign}
                    onClose={true}
                    handleClose={handleCloseForeign}
                    handleConfirm={()=>navigateToRegistration(id)}
                    title={strings.workingVerionRegistration}
                    content={
                        <div>
                            {strings.navigateWorkingVersionOrRegistration}
                            <div className="d-flex justify-content-between mt-3">
                                <Button onClick={()=>navigateToRegistration(id)}>{strings.navigateRegistration}</Button>
                                <Button onClick={()=>navigateToWorkingVersion(id)}>{strings.navigateWorkingVersion}</Button>
                            </div>
                        </div>
                    }
                />
            </React.Fragment>
        } />

    )
}
