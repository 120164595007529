import { AddressCreationDTO } from "./AddressCreationDTO";

export class LivingPlaceDTO {
    id:number;
    dateFrom: Date | string;
    dateTo: Date | string;
    address?: AddressCreationDTO | undefined;
    constructor(json:LivingPlaceDTO){
        this.id= json.id
        this.dateFrom = json.dateFrom;
        this.dateTo = json.dateTo;
        this.address = json.address ? new AddressCreationDTO(json.address) : undefined;
    }
}