import { at } from 'lodash';
import { useField } from 'formik';
import { InputAdornment, TextField } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { transliterateToCyrillic } from '../../../common/AutoTransliterationCyrillic';
interface Props {
  name: string;
  label: string;
  className?: string,
  fullWidth:boolean,
  multiline?:boolean, 
  rows?:number,
  errorText?:string | undefined,
  disabled?:boolean,
  readOnly?: boolean,
  onClick?: () => void;
  icon?:boolean,

}
export default function InputFieldCyrillic(props:Props) {
  const { errorText, multiline, rows, disabled, readOnly, icon, onClick,...rest } = props;
  const [field, meta, helpers] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const transliteratedValue = transliterateToCyrillic(value);
    helpers.setValue(transliteratedValue);
  }
  return (
    <TextField
      type="text"
      error={isError}
      multiline={multiline}
      rows = {rows}
      disabled={disabled}
      helperText={_renderHelperText()}
      InputProps={{
        endAdornment: icon && field.value && <InputAdornment position="start"><GetAppIcon onClick={onClick} className='cursor-pointer'/></InputAdornment>,
        readOnly: props.readOnly ? true : false
      }}
      {...field}
      {...rest}
      onChange={handleInputChange}
      value={field.value ? field.value : ""}
    />
  );
}
