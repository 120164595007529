export class CompanyResponseDTO {
    podruznica: boolean;
    naziv: string;
    adresa: string;
    opstinaSifraMvr: string;
    mestoSifraMvr: string;
    constructor(json:CompanyResponseDTO){
        this.podruznica = json.podruznica;
        this.naziv = json.naziv;
        this.adresa = json.adresa;
        this.opstinaSifraMvr = json.opstinaSifraMvr;
        this.mestoSifraMvr = json.mestoSifraMvr;
    }
}