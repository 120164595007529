import { NomenclatureConstants } from "../constants/NomenclatureConstants";
import { NomenclatureDTO } from "../models/nomenclatures/NomenclatureDTO";
import { NomenclatureEntryDTO } from "../models/nomenclatures/NomenclatureEntryDTO";
import { NomenclatureService } from "../services/NomenclatureService";


export function getAllEntriesForNomenclatureEvidency(): Promise<NomenclatureEntryDTO[]> {
    const evidencyReasons = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.evidencyReason, true);
    return evidencyReasons
}

export function getAllEntriesForNomenclatureGender(): Promise<NomenclatureEntryDTO[]> {
    const genders = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.genders, true);
    return genders
}
export function getAllEntriesForNomenclatureCitizenship(): Promise<NomenclatureEntryDTO[]> {
    const citizenships = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.citizenship, true);
    return citizenships;

}
export function getAllEntriesForNomenclatureCountry(): Promise<NomenclatureEntryDTO[]> {
    const countries = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.country, true);
    return countries
}
export function getAllEntriesForNomenclatureMunicipality(): Promise<NomenclatureEntryDTO[]> {
    const municipalities = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.municipality, true);
    return municipalities
}

export function getAllEntriesForNomenclaturePlace(): Promise<NomenclatureEntryDTO[]> {
    const places = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.place,true);
    return places
}

export function getAllEntriesForNomenclatureOccupation(): Promise<NomenclatureEntryDTO[]> {
    const occupations = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.occupations, true);
    return occupations

}
export function getAllEntriesForNomenclaturePlaceOfRequest(): Promise<NomenclatureEntryDTO[]> {
    const placeData = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.placeOfRequest, true);
    return placeData
}
export function getAllEntriesForNomenclatureProfessionalTraining(): Promise<NomenclatureEntryDTO[]> {
    const professional_trainings = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.professionalTrainings, true);
    return professional_trainings
}
export function getAllEntriesForNomenclatureForeignLanguage(): Promise<NomenclatureEntryDTO[]> {
    const foreignLanguages = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.foreignLanguages, true);
    return foreignLanguages

}
export function getAllEntriesForNomenclatureMarriageStatus(): Promise<NomenclatureEntryDTO[]> {
    const marriage_statuses = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.marriageStatuses, true);
    return marriage_statuses
}

export function getAllEntriesForNomenclatureStreets(): Promise<NomenclatureEntryDTO[]> {
    const streets = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.street, true);
    return streets
}


export function getAllEntriesForNomenclaturePassportType(): Promise<NomenclatureEntryDTO[]> {
    const passport_types = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.passportTypes, true);
    return passport_types
}

export function getAllEntriesForNomenclatureEntranceTypes(): Promise<NomenclatureEntryDTO[]> {
    const entrance_types = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.entranceTypes, true);
    return entrance_types
}

export function getAllEntriesForNomenclatureDiscover(): Promise<NomenclatureEntryDTO[]> {
    const discoverers = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.discoverers, true);
    return discoverers
}
export function getAllEntriesForNomenclatureOccupationPosition(): Promise<NomenclatureEntryDTO[]> {
    const occupation_positions = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.occupationPosition, true);
    return occupation_positions
}

export function getAllEntriesForNomenclatureOVR(): Promise<NomenclatureEntryDTO[]> {
    const ovrs = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.organisationalUnit, true);
    return ovrs

}
export function getAllEntriesForNomenclatureFinancialSources(): Promise<NomenclatureEntryDTO[]> {
    const financial_sources = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.financialSources, true);
    return financial_sources

}
export function getAllEntriesForNomenclatureEmploymentStatus(): Promise<NomenclatureEntryDTO[]> {
    const employment_statuses = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.employmentStatuses, true);
    return employment_statuses

}
export function getAllEntriesForNomenclaturePropertyEngagement(): Promise<NomenclatureEntryDTO[]> {
    const property_engagements = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.propertyEngagement, true);
    return property_engagements

}
export function getAllEntriesForNomenclatureEmloymentSector(): Promise<NomenclatureEntryDTO[]> {
    const employment_sectors = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.employmentSectors, true);
    return employment_sectors

}

export function getAllEntriesForNomenclatureTemporaryResidence(): Promise<NomenclatureEntryDTO[]> {
    const temporary_residence_reasons = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.temporaryResidenceReasons, true);
    return temporary_residence_reasons
}
export function getAllEntriesForNomenclaturePermanentResidence(): Promise<NomenclatureEntryDTO[]> {
    const permanent_residence_reasons = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.permanentResidenceReasons, true);
    return permanent_residence_reasons
}

export function getAllResidenceReasons(data: string) {
    const residenceReasons = NomenclatureService.findAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.reasons, true, `basis=${data}`);
    return residenceReasons
}

export function getAllEntriesForNomenclatureMeasureTakenReasons(): Promise<NomenclatureEntryDTO[]> {
    const measures_taken_reasons = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.measuresTakenReasons, true);
    return measures_taken_reasons

}

export function getAllEntriesForNomenclatureMeasureTakenTypes(): Promise<NomenclatureEntryDTO[]> {
    const measures_taken_types = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.measuresTakenType, true);
    return measures_taken_types

}

export function getAllEntriesForNomenclatureBorderPoints(): Promise<NomenclatureEntryDTO[]> {
    const borders = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.borderPoints, true);
    return borders
}

export function getAllEntriesForNomenclatureVisaType(): Promise<NomenclatureEntryDTO[]> {
  const visa_types = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.visaTypes, true);
  return visa_types
}

export function getAllEntriesForNomenclatureDecisionTypes(): Promise<NomenclatureEntryDTO[]> {
    const decision_types = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.decisionTypes, true);
    return decision_types
}

export function getAllTypesOfObjectsForStay(): Promise<NomenclatureEntryDTO[]> {
    const object_types = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.objectTypes);
    return object_types
}

export function getAllEntriesForNomenclatureComplaintDecisions(): Promise<NomenclatureEntryDTO[]> {
    const complaint_decisions = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.complaintDecisions, true);
    return complaint_decisions
}

export function getAllEntriesForNomenclatureAppealDecisions(): Promise<NomenclatureEntryDTO[]> {
    const appeal_decisions = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.appealDecisions, true);
    return appeal_decisions
}

export function getAllEntriesForNomenclatureIdentificationDocuments(): Promise<NomenclatureEntryDTO[]> {
    const identification_documents = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.identificationDocument, true);
    return identification_documents
}

export function getAllEntriesForNomenclatureAnbAnswers(): Promise<NomenclatureEntryDTO[]> {
    const appeal_decisions = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.anb_answers, true);
    return appeal_decisions
}

export function getAllEntriesForNomenclatureMvrOrganizationalUnit(): Promise<NomenclatureEntryDTO[]> {
    const mvr_organizational_unit = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.mvrOrganizationalUnit, true);
    return mvr_organizational_unit
}

export function getAllEntriesForNomenclatureEthnicity(): Promise<NomenclatureEntryDTO[]> {
    const ethnicOrigin = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.ethnicOrigin, true);
    return ethnicOrigin;
}

export function getAllEntriesForNomenclatureFamilyRelations(): Promise<NomenclatureEntryDTO[]> {
    const family_relations = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.familyRelations, true);
    return family_relations
}

export function getAllEntriesForNomenclaturetemporaryStayReportedBy(): Promise<NomenclatureEntryDTO[]> {
    const temporary_stay_reported_by = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.temporaryStayReportedBy, true);
    return temporary_stay_reported_by
}

export function getAllEntriesForNomenclaturetemporaryStaySettlementReasons(): Promise<NomenclatureEntryDTO[]> {
    const temporary_stay_settlement_reasons = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.temporaryStaySettlementReasons, true);
    return temporary_stay_settlement_reasons
}

export function getAllEntriesForOrganisationalUnit(): Promise<NomenclatureEntryDTO[]> {
    const placeOfRequest = NomenclatureService.getAllNomenclatureEntriesForNomenclatureList(NomenclatureConstants.placeOfRequest, true);
    return placeOfRequest;
}
