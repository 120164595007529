import {Page} from "../components/Page";
import {RegistrationReducedDTO} from "../models/registration/RegistrationReducedDTO";
import axiosInstance from "../network/AxiosInstance";
import {RegistrationReducedWithDecisionDTO} from "../models/registration/RegistrationReducedWithDecisionDTO";

export class ForeignCitizensService {

    static async findAllSubmittedForeignCitizensForms(page: number, size: number, readDiplomat: boolean, registrationTypeKey: string, firstName: string, parentName: string,
                                                      lastName: string, active: boolean | null | undefined, email: string, embg: string, holder: boolean | null | undefined,
                                                      evidencyReasonMvrKey: string, settlementReasonMvrKey: string, activeSubmittedForm: boolean | null | undefined, birthDate: string | null | undefined, submitted: boolean, archiveNumber: string | null | undefined, motherName: string, citizenshipMvrKey: string | undefined, registrationDateFrom: string | undefined | null, registrationDateTo: string | undefined | null): Promise<Page<RegistrationReducedDTO>> {

        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        })


        if (registrationTypeKey) {
            params.append("registrationTypeKey", registrationTypeKey);
        }

        if (firstName) {
            params.append("firstName", firstName);
        }

        if (parentName) {
            params.append("parentName", parentName);
        }

        if (motherName) {
            params.append("motherName", motherName);
        }

        if (citizenshipMvrKey) {
            params.append("citizenshipMvrKey", citizenshipMvrKey);
        }

        if (registrationDateFrom) {
            params.append("registrationDateFrom", registrationDateFrom);
        }

        if (registrationDateTo) {
            params.append("registrationDateTo", registrationDateTo);
        }

        if (lastName) {
            params.append("lastName", lastName);
        }

        if (active !== null && active !== undefined) {
            params.append("active", active.toString());
        }

        if (email) {
            params.append("email", email);
        }

        if (embg) {
            params.append("embg", embg);
        }

        if (holder !== null && holder !== undefined) {
            params.append("holder", holder.toString());
        }

        if (evidencyReasonMvrKey) {
            params.append("evidencyReasonMvrKey", evidencyReasonMvrKey);
        }

        if (settlementReasonMvrKey) {
            params.append("settlementReasonMvrKey", settlementReasonMvrKey);
        }
        if (birthDate) {
            params.append("birthDate", birthDate);
        }

        if (activeSubmittedForm !== null && activeSubmittedForm !== undefined) {
            var isRegistrationPassivated = !activeSubmittedForm;
            params.append("isRegistrationPassivated", isRegistrationPassivated.toString());
        }
        if (archiveNumber) {
            params.append("archiveNumber", archiveNumber)
        }
        params.append("readDiplomat", readDiplomat.toString());
        params.append("submitted", submitted.toString());

        const response = await axiosInstance.get<Page<RegistrationReducedDTO>>(
            `/rest/foreign-citizens/registrations/all-paged`, {params: params}
        )

        const data = response.data;

        return new Page<RegistrationReducedDTO>(
            data.content.map(registration => new RegistrationReducedDTO(registration)),
            data.totalElements
        )
    }

    static async findAllSubmittedForeignCitizensFormsWithDecisions(page: number, size: number, readDiplomat: boolean, registrationTypeKey: string, firstName: string, parentName: string,
                                                      lastName: string, active: boolean | null | undefined, email: string, embg: string, holder: boolean | null | undefined,
                                                      evidencyReasonMvrKey: string, settlementReasonMvrKey: string, activeSubmittedForm: boolean | null | undefined, birthDate: string | null | undefined, submitted: boolean, archiveNumber: string | null | undefined, motherName: string, citizenshipMvrKey: string | undefined, registrationDateFrom: string | undefined | null, registrationDateTo: string | undefined | null,
                                                      decisionTypeMvrKey: string, decisionDate: string | null | undefined): Promise<Page<RegistrationReducedWithDecisionDTO>> {

        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString(),
        })


        if (registrationTypeKey) {
            params.append("registrationTypeKey", registrationTypeKey);
        }

        if (firstName) {
            params.append("firstName", firstName);
        }

        if (parentName) {
            params.append("parentName", parentName);
        }

        if (motherName) {
            params.append("motherName", motherName);
        }

        if (citizenshipMvrKey) {
            params.append("citizenshipMvrKey", citizenshipMvrKey);
        }

        if (registrationDateFrom) {
            params.append("registrationDateFrom", registrationDateFrom);
        }

        if (registrationDateTo) {
            params.append("registrationDateTo", registrationDateTo);
        }

        if (lastName) {
            params.append("lastName", lastName);
        }

        if (active !== null && active !== undefined) {
            params.append("active", active.toString());
        }

        if (email) {
            params.append("email", email);
        }

        if (embg) {
            params.append("embg", embg);
        }

        if (holder !== null && holder !== undefined) {
            params.append("holder", holder.toString());
        }

        if (evidencyReasonMvrKey) {
            params.append("evidencyReasonMvrKey", evidencyReasonMvrKey);
        }

        if (settlementReasonMvrKey) {
            params.append("settlementReasonMvrKey", settlementReasonMvrKey);
        }
        if (birthDate) {
            params.append("birthDate", birthDate);
        }

        if (activeSubmittedForm !== null && activeSubmittedForm !== undefined) {
            var isRegistrationPassivated = !activeSubmittedForm;
            params.append("isRegistrationPassivated", isRegistrationPassivated.toString());
        }
        if (archiveNumber) {
            params.append("archiveNumber", archiveNumber)
        }
        params.append("readDiplomat", readDiplomat.toString());
        params.append("submitted", submitted.toString());

        if (decisionTypeMvrKey) {
            params.append("decisionTypeMvrKey", decisionTypeMvrKey);
        }

        if (decisionDate) {
            params.append("decisionDate", decisionDate);
        }

        const response = await axiosInstance.get<Page<RegistrationReducedWithDecisionDTO>>(
            `/rest/foreign-citizens/registrations/all-with-decision-paged`, {params: params}
        )

        const data = response.data;

        return new Page<RegistrationReducedWithDecisionDTO>(
            data.content.map(registration => new RegistrationReducedWithDecisionDTO(registration)),
            data.totalElements
        )
    }
}
