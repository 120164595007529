import {Routes} from "../../router/Routes";
import {Navigate} from "react-router-dom";
import AuthorizedPage from "../../util/auth/AuthorizedPage";

interface DashboardProps {
    hasAuthority?: boolean;
    readShortTerm?: boolean; 
}

export function Dashboard(props: DashboardProps){

    if(props.hasAuthority === true){
    return (
        <AuthorizedPage component={<Navigate to={Routes.FOREIGNERS_SUBMITTED_FORMS}/>} />
    )
    } else if(props.hasAuthority === false && props.readShortTerm === true) {
        return (
            <AuthorizedPage component={<Navigate to={Routes.FOREIGNERS_SUBMITTED_SHORT_STAY_FORMS}/>} />
        ) 
    } else {
        return (
            <AuthorizedPage component={<Navigate to={Routes.NOMENCLATURES}/>} />
        ) 
    }
  
}
