import { RestrictedStayPlaceDTO } from "./RestrictedStayPlaceDTO";

export class RestrictedStayDataDTO {
id:number;
startDateOfStay:Date | string;
finalDateOfStay: Date | string;
extendedDateOfStay: Date | string;
stoppedDateOfStay: Date | string;
proposer: string;
restrictedStayPlace?: RestrictedStayPlaceDTO;
proposerMvrKey?: string | null;
agentMvrKey?: string | null;
deadline? : Date | string;
agent:string;
constructor(json:RestrictedStayDataDTO){
    this.id = json.id;
    this.startDateOfStay = json.startDateOfStay;
    this.finalDateOfStay = json.finalDateOfStay;
    this.extendedDateOfStay = json.extendedDateOfStay;
    this.stoppedDateOfStay = json.stoppedDateOfStay;
    this.proposer = json.proposer;
    //this.restrictedStayData = json.restrictedStayData
    this.restrictedStayPlace = json.restrictedStayPlace ? new RestrictedStayPlaceDTO(json.restrictedStayPlace) : undefined;
    this.proposerMvrKey = json.proposerMvrKey;
    this.agentMvrKey = json.agentMvrKey;
    this.deadline = json.deadline;
    this.agent = json.agent;
}
}