import * as Yup from 'yup';
import FormModel from './FormModel';
import { strings } from '../../../localization/Localization';

const {
  formField: {
    lastName,
    name,
    gender,
    citizenShip,
    birthDate,
    countryOfBirth,
    placeOfBirth,
    inRSMBirthMunicipality,
    inRSMBirthPlacePlace,
    nameCyrillic,
    surnameCyrillic,
    familyMembersAcompanyList,
    typeOfAccomodation,
    placeAccomodation,
    municipalityAccomodation,
    street,
    streetNumber,
    addressDateFrom,
    requiredMessage,
  }
} = FormModel;


const familyMemberSchema = Yup.object().shape({
  memberSurname: Yup.string().required(`${requiredMessage}`),
  memberName: Yup.string().required(`${requiredMessage}`),
  memberDateOfBirth: Yup.string().required(`${requiredMessage}`),
  // memberStateOfBirth: Yup.mixed().transform((v) => !v ? undefined : v).required(`${requiredMessage}`),
  memberStateOfBirth: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['memberPlaceOfBirth', 'memberInRSMMunicipality', 'memberInRSMPlace'], {
    is: (placeOfBirthAbroadValue: any, inRSMBirthMunicipalityValue: any, inRSMBirthPlacePlaceValue: any) => (!placeOfBirthAbroadValue || placeOfBirthAbroadValue !== "") && !inRSMBirthMunicipalityValue && !inRSMBirthPlacePlaceValue,
    then: (schema) => schema.required(`Полето е задолжително`),
  })),
  // memberPlaceOfBirth: Yup.lazy(() => Yup.string().when(['memberStateOfBirth', 'memberInRSMMunicipality', 'memberInRSMPlace'], {
  //   is: (abroadBirthPlaceValue: any, inRSMBirthMunicipalityValue: any, inRSMBirthPlacePlaceValue: any) => (!abroadBirthPlaceValue || abroadBirthPlaceValue !== "") && !inRSMBirthMunicipalityValue && !inRSMBirthPlacePlaceValue,
  //   then: (schema) => schema.required(`Полето е задолжително`),
  //   otherwise: (schema) => schema.notRequired()
  //
  // })),
  // memberPlaceOfBirth: Yup.string().required(`${requiredMessage}`),
  // memberInRSMMunicipality: Yup.mixed().transform((v) => !v ? undefined : v).required(`${requiredMessage}`),
  // memberInRSMPlace: Yup.string().required(`${requiredMessage}`),
  memberInRSMMunicipality: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['memberPlaceOfBirth', 'memberStateOfBirth', 'memberInRSMPlace'], {
    is: (placeOfBirthAbroadValue: any, abroadBirthPlaceValue: any, inRSMBirthPlacePlaceValue: any) => !placeOfBirthAbroadValue && !abroadBirthPlaceValue && (!inRSMBirthPlacePlaceValue || inRSMBirthPlacePlaceValue !== ""),
    then: (schema) => schema.required(`Полето е задолжително`),
    otherwise: (schema) => schema.notRequired()
  })),
  memberInRSMPlace: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['memberStateOfBirth', 'memberInRSMMunicipality', 'memberPlaceOfBirth'], {
    is: (abroadBirthPlaceValue: any, inRSMBirthMunicipalityValue: any, placeOfBirthAbroadValue: any) => !abroadBirthPlaceValue && (!inRSMBirthMunicipalityValue || inRSMBirthMunicipalityValue !== "") && !placeOfBirthAbroadValue,
    then: (schema) => schema.required(`Полето е задолжително`),
    otherwise: (schema) => schema.notRequired()
  })),
  memberCitizenShip: Yup.mixed().transform((v) => !v ? undefined : v).required(`${requiredMessage}`),
  relationshipsOrSex: Yup.string().required(`${requiredMessage}`),
  memberNameCyrillic: Yup.string().required(`${requiredMessage}`),
  memberSurnameCyrillic: Yup.string().required(`${requiredMessage}`),
});

const step4ValidationSchema = Yup.object().shape({
  familyMembersAcompanyList: Yup.array().of(familyMemberSchema),
});

export default [
  // Step 1 Validation Schema
  Yup.object().shape({
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [name.name]: Yup.string().required(`${name.requiredErrorMsg}`),
    [nameCyrillic.name]: Yup.string().required(`${nameCyrillic.requiredErrorMsg}`),
    [surnameCyrillic.name]: Yup.string().required(`${surnameCyrillic.requiredErrorMsg}`),
    [gender.name]: Yup.string().required(`${gender.requiredErrorMsg}`),
    [citizenShip.name]: Yup.object().required(`${citizenShip.requiredErrorMsg}`),
    [birthDate.name]: Yup.string().required(`${birthDate.requiredErrorMsg}`),
    [countryOfBirth.name]: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['placeOfBirth', 'inRSMBirthMunicipality', 'inRSMBirthPlacePlace'], {
      is: (placeOfBirthAbroadValue: any, inRSMBirthMunicipalityValue: any, inRSMBirthPlacePlaceValue: any) => (!placeOfBirthAbroadValue || placeOfBirthAbroadValue !== "") && !inRSMBirthMunicipalityValue && !inRSMBirthPlacePlaceValue,
      then: (schema) => schema.required(`${countryOfBirth.requiredErrorMsg}`),
    })),
    // [placeOfBirth.name]: Yup.lazy(() => Yup.string().when(['countryOfBirth', 'inRSMBirthMunicipality', 'inRSMBirthPlacePlace'], {
    //   is: (abroadBirthPlaceValue: any, inRSMBirthMunicipalityValue: any, inRSMBirthPlacePlaceValue: any) => (!abroadBirthPlaceValue || abroadBirthPlaceValue !== "") && !inRSMBirthMunicipalityValue && !inRSMBirthPlacePlaceValue,
    //   then: (schema) => schema.required(`${placeOfBirth.requiredErrorMsg}`),
    //   otherwise: (schema) => schema.notRequired()
    //
    // })),
    [inRSMBirthMunicipality.name]: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['placeOfBirth', 'countryOfBirth', 'inRSMBirthPlacePlace'], {
      is: (placeOfBirthAbroadValue: any, abroadBirthPlaceValue: any, inRSMBirthPlacePlaceValue: any) => !placeOfBirthAbroadValue && !abroadBirthPlaceValue && (!inRSMBirthPlacePlaceValue || inRSMBirthPlacePlaceValue !== ""),
      then: (schema) => schema.required(`${inRSMBirthMunicipality.requiredErrorMsg}`),
      otherwise: (schema) => schema.notRequired()
    })),
    [inRSMBirthPlacePlace.name]: Yup.lazy(() => Yup.mixed().transform((v) => !v ? undefined : v).when(['countryOfBirth', 'inRSMBirthMunicipality', 'placeOfBirth'], {
      is: (abroadBirthPlaceValue: any, inRSMBirthMunicipalityValue: any, placeOfBirthAbroadValue: any) => !abroadBirthPlaceValue && (!inRSMBirthMunicipalityValue || inRSMBirthMunicipalityValue !== "") && !placeOfBirthAbroadValue,
      then: (schema) => schema.required(`${inRSMBirthPlacePlace.requiredErrorMsg}`),
      otherwise: (schema) => schema.notRequired()
    })),
  }),

  Yup.object().shape({
  }),

  Yup.object().shape({
    [typeOfAccomodation.name] : Yup.string().required(`${typeOfAccomodation.requiredErrorMsg}`),
    [municipalityAccomodation.name]: Yup.mixed().required(`${municipalityAccomodation.requiredErrorMsg}`),
    [placeAccomodation.name]: Yup.mixed().required(`${placeAccomodation.requiredErrorMsg}`),
    [street.name]: Yup.mixed().required(`${street.requiredErrorMsg}`),
    [streetNumber.name]: Yup.string().required(`${streetNumber.requiredErrorMsg}`),
    [addressDateFrom.name]: Yup.string().required(`${addressDateFrom.requiredErrorMsg}`),
  }),

  // Step 4 Validation Schema
  step4ValidationSchema,

  Yup.object().shape({
  }),
];