import {Guard} from "./Guard";
import {StorageService} from "../../storage/StorageService";
import {Navigate} from "react-router-dom";
import {Routes} from "../Routes";

export class NonInternalAuthGuard extends Guard {
    constructor() {
        super((
            <Navigate replace={true} to={Routes.NOT_FOUND}/>
        ));
    }

    async check(): Promise<boolean> {
        return !StorageService.hasInternalToken();
    }
}
