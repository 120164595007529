import { CountryCreationDTO } from "./CountryCreationDTO";

export class PlaceCreationDTO{
    mvrKey:string | null;
    countryDTO:CountryCreationDTO;
    municipalityName:string | null;
    municipalityMvrKey:string | null;
    name:string | null;
    nameSq:string | null;
    nameEn:string | null;
    constructor(json:PlaceCreationDTO){
        this.mvrKey = json.mvrKey;
        this.countryDTO = new CountryCreationDTO(json.countryDTO);
        this.municipalityName = json.municipalityName;
        this.municipalityMvrKey = json.municipalityMvrKey;
        this.name = json.name;
        this.nameSq = json.nameSq;
        this.nameEn = json.nameEn;
    }
}