import { AxiosError } from "axios";
import { showErrorDialog } from "../../common/Dialogues";
import { strings } from "../../localization/Localization";
import { PermitDTO } from "../../models/reports/PermitDTO";
import axiosInstance from "../../network/AxiosInstance";
import { AuthService } from "../AuthService";

export class ReportService {

    static async readCardDataForEMBG(embg: string): Promise<Array<PermitDTO>> {
        const response = await axiosInstance.get<Array<PermitDTO>>(`${AuthService.PREFIX}/foreign-citizens/registrations/read-card-with-embg/${embg}`);
        const data = response.data;
        return data;
    }

    static async downloadCardDataForEMBG(embg: string) {
        const url = `${AuthService.PREFIX}/foreign-citizens/registrations/download-card-with-embg/${embg}`;
      
        try {
          const response = await axiosInstance.post(url, null, {
            responseType: 'blob',
          });
      
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
          const fileURL = window.URL.createObjectURL(blob);
      
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = `Број на дозволи за матичниот број ${embg}.xlsx`;
          document.body.appendChild(link);
      
          link.click();
      
          document.body.removeChild(link);
          window.URL.revokeObjectURL(fileURL);
        } catch (error) {
          const axiosError = error as AxiosError;
          if (axiosError.isAxiosError && axiosError.message === "Request failed with status code 404") {
            showErrorDialog(strings.fail, "Не постојат податоци за дозвола за конкретниот матичен број!", strings.ok).then(_ => {});
          } else {
            showErrorDialog(strings.fail, "Не постои регистрација со тој матичен број!", strings.ok).then(_ => {});
          }
        }
    }
}