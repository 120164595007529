import { at } from 'lodash';
import { useField } from 'formik';
import { InputAdornment, TextField } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
interface Props {
  name: string;
  label: string;
  className?: string,
  fullWidth:boolean,
  multiline?:boolean, 
  rows?:number,
  errorNumber?:number | undefined,
  disabled?:boolean,
  onClick?: () => void;
  icon?:boolean,

}
export default function InputFieldNumber(props:Props) {
  const { errorNumber, multiline, rows, disabled,icon, onClick,...rest } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }
  
  return (
    <TextField
      type="number"
      error={isError}
      multiline={multiline}
      rows = {rows}
      disabled={disabled}
      helperText={_renderHelperText()}
      InputProps={{
        endAdornment: icon && field.value && <InputAdornment position="start"><GetAppIcon onClick={onClick} className='cursor-pointer'/></InputAdornment>,
      }}
      {...field}
      {...rest}
      value={field.value ? field.value : ""}
    />
  );
}
