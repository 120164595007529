import { RegistrationOrEmbgHistoryActionDTO } from "./RegistrationOrEmbgHistoryActionDTO";

export class RegistrationHistoryActionsDetailsDTO{
    firstName : string;
    lastName: string;
    embg : string;
    registrationOrEmbgHistoryActions: RegistrationOrEmbgHistoryActionDTO[]

    constructor(json:RegistrationHistoryActionsDetailsDTO){
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.embg = json.embg;
        this.registrationOrEmbgHistoryActions = json.registrationOrEmbgHistoryActions?.map((item:any)=>new RegistrationOrEmbgHistoryActionDTO(item)?? [])
    }
}