import { Button, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { CenteredCardLayout } from "../../../components/CenteredCardLayout";
import { DatePickerField } from "../formFields/DatePickerFields";
import InputField from "../formFields/InputField";
import SelectField from "../formFields/SelectField";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { NomenclatureEntryDTO } from "../../../models/nomenclatures/NomenclatureEntryDTO";
import { useEffect, useState } from "react";
import { strings } from "../../../localization/Localization";
import { useFormikContext } from "formik";
import CustomSelect from "../formFields/CustomSelect";
import {
  getAllEntriesForNomenclatureFamilyRelations,
    getAllEntriesForNomenclatureGender,
    getAllEntriesForNomenclatureMunicipality, getAllEntriesForNomenclaturePlace, getAllEntriesForNomenclatureStreets,
    getAllResidenceReasons
} from "../../../common/NomenclatureMethods";
import { FileService } from "../../../services/FileService";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import { showErrorDialog, showSuccessDialog } from "../../../common/Dialogues";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { NomenclatureConstants } from "../../../constants/NomenclatureConstants";
import { ExternalService } from "../../../services/ExternalService";
import { NomenclatureService } from "../../../services/NomenclatureService";
import { PaginatedSelect } from "../../../components/PaginatedSelect";
import {values} from "lodash";
import InputFieldCyrillic from "../formFields/InputFieldCyrillic";
const list = [
  {
    value: true,
    label: 'Позитивно'
  },
  {
    value: false,
    label: 'Негативно'
  },
];
export function SettleDataForm(props:any) {
  const {
    formField: {
      settlementReasonMvrKey,
      familyRelationMvrKey,
      approvalDate,
      dateOfExtension,
      endDate,
      startDateStay,
      endDateStay,
      dateOfDeliveryOfDecision,
      decision,
      fileDecision,
      idForeignerNumber,
      dateIdForeignerNumber,
      company,
      companyName,
      companyPlace,
      companyCountry,
      spouseEmbg,
      spouseLastName,
      spouseFirstName,
      spouseBirthDate,
      spouseBirthPlace,
      spouseAbroadBirthPlace,
      placeOfBirthSpouseAbroad,
      inRSM,
      familyMemberBirthMunicipality,
      familyMemberBirthPlace,
      numberChildren,
      numberOfForeignChildren,
      numberOfDomesticChildren,
      privateVisitPerson,
      embgPrivateVisitPerson,
      lastNamePrivateVisitPerson,
      firstNamePrivateVisitPerson,
      genderPrivateVisitPerson,
      citizen,
      addressPrivateVisitPerson,
      municipalityPrivateVisitPerson,
      placePrivateVisitPerson,
      streetPrivateVisitPerson,
      numberStreetPrivateVisitPerson,
      apartmentEntrancePrivateVisitPerson, 
      apartmentPrivateVisitPerson,
      municipalityRelative,
      placeRelative,
      streetRelative,
      numberStreetRelative,
      addressRelative,
    }
  } = props;
  const [genders, setGenders] = useState<NomenclatureEntryDTO[]>([]);
  const [residenceReasons, setResidenceReasons] = useState<NomenclatureEntryDTO[]>([])
  const [familyRelations, setFamilyRelations] = useState<NomenclatureEntryDTO[]>([])
  const formikProps = useFormikContext<any>()
  const [disabled, setDisabled] = useState<boolean>(true);
  const [disabledSpouseAbroad, setDisabledSpouseAbroad] = useState<boolean>();
  const [disabledSpouseRSM, setDisabledSpouseRSM] = useState<boolean>();
  useEffect(()=>{

    getAllEntriesForNomenclatureGender().then((res)=>setGenders(res));
    if(formikProps.values.evidencyReasonMvrKey){
    getAllResidenceReasons(formikProps.values.evidencyReasonMvrKey).then((res)=>{setResidenceReasons(res)});
    }
    getAllEntriesForNomenclatureFamilyRelations().then((res) => setFamilyRelations(res))

     async function loadData(): Promise<void> {
      if(formikProps.values.evidencyReasonMvrKey){
      await getAllResidenceReasons(formikProps.values.evidencyReasonMvrKey);
      }
       if(formikProps.values.evidencyReasonMvrKey===NomenclatureConstants.permanentStayKey || formikProps.values.evidencyReasonMvrKey===NomenclatureConstants.temporaryStayKey){
       setDisabled(true)
       }else{
        setDisabled(false)
      }
    }
    loadData().then((_) => {
    });
  },[])
  useEffect(()=>{
    if(formikProps.values.spouseAbroadBirthPlace || formikProps.values.placeOfBirthSpouseAbroad){
      setDisabledSpouseRSM(true);
    }else{
      setDisabledSpouseRSM(false);
    }
    if(formikProps.values.familyMemberBirthMunicipality || formikProps.values.familyMemberBirthPlace){
      setDisabledSpouseAbroad(true);
    }else{
      setDisabledSpouseAbroad(false)
    }

  },[formikProps.values.spouseAbroadBirthPlace, formikProps.values.placeOfBirthSpouseAbroad, formikProps.values.familyMemberBirthMunicipality,formikProps.values.familyMemberBirthPlace, disabledSpouseAbroad, disabledSpouseRSM])

function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString().split(";base64,")[1];
      if (base64String) {
        resolve(base64String);
      } else {
        reject(new Error("Failed to convert file to Base64"));
      }
    };
    reader.readAsDataURL(file);
  });
}
async function handleFileRead(event:any) {
  const file = event.target.files[0]

  if(!file){ 
    return
  }

  const MAX_FILE_SIZE = 30 * 1024 * 1024; //ova e ekvivalentno na 30MB
  if(file.size > MAX_FILE_SIZE) {
    showErrorDialog(strings.fail, "Оваа датотека е преголема за да се прикачи! Максималната поддржана големина e 30MB.", strings.ok).then(_ => {})
    event.target.value = null
    return
  }

  const base64 = await fileToBase64(file)
  const mimeType = file.type
  formikProps.setFieldValue("fileDecision", {
    name: file.name,
    contentBase64: base64,
    mimeType: mimeType,
  });
}
async function downloadFileDecision(id:number, name:string) {
  await FileService.downloadFileContent(id,name).catch((e) => {
    const errorMessage = ErrorHandler.parseErrorMessage(e);
    showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
  });
}
async function getPersonDataByEmbg(embg:string){
  await ExternalService.getPersonInformationByEmbg(embg).then((res)=>{
   formikProps.setFieldValue("spouseFirstName", res.firstName);
   formikProps.setFieldValue("spouseLastName", res.lastName);
   formikProps.setFieldValue("spouseBirthDate", res.birthDate);
  }).catch((e)=>{
    const errorMessage = ErrorHandler.parseErrorMessage(e);
    showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
  })

    await ExternalService.getLivingPlaceByEmbg(embg).then((res) => {
        getAllEntriesForNomenclatureMunicipality().then((municipalities)=>{
            const item = municipalities.find(el => el.key === res.sifOpstina)
            formikProps.setFieldValue("municipalityRelative",item)
        })
        getAllEntriesForNomenclaturePlace().then((places)=>{
            const item = places.find(el => el.key === res.sifMesto)
            formikProps.setFieldValue("placeRelative",item)
        })
        getAllEntriesForNomenclatureStreets().then((streets) => {
            const item = streets.find(el => el.key === res.sifUlica)
            formikProps.setFieldValue("streetRelative",item)
        })
        if(res.brojUlica !== null && res.brojUlica !== undefined) {
            formikProps.setFieldValue("numberStreetRelative", res.brojUlica)
        }
    }).catch((e)=>{
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
    })
}
async function getPrivateVisitPersonDataByEmbg(embg:string){
  await ExternalService.getPersonInformationByEmbg(embg).then((res)=>{
    formikProps.setFieldValue("lastNamePrivateVisitPerson", res.lastName)
    formikProps.setFieldValue("firstNamePrivateVisitPerson", res.firstName)
  }).catch((e)=>{
    const errorMessage = ErrorHandler.parseErrorMessage(e);
    showErrorDialog(strings.fail, errorMessage, strings.ok).then(_ => {})
  })
}
function handleChange(event: SelectChangeEvent){
  formikProps.setFieldValue("decision", event.target.value)
  if(event.target.value){
    showSuccessDialog(strings.opinionPositiveTitle, strings.opinionPositive, strings.ok).then(_ => {
    });
  }
};

function handleSettlementReasonChange(newValue: any) {
  if (
    newValue !== NomenclatureConstants.familyReunificationMvrKey &&
    newValue !== NomenclatureConstants.familyReunificationShortTermStayMvrKey
  ) {
    formikProps.setFieldValue('familyRelationMvrKey', "")
  }
};

    return (
      <>
        <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {/* Grid 1 */}
        <Grid item xs={12} sm={4} md={6}>
        <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
       2. Постојано населување или привремен престој
      </Typography>
        <Typography variant="subtitle1" gutterBottom>
            {formikProps.values.evidencyReasonMvrKey === NomenclatureConstants.measureTakenKey ?  settlementReasonMvrKey.id + ". " + strings.measureTaken :  settlementReasonMvrKey.id + ". " + settlementReasonMvrKey.label }
        </Typography>
        <SelectField className="pb-3"
            name={settlementReasonMvrKey.name}
            label={formikProps.values.evidencyReasonMvrKey === NomenclatureConstants.measureTakenKey ?  strings.measureTaken + "*" :  settlementReasonMvrKey.placeholder }
            data={residenceReasons}
            read={props.hasAuthority ? false : true}
            onResetFamilyRelation={handleSettlementReasonChange}
          />
        {(formikProps?.values.settlementReasonMvrKey === NomenclatureConstants.familyReunificationMvrKey || formikProps?.values.settlementReasonMvrKey === NomenclatureConstants.familyReunificationShortTermStayMvrKey) && 
        <><Typography variant="subtitle1" gutterBottom>
            {familyRelationMvrKey.id + ". " + familyRelationMvrKey.label}
        </Typography>
        <SelectField className="pb-3"
            name={familyRelationMvrKey.name}
            label={strings.role}
            data={familyRelations}
            read={props.hasAuthority ? false : true}
          /></>}
        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"}  style={{border:"1px solid #3f51b5"}}>
        <Typography variant="subtitle1" gutterBottom>
            {approvalDate.id + ". " + approvalDate.label}
        </Typography>
        <DatePickerField
            name={approvalDate.name}
            label={approvalDate.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
          />
           <Typography variant="subtitle1" gutterBottom>
            {dateOfExtension.id + ". " + dateOfExtension.label}
        </Typography>
        <DatePickerField
            name={dateOfExtension.name}
            label={dateOfExtension.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
          />
        <Typography variant="subtitle1" gutterBottom>
            {endDate.id + ". " + endDate.label}
        </Typography>  
        <CenteredCardLayout maxWidth={"100%"} minWidth={"100%"} className="pb-3">
        <Typography variant="subtitle1" gutterBottom>
            {startDateStay.id + ". " + startDateStay.label}
        </Typography>  
        <DatePickerField
            name={startDateStay.name}
            label={startDateStay.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
          />
           <Typography variant="subtitle1" gutterBottom>
            {endDateStay.id + ". " + endDateStay.label}
        </Typography>  
           <DatePickerField
            name={endDateStay.name}
            label={endDateStay.placeholder}
            className="pb-3"
            minDate={formikProps?.values?.startDateStay}
            read={props.hasAuthority ? false : true}
          />
          </CenteredCardLayout>
          <Typography variant="subtitle1" gutterBottom>
            {dateOfDeliveryOfDecision.id + ". " + dateOfDeliveryOfDecision.label}
        </Typography> 
        <DatePickerField
            name={dateOfDeliveryOfDecision.name}
            label={dateOfDeliveryOfDecision.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
          />
          <Typography variant="subtitle1" gutterBottom>
            {decision.id + ". " + decision.label}
        </Typography> 
        <CustomSelect className="pb-3"
            name={decision.name}
            label={decision.placeholder}
            data={list}
            read={props.hasAuthority ? false : true}
            onChange={handleChange}
          />
              <input style={{ display: "none" }} onChange={handleFileRead} name={fileDecision.name}
                accept=".pdf,.doc,.docx,.txt,.csv,image/*"
                id="raised-button-file"
                type="file"
              />
              <a onClick={()=>downloadFileDecision(formikProps?.values?.fileDecision?.id, formikProps?.values?.fileDecision?.name)} className="cursor-pointer fileLink"> {formikProps.values.fileDecision && <InsertDriveFileIcon/>}{formikProps?.values?.fileDecision?.name}</a>
              <label htmlFor="raised-button-file" className="w-100">
                {props.hasAuthority && <Button component="span" endIcon={<AttachFileIcon />} variant="contained" style={{ float: "right" }} className="mb-3" >
                  Прикачи решение
                </Button>}
              </label>
          </CenteredCardLayout>
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"}  style={{border:"1px solid #FFA500", marginTop:"10px"}}>
            <div style = {{fontSize:"13px"}}>*Повеќе информации за историјата на број на дозволи може да се најдат во <b>Извештаи - Преглед за број на дозволи за матичен број</b></div>
            <Typography variant="subtitle1" gutterBottom className="pt-3">
              {idForeignerNumber.id + ". " + idForeignerNumber.label}
          </Typography>
          <InputField name={idForeignerNumber.name} label={idForeignerNumber.placeholder}  className="pb-3" fullWidth readOnly={true}></InputField>
          <Typography variant="subtitle1" gutterBottom>
              {dateIdForeignerNumber.id + ". " + dateIdForeignerNumber.label}
          </Typography>
          <DatePickerField
              name={dateIdForeignerNumber.name}
              label={dateIdForeignerNumber.placeholder}
              className="pb-3"
              read={true}
            />
            </CenteredCardLayout>
        <Typography variant="subtitle1" gutterBottom>
            {company.id + ". " + company.label}
        </Typography>  
        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
        <Typography variant="subtitle1" gutterBottom>
            {companyName.id + ". " + companyName.label}
        </Typography>  
        <InputField name={companyName.name} label={companyName.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {companyCountry.id + ". " + companyCountry.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.companyCountry!=="" ? formikProps.values.companyCountry : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("companyCountry", value)
                          }
                        }
                        label={companyCountry.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={companyCountry.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {companyPlace.id + ". " + companyPlace.label}
        </Typography>  
        <InputField name={companyPlace.name} label={companyPlace.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
       
        </CenteredCardLayout>

        <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
       А. Податоци за членовите на потесното семејство со кои престојува
      </Typography>
        <Typography variant="subtitle1" gutterBottom>
            {spouseEmbg.id + ". " + spouseEmbg.label}
        </Typography>  
        <InputField name={spouseEmbg.name} label={spouseEmbg.placeholder}  className="pb-3" fullWidth icon={(formikProps.values.spouseEmbg && formikProps.values.spouseEmbg.length === 13) ? true : false}
        onClick={()=>getPersonDataByEmbg(formikProps.values.spouseEmbg)}  readOnly={props.hasAuthority ? false : true} embgNumber={true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {spouseLastName.id + ". " + spouseLastName.label}
        </Typography>  
        <InputField name={spouseLastName.name} label={spouseLastName.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {spouseFirstName.id + ". " + spouseFirstName.label}
        </Typography>  
        <InputField name={spouseFirstName.name} label={spouseFirstName.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {spouseBirthDate.id + ". " + spouseBirthDate.label}
        </Typography>  
        <DatePickerField
            name={spouseBirthDate.name}
            label={spouseBirthDate.placeholder}
            className="pb-3"
            read={props.hasAuthority ? false : true}
            maxDate={true}
          />
        <Typography variant="subtitle1" gutterBottom>
            {spouseBirthPlace.id + ". " + spouseBirthPlace.label}
        </Typography>  
        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
        <Typography variant="subtitle1" gutterBottom>
            {spouseAbroadBirthPlace.id + ". " + spouseAbroadBirthPlace.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.spouseAbroadBirthPlace!=="" ? formikProps.values.spouseAbroadBirthPlace : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("spouseAbroadBirthPlace", value)
                          }
                        }
                        label={spouseAbroadBirthPlace.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.country, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={spouseAbroadBirthPlace.name}
                        className="pb-3"
                        disabled={disabledSpouseAbroad}
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {placeOfBirthSpouseAbroad.id + ". " + placeOfBirthSpouseAbroad.label}
        </Typography>  
        <InputField name={placeOfBirthSpouseAbroad.name} disabled={disabledSpouseAbroad} label={placeOfBirthSpouseAbroad.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {inRSM.id + ". " + inRSM.label}
        </Typography>  
        <Typography variant="subtitle1" gutterBottom>
            {familyMemberBirthMunicipality.id + ". " + familyMemberBirthMunicipality.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.familyMemberBirthMunicipality!=="" ? formikProps.values.familyMemberBirthMunicipality : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("familyMemberBirthMunicipality", value);
                          formikProps.setFieldValue("familyMemberBirthPlace", "")
                        }
                        }
                        label={familyMemberBirthMunicipality.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={familyMemberBirthMunicipality.name}
                        className="pb-3"
                        disabled={disabledSpouseRSM}
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {familyMemberBirthPlace.id + ". " + familyMemberBirthPlace.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.familyMemberBirthPlace!=="" ? formikProps.values.familyMemberBirthPlace : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("familyMemberBirthPlace", value)
                          }
                        }
                        label={familyMemberBirthPlace.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.familyMemberBirthMunicipality.key}`, filter ? filter : ""
                          );
                        }}
                        name={familyMemberBirthPlace.name}
                        className="pb-3"
                        disabled={disabledSpouseRSM}
                        readOnly={props.hasAuthority ? false : true}
                      />
        </CenteredCardLayout>
          <Typography variant="subtitle1" gutterBottom>
            {addressRelative.id + ". " + addressRelative.label}
          </Typography>
          <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
            <Typography variant="subtitle1" gutterBottom>
              {municipalityRelative.id + ". " + municipalityRelative.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                value={formikProps.values.municipalityRelative!=="" ? formikProps.values.municipalityRelative : undefined}
                onChange={(value) => {
                  formikProps.setFieldValue("municipalityRelative", value);
                  formikProps.setFieldValue("placeRelative", "");
                  formikProps.setFieldValue("streetRelative", "");
                }
                }
                label={strings.municipality}
                valueMapper={(item) => item?.key && item?.key?.toString()}
                keyMapper={(item) => item.id.toString()}
                itemMapper={(item) => <>{item.name}</>}
                labelMapper={(item) => item.name}
                dataFetcher={(page, size, filter) => {
                  return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                      page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                  );
                }}
                name={municipalityRelative.name}
                className="pb-3"
                readOnly={props.hasAuthority ? false : true}
            />
            <Typography variant="subtitle1" gutterBottom>
              {placeRelative.id + ". " + placeRelative.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                value={formikProps.values.placeRelative!=="" ? formikProps.values.placeRelative : undefined}
                onChange={(value) => {
                  formikProps.setFieldValue("placeRelative", value);
                  formikProps.setFieldValue("streetRelative", "")
                }
                }
                label={strings.place}
                valueMapper={(item) => item?.key && item?.key?.toString()}
                keyMapper={(item) => item.id.toString()}
                itemMapper={(item) => <>{item.name}</>}
                labelMapper={(item) => item.name}
                dataFetcher={(page, size, filter) => {
                  return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                      page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.municipalityRelative?.key}`, filter ? filter : ""
                  );
                }}
                name={placeRelative.name}
                className="pb-3"
                readOnly={props.hasAuthority ? false : true}
            />
            <Typography variant="subtitle1" gutterBottom>
              {streetRelative.id + ". " + streetRelative.label}
            </Typography>
            <PaginatedSelect<NomenclatureEntryDTO>
                value={formikProps.values.streetRelative!=="" ? formikProps.values.streetRelative : undefined}
                onChange={(value) => {
                  formikProps.setFieldValue("streetRelative", value)
                }
                }
                label={strings.street}
                valueMapper={(item) => item?.key && item?.key?.toString()}
                keyMapper={(item) => item.id.toString()}
                itemMapper={(item) => <>{item.name}</>}
                labelMapper={(item) => item.name}
                dataFetcher={(page, size, filter) => {
                  return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                      page, size, NomenclatureConstants.street, true, `place=${formikProps.values.placeRelative?.key}`, filter ? filter : ""
                  );
                }}
                name={streetRelative.name}
                className="pb-3"
                readOnly={props.hasAuthority ? false : true}
            />
            <Typography variant="subtitle1" gutterBottom>
              {numberStreetRelative.id + ". " + numberStreetRelative.label}
            </Typography>
            <InputField name={numberStreetRelative.name} label={numberStreetRelative.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
          </CenteredCardLayout>
        </Grid>
        {/* Grid 2 */}
        <Grid item xs={12} sm={4} md={6}>
        <Typography variant="subtitle1" gutterBottom>
            {numberChildren.id + ". " + numberChildren.label}
        </Typography>  
        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
        <Typography variant="subtitle1" gutterBottom>
            {numberOfDomesticChildren.id + ". " + numberOfDomesticChildren.label}
        </Typography>  
        <InputField name={numberOfDomesticChildren.name} label={numberOfDomesticChildren.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {numberOfForeignChildren.id + ". " + numberOfForeignChildren.label}
        </Typography>
        <InputField name={numberOfForeignChildren.name} label={numberOfForeignChildren.placeholder}  className="pb-3" fullWidth readOnly={props.hasAuthority ? false : true}></InputField> 

        </CenteredCardLayout>
        {!disabled && (
        <>
        <Typography variant="h5" gutterBottom display={"flex"} justifyContent={"center"} className="pb-3">
       Б. Лице кај кого престојува во посета
      </Typography>
        <Typography variant="subtitle1" gutterBottom>
            {privateVisitPerson.id + ". " + privateVisitPerson.label}
        </Typography>
        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
        <Typography variant="subtitle1" gutterBottom>
            {embgPrivateVisitPerson.id + ". " + embgPrivateVisitPerson.label}
        </Typography>  
        <InputField name={embgPrivateVisitPerson.name} label={embgPrivateVisitPerson.placeholder} icon={(formikProps.values.embgPrivateVisitPerson && formikProps.values.embgPrivateVisitPerson.length === 13) ? true : false} embgNumber={true}
        onClick={()=>getPrivateVisitPersonDataByEmbg(formikProps.values.embgPrivateVisitPerson)}  className="pb-3" readOnly={props.hasAuthority ? false : true} fullWidth disabled={disabled}></InputField>
        
     
        <Typography variant="subtitle1" gutterBottom>
            {lastNamePrivateVisitPerson.id + ". " + lastNamePrivateVisitPerson.label}
        </Typography>  
        <InputField name={lastNamePrivateVisitPerson.name} label={lastNamePrivateVisitPerson.placeholder}  className="pb-3" fullWidth disabled={disabled} readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {firstNamePrivateVisitPerson.id + ". " + firstNamePrivateVisitPerson.label}
        </Typography>  
        <InputField name={firstNamePrivateVisitPerson.name} label={firstNamePrivateVisitPerson.placeholder}  className="pb-3" fullWidth disabled={disabled} readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {genderPrivateVisitPerson.id + ". " + genderPrivateVisitPerson.label}
        </Typography>  
        <SelectField className="pb-3"
            name={genderPrivateVisitPerson.name}
            label={genderPrivateVisitPerson.placeholder}
            data={genders}
            disabled={disabled}
            read={props.hasAuthority ? false : true}
          />
        <Typography variant="subtitle1" gutterBottom>
            {citizen.id + ". " + citizen.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.citizen!=="" ? formikProps.values.citizen : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("citizen", value)
                          }
                        }
                        label={citizen.placeholder}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.citizenship, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={citizen.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        </CenteredCardLayout>
        <Typography variant="subtitle1" gutterBottom>
            {addressPrivateVisitPerson.id + ". " + addressPrivateVisitPerson.label}
        </Typography>  
        <CenteredCardLayout minWidth={"100%"} maxWidth={"100%"} className="pb-3">
        <Typography variant="subtitle1" gutterBottom>
            {municipalityPrivateVisitPerson.id + ". " + municipalityPrivateVisitPerson.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.municipalityPrivateVisitPerson!=="" ? formikProps.values.municipalityPrivateVisitPerson : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("municipalityPrivateVisitPerson", value);
                          formikProps.setFieldValue("placePrivateVisitPerson", "");
                          formikProps.setFieldValue("streetPrivateVisitPerson", "");
                        }
                        }
                        label={strings.municipality}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclature(
                            page, size, NomenclatureConstants.municipality, filter ? filter : "", "", "", undefined, ""
                          );
                        }}
                        name={municipalityPrivateVisitPerson.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {placePrivateVisitPerson.id + ". " + placePrivateVisitPerson.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.placePrivateVisitPerson!=="" ? formikProps.values.placePrivateVisitPerson : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("placePrivateVisitPerson", value);
                          formikProps.setFieldValue("streetPrivateVisitPerson", "")
                        }
                        }
                        label={strings.place}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.place, true, `municipality=${formikProps.values.municipalityPrivateVisitPerson?.key}`, filter ? filter : ""
                          );
                        }}
                        name={placePrivateVisitPerson.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {streetPrivateVisitPerson.id + ". " + streetPrivateVisitPerson.label}
        </Typography>  
        <PaginatedSelect<NomenclatureEntryDTO>
                        value={formikProps.values.streetPrivateVisitPerson!=="" ? formikProps.values.streetPrivateVisitPerson : undefined}
                        onChange={(value) => {
                          formikProps.setFieldValue("streetPrivateVisitPerson", value)
                          }
                        }
                        label={strings.street}
                        valueMapper={(item) => item?.key && item?.key?.toString()}
                        keyMapper={(item) => item.id.toString()}
                        itemMapper={(item) => <>{item.name}</>}
                        labelMapper={(item) => item.name}
                        dataFetcher={(page, size, filter) => {
                          return NomenclatureService.findAllNomenclatureEntriesForNomenclaturePageable(
                            page, size, NomenclatureConstants.street, true, `place=${formikProps.values.placePrivateVisitPerson?.key}`, filter ? filter : ""
                          );
                        }}
                        name={streetPrivateVisitPerson.name}
                        className="pb-3"
                        readOnly={props.hasAuthority ? false : true}
                      />
        <Typography variant="subtitle1" gutterBottom>
            {numberStreetPrivateVisitPerson.id + ". " + numberStreetPrivateVisitPerson.label}
        </Typography>  
        <InputField name={numberStreetPrivateVisitPerson.name} label={numberStreetPrivateVisitPerson.placeholder}  className="pb-3" fullWidth disabled={disabled} readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {apartmentEntrancePrivateVisitPerson.id + ". " + apartmentEntrancePrivateVisitPerson.label}
        </Typography>  
        <InputField name={apartmentEntrancePrivateVisitPerson.name} label={apartmentEntrancePrivateVisitPerson.placeholder}  className="pb-3" fullWidth disabled={disabled} readOnly={props.hasAuthority ? false : true}></InputField> 
        <Typography variant="subtitle1" gutterBottom>
            {apartmentPrivateVisitPerson.id + ". " + apartmentPrivateVisitPerson.label}
        </Typography>  
        <InputField name={apartmentPrivateVisitPerson.name} label={apartmentPrivateVisitPerson.placeholder}  className="pb-3" fullWidth disabled={disabled} readOnly={props.hasAuthority ? false : true}></InputField> 
        </CenteredCardLayout>
        </>
       )}
        </Grid>
      </Grid>
      </>
    )
}
