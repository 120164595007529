import { OrganizationCreationDTO } from "./OrganizationCreationDTO";

export class EmploymentInfoDTO{
    id:number;
    dateFrom: Date | string;
    dateTo: Date | string;
    organization?: OrganizationCreationDTO;
    constructor(json:EmploymentInfoDTO){
        this.id = json.id;
        this.dateFrom = json.dateFrom;
        this.dateTo = json.dateTo;
        this.organization = json.organization ? new OrganizationCreationDTO(json.organization) : undefined;
    }
}