import { FileDTO } from "../../models/foreign_citizens/FileDTO";
import { ForeignCitizenDTO } from "../../models/foreign_citizens/ForeignCitizenDTO";
import { RegistrationCreationDTO } from "../../models/foreign_citizens/RegistrationCreationDTO";
import { RegistrationDTO } from "../../models/foreign_citizens/RegistrationDTO";
import { RegistrationHistoryActionsDTO } from "../../models/foreign_citizens/RegistrationHistoryActionsDTO";
import { RegistrationHistoryActionsDetailsDTO } from "../../models/foreign_citizens/RegistrationHistoryActionsDetailsDTO";
import axiosInstance from "../../network/AxiosInstance";
import { AuthService } from "../AuthService";

export class ForeignCitizenService{
    static async createForeignCitizen(registrationCreationDTO: RegistrationCreationDTO): Promise<RegistrationDTO> {
        const response = await axiosInstance.post<RegistrationDTO>( `${AuthService.PREFIX}/foreign-citizens/registrations/`,registrationCreationDTO);
        const data = response.data;
        return new RegistrationDTO(data);
      }
      static async getRegistrationById(id: number): Promise<RegistrationDTO> {
        const response = await axiosInstance.get<RegistrationDTO>(`/rest/foreign-citizens/registrations/${id}`,);
        const data = response.data;
    
        return new RegistrationDTO(data);
    }

    static async updateForeignCitizen(id:number, registrationCreationDTO: RegistrationCreationDTO): Promise<RegistrationDTO> {
      const response = await axiosInstance.put<RegistrationDTO>( `${AuthService.PREFIX}/foreign-citizens/registrations/${id}`,registrationCreationDTO);
      const data = response.data;
      return new RegistrationDTO(data);
    }

    static async getForeignCitizenVersionForRegistration(id:number, foreignCitizenId: number | string | undefined): Promise<ForeignCitizenDTO> {
      const response = await axiosInstance.get<ForeignCitizenDTO>( `${AuthService.PREFIX}/foreign-citizens/registrations/${id}/versions/${foreignCitizenId}`);
      const data = response.data;
      return new ForeignCitizenDTO(data);
    }

    static async generateEMBG(id:number): Promise<RegistrationDTO> {
      const response = await axiosInstance.post<RegistrationDTO>( `${AuthService.PREFIX}/foreign-citizens/registrations/${id}/generate-embg`);
      const data = response.data;
      return new RegistrationDTO(data);
    }

    // static async activateEMBG(id:number): Promise<RegistrationDTO> {
    //   const response = await axiosInstance.post<RegistrationDTO>( `${AuthService.PREFIX}/foreign-citizens/registrations/activate-embg`);
    //   const data = response.data;
    //   return new RegistrationDTO(data);
    // }

    static async activateEMBG(registrationIds: Array<number>): Promise<void> {
        const params = new URLSearchParams();
        for(const regId of registrationIds) {
            params.append("registrationIds", regId.toString())
        }
        const response = await axiosInstance.post<RegistrationDTO>( `${AuthService.PREFIX}/foreign-citizens/registrations/activate-embg`, null, {params});
    }

    static async createOrUpdateWorkingVersion(registrationCreationDTO: RegistrationCreationDTO): Promise<RegistrationDTO> {
      const response = await axiosInstance.post<RegistrationDTO>( `${AuthService.PREFIX}/foreign-citizens/registrations/working-version`,registrationCreationDTO);
      const data = response.data;
      return new RegistrationDTO(data);
    }

    static async getHistoryActions(id:number): Promise<RegistrationHistoryActionsDetailsDTO> {
      const response = await axiosInstance.get<RegistrationHistoryActionsDetailsDTO>( `${AuthService.PREFIX}/foreign-citizens/registrations/history/${id}`);
      const data = response.data;
      return new RegistrationHistoryActionsDetailsDTO(data);
    }

    static async getHistoryEMBG(id:number): Promise<RegistrationHistoryActionsDTO> {
      const response = await axiosInstance.get<RegistrationHistoryActionsDTO>( `${AuthService.PREFIX}/foreign-citizens/registrations/embg-history/${id}`);
      const data = response.data;
      return new RegistrationHistoryActionsDTO(data);
    }

    static async getFilesForRegistration(id:number): Promise<Array<FileDTO>> {
      const response = await axiosInstance.get<Array<FileDTO>>( `${AuthService.PREFIX}/foreign-citizens/registrations/${id}/files`);
      const data = response.data;
      return data;
    }
}
