import { PlaceCreationDTO } from "./PlaceCreationDTO";

export class EntraceDataCreationDTO{
    entranceDate:Date;
    entranceMannerMvrKey:string;
    placeDTO:PlaceCreationDTO | undefined;
    discovererMvrKey:string;
    ovrMvrKey:string;
    visitedForeignerShelter:boolean;
    visitedRefugeeShelter:boolean;
    financialSourceTypeMvrKey:string;
    borderPointMvrKey?: string;
    vehicle?: string;
    constructor(json:EntraceDataCreationDTO){
        this.entranceDate = json.entranceDate;
        this.entranceMannerMvrKey = json.entranceMannerMvrKey;
        this.placeDTO =  json.placeDTO ? new PlaceCreationDTO(json.placeDTO) : undefined;
        this.discovererMvrKey = json.discovererMvrKey;
        this.ovrMvrKey = json.ovrMvrKey;
        this.visitedForeignerShelter = json.visitedForeignerShelter;
        this.visitedRefugeeShelter = json.visitedRefugeeShelter;
        this.financialSourceTypeMvrKey = json.financialSourceTypeMvrKey;
        this.borderPointMvrKey = json.borderPointMvrKey;
        this.vehicle = json.vehicle;
    }
}