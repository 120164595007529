import {Guard} from "./Guard";
import {Navigate} from "react-router-dom";
import {Routes} from "../Routes";
import {EnvironmentUtils} from "../../environment/EnvironmentUtils";

export class InternalPageGuard extends Guard {
    constructor() {
        super((
            <Navigate replace={true} to={Routes.NOT_FOUND}/>
        ));
    }

    async check(): Promise<boolean> {
        return EnvironmentUtils.internalAuth();
    }
}
