import {createBrowserRouter} from "react-router-dom";
import {ErrorRoute} from "../error/ErrorRoute";
import ForeignUserForm from "../pages/applications/ForeignUserForm";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { Profile } from "../pages/Profile";
import { ForeignersSubmittedForms } from "../pages/foreignersSubmittedForms/ForeignersSubmittedForms";
import { GuardedRoute } from "./guards/GuardedRoute";
import { NonAuthGuard } from "./guards/NonAuthGuard";
import {InternalLogin} from "../pages/login/InternalLogin";
import {Login} from "../pages/login/Login";
import {Nomenclatures} from "../pages/nomenclatures/Nomenclatures";
import {NomenclatureDetails} from "../pages/nomenclatures/NomenclatureDetails";
import {InternalAuthGuard} from "./guards/InternalAuthGuard";
import {NomenclatureEntryNew} from "../pages/nomenclatures/NomenclatureEntryNew";
import {ForeignUserFormDetails} from "../pages/applications/ForeignUserFormDetails";
import AsylumSeekersForm from "../pages/AsylumSeekers/AsylumSeekersForm";
import {AsylumSeekersDetails} from "../pages/AsylumSeekers/AsylumSeekersDetails";
import { DiplomatsDetails } from "../pages/DiplomatsForm/DiplomatsDetails";
import ShortTermUserForm from "../pages/ShortTermStayForm/ShortTermUserForm";
import {ForeignersSubmittedShortStayForms} from "../pages/foreignersSubmittedForms/ForeignersSubmittedShortStayForms";
import {ShortTermUserFormDetails} from "../pages/ShortTermStayForm/ShortTermUserFormDetails";
import {Reports} from "../pages/reports/Reports";
import { useEffect, useState } from "react";
import {Routes} from "./Routes";
import CallbackPage from "../pages/auth/CallbackPage";
import AuthorizedPage from "../util/auth/AuthorizedPage";
import {NonInternalAuthGuard} from "./guards/NonInternalAuthGuard";
import {InternalPageGuard} from "./guards/InternalPageGuard";
import { Privileges } from "../models/nomenclatures/Privileges";
import { StorageService } from "../storage/StorageService";
import { AuthService } from "../services/AuthService";
import { PrivilegeGuard, PrivilegeGuardMode } from "./guards/PrivilegeGuard";
import {strings} from "../localization/Localization";
import SilentRenew from "../util/auth/comps/silent-renew";
import {AsylumReports} from "../pages/reports/AsylumReports";
import { DiplomatsSubmittedForms } from "../pages/DiplomatsForm/diplomatsSubmittedForms/DiplomatsSubmittedForms";
import LogoutPage from "../util/auth/comps/LogoutPage";
import {ForeignersSubmittedFormDecisions} from "../pages/foreignersSubmittedForms/ForeignersSubmittedFormDecisions";

export function CreateRouter(){

    const [hasAuthority, setHasAuthority] = useState<boolean>(false);
    const [readShortTerm, setReadShortTerm] = useState<boolean>(false);

    useEffect(() => {
        async function userHasAuthority(): Promise<void> {
            const userRoles: string[] | null = StorageService.getUserRoles();
            if (userRoles !== null) {
                if (userRoles.includes(Privileges.READ_ASYLUM_REQUEST) || userRoles.includes(Privileges.READ_FOREIGN_CITIZEN) || userRoles.includes(Privileges.READ_SHORT_TERM_STAY)) {
                    if (userRoles.includes(Privileges.READ_ASYLUM_REQUEST) || userRoles.includes(Privileges.READ_FOREIGN_CITIZEN)) {
                        setHasAuthority(true)
                    }
                    if (userRoles.includes(Privileges.READ_SHORT_TERM_STAY)) {
                        setReadShortTerm(true)
                    }
                }
            } else {
                const authorization = await AuthService.getAuthorization();
                if (authorization.roles.includes(Privileges.READ_ASYLUM_REQUEST) || authorization.roles.includes(Privileges.READ_FOREIGN_CITIZEN) || authorization.roles.includes(Privileges.READ_SHORT_TERM_STAY)) {
                    if (authorization.roles.includes(Privileges.READ_ASYLUM_REQUEST) || authorization.roles.includes(Privileges.READ_FOREIGN_CITIZEN)) {
                        setHasAuthority(true)
                    }
                    if (authorization.roles.includes(Privileges.READ_SHORT_TERM_STAY)) {
                        setReadShortTerm(true)
                    }
                }
            }
        }

        userHasAuthority().then((_) => { });

    }, []);
      
      
    return createBrowserRouter([
        {
            path: Routes.CALLBACK,
            element: <CallbackPage/>,
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.HOME,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                    ]}
                    component={<Dashboard hasAuthority={hasAuthority} readShortTerm={readShortTerm} />}
                />
            ),
            errorElement: <ErrorRoute/>,

        },
        {
            path: Routes.NOT_FOUND,
            element: (
               <ErrorRoute/>
            ),
        },
        {
            path: Routes.PROFILE,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                    ]}
                    component={<AuthorizedPage
                        component={<Profile/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>,
        },
        {
            path: Routes.APPLICATION,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_FOREIGN_CITIZEN],
                            PrivilegeGuardMode.hasAll,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<ForeignUserForm/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>,

        },
        {
            path: Routes.ADD_SHORT_TERM_STAY_APPLICATION,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_SHORT_TERM_STAY],
                            PrivilegeGuardMode.hasAll,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<ShortTermUserForm/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>,

        },
        {
            path: Routes.EDIT_SHORT_TERM_STAY_APPLICATION,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_SHORT_TERM_STAY, Privileges.MANAGE_SHORT_TERM_STAY],
                            PrivilegeGuardMode.hasAny,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<ShortTermUserFormDetails/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>,

        },
        // {
        //     path: Routes.USERS,
        //     element: (
        //         <GuardedRoute
        //             guards={[
        //                 new InternalAuthGuard(),
        //             ]}
        //             component={<AuthorizedPage
        //                 component={<UsersView/>}
        //             />}
        //         />
        //     ),
        //     errorElement: <ErrorRoute/>,

        // },
        // {
        //     path: Routes.USERDETAILS,
        //     element: (
        //         <GuardedRoute
        //             guards={[
        //                 new InternalAuthGuard(),
        //             ]}
        //             component={<AuthorizedPage
        //                 component={<UserDetails/>}
        //             />}
        //         />
        //     ),
        //     errorElement: <ErrorRoute/>,

        // },
        {
            path: Routes.ASYLUM_SEEKERS,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_ASYLUM_REQUEST],
                            PrivilegeGuardMode.hasAll,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<AsylumSeekersForm/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>,
        },
        {
            path: Routes.ASYLUM_SEEKERS_EDIT,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_ASYLUM_REQUEST, Privileges.MANAGE_ASYLUM_REQUEST],
                            PrivilegeGuardMode.hasAny,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<AsylumSeekersDetails/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>,
        },
        {
            path: Routes.DIPLOMATS_FORM,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_DIPLOMAT],
                            PrivilegeGuardMode.hasAll,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<DiplomatsDetails/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>,
        },
        {
            path: Routes.DIPLOMATS_FORM_EDIT,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [ Privileges.MANAGE_DIPLOMAT],
                            PrivilegeGuardMode.hasAll,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<DiplomatsDetails/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>,
        },
        {
            path: Routes.INTERNAL_LOGIN,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalPageGuard(),
                        new NonInternalAuthGuard(),
                    ]}
                    component={<InternalLogin/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },
        {
            path: Routes.LOGIN,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<Login/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },
        {
            path: Routes.LOGOUT,
            element: <LogoutPage/>,
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.FOREIGNERS_SUBMITTED_FORMS,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_FOREIGN_CITIZEN, Privileges.READ_ASYLUM_REQUEST, Privileges.MANAGE_FOREIGN_CITIZEN, Privileges.MANAGE_ASYLUM_REQUEST],
                            PrivilegeGuardMode.hasAny,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<ForeignersSubmittedForms submitted={true} title={strings.foreignersSubmittedForms}/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.FOREIGNERS_SUBMITTED_FORMS_PENDING,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_FOREIGN_CITIZEN, Privileges.READ_ASYLUM_REQUEST, Privileges.MANAGE_FOREIGN_CITIZEN, Privileges.MANAGE_ASYLUM_REQUEST],
                            PrivilegeGuardMode.hasAny,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<ForeignersSubmittedForms submitted={false} title={strings.foreignersSubmittedFormsPending}/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.FOREIGNERS_SUBMITTED_FORM_DECISIONS,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_FOREIGN_CITIZEN, Privileges.READ_ASYLUM_REQUEST, Privileges.MANAGE_FOREIGN_CITIZEN, Privileges.MANAGE_ASYLUM_REQUEST],
                            PrivilegeGuardMode.hasAny,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={
                            <ForeignersSubmittedFormDecisions />
                        }
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.DIPLOMATS_SUBMITTED_FORMS,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_DIPLOMAT],
                            PrivilegeGuardMode.hasAny,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<DiplomatsSubmittedForms submitted={true} title={strings.diplomatsRegister}/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.DIPLOMATS_SUBMITTED_FORMS_PENDING,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_DIPLOMAT],
                            PrivilegeGuardMode.hasAny,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<DiplomatsSubmittedForms submitted={false} title={strings.foreignersSubmittedFormsPending}/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.FOREIGNERS_SUBMITTED_SHORT_STAY_FORMS,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_SHORT_TERM_STAY, Privileges.MANAGE_SHORT_TERM_STAY],
                            PrivilegeGuardMode.hasAny,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<ForeignersSubmittedShortStayForms submitted={true} title={strings.shortStayForms}/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.FOREIGNERS_SUBMITTED_SHORT_STAY_FORMS_PENDING,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_SHORT_TERM_STAY, Privileges.MANAGE_SHORT_TERM_STAY],
                            PrivilegeGuardMode.hasAny,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<ForeignersSubmittedShortStayForms submitted={false} title={strings.shortStayFormsPending}/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.NOMENCLATURES,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_NOMENCLATURES],
                            PrivilegeGuardMode.hasAll,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<Nomenclatures/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.NOMENCLATURE_ENTRY_NEW,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_NOMENCLATURES],
                            PrivilegeGuardMode.hasAll,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<NomenclatureEntryNew/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.NOMENCLATURE_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_NOMENCLATURES],
                            PrivilegeGuardMode.hasAll,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<NomenclatureDetails/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.EDIT_APPLICATION,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_FOREIGN_CITIZEN, Privileges.MANAGE_FOREIGN_CITIZEN],
                            PrivilegeGuardMode.hasAny,
                        )
                    ]}
                    component={<AuthorizedPage
                        component={<ForeignUserFormDetails/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>,

        },
        {
            path: Routes.REPORTS_NUMBER_OF_PERMITS,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_REPORTS_FOREIGN_CITIZEN],
                            PrivilegeGuardMode.hasAll, 
                        )]}
                    component={<AuthorizedPage
                        component={<Reports/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.SILENT_RENEW,
            element: (
                <SilentRenew/>
            ),
            errorElement: <ErrorRoute/>
        },
        {
            path: Routes.ASYLUM_REPORTS,
            element: (
                <GuardedRoute
                    guards={[
                        new InternalAuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_FOREIGN_CITIZEN, Privileges.MANAGE_FOREIGN_CITIZEN],
                            PrivilegeGuardMode.hasAny,
                        )]}
                    component={<AuthorizedPage
                        component={<AsylumReports/>}
                    />}
                />
            ),
            errorElement: <ErrorRoute/>
        }
    ], {
        basename: process.env.REACT_APP_ROUTER_BASE,
    });
}
