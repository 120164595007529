import {NomenclatureEntryDTOWithKey} from "./NomenclatureEntryDTOWithKey";

export class NomenclatureEntryDTO {
    id: number;
    dateCreated: string;
    dateModified: string;
    key: string;
    name: string;
    nameSq: string;
    nameEn: string;
    active: boolean | null | undefined;
    nomenclatureEntryKey: string;


    constructor(json: any) {
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.key = json.key;
        this.name = json.name;
        this.nameSq = json.nameSq;
        this.nameEn = json.nameEn;
        this.active = json.active;
        this.nomenclatureEntryKey = json.nomenclatureEntryKey;
    }

    mappedKeyToId(): NomenclatureEntryDTOWithKey {
        return new NomenclatureEntryDTOWithKey(this);
    }

    static fromKey(key: string): NomenclatureEntryDTO {
        return new NomenclatureEntryDTO({
            id: "id-" + key,
            key: key,
            name: key,
            nameSq: key,
            nameEn: key,
            active: key,
            nomenclatureEntryKey: key,
        });
    }
}
